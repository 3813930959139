import { DateTimeParam, UrlUpdateType, useQueryParams } from "use-query-params";
import { useDebouncedCallback } from "use-debounce";
import { DateRange } from "src/components/DateSelector/DateSelector";

type UseQueryDateRangeResult = [
  [Date | null, Date | null],
  (dateRange: DateRange) => void
];

type UseQueryDateRangeArgs = {
  updateType?: UrlUpdateType;
};

export function useQueryDateRangeReportsWithEmptyDefault(
  args?: UseQueryDateRangeArgs
): UseQueryDateRangeResult {
  const { updateType = "replaceIn" } = args || {};

  const [{ startDateReports, endDateReports }, setQueryParams] = useQueryParams(
    {
      startDateReports: DateTimeParam,
      endDateReports: DateTimeParam,
    }
  );

  const nextDateRange: [Date | null, Date | null] = [
    startDateReports || null,
    endDateReports || null,
  ];

  const setDateRange = useDebouncedCallback((dateRange: DateRange) => {
    setQueryParams(
      { startDateReports: dateRange[0], endDateReports: dateRange[1] },
      updateType
    );
  }, 300);

  return [nextDateRange, setDateRange];
}
