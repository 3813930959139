import { Fragment } from "react/jsx-runtime";
import { Children, ReactElement, ReactNode, cloneElement } from "react";

function isCustomOptionExists<T>({
  option,
  existing,
  getOptionKey,
}: {
  option: T;
  existing: readonly T[];
  getOptionKey?: (opt: T) => string | number;
}) {
  return existing.some((opt) => {
    const key1 = getOptionKey ? getOptionKey(opt) : opt;
    const key2 = getOptionKey ? getOptionKey(option) : option;
    return key1 === key2;
  });
}

const isPrimitive = (
  value: unknown
): value is string | number | boolean | null => {
  return (
    typeof value === "string" ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    value === null
  );
};

const injectElement = ({
  inject,
  children,
  position,
}: {
  inject: ReactNode;
  children: ReactNode;
  position: "start" | "end";
}) => {
  const prev = Children.toArray(children);

  if (position === "start") {
    return (
      <Fragment>
        {inject}
        {prev.map((child) => child)}
      </Fragment>
    );
  }

  if (position === "end") {
    return (
      <Fragment>
        {prev.map((child) => child)}
        {inject}
      </Fragment>
    );
  }
};

function isIterable<T = unknown>(
  value: unknown | Iterable<T>
): value is Iterable<T> {
  return typeof (value as Iterable<T>)[Symbol.iterator] === "function";
}

const injectIntoElement = ({
  inject,
  parent,
  position,
}: {
  inject: ReactElement;
  parent: ReactNode;
  position: "start" | "end";
}): ReactElement => {
  // parent element can be almost anything
  if (!parent) {
    return inject;
  }

  if (isPrimitive(parent)) {
    return <Fragment>{parent}</Fragment>;
  }

  if (isIterable(parent)) {
    return <Fragment>[...parent, inject]</Fragment>;
  }

  const nextChildren = injectElement({
    inject,
    children: parent.props.children,
    position,
  });

  return cloneElement(parent, parent.props, nextChildren);
};

export function useAppAutocompleteHelpers() {
  return {
    isCustomOptionExists,
    injectElement,
    isPrimitive,
    isIterable,
    injectIntoElement,
  };
}
