import Joi from "joi";
import { WatchListClusterAssignFormValues } from "./WatchListClusterAssignForm.model";

type ClusterValue = { id: string | null; title: string };

const ClusterSchema = Joi.object<ClusterValue>({
  id: Joi.string().allow(null),
  title: Joi.string().required().label("Cluster"),
});

export const makeWatchListClusterAssignFormSchema = ({
  clusterNames,
}: {
  clusterNames: string[];
}) =>
  Joi.object<WatchListClusterAssignFormValues>({
    queryIds: Joi.array().items(Joi.string()).required(),
    cluster: ClusterSchema.required().label("Cluster").messages({
      "object.base": "Cluster is required",
    }),
  });
