import { create } from "zustand";
import { MediaCenterItem } from "./useMediaCenter";

type SelectionState<
  T extends {
    id: string;
  }
> = {
  list: T[];
  select: (event: T) => void;
  selectAll: (events: T[]) => void;
  unselect: (event: T) => void;
  unselectAll: () => void;
};

export const useEventSelectionMediaCenter = create<
  SelectionState<MediaCenterItem>
>((setState, getState) => ({
  list: [],
  select: (event) => {
    setState((state) => ({
      list: [...state.list, event],
    }));
  },
  selectAll: (events) => {
    setState(() => ({
      list: events,
    }));
  },
  unselect: (event) => {
    setState((state) => ({
      list: state.list.filter((stateEvent) => {
        return event.id !== stateEvent.id;
      }),
    }));
  },
  unselectAll: () => {
    const prev = getState();
    if (prev.list.length) {
      // update reference if there are items
      setState(() => ({
        list: [],
      }));
    }
  },
}));
