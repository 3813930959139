import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const path: keyof paths = "/api/user/{userId}/query/{queryId}/matches";
const method: keyof paths[typeof path] = "post";

export const watchTermResultQueryKey = path;

type Endpoint = paths[typeof path][typeof method];
type Parameters = Endpoint["parameters"];

export type WatchTermResultItem = Required<
  components["schemas"]["EventHighlightsDTO"]
>;

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type WatchTermResultsResponse = {
  total?: number;
  results?: WatchTermResultItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

type UseWatchTermResultsParams = {
  path: Omit<Parameters["path"], "userId">;
  body: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
  query: Parameters["query"];
};

export function useWatchTermResults({
  params,
  options,
  keepPreviousData = true,
}: {
  params: UseWatchTermResultsParams;
  options?: UseQueryOptions<WatchTermResultsResponse, unknown>;
  keepPreviousData?: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  return useQuery<WatchTermResultsResponse, unknown>({
    ...options,
    keepPreviousData,
    queryKey: [path, params],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(path, {
        ...params,
        path: {
          ...params.path,
          userId,
        },
      });

      const { data } = await axios<WatchTermResultsResponse>(url, {
        method,
        data: params.body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
