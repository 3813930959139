import { Stack, StackProps } from "@mui/material";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import {
  CarouselBase,
  CarouselBaseProps,
} from "src/components/CarouselBase/CarouselBase";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { DateTimeSearchSlide } from "./DateTimeSearchSlide";
import { useDateTimeSearchPlayState } from "../DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";

export type DateTimeSearchCarouselProps = Omit<
  StackProps,
  "children" | "onChange"
> & {
  id?: string;
  data: DateTimeSearchResultItem[];
  loading?: boolean;
  current?: number;
  onChange: CarouselBaseProps<DateTimeSearchResultItem>["onChange"];
};

export function DateTimeSearchCarousel({
  id,
  data,
  loading,
  current,
  onChange,
  ...props
}: DateTimeSearchCarouselProps) {
  const { pause, setPlayState, isPlaying, activeIndex } =
    useDateTimeSearchPlayState();

  const renderItemWrapper: CarouselBaseProps<DateTimeSearchResultItem>["renderItem"] =
    (item) => {
      return <DateTimeSearchSlide value={item} />;
    };

  const onChangeWrapper: CarouselBaseProps<DateTimeSearchResultItem>["onChange"] =
    (item) => {
      setPlayState(`${item.id}`);
    };

  const onScrollWrapper: CarouselBaseProps<DateTimeSearchResultItem>["onScroll"] =
    () => {
      if (isPlaying) {
        pause();
      }
    };

  const content = loading ? (
    <LoadingOverlay />
  ) : (
    <CarouselBase
      flex={1}
      height="100%"
      data={data}
      getItemId={(item) => `${item.id}`}
      current={activeIndex ?? 0}
      onChange={onChangeWrapper}
      onScroll={onScrollWrapper}
      renderItem={renderItemWrapper}
    />
  );

  return <Stack id={id} overflow="hidden" children={content} {...props} />;
}
