import { Locale } from "date-fns";

const clipViewerDistanceFormat: Record<string, string> = {
  xSeconds: "{{count}}s",
  xMinutes: "{{count}}m",
  xHours: "{{count}}h",
};

export const clipViewerDistanceFormatLocaleEN: Locale = {
  formatDistance: (token: string, count: string) =>
    clipViewerDistanceFormat[token].replace("{{count}}", count),
};
