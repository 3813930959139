import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { AccountsManagementEditAccount } from "./AccountsManagementEditAccount.page";

export type AccountsManagementEditAccountParams = {
  accountId: string;
};

export const accountsManagementEditAccountRoute =
  new AppRouteBuilder<AccountsManagementEditAccountParams>({
    path: "/accounts-management/:accountId",
    element: (
      <PermissionGuard
        component={AccountsManagementEditAccount}
        allowedRoles={["Admin", "Account Manager", "User Manager"]}
        disableMobile
        redirectTo="/"
        // silent
      />
    ),
  });
