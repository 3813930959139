import { useEffect } from "react";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useWatchQueryClone } from "src/api/useWatchQueryClone";
import { useWatchQuery } from "src/api/useWatchQuery";
import { useWatchQueryCloneForm } from "./utils/useWatchQueryCloneForm";
import { useWatchQueryCloneDialogState } from "./utils/useWatchQueryCloneDialogState";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { WatchQueryCloneForm } from "./WatchQueryCloneForm";

export function WatchQueryCloneDialog() {
  const { hide, queryId, isOpen } = useWatchQueryCloneDialogState();

  const watchQuery = useWatchQuery({
    enabled: !!queryId,
    path: { queryId },
  });

  const formHook = useWatchQueryCloneForm();

  const watchQueryClone = useWatchQueryClone({
    options: {
      onSuccess() {
        hide();
      },
    },
  });

  const { handleSubmit, watch, formState, reset, setValue } = formHook;
  const { title, includeAlerts } = watch();
  const { isValid } = formState;

  const handleDecline = () => {
    hide();
  };

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!isOpen) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset();
    }
  }, [reset, isOpen]);

  console.log(`@@ DEBUG:formState`, { title, includeAlerts });

  useEffect(() => {
    setValue("title", `${watchQuery.data?.title} - Copy`);
  }, [setValue, watchQuery.data?.title]);

  const handleConfirm = handleSubmit(() => {
    watchQueryClone.mutate({
      query: {
        includeAlerts,
        title,
      },
      path: {
        queryId,
      },
    });
  });

  const content = (
    <Stack>
      <WatchQueryCloneForm isLoading={watchQuery.isFetching} />
    </Stack>
  );

  return (
    <FormProvider {...formHook}>
      <DialogPrompt
        open={isOpen}
        title="Clone Watch term"
        declineLabel="Cancel"
        confirmLabel="Ok"
        children={content}
        onConfirm={handleConfirm}
        confirmDisabled={
          watchQueryClone.isLoading || watchQuery.isFetching || !isValid
        }
        onDecline={handleDecline}
      />
    </FormProvider>
  );
}
