import { ChangeEvent, useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { Box, TextField } from "@mui/material";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { useDateTimeSearchSaveClip } from "src/api/useDateTimeSearchSaveClip";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";

type SaveDateTimeSearchClipDialogProps = {
  open: boolean;
  event: DateTimeSearchResultItem;
  onClose: () => void;
};

export const SaveDateTimeSearchClipDialog = ({
  open,
  event,
  onClose,
}: SaveDateTimeSearchClipDialogProps) => {
  const [title, setTitle] = useState(event.title);

  useEffect(() => {
    if (!event.title) return;

    setTitle(event.title);
  }, [event]);

  const handleClose = () => {
    setTitle(event.title);
    onClose();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const eventCreate = useDateTimeSearchSaveClip({
    options: {
      onSettled: handleClose,
    },
  });

  const handleClipSave = () => {
    if (!title || !event.blockEndTime || !event.blockStartTime) return;

    const durationSeconds = differenceInSeconds(
      new Date(event.blockEndTime),
      new Date(event.blockStartTime)
    );
    eventCreate.mutate({
      query: {
        clipTitle: title,
        stationUID: event.stationUUID,
        dateTime: event.blockStartTime,
        durationSeconds: durationSeconds,
        archive: true,
      },
    });
  };

  const content = (
    <Box py={1}>
      <TextField
        value={title}
        onChange={handleInputChange}
        label="Clip title"
        variant="outlined"
        fullWidth
      />
    </Box>
  );

  return (
    <DialogPrompt
      title="Save Clip"
      open={open}
      onDecline={handleClose}
      onConfirm={handleClipSave}
      confirmLabel="Ok"
      children={content}
    />
  );
};
