import { BoxProps, Stack, Typography, alpha, useTheme } from "@mui/material";
import { usePublicPageLayout } from "src/utils/usePublicPageLayout";
import { LoginLogoutButton } from "./LoginLogoutButton/LoginLogoutButton";
import { LogoEye } from "./logo/LogoEye";

export function AppLayoutPublicHeader() {
  const { palette } = useTheme();
  const { maxContentWidth } = usePublicPageLayout();
  const logoSize: BoxProps["width"] = {
    xs: 40,
    sm: 64,
  };

  return (
    <Stack
      id="app-layout-public-header"
      direction="row"
      alignItems="center"
      bgcolor={palette.background.brand}
      color={palette.getContrastText(palette.background.brand)}
      height={{ sm: 102 }}
      width="100%"
      maxWidth={maxContentWidth}
      columnGap={{ xs: 2, md: 3 }}
      py={{ xs: 1, md: 2 }}
      px={{ xs: 2, md: 4 }}
      mx="auto"
    >
      <LogoEye dark pt={1} maxHeight={logoSize} maxWidth={logoSize} />
      <Stack flex={1} height={{ xs: 84 }} justifyContent={{ xs: "center" }}>
        <Typography variant="subtitle1">TVEyes Insight</Typography>

        <Typography
          variant="body2"
          color={alpha(palette.getContrastText(palette.background.brand), 0.7)}
          children="Advanced Media Monitoring"
        />
      </Stack>

      <LoginLogoutButton />
    </Stack>
  );
}
