import { create } from "zustand";

interface OpenState {
  isOpen: boolean;
  show: () => void;
  hide: () => void;
}

export const useGlobalSearchOpen = create<OpenState>((set) => ({
  isOpen: false,
  show: () => set({ isOpen: true }),
  hide: () => set({ isOpen: false }),
}));
