import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicMediaCenterItemPage } from "./PublicMediaCenterItem.page";

export type PublicMediaCenterItemPathParams = {
  userId: string;
  itemId: string;
};

export const publicMediaCenterItemRoute =
  new AppRouteBuilder<PublicMediaCenterItemPathParams>({
    path: "/public/media-center/:userId/:itemId",
    element: <PublicMediaCenterItemPage />,
  });
