import {
  Dialog,
  Typography,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Box,
} from "@mui/material";
import { Assessment, BookmarkAdd } from "@mui/icons-material";
import { useClipsSave } from "src/api/useClipsSave";
import { useSessionContext } from "src/api/useSessionContext";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { EventsResultsExportDialogMobileProps } from "./EventsResultsExportDialogMobile.model";

export function EventsResultsExportDialogMobile({
  open,
  onClose,
  values,
  selectedEventsLength,
  createReport,
  // downloadPdf,
  downloadCsv,
}: EventsResultsExportDialogMobileProps) {
  const saveClips = useClipsSave({});
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;

  const handleClipSave = () => {
    const body = values.map((item) => ({ eventHighlights: item }));
    saveClips.mutate({ query: body });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 319,
          py: 2,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          px: 3,
        }}
      >
        Export
      </Typography>
      <List>
        <MenuItem
          onClick={() => {
            onClose();
            createReport();
          }}
        >
          <ListItemIcon>
            <Assessment fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Create Report</ListItemText>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            onClose();
            downloadPdf();
          }}
        >
          <ListItemIcon>
            <DownloadPDF />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Download PDF</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            onClose();
            downloadCsv();
          }}
        >
          <ListItemIcon>
            <DownloadCSV />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Download CSV</ListItemText>
        </MenuItem>
        <Tooltip
          title={
            selectedEventsLength > 10
              ? "Save To Media Center is available for up to 10 clips. Please select less items"
              : "Save for 90 days to the Media Center from where you can select clips when create report or share items"
          }
        >
          <Box>
            <MenuItem
              onClick={() => {
                onClose();
                handleClipSave();
              }}
              disabled={!enableMediaDownload || selectedEventsLength > 10}
            >
              <ListItemIcon>
                <BookmarkAdd fontSize="medium" color="primary" />
              </ListItemIcon>
              <ListItemText sx={{ ml: 0.5 }}>Save To Media Center</ListItemText>
            </MenuItem>
          </Box>
        </Tooltip>
      </List>
      <Button
        variant="outlined"
        size="medium"
        color="inherit"
        sx={{
          width: 84,
          ml: "auto",
          mr: 2,
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Dialog>
  );
}
