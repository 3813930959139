import { useRef } from "react";
import { ZoomInOutlined, ZoomOutOutlined } from "@mui/icons-material";
import {
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  useTheme,
  SxProps,
  Tooltip,
} from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { TimeLineZoomControlsProps } from "./TimeLineZoomControls.model";
import { timeLineMaxScale, timeLineMinScale } from "../../TimeLine.const";

export function TimeLineZoomControls({
  value,
  onChange,
  minScale = timeLineMinScale,
  maxScale = timeLineMaxScale,
  disabled,
  size = "small",
  position = "inside",
}: TimeLineZoomControlsProps) {
  const { palette } = useTheme();
  const { isOpen, hide, show } = useOpenState();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const bgcolor = palette.background.paper;

  const step = value / 3;

  const handleZoomIn = () => {
    const nextScale = Math.min(maxScale, value + step);
    onChange && onChange(nextScale);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(hide, 1000);
    show();
  };

  const handleZoomOut = () => {
    const nextScale = Math.max(minScale, value - step);
    onChange && onChange(nextScale);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(hide, 1000);
    show();
  };

  const groupButtonSx: SxProps = { bgcolor };
  const buttonSx: SxProps = {
    width: 36,
    height: 36,
    borderColor: palette.primary.main,
  };

  const zoomInDisabled = disabled || value === timeLineMaxScale;
  const zoomOutDisabled = disabled || value === timeLineMinScale;

  return (
    <Tooltip title={`Zoom ${value.toFixed(1)}`} open={isOpen}>
      <Stack
        position="absolute"
        right={position === "inside" ? 16 : 8}
        top={position === "inside" ? 8 : -8}
        direction="row"
        columnGap={1}
      >
        <ToggleButtonGroup
          size={size}
          sx={groupButtonSx}
          disabled={disabled || zoomInDisabled}
        >
          <ToggleButton value="zoom-in" onClick={handleZoomIn} sx={buttonSx}>
            <ZoomInOutlined color={zoomInDisabled ? "disabled" : "primary"} />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          size={size}
          sx={groupButtonSx}
          disabled={disabled || zoomOutDisabled}
        >
          <ToggleButton value="zoom-out" onClick={handleZoomOut} sx={buttonSx}>
            <ZoomOutOutlined color={zoomOutDisabled ? "disabled" : "primary"} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Tooltip>
  );
}
