import { create } from "zustand";
import { makeEmptyWatchQueryFilterFormValues } from "src/components/WatchQueryFilterForm/WatchQueryFilterForm.hook";
import { WatchQueryFilterFormValues } from "src/components/WatchQueryFilterForm/WatchQueryFilterForm.model";

type UseQueryFilterState = {
  form: WatchQueryFilterFormValues;
  reset: () => void;
  update: (changes: Partial<WatchQueryFilterFormValues>) => void;
};

const emptyFormValues = makeEmptyWatchQueryFilterFormValues();

const stateHook = create<UseQueryFilterState>((set, get) => {
  return {
    form: emptyFormValues,
    reset: () => {
      const prev = get();
      set(
        {
          ...prev,
          form: {
            ...emptyFormValues,
          },
        },
        true
      );
    },
    update: (changes) => {
      const { form } = get();
      set({ form: { ...form, ...changes } });
    },
  };
});

/**
 * Syncs query Watch Query Filter object and date range in query string
 */
export function useAnalyticsLiteQueryFilter(): [
  WatchQueryFilterFormValues,
  (changes: WatchQueryFilterFormValues) => void,
  () => void
] {
  const state = stateHook();
  const { form, update, reset } = state;

  return [form, update, reset];
}
