import { useMemo } from "react";

export function useListSelectInfo({
  list,
  selectedList,
  areAllPagesSelected,
  excludeEventsLength,
}: {
  list: { id: string }[];
  selectedList: { id: string }[];
  areAllPagesSelected?: boolean;
  excludeEventsLength?: number;
}) {
  return useMemo(() => {
    if (areAllPagesSelected) {
      return {
        isAllSelected: excludeEventsLength === 0,
        isPartiallySelected: excludeEventsLength !== 0,
      };
    }

    let hasSomeSelected = false;
    let hasAllSelected = list.length > 0;

    for (const item of list) {
      if (selectedList.some((selectedItem) => selectedItem?.id === item?.id)) {
        // track we have selected item
        hasSomeSelected = true;
      } else {
        // track we have unselected item
        hasAllSelected = false;
      }

      if (hasSomeSelected && !hasAllSelected) {
        // no need to check other items
        break;
      }
    }

    return {
      isAllSelected: hasAllSelected,
      isPartiallySelected: hasSomeSelected && !hasAllSelected,
    };
  }, [list, selectedList, areAllPagesSelected, excludeEventsLength]);
}
