import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/banner/user/{userId}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];
//FIXME Wrong types from BE
export type BannerDataOriginal = ResponseBody;

export type BannerData = Omit<BannerDataOriginal, "bannerOwner"> & {
  bannerOwner?: "User" | "Account" | "Default";
};

type RequestOptions = {
  params: {
    path: {
      userId: Parameters["path"]["userId"];
    };
  };
};

export const userBannerKey = "userBannerKey";

export function useBanner({ request }: { request: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  const userId = request.params.path.userId;

  const parameters: Parameters = {
    path: {
      userId: userId,
    },
  };

  return useQuery<BannerData, unknown>({
    queryKey: [userBannerKey, userId],
    enabled: !!userId,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, parameters);
      const { data } = await axios<BannerData>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
