import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronRight, Logout, Receipt, Settings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { settingsRoute } from "src/pages/Settings/Settings.route";
import { useProfileImage } from "src/api/useProfileImage";
import { useSessionContext } from "src/api/useSessionContext";
import { accountsManagementEditAccountRoute } from "src/pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { getRoleFromUser } from "src/utils/useUserRoleList";
import { SettingsPageTab } from "src/pages/Settings/Settings.page";
import { useCurrentUser } from "src/api/useCurrentUser";
import { ProfileButtonMenuItem } from "./ProfileButtonMenuIcon";
import { AppLink } from "../AppLink/AppLink";

export const ProfileButton = (props: { collapsed?: boolean }) => {
  const { data: currentUser } = useCurrentUser();
  const { accountId, isImpersonatingUser, managementContact } =
    useSessionContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const { palette } = useTheme();
  const { user, logout } = useAuth0();
  const role = getRoleFromUser(user);
  const { data } = useProfileImage();

  const contactUrl = settingsRoute.makeUrl(
    {},
    {
      pageTab: SettingsPageTab.contact,
    }
  );

  const accountUrl = accountsManagementEditAccountRoute.makeUrl({
    accountId,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleListItemClick = () => {
    handleClose();
    navigate(contactUrl);
  };

  return (
    <ListItem alignItems="center" disablePadding disableGutters>
      <ListItemButton
        id="profile-button"
        onClick={openProfileMenu}
        sx={{
          paddingX: 0.5,
          paddingY: 0,
          borderRadius: 2,
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{ width: 32, height: 32, m: 0.5 }}
            alt={
              isImpersonatingUser === true
                ? `${currentUser?.firstName} ${currentUser?.lastName}` ||
                  "Impersonated User"
                : user?.name
            }
            src={data?.image}
            imgProps={{
              referrerPolicy: "no-referrer",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{
            margin: 0,
            opacity: props.collapsed ? 0 : 1,
            transition: theme.transitions.create("opacity", {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
          primary={
            <Typography noWrap>
              {isImpersonatingUser === true
                ? currentUser
                  ? `${currentUser?.firstName} ${currentUser?.lastName}`
                  : "Impersonated User"
                : user?.name}
            </Typography>
          }
          secondary={
            isImpersonatingUser === true ? undefined : (
              <Typography color={palette.primary.main} noWrap>
                {role}
              </Typography>
            )
          }
        />
        <ListItemIcon
          sx={{
            minWidth: "unset",
            opacity: props.collapsed ? 0 : 1,
            transition: theme.transitions.create("opacity", {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <ChevronRight />
        </ListItemIcon>
      </ListItemButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          // offset menu content farther from anchorEl
          ml: 1.2,
          mt: 1.8,
        }}
      >
        <ProfileButtonMenuItem
          path={settingsRoute.path}
          label="My Settings"
          tooltip="My Settings"
          icon={<Settings />}
          onClick={handleClose}
        />

        <ProfileButtonMenuItem
          path={accountUrl}
          label="My Account"
          tooltip="My Account"
          icon={<Receipt />}
          onClick={handleClose}
        />

        <ProfileButtonMenuItem
          label="Log Out"
          tooltip="Log Out"
          icon={<Logout />}
          onClick={handleLogout}
          divider
        />

        <MenuItem sx={{ mt: 1 }}>
          <Box display="flex" flexDirection="column">
            <ListItemText
              onClick={handleListItemClick}
              primaryTypographyProps={{
                variant: "body2",
              }}
              primary="Your Sales Representative:"
              secondary={
                <Typography color={theme.palette.primary.main}>
                  <AppLink to={contactUrl} onClick={handleClose}>
                    {`${managementContact?.firstName} ${managementContact?.lastName}`}
                  </AppLink>
                </Typography>
              }
            />
          </Box>
        </MenuItem>
      </Menu>
    </ListItem>
  );
};
