export const axisNumberTickAutoFormat = (value: number) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 100000) {
    return `${(value / 1000).toFixed(1)}K`;
  } else if (value >= 10000) {
    return `${(value / 1000).toFixed(1)}K`;
  } else {
    return value.toString();
  }
};
