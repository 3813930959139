import { Fragment } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { format, isValid, startOfDay } from "date-fns";
import { Stack, Typography } from "@mui/material";
import { StationBroadcastData } from "src/api/useStationBroadcastProgram";
import { TimeLine } from "../TimeLine/TimeLine";
import { TimeLineProgramLayout } from "./components/TimeLineProgramLayout/TimeLineProgramLayout";
import { TimeLineEmptyProgramLayout } from "../TimeLine/components/TimeLineEmptyProgramLayout/TimeLineEmptyProgramLayout";
import { TimeLineZoomControlsProps } from "../TimeLine/components/TimeLineZoomControls/TimeLineZoomControls.model";

/**
 * Draggable TimeLine to render programs
 */
export function TimeLineProgram({
  value,
  maxDate,
  onChange,
  schedule,
  station = "n/a",
  timeZone,
  isPlaying,
  zoomControlPosition,
}: {
  value: Date;
  maxDate: Date;
  onChange: (value: Date) => void;
  schedule?: StationBroadcastData;
  station?: string;
  timeZone?: string;
  isPlaying?: boolean;
  zoomControlPosition?: TimeLineZoomControlsProps["position"];
}) {
  const topLabelDate = timeZone
    ? utcToZonedTime(value, timeZone)
    : new Date(value);

  const topLabel = isValid(topLabelDate)
    ? format(startOfDay(topLabelDate), "MMMM dd")
    : " - ";

  return (
    <TimeLine
      value={value}
      maxDate={maxDate}
      onChange={onChange}
      isPlaying={isPlaying}
      zoomControlPosition={zoomControlPosition}
      renderBlockContent={({ blockStartTime }) => {
        const date = timeZone
          ? utcToZonedTime(blockStartTime, timeZone)
          : new Date(blockStartTime);

        const label = isValid(date) ? format(date, "p") : " - ";
        return (
          <Stack justifyContent="flex-end" flex={1}>
            <Typography align="center" variant="body2">
              {label.length > 4 ? (
                <small children={label} />
              ) : (
                <span children={label} />
              )}
            </Typography>
          </Stack>
        );
      }}
      renderTimeLineContent={({ secondsPerPixel }) => {
        const timeLinePrograms = schedule?.programs?.length ? (
          <TimeLineProgramLayout
            time={value}
            schedule={schedule}
            secondsPerPixel={secondsPerPixel}
          />
        ) : (
          <TimeLineEmptyProgramLayout
            time={value}
            station={station}
            secondsPerPixel={secondsPerPixel}
          />
        );

        return (
          <Fragment>
            <Typography position="absolute" variant="body2" top={8} left={4}>
              {topLabel}
            </Typography>
            {timeLinePrograms}
          </Fragment>
        );
      }}
    />
  );
}
