import {
  Paper,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppTopBarMobile } from "./AppTopBarMobile/AppTopBarMobile";
import { useIsFullScreen } from "src/utils/useIsFullScreen";

/**
 * Universal page layout.
 * Please use PageLayoutDesktop and PageLayoutMobile for a better separation of concerns
 * @deprecated
 */
export function PageLayout({
  children,
  hideTopBar,
  overflowX = "hidden",
  overflowY = "hidden",
  maxWidth,
  ...props
}: Omit<StackProps, "overflowX" | "overflowY" | "sx"> & {
  hideTopBar?: boolean;
  overflowX?: "auto" | "hidden";
  overflowY?: "auto" | "hidden";
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isFullScreen = useIsFullScreen();

  return (
    <Stack
      id="page-layout"
      flex={1}
      mx="auto"
      px={isMobile ? 0 : 2}
      boxSizing="content-box"
      width="100%"
      maxWidth={maxWidth ?? isFullScreen ? 1528 : 1152}
      overflow="hidden"
      {...props}
    >
      {!hideTopBar && isMobile && <AppTopBarMobile />}
      <Stack
        pt={{ xs: 0, sm: 5 }}
        pb={{ xs: 0, sm: 3 }}
        flexGrow={1}
        width="100%"
        margin="auto"
        component={isMobile ? Paper : "div"}
        children={children}
        sx={{
          overflowX,
          overflowY,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
    </Stack>
  );
}
