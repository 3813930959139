import { useDebounce } from "use-debounce";
import { useState } from "react";
import { ProgramOption, usePrograms } from "src/api/useProgram";
import { TagPicker } from "../TagPicker/TagPicker";
import { ProgramPickerProps } from "./ProgramPicker.model";

export function ProgramTagPicker(props: ProgramPickerProps) {
  const [sourcesQuery, setSourceQuery] = useState("");
  const [searchText] = useDebounce(sourcesQuery, 500);

  const programData = usePrograms({
    path: {
      searchText: searchText ? searchText : "*",
    },
  });

  const options = programData.data?.list || [];

  return (
    <TagPicker<ProgramOption>
      {...props}
      options={options}
      onInputChange={setSourceQuery}
      hideOperator
      getOptionFromInput={(inputValue: string) => {
        return {
          name: inputValue,
          value: inputValue,
        };
      }}
    />
  );
}
