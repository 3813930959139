import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { MonacoCodeEditor } from "src/components/CodeEditor/MonacoCodeEditor";

export function DevCodeEditor() {
  const [value, setValue] = useState<string | undefined>(`
    (coke NOT (
      "coke it's delicious" OR 
      "coke tastes refreshing"
    )) 
    OR 
    (sprite NOT (
      "holy cow I love sprite" OR 
      "drink moar sprite"))
  `);

  const handleEditorChange = (value?: string) => {
    setValue(value);
  };

  return (
    <Stack>
      <Typography variant="h6">Code Editor</Typography>
      <MonacoCodeEditor value={value} onChange={handleEditorChange} />
    </Stack>
  );
}
