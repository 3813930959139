import { useCallback, useEffect } from "react";
import { ListBase } from "src/components/ListBase/ListBase";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { DateTimeSearchResultListProps } from "./DateTimeSearchResultList.model";
import { DateTimeSearchResultListItem } from "./DateTimeSearchResultListItem";
import { useDateTimeSearchPlayState } from "../DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";

export function DateTimeSearchResultList({
  showFullTranscript,
  ...props
}: DateTimeSearchResultListProps) {
  const { getActiveInstanceId } = useDateTimeSearchPlayState();
  const activeInstanceId = getActiveInstanceId();

  useEffect(() => {
    if (!activeInstanceId) {
      return;
    }

    const element = document.querySelector(`[id='${activeInstanceId}']`);

    element?.scrollIntoView();
  }, [activeInstanceId]);

  const renderItem = useCallback(
    (data: DateTimeSearchResultItem) => {
      return (
        <DateTimeSearchResultListItem
          id={`${data.id}`}
          key={data.id}
          value={data}
          isExpandedTextMode={showFullTranscript}
        />
      );
    },
    [showFullTranscript]
  );

  return (
    <ListBase<DateTimeSearchResultItem>
      renderItem={renderItem}
      customScrollBarStyle="normal"
      ListProps={{
        sx: {
          height: "100%",
          rowGap: 0,
        },
      }}
      {...props}
    />
  );
}
