import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import { useState } from "react";
import {
  AutocompleteRenderGroupParams,
  ButtonBase,
  Collapse,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

export function TagPickerListCollapseGroup({
  total,
  label,
  ...props
}: AutocompleteRenderGroupParams & {
  label: string;
  total?: number;
}) {
  const { palette, transitions, mixins } = useTheme();
  const bgColor =
    palette.mode === "dark" ? palette.primary.dark : palette.primary.light;

  const [collapsed, setCollapsed] = useState(false);
  const count = React.Children.count(props.children);
  const more = total ? total - count : 0;

  return (
    <Stack {...props} direction="column">
      <ListSubheader
        disableGutters
        component={ButtonBase}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          width="100%"
          bgcolor={alpha(bgColor, 0.12)}
        >
          <ChevronRightIcon
            sx={{
              ml: 1,
              rotate: collapsed ? "+90deg" : "-90deg",
              transition: transitions.create(
                "rotate",
                collapsed
                  ? mixins.drawer.expanded.transition
                  : mixins.drawer.collapsed.transition
              ),
            }}
          />
          <Typography
            px={2}
            py={1}
            variant="subtitle2"
            textOverflow="ellipsis"
            align="left"
            flexGrow={1}
            color={palette.text.primary}
            fontWeight="400"
            fontSize={16}
          >
            {label}
          </Typography>

          {total && total > count ? (
            <Typography variant="subtitle2" mr={2}>
              {count} of {total}
            </Typography>
          ) : null}
        </Stack>
      </ListSubheader>

      <Collapse in={!collapsed}>
        <Stack direction="column" overflow="auto">
          {props.children}
          {more ? (
            <ListItem>
              <ListItemText
                secondary={
                  <span style={{ whiteSpace: "break-spaces" }}>
                    <span style={{ whiteSpace: "nowrap" }}>+{more} more.</span>
                    <br />
                    <span>Please refine your search.</span>
                  </span>
                }
              />
            </ListItem>
          ) : null}
        </Stack>
      </Collapse>
    </Stack>
  );
}
