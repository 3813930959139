import { ReactElement, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShareIcon from "@mui/icons-material/Share";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { IconButtonDropDown } from "src/components/IconButtonDropDown/IconButtonDropDown";
import { ShareReportDialog } from "src/components/ShareReportDialog/ShareReportDialog";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { reportDownloadRequest } from "src/api/useReportDownloadRequest";
import {
  useShareReportFormSnackbar,
  useShareReportLinkSnackbar,
} from "src/api/useShareReportSnackbar";
import {
  DownloadReportFormat,
  getDownloadTooltipTitle,
} from "src/api/useReportExport";
import { useTranslation } from "react-i18next";
import { ReportItem } from "src/models/Report";
import { useSessionContext } from "src/api/useSessionContext";
import { useOpenState } from "src/utils/useOpenState";
import { downloadWarningEventsLength } from "src/utils/reportLimits";
import { useEventsDownloadLimits } from "src/utils/useEventsDownloadLimits";
import { useIsMobile } from "src/utils/useIsMobile";
import { getReportId, getReportStatus } from "src/api/useReport.getters";
import { reportEditRoute } from "src/pages/ReportEdit/ReportEdit.route";
import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { ReportDeleteDialog } from "./ReportDeleteDialog";
import { DownloadMessageDialog } from "../DownloadMessageDialog";

export const ReportItemActions = ({ value }: { value: ReportItem }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const deleteDialog = useOpenState();
  const shareDialog = useOpenState();
  const downloadMessageDialog = useOpenState();
  const shareFormSnackBar = useShareReportFormSnackbar();
  const shareLinkSnackBar = useShareReportLinkSnackbar();

  const { effectiveEntitlements } = useSessionContext();
  const enableReportDownloads =
    effectiveEntitlements.enableReportDownloads?.value;

  const [downloadReportFormat, setDownloadReportFormat] =
    useState<DownloadReportFormat>(DownloadReportFormat.csv);

  const getEventsDownloadLimit = useEventsDownloadLimits();
  const pdfLimit = getEventsDownloadLimit(DownloadReportFormat.pdf) || 0;
  const pdfLimitExceeded = value.count > pdfLimit;
  const csvLimit = getEventsDownloadLimit(DownloadReportFormat.csv) || 0;
  const csvLimitExceeded = value.count > csvLimit;

  const navigate = useNavigate();

  const reportStatus = getReportStatus(value);
  const isDisabled = reportStatus !== "ready";

  const handleReportExport = useCallback(
    async (format?: DownloadReportFormat) => {
      const { status } = await reportDownloadRequest({
        format: format || downloadReportFormat,
        reportId: value.id,
      });

      if (status === 200) {
        enqueueSnackbar(
          <div>
            <Typography variant="body1" fontWeight={500}>
              {`${format?.toUpperCase()} is generating: '${value.title}'`}
            </Typography>
            <Typography variant="body2">
              You will receive email and system notification once it's ready
            </Typography>
          </div>,
          {
            variant: "info",
            autoHideDuration: 5000,
          }
        );
      }
    },
    [downloadReportFormat, value.id, value.title]
  );

  const exportReportItem = useCallback(
    (format: DownloadReportFormat) => {
      if (value.count > downloadWarningEventsLength) {
        setDownloadReportFormat(format);
        downloadMessageDialog.show();
        return;
      }
      handleReportExport(format);
    },
    [
      handleReportExport,
      value.count,
      downloadMessageDialog,
      setDownloadReportFormat,
    ]
  );

  const renderDownloadPdfWithTooltip = (menuItem: ReactElement) => {
    return isMobile ? (
      <TooltipBaseMobile
        width={249}
        title={
          enableReportDownloads
            ? getDownloadTooltipTitle(DownloadReportFormat.pdf, pdfLimit)
            : t("reportDownloadsDisabled")
        }
        placement="top"
        disableHoverListener={enableReportDownloads && !pdfLimitExceeded}
      >
        {menuItem}
      </TooltipBaseMobile>
    ) : (
      <TooltipBase
        width={249}
        textAlign="left"
        title={
          enableReportDownloads
            ? getDownloadTooltipTitle(DownloadReportFormat.pdf, pdfLimit)
            : t("reportDownloadsDisabled")
        }
        placement="left"
        disableHoverListener={enableReportDownloads && !pdfLimitExceeded}
      >
        <Box>{menuItem}</Box>
      </TooltipBase>
    );
  };

  const renderDownloadCsvWithTooltip = (menuItem: ReactElement) => {
    return isMobile ? (
      <TooltipBaseMobile
        width={249}
        title={
          enableReportDownloads
            ? getDownloadTooltipTitle(DownloadReportFormat.csv, csvLimit)
            : t("reportDownloadsDisabled")
        }
        placement="top"
        disableHoverListener={enableReportDownloads && !csvLimitExceeded}
      >
        {menuItem}
      </TooltipBaseMobile>
    ) : (
      <TooltipBase
        width={249}
        textAlign="left"
        title={
          enableReportDownloads
            ? getDownloadTooltipTitle(DownloadReportFormat.csv, csvLimit)
            : t("reportDownloadsDisabled")
        }
        placement="left"
        disableHoverListener={enableReportDownloads && !csvLimitExceeded}
      >
        <Box>{menuItem}</Box>
      </TooltipBase>
    );
  };

  return (
    <>
      <IconButtonDropDown
        disabled={isDisabled}
        renderDropDown={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                navigate(reportEditRoute.makeUrl({ reportId: value.id }, {}));
              }}
            >
              <ListItemIcon>
                <ModeEditIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                navigate(
                  publicReportRoute.makeUrl({
                    reportId: getReportId(value),
                  })
                );
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Instant link</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                shareDialog.show();
              }}
            >
              <ListItemIcon>
                <ShareIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                deleteDialog.show();
              }}
            >
              <ListItemIcon>
                <DeleteOutline fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            {renderDownloadPdfWithTooltip(
              <MenuItem
                disabled={!enableReportDownloads || pdfLimitExceeded}
                onClick={() => {
                  exportReportItem(DownloadReportFormat.pdf);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <DownloadPDF />
                </ListItemIcon>
                <ListItemText>Download PDF</ListItemText>
              </MenuItem>
            )}

            {renderDownloadCsvWithTooltip(
              <MenuItem
                disabled={!enableReportDownloads || csvLimitExceeded}
                onClick={() => {
                  exportReportItem(DownloadReportFormat.csv);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <DownloadCSV />
                </ListItemIcon>
                <ListItemText>Download CSV</ListItemText>
              </MenuItem>
            )}
          </Menu>
        )}
      >
        <MoreVertOutlined />
      </IconButtonDropDown>
      <ReportDeleteDialog
        open={deleteDialog.isOpen}
        values={[value]}
        onClose={deleteDialog.hide}
      />
      <ShareReportDialog
        reports={[value]}
        title="Share Report"
        open={shareDialog.isOpen}
        onClose={shareDialog.hide}
        onSubmit={shareFormSnackBar.show}
        copyShareLinkCallback={shareLinkSnackBar.show}
      />
      <DownloadMessageDialog
        open={downloadMessageDialog.isOpen}
        onClose={downloadMessageDialog.hide}
        handleReportExport={handleReportExport}
        format={downloadReportFormat}
      />
    </>
  );
};
