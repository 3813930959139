import { useMediaQuery, useTheme } from "@mui/material";
import { gridClasses } from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import { ReportsNoFilteredReportsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { makeReportsTableColumns } from "./ReportsTable.utils";
import { ReportsTableProps } from "./ReportsTable.model";
import { ReportsTableClasses } from "./ReportsTable.const";

export function ReportsTable({ ...props }: ReportsTableProps) {
  const { breakpoints, typography, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const columns = makeReportsTableColumns({
    palette,
  });

  return (
    <TableBase
      columns={columns}
      columnHeaderHeight={isMobile ? 42 : undefined}
      {...props}
      disableRowSelectionOnClick
      getRowId={(row) => row.id}
      slots={{
        noRowsOverlay: ReportsNoFilteredReportsOverlay,
        noResultsOverlay: ReportsNoFilteredReportsOverlay,
        ...props.slots,
      }}
      sx={{
        // cell styles, these override TableBase cell styles
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
        [`.${ReportsTableClasses.cellReportTitle}`]: {
          ...typography.body1,
          fontWeight: 600,
        },
      }}
    />
  );
}
