import { AppRouteBuilder } from "src/models/AppRoute";
import { DownloadReportFormat } from "src/api/useReportExport";
import { DownloadFilePage } from "./DownloadFile.page";

export type DownloadFilePathParams = {
  userId: string;
  id: string;
  type: DownloadReportFormat;
};

export type DownloadFileQueryParams = {
  name: string;
};
export const downloadFileRoute = new AppRouteBuilder<
  DownloadFilePathParams,
  DownloadFileQueryParams
>({
  path: "/download/:userId/:type/:id",
  element: <DownloadFilePage />,
});
