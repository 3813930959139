import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { QueryDefinition } from "src/models/QueryDefinition";

const apiKey: keyof paths = "/api/analytics/audience/composite-series";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type EndpointRes = Endpoint["responses"]["200"]["content"]["application/json"];

export function useAudienceAnalytics({
  queryIds,
  dateRange,
  filters,
  sortOrder = "asc",
}: {
  queryIds: string[];
  dateRange: [Date, Date];
  filters?: QueryDefinition;
  sortOrder?: "asc" | "desc";
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<EndpointRes>({
    queryKey: [apiKey, queryIds, dateRange, filters, sortOrder],
    keepPreviousData: true,
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      const body: RequestBody = {
        queryIdentifiers: queryIds,
        startDate: formatDateTimeWithoutTimeZone(dateRange[0]),
        endDate: formatDateTimeWithoutTimeZone(dateRange[1]),
        filters,
        sortOrder,
      };

      const url = makeApiUrl(apiKey);
      const { data } = await axios<EndpointRes>({
        url,
        method,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
  });
}
