import { IncludeExcludePair } from "src/models/IncludeExcludePair";

/** Type-Guard to check object to be IncludeExcludePair */
export const isIncludeExcludePair = (
  obj: unknown
): obj is IncludeExcludePair => {
  return (
    Array.isArray((obj as IncludeExcludePair)?.include) &&
    Array.isArray((obj as IncludeExcludePair)?.exclude)
  );
};
