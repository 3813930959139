import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { WatchQueryDialogActionsProps } from "./WatchQueryDialogActions.model";
import { LoadingButton } from "../buttons/LoadingButton";
import { Add, SearchOutlined } from "@mui/icons-material";

export function WatchQueryDialogActions({
  leftSide,
  isBusy,
  isValid,
  onClose,
  onFormClear,
  submitWatchQueryCreate,
  submitPowerSearch,
}: WatchQueryDialogActionsProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent={leftSide ? "space-between" : "end"}
    >
      {leftSide}

      <Stack
        direction="row"
        justifyContent={isMobile ? "justify-between" : undefined}
        width={isMobile ? "100%" : undefined}
        spacing={2}
        minHeight={42}
      >
        {onClose && (
          <Hidden lgDown>
            <Button
              sx={{ width: 100, display: { xs: "none", md: "flex" } }}
              onClick={onClose}
              color="error"
            >
              Cancel
            </Button>
          </Hidden>
        )}

        {onFormClear && (
          <Hidden smDown>
            <Button
              sx={{ width: 160, display: { xs: "none", md: "flex" } }}
              onClick={onFormClear}
            >
              Clear options
            </Button>
          </Hidden>
        )}

        <LoadingButton
          variant="contained"
          color="info"
          id="add-watch-term"
          loading={isBusy}
          disabled={isBusy || !isValid}
          startIcon={<Add />}
          onClick={submitWatchQueryCreate}
          sx={{
            minWidth: isMobile ? 160 : 176,
            width: isMobile ? "100%" : undefined,
            flex: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          Watch term
        </LoadingButton>

        <LoadingButton
          variant="contained"
          color="primary"
          loading={isBusy}
          disabled={isBusy || !isValid}
          startIcon={<SearchOutlined />}
          onClick={submitPowerSearch}
          sx={{
            minWidth: isMobile ? 160 : 176,
            width: isMobile ? "50%" : undefined,
          }}
        >
          Search
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
