import { useCallback, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { GridRowSelectionModel, gridClasses } from "@mui/x-data-grid-premium";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { TableBase } from "src/components/TableBase/TableBase";
import {
  EventsNoResultsOverlay,
  FilteredEventsNoResultsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { makePowerSearchResultTableColumns } from "./PowerSearchResultTable.utils";
import { PowerSearchResultTableProps } from "./PowerSearchResultTable.model";
import { PowerSearchResultTableClasses } from "./PowerSearchResultTable.const";

export function PowerSearchResultTable({
  addItemToReport,
  showDuplicatesDrawer,
  drawerParentEventId,
  hasFilters,
  ...props
}: PowerSearchResultTableProps) {
  const { palette, breakpoints, typography } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const {
    list,
    selectAll,
    unselectAll,
    areAllPagesSelected,
    setAllPagesSelected,
    excludeList,
    setExcludeList,
    rowSelectionModel,
    setRowSelectionModel,
  } = useEventSelectionPowerSearchResults();

  const onRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      if (newRowSelectionModel.length === 0) {
        setAllPagesSelected(false);
        unselectAll();
      }

      if (areAllPagesSelected) {
        const newExcludeEvents = props.rows.filter(
          (event) =>
            !newRowSelectionModel.includes(event.id) &&
            !excludeList.find((ev) => ev.id === event.id)
        );
        const excludeEvents = [
          ...excludeList.filter(
            (event) => !newRowSelectionModel.includes(event.id)
          ),
          ...newExcludeEvents,
        ];
        setExcludeList(excludeEvents);
      } else {
        const selectedEvents = newRowSelectionModel.map(
          (id) =>
            list.find((ev) => ev.id === id) ||
            props.rows.find((ev) => ev.id === id)
        );
        selectAll(selectedEvents as PowerSearchResultItem[]);
      }
      setRowSelectionModel(newRowSelectionModel);
    },
    [
      props.rows,
      areAllPagesSelected,
      excludeList,
      list,
      selectAll,
      setAllPagesSelected,
      setExcludeList,
      unselectAll,
      setRowSelectionModel,
    ]
  );

  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();
  const selectedDuplicatesIds = duplicatesDrawerEventsState.list.map(
    (ev) => ev.id
  );

  const columns = useMemo(
    () =>
      makePowerSearchResultTableColumns({
        highlightColor: palette.primary.main,
        addItemToReport,
        showDuplicatesDrawer,
        selectedDuplicatesIds,
      }),
    [palette, addItemToReport, showDuplicatesDrawer, selectedDuplicatesIds]
  );

  return (
    <TableBase
      columns={columns}
      {...props}
      slots={{
        noRowsOverlay: hasFilters
          ? FilteredEventsNoResultsOverlay
          : EventsNoResultsOverlay,
        noResultsOverlay: hasFilters
          ? FilteredEventsNoResultsOverlay
          : EventsNoResultsOverlay,
        ...props.slots,
      }}
      checkboxSelection
      disableRowSelectionOnClick
      pagination
      paginationMode="server"
      keepNonExistentRowsSelected
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={
        areAllPagesSelected
          ? props.rows
              .filter((ev) => !excludeList.find((event) => event.id === ev.id))
              .map((event) => event.id)
          : rowSelectionModel
      }
      getRowId={(row) => row.id}
      columnHeaderHeight={isMobile ? 40 : undefined}
      getRowClassName={(params) =>
        params.row.id === drawerParentEventId ? "row-highlighted" : ""
      }
      sx={{
        // cell styles, these override TableBase cell styles
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
        [`.${PowerSearchResultTableClasses.cellEvent}`]: {
          ...typography.body1,
        },
        [`.${PowerSearchResultTableClasses.cellEventTitle}`]: {
          ...typography.body1,
          fontWeight: 600,
        },
      }}
    />
  );
}
