import { create } from "zustand";
import { EventSourceOption } from "src/api/useEventSources";
import {
  Advertisement,
  queryAdvertisementValues,
} from "src/models/Advertisement";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";
import { ProgramTagPickerValue } from "src/components/ProgramTagPicker/ProgramPicker.model";

export type WatchTermSharedValues = {
  sourcesInclude: TagPickerValue<EventSourceOption>;
  sourcesExclude: TagPickerValue<EventSourceOption>;
  advertisement: Advertisement;
  programInclude: ProgramTagPickerValue;
  programExclude: ProgramTagPickerValue;
};

export const emptySharedValues = {
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type WatchTermSharedState = {
  customQueryFromKeyword: string;
  displayNameFromKeyword: string;
  saveCustomQueryFromKeyword: (customQuery: string) => void;
  saveDisplayNameFromKeyword: (displayName: string) => void;
  sharedValues: WatchTermSharedValues;
  saveSharedValue: <T extends keyof WatchTermSharedValues>(
    name: T,
    value: WatchTermSharedValues[T]
  ) => void;
  saveSharedValues: (values: WatchTermSharedValues) => void;
  clearSharedValues: () => void;
};

export const useWatchTermSharedForm = create<WatchTermSharedState>(
  (setState, getState) => ({
    customQueryFromKeyword: "",
    displayNameFromKeyword: "",
    saveCustomQueryFromKeyword: (customQuery) => {
      setState({
        customQueryFromKeyword: customQuery,
      });
    },
    saveDisplayNameFromKeyword: (displayName) => {
      setState({
        displayNameFromKeyword: displayName,
      });
    },
    sharedValues: emptySharedValues,
    saveSharedValue: (name, value) => {
      const prev = getState();
      setState({
        sharedValues: {
          ...prev.sharedValues,
          [name]: value,
        },
      });
    },
    saveSharedValues: (values) => {
      setState({
        sharedValues: values,
      });
    },
    clearSharedValues: () => {
      setState({
        sharedValues: emptySharedValues,
        customQueryFromKeyword: "",
      });
    },
  })
);
