import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortNewToOld = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
