import { Controller, useFormContext } from "react-hook-form";
import {
  Stack,
  Grid,
  Alert,
  alertClasses,
  formHelperTextClasses,
  Typography,
} from "@mui/material";
import { TextInputBase } from "src/components/TextInputBase";
import { DuplicateUserFormValues } from "./DuplicateUserForm.model";

export function DuplicateUserForm() {
  const { control } = useFormContext<DuplicateUserFormValues>();

  return (
    <Stack>
      <Grid
        container
        spacing={1.5}
        sx={{
          pt: 0,
          [`.${formHelperTextClasses.root}`]: {
            ml: 0,
          },
        }}
      >
        <Grid item xs={12} pb={1.5}>
          <Typography variant="body1">
            Do you want to duplicate user to “Google” account? Please enter the
            information about new user.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  id="firstName-input"
                  label="First name"
                  fullWidth
                  placeholder="First name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  id="lastName-input"
                  label="Last name"
                  fullWidth
                  placeholder="Last name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  id="email-input"
                  label="Email"
                  fullWidth
                  placeholder="Email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Alert
        severity="info"
        sx={{
          mt: 1,
          px: 1,
          [`.${alertClasses.icon}`]: {
            mr: 1,
          },
        }}
      >
        You will duplicate watch terms, user role, search presets and filters
        options for a new user.
      </Alert>
    </Stack>
  );
}
