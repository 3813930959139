import { create } from "zustand";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { PowerSearchResultItem } from "./usePowerSearch";

type SelectionState<
  T extends {
    id: string;
  }
> = {
  list: T[];
  excludeList: PowerSearchResultItem[];
  areAllPagesSelected: boolean;
  setAllPagesSelected: (value: boolean) => void;
  setExcludeList: (excludeList: PowerSearchResultItem[]) => void;
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: (value: GridRowSelectionModel) => void;
  select: (event: T) => void;
  selectAll: (events: T[]) => void;
  unselect: (event: T) => void;
  unselectAll: () => void;
};

export const useEventSelectionPowerSearchResults = create<
  SelectionState<PowerSearchResultItem>
>((setState) => ({
  list: [],
  excludeList: [],
  areAllPagesSelected: false,
  setAllPagesSelected: (value: boolean) => {
    setState({
      areAllPagesSelected: value,
      excludeList: [],
      list: [],
    });
  },
  setExcludeList: (excludeList: PowerSearchResultItem[]) => {
    setState({
      excludeList,
    });
  },
  rowSelectionModel: [],
  setRowSelectionModel: (value: GridRowSelectionModel) => {
    setState({
      rowSelectionModel: value,
    });
  },
  select: (event: PowerSearchResultItem) => {
    setState((state) => ({
      list: [...state.list, event],
      excludeList: state.areAllPagesSelected
        ? state.excludeList.filter((ev) => event.id !== ev.id)
        : state.excludeList,
    }));
  },
  selectAll: (events: PowerSearchResultItem[]) => {
    const selectedEventsIds = events.map((event) => event.id);
    setState((state) => ({
      list: events,
      excludeList: state.areAllPagesSelected
        ? state.excludeList.filter((ev) => !selectedEventsIds.includes(ev.id))
        : state.excludeList,
    }));
  },
  unselect: (event: PowerSearchResultItem) => {
    setState((state) => ({
      list: state.list.filter((stateEvent) => stateEvent.id !== event.id),
      excludeList: state.areAllPagesSelected
        ? [...state.excludeList, event]
        : state.excludeList,
    }));
  },
  unselectAll: () => {
    setState({
      list: [],
      areAllPagesSelected: false,
      excludeList: [],
      rowSelectionModel: [],
    });
  },
}));
