import { useMemo } from "react";
import { ArrayParam, useQueryParam, withDefault } from "use-query-params";

/** Get a typed value list from a query string */
export const useQueryParamList = ({ key }: { key: string }) => {
  const StringListParam = withDefault(ArrayParam, []);
  const [queryRawIds, setQueryIds] = useQueryParam(key, StringListParam);
  const queryIds = queryRawIds.filter((v) => typeof v === "string") as string[];
  return useMemo(
    () => [queryIds, setQueryIds] as const,
    [queryIds, setQueryIds]
  );
};
