import { AccountsManagementFilterFormValues } from "src/pages/AccountsManagement/components/AccountsManagementFilterForm/AccountsManagementFilterForm.model";

export function countAccountsManagementFilters(
  data?: AccountsManagementFilterFormValues
): number | undefined {
  const {
    expirationDateMaximum,
    expirationDateMinimum,
    orgTypes,
    states,
    scoreMaximum,
    scoreMinimum,
    accountManager,
    status,
  } = data || {};

  const totalFilters = [
    accountManager?.length,
    orgTypes?.length,
    states?.length,
    status?.length,
    expirationDateMinimum !== null ? 1 : 0,
    expirationDateMaximum !== null ? 1 : 0,
    scoreMaximum && scoreMaximum < 100 ? 1 : 0,
    scoreMinimum && scoreMinimum > 0 ? 1 : 0,
  ].reduce((acc = 0, filterCount = 0) => {
    acc += filterCount ? 1 : 0;
    return acc;
  }, 0);

  return totalFilters;
}
