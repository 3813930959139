import { Box } from "@mui/material";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useMediaCenterDelete } from "src/api/useMediaCenterDelete";
import { useMediaCenterDeleteItem } from "src/api/useMediaCenterDeleteItem";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type MediaCenterDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  value: MediaCenterItem | Array<string>;
};

function getContentText(value: MediaCenterItem | Array<string>) {
  if (Array.isArray(value)) {
    return `Do you want to delete ${value.length} selected clips?`;
  }

  return `Do you want to delete the “${value.title}” clip?`;
}

function getTitle(value: MediaCenterItem | Array<string>) {
  if (Array.isArray(value)) {
    return "Delete clips?";
  }

  return "Delete clip?";
}

export const MediaCenterDeleteDialog = ({
  onClose,
  value,
  ...props
}: MediaCenterDeleteDialogProps) => {
  const clipDelete = useMediaCenterDeleteItem({
    options: {
      onSettled: onClose,
    },
  });

  const severalClipsDelete = useMediaCenterDelete({
    options: {
      onSettled: onClose,
    },
  });

  const handleDelete = () => {
    if (Array.isArray(value)) {
      return severalClipsDelete.mutate({
        events: value,
      });
    }

    clipDelete.mutate(value);
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      title={getTitle(value)}
      children={<Box pb={1}>{getContentText(value)}</Box>}
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      {...props}
    />
  );
};
