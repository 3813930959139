import {
  Grid,
  Paper,
  PaperProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

export function DevPaper() {
  const { palette } = useTheme();
  const elevations = Array.from({ length: 25 }, (_, index) => {
    return index;
  });

  const renderItem = (elevation: number, variant: PaperProps["variant"]) => (
    <Grid item xs={3} key={`${elevation}:${variant}`}>
      <Paper
        sx={{ width: "100%", aspectRatio: 1, display: "flex" }}
        elevation={elevation}
        variant={variant}
      >
        <Typography m="auto" color={palette.text.disabled} textAlign="center">
          {variant === "outlined" ? "outlined" : `Elevation: ${elevation}`}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Stack sx={{ background: palette.background.default }} pt={2} px={4} pb={4}>
      <Typography variant="h6">Paper</Typography>
      <Grid container spacing={4} width="fit-content">
        {renderItem(0, "outlined")}
        {elevations.map((elevation) => renderItem(elevation, "elevation"))}
      </Grid>
    </Stack>
  );
}
