type InstantAlertLocalStorage = {
  date: string;
};

export function getSkippedInstantAlert() {
  const now = new Date().getTime();

  const saved = localStorage.getItem("instantAlertMeta") || "{}";
  const initialValue: InstantAlertLocalStorage = JSON.parse(saved);
  return initialValue.date && now < Number(initialValue.date);
}

export function setSkippedInstantAlert() {
  const now = new Date().getTime();

  const oneMonth = 1000 * 60 * 60 * 24 * 30;
  const requiredDate = now + oneMonth;
  localStorage.setItem(
    "instantAlertMeta",
    JSON.stringify({ date: requiredDate })
  );
}
