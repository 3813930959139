import { PauseCircle, PlayCircle } from "@mui/icons-material";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";

export const ClipEditorPreviewButton = ({
  label,
  isMobile,
  isPreviewing,
  isDisabled,
  onClick,
}: {
  label?: string;
  isMobile?: boolean;
  isPreviewing?: boolean;
  isDisabled?: ButtonProps["disabled"];
  onClick: ButtonProps["onClick"];
}) => {
  const icon = isPreviewing ? <PauseCircle /> : <PlayCircle />;

  if (!label) {
    return (
      <IconButton
        color="primary"
        disabled={isDisabled}
        children={icon}
        onClick={onClick}
      />
    );
  }

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={isDisabled}
      startIcon={icon}
      onClick={onClick}
      sx={{ height: isMobile ? 42 : undefined }}
      children={<TextLineClamp lineClamp={1}>{label}</TextLineClamp>}
    />
  );
};
