import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { UserInformationUpdateFormValues } from "./UserInformationUpdateForm.model";

const emptyFormValues: UserInformationUpdateFormValues = {
  timezone: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  isActive: false,
  notify: false,
  accountId: "",
  roles: ["User"],
  createdDate: "",
  expirationDate: "",
};

type UseUserInformationFormArgs = {
  initial?: UserInformationUpdateFormValues;
  schema: Joi.Schema;
};

export function useUserInformationForm(params?: UseUserInformationFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<UserInformationUpdateFormValues>({
    mode: "all",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: initial || emptyFormValues,
  });

  return formHook;
}
