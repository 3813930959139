import {
  Alert,
  alertClasses,
  AlertTitle,
  alpha,
  Button,
  Snackbar,
  useTheme,
} from "@mui/material";
import { addMinutes, isEqual, max, min } from "date-fns";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { useVideoEditingLimitationsSnackBar } from "src/api/useVideoEditingLimitationsSnackBar";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { calcDifferenceInSeconds } from "src/components/ClipEditorPage/ClipEditorPage.utils";
import { UseTrimRangeValue } from "src/components/ClipEditorPage/hook/useTrimRangeState";
import { EventDetails } from "src/models/EventDetails";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";

export function ShiftPlayerSetStartEndButtons({
  value,
  event,
  onShiftPlayerPlay,
  handleSetStart,
  handleSetEnd,
  trimRange,
}: {
  value: DateTimeSearchResultItem;
  event: EventDetails;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  handleSetStart: (t0?: Date, force?: boolean, t1?: Date | null) => void;
  handleSetEnd: (t1?: Date, force?: boolean, t0?: Date | null) => void;
  trimRange?: UseTrimRangeValue;
}) {
  const { palette } = useTheme();
  const videoEditingLimitationsSnackBar = useVideoEditingLimitationsSnackBar();

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          if (!value.blockStartTime || !event.startDateTime) return;
          if (!value.blockEndTime || !event.endDateTime) return;

          const outputData: ShiftPlayerDataProps = {
            startDateTime: value.blockStartTime,
          };

          const minStartDate = min([
            new Date(value.blockStartTime),
            new Date(event.startDateTime),
          ]);
          const maxDateValue = max([
            new Date(value.blockStartTime),
            new Date(event.endDateTime),
          ]);

          if (isEqual(minStartDate, new Date(value.blockStartTime))) {
            const durationInSeconds = calcDifferenceInSeconds(
              event.endDateTime,
              value.blockStartTime
            );
            const isDurationLessTenMinutes = durationInSeconds < 60 * 10;
            if (!isDurationLessTenMinutes) {
              return videoEditingLimitationsSnackBar.show();
            }

            outputData.endDateTime = event.endDateTime;

            onShiftPlayerPlay && onShiftPlayerPlay(outputData);
            return handleSetStart(
              value.blockStartTime ? new Date(value.blockStartTime) : undefined,
              true,
              trimRange ? trimRange[1] : undefined
            );
          }

          if (isEqual(maxDateValue, new Date(value.blockStartTime))) {
            outputData.endDateTime = value.blockEndTime;

            onShiftPlayerPlay && onShiftPlayerPlay(outputData);
            return handleSetStart(
              value.blockStartTime ? new Date(value.blockStartTime) : undefined,
              true
            );
          }

          handleSetStart(
            value.blockStartTime ? new Date(value.blockStartTime) : undefined
          );
        }}
        sx={{
          color: palette.success.main,
          borderColor: alpha(palette.success.main, 0.5),
        }}
      >
        Set start
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          if (!value.blockStartTime || !event.startDateTime) return;
          if (!event.endDateTime) return;

          const outputData: ShiftPlayerDataProps = {
            endDateTime: value.blockStartTime,
          };

          const minDateValue = min([
            new Date(value.blockStartTime),
            new Date(event.startDateTime),
          ]);
          const maxEndDate = max([
            new Date(value.blockStartTime),
            new Date(event.endDateTime),
          ]);

          if (isEqual(minDateValue, new Date(value.blockStartTime))) {
            outputData.startDateTime = formatDateTimeWithoutTimeZone(
              addMinutes(new Date(value.blockStartTime), -10)
            );

            onShiftPlayerPlay && onShiftPlayerPlay(outputData);
            return handleSetEnd(
              value.blockStartTime ? new Date(value.blockStartTime) : undefined,
              true
            );
          }

          if (isEqual(maxEndDate, new Date(value.blockStartTime))) {
            const durationInSeconds = calcDifferenceInSeconds(
              value.blockStartTime,
              event.startDateTime
            );
            const isDurationLessTenMinutes = durationInSeconds < 60 * 10;
            if (!isDurationLessTenMinutes)
              return videoEditingLimitationsSnackBar.show();

            outputData.startDateTime = event.startDateTime;

            onShiftPlayerPlay && onShiftPlayerPlay(outputData);
            return handleSetEnd(
              value.blockStartTime ? new Date(value.blockStartTime) : undefined,
              true,
              trimRange ? trimRange[0] : undefined
            );
          }

          handleSetEnd(
            value.blockStartTime ? new Date(value.blockStartTime) : undefined
          );
        }}
        sx={{
          color: palette.error.main,
          borderColor: alpha(palette.error.main, 0.5),
        }}
      >
        Set end
      </Button>
      <Snackbar
        open={videoEditingLimitationsSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={videoEditingLimitationsSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          variant="filled"
          onClose={videoEditingLimitationsSnackBar.hide}
          sx={{
            borderRadius: 1,
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          <AlertTitle>System limit</AlertTitle>
          The edited clip must be shorter than 10 minutes. You can edit the
          video within the limits.
        </Alert>
      </Snackbar>
    </>
  );
}
