import { create } from "zustand";

type SnackbarState = {
  isOpen: boolean;
  show: () => void;
  hide: () => void;
};

export const useShareMediaFormSnackbar = create<SnackbarState>((setState) => ({
  isOpen: false,
  show: () => {
    setState(() => ({
      isOpen: true,
    }));
  },
  hide: () => {
    setState(() => ({
      isOpen: false,
    }));
  },
}));

export const useShareMediaLinkSnackbar = create<SnackbarState>((setState) => ({
  isOpen: false,
  show: () => {
    setState(() => ({
      isOpen: true,
    }));
  },
  hide: () => {
    setState(() => ({
      isOpen: false,
    }));
  },
}));
