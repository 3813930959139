import { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export function ReportPreviewAccordion({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  const { palette } = useTheme();

  return (
    <Accordion
      sx={{
        backgroundColor: alpha(palette.primary.light, 0.04),
        borderRadius: 1,
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{ borderBottom: `1px solid ${palette.divider}` }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
