import { DateTimeParam, UrlUpdateType, useQueryParams } from "use-query-params";
import { useDebouncedCallback } from "use-debounce";
import { DateRange } from "src/components/DateSelector/DateSelector";

type UseQueryDateRangeResult = [
  [Date | null, Date | null],
  (dateRange: DateRange) => void
];

type UseQueryDateRangeArgs = {
  updateType?: UrlUpdateType;
};

export function useQueryDateRangeDialogWithEmptyDefault(
  args?: UseQueryDateRangeArgs
): UseQueryDateRangeResult {
  const { updateType = "replaceIn" } = args || {};

  const [{ startDateDialog, endDateDialog }, setQueryParams] = useQueryParams({
    startDateDialog: DateTimeParam,
    endDateDialog: DateTimeParam,
  });

  const nextDateRange: [Date | null, Date | null] = [
    startDateDialog || null,
    endDateDialog || null,
  ];

  const setDateRange = useDebouncedCallback((dateRange: DateRange) => {
    setQueryParams(
      { startDateDialog: dateRange[0], endDateDialog: dateRange[1] },
      updateType
    );
  }, 300);

  return [nextDateRange, setDateRange];
}
