import { useState, useCallback, ChangeEvent, useMemo } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { BooleanParam, useQueryParam } from "use-query-params";
import { UseInfiniteQueryResult } from "react-query";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SearchInput } from "src/components/SearchInput";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { PreselectedEventsEmpty } from "src/components/PreselectedEventsEmpty/PreselectedEventsEmpty";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useListFilter } from "src/utils/useListFilter";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useReportScratchEvents } from "src/api/useReportScratchEvents";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import { DeleteIconButton } from "src/components/DeleteIconButton/DeleteIconButton";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import {
  SortBy,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import { SelectAllPages } from "src/components/SelectAllPages/SelectAllPages";
import { usePreselectedEventsDataView } from "./PreselectedEvents.hook";
import { PreselectedEventsDeleteDialog } from "./PreselectedEventsDeleteDialog";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function PreselectedEventsTab({
  isLoading,
  events,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  sortOption,
  onSortChange,
  dateRange,
  setDateRange,
  reportScratchEventsTiles,
}: {
  sortOption: SortBy;
  onSortChange: (sortBy: SortOption) => void;
  isLoading: boolean;
  events: PowerSearchResultItem[];
  rowCount: number;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  reportScratchEventsTiles: UseInfiniteQueryResult;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);
  const [filterText, setFilterText] = useState("");
  const deletePreselectedEventsDialog = useOpenState();
  const [pagination] = useQueryPagination();

  const preselectedEventsWithoutFilter = useReportScratchEvents({
    request: {
      query: {
        from: 0,
        size: 1,
      },
    },
  });
  const eventsSelectedForReports = useEventsSelectedForReports();

  const deleteIconButton = useMemo(
    () => (
      <DeleteIconButton
        onClick={deletePreselectedEventsDialog.show}
        size={isMobile ? "small" : "medium"}
        disabled={
          !eventsSelectedForReports.events.length &&
          !eventsSelectedForReports.areAllPagesSelected
        }
      />
    ),
    [
      deletePreselectedEventsDialog.show,
      eventsSelectedForReports.events.length,
      eventsSelectedForReports.areAllPagesSelected,
      isMobile,
    ]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [onSortChange, sortOption]
  );

  const onChangeViewMode = useCallback(
    (nextMode: ViewMode) => {
      if (viewMode !== nextMode) {
        eventsSelectedForReports.unselectAll();
      }
      setViewMode(nextMode);
    },
    [viewMode, setViewMode, eventsSelectedForReports]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={onChangeViewMode}
        size={isMobile ? "small" : "medium"}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      />
    ),
    [isMobile, onChangeViewMode, viewMode]
  );

  const filterData = useListFilter(events, filterText, ["title", "highlights"]);

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: eventsSelectedForReports.events,
    areAllPagesSelected: eventsSelectedForReports.areAllPagesSelected,
    excludeEventsLength: eventsSelectedForReports.excludeEvents?.length,
  });

  const hasMorePages = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      return rowCount > filterData.length;
    }
    return rowCount / pagination.pageSize > 1;
  }, [viewMode, rowCount, filterData.length, pagination.pageSize]);

  const selectAllPagesIsShown = isAllSelected && hasMorePages;

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        eventsSelectedForReports.selectEvents(filterData);
      } else {
        eventsSelectedForReports.unselectAll();
      }
    },
    [filterData, eventsSelectedForReports]
  );

  const renderAllCheckboxSelector = useCallback(
    ({ hideLabel }: { hideLabel?: boolean }) => {
      if ([ViewMode.tile, ViewMode.list].includes(viewMode)) {
        return (
          <AllCheckboxSelector
            checked={isAllSelected || isPartiallySelected}
            isPartiallySelected={isPartiallySelected}
            onChange={handleChangeAllCheckbox}
            sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
            hideLabel={hideLabel}
          />
        );
      }
      return null;
    },
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
      />
    ),
    [isMobile]
  );

  const toolbarDesktop = useMemo(() => {
    if (selectAllPagesIsShown) return null;
    return (
      <Stack
        p={2}
        gap={2}
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row" flex={1}>
          {renderAllCheckboxSelector({})}
          <Stack
            sx={{
              justifyContent: "flex-end",
              mr: 2,
            }}
          >
            {searchInput}
          </Stack>
          <DateTimeSelector
            mode={DateTimeSelectorMode.variable}
            views={["year", "month", "day", "hours", "minutes"]}
            value={dateRange}
            onChange={setDateRange}
            sx={{ flex: 1 }}
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {deleteIconButton}
          {sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    );
  }, [
    renderAllCheckboxSelector,
    dateRange,
    deleteIconButton,
    searchInput,
    setDateRange,
    sortingMenu,
    viewModeSwitch,
    selectAllPagesIsShown,
  ]);

  const toolbarMobile = useMemo(
    () => (
      <Stack>
        <Stack sx={{ m: 2 }}>{searchInput}</Stack>
        {!selectAllPagesIsShown && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ mx: 2, mb: 2 }}
          >
            <Box>{renderAllCheckboxSelector({})}</Box>
            <Stack flexDirection="row" gap={1}>
              {deleteIconButton}
              <DateTimeRangePickerMobile
                value={dateRange}
                onChange={setDateRange}
                iconButtonSize="small"
              />
              {sortingMenu}
              {viewModeSwitch}
            </Stack>
          </Stack>
        )}
      </Stack>
    ),
    [
      renderAllCheckboxSelector,
      dateRange,
      deleteIconButton,
      searchInput,
      setDateRange,
      sortingMenu,
      viewModeSwitch,
      selectAllPagesIsShown,
    ]
  );

  const renderHeader = useCallback(() => {
    return isMobile ? toolbarMobile : toolbarDesktop;
  }, [isMobile, toolbarDesktop, toolbarMobile]);

  const renderSelectAll = useCallback(() => {
    if (!selectAllPagesIsShown) return null;
    return (
      <SelectAllPages
        rowCount={rowCount}
        selectedCount={
          eventsSelectedForReports.areAllPagesSelected
            ? rowCount
            : eventsSelectedForReports.events.length
        }
        areAllPagesSelected={eventsSelectedForReports.areAllPagesSelected}
        setAllPagesSelected={eventsSelectedForReports.setAllPagesSelected}
        unselectAll={eventsSelectedForReports.unselectAll}
        allCheckboxSelector={renderAllCheckboxSelector({ hideLabel: true })}
        deleteIconProps={{
          handleDelete: deletePreselectedEventsDialog.show,
        }}
      />
    );
  }, [
    selectAllPagesIsShown,
    rowCount,
    eventsSelectedForReports,
    renderAllCheckboxSelector,
    deletePreselectedEventsDialog.show,
  ]);

  const dataViewElement = usePreselectedEventsDataView({
    viewMode,
    isExpandedTextMode,
    isLoading,
    data: filterData,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    renderHeader,
    renderSelectAll,
    reportScratchEventsTiles,
  });

  if (
    !isLoading &&
    !preselectedEventsWithoutFilter.isLoading &&
    !preselectedEventsWithoutFilter.data?.total
  ) {
    return <PreselectedEventsEmpty />;
  }

  return (
    <Stack flex={1} overflow="hidden">
      {dataViewElement}
      <PreselectedEventsDeleteDialog
        open={deletePreselectedEventsDialog.isOpen}
        onClose={deletePreselectedEventsDialog.hide}
        events={eventsSelectedForReports.events}
      />
    </Stack>
  );
}
