import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useUserClone } from "src/api/useUserClone";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { DuplicateUserForm } from "../DuplicateUserForm/DuplicateUserForm";
import { DuplicateUserFormValues } from "../DuplicateUserForm/DuplicateUserForm.model";
import { UserManagementDuplicateForm } from "../DuplicateUserForm/UserManagementDuplicateForm.hook";

type DuplicateUserDialogProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  userData: DuplicateUserFormValues | null;
};

const defaultValues = {
  email: "",
  firstName: "",
  secondName: "",
  roles: [],
};

export const DuplicateUserDialog = ({
  onClose,
  userData,
  ...props
}: DuplicateUserDialogProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [notifyUser, setNotifyUser] = useState(false);
  const handleNotifyUser = (_event: SyntheticEvent, checked: boolean) => {
    setNotifyUser(checked);
  };

  const formHook = UserManagementDuplicateForm();
  const { reset, formState, handleSubmit } = formHook;

  const userClone = useUserClone({});

  const handleSubmitWrapper = handleSubmit((data) => {
    onClose && onClose();

    const params = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      notify: notifyUser,
    };
    userClone.mutate(params);
  });

  useEffect(() => {
    reset(userData || defaultValues);
  }, [reset, userData]);

  return (
    <FormProvider {...formHook}>
      <DialogBase
        maxWidth="sm"
        extraMobileMargin={3}
        disableHeaderDivider
        {...props}
        children={<DuplicateUserForm />}
        footer={
          <Fragment>
            <FormGroup sx={{ flexDirection: "row" }}>
              <FormControlLabel
                value={notifyUser}
                onChange={handleNotifyUser}
                control={<Checkbox />}
                label="Notify user"
              />
            </FormGroup>
            <Stack
              direction="row"
              justifyContent={isMobile ? "space-between" : "flex-end"}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  width: isMobile ? 132 : 84,
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{
                  width: isMobile ? 132 : 84,
                  ml: isMobile ? 0 : 1,
                }}
                disabled={!formState.isValid}
                onClick={handleSubmitWrapper}
              >
                Ok
              </LoadingButton>
            </Stack>
          </Fragment>
        }
      />
    </FormProvider>
  );
};
