import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useInfiniteQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths, components } from "@tveyes/twosionwebapischema";
import { SourcesPickerValue } from "src/components/SourcesPicker/SourcesPicker.model";
import { makeSourceFilterQuery } from "src/utils/makeSourceFilterQuery";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedSnapshotResultsTotal } from "src/utils/countPagedResultTotal";

const apiKey: keyof paths = "/api/snapshot";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export type MarketSnapshot = components["schemas"]["MarketSnapshot"];
export type StationSnapshot = components["schemas"]["StationSnapshot"];
export type MarketSnapshotPagedResultSet = Response;

type RequestParams = {
  body: SourcesPickerValue;
  query: Endpoint["parameters"]["query"];
};

const pageSize = 30;

export function useSnapshotInfinite({
  params,
}: {
  params: RequestParams;
  options: UseQueryOptions<MarketSnapshotPagedResultSet, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useInfiniteQuery<MarketSnapshotPagedResultSet, unknown>({
    queryKey: [apiKey, params],
    keepPreviousData: true,
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;
      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl(apiKey, {
        query: {
          from,
          size,
          sortby: params?.query?.sortby,
          orderby: params?.query?.orderby,
        },
      });

      const body = makeSourceFilterQuery(params.body);

      const { data } = await axios<MarketSnapshotPagedResultSet>(apiPath, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });
      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedSnapshotResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        const fromTotal = from + pageSize;
        return {
          from: fromTotal,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
