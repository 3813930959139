import { create } from "zustand";

type SnackbarState = {
  isOpen: boolean;
  reportTitle: string;
  show: (reportTitle: string) => void;
  hide: () => void;
};

export const useReportDeleteSnackbar = create<SnackbarState>((setState) => ({
  isOpen: false,
  reportTitle: "",
  show: (reportTitle) => {
    setState(() => ({
      isOpen: true,
      reportTitle,
    }));
  },
  hide: () => {
    setState(() => ({
      isOpen: false,
      reportTitle: "",
    }));
  },
}));
