import axios from "axios";
import { paths } from "@tveyes/twosionwebapischema";
import { useAuth0 } from "@auth0/auth0-react";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { userManagementKey } from "./useUsersManagement";

const apiKey: keyof paths = "/api/User/{id}/clone";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"] & {
    id: Endpoint["parameters"]["path"]["id"];
  };

export type UserDataResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];

type RequestOptions = Omit<
  UseMutationOptions<UserDataResponse, unknown, RequestBody>,
  "mutationFn"
>;

export const userCloneKey = "userCloneKey";

export function useUserClone({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<UserDataResponse, unknown, RequestBody>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ id, ...requestBody }) => {
      const url = makeApiUrl(apiKey, {
        path: {
          id,
        },
      });

      const token = await getAccessTokenSilently();

      const { data } = await axios<UserDataResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: requestBody,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        `User "${data.firstName} ${data.lastName}" was successfully added to "${data.company}" account`,
        {
          variant: "success",
        }
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: userManagementKey,
      });

      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
