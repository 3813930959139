import "./i18n";
import "./index.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

// This polyfill is required on iPhone as iOS did not support fullscreen API
// https://caniuse.com/mdn-api_document_exitfullscreen
import "fullscreen-api-polyfill";

import CssBaseline from "@mui/material/CssBaseline";

import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "react-query";
import { AppLocalizationProvider } from "./providers/AppLocalizationProvider";
import { SessionProvider } from "./api/useSessionContext";
import { Auth0ProviderWithConfig } from "./providers/Auth0ProviderWithConfig";
import { AppThemeProvider } from "./providers/AppThemeProvider";
import { DebugOverlay } from "./components/DebugOverlay";
import { AppSnackbarProvider } from "./providers/AppSnackbarProvider";
import { IntercomProvider } from "./providers/IntercomProvider";
import { PushNotificationProvider } from "./providers/PushNotificationProvider/PushNotificationProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <CssBaseline enableColorScheme />
      <AppLocalizationProvider>
        <Auth0ProviderWithConfig>
          <QueryClientProvider client={queryClient}>
            <PushNotificationProvider>
              <SessionProvider>
                <DebugOverlay />
                <IntercomProvider />
                <AppSnackbarProvider />
              </SessionProvider>
            </PushNotificationProvider>
          </QueryClientProvider>
        </Auth0ProviderWithConfig>
      </AppLocalizationProvider>
    </AppThemeProvider>
  </React.StrictMode>
);
