import { useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";
import { parseISO } from "date-fns";
import { TextInputBase } from "src/components/TextInputBase";
import { useUsersAutocomplete } from "src/api/useUsersAutocomplete";
import { AccountsManagementConvertFormValues } from "./AccountsManagementConvertForm.model";
import { AccountsManagementConfigurationDropdown } from "../AccountsManagementConfigurationDropdown/AccountsManagementConfigurationDropdown";
import { CurrencyInput } from "../CurrencyInput/CurrencyInput";

export function AccountsManagementConvertForm({
  accountId,
  accountManager,
  accountManagerEmail,
}: {
  accountId: string;
  accountManager?: string | null;
  accountManagerEmail?: string | null;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [count, setCount] = useState(200);

  const { control, setValue } =
    useFormContext<AccountsManagementConvertFormValues>();

  const handleTextaAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.length;
    if (value === 201) return;
    setValue("note", e.target.value, { shouldDirty: true });
    setCount(200 - value);
  };
  //Get total users value for account users request
  const { data: accountUsers } = useUsersAutocomplete({
    request: {
      params: {
        query: {
          from: 0,
          size: 1,
        },
      },
      body: {
        accountIds: [accountId],
      },
    },
  });
  //Account users for dropdown
  const { data: allAccountUsers } = useUsersAutocomplete({
    request: {
      params: {
        query: {
          from: 0,
          size: accountUsers?.total,
        },
      },
      body: {
        accountIds: [accountId],
      },
    },
  });

  return (
    <Stack ref={containerRef}>
      <Grid container spacing={1} py={2}>
        <Grid item xs={12} md={6} py={2}>
          <Controller
            name="status"
            control={control}
            render={({ field }) => {
              return <AccountsManagementConfigurationDropdown />;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} py={2}>
          <Controller
            name="expiration"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  label="Expiration date"
                  value={
                    typeof field.value === "string"
                      ? parseISO(field.value ?? "")
                      : field.value
                  }
                  onChange={field.onChange}
                  format="MMM dd ,yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: "outlined",
                      autoComplete: "off",
                      error: !!fieldState.error,
                      onBlur: field.onBlur,
                    },
                  }}
                  slots={{
                    textField: TextInputBase,
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} py={2}>
          <Controller
            name="seatCount"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  label="User seats"
                  size="medium"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} py={2}>
          <Controller
            name="annualizedRecurringRevenue"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Tooltip
                  title={
                    "How much does the user spend per year. (If this is a short-term account adjust for 12 month term.)"
                  }
                >
                  <Stack>
                    <CurrencyInput
                      label="Annualized Revenue"
                      value={field.value}
                      onInputChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message || " "}
                      fullWidth
                    />
                  </Stack>
                </Tooltip>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} py={2}>
          <Controller
            name="accountManager"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Account Manager</InputLabel>
                <Select
                  {...field}
                  id="accountManager"
                  label="Account Manager"
                  value={field.value || ""}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  onBlur={field.onBlur}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value={accountManager || ""}>
                    {accountManagerEmail}
                  </MenuItem>
                  {allAccountUsers?.results?.map(
                    (user) =>
                      user.id !== accountManager && (
                        <MenuItem key={user.id} value={user.id}>
                          {user.email}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} py={2}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Leave you comments, Order ID or any Special requests or notes"
                {...field}
                id="outlined-multiline-static"
                multiline
                onChange={handleTextaAreaChange}
                rows={4}
              />
            )}
          />
          <Typography variant="body2" color={"text.secondary"} mt={0.5}>
            {count}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
}
