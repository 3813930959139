import { create } from "zustand";

export type ReportEventSelectionState<T> = {
  activeSelector: T;
  select: (event: T) => void;
};

export const useOpenedSelector = create<
  ReportEventSelectionState<string | null>
>((setState) => ({
  activeSelector: null,
  select: (selector) => {
    setState((state) => {
      if (state.activeSelector !== selector) {
        return { activeSelector: selector };
      }
      return state;
    });
  },
}));
