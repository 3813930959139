import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDateTimeSearchDetails } from "src/api/useDateTimeSearchDetails";
import { useEventByStationAndDate } from "src/api/useEventByStationAndDate";
import { ClipViewerPage } from "src/components/ClipViewerPage/ClipViewerPage";
import { dateTimeSearchClipEditorRoute } from "../DateTimeSearchClipEditor/DateTimeSearchClipEditor.route";
import { ShiftPlayerDataProps } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";

export const DateTimeSearchClipPage = () => {
  const navigate = useNavigate();

  const [dateTime, setDateTime] = useState<undefined | ShiftPlayerDataProps>(
    undefined
  );

  const details = useDateTimeSearchDetails({});

  const dateTimeEventDetails = useEventByStationAndDate({
    request: {
      query: {
        stationUUID: details.data?.sourceId ?? "",
        startDateTime: dateTime?.startDateTime ?? "",
        endDateTime: dateTime?.endDateTime ?? "",
      },
    },
    body: {},
    options: {
      enabled: Boolean(dateTime),
    },
  });

  const event = useMemo(() => {
    if (dateTime && dateTimeEventDetails.data) {
      return (
        {
          ...details.data,
          startDateTime: dateTime.startDateTime,
          endDateTime: dateTime.endDateTime,
          transcript: dateTimeEventDetails.data.transcript,
          transcriptLines: dateTimeEventDetails.data.transcriptLines,
          transcriptLineBundles:
            dateTimeEventDetails.data.transcriptLineBundles,
        } || {}
      );
    }

    return details.data || {};
  }, [details.data, dateTimeEventDetails.data, dateTime]);

  const onShiftPlayerPlay = (data: ShiftPlayerDataProps) => setDateTime(data);

  const goToEditor = () => {
    const url = dateTimeSearchClipEditorRoute.makeUrl({});

    navigate(url);
  };

  return (
    <ClipViewerPage
      initialStartDateTime={details.data?.startDateTime}
      loading={details.isLoading}
      shiftOffset={dateTime?.shiftOffset}
      onShiftPlayerPlay={onShiftPlayerPlay}
      event={event}
      routeRoot="Date/Time search"
      onEdit={goToEditor}
    />
  );
};
