import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { publicShareEventsRoute } from "src/pages/PublicShareEvents/PublicShareEvents.route";
import { publicReportEventRoute } from "src/pages/PublicReportEvent/PublicReportEvent.route";
import { reportEditRoute } from "src/pages/ReportEdit/ReportEdit.route";

export const getFullReportDetailsLink = (reportId: string) => {
  return `${window.location.origin}${publicReportRoute.makeUrl({
    reportId,
  })}`;
};

export const getFullShareEventsLink = (reportId: string) => {
  return `${window.location.origin}${publicShareEventsRoute.makeUrl({
    reportId,
  })}`;
};

export const getFullPublicEventLink = (reportId: string, eventId: string) => {
  return `${window.location.origin}${publicReportEventRoute.makeUrl({
    reportId,
    eventId,
  })}`;
};

export const getFullReportEditLink = (reportId: string) => {
  return `${window.location.origin}${reportEditRoute.makeUrl(
    {
      reportId,
    },
    {}
  )}`;
};

export const getFullMediaItemLink = (userId: string, mediaItemId: string) => {
  return `${window.location.origin}/public/media-center/${userId}/${mediaItemId}`;
};
