import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";
import defaultAvatar from "../pages/Settings/components/assets/defaultAvatar.png";

const apiPath: keyof paths = "/api/User/{id}/profile-image";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];
type CustomResponse = {
  image: ResponseBody;
  imageSource?: string;
};

export const profileImageKey = "profileImageKey";

export function useProfileImage() {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();
  const parameters: Parameters = {
    path: {
      id: userId,
    },
  };

  return useQuery<CustomResponse, unknown>({
    queryKey: [profileImageKey, userId],
    enabled: !!userId,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, parameters);
      const { data, headers } = await axios<ArrayBuffer>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });

      if (data) {
        const imageSource =
          headers["image-source"] || headers["x-profileimage-source"];
        const imageBlob = new Blob([data], {
          type: "image/png",
        });
        const imageUrl = URL.createObjectURL(imageBlob);
        return {
          image: imageUrl,
          imageSource,
        };
      }
      return { image: defaultAvatar };
    },

    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
