import { create } from "zustand";

type WatchQueryCloneDialogState = {
  queryId: string;
  show: (queryId: string) => void;
  hide: () => void;
};

const stateHook = create<WatchQueryCloneDialogState>((set) => ({
  queryId: "",
  hide: () => set({ queryId: "" }),
  show: (queryId) => set({ queryId }),
}));

export function useWatchQueryCloneDialogState() {
  const hook = stateHook();

  return {
    ...hook,
    isOpen: !!hook.queryId,
  };
}
