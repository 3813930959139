import {
  Typography,
  dialogContentClasses,
  dialogTitleClasses,
  dialogActionsClasses,
} from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type AccountsManagementDeactivateAccountDialogProps = Omit<
  DialogPromptProps,
  "onConfirm" | "onClose"
> & {
  onClose: () => void;
  accountName?: string | null;
  convertAccount: (convertKey: string, value?: string | boolean) => void;
};

export function AccountsManagementDeactivateAccountDialog({
  onClose,
  accountName,
  convertAccount,
  ...props
}: AccountsManagementDeactivateAccountDialogProps) {
  const deactivateAccount = () => {
    convertAccount("isActive", false);
    onClose();
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      disableHeaderDivider
      sx={{
        [`.${dialogTitleClasses.root}`]: {
          paddingY: 2,
          paddingX: 3,
        },
        [`.${dialogContentClasses.root}`]: {
          paddingY: 1,
          paddingLeft: 3,
          paddingRight: 2,
        },
        [`.${dialogActionsClasses.root}`]: {
          paddingY: 2,
          paddingX: 3,
        },
      }}
      {...props}
      children={
        <Typography variant="body1" pb={1}>
          Do you want to Deactivate "{accountName}" account?
        </Typography>
      }
      onDecline={onClose}
      onConfirm={deactivateAccount}
    />
  );
}
