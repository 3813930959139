import { IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";

export function PaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange, slotProps } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const pagesCount = Math.ceil(count / rowsPerPage);

  return (
    <Stack direction="row">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography
        variant="caption"
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
        }}
      >
        {page + 1}
      </Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page + 1 >= pagesCount || slotProps?.nextButton?.disabled}
        aria-label="next page"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Stack>
  );
}
