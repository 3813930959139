import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { ReportCreateForm } from "src/components/ReportCreateForm/ReportCreateForm";

export function ReportCreateInfoTab({ isBusy }: { isBusy: boolean }) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Stack alignItems="center" pt={isMobile ? 2 : 9} px={2}>
      <CustomBannerHeader width={isMobile ? "100%" : "692px"} />
      <Stack
        sx={{
          maxWidth: 692,
          width: "100%",
          mt: { xs: 4, md: 8 },
        }}
      >
        <ReportCreateForm isBusy={isBusy} />
      </Stack>
    </Stack>
  );
}
