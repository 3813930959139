export function makeTimeLineBlockParams(scale: number): {
  width: number;
  spanSec: number;
} {
  const minWidth = 60;

  if (scale > 4) {
    return {
      width: (minWidth * scale) / 5.25,
      spanSec: 1 * 60,
    };
  }

  if (scale > 3) {
    return {
      width: (minWidth * scale) / 4,
      spanSec: 2 * 60,
    };
  }

  if (scale > 2) {
    return {
      width: (minWidth * scale) / 2,
      spanSec: 5 * 60,
    };
  }

  return {
    width: minWidth * scale,
    spanSec: 10 * 60,
  };
}
