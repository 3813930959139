import { useState } from "react";

export function useOpenState() {
  const [isOpen, setIsOpen] = useState(false);
  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);

  return {
    isOpen,
    show,
    hide,
  };
}
