import { Edit } from "@mui/icons-material";
import {
  BoxProps,
  Typography,
  useTheme,
  Stack,
  Chip,
  Button,
  Grid,
  Box,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { format } from "date-fns";
import { ImageLoader } from "src/components/ImageLoader/ImageLoader";
import { accountsManagementEditAccountRoute } from "src/pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { AccountItem } from "src/api/useAccountAutocomplete";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import {
  getBorderColor,
  getStatusIcon,
} from "../AccountsManagementTable/AccountsManagementTable.utils";

export type ExpectedResultItem = {
  region?: string;
  status?: string;
};

export function AccountsManagementTile({
  value,
  width = "100%",
  height = "100%",
}: {
  value: AccountItem;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
}) {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const expirationDate = value?.account?.expirationDate
    ? format(new Date(value?.account?.expirationDate), "PP pp")
    : " - ";
  const accountUrl = accountsManagementEditAccountRoute.makeUrl({
    accountId: value?.account?.id || "",
  });

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="visible"
      position="relative"
    >
      <Grid container>
        <Grid
          container
          bgcolor={value.banner?.bgColor || "rgba(224, 224, 224, .3)"}
          sx={{
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
          height={84}
        >
          <Grid item sm={2} p={1} alignContent={"center"}>
            <Box position="relative" borderRadius={2} overflow="hidden" pl={2}>
              <ImageLoader
                src={`data:image/png;base64,${value.banner?.bannerData}`}
                height={60}
                width={60}
                borderRadius={"50%"}
                position="relative"
              />
            </Box>
          </Grid>
          <Grid item sm={10} py={1} pt={3}>
            <Stack pl={1} sx={{ color: value.banner?.color }}>
              <Typography variant="subtitle2">
                {value?.account?.name}
              </Typography>
              <Typography variant="caption">
                {value?.account?.description}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid item sm={4} p={2} pt={3} pb={0}>
          <AppLink to={accountUrl}>
            <Typography
              variant="subtitle1"
              color="primary.main"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value?.account?.name}
            </Typography>
          </AppLink>
        </Grid>
        <Grid item sm={8} p={2} pb={1}>
          <Stack
            direction="row"
            alignItems={"center"}
            gap={1}
            justifyContent={"end"}
          >
            {/* Status */}
            <Chip
              sx={{
                textTransform: "capitalize",
                border: `.1px solid ${getBorderColor(value?.status)}`,
              }}
              variant="outlined"
              avatar={
                <Avatar sx={{ background: "none !important" }}>
                  {getStatusIcon(value?.account?.status)}
                </Avatar>
              }
              label={value?.account?.status}
            />

            {/* Users */}
            <Box
              gap={0.5}
              display={"flex"}
              border=".1px solid rgba(224, 224, 224, 1)"
              borderRadius={10}
              px={1}
              py={0.5}
            >
              <InsertEmoticonIcon sx={{ color: "gray" }} />
              <Typography
                variant="body2"
                color="text.secondary"
                flexGrow={1}
                maxWidth={250}
                margin={"auto"}
              >
                {`${value.activeUserCount} ${
                  value.activeUserCount === 1 ? "user" : "users"
                }`}
              </Typography>
            </Box>
            <Chip
              variant={"filled"}
              color={"success"}
              size={"small"}
              label={value.healthScore}
              sx={{ padding: "16px", paddingX: 1 }}
            />
          </Stack>
        </Grid>
        <Grid item sm={5} pl={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Expiration Date:
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant="subtitle2" flexGrow={2}>
            {expirationDate}
          </Typography>
        </Grid>

        <Grid item sm={5} pl={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Account Representative:
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <TextLineClamp
            variant="body2"
            flex={1}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value?.accountManagerEmail}
          </TextLineClamp>
        </Grid>

        <Grid item sm={5} pl={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            State:
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant="subtitle2" flexGrow={2}>
            {value.account?.stateOrProvince}
          </Typography>
        </Grid>

        <Grid item sm={5} pl={2}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Organization Type:
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant="subtitle2" flexGrow={2}>
            {value?.account?.organizationType}
          </Typography>
        </Grid>
      </Grid>

      <Stack flexDirection="row" m={1} gap={2}>
        <Button startIcon={<Edit />} onClick={() => navigate(accountUrl)}>
          Edit
        </Button>
        <Button startIcon={<ContentCopyIcon />}>Duplicate</Button>
      </Stack>
    </Stack>
  );
}
