import { useState } from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  buttonBaseClasses,
  alpha,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultEventType,
  getPowerSearchResultSource,
  getPowerSearchResultViews,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultLanguage,
  getPowerSearchResultCategory,
  getPowerSearchResultId,
} from "src/api/usePowerSearchResult.getters";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getEventIcon } from "src/utils/getEventIcon";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { PowerSearchResultActions } from "src/pages/PowerSearchResult/components/PowerSearchResultActions/PowerSearchResultActions";
import { WatchTermResultsActions } from "src/pages/WatchListTermResults/components/WatchTermResultsActions/WatchTermResultsActions";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { stripHTMLTags } from "src/utils/stripHTMLTags";

export function DuplicatesListItem({
  queryId,
  value,
  isChecked,
  addItemToReport,
  isExpandedTextMode,
  isPowerSearch,
}: {
  queryId: string;
  value: PowerSearchResultItem;
  isChecked: boolean;
  addItemToReport: (value: PowerSearchResultItem) => void;
  isExpandedTextMode: boolean;
  isPowerSearch?: boolean;
}) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventId = getPowerSearchResultId(value);
  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const eventTitle = getPowerSearchResultEvent(value);

  const detailsUrl = isPowerSearch
    ? powerSearchResultClipRoute.makeUrl({ eventId })
    : watchListTermResultClipRoute.makeUrl({
        queryId,
        eventId,
      });

  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention
  );
  const transcriptTooltip = stripHTMLTags({ transcript });

  const { select, unselect } = useEventSelectionDuplicatesDrawer();

  const handleChange = (checked: boolean) => {
    if (checked) {
      select(value);
    } else {
      unselect(value);
    }
  };

  return (
    <ListBaseItem
      key={eventId}
      sx={{
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
        backgroundColor: alpha(palette.action.hover, 0.04),
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
        maxHeight={176}
      >
        <ClipViewer
          id={eventId}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
          sx={{
            [`.player-container .${buttonBaseClasses.root}`]: {
              minWidth: "59px",
              width: "59px",
              height: "59px",
              opacity: "0.4",
            },
          }}
        />
        <TileCheckBox value={isChecked} onChange={handleChange} zIndex={2} />
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Stack flex={1} justifyContent="space-between" overflow="hidden" gap={1}>
        <Stack>
          {/* header card */}
          <Stack direction="row" spacing={2} mr={8} mb={1.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Tooltip title={eventType}>
                <Icon />
              </Tooltip>
              <Tooltip title={eventTitle}>
                <AppLink to={detailsUrl}>
                  <TextLineClamp
                    variant="subtitle1"
                    color="primary"
                    lineClamp={1}
                    height={20}
                  >
                    {getPowerSearchResultEvent(value)}
                  </TextLineClamp>
                </AppLink>
              </Tooltip>
            </Stack>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              minWidth="fit-content"
            >
              <DateRange sx={{ color: palette.action.active }} />
              <TextLineClamp variant="body2" lineClamp={1}>
                {getPowerSearchResultDateTime(value).toLocaleString()}
              </TextLineClamp>
            </Stack>
          </Stack>

          {/* attributes */}
          <Stack
            direction="row"
            columnGap={2}
            justifyContent="space-between"
            maxWidth="100%"
            overflow="hidden"
            flexWrap="wrap"
          >
            <KeyValue name="Source" value={getPowerSearchResultSource(value)} />
            <KeyValue
              name="Country"
              value={getPowerSearchResultCountry(value)}
            />
            <KeyValue name="Market" value={getPowerSearchResultMarket(value)} />
            <KeyValue
              name="Viewership"
              value={getPowerSearchResultViews(value)}
            />
            <KeyValue
              name="Language"
              value={getPowerSearchResultLanguage(value)?.toUpperCase()}
            />
            <KeyValue
              name="Category"
              value={getPowerSearchResultCategory(value)}
            />
          </Stack>
        </Stack>

        <Tooltip title={transcriptTooltip}>
          <TranscriptBlock px={2} py={1} borderRadius={1}>
            {isExpandedTextMode ? (
              <Typography variant="body1">
                <TranscriptParser
                  highlightColor={palette.primary.main}
                  transcript={transcript}
                />
              </Typography>
            ) : (
              <TranscriptText
                variant="body1"
                transcript={transcript}
                lineClamp={3}
              />
            )}
          </TranscriptBlock>
        </Tooltip>
      </Stack>
      <ListItemSecondaryAction
        sx={{
          top: spacing(1),
          right: 0,
          transform: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        {isPowerSearch ? (
          <PowerSearchResultActions
            value={value}
            addItemToReport={addItemToReport}
          />
        ) : (
          <WatchTermResultsActions
            value={value}
            addItemToReport={addItemToReport}
            queryId={queryId}
          />
        )}
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
