import Joi from "joi";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";

const OptionSchema = Joi.object({
  group: Joi.string().required(),
  name: Joi.string().required(),
  value: Joi.string().required(),
});

export const DateTimeSearchFormSchema = Joi.object<DateTimeSearchFormValues>({
  market: OptionSchema.empty(null).label("Market"),
  station: OptionSchema.empty(null).label("Station").required(),
  dateTime: Joi.date().max("now").empty(null).label("Date Time").required(),
});
