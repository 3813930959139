import { useState } from "react";
import { DateRange } from "@mui/icons-material";
import {
  BoxProps,
  Typography,
  useTheme,
  Stack,
  Divider,
  Box,
  Tooltip,
  alpha,
} from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import {
  getPowerSearchResultDateTime,
  getPowerSearchResultDuplicates,
  getPowerSearchResultEvent,
  getPowerSearchResultEventType,
  getPowerSearchResultId,
  getPowerSearchResultIndividualTranscript,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
} from "src/api/usePowerSearchResult.getters";
import { getEventIcon } from "src/utils/getEventIcon";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { AppLink } from "src/components/AppLink/AppLink";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { DuplicatesChip } from "src/components/DuplicatesChip/DuplicatesChip";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { PowerSearchResultActions } from "../PowerSearchResultActions/PowerSearchResultActions";

export function PowerSearchResultTile({
  value,
  isChecked,
  width = "100%",
  height = "100%",
  addItemToReport,
  isExpandedTextMode,
  showDuplicatesDrawer,
  drawerParentEventId,
}: {
  value: PowerSearchResultItem;
  isChecked: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  addItemToReport: (value: PowerSearchResultItem) => void;
  isExpandedTextMode: boolean;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  drawerParentEventId?: string;
}) {
  const { palette } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventId = getPowerSearchResultId(value);
  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const detailsUrl = powerSearchResultClipRoute.makeUrl({ eventId });

  const dateTimeLabel = getPowerSearchResultDateTime(value).toLocaleString();
  const eventTitleLabel = getPowerSearchResultEvent(value);
  const transcript = getPowerSearchResultIndividualTranscript(
    value,
    activeMention
  );
  const transcriptTitle = stripHTMLTags({ transcript });

  const duplicatesIds = getPowerSearchResultDuplicates(value);
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();
  const selectedDuplicatesIds = duplicatesDrawerEventsState.list.map(
    (ev) => ev.id
  );
  const itemSelectedDuplicates = duplicatesIds.filter((id) =>
    selectedDuplicatesIds.includes(id)
  );

  const selectionState = useEventSelectionPowerSearchResults();

  const handleSelect = (checked: boolean) => {
    if (checked) {
      selectionState.select(value);
    } else {
      selectionState.unselect(value);
    }
  };

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="hidden"
      sx={{
        backgroundColor:
          eventId === drawerParentEventId
            ? alpha(palette.secondary.main, 0.08)
            : "inherit",
      }}
    >
      <Box position="relative">
        <ClipViewer
          id={eventId}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
        />
        <TileCheckBox value={isChecked} onChange={handleSelect} zIndex={3} />
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}

        {!!duplicatesIds.length && (
          <Box
            sx={{
              position: "absolute",
              right: getPowerSearchResultTag(value).length ? 56 : 8,
              top: 8,
              backgroundColor:
                palette.mode === "light"
                  ? palette.common.white
                  : palette.common.black,
              borderRadius: "16px",
            }}
          >
            <DuplicatesChip
              count={duplicatesIds.length}
              selectedCount={itemSelectedDuplicates.length}
              onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
            />
          </Box>
        )}
      </Box>

      <Divider />

      <Stack
        direction="row"
        height={84}
        pl={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack rowGap={1}>
          <Stack direction="row" alignItems="center">
            <Icon />
            <AppLink title={eventTitleLabel} to={detailsUrl}>
              <TextLineClamp
                variant="subtitle1"
                color="primary"
                lineClamp={1}
                ml={1}
                height={20}
              >
                {eventTitleLabel}
              </TextLineClamp>
            </AppLink>
          </Stack>

          <Stack direction="row" alignItems="center">
            <DateRange color="action" />
            <Typography variant="body2" ml={1}>
              {dateTimeLabel}
            </Typography>
          </Stack>
        </Stack>
        <PowerSearchResultActions
          value={value}
          addItemToReport={addItemToReport}
        />
      </Stack>
      <Tooltip title={transcriptTitle}>
        <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
          {isExpandedTextMode ? (
            <Typography>
              <TranscriptParser
                highlightColor={palette.primary.main}
                transcript={transcript}
              />
            </Typography>
          ) : (
            <TranscriptText
              variant="body2"
              transcript={transcript}
              lineClamp={2}
            />
          )}
        </TranscriptBlock>
      </Tooltip>
    </Stack>
  );
}
