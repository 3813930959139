import { Dispatch, SetStateAction } from "react";
import { AlertConfigFormDaily } from "./AlertConfigFormDaily/AlertConfigFormDaily";
import { AlertConfigFormWeekly } from "./AlertConfigFormWeekly/AlertConfigFormWeekly";
import { AlertConfigFormMonthly } from "./AlertConfigFormMonthly/AlertConfigFormMonthly";
import { AlertConfigFormHourly } from "./AlertConfigFormHourly/AlertConfigFormHourly";
import { AlertConfig } from "../ScheduledAlertPopover.model";

type ScheduledAlertControlsAltProps = {
  updateSelectOpen?: (v: boolean) => void;
  config: AlertConfig;
  setConfig: Dispatch<SetStateAction<AlertConfig>>;
};

export const ScheduledAlertControls = ({
  config,
  setConfig,
  updateSelectOpen,
}: ScheduledAlertControlsAltProps) => {
  switch (config.type) {
    case "hourly":
      return (
        <AlertConfigFormHourly
          updateSelectOpen={updateSelectOpen}
          value={config}
          onChange={setConfig}
        />
      );

    case "daily":
      return (
        <AlertConfigFormDaily
          updateSelectOpen={updateSelectOpen}
          value={config}
          onChange={setConfig}
        />
      );

    case "weekly":
      return (
        <AlertConfigFormWeekly
          updateSelectOpen={updateSelectOpen}
          value={config}
          onChange={setConfig}
        />
      );

    case "monthly":
      return (
        <AlertConfigFormMonthly
          updateSelectOpen={updateSelectOpen}
          value={config}
          onChange={setConfig}
        />
      );

    default:
      return null;
  }
};
