import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { usersManagementProfileImageKey } from "./useUsersManagementProfileImage";

const apiPath: keyof paths = "/api/User/{id}/profile-image";
const method: keyof paths[typeof apiPath] = "delete";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"][204];

type RequestOptions = Pick<
  UseMutationOptions<Response>,
  "onSuccess" | "onError" | "onSettled"
>;

type Parameters = Endpoint["parameters"];

type RequestParameters = {
  params?: Parameters;
};

export function useUserManagementProfileImageDelete({
  request,
  options,
}: {
  request: RequestParameters;
  options?: RequestOptions;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation<Response>({
    ...options,
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      const userId = request.params?.path.id || "";
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        path: {
          id: userId,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        t("profileImageDeleteMessage", "Profile avatar deleted"),
        {
          variant: "success",
        }
      );
      //Delay to allow BE to update Image-Source header/Avoid cached value
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: usersManagementProfileImageKey,
        });
      }, 0);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
