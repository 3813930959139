import {
  differenceInHours,
  endOfDay,
  format,
  isToday,
  isValid,
  isWeekend,
  startOfDay,
  subDays,
} from "date-fns";
import { GridKeyValue } from "@mui/x-data-grid-premium";
import { Typography } from "@mui/material";

import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { makeDateCaption } from "src/utils/makeDateCaption";
import { AppLink } from "src/components/AppLink/AppLink";
import { watchListTermResultsRoute } from "src/pages/WatchListTermResults/WatchListTermResults.route";
import { WatchQueryListResponse } from "src/api/useWatchQueryList";

import { WatchQueryTableGridColDef } from "./WatchQueryTable.model";
import { WatchQueryTableHeaderDay } from "./WatchQueryTableHeaderDay";
import { WatchQueryTableAlerts } from "./WatchQueryTableAlerts";
import { WatchQueryTableClasses } from "./WatchQueryTable.const";
import { WatchQueryItemMenuButton } from "../WatchQueryItemMenu/WatchQueryItemMenuButton";
import { WatchListClusterItemMenuButton } from "../WatchListClusterItemMenu/WatchListClusterItemMenuButton";

const dateTimeRegEx = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

const commonCellParams: Pick<
  WatchQueryTableGridColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu" | "disableReorder"
> = {
  headerAlign: "center",
  align: "center",
  disableColumnMenu: true,
  sortable: false,
  disableReorder: true,
};

export function fixmeGetClusterParamsFromRowNodeId(groupKey: GridKeyValue) {
  const [clusterId, clusterTitle] = `${groupKey}`.split("/").slice(1);
  return {
    clusterId,
    clusterTitle,
  };
}

export function makeWatchQueryTableColumns({
  dateRange,
  queries,
  isMobile,
}: {
  dateRange: [Date, Date];
  queries?: WatchQueryListResponse["results"];
  isMobile?: boolean;
}): WatchQueryTableGridColDef[] {
  const [startDate, endDate] = dateRange;
  const dayColumns: WatchQueryTableGridColDef[] = [];

  if (startDate && isValid(startDate) && endDate && isValid(endDate)) {
    const hourCount = differenceInHours(endDate, startDate);
    const dayCount = Math.ceil(hourCount / 24);

    for (let day = 0; day < dayCount; day++) {
      // date columns come from most recent to right less recent dates
      const currentDate = subDays(endDate, day);
      const currentDayStart = startOfDay(currentDate);
      const currentDayEnd = endOfDay(currentDate);
      const dayCaption = makeDateCaption(currentDate);

      const getHeaderClassName = () => {
        if (isToday(currentDate)) {
          return WatchQueryTableClasses.headerToday;
        }

        if (isWeekend(currentDate)) {
          return WatchQueryTableClasses.headerWeekend;
        }

        if (!isMobile) {
          return WatchQueryTableClasses.header;
        }
      };

      const getCellClassName = () => {
        if (isToday(currentDate)) {
          return WatchQueryTableClasses.cellToday;
        }

        if (isWeekend(currentDate)) {
          return WatchQueryTableClasses.cellWeekend;
        }

        return WatchQueryTableClasses.cellDay;
      };

      const dayDateLabel = format(currentDate, "dd MMM");
      const dayDateTimeKey = formatDateTimeWithoutTimeZone(currentDayStart);
      const dayColumn: WatchQueryTableGridColDef = {
        ...commonCellParams,
        field: dayDateTimeKey,
        disableReorder: true,
        valueGetter(params) {
          if (!params.row.hits) {
            return null;
          }

          const dayHitData = params.row.hits.find((hitData) => {
            if (!hitData.keyAsString) return false;

            const dateTimeRegExResult = dateTimeRegEx.exec(hitData.keyAsString);
            const [serverDateKey] = dateTimeRegExResult || [];

            return serverDateKey === dayDateTimeKey;
          });

          return dayHitData?.hits;
        },
        cellClassName: getCellClassName(),
        headerClassName: getHeaderClassName(),
        renderHeader({ colDef }) {
          return (
            <WatchQueryTableHeaderDay
              {...colDef}
              caption={dayCaption}
              content={dayDateLabel}
            />
          );
        },
        renderCell(params) {
          // do not render anything in group row
          if (params.rowNode.type === "group") {
            return null;
          }

          if (!params.value) {
            return (
              <Typography
                variant="inherit"
                color="text.disabled"
                children={0}
              />
            );
          }

          return (
            <AppLink
              title={Number(params.value).toLocaleString()}
              to={watchListTermResultsRoute.makeUrl(
                {
                  queryId: params.row.id,
                },
                {
                  startDateTime: formatDateTimeWithoutTimeZone(currentDayStart),
                  endDateTime: formatDateTimeWithoutTimeZone(currentDayEnd),
                }
              )}
            />
          );
        },
      };

      dayColumns.push(dayColumn);
    }
  }

  const dataColumns: WatchQueryTableGridColDef[] = [
    ...dayColumns,
    {
      ...commonCellParams,
      headerName: "Notifications",
      field: "alerts",
      width: 120,
      headerClassName: !isMobile ? WatchQueryTableClasses.header : undefined,
      renderCell(params) {
        const { rowNode, row } = params;
        const { clusterId } = fixmeGetClusterParamsFromRowNodeId(rowNode.id);

        const query =
          rowNode.type === "group"
            ? queries?.find((query) => query.id === clusterId)
            : queries?.find((query) => query.id === row.id);

        const alerts = query?.alerts || [];

        if (!query) {
          return "Missing query";
        }

        return (
          <WatchQueryTableAlerts
            alerts={alerts}
            queryId={query.id}
            title={query.title}
          />
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Actions",
      field: "actions",
      width: 80,
      pinnable: true,
      cellClassName: WatchQueryTableClasses.actions,
      headerClassName: !isMobile ? WatchQueryTableClasses.header : undefined,
      renderCell(params) {
        // FIXME: i found no way to associate clusterId and clusterTitle with autogenerated rows
        if (params.rowNode.type === "group") {
          const cluster = fixmeGetClusterParamsFromRowNodeId(params.rowNode.id);

          return (
            <WatchListClusterItemMenuButton
              clusterId={cluster.clusterId}
              clusterTitle={cluster.clusterTitle}
            />
          );
        }

        return <WatchQueryItemMenuButton query={params.row} />;
      },
    },
  ];

  return dataColumns;
}
