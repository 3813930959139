import { AddToQueue, LiveTvOutlined, RadioOutlined } from "@mui/icons-material";
import { SnapshotMediaType } from "src/models/SnapshotMediaType";

export const getSnapshotIcon = (type?: string | null) => {
  switch (type) {
    case SnapshotMediaType.TV:
      return LiveTvOutlined;
    case SnapshotMediaType.Radio:
      return RadioOutlined;
    default:
      return AddToQueue;
  }
};
