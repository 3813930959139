import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ReportItem } from "../models/Report";
import { reportsListKey } from "./useReportList";
import { reportsListKeyInfinite } from "./useReportListInfinite";
import { reportScratchEventsKey } from "./useReportScratchEvents";
import { reportScratchEventsInfiniteKey } from "./useReportScratchEventsInfinite";

const apiPath: keyof paths = "/api/Report/{id}";
const method: keyof paths[typeof apiPath] = "put";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  report: ReportItem;
  isMobile: boolean;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useReportEdit({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationFn: async ({ report }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        path: {
          id: report?.id,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: report,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: reportsListKeyInfinite,
      });
      queryClient.refetchQueries({
        queryKey: reportsListKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsInfiniteKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsKey,
      });
      enqueueSnackbar(
        t("reportEditMessage", { reportTitle: `"${variables.report.title}"` }),
        {
          variant: "success",
          style: {
            marginBottom: variables.isMobile ? "85px" : 0,
          },
        }
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: reportsListKey,
      });
      queryClient.refetchQueries({
        queryKey: reportsListKeyInfinite,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsInfiniteKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsKey,
      });
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
