import { create } from "zustand";

interface ShownState {
  isShown: boolean;
  show: () => void;
  hide: () => void;
}

export const useIntercomButtonState = create<ShownState>((set) => ({
  isShown: true,
  show: () => set({ isShown: true }),
  hide: () => set({ isShown: false }),
}));
