import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Add } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  ToggleButton,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { WatchQueryInstantAlertDialog } from "src/components/WatchQueryInstantAlertDialog/WatchQueryInstantAlertDialog";
import { ScheduledAlertPopover } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover";
import { ScheduledAlertMobileCreate } from "src/components/ScheduledAlertMobile/ScheduledAlertMobileCreate/ScheduledAlertMobileCreate";
import { ScheduledAlertCardView } from "src/components/ScheduledAlertPopover/ScheduledAlertCardView/ScheduledAlertCardView";
import { ScheduledAlertDeleteDialog } from "src/components/ScheduledAlertPopover/ScheduledAlertDeleteDialog/ScheduledAlertDeleteDialog";
import {
  alertTypePresets,
  getScheduledAlertType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { useWatchQueryAlertDelete } from "src/api/useWatchQueryAlertDelete";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { getSkippedInstantAlert } from "src/utils/instantAlertHelpers";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { AlertType } from "src/models/AlertType";
import { StackScroll } from "src/components/StackScroll/StackScroll";

export const NotificationSection = ({
  watchTerm,
  isInstantAlertActive,
  toggleInstantAlert,
  scheduledAlerts,
  scheduledAlertCreateHandler,
  defaultAlertId,
}: {
  watchTerm?: UserQueryDTO;
  isInstantAlertActive?: boolean;
  toggleInstantAlert?: () => void;
  scheduledAlerts?: AlertType[] | null;
  scheduledAlertCreateHandler?: (
    newValue: AlertType | AlertType[],
    action?: "delete" | "edit"
  ) => void;
  defaultAlertId?: boolean;
}) => {
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));

  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const [scheduledAlertEnabled, setScheduledAlertEnabled] = useState(true);
  const [showInstantAlert, setShowInstantAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [editRule, setEditRule] = useState<AlertType | null>(null);
  const [showAlertDeleteDialog, setShowAlertDeleteDialog] = useState(false);
  const [alertDeleteData, setAlertDeleteData] = useState<AlertType | null>(
    null
  );
  const [sheduledAlertArchiveEnabled, setSheduledAlertArchiveEnabled] =
    useState(false);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const isAlertsEnabled = (watchTerm?.alerts || scheduledAlerts || []).every(
      (alert) => alert.enabled
    );
    setScheduledAlertEnabled(isAlertsEnabled);
  }, [watchTerm?.alerts, scheduledAlerts]);

  const instantAlert =
    watchTerm?.alerts.filter((alert) => alert.type === "realtime") || [];

  const isScheduledAlertsDisabled =
    (watchTerm?.alerts || scheduledAlerts || []).filter(
      (alert) => alert.type !== "realtime"
    ).length === 0;

  const alertDelete = useWatchQueryAlertDelete({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: watchTerm ? "edit" : "create",
      type: "instant",
    },
  });
  const instantAlertDelete = () => {
    if (!watchTerm && toggleInstantAlert && isInstantAlertActive) {
      return toggleInstantAlert();
    }
    if (!watchTerm || !instantAlert.length) return;

    const alertId = instantAlert[0].id;
    if (!alertId) return;

    alertDelete.mutate({
      params: {
        path: {
          queryId: watchTerm.id,
          alertId,
        },
      },
    });
  };

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: watchTerm ? "edit" : "create",
      type: "instant",
    },
  });

  const instantAlertCreate = () => {
    if (!watchTerm?.id && toggleInstantAlert) {
      toggleInstantAlert();
      setShowInstantAlert(false);
      return;
    }
    if (!watchTerm?.id) return;

    alertCreate.mutate({
      params: {
        path: {
          queryId: watchTerm?.id,
        },
        query: {
          type: "realtime",
          archive: false,
        },
      },
    });
  };

  const skippedInstantAlert = getSkippedInstantAlert();

  const editRuleHandler = (rule?: AlertType | null) => {
    if (!rule && rule !== null) return;

    if (rule !== null) {
      setAnchorEl(buttonRef.current);
    }
    setEditRule(rule);
  };

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      origin: watchTerm ? "edit" : "create",
    },
  });
  const handleScheduledAlertChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (scheduledAlertCreateHandler) {
      const updatedAlerts = (scheduledAlerts || []).map((v) => {
        return { ...v, enabled: event.target.checked };
      });
      return scheduledAlertCreateHandler(updatedAlerts);
    }

    if (!watchTerm) return;

    const updatedAlerts =
      watchTerm?.alerts.map((v) => {
        if (v.type === "realtime") return v;
        return { ...v, enabled: event.target.checked };
      }) || [];

    watchQueryUpdate.mutate({
      ...watchTerm,
      alerts: updatedAlerts,
    });
  };

  const scheduledAlertRules = useMemo(() => {
    const updatedRules =
      (watchTerm?.alerts || scheduledAlerts)?.filter((alert) => {
        if (alert.type === "realtime") return false;
        return alert.id !== editRule?.id;
      }) || [];
    return (
      updatedRules.length !== 0 && (
        <StackScroll
          gap={isSmallScreen ? 2 : 1}
          mt={1}
          px={isSmallScreen ? 2 : undefined}
          py={isSmallScreen ? 2 : undefined}
          height={isSmallScreen ? undefined : 460}
          sx={{
            backgroundColor: isSmallScreen
              ? palette.background.default
              : undefined,
          }}
        >
          {updatedRules.map((alert, idx) => (
            <ScheduledAlertCardView
              key={alert.id}
              type={getScheduledAlertType(alert)}
              value={alert.schedule || []}
              enabled={alert.enabled}
              onDelete={() => {
                if (!scheduledAlertCreateHandler) {
                  setAlertDeleteData(alert);
                  setShowAlertDeleteDialog(true);
                  return;
                }

                scheduledAlertCreateHandler(alert, "delete");
              }}
              onEdit={() => editRuleHandler(alert)}
            />
          ))}
          <ScheduledAlertDeleteDialog
            open={showAlertDeleteDialog}
            onClose={() => setShowAlertDeleteDialog(false)}
            queryId={watchTerm?.id}
            alert={alertDeleteData}
            origin={watchTerm ? "edit" : "create"}
          />
        </StackScroll>
      )
    );
  }, [
    isSmallScreen,
    palette.background.default,
    alertDeleteData,
    editRule,
    watchTerm,
    scheduledAlerts,
    showAlertDeleteDialog,
    scheduledAlertCreateHandler,
  ]);

  return (
    <FormGroup>
      {isSmallScreen && (
        <Typography
          variant="body2"
          fontWeight={600}
          lineHeight="24px"
          p={2}
          pt={0}
        >
          Notifications
        </Typography>
      )}
      <FormControlLabel
        sx={{ px: isSmallScreen ? 2 : undefined, height: "54px" }}
        checked={instantAlert.length !== 0 || isInstantAlertActive || false}
        control={
          <Switch
            onClick={() => {
              if (
                skippedInstantAlert &&
                toggleInstantAlert &&
                !isInstantAlertActive
              ) {
                return instantAlertCreate();
              }

              if (
                skippedInstantAlert &&
                !toggleInstantAlert &&
                !instantAlert.length
              ) {
                return instantAlertCreate();
              }

              if (toggleInstantAlert && !isInstantAlertActive) {
                setShowInstantAlert(true);
              } else if (!toggleInstantAlert && !instantAlert.length) {
                setShowInstantAlert(true);
              } else {
                instantAlertDelete();
              }
            }}
          />
        }
        label="Get the Instant alerts"
      />
      {!skippedInstantAlert && (
        <WatchQueryInstantAlertDialog
          open={showInstantAlert}
          queryId={watchTerm?.id}
          toggleInstantAlert={toggleInstantAlert}
          origin={watchTerm ? "edit" : "create"}
          onClose={() => setShowInstantAlert(false)}
        />
      )}
      <Divider />
      <Stack
        flexDirection="row"
        px={isSmallScreen ? 2 : undefined}
        height="58px"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormControlLabel
          control={
            <Switch
              disabled={isScheduledAlertsDisabled}
              checked={scheduledAlertEnabled}
              onChange={handleScheduledAlertChange}
            />
          }
          label="Get the Scheduled alerts"
        />
        <Box>
          <ToggleButton
            ref={buttonRef}
            onClick={handleClick}
            value="check"
            sx={{
              borderRadius: "8px",
              p: isSmallScreen ? "11px" : "7px",
              border: `1px solid ${alpha(palette.action.disabled, 0.23)}`,
            }}
          >
            <Add sx={{ color: palette.text.primary }} />
          </ToggleButton>
        </Box>
      </Stack>
      {scheduledAlertRules}
      <ScheduledAlertPopover
        editedRule={editRule}
        onSuccessEditRule={editRuleHandler}
        queryId={watchTerm?.id}
        presets={alertTypePresets}
        open={open && !isSmallScreen}
        anchorEl={anchorEl}
        alerts={watchTerm?.alerts || scheduledAlerts || []}
        scheduledAlertCreateHandler={scheduledAlertCreateHandler}
        origin={watchTerm ? "edit" : "create"}
        onClose={handleClose}
        defaultAlertId={defaultAlertId}
      />
      <ScheduledAlertMobileCreate
        sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
        setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        editedRule={editRule}
        onSuccessEditRule={editRuleHandler}
        open={open && isSmallScreen}
        queryId={watchTerm?.id}
        presets={alertTypePresets}
        origin={watchTerm ? "edit" : "create"}
        alerts={watchTerm?.alerts || scheduledAlerts || []}
        scheduledAlertCreateHandler={scheduledAlertCreateHandler}
        onClose={handleClose}
        defaultAlertId={defaultAlertId}
      />
    </FormGroup>
  );
};
