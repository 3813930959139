import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Stack } from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  TextInputBase,
  TextInputBaseProps,
} from "src/components/TextInputBase";
import { stringCurrencyToNumber } from "../AccountsManagementConvertForm/AccountsManagamentConvertForm.utils";

const buttonSX = { width: "15px", height: "15px" };

export function CurrencyInput({
  value,
  onChange,
  onInputChange,
  error,
  helperText,
  disabled,
  ...props
}: TextInputBaseProps & {
  value: number;
  onInputChange?: (value: string) => void;
}) {
  const handleIncrease = () => {
    //formatted value is string
    if (typeof value === "string") {
      const number = stringCurrencyToNumber(value);
      const newNumber = (number + 0.01).toFixed(2);
      onInputChange?.(newNumber);
    } else {
      //Value from BE is Number
      const newNumber = (value + 0.01).toFixed(2);
      onInputChange?.(newNumber);
    }
  };

  const handleDecrease = () => {
    //formatted value is string
    if (value === 0) return;

    if (typeof value === "string") {
      const number = stringCurrencyToNumber(value);
      const decreasedNumber = (number - 0.01).toFixed(2);
      if (number === 0) return;
      onInputChange?.(decreasedNumber);
    } else {
      //Value from BE is Number
      const decreasedNumber = (value - 0.01).toFixed(2);
      onInputChange?.(decreasedNumber);
    }
  };

  return (
    <NumericFormat
      defaultValue={0}
      onValueChange={(e) => onInputChange?.(e.value)}
      allowNegative={false}
      decimalScale={2}
      thousandSeparator=","
      min={0.0}
      customInput={TextInputBase}
      InputProps={{
        startAdornment: (
          <AttachMoneyOutlinedIcon color={disabled ? "disabled" : "action"} />
        ),
        endAdornment: (
          <Stack mr={1} py={1}>
            <IconButton
              onClick={handleIncrease}
              edge="end"
              sx={buttonSX}
              disabled={disabled}
            >
              <KeyboardArrowUpIcon sx={buttonSX} />
            </IconButton>
            <IconButton
              onClick={handleDecrease}
              edge="end"
              sx={buttonSX}
              disabled={disabled}
            >
              <KeyboardArrowDownIcon sx={buttonSX} />
            </IconButton>
          </Stack>
        ),
      }}
      label={props.label}
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
}
