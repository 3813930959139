import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { accountsManagementEditAccountRoute } from "../../AccountsManagementEditAccount.route";

type AccountsManagementItemMenuProps = {
  id?: string;
  onClose: () => void;
  showConvertAccountDialog: () => void;
} & Omit<MenuProps, "children">;

export const AccountsManagementItemMenu = ({
  id = "",
  showConvertAccountDialog,
  ...props
}: AccountsManagementItemMenuProps) => {
  const navigate = useNavigate();

  const accountUrl = accountsManagementEditAccountRoute.makeUrl({
    accountId: id,
  });
  const handleOpenConvertAccountDialog = () => {
    showConvertAccountDialog();
    props.onClose();
  };

  const handleEdit = () => {
    navigate(accountUrl);
  };

  return (
    <Menu {...props}>
      <MenuItem onClick={handleEdit}>
        <ListItemIcon>
          <ModeEditIcon fontSize="small" color="action" />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleOpenConvertAccountDialog}>
        <ListItemIcon>
          <ChangeCircleIcon fontSize="small" color="action" />
        </ListItemIcon>
        <ListItemText>Convert</ListItemText>
      </MenuItem>
    </Menu>
  );
};
