import { ListBase } from "src/components/ListBase/ListBase";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { WatchQueryListProps } from "./WatchQueryList.model";
import { WatchQueryListItem } from "./WatchQueryListItem";

export function WatchQueryList({ queries, ...props }: WatchQueryListProps) {
  const renderItem = (item: WatchListUserQueryDTO) => {
    const query = queries?.find((query) => {
      // FIXME: currently clusters are not shown in the list
      //   return item.clusterId
      //     ? query.id === item.clusterId
      //     : query.id === item.id;

      return query.id === item.id;
    });

    const alerts = query?.alerts || [];

    return <WatchQueryListItem key={item.title} query={item} alerts={alerts} />;
  };

  return (
    <ListBase<WatchListUserQueryDTO>
      renderItem={renderItem}
      renderEmpty={EventsNoResultsOverlay}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
