import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import { useWatchTermSharedForm } from "src/api/useWatchTermSharedForm";
import { WatchQueryCustomFormValues } from "../WatchQueryCustomForm/WatchQueryCustomForm.model";

export const WatchListCreateCustomFormWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  const { watch, reset, trigger } =
    useFormContext<WatchQueryCustomFormValues>();
  const formValues = watch();

  const { sharedValues, customQueryFromKeyword, displayNameFromKeyword } =
    useWatchTermSharedForm();

  useEffect(() => {
    let updatedFormValues = {
      ...formValues,
      ...sharedValues,
    };

    if (customQueryFromKeyword) {
      updatedFormValues.customQuery = customQueryFromKeyword;
    }

    if (displayNameFromKeyword) {
      updatedFormValues.displayName = displayNameFromKeyword;
    }

    reset(updatedFormValues);
    const sharedKeys = Object.keys(sharedValues) as Array<
      keyof WatchQueryCustomFormValues
    >;
    trigger(sharedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Stack>{children}</Stack>;
};
