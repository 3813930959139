import {
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { downloadReportFile } from "src/api/useReportDownloadFile";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { DownloadReportFormat } from "src/api/useReportExport";

export const ExportReportFile = ({
  userId,
  type,
  id,
  fileName,
}: {
  userId: string;
  type: DownloadReportFormat;
  id: string;
  fileName: string;
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isPDF = type === DownloadReportFormat.pdf;

  const handleExportItem = () => {
    downloadReportFile({
      userId,
      type,
      id,
      name: fileName || "",
    });
  };
  const commonContent = (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack display={"flex"} alignItems={"center"}>
        <Stack
          p={2}
          width={100}
          height={100}
          sx={{
            borderRadius: "50px",
            background: alpha(palette.success.main, 0.04),
          }}
        >
          {isPDF ? (
            <DownloadPDF
              width={"100%"}
              height={"100%"}
              color={palette.info.main}
            />
          ) : (
            <DownloadCSV
              width={"100%"}
              height={"100%"}
              fillColor={palette.success.main}
            />
          )}
        </Stack>
        <Typography variant={isMobile ? "h5" : "h4"} py={3}>
          Your {isPDF ? " PDF" : "CSV"} file is ready
        </Typography>
        <Typography variant="subtitle1" pb={3} color={"text.secondary"}>
          {fileName}
        </Typography>

        <Button
          startIcon={<FileDownloadOutlinedIcon />}
          variant="contained"
          color="primary"
          onClick={handleExportItem}
          children="Download"
          sx={{
            width: !isMobile ? 164 : undefined,
            height: isMobile ? 36 : undefined,
          }}
        />
      </Stack>
    </Paper>
  );
  return (
    <>
      {isMobile ? (
        <PageLayoutMobile content={commonContent} />
      ) : (
        <PageLayoutDesktop content={commonContent} overflow="hidden" />
      )}
    </>
  );
};
