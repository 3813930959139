import Joi from "joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AnalyticsLiteFilterFormValues } from "./AnalyticsLiteFilterForm.model";
import { WatchTerm } from "../WatchTermInput/WatchTermInput.model";

const watchTermListSchema = Joi.array().items(Joi.string()).required();
const watchTermLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const programListSchema = Joi.array().items().required();
const programLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

export const AnalyticsLiteFilterFormSchema =
  Joi.object<AnalyticsLiteFilterFormValues>({
    termsInclude: Joi.object<WatchTerm>({
      list: watchTermListSchema.label("Include"),
      logic: watchTermLogicSchema.required().label("Logic"),
    }),
    termsExclude: Joi.object<WatchTerm>({
      list: watchTermListSchema.label("Exclude"),
      logic: watchTermLogicSchema.required().label("Logic"),
    }),
    //
    sourcesInclude: {
      list: sourceListSchema.label("Sources"),
      logic: sourceLogicSchema.label("Logic"),
    },
    //
    advertisement: Joi.valid(
      ...Object.values(queryAdvertisementValues)
    ).required(),
    //
    programInclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
    programExclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
  });
