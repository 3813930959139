import { differenceInSeconds } from "date-fns";
import { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { ClipEditorTrim } from "src/components/ClipEditorTrim/ClipEditorTrim";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { mockEventDetails } from "./DevClipViewer.mock";

export function DevClipViewer() {
  const [isLoading, setLoading] = useState(false);
  const [isMediaVisible, setMediaVisible] = useState(true);
  const [isPreview, setPreview] = useState(false);
  const [trim, setTrim] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ]);

  const t0 = trim[0];
  const t1 = trim[1];

  const startOffset =
    mockEventDetails.startDateTime && t0
      ? differenceInSeconds(t0, new Date(mockEventDetails.startDateTime))
      : 0;

  const endOffset =
    mockEventDetails.startDateTime && t1
      ? differenceInSeconds(t1, new Date(mockEventDetails.startDateTime))
      : 0;

  return (
    <Stack maxWidth={800}>
      <Stack direction="row" columnGap={2}>
        <FormControl>
          <FormControlLabel
            label="Is Loading"
            control={
              <Switch
                checked={isLoading}
                onChange={(_e, value) => setLoading(value)}
              />
            }
          />
        </FormControl>

        <FormControl>
          <FormControlLabel
            label="show Media"
            control={
              <Switch
                checked={isMediaVisible}
                onChange={(_e, value) => setMediaVisible(value)}
              />
            }
          />
        </FormControl>
      </Stack>

      <ClipViewer
        id="test"
        event={mockEventDetails}
        isPreviewing={isPreview}
        showMedia={isMediaVisible}
        loading={isLoading}
        editInOffset={startOffset}
        editOutOffset={endOffset}
      />

      <ClipEditorTrim
        value={trim}
        bounds={[null, null]}
        minSpanSec={5}
        offsetSec={100}
        onChange={setTrim}
        onClickPreview={() => setPreview(true)}
      />

      <Typography variant="h6" mt={2}>
        Set Preview Time: {startOffset?.toFixed(2)}s {endOffset?.toFixed(2)}s
      </Typography>
    </Stack>
  );
}
