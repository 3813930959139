import { useRef } from "react";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { LinearProgress, Stack } from "@mui/material";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { AccountItem } from "src/api/useAccountsManagement";
import { ListBaseFooter } from "src/components/ListBase/ListBaseFooter";
import { NoAccountsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { AccountsManagementTile } from "../AccountsManagementTile/AccountsManagementTile";
import { AccountsManagementTilesProps } from "./AccountsManagementTiles.model";

export function AccountsManagementTiles({
  rows,
  loading,
  rowCount,
  paginationModel,
  onPaginationModelChange,
}: AccountsManagementTilesProps) {
  const scrollRef = useRef(null);

  const linearProgress = loading && (
    <LinearProgress
      color="secondary"
      sx={{
        position: "absolute",
        top: 2,
        left: 0,
        right: 0,
        zIndex: 5,
      }}
    />
  );

  const renderItem = (data: AccountItem) => {
    return (
      <AccountsManagementTile
        key={data?.account?.id}
        value={data}
        height={276}
      />
    );
  };

  return (
    <Stack height="100%" overflow="hidden" position="relative">
      {linearProgress}
      <ScrollBox
        ref={scrollRef}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          flex: 1,
        }}
      >
        <Stack
          pl={2}
          pr={1}
          pb={1}
          flex={1}
          children={
            <TileLayout<AccountItem>
              rows={rows}
              renderItem={renderItem}
              renderEmpty={NoAccountsOverlay}
              minTileWidth={420}
            />
          }
        />
      </ScrollBox>
      <ListBaseFooter
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </Stack>
  );
}
