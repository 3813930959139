import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type LeaveConfirmationDialogProps = Omit<DialogPromptProps, "title" | "footer">;

export const LeaveConfirmationDialog = ({
  onClose,
  onConfirm,
  ...props
}: LeaveConfirmationDialogProps) => {
  return (
    <DialogPrompt
      title="Are you sure you want to leave?"
      children="You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost."
      onDecline={onClose}
      onConfirm={onConfirm}
      confirmColor="primary"
      confirmLabel="Leave"
      {...props}
    />
  );
};
