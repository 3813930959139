import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  DragEvent,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  avatarClasses,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import AvatarEditor from "react-avatar-editor";
import { UploadIcon } from "../icons/UploadIcon";
import {
  BannerFormValues,
  UploadImageEditorProps,
} from "./UploadImageEditor.model";
import { UploadImageEditorClasses } from "./UploadImageEditor.const";
import { DialogModes } from "../UploadImageDialog/UploadImageDialog.model";
import { UploadImageColorSelector } from "./UploadImageColorSelector";
import { UploadImageControls } from "./UploadImageControls";
import { AppTabLayout } from "../AppTabLayout/AppTabLayout";
import { AppTab } from "../AppTabLayout/AppTab";

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes

export function UploadImageEditor({
  mode,
  setPreselectedImage,
  setRotation,
  setScale,
  rotation,
  scale,
  resetChanges,
}: UploadImageEditorProps) {
  const [file, setFile] = useState<null | File | undefined>(null);
  const [previewFile, setPreviewFile] = useState<string | File | null>(null);
  const [previewCanvas, setPreviewCanvas] = useState<string | Blob | null>(
    null
  );
  const [fileError, setFileError] = useState<null | string>(null);

  const previewCanvasString =
    typeof previewCanvas === "string" ? previewCanvas : undefined;

  const previewFileString = typeof previewFile === "string" ? previewFile : "";

  const formContext = useFormContext<BannerFormValues>();
  const { control, getValues, watch } = formContext;

  const { palette, breakpoints } = useTheme();
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  useEffect(() => {
    if (!file) {
      setPreviewFile(null);
      setScale(1);
      setRotation(0);
      return;
    }

    const objectUrl = URL?.createObjectURL(file);
    setPreviewFile(objectUrl);
    setPreviewCanvas(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file, setScale, setRotation]);

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFile(event?.dataTransfer?.files);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFile(event?.dataTransfer?.files);
  };

  const handleFile = (files: FileList | null) => {
    const fileUrl = files?.[0];
    if (fileUrl && fileUrl?.size >= MAX_FILE_SIZE) {
      setFileError("File size exceeds the maximum limit");
      return;
    }
    setFile(fileUrl);
    setPreselectedImage?.(fileUrl);
  };

  const handlePositionChange = async () => {
    if (editorRef?.current) {
      const dataUrl = editorRef?.current?.getImageScaledToCanvas().toDataURL();
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const img = window.URL.createObjectURL(blob);
      const png = new File([blob], "image.png");
      setPreviewCanvas(img);
      setPreselectedImage?.(png);
    }
  };

  const handleZoomChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | Event,
    newValue: number | number[]
  ) => {
    setScale(newValue as number);
  };

  const handleRotation = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | Event,
    newValue: number | number[]
  ) => {
    setRotation(newValue as number);
  };

  const formatLabelRotate = (value: number) => {
    return `${value}°`;
  };

  const formatLabelZoom = (value: number) => {
    return `${Math.floor((value - 1) * 100)}%`;
  };

  const previewLabel =
    mode === DialogModes.avatar ? (
      <Typography variant="body2" py={2}>
        The previews show what your new avatar will look like after clicking
        Crop and Save
      </Typography>
    ) : (
      <Typography variant="body2" py={2}>
        The previews show what your logotype will look like after clicking Crop
        and Save
      </Typography>
    );

  const mobileContent = (
    <>
      <Grid container mt={4}>
        <UploadImageControls
          scale={scale}
          handleZoomChange={handleZoomChange}
          formatLabelZoom={formatLabelZoom}
          rotation={rotation}
          handleRotation={handleRotation}
          formatLabelRotate={formatLabelRotate}
          resetChanges={resetChanges}
        />
        <Grid item xs={12} bgcolor={"rgba(162, 91, 91, 0.04)"} width="100%">
          {mode === DialogModes.avatar && (
            <>
              <Stack mx={2}>
                <Typography variant="subtitle1" my={2}>
                  Avatar preview
                </Typography>
                {previewLabel}
              </Stack>
              <Stack mx={2} my={2}>
                <Typography variant="subtitle2">Small preview</Typography>
                <Avatar
                  alt="avatar"
                  src={previewCanvasString}
                  sx={{ width: "40px", height: "40px", margin: "16px 32px" }}
                />
              </Stack>
            </>
          )}
          <Stack mx={2} my={2}>
            {mode === DialogModes.avatar ? (
              <>
                <Typography variant="subtitle2" my={1}>
                  Large preview
                </Typography>

                <Avatar
                  alt="avatar"
                  src={previewCanvasString}
                  sx={{ width: "192px", height: "192px" }}
                />
              </>
            ) : (
              <UploadImageColorSelector
                control={control}
                watch={watch}
                previewCanvas={previewCanvasString}
                getValues={getValues}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  const desktopContent = (
    <>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        height={350}
        bgcolor={palette.action.selected}
      >
        <Grid
          container
          m={4}
          flexDirection={mode === DialogModes.avatar ? "row" : "column"}
        >
          {previewCanvas && (
            <>
              <Grid
                item
                xs={3}
                display={"flex"}
                flexDirection={"column"}
                alignItems={mode === DialogModes.avatar ? "center" : "start"}
                sx={{
                  [`.${avatarClasses.root}`]: {
                    border: `1px solid ${palette.text.disabled}`,
                  },
                }}
              >
                <Typography variant="subtitle2" mb={1}>
                  Small preview
                </Typography>
                <Avatar
                  alt="avatar"
                  src={previewCanvasString}
                  sx={{ width: "40px", height: "40px" }}
                />
              </Grid>
              <Grid
                item
                xs={9}
                display={"flex"}
                flexDirection={"column"}
                alignItems={mode === DialogModes.avatar ? "center" : "start"}
                sx={{
                  [`.${avatarClasses.root}`]: {
                    border: `1px solid ${palette.text.disabled}`,
                  },
                }}
              >
                {mode === DialogModes.avatar ? (
                  <>
                    <Typography variant="subtitle2" my={1}>
                      Large preview
                    </Typography>

                    <Avatar
                      alt="avatar"
                      src={previewCanvasString}
                      sx={{ width: "192px", height: "192px" }}
                    />
                  </>
                ) : (
                  <UploadImageColorSelector
                    control={control}
                    watch={watch}
                    previewCanvas={previewCanvasString}
                    getValues={getValues}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Stack m={4} mt={mode === DialogModes.avatar ? 13 : 5}>
          {previewLabel}
        </Stack>
      </Grid>
      <Grid
        container
        width="50%"
        mt={2}
        gap={2}
        direction={"column"}
        height={"65px"}
      >
        <UploadImageControls
          scale={scale}
          handleZoomChange={handleZoomChange}
          formatLabelZoom={formatLabelZoom}
          rotation={rotation}
          handleRotation={handleRotation}
          formatLabelRotate={formatLabelRotate}
        />
      </Grid>
    </>
  );

  const fileUploadTabContent = previewFile ? (
    <Grid container pt={isMobile ? 0 : 2}>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        mx={0}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        bgcolor={palette.action.active}
        minHeight={340}
        borderRadius={isMobile ? 2 : 0}
        sx={{
          [`.${UploadImageEditorClasses.canvasEditor}`]: {
            borderRadius: "200px",
            border: `.5px dashed ${palette.action.active}`,
          },
        }}
      >
        <AvatarEditor
          style={{
            margin: "auto",
            background: palette.text.disabled,
          }}
          onImageChange={handlePositionChange}
          ref={editorRef}
          image={previewFileString}
          width={304}
          height={304}
          border={0}
          borderRadius={200}
          color={[0, 0, 0, 0.5]}
          scale={scale}
          rotate={rotation}
          className={UploadImageEditorClasses.canvasEditor}
        />
      </Grid>
      {isMobile ? mobileContent : desktopContent}
    </Grid>
  ) : (
    <Box
      height={"100%"}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        backgroundImage: `url('data:image/svg+xml,%3csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg"%3e%3crect width="100%25" height="100%25" fill="none" rx="10" ry="10" stroke="rgba(4, 36, 65, 0.38)" stroke-width="2" stroke-dasharray="6%2c 14" stroke-dashoffset="3" stroke-linecap="square"/%3e%3c/svg%3e')`,
        borderRadius: "8px",
        mt: 2,
      }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      mx={0}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
        py={isMobile ? "191px" : "77px"}
      >
        <UploadIcon
          sx={{
            width: "32px",
            height: "32px",
          }}
          fill={palette.text.primary}
          fillOpacity="0.65"
        />
        <Typography variant="body2" width="65%" mt={2} textAlign={"center"}>
          {isMobile ? (
            <>Upload the image</>
          ) : (
            <>
              Drag and drop files here <br></br>
              or{" "}
            </>
          )}
        </Typography>
        <input
          type="file"
          hidden
          onChange={(event) => handleFile(event.target.files)}
          accept="image/png, image/jpeg, image/jpg"
          ref={inputRef}
        />
        <Stack mx={2} width={isMobile ? "100%" : "initial"} px={1}>
          <Button
            variant={"outlined"}
            onClick={() => inputRef?.current?.click()}
            fullWidth={isMobile}
          >
            Select files
          </Button>
        </Stack>
        <Typography
          variant="body2"
          pt={2}
          color={"text.secondary"}
          mx={isMobile ? "81px" : 0}
          textAlign={"center"}
        >
          Allowed *.jpeg, *.jpg, *.png max size of 3.1 MB
        </Typography>
        {fileError && (
          <Typography variant="caption" color={"error"}>
            {fileError}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Stack width={"100%"}>
      <Box mb={previewFile ? 2 : 0}>
        {!previewFile ? (
          <>
            <AppTabLayout variant="fullWidth">
              <AppTab
                label={isMobile ? "DEVICE PHOTOS" : "UPLOAD NEW IMAGE"}
                value={"fileUpload"}
                children={fileUploadTabContent}
              />
              <AppTab
                label={isMobile ? "CAMERA" : "FROM WEBCAM"}
                value={"webcam"}
                children={<>Camera/Webcam content</>}
                disabled
              />
            </AppTabLayout>
          </>
        ) : (
          fileUploadTabContent
        )}
      </Box>
    </Stack>
  );
}
