import {
  alpha,
  Box,
  Fab,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import { LogoEye } from "src/components/logo/LogoEye";

export function ReportBanner({
  showEditIcon = false,
  imageUrl,
  setImageUrl,
  hideFormatInfo = false,
  width,
}: {
  showEditIcon?: boolean;
  imageUrl?: string | null;
  setImageUrl?: () => void;
  hideFormatInfo?: boolean;
  width?: string;
}) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const editButton = (
    <Fab
      sx={{
        backgroundColor: palette.background.default,
        "&:hover": {
          backgroundColor: palette.background.default,
        },
        position: "absolute",
        right: isMobile ? 16 : 24,
      }}
      size="medium"
      aria-label="edit"
      onClick={() => setImageUrl?.()}
    >
      <Edit
        sx={{
          color: palette.text.primary,
          backgroundColor: palette.background.default,
        }}
      />
    </Fab>
  );

  return imageUrl ? (
    <Stack
      direction="row"
      alignItems="center"
      width={width}
      bgcolor={palette.background.brand}
      maxWidth={864}
      minHeight={100}
      pl={{ xs: 1, sm: 2 }}
      borderRadius={isMobile ? 0 : "4px 4px 0 0"}
      position="relative"
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPositionY: "center",
        backgroundPositionX: "left",
      }}
    >
      {showEditIcon && editButton}
    </Stack>
  ) : (
    <Stack
      direction="row"
      width={width}
      alignItems="center"
      bgcolor={palette.background.brand}
      color={palette.getContrastText(palette.background.brand)}
      maxWidth={864}
      minHeight={100}
      pr={isMobile ? 3 : 9}
      pl={{ xs: 1, sm: 2 }}
      borderRadius={isMobile ? 0 : "4px 4px 0 0"}
      position="relative"
    >
      <LogoEye
        dark
        maxHeight={isMobile ? 54 : 58}
        maxWidth={isMobile ? 54 : 58}
        mr={{ xs: 1, md: 2 }}
      />
      <Stack flex={1}>
        <Typography
          variant="subtitle1"
          mb={0.25}
          fontSize={{ xs: 14, md: 16 }}
          sx={{ letterSpacing: isMobile ? 0.1 : 0.15 }}
        >
          TVEyes Insight
        </Typography>
        <Typography
          variant="body2"
          color={alpha(palette.getContrastText(palette.background.brand), 0.7)}
          fontSize={{ xs: 12, md: 14 }}
        >
          Advanced Media Monitoring
        </Typography>
      </Stack>

      {!isMobile && showEditIcon && (
        <Stack sx={{ ml: 1, display: { sm: "none", md: "flex" } }}>
          <Box
            sx={{
              fontSize: 10,
              height: 22,
              width: "fit-content",
              backgroundColor: alpha(palette.info.main, 0.3),
              color: alpha(palette.primary.contrastText, 0.7),
              borderRadius: "2px",
              px: 0.75,
              mr: 2,
            }}
          >
            Size 700x102
          </Box>
          {!hideFormatInfo && (
            <Typography
              fontSize={8}
              sx={{
                mt: 0.5,
              }}
            >
              Allowed <b>*.jpeg, *.jpg, *.png, *.gif</b> max size of 3.1 MB
            </Typography>
          )}
        </Stack>
      )}
      {showEditIcon && editButton}
    </Stack>
  );
}
