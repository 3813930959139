import clsx from "clsx";
import { useCallback, useState } from "react";
import { LinearProgress, Stack } from "@mui/material";
import { ScrollHeaderLayoutProps } from "./ScrollHeaderLayout.model";
import { ScrollBox } from "../ScrollBox/ScrollBox";
import { ScrollBoxProps } from "../ScrollBox/ScrollBox.model";
import { scrollHeaderLayoutClasses } from "./ScrollHeaderLayout.const";

export function ScrollHeaderLayout({
  children,
  renderHeader,
  headerMinHeight = 0,
  headerJustifyContent = "flex-end",
  scrollThreshold = 40,
  isLoading,
  customScrollBarStyle,
  scrollbarColor,
  onScroll,
  ...props
}: ScrollHeaderLayoutProps) {
  const [scrollTop, setScrollTop] = useState(0);
  const [headerRef, setHeaderEl] = useState<HTMLDivElement | null>(null);

  const contentHeight = headerRef?.scrollHeight;
  const scroll = Math.max(0, scrollTop - scrollThreshold);

  const height =
    typeof contentHeight === "number"
      ? Math.max(headerMinHeight, contentHeight - scroll / 2)
      : undefined;

  const transition =
    typeof height === "number"
      ? Math.max(0, height - scroll / 2) / height
      : 1.0;

  const onScrollWrapper = useCallback<NonNullable<ScrollBoxProps["onScroll"]>>(
    (event) => {
      const { currentTarget } = event;
      setScrollTop(currentTarget.scrollTop);
      onScroll?.(event);
    },
    [onScroll]
  );

  return (
    <Stack
      flex={1}
      overflow="hidden"
      position="relative"
      className={clsx(scrollHeaderLayoutClasses.root)}
      {...props}
    >
      {renderHeader ? (
        <Stack
          height={height && `${height}px`}
          justifyContent={headerJustifyContent}
        >
          <Stack ref={setHeaderEl}>
            {renderHeader({ transition, scrollTop })}
          </Stack>
        </Stack>
      ) : null}

      {isLoading ? (
        <LinearProgress
          sx={{
            position: "absolute",
            top: typeof height === "number" ? `${height}px` : undefined,
            left: 0,
            right: 0,
            zIndex: 3,
          }}
        />
      ) : null}

      <ScrollBox
        height="100%"
        customScrollBarStyle={customScrollBarStyle}
        children={children}
        onScroll={onScrollWrapper}
        scrollbarColor={scrollbarColor}
      />
    </Stack>
  );
}
