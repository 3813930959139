import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { ReportEventsTile } from "./ReportEventsTile";
import { ReportEventsTilesProps } from "./ReportEventsTiles.model";

export function ReportEventsTiles({
  id,
  filterData,
  lineClamp,
  renderEventsItemActions,
  selectionState,
  compactHorizontalPadding,
  getEventUrl,
  highlightedEventsIds,
  reportEventsTiles,
  renderHeader,
  headerMinHeight = 72,
}: ReportEventsTilesProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const handleScroll = useScrollQueryLoader({
    query: reportEventsTiles,
  });

  const renderItem = (data: PowerSearchResultItem) => {
    const isChecked = !!selectionState?.list.find(
      (event) => event.id === data.id
    );
    return (
      <ReportEventsTile
        key={data.id}
        value={data}
        lineClamp={lineClamp}
        isChecked={isChecked}
        renderEventsItemActions={renderEventsItemActions}
        selectionState={selectionState}
        getEventUrl={getEventUrl}
        highlightedEventsIds={highlightedEventsIds}
      />
    );
  };

  return (
    <ScrollHeaderLayout
      id={id}
      headerMinHeight={headerMinHeight}
      onScroll={handleScroll}
      renderHeader={renderHeader}
      isLoading={reportEventsTiles.isLoading || reportEventsTiles.isFetching}
      children={
        <Stack
          pl={compactHorizontalPadding ? 1 : 2}
          pr={compactHorizontalPadding ? 0 : 1}
          pb={1}
          flex={1}
          children={
            <TileLayout<PowerSearchResultItem>
              rows={filterData}
              renderItem={renderItem}
              renderEmpty={EventsNoResultsOverlay}
              gap={isMobile ? 3 : undefined}
            />
          }
        />
      }
    />
  );
}
