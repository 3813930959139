import { Dispatch, SetStateAction } from "react";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";

export enum DialogModes {
  avatar = "avatar",
  banner = "banner",
}

export type UploadImageDialogProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  selectedAvatar?: string | Blob | null;
  setSelectedAvatar?: Dispatch<
    SetStateAction<string | Blob | null | undefined>
  >;
  mode: DialogModes;
  page?: "account" | "user" | "settings";
};
