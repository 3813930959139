export const snapshotTileGroupConstants = {
  defaultHeaderHeight: 32,
  headerZIndex: 10,
};

export const snapshotTileGroupClasses = {
  root: "snapshotTileGroup-root",
  header: "snapshotTileGroup-header",
  content: "snapshotTileGroup-content",
};
