import { RefObject } from "react";
import { alpha, Palette, Stack, useTheme } from "@mui/material";
import { blue, green, purple } from "@mui/material/colors";

type CodeEditoOperatorBlockProps = {
  containerRef: RefObject<HTMLDivElement>;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (v: string) => void;
  label: string;
};

function makeColor(label: string, palette: Palette) {
  switch (label) {
    case "AND":
      return green["500"];
    case "OR":
      return blue["500"];
    case "NOT":
      return palette.warning.main;
    default:
      return purple["A200"];
  }
}

export const CodeEditoOperatorBlock = ({
  containerRef,
  onMouseEnter,
  onMouseLeave,
  onClick,
  label,
}: CodeEditoOperatorBlockProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => onClick(label)}
      height={32}
      width={70}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: alpha(palette.action.selected, 0.08),
        fontFamily: "monospace",
        color: makeColor(label, palette),
        fontSize: 14,
        borderRadius: 4,
        cursor: "pointer",
      }}
    >
      {label}
    </Stack>
  );
};
