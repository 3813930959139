import { differenceInSeconds } from "date-fns";
import { useTheme, Stack, Tooltip } from "@mui/material";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { TimeLineProgramProps } from "./TimeLineProgram.model";

export const TimeLineProgram = ({
  offset,
  program,
  secondsPerPixel,
}: TimeLineProgramProps) => {
  const { palette } = useTheme();
  const title = program.title || " - ";

  if (!program.startDateTime || !program.endDateTime) {
    return null;
  }

  const blockStartTime = new Date(program.startDateTime + "Z");
  const blockEndTime = new Date(program.endDateTime + "Z");
  const blokSpanSec = differenceInSeconds(blockEndTime, blockStartTime);
  const blockWidth = blokSpanSec ? blokSpanSec / secondsPerPixel : undefined;

  return (
    <Tooltip title={title}>
      <Stack
        width={blockWidth}
        boxSizing="border-box"
        height={40}
        px={0.25}
        position="absolute"
        top="50%"
        left={offset}
        sx={{ transform: "translateY(-50%)" }}
      >
        <Stack
          bgcolor={palette.info.main}
          borderRadius={2}
          justifyContent="center"
          overflow="hidden"
          px={2}
          py={0.25}
          flex={1}
        >
          <TextLineClamp
            variant="body1"
            lineHeight="1em"
            lineClamp={1}
            color={palette.info.contrastText}
          >
            {title}
          </TextLineClamp>
        </Stack>
      </Stack>
    </Tooltip>
  );
};
