import { FormControlLabel, SwitchProps, Typography } from "@mui/material";
import { AppSwitch, AppSwitchProps } from "src/components/AppSwitch";
import { useQueryParam } from "use-query-params";

export type DataLevelValue = "National" | "Regional";

export const useDataLevelQueryParam = (): [
  DataLevelValue,
  (value: string) => void
] => {
  const [dataLevel, setDataLevel] = useQueryParam(
    "dataLevel",
    {
      encode(value) {
        if (value === "Regional" || value === "National") return value;
      },
      decode(value) {
        if (value === "Regional" || value === "National") return value;
      },
      default: "Regional",
    },
    {
      updateType: "replaceIn",
    }
  );

  switch (dataLevel) {
    case "Regional":
    case "National":
      return [dataLevel, setDataLevel];
    default:
      return ["Regional", setDataLevel];
  }
};

export function DataLevelSwitch({
  value,
  onChange,
  size,
}: {
  value: DataLevelValue;
  onChange: (v: DataLevelValue) => void;
  size?: SwitchProps["size"];
}) {
  const handleOnChange: AppSwitchProps["onChange"] = (isChecked) => {
    const nextValue = isChecked ? "Regional" : "National";
    onChange(nextValue);
  };

  return (
    <FormControlLabel
      label={<Typography variant="body2">{value}</Typography>}
      sx={{ minWidth: 103 }}
      control={
        <AppSwitch
          size={size}
          value={value === "Regional"}
          onChange={handleOnChange}
        />
      }
    />
  );
}
