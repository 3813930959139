import {
  useTheme,
  autocompleteClasses,
  outlinedInputClasses,
  inputBaseClasses,
} from "@mui/material";

export const useAppAutocompleteInlineStyles = ({
  size = "medium",
}: {
  size?: "small" | "medium";
}) => {
  const { transitions } = useTheme();
  return {
    // inline tag styles
    [`& .${autocompleteClasses.inputRoot}`]: {
      // for scrolling instead of multiple lines
      flexWrap: "nowrap",
      overflow: "hidden",
      // make better usage of height to put scrollbars there
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: "88px!important",
      minHeight: size === "medium" ? "56px" : "48px",
    },

    [`.${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    // below is to make input wider when user interacts with it
    [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
      minWidth: 60,
      transition: transitions.create(["min-width"], {
        duration: 200,
      }),
    },
  };
};
