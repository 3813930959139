import { Box } from "@mui/material";
import { OriginType } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryAlertDelete } from "src/api/useWatchQueryAlertDelete";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { AlertType } from "src/models/AlertType";

type WatchQueryDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  queryId?: string;
  alert: AlertType | null;
  origin: OriginType;
};

export const ScheduledAlertDeleteDialog = ({
  onClose,
  queryId,
  alert,
  origin,
  ...props
}: WatchQueryDeleteDialogProps) => {
  const alertDelete = useWatchQueryAlertDelete({
    options: {
      onSettled: () => {
        onClose && onClose();
      },
      origin,
      type: "scheduled",
    },
  });

  const handleDelete = () => {
    if (!alert?.id || !queryId) return;

    alertDelete.mutate({
      params: {
        path: {
          queryId,
          alertId: alert.id,
        },
      },
    });
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      title="Delete the alert?"
      children={
        <Box pb={1}>
          Are you sure you want to remove the {alert?.type} rule?
        </Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      {...props}
    />
  );
};
