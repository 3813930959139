import Joi from "joi";
import { UserInformationSettingsFormValues } from "./UserInformationSettingsForm.model";

export const UserInformationSettingsFormSchema =
  Joi.object<UserInformationSettingsFormValues>({
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    email: Joi.string().required().label("Email"),
    phoneNumber: Joi.string().optional().allow("").label("Phone"),
    timezone: Joi.string().optional().label("Timezone").allow(""),
    trackLocation: Joi.boolean()
      .required()
      .label("Keep track of my current location"),
    account: Joi.string().label("Account"),
    roles: Joi.array().items(Joi.string().required()).required(),
  });
