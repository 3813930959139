import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { UserManagementFilterFormValues } from "./UserManagementFilterForm.model";
import { UserManagementFilterFormSchema } from "./UserManagementFilterForm.schema";

export const makeEmptyUserManagementFilterFormValues = (
  merge?: Partial<UserManagementFilterFormValues>
): UserManagementFilterFormValues => ({
  accountIds: merge?.accountIds || [],

  status: merge?.status || null,

  timezone: merge?.timezone || null,

  roles: merge?.roles || [],
});

type UseUserManagementFilterFormArgs = {
  initial?: Partial<UserManagementFilterFormValues>;
};

export function useUserManagementFilterForm(
  params?: UseUserManagementFilterFormArgs
) {
  const { initial } = params || {};

  const emptyFormValues = makeEmptyUserManagementFilterFormValues();
  const formHook = useForm<UserManagementFilterFormValues>({
    mode: "all",
    resolver: joiResolver(UserManagementFilterFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}
