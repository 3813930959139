import { ContentCopy, Edit, ExpandCircleDown } from "@mui/icons-material";
import {
  BoxProps,
  Typography,
  useTheme,
  Stack,
  Chip,
  Button,
  Grid,
  ClickAwayListener,
  Fade,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserItem } from "src/api/useUsersManagement";
import { useOpenedSelector } from "src/api/useOpenedSelector";
import {
  getUserHighestRole,
  getUserManagementAccount,
  getUserManagementCreationDate,
  getUserManagementEmail,
  getUserManagementExpirationDate,
  getUserManagementFirstName,
  getUserManagementFullName,
  getUserManagementId,
  getUserManagementLastName,
  getUserManagementRoles,
  getUserManagementStatus,
} from "src/api/useUserManagementResult.getters";
import { getRoleColor } from "src/pages/UserManagement/UserManagement.utils";
import { userManagementUpdateRoute } from "src/pages/UserManagementUpdate/UserManagementUpdate.route";
import { getStatusIcon } from "src/pages/AccountsManagement/components/AccountsManagementTable/AccountsManagementTable.utils";
import { getBorderColor } from "src/pages/AccountsManagement/components/AccountsManagementTable/AccountsManagementTable.utils";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { DuplicateUserFormValues } from "../DuplicateUserForm/DuplicateUserForm.model";
import { UserManagementActions } from "../UserManagementActions/UserManagementActions";

export function UserManagementTile({
  value,
  onUserDuplicate,
  onUserDeactivate,
  width = "100%",
  height = "100%",
  authUserHighestRole,
}: {
  value: UserItem;
  onUserDuplicate: (userData: DuplicateUserFormValues | null) => void;
  onUserDeactivate: (id: string, isActive: boolean) => void;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  authUserHighestRole: string;
}) {
  const { palette } = useTheme();

  const navigate = useNavigate();

  const onUserEdit = (userId: string) => {
    const url = userManagementUpdateRoute.makeUrl({
      userId,
    });
    navigate(url);
  };

  const openedSelector = useOpenedSelector();

  const roles = getUserManagementRoles(value);
  const lastRoleValue = roles[roles.length - 1];

  const handleTagCollapse = () => {
    const updatedValue =
      openedSelector.activeSelector !== value.id ? value.id : null;
    openedSelector.select(updatedValue);
  };
  const userHighestRole = getUserHighestRole(value);

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="visible"
      position="relative"
    >
      <Grid container px={2} pt={2}>
        <Grid item sm={7} pb={1}>
          <Tooltip title={getUserManagementFullName(value)}>
            <TextLineClamp
              variant="subtitle1"
              color="primary.main"
              lineClamp={1}
            >
              {getUserManagementFullName(value)}
            </TextLineClamp>
          </Tooltip>
        </Grid>
        <Grid item sm={5} pb={1}>
          <Stack flexDirection="row" justifyContent="end" gap={1}>
            <Chip
              variant="outlined"
              avatar={getStatusIcon(getUserManagementStatus(value))}
              label={getUserManagementStatus(value)}
              sx={{
                borderColor: getBorderColor(getUserManagementStatus(value)),
                textTransform: "capitalize",
              }}
            />
            <ClickAwayListener
              touchEvent={false}
              onClickAway={() => {
                openedSelector.select(null);
              }}
            >
              <Stack alignItems="end">
                {roles?.length ? (
                  <Chip
                    sx={{
                      maxWidth: 101,
                      backgroundColor: getRoleColor(palette, lastRoleValue),
                      "&:hover": {
                        backgroundColor: getRoleColor(palette, lastRoleValue),
                      },
                      color: palette.text.primary,
                    }}
                    deleteIcon={
                      <ExpandCircleDown
                        sx={{
                          rotate:
                            openedSelector.activeSelector === value.id
                              ? "180deg"
                              : undefined,
                        }}
                      />
                    }
                    onClick={handleTagCollapse}
                    onDelete={handleTagCollapse}
                    avatar={
                      <Avatar
                        sx={{
                          color: palette.text.primary,
                          backgroundColor: `${getRoleColor(
                            palette,
                            lastRoleValue
                          )} !important`,
                          filter: "brightness(0.85)",
                        }}
                      >
                        {roles.length}
                      </Avatar>
                    }
                    label={[roles[roles.length - 1]]}
                  />
                ) : null}

                <Fade
                  in={openedSelector.activeSelector === value.id}
                  timeout={
                    openedSelector.activeSelector === value.id ? 300 : 250
                  }
                >
                  <Stack>
                    <Stack
                      alignItems="end"
                      flexDirection="column"
                      position="absolute"
                      right={16}
                    >
                      {roles.map((role) => {
                        return (
                          <Chip
                            key={role}
                            sx={{
                              mt: 2,
                              backgroundColor: getRoleColor(palette, role),
                              "&:hover": {
                                backgroundColor: getRoleColor(palette, role),
                              },
                              color: palette.text.primary,
                            }}
                            label={role}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Fade>
              </Stack>
            </ClickAwayListener>
          </Stack>
        </Grid>

        <Grid item sm={4}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Account:
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Tooltip title={getUserManagementAccount(value)}>
            <TextLineClamp variant="subtitle2" lineClamp={1}>
              {getUserManagementAccount(value)}
            </TextLineClamp>
          </Tooltip>
        </Grid>

        <Grid item sm={4}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Email:
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Tooltip title={getUserManagementEmail(value)}>
            <TextLineClamp variant="subtitle2" lineClamp={1}>
              {getUserManagementEmail(value)}
            </TextLineClamp>
          </Tooltip>
        </Grid>

        <Grid item sm={4}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Creation date:
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Tooltip title={getUserManagementCreationDate(value)}>
            <TextLineClamp variant="subtitle2" lineClamp={1}>
              {getUserManagementCreationDate(value)}
            </TextLineClamp>
          </Tooltip>
        </Grid>

        <Grid item sm={4}>
          <Typography
            variant="body2"
            color="text.secondary"
            flexGrow={1}
            maxWidth={190}
          >
            Expiration date:
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Tooltip title={getUserManagementExpirationDate(value)}>
            <TextLineClamp variant="subtitle2" lineClamp={1}>
              {getUserManagementExpirationDate(value)}
            </TextLineClamp>
          </Tooltip>
        </Grid>
      </Grid>

      <Stack flexDirection="row" justifyContent="space-between" m={1}>
        <Stack flexDirection="row" gap={2}>
          <Button
            onClick={() => onUserEdit(getUserManagementId(value))}
            startIcon={<Edit />}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              const userData = {
                firstName: getUserManagementFirstName(value),
                lastName: getUserManagementLastName(value),
                email: getUserManagementEmail(value),
                id: getUserManagementId(value),
              };
              onUserDuplicate(userData);
            }}
            startIcon={<ContentCopy />}
          >
            Duplicate
          </Button>
        </Stack>
        <UserManagementActions
          id={getUserManagementId(value)}
          status={getUserManagementStatus(value)}
          onUserDeactivate={() =>
            onUserDeactivate(
              getUserManagementId(value),
              getUserManagementStatus(value) !== "active"
            )
          }
          authUserHighestRole={authUserHighestRole}
          userHighestRole={userHighestRole}
        />
      </Stack>
    </Stack>
  );
}
