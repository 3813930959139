import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/User/{id}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];

export type UserDataResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];

type Parameters = Endpoint["parameters"];

type RequestOptions = {
  params?: Parameters;
};

export const userDataKey = "userDataKey";

export function useUserData({
  request,
  options,
}: {
  request: RequestOptions;
  options?: UseQueryOptions<UserDataResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<UserDataResponse, unknown>({
    ...options,
    keepPreviousData: true,
    queryKey: [userDataKey, request],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request.params);
      const { data } = await axios<UserDataResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
