import { Children, SyntheticEvent, cloneElement, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Stack, tabsClasses } from "@mui/material";
import { AppTabLayoutProps } from "./AppTabLayout.model";

/**
 * A layout component that displays tabs with content
 * FIXME: split this into mobile and desktop versions
 */
export function AppTabLayout({
  id,
  children,
  overflow = "hidden",
  variant = "fullWidth",
  layout = "desktop",
  scrolling = 0,
  width,
  height,
  maxWidth,
  maxHeight,
  flex,
  onCurrentTabChange,
  currentTab,
  ...props
}: AppTabLayoutProps) {
  const initial = Array.isArray(children)
    ? children[0].props.value
    : children.props.value;

  const [value, setValue] = useState(initial);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
    onCurrentTabChange && onCurrentTabChange(newValue);
  };

  if (layout === "mobile-bottom-tabs" || layout === "mobile" || !variant) {
    variant = "fullWidth";
  }

  return (
    <Stack
      id={id}
      className="AppTabLayout-root"
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      flex={flex}
      direction={layout === "mobile-bottom-tabs" ? "column-reverse" : "column"}
      overflow={
        overflow === "auto"
          ? "hidden" // when we want tabs to scroll, we need to set parent container overflow to be hidden
          : overflow
      }
    >
      <TabContext value={currentTab || value}>
        <TabList
          onChange={handleChange}
          variant={variant}
          {...props}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            [`.${tabsClasses.indicator}`]: {
              display: layout === "mobile" ? "none" : undefined,
            },
          }}
        >
          {Children.map(children, (tab, index) => {
            return cloneElement(tab, {
              ...tab.props,
              layout,
            });
          })}
        </TabList>
        {Children.map(children, (tab, index) => (
          <TabPanel
            value={tab.props.value}
            key={`tab-panel-${index}`}
            sx={{
              flex: 1,
              p: 0,
              overflow: tab.props.overflow || overflow,
            }}
          >
            {tab.props.children}
          </TabPanel>
        ))}
      </TabContext>
    </Stack>
  );
}
