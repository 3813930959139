import { AppRouteBuilder } from "src/models/AppRoute";
import { DateTimeSearchClipPage } from "./DateTimeSearchClip.page";

export type DateTimeSearchClipPathParam = {
  // no params for now
};

export const dateTimeSearchClipRoute =
  new AppRouteBuilder<DateTimeSearchClipPathParam>({
    path: "/date-time-search/results/clip",
    element: <DateTimeSearchClipPage />,
  });
