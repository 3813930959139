import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import { DateTimeSearchFormSchema } from "src/pages/DateTimeSearchInitial/DateTimeSearchForm/DateTimeSearchForm.schema";
import { DateTimeSearchFormValues } from "./useDateTimeSearchForm";

export function useDateTimeSearchFetchParams({
  form,
}: {
  form: DateTimeSearchFormValues;
}) {
  const state = useDebounce<DateTimeSearchFormValues | null>(null, 300);
  const [fetchParams, setFetchParams] = state;
  const { market, station, dateTime } = form;

  useEffect(() => {
    const validationResult = DateTimeSearchFormSchema.validate({
      market,
      station,
      dateTime,
    });

    if (!validationResult.error) {
      setFetchParams({
        market,
        station,
        dateTime,
      });
    } else {
      setFetchParams(null);
    }
  }, [market, station, dateTime, setFetchParams]);

  return fetchParams;
}
