import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { getFullMediaItemLink } from "src/utils/getFullPublicPageLink";
import { useMediaCenterNotify } from "src/api/useMediaCenterNotify";
import { useSessionContext } from "src/api/useSessionContext";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";
import { ShareFormValues } from "../ShareForm/ShareForm.model";
import { ShareFormSchema } from "../ShareForm/ShareForm.schema";
import { ShareBaseDialog } from "../ShareBaseDialog/ShareBaseDialog";

type ShareMediaDialogProps = Omit<DialogBaseProps, "onSubmit" | "onClose"> & {
  itemsIds: string[];
  onSubmit: () => void;
  onClose: () => void;
  copyShareLinkCallback: () => void;
};

const defaultValues = {
  recipients: [],
  subject: "",
  note: "",
};

export const ShareMediaDialog = ({
  itemsIds,
  onClose,
  onSubmit,
  copyShareLinkCallback,
  ...props
}: ShareMediaDialogProps) => {
  const [instantLink, setInstantLink] = useState("");
  const { userId } = useSessionContext();

  const getInstantLink = () => {
    setInstantLink(getFullMediaItemLink(userId, itemsIds[0]));
  };

  const formHook = useForm<ShareFormValues>({
    mode: "all",
    resolver: joiResolver(ShareFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;
  const formValues = formHook.watch();

  const mediaCenterNotifyMutation = useMediaCenterNotify({});

  const notifyRecipients = () => {
    const { recipients, subject, note } = formValues;
    mediaCenterNotifyMutation.mutate({
      itemIds: itemsIds,
      recipients,
      subject,
      message: note,
    });
  };

  const handleSubmitWrapper = handleSubmit(() => {
    notifyRecipients();
    onClose();
    onSubmit();
  });

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open]);

  return (
    <FormProvider {...formHook}>
      <ShareBaseDialog
        hideInstantLink={itemsIds.length > 1}
        instantLinkValue={instantLink}
        getInstantLink={getInstantLink}
        copyShareLinkCallback={copyShareLinkCallback}
        onClose={onClose}
        isBusy={!formState.isValid || !formState.dirtyFields?.recipients}
        handleSubmit={handleSubmitWrapper}
        {...props}
      />
    </FormProvider>
  );
};
