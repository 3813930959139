import { useQueryParam } from "use-query-params";
import { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { Logo } from "src/components/logo/Logo";
import { TextInputBase } from "src/components/TextInputBase";
import { useUnsubscribeAll } from "src/api/useUnsubscribeAll";
import { useUnsubscribeOne } from "src/api/useUnsubscribeOne";
import { AppLayoutPublicHeader } from "src/components/AppLayoutPublicHeader";

const unsubscribeReasons = {
  noReason: {
    value: "dontProvide",
    title: "Don’t want to provide the reason",
  },
  tooManyNotifications: {
    value: "lotNotifications",
    title: "I get a lot of notifications",
  },
  noLongerRelevant: {
    value: "noRelevant",
    title: "No longer relevant",
  },
  leaveComment: {
    value: "leaveComment",
    title: "Leave a comment",
  },
} as const;

export const UnsubscribeAlertsPage = () => {
  const { palette } = useTheme();
  const unsubscribeAll = useUnsubscribeAll();
  const unsubscribeOne = useUnsubscribeOne();

  const [userId] = useQueryParam<string>("userId");
  const [queryId] = useQueryParam<string>("queryId");
  const [queryTitle] = useQueryParam<string>("title");

  const [reason, setReason] = useState<string>(
    unsubscribeReasons.noReason.value
  );

  const [comment, setComment] = useState("");

  const [disableAllAlerts, setDisableAllAlerts] = useState(false);

  useEffect(() => {
    if (reason !== unsubscribeReasons.leaveComment.value) {
      // reset comment when user picks predefined reason
      setComment("");
    }
  }, [reason]);

  const handleUnsubscribe = () => {
    const message =
      reason === unsubscribeReasons.leaveComment.value
        ? `${reason}: ${comment}` // send with comment
        : `${reason}`;

    if (disableAllAlerts) {
      unsubscribeAll.mutate({
        body: {
          reason: message,
        },
        params: {
          path: {
            userId,
          },
        },
      });
    } else {
      unsubscribeOne.mutate({
        body: {
          reason: message,
        },
        params: {
          path: {
            userId,
            queryId,
          },
        },
      });
    }
  };

  return (
    <>
      <AppLayoutPublicHeader />
      <Stack
        mx="auto"
        pb={4}
        width="100%"
        alignItems="center"
        bgcolor={palette.background.paper}
      >
        <Stack
          width="100%"
          height={56}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: palette.background.default }}
        >
          <Logo height={34} />
        </Stack>
        <Stack mt={6} width={346}>
          <Typography variant="subtitle1">
            Disable Instant Alerts for {queryTitle}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="center"
            mt={1}
          >
            Please select a reason and click the “Disable” button.
          </Typography>
          <RadioGroup
            sx={{ mt: 2 }}
            value={reason}
            onChange={(_, val) => setReason(val)}
          >
            <FormControlLabel
              value={unsubscribeReasons.noReason.value}
              label={unsubscribeReasons.noReason.title}
              control={<Radio />}
              sx={{
                px: 1,
                py: 2,
                backgroundColor: alpha(palette.primary.light, 0.08),
                borderRadius: 2,
                mx: 0,
              }}
            />
            <Typography variant="body1" p={1}>
              Another reasons
            </Typography>
            <FormControlLabel
              value={unsubscribeReasons.tooManyNotifications.value}
              label={unsubscribeReasons.tooManyNotifications.title}
              control={<Radio />}
              sx={{ pl: 1, mx: 0 }}
            />
            <FormControlLabel
              value={unsubscribeReasons.noLongerRelevant.value}
              label={unsubscribeReasons.noLongerRelevant.title}
              control={<Radio />}
              sx={{ pl: 1, mx: 0 }}
            />
            <FormControlLabel
              value={unsubscribeReasons.leaveComment.value}
              label={unsubscribeReasons.leaveComment.title}
              control={<Radio />}
              sx={{ pl: 1, mx: 0 }}
            />
          </RadioGroup>
          {reason === unsubscribeReasons.leaveComment.value && (
            <TextInputBase
              id="comment-input"
              label="Comment"
              fullWidth
              multiline
              rows={4}
              placeholder="Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              helperText={`${comment.length} / 200`}
            />
          )}
          <FormControlLabel
            label="Disable all alerts"
            control={
              <Switch
                checked={disableAllAlerts}
                onChange={(_, checked) => setDisableAllAlerts(checked)}
              />
            }
            sx={{ pl: 1, mx: 0, mt: 1 }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2.5 }}
            onClick={handleUnsubscribe}
            children="Disable"
          />
        </Stack>
      </Stack>
    </>
  );
};
