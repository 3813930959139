import { useMemo } from "react";
import { countries } from "countries-list";

export function useCountries() {
  const countriesList = useMemo(() => {
    return Object.values(countries)
      .map((country) => country.name)
      .sort();
  }, []);

  return countriesList;
}
