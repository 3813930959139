import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material";

export type CollapseExpandButtonProps = Omit<IconButtonProps, "children"> & {
  collapsed?: boolean;
  rotate?: number;
};

export const CollapseExpandButton = ({
  collapsed,
  rotate = 0,
  ...props
}: CollapseExpandButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      {...props}
      sx={{
        width: "24px",
        height: "24px",
        boxShadow: 4,
        background: theme.palette.background.paper,
        cursor: "pointer",
        opacity: 1,
        zIndex: 1,
        "&:hover": {
          background: theme.palette.background.paper,
        },
        ...props.sx,
      }}
    >
      <ChevronRightIcon
        sx={{
          rotate: collapsed ? `${rotate + 0}deg` : `${rotate + 180}deg`,
          transition: theme.transitions.create(
            "rotate",
            collapsed
              ? theme.mixins.drawer.collapsed.transition
              : theme.mixins.drawer.expanded.transition
          ),
        }}
      />
    </IconButton>
  );
};
