import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nFromBackend from "i18next-http-backend";

i18n
  .use(i18nFromBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      load: "languageOnly",
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    fallbackLng: "en",
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      // these can be loaded from JSON files as well - see loadPath above
      en: {
        routes: {
          // home: "Home",
        },
      },
      ua: {
        routes: {
          // home: "Додому",
        },
      },
    },
  })
  .catch((err) => console.error(err));
