import { ChevronLeft } from "@mui/icons-material";
import { Checkbox, IconButton, IconButtonProps, Stack } from "@mui/material";
import {
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { useIsMobile } from "src/utils/useIsMobile";
import { fixmeGetClusterParamsFromRowNodeId } from "../WatchQueryTable.utils";
import { WatchQueryTitle } from "./WatchQueryTitle";

export function WatchQueryTableTitleCell({
  params,
  isEditMode,
  dateRange,
}: {
  isEditMode: boolean;
  dateRange: [Date, Date];
  params: GridRenderCellParams<WatchListUserQueryDTO>;
}) {
  const { rowNode, id, field } = params;
  const apiRef = useGridApiContext();
  const isMobile = useIsMobile();
  const isChecked = apiRef.current.isRowSelected(id);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const toggle = useCallback(
    (nextIsChecked: boolean) => {
      apiRef.current.selectRow(id, nextIsChecked);
    },
    [apiRef, id]
  );

  if (rowNode.type === "group") {
    const handleClick: IconButtonProps["onClick"] = (event) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);

      event.stopPropagation();
    };

    const { clusterTitle } = fixmeGetClusterParamsFromRowNodeId(id);

    return (
      <Stack
        sx={{ ml: rowNode.depth * 4 }}
        direction="row"
        alignItems="center"
        columnGap={1}
      >
        <IconButton
          onClick={handleClick}
          children={
            <ChevronLeft
              sx={{
                rotate: rowNode.childrenExpanded ? "-90deg" : "+90deg",
              }}
            />
          }
        />

        <TextLineClamp
          lineClamp={2}
          whiteSpace="break-spaces"
          children={`${clusterTitle} (${filteredDescendantCount})`}
        />
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      columnGap={1}
      pl={rowNode.depth * 2}
      position="relative"
    >
      {isEditMode ? (
        <Checkbox
          onChange={(_e, checked) => toggle(checked)}
          checked={isChecked}
        />
      ) : null}
      <WatchQueryTitle
        query={params.row}
        dateRange={dateRange}
        placement={isMobile ? undefined : "right"}
      />
    </Stack>
  );
}
