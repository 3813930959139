import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationOptions } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/user/{userId}/query/{queryId}/unsubscribe";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestBody = NonNullable<
  Endpoint["requestBody"]
>["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
  body: RequestBody;
};

export function useUnsubscribeOne(
  options?: Omit<
    UseMutationOptions<Response, unknown, RequestMutationFnParams, void>,
    "mutationFn"
  >
) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiPath],
    mutationFn: async ({
      body,
      params,
    }: RequestMutationFnParams): Promise<Response> => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, params);
      const { data } = await axios<Response>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess(data, variables, context) {
      enqueueSnackbar(
        t("alertUnsubscribeOneSuccess", "You have unsubscribed"),
        {
          variant: "success",
        }
      );

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });

      options?.onError?.(error, variables, context);
    },
  });
}
