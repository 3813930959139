import { useCallback } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import { components } from "@tveyes/twosionwebapischema";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { useManagementView } from "src/api/useManagementView";
import defaultAvatar from "src/pages/Settings/components/assets/defaultAvatar.png";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { SettingsContactForm } from "./SettingsContactForm/SettingsContactForm";

type contact = components["schemas"]["UserCardViewDTO"];

export function SettingsContactTab({
  isRegularUser = true,
  isLoading,
}: {
  isRegularUser?: boolean;
  isLoading: boolean;
}) {
  const { data } = useManagementView();
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const adminContent = (
    <Stack
      component={Paper}
      p={4}
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack justifyContent="center">
            <TextLineClamp flex={1} variant="h6" mb={2} wordBreak="break-all">
              Contact us
            </TextLineClamp>
            <Typography variant="body1" color={"text.secondary"}>
              To learn more about TVEyes, call{" "}
              <AppLink title="203-254-3600" to={`mailto:203-254-3600`} /> and
              select option 1 or email us at:{" "}
              <AppLink
                title="hello@tveyes.com"
                to={`mailto:hello@tveyes.com`}
              />
              . If you prefer, fill out this form and the TVEyes team will
              contact you!
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <SettingsContactForm />
        </Grid>
      </Grid>
    </Stack>
  );

  const rederContact = useCallback(
    (contact: contact) => {
      if (isMobile) {
        return (
          <Grid container mt={2}>
            <Grid item xs={3}>
              <img
                src={contact.profileImage || ""}
                alt={contact.userTitle || ""}
                width={70}
                height={70}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = defaultAvatar;
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack gap={1} mt={1}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >{`${contact.firstName} ${contact.lastName}`}</Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  color={"text.secondary"}
                >
                  {contact?.userTitle}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                borderTop={`1px solid ${palette.divider}`}
              >
                {contact.phone && (
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <IconButton>
                      <PhonePausedIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                    <AppLink
                      title={contact.phone}
                      to={`tel:${contact.phone}`}
                    />
                  </Typography>
                )}
                {contact.email && (
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <IconButton>
                      <EmailIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                    <AppLink
                      title={contact?.email || ""}
                      to={`mailto:${contact.email}`}
                    />
                  </Typography>
                )}
                {contact.website && (
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <IconButton>
                      <LanguageIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                    <AppLink
                      title={contact.website || ""}
                      to={`https://${contact.website}`}
                    />
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid container mt={2}>
          <Grid item xs={6}>
            <Stack direction="row" gap={3} flexWrap="wrap">
              <Stack
                direction="column"
                marginTop="auto"
                marginBottom="auto"
                height={"100%"}
              >
                <img
                  src={contact.profileImage || ""}
                  alt={contact.userTitle || ""}
                  width={70}
                  height={70}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = defaultAvatar;
                  }}
                />
              </Stack>
              <Stack gap={1} mt={1}>
                <Typography>{`${contact.firstName} ${contact.lastName}`}</Typography>
                <Typography color={"text.secondary"}>
                  {contact?.userTitle}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="column"
              borderLeft={`1px solid ${palette.divider}`}
              pl={1}
            >
              {contact.phone && (
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <IconButton>
                    <PhonePausedIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <AppLink title={contact.phone} to={`tel:${contact.phone}`} />
                </Typography>
              )}
              {contact.email && (
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <IconButton>
                    <EmailIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <AppLink
                    title={contact?.email || ""}
                    to={`mailto:${contact.email}`}
                  />
                </Typography>
              )}
              {contact.website && (
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <IconButton>
                    <LanguageIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <AppLink
                    title={contact.website || ""}
                    to={`https://${contact.website}`}
                  />
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      );
    },
    [isMobile, palette]
  );

  const defaultContent = (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={6}>
        {data?.supportContacts?.map((contact, index) => (
          <Stack
            key={index}
            px={2}
            pt={3}
            pb={1}
            flex={1}
            justifyContent="center"
            component={Paper}
            mb={2}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Typography variant="subtitle1">Management contact</Typography>
            {rederContact(contact)}
          </Stack>
        ))}
        {data?.managementContacts?.map((contact, index) => (
          <Stack
            key={index}
            px={2}
            pt={3}
            pb={1}
            flex={1}
            justifyContent="center"
            component={Paper}
            mb={2}
          >
            <Typography variant="subtitle1">Support contact</Typography>
            {rederContact(contact)}
          </Stack>
        ))}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Stack
          p={4}
          flex={1}
          justifyContent="center"
          component={Paper}
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <TextLineClamp
            flex={1}
            variant="h6"
            lineClamp={1}
            mr={2}
            mb={2}
            wordBreak="break-all"
          >
            Contact us
          </TextLineClamp>
          <Typography variant="body1" color={"text.secondary"}>
            To learn more about TVEyes, call{" "}
            <AppLink title="203-254-3600" to={`mailto:203-254-3600`} /> and
            select option 1 or email us at:{" "}
            <AppLink title="hello@tveyes.com" to={`mailto:hello@tveyes.com`} />.
            If you prefer, fill out this form and the TVEyes team will contact
            you!
          </Typography>
          {!isMobile && (
            <Stack pt={4}>
              <SettingsContactForm />
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );

  if (isLoading) return <LoadingOverlay />;

  return isRegularUser ? defaultContent : adminContent;
}
