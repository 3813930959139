import { Box } from "@mui/system";
import { useSessionContext } from "src/api/useSessionContext";

export function FeatureGateDemoPage() {
  const session = useSessionContext();

  return (
    <Box display="flex" flexDirection="column" m="auto">
      <pre>{JSON.stringify(session, null, 2)}</pre>
    </Box>
  );
}
