import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths, components } from "@tveyes/twosionwebapischema";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import { QueryDefinition } from "src/models/QueryDefinition";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";

const apiKey: keyof paths = "/api/Geography/geo-aggregations";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type EndpointRes = Endpoint["responses"]["200"]["content"]["application/json"];

export type QueryGeoAnalytics = components["schemas"]["GeoAggregationDataDTO"];

export function useQueryGeoAnalytics({
  queryIds,
  filters,
  dateRange,
  sortOrder = "asc",
}: {
  queryIds: string[];
  dateRange: [Date, Date];
  filters?: QueryDefinition;
  sortOrder?: "asc" | "desc";
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [apiKey, queryIds, dateRange, filters, sortOrder],
    keepPreviousData: true,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey);

      const body: Endpoint["requestBody"]["content"]["application/json"] & {
        // FIXME: BE schema missing sort order
        orderby: string;
      } = {
        queryIdentifiers: queryIds,
        startDate: formatDateTimeWithoutTimeZone(dateRange[0]),
        endDate: formatDateTimeWithoutTimeZone(dateRange[1]),
        filters,
        orderby: sortOrder,
      };

      const { data } = await axios<EndpointRes>({
        url,
        method,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
