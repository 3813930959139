import { create } from "zustand";
import { EventSourceOption } from "src/api/useEventSources";
import {
  Advertisement,
  queryAdvertisementValues,
} from "src/models/Advertisement";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";
import { ProgramTagPickerValue } from "src/components/ProgramTagPicker/ProgramPicker.model";

export type PowerSearchSharedValues = {
  startDateTime: Date | null;
  endDateTime: Date | null;
  sourcesInclude: TagPickerValue<EventSourceOption>;
  sourcesExclude: TagPickerValue<EventSourceOption>;
  advertisement: Advertisement;
  programInclude: ProgramTagPickerValue;
  programExclude: ProgramTagPickerValue;
};

export const emptySharedValues = {
  startDateTime: null,
  endDateTime: null,
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type PowerSearchQueryState = {
  sharedValues: PowerSearchSharedValues;
  saveSharedValue: <T extends keyof PowerSearchSharedValues>(
    name: T,
    value: PowerSearchSharedValues[T]
  ) => void;
  clearSharedValues: () => void;
};

export const usePowerSearchSharedForm = create<PowerSearchQueryState>(
  (setState, getState) => ({
    sharedValues: emptySharedValues,
    saveSharedValue: (name, value) => {
      const prev = getState();
      setState({
        sharedValues: {
          ...prev.sharedValues,
          [name]: value,
        },
      });
    },
    clearSharedValues: () => {
      setState({
        sharedValues: emptySharedValues,
      });
    },
  })
);
