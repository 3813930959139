import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { DialogBase } from "../DialogBase/DialogBase";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";

export const ClipViewSettingsDialog = ({
  open,
  onClose,
  isAutoScroll,
  onAutoScrollChange,
  isHighlight,
  onHighlightChange,
}: Pick<DialogBaseProps, "open" | "onClose"> & {
  //
  isAutoScroll?: boolean;
  onAutoScrollChange: (next: boolean) => void;
  //
  isHighlight?: boolean;
  onHighlightChange: (next: boolean) => void;
}) => {
  return (
    <DialogBase
      open={open}
      title="Playback Settings"
      maxWidth="xs"
      extraMobileMargin={2}
      disableHeaderDivider
      hideIntercomButton
      footer={
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={onClose}
            children="Close"
          />
        </Stack>
      }
      children={
        <Stack rowGap={2}>
          <FormControlLabel
            label="Autoscroll"
            control={
              <Switch
                checked={isAutoScroll}
                onChange={(_e, v) => onAutoScrollChange(v)}
              />
            }
          />

          <FormControlLabel
            label="Highlight"
            control={
              <Switch
                checked={isHighlight}
                onChange={(_e, v) => onHighlightChange(v)}
              />
            }
          />
        </Stack>
      }
    />
  );
};
