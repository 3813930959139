import {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useQueryParam } from "use-query-params";
import Papa from "papaparse";
import { Add } from "@mui/icons-material";
import { SearchInput } from "src/components/SearchInput";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { UserManagementTable } from "src/pages/UserManagement/components/UserManagementTable/UserManagementTable";
import { UserManagementTiles } from "src/pages/UserManagement/components/UserManagementTiles/UserManagementTiles";
import { accountsManagementAddUserRoute } from "src/pages/AccountsManagementAddUser/AccountsManagementAddUser.route";
import { useSessionContext } from "src/api/useSessionContext";
import { useAccount } from "src/api/useAccount";
import { defaultUMBody, useUsersManagement } from "src/api/useUsersManagement";
import { useBulkUsers } from "src/api/useBulkUsersCreate";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { AccountsManagementUsersFields } from "src/pages/AccountsManagementCreateAccount/components/AccountsManagementUsersFields/AccountsManagementUsersFields";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { SortingByColumn } from "src/components/SortingByColumn/SortingByColumn";
import { OrderByParameters } from "src/components/SortingMenu/SortingMenu.model";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { NewUser } from "src/pages/AccountsManagementCreateAccount/components/AccountsManagementNewAccountForm/AccountsManagementUsersForm.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { ManagementUsersListProps } from "./ManagementUsersList.model";
import { UserManagementFilterButton } from "./UserManagementFilterButton/UserManagementFilterButton";
import { DuplicateUserFormValues } from "../DuplicateUserForm/DuplicateUserForm.model";
import { DuplicateUserDialog } from "../DuplicateUserDialog/DuplicateUserDialog";
import { UserManagementLayoutButton } from "./UserManagementLayoutButton/UserManagementLayoutButton";

const viewModeOptions = [ViewMode.table, ViewMode.tile];
const viewModeQueryKey = "tab";

function buildBody(newValues = {}, search: string, userId?: string) {
  return {
    ...defaultUMBody,
    ...newValues,
    accountManagerId: userId ?? null,
    search,
  };
}

export function ManagementUsersList({
  showAddUserButton,
  filterDialog,
  filterQuery,
  accountId = "",
  isExpanded,
  setIsExpanded,
}: ManagementUsersListProps) {
  const navigate = useNavigate();

  const { userId } = useSessionContext();

  const [pagination, setPagination] = useQueryPagination();

  const initialViewMode: ViewMode = ViewMode.table;
  const tabParam = useQueryParam<ViewMode>(viewModeQueryKey);
  const [viewMode = initialViewMode, setViewMode] = tabParam;

  const [sortBy, setSortBy] = useState("default");
  const [orderBy, setOrderBy] = useState<OrderByParameters>("asc");

  const [filterText, setFilterText] = useState("");
  const [duplicateUserData, setDuplicateUserData] =
    useState<DuplicateUserFormValues | null>(null);
  const [myUsersOnly, setMyUsersOnly] = useState(false);

  const { data, isLoading } = useUsersManagement({
    request: {
      params: {
        query: {
          from: pagination.page * pagination.pageSize,
          size: pagination.pageSize,
          sortby: sortBy,
          orderby: orderBy,
        },
      },
      body: buildBody(
        filterQuery,
        filterText,
        myUsersOnly ? userId : undefined
      ),
    },
  });

  const { data: accountData } = useAccount({
    request: {
      path: {
        id: accountId,
      },
    },
  });
  const usersDialog = useOpenState();
  const createUsers = useBulkUsers({});

  const { control, handleSubmit } = useForm<NewAccountFormValues>({
    defaultValues: {
      users: [],
    },
  });

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "users",
  });

  const addUserUrl = accountsManagementAddUserRoute.makeUrl({
    accountId: accountData?.id || "",
  });

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder="Filter by text..."
        onTextChangeThrottled={setFilterText}
        sx={{ minWidth: 168 }}
      />
    ),
    [setFilterText]
  );

  const onUserDuplicate = useCallback(
    (userData: DuplicateUserFormValues | null) =>
      setDuplicateUserData(userData),
    [setDuplicateUserData]
  );

  const onSortChange = (column: string, order: OrderByParameters) => {
    setSortBy(column);
    setOrderBy(order);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const file = e.target?.files[0];
      if (file) {
        Papa.parse<NewUser>(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data) replace(results.data);
            usersDialog.show();
          },
        });
      }
    }
  };

  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const dataViewElement = useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <UserManagementTable
              id="users-management-table"
              rows={data?.results || []}
              rowCount={data?.total || 0}
              loading={isLoading}
              paginationMode="server"
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              onUserDuplicate={onUserDuplicate}
            />
          ),
          tile: (
            <UserManagementTiles
              id="users-management-tiles"
              filterText={filterText}
              filterQuery={filterQuery}
              myUsersOnly={myUsersOnly}
              sortBy={sortBy}
              orderBy={orderBy}
              hideFooter
              onUserDuplicate={onUserDuplicate}
              accountId={accountId}
            />
          ),
        }}
      />
    );
  }, [
    accountId,
    data?.results,
    data?.total,
    myUsersOnly,
    onUserDuplicate,
    viewMode,
    filterText,
    filterQuery,
    sortBy,
    orderBy,
    pagination,
    setPagination,
    isLoading,
  ]);

  const closeUsersDialog = () => {
    fields.forEach((_field, index) => remove(index));
    usersDialog.hide();
  };

  const handleSubmitUsers = handleSubmit((data) => {
    createUsers.mutate({ query: data.users, accountId });
    usersDialog.hide();
  });

  const sortingMenu = (
    <SortingByColumn
      onChange={onSortChange}
      order={orderBy}
      column={sortBy}
      columns={[
        {
          column: "first",
          label: "First Name",
        },
        {
          column: "last",
          label: "Last Name",
        },
        {
          column: "email",
          label: "Email",
        },
        {
          column: "createdate",
          label: "Creation",
          type: "date",
        },
        {
          column: "expiredate",
          label: "Expiration",
          type: "date",
        },
      ]}
    />
  );

  return (
    <>
      <Grid container p={2} spacing={2}>
        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          {filterInput}
        </Grid>

        <Grid
          item
          xs={6}
          md={true}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems={{ md: "end" }}
        >
          {!accountId && setMyUsersOnly ? (
            <FormControlLabel
              checked={!!myUsersOnly}
              control={
                <Switch onChange={(_e, checked) => setMyUsersOnly(checked)} />
              }
              label={
                <Typography variant="body1">
                  Show only users from my accounts
                </Typography>
              }
              sx={{ mx: 0 }}
            />
          ) : (
            <>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                hidden
                ref={inputRef}
              />
              <Button
                variant="text"
                onClick={() => inputRef?.current?.click()}
                color="inherit"
                sx={{
                  fontWeight: 600,
                }}
              >
                Import from CSV
              </Button>
            </>
          )}
        </Grid>

        <Grid
          item
          xs={6}
          md="auto"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {showAddUserButton && (
              <Button
                startIcon={<Add />}
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(addUserUrl, {
                    state: { accountId: accountId, accountData },
                  })
                }
                sx={{
                  width: 164,
                }}
              >
                Add user
              </Button>
            )}
            <UserManagementFilterButton
              value={filterQuery}
              onClick={filterDialog.show}
            />
            {sortingMenu}
            <ViewModeSwitch
              value={viewMode}
              onChange={setViewMode}
              options={viewModeOptions}
              size="medium"
            />
            {!accountId && (
              <UserManagementLayoutButton
                isExpanded={isExpanded}
                onClick={setIsExpanded}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      {dataViewElement}

      <DuplicateUserDialog
        title="Duplicate user"
        open={Boolean(duplicateUserData)}
        onClose={() => onUserDuplicate(null)}
        userData={duplicateUserData}
      />
      <DialogBase
        extraMobileMargin={3}
        title={"Import Users"}
        maxWidth="md"
        fullWidth
        disableHeaderDivider
        open={usersDialog.isOpen}
        children={
          <Fragment>
            <AccountsManagementUsersFields
              fields={fields}
              remove={remove}
              control={control}
            />
          </Fragment>
        }
        footer={
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: 184,
              }}
              onClick={closeUsersDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: 184,
                ml: 1,
              }}
              //disabled={isBusy}
              onClick={handleSubmitUsers}
            >
              Add Users
            </LoadingButton>
          </Stack>
        }
      />
    </>
  );
}
