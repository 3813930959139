import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import ManageSearchOutlined from "@mui/icons-material/ManageSearchOutlined";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";

export const GlobalSearchDesktopButton = () => {
  const watchQuerySearchDialog = useGlobalSearchOpen();
  return (
    <ToggleButtonGroup>
      <ToggleButton
        value={true}
        onClick={watchQuerySearchDialog.show}
        sx={{ width: 42, height: 42 }}
      >
        <Tooltip title={"Global search"}>
          <ManageSearchOutlined color="primary" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
