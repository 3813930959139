import { useMemo, useEffect, useState, useCallback } from "react";
import {
  badgeClasses,
  Badge,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useNavigate,
  useParams,
  useBlocker,
  BlockerFunction,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { ReportCreateFormSchema } from "src/components/ReportCreateForm/ReportCreateForm.schema";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { PageLoader } from "src/components/PageLoader";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { LeaveConfirmationDialog } from "src/components/LeaveConfirmationDialog/LeaveConfirmationDialog";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { useReport } from "src/api/useReport";
import { useReportEvents } from "src/api/useReportEvents";
import { useReportEventsInfinite } from "src/api/useReportEventsInfinite";
import { useReportEdit } from "src/api/useReportEdit";
import { useReportEventsAppend } from "src/api/useReportEventsAppend";
import { useReportEventsRemove } from "src/api/useReportEventsRemove";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionReport } from "src/api/useEventSelectionReport";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { makeAppRouteUrl } from "src/utils/makeAppRouteUrl";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { useReportScratchRemoveEvents } from "src/api/useReportScratchRemoveEvents";
import { ReportCreateActionButtonsMobile } from "../ReportCreate/components/ReportCreateActionButtonsMobile";
import { ReportCreateInfoTab } from "../ReportCreate/components/ReportCreateInfoTab/ReportCreateInfoTab";
import { ReportPreviewTab } from "../ReportCreate/components/ReportPreviewTab/ReportPreviewTab";
import { reportsRoute } from "../Reports/Reports.route";
import {
  ReportsPageTabMode,
  reportsPageTabKey,
} from "../Reports/Reports.const";
import { ReportEditEventsTab } from "./components/ReportEditEventsTab";

export enum PageTabMode {
  info = "info",
  events = "events",
  preview = "preview",
}

const pageTabKey = "pageTab";

export function ReportEditPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { reportId = "" } = useParams();
  const [pageTabMode, setPageTabMode] = useViewModeQueryParam<PageTabMode>({
    paramKey: pageTabKey,
    defaultValue: PageTabMode.info,
  });
  const selectionState = useEventSelectionReport();
  const [hasEventsChanges, setHasEventsChanges] = useState(false);
  const [pagination, setPagination] = useQueryPagination();
  const [dateRange, setDateRange] = useQueryDateRangeWithEmptyDefault();
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewModeWrapper] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const removeScratchEvents = useReportScratchRemoveEvents({});

  const reportData = useReport({
    path: {
      id: reportId,
    },
  });

  const [eventsToAdd, setEventsToAdd] = useState<PowerSearchResultItem[]>([]);
  const [eventsToRemove, setEventsToRemove] = useState<PowerSearchResultItem[]>(
    []
  );

  const reportEventsWithoutDateFilter = useReportEvents({
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: 0,
        size: 1,
      },
    },
    excludeFilter: eventsToRemove.map((ev) => ev.id),
  });

  const reportEvents = useReportEvents({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
      },
    },
    excludeFilter: eventsToRemove.map((ev) => ev.id),
  });

  const reportEventsTiles = useReportEventsInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    params: {
      path: {
        id: reportId,
      },
      query: {
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
      },
    },
    excludeFilter: eventsToRemove.map((ev) => ev.id),
  });

  const reportEventsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return [];
      return combineInfiniteTilesData(reportEventsTiles.data?.pages);
    }
    if (reportEvents.isError) return [];
    return reportEvents.data?.results || [];
  }, [
    viewMode,
    reportEvents.data,
    reportEventsTiles.data,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const reportsEventsTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsTiles.isError) return 0;
      const tilesPagesData = reportEventsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportEvents.isError) return 0;
    return reportEvents.data?.total || 0;
  }, [
    viewMode,
    reportEventsTiles.data?.pages,
    reportEvents.data?.total,
    reportEvents.isError,
    reportEventsTiles.isError,
  ]);

  const updatedEventsLength = reportsEventsTotal + eventsToAdd.length;
  const updatedEventsWithoutDateFilterLength =
    (reportEventsWithoutDateFilter.data?.total || 0) + eventsToAdd.length;

  const updatedEventsList = useMemo(() => {
    if (!reportEventsData) {
      return [];
    }
    return [...eventsToAdd, ...reportEventsData];
  }, [reportEventsData, eventsToAdd]);

  const removeEvents = useCallback(
    (events: PowerSearchResultItem[]) => {
      setEventsToRemove([...eventsToRemove, ...events]);
      const updatedEventsToAdd = eventsToAdd.filter(
        (event) => !events.find((ev) => ev.id === event.id)
      );
      setEventsToAdd(updatedEventsToAdd);
      setHasEventsChanges(true);
      selectionState.unselectSome(events);
    },
    [eventsToRemove, eventsToAdd, selectionState]
  );

  const addEvents = useCallback(
    (events: PowerSearchResultItem[]) => {
      setEventsToAdd([...eventsToAdd, ...events]);
      const updatedEventsToRemove = eventsToRemove.filter(
        (event) => !events.find((ev) => ev.id === event.id)
      );
      setEventsToRemove(updatedEventsToRemove);
      setHasEventsChanges(true);
    },
    [eventsToAdd, eventsToRemove]
  );

  const formHook = useForm<ReportCreateFormValues>({
    mode: "all",
    resolver: joiResolver(ReportCreateFormSchema),
    defaultValues: {
      title: reportData.data?.title || "",
      description: reportData.data?.description || "",
    },
  });

  const { formState, handleSubmit, reset, getValues } = formHook;

  const reportWasChanged = formState.isDirty || hasEventsChanges;
  const isSaveReportDisabled =
    !formState.isValid || !reportWasChanged || !updatedEventsLength;

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        !formState.isSubmitted &&
        reportWasChanged &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [formState.isSubmitted, reportWasChanged]
  );
  const blocker = useBlocker(shouldBlockLeaving);

  useEffect(() => {
    if (!reportData.isLoading) {
      reset({
        title: reportData.data?.title || "",
        description: reportData.data?.description || "",
      });
    }
  }, [
    reset,
    reportData.data?.title,
    reportData.data?.description,
    reportData.isLoading,
  ]);

  const reportEdit = useReportEdit({
    options: {
      onSuccess: () => {
        const url = reportsRoute.makeUrl(undefined, {
          pageTab: ReportsPageTabMode.reports,
        });

        navigate(url);
      },
    },
  });

  const reportAddEvents = useReportEventsAppend({
    options: {
      onSuccess: () => {
        if (eventsToAdd.length) {
          removeScratchEvents.mutate({
            events: eventsToAdd,
          });
        }
      },
    },
  });
  const reportRemoveEvents = useReportEventsRemove({});

  const saveReport = handleSubmit(
    ({ title, description }) => {
      if (reportData.data?.id && updatedEventsLength && !reportEdit.isLoading) {
        reportEdit.mutate({
          report: {
            ...reportData.data,
            title,
            description: description || "",
          },
          isMobile,
        });

        if (eventsToAdd.length) {
          reportAddEvents.mutate({
            params: {
              path: {
                id: reportId,
              },
            },
            events: eventsToAdd,
          });
        }

        if (eventsToRemove.length) {
          reportRemoveEvents.mutate({
            params: {
              path: {
                id: reportId,
              },
            },
            events: eventsToRemove,
          });
        }
      }
    },
    (errors) => {
      console.log("@@ DEBUG:submitReportEdit:error", errors);
    }
  );

  const isBusy = formState.isSubmitting || reportEdit.isLoading;

  const handleChangePageTabMode = (newValue: string) => {
    setPageTabMode(newValue as PageTabMode);
  };

  const tabContent = useMemo(() => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {pageTabMode === PageTabMode.info ? (
          <ReportCreateInfoTab isBusy={isBusy} />
        ) : pageTabMode === PageTabMode.events ? (
          <ReportEditEventsTab
            isLoading={reportEvents.isFetching}
            isBusy={isBusy}
            data={updatedEventsList}
            removeEvents={removeEvents}
            addEvents={addEvents}
            rowCount={updatedEventsLength}
            rowCountWithoutDateFilter={updatedEventsWithoutDateFilterLength}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            sortOption={sortOption}
            setSortOption={setSortOption}
            setSortBy={setSortBy}
            setOrderBy={setOrderBy}
            highlightedEventsIds={eventsToAdd.map((ev) => ev.id)}
            dateRange={dateRange}
            setDateRange={setDateRange}
            viewMode={viewMode}
            setViewModeWrapper={setViewModeWrapper}
            reportEventsTiles={reportEventsTiles}
          />
        ) : pageTabMode === PageTabMode.preview ? (
          <ReportPreviewTab
            title={getValues().title}
            description={getValues().description}
            audienceInformation={reportData.data?.audienceInformation}
            localViewership={reportData.data?.localViewership}
            nationalViewership={reportData.data?.nationalViewership}
            events={updatedEventsList}
            rowCount={updatedEventsLength}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            isLoading={reportEvents.isFetching}
            sortOption={sortOption}
            setSortOption={setSortOption}
            setSortBy={setSortBy}
            setOrderBy={setOrderBy}
            viewMode={viewMode}
            setViewModeWrapper={setViewModeWrapper}
            reportEventsTiles={reportEventsTiles}
          />
        ) : null}
      </Paper>
    );
  }, [
    pageTabMode,
    isBusy,
    reportEvents.isFetching,
    updatedEventsList,
    updatedEventsLength,
    updatedEventsWithoutDateFilterLength,
    removeEvents,
    addEvents,
    getValues,
    pagination,
    setPagination,
    sortOption,
    eventsToAdd,
    dateRange,
    setDateRange,
    viewMode,
    setViewModeWrapper,
    reportEventsTiles,
    reportData.data?.audienceInformation,
    reportData.data?.localViewership,
    reportData.data?.nationalViewership,
  ]);

  const reportTabs = (
    <AppTabLayout
      variant={isMobile ? "fullWidth" : "scrollable"}
      height="100%"
      currentTab={pageTabMode}
      onCurrentTabChange={handleChangePageTabMode}
    >
      <AppTab
        label={isMobile ? "Info" : "General Info"}
        value={PageTabMode.info}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
      <AppTab
        label={
          <Badge
            badgeContent={`${updatedEventsWithoutDateFilterLength ?? 0}`}
            color="primary"
            sx={{
              [`.${badgeClasses.badge}`]: {
                top: 10,
                right: 4,
              },
            }}
          >
            <Typography sx={{ pr: 3.5, fontSize: 14, fontWeight: 500 }}>
              {isMobile ? "Events" : "Report Events"}
            </Typography>
          </Badge>
        }
        value={PageTabMode.events}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
      <AppTab
        label="Preview"
        value={PageTabMode.preview}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
    </AppTabLayout>
  );

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title={reportData.data?.title || ""}
        hideBackButton
        toolbar={
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ sm: 1, md: 2 }}
            ml="auto"
          >
            <GlobalSearchDesktopButton />
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: 160, height: 42 }}
              onClick={() =>
                navigate(
                  makeAppRouteUrl(reportsRoute.path, {
                    query: { [reportsPageTabKey]: ReportsPageTabMode.reports },
                  })
                )
              }
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ width: 160, height: 42 }}
              disabled={isSaveReportDisabled}
              loading={isBusy}
              onClick={saveReport}
            >
              Save report
            </LoadingButton>
          </Stack>
        }
        breadcrumbLabels={{
          "1": "Reports",
          "0": "Edit Report",
        }}
      />
    ),
    [reportData.data?.title, isBusy, navigate, saveReport, isSaveReportDisabled]
  );

  const mobileContent = (
    <>
      <Stack height="100%" sx={{ overflowY: "auto" }}>
        <Stack direction="row" justifyContent="space-between" pr={2}>
          <AppTopNavBarMobile title={reportData.data?.title || ""} />
        </Stack>
        {reportTabs}
      </Stack>
      {!!updatedEventsLength && (
        <ReportCreateActionButtonsMobile
          disabled={isSaveReportDisabled}
          isLoading={isBusy}
          saveReport={saveReport}
        />
      )}
    </>
  );

  if (reportData.isLoading || reportEvents.isLoading) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formHook}>
      {isMobile ? (
        <PageLayoutMobile content={mobileContent} />
      ) : (
        <PageLayoutDesktop
          header={headerDesktop}
          content={reportTabs}
          overflow="hidden"
        />
      )}

      {blocker && (
        <LeaveConfirmationDialog
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
          onConfirm={() => blocker.proceed?.()}
        />
      )}

      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
    </FormProvider>
  );
}
