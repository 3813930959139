import { useState } from "react";
import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ClusterTagPicker } from "src/components/ClusterTagPicker/ClusterTagPicker";
import { ClusterOption } from "src/components/ClusterTagPicker/ClusterTagPicker.model";
import { clusterSpecialOptions } from "src/api/useWatchQueryClusterUnassign";
import { WatchListClusterAssignFormValues } from "./WatchListClusterAssignForm.model";

export function WatchListClusterAssignForm() {
  const { control } = useFormContext<WatchListClusterAssignFormValues>();
  const [userOptions, setUserOptions] = useState<ClusterOption[]>([]);

  return (
    <Stack id="cluster-assign-form" pt={3}>
      <Controller
        control={control}
        name="cluster"
        render={({ field, fieldState }) => {
          const isNewCluster = field.value && field.value?.id === null;
          const helperText = fieldState.error
            ? fieldState.error.message
            : isNewCluster
            ? "New cluster will be created"
            : " ";

          return (
            <ClusterTagPicker
              {...field}
              label="Cluster"
              placeholder="Type name to create new Cluster or select an existing one"
              error={!!fieldState.error}
              helperText={helperText}
              customOptions={[clusterSpecialOptions.unassign, ...userOptions]}
              onInputSubmit={({ option, isExisting }) => {
                if (!isExisting) {
                  setUserOptions((prev) => [option, ...prev]);
                }

                field.onChange(option);
              }}
            />
          );
        }}
      />
    </Stack>
  );
}
