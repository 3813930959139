import { MutableRefObject } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CodeEditorSection } from "src/components/CodeEditorSection/CodeEditorSection";
import { TextInputBase } from "src/components/TextInputBase";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";

export const WatchListCreateCustomForm = ({
  loading,
  dropDownBoundsEl,
}: {
  loading?: boolean;
  dropDownBoundsEl: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));
  const { formState, control } = useFormContext<WatchQueryCustomFormValues>();
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;

  return (
    <Stack sx={{ backgroundColor: palette.background.default }}>
      <Stack
        component={Paper}
        mb={2}
        p={isSmallScreen ? 2 : 4}
        pb={2}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: isSmallScreen ? 0 : "8px",
          borderBottomRightRadius: isSmallScreen ? 0 : "8px",
        }}
      >
        <Grid container spacing={1.5}>
          {isSmallScreen && (
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                variant="body2"
                fontWeight={600}
                lineHeight="24px"
                mb={1.5}
              >
                Custom Query
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} mb={2.5}>
            <Controller
              name="customQuery"
              control={control}
              render={({ field }) => {
                return (
                  <CodeEditorSection
                    readOnly={isSmallScreen}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </Grid>

          {/* Display name*/}
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="displayName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="Display name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          {/* Display name*/}
        </Grid>
      </Stack>

      {/* Sources */}
      <Stack
        component={Paper}
        mb={2}
        p={isSmallScreen ? 2 : 4}
        pb={2}
        sx={{
          overflow: "hidden",
          borderRadius: isSmallScreen ? 0 : 2,
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={12}>
            {isSmallScreen ? (
              <Typography
                variant="body2"
                fontWeight={600}
                lineHeight="24px"
                mb={1.5}
              >
                Sources
              </Typography>
            ) : (
              <Typography variant="subtitle1">Sources</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="sourcesInclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-include-input"
                    label={"Include"}
                    dropDownBoundsEl={dropDownBoundsEl}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>
          {/* Sources */}

          {/* Exclude sources */}
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="sourcesExclude"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SourcesPicker
                    id="source-exclude-input"
                    label="Exclude"
                    dropDownBoundsEl={dropDownBoundsEl}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isBusy}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                  />
                );
              }}
            />
          </Grid>
          {/* Exclude sources */}
        </Grid>
      </Stack>
    </Stack>
  );
};
