import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseMutationOptions, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ContactFormValues } from "src/pages/Settings/components/SettingsContactForm/SettingsContactForm.model";
import { useSessionContext } from "./useSessionContext";

const apiKey: keyof paths = "/api/User/{id}/contact";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];

type RequestMutationFnParams = {
  formData: ContactFormValues;
};
type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useSettingsContact({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId, accountId } = useSessionContext();

  const { enqueueSnackbar } = useSnackbar();
  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ formData }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        path: {
          id: userId,
        },
      });
      const body: RequestBody = {
        ...formData,
        userId: userId,
        accountId: accountId,
      };
      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
