import { AppRouteBuilder } from "src/models/AppRoute";
import { SettingsPage, SettingsPageTab } from "./Settings.page";

type SettingsRoutePathParams = {
  // no path params for now
};

type SettingsRouteQueryParams = {
  pageTab?: SettingsPageTab;
};

export const settingsRoute = new AppRouteBuilder<
  SettingsRoutePathParams,
  SettingsRouteQueryParams
>({
  path: "/settings",
  element: <SettingsPage />,
});
