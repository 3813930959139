import { useMemo } from "react";
import getNestedValue from "lodash/get";
import { ObjectPaths } from "src/models/ObjectPaths";

/**
 * Filters object list with arbitrary text by checking its props
 */
export function useListFilterDeep<T extends { [key: string]: unknown }>(
  source: T[],
  filterText: string,
  propNames: ObjectPaths<T>[]
) {
  const empty: T[] = useMemo(() => [], []);

  const nextFilterData = useMemo(
    () =>
      source?.filter((item) => {
        const q = filterText.toLowerCase();

        const check = (propName: ObjectPaths<T>) => {
          const itemValue = propName.includes(".")
            ? getNestedValue(item, propName)
            : item[propName];

          const value = Array.isArray(itemValue)
            ? JSON.stringify(itemValue)
            : itemValue;

          if (value === null || value === undefined) {
            return false;
          }

          if (Array.isArray(value)) {
            return value.some((v) => `${v}`.toLowerCase().includes(q));
          }

          return `${value}`.toLowerCase().includes(q);
        };

        return propNames.find(check);
      }),
    [source, filterText, propNames]
  );

  return nextFilterData.length ? nextFilterData : empty;
}
