import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { WatchQueryKeywordFormValues } from "./WatchQueryKeywordForm.model";

export const emptyWatchQueryKeywordFormValues: WatchQueryKeywordFormValues = {
  displayName: "",
  termsInclude: {
    list: [],
    logic: "or",
  },
  termsExclude: {
    list: [],
    logic: "or",
  },
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type UseWatchQueryFormArgs = {
  initial?: WatchQueryKeywordFormValues;
  schema: Joi.Schema;
};

export function useWatchQueryKeywordForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<WatchQueryKeywordFormValues>({
    mode: "all",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: initial || emptyWatchQueryKeywordFormValues,
  });

  const { formState, watch, setValue } = formHook;
  const { dirtyFields } = formState;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "termsInclude" &&
        !dirtyFields.displayName
      ) {
        const updatedValue = value.termsInclude?.list?.join(", ") || "";
        setValue("displayName", updatedValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, dirtyFields]);

  return formHook;
}
