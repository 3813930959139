import { Dispatch, SetStateAction } from "react";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AlertConfig,
  AlertConfigMonthly,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  AlertScheduleDayKey,
  getDefaultAlertStateData,
  ActiveGroupKey,
  isAlertConfigType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import { AppTimePicker } from "src/components/AppTimePicker/AppTimePicker";

type AlertConfigFormMonthlyProps = {
  value: AlertConfigMonthly;
  onChange: Dispatch<SetStateAction<AlertConfig>>;
  updateSelectOpen?: (v: boolean) => void;
};

function selectedValue(val: { [key in AlertScheduleDayKey]?: boolean }) {
  const optionValues = makeTypedPropList(val);

  return optionValues.find((v) => val[v]);
}

export const AlertConfigFormMonthly = ({
  value,
  onChange,
  updateSelectOpen,
}: AlertConfigFormMonthlyProps) => {
  const {
    type,
    time,
    preset,
    first,
    second,
    third,
    fourth,
    fifth,
    last,
    sun,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    day,
    weekday,
    weekend,
    each,
    activeGroup,
  } = value;

  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const onTypeChange = (event: SelectChangeEvent) => {
    if (isAlertConfigType(event?.target.value)) {
      onChange(getDefaultAlertStateData(event.target.value));
      updateSelectOpen && updateSelectOpen(false);
    } else {
      throw new Error(
        `Invalid value: ${event.target.value}. Should be one of ${Object.values(
          AlertTypesKey
        )}`
      );
    }
  };

  const onTimeChange = (val: Date | null) => {
    if (!val) return;

    onChange((prev) => {
      if (prev.type !== AlertTypesKey.monthly) return prev;

      return {
        ...prev,
        time: val,
      };
    });
  };

  const onChangeActiveGroup = () => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.monthly) return prev;

      return {
        ...prev,
        activeGroup:
          prev.activeGroup === ActiveGroupKey.calendar
            ? ActiveGroupKey.selectors
            : ActiveGroupKey.calendar,
      };
    });
  };

  const onPositionChange = (v: AlertScheduleDayKey) => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.monthly) return prev;

      return {
        ...prev,
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        last: false,
        [v]: !prev[v],
      };
    });
  };

  const onMonthDayChange = (v: AlertScheduleDayKey) => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.monthly) return prev;

      return {
        ...prev,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        day: false,
        weekday: false,
        weekend: false,
        [v]: !prev[v],
      };
    });
  };

  const onCalendarChange = (v: number) => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.monthly) return prev;

      return {
        ...prev,
        each: prev.each.includes(v)
          ? prev.each.filter((val: number) => val !== v)
          : [...prev.each, v],
      };
    });
  };

  const firstSeletedValue = selectedValue({
    first,
    second,
    third,
    fourth,
    fifth,
    last,
  });

  const secondSeletedValue = selectedValue({
    sun,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    day,
    weekday,
    weekend,
  });

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Type of alert</InputLabel>
        <Select
          onOpen={() => {
            updateSelectOpen && updateSelectOpen(true);
          }}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                updateSelectOpen && updateSelectOpen(false);
              },
            },
          }}
          value={preset || type}
          label="Type of alert"
          onChange={onTypeChange}
        >
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.hourly}>
            Hourly
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekdayMorning}>
            Every Weekday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.threeTimes}>
            Three Times Daily
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.mondayMorning}>
            Monday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} divider value={AlertTypesKey.firstDay}>
            First Day of the Month
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.daily}>
            Custom Every Day
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekly}>
            Custom Day of Week
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.monthly}>
            Custom Monthly
          </MenuItem>
        </Select>
      </FormControl>

      <Stack gap={2}>
        <AppTimePicker
          disablePortal={true}
          label="Time"
          value={time}
          onChange={(val) => onTimeChange(val)}
        />

        <RadioGroup value={activeGroup} onChange={onChangeActiveGroup}>
          <FormControlLabel
            value={ActiveGroupKey.selectors}
            control={<Radio />}
            label="On the"
          />
          <Stack flexDirection="row" gap={2}>
            <Select
              onOpen={() => {
                updateSelectOpen && updateSelectOpen(true);
              }}
              MenuProps={{
                TransitionProps: {
                  onExited: () => {
                    updateSelectOpen && updateSelectOpen(false);
                  },
                },
              }}
              disabled={activeGroup === ActiveGroupKey.calendar}
              value={firstSeletedValue}
              onChange={(event: SelectChangeEvent) => {
                onPositionChange(event.target.value as AlertScheduleDayKey);
                updateSelectOpen && updateSelectOpen(false);
              }}
              fullWidth
            >
              <MenuItem value={AlertScheduleDayKey.first}>First</MenuItem>
              <MenuItem value={AlertScheduleDayKey.second}>Second</MenuItem>
              <MenuItem value={AlertScheduleDayKey.third}>Third</MenuItem>
              <MenuItem value={AlertScheduleDayKey.fourth}>Fourth</MenuItem>
              <MenuItem value={AlertScheduleDayKey.last}>Last</MenuItem>
            </Select>
            <Select
              onOpen={() => {
                updateSelectOpen && updateSelectOpen(true);
              }}
              MenuProps={{
                TransitionProps: {
                  onExited: () => {
                    updateSelectOpen && updateSelectOpen(false);
                  },
                },
              }}
              disabled={activeGroup === ActiveGroupKey.calendar}
              value={secondSeletedValue}
              onChange={(event: SelectChangeEvent) => {
                onMonthDayChange(event.target.value as AlertScheduleDayKey);
                updateSelectOpen && updateSelectOpen(false);
              }}
              fullWidth
            >
              <MenuItem value={AlertScheduleDayKey.sun}>Sunday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.mon}>Monday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.tue}>Tuesday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.wed}>Wednesday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.thu}>Thursday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.fri}>Friday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.sat}>Saturday</MenuItem>
              <MenuItem value={AlertScheduleDayKey.day}>Day</MenuItem>
            </Select>
          </Stack>
          <FormControlLabel
            value={ActiveGroupKey.calendar}
            control={<Radio />}
            label="Each"
          />
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            mx={isMobile ? 2.75 : 5.5}
            gap={0.25}
          >
            {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
              <Stack
                key={item}
                alignItems="center"
                justifyContent="center"
                width={36}
                height={36}
                sx={{
                  color:
                    activeGroup === ActiveGroupKey.calendar
                      ? each.includes(item)
                        ? palette.background.paper
                        : undefined
                      : palette.text.disabled,
                  backgroundColor:
                    activeGroup === ActiveGroupKey.calendar
                      ? each.includes(item)
                        ? palette.primary.main
                        : undefined
                      : undefined,
                  borderRadius: each.includes(item) ? "50%" : undefined,
                  cursor:
                    activeGroup === ActiveGroupKey.calendar
                      ? "pointer"
                      : "default",
                }}
                onClick={() => {
                  onCalendarChange(item);
                }}
              >
                <Typography variant="caption">{item}</Typography>
              </Stack>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>
    </>
  );
};
