import { useState } from "react";
import { Box, Fade, Tooltip } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { Spinner } from "../Spinner/Spinner";
import { ImagePlaceholder } from "../ImagePlaceholder/ImagePlaceholder";
import { ImageLoaderProps } from "./ImageLoader.model";

export const ImageLoader = ({
  src,
  alt,
  loading,
  aspectRatio = "16/9",
  objectFit = "cover",
  type,
  eventType,
  mediaType,
  innerRef,
  ...props
}: ImageLoaderProps) => {
  const [state, setState] = useState<"init" | "loading" | "success" | "error">(
    "init"
  );

  const placeholder = (
    <ImagePlaceholder
      width="100%"
      height="100%"
      borderRadius={type === "compact" ? 2 : undefined}
      iconScale={type === "compact" ? 3 : undefined}
      eventType={eventType}
      mediaType={mediaType}
    />
  );
  const spinner = <Spinner size={80} />;
  const error = <ErrorOutline fontSize="large" color="error" />;

  const renderContent = () => {
    if (loading) {
      // external loading
      return spinner;
    }

    switch (state) {
      case "init":
        // initial state when src is present should show loading
        return src ? spinner : placeholder;
      case "loading":
        return spinner;
      case "error":
        return error;
      case "success":
        return null;
    }
  };

  const content = renderContent();

  return (
    <Tooltip
      title={
        !loading && state === "error" ? "The image could not be loaded" : ""
      }
    >
      <Box
        position="relative"
        overflow="hidden"
        borderRadius={type === "compact" ? 2 : undefined}
        ref={innerRef}
        {...props}
        sx={{
          aspectRatio,
          // the image must take container dimensions
          img: {
            width: "100%",
            height: "100%",
            objectFit,
          },
        }}
      >
        <Fade in={state === "success"}>
          <img
            src={src}
            width="100%"
            height="100%"
            onLoadStart={() => setState("loading")}
            onLoad={() => setState("success")}
            onError={() => setState("error")}
            alt={alt}
          />
        </Fade>
        {content && (
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {content}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};
