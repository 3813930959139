import {
  useMemo,
  useState,
  useCallback,
  ChangeEvent,
  Fragment,
  useEffect,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ArrayParam,
  BooleanParam,
  NumericArrayParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { useSnackbar } from "notistack";
import { FormProvider } from "react-hook-form";
import { Edit } from "@mui/icons-material";
import {
  Alert,
  alertClasses,
  Box,
  FormControlLabel,
  LabelDisplayedRowsArgs,
  Paper,
  Snackbar,
  Stack,
  Switch,
  ToggleButton,
  Typography,
} from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import {
  WatchTermResultItem,
  useWatchTermResults,
} from "src/api/useWatchTermResults";
import { useWatchQuery } from "src/api/useWatchQuery";
import { useWatchTermResultsInfinite } from "src/api/useWatchTermResultsInfinite";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { useReportScratchAppend } from "src/api/useReportScratchAppend";
import {
  maxEventsToPreselect,
  useReportScratchAppendFromQuery,
} from "src/api/useReportScratchAppenFromQuery";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { useIsMobile } from "src/utils/useIsMobile";
import { useReportCreate } from "src/api/useReportCreate";
import { useReportCreateFromQuery } from "src/api/useReportCreateFromQuery";

// every commented line here is related to MMSR-2997
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { useOpenState } from "src/utils/useOpenState";
import { useQueryDateTimeRangeWatchlist } from "src/utils/useQueryDateTimeRangeWatchlist";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { useListFilter } from "src/utils/useListFilter";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { SearchInput } from "src/components/SearchInput";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { useWatchQueryForm } from "src/components/WatchQueryForm/WatchQueryForm.hook";
import { WatchQueryFilterButton } from "src/components/WatchQueryFilterButton/WatchQueryFilterButton";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { WatchQueryUpdateDialog } from "src/components/WatchQueryUpdateDialog/WatchQueryUpdateDialog";
import { WatchQueryUpdateDialogAdvanced } from "src/components/WatchQueryUpdateDialogAdvanced/WatchQueryUpdateDialogAdvanced";
import { WatchTermNavBar } from "src/components/WatchTermNavBar/WatchTermNavBar";
import { WatchTermNavBarMobile } from "src/components/WatchTermNavBarMobile/WatchTermNavBarMobile";
import { WatchQueryFormSchema } from "src/components/WatchQueryForm/WatchQueryForm.schema";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { ShareEventsDialog } from "src/components/ShareEventsDialog/ShareEventsDialog";
import { ReportCreateDialog } from "src/components/ReportCreateDialog/ReportCreateDialog";
import {
  SortBy,
  SortOption,
  SortByParameters,
  OrderByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { PreselectEventsButton } from "src/components/PreselectEventsButton/PreselectEventsButton";
import { ActionButtonsMobile } from "src/components/ActionButtonsMobile/ActionButtonsMobile";
import { WatchQueryFilterDialog } from "src/components/WatchQueryFilterDialog/WatchQueryFilterDialog";
import { EventsResultsExportButton } from "src/components/EventsResultsExportButton/EventsResultsExportButton";
import { ShareButtonDesktop } from "src/components/ShareButtonDesktop/ShareButtonDesktop";
import { SelectAllPages } from "src/components/SelectAllPages/SelectAllPages";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { DownloadFilesSnackbar } from "src/components/DownloadFilesSnackbar/DownloadFilesSnackbar";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DuplicatesDrawer } from "src/components/DuplicatesDrawer/DuplicatesDrawer";
import { DuplicatesDrawerMobile } from "src/components/DuplicatesDrawer/DuplicatesDrawerMobile";
import { DuplicatesMobileSettingsMenu } from "src/components/DuplicatesMobileSettingsMenu/DuplicatesMobileSettingsMenu";
import { DuplicatesPaginationLabel } from "src/components/DuplicatesPaginationLabel/DuplicatesPaginationLabel";
import { countQueryFilters } from "src/utils/countQueryFilters";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { useSessionContext } from "src/api/useSessionContext";
import { watchListTermResultsRoute } from "./WatchListTermResults.route";
import { WatchTermResultTiles } from "./components/WatchTermResultTiles/WatchTermResultTiles";
import { WatchTermResultsList } from "./components/WatchTermResultsList/WatchTermResultsList";
import { WatchTermResultsTable } from "./components/WatchTermResultsTable/WatchTermResultsTable";
import { useWatchQueryFilter } from "../WatchList/hooks/useWatchQueryFilter";
import { WatchTermResultTilesProps } from "./components/WatchTermResultTiles/WatchTermResultTiles.model";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

const viewModeQueryKey = "tab";
const emptyList: WatchTermResultItem[] = [];

export const WatchListTermResultsPage = () => {
  const isMobile = useIsMobile();
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");

  const { effectiveEntitlements } = useSessionContext();
  const limitItemsPerEmail =
    effectiveEntitlements?.limitItemsPerEmail?.value || 0;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [search] = useSearchParams();
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();
  const reportCreateDialog = useOpenState();
  const reportCreateSnackBar = useOpenState();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const watchQueryUpdateDialog = useOpenState();
  const formHook = useWatchQueryForm({ schema: WatchQueryFormSchema });
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const filterDialog = useOpenState();
  const [filterQuery, setFilterQuery] = useWatchQueryFilter();

  const [dateRange, setDateRange] = useQueryDateTimeRangeWatchlist();
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [isExpandedTextMode, setExpandedTextMode] = useQueryParam(
    "expanded",
    BooleanParam
  );
  const [groupDuplicates, setGroupDuplicates] = useQueryParam(
    "grouped",
    withDefault(BooleanParam, true)
  );
  const duplicatesDrawerState = useOpenState();
  const [drawerParentEventId, setDrawerParentEventId] = useState<string>();
  const [duplicatesIds, setDuplicatesIds] = useState<string[]>([]);
  const [duplicatesSortBy, duplicatesSetSortBy] =
    useState<SortByParameters>("default");
  const [duplicatesOrderBy, duplicatesSetOrderBy] =
    useState<OrderByParameters>("desc");

  const intercomButtonState = useIntercomButtonState();

  const [keepPreviousData, setKeepPreviousData] = useState(true);
  const { queryId = "" } = useParams();
  const [pagination, setPagination] = useQueryPagination();
  const [paginationDrawer, setPaginationDrawer] = useQueryPagination({
    pageKey: "pageDrawer",
    pageSizeKey: "pageSizeDrawer",
  });

  /** is used for switching between pages while groupDuplicates is set to true */
  const [pagesHistory, setPagesHistory] = useQueryParam(
    "groupedPages",
    withDefault(ArrayParam, [""])
  );
  /** "after" is overriding "from" on the BE side, is used with groupDuplicates set to true */
  const after = pagesHistory[pagesHistory.length - 1];

  /** is used for the displayed rows label in the pagination while groupDuplicates is set to true */
  const [duplicatesCounts, setDuplicatesCounts] = useQueryParam(
    "duplicatesCounts",
    withDefault(NumericArrayParam, [])
  );

  const [viewMode = initialViewMode, setViewMode] =
    useQueryParam<ViewMode>(viewModeQueryKey);

  const filterButton = useMemo(
    () => (
      <WatchQueryFilterButton
        value={filterQuery}
        onClick={filterDialog.show}
        isMobile={isMobile}
      />
    ),
    [filterQuery, isMobile, filterDialog.show]
  );

  const reportScratchAppend = useReportScratchAppend({});
  const reportScratchAppendFromQuery = useReportScratchAppendFromQuery({
    params: {
      query: {
        queryId,
        startTime: formatDateTimeWithoutTimeZone(dateRange[0]),
        endTime: formatDateTimeWithoutTimeZone(dateRange[1]),
        groupDuplicates,
      },
    },
  });

  const pageEventsState = useEventSelectionWatchTermResults();
  const duplicatesDrawerEventsState = useEventSelectionDuplicatesDrawer();

  const { setHighlightedEventsIds, showTooltip, setAllAddedEventsLenght } =
    useEventsPreselected();

  const watchTermResults = useWatchTermResults({
    options: { enabled: !!queryId && viewMode !== ViewMode.tile },
    params: {
      path: { queryId },
      body: makeQueryDefinition(filterQuery),
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : "",
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : "",
        sortby: sortBy,
        orderby: orderBy,
        groupDuplicates: !!groupDuplicates,
        after: groupDuplicates && after ? after : undefined,
      },
    },
    keepPreviousData,
  });

  const watchTermResultsTiles = useWatchTermResultsInfinite({
    options: { enabled: !!queryId && viewMode === ViewMode.tile },
    params: {
      path: { queryId },
      body: makeQueryDefinition(filterQuery),
      query: {
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : "",
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : "",
        sortby: sortBy,
        orderby: orderBy,
        groupDuplicates: !!groupDuplicates,
      },
    },
    keepPreviousData,
  });

  const watchTermResultsData = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      return combineInfiniteTilesData(watchTermResultsTiles.data?.pages);
    }
    return watchTermResults.data?.results;
  }, [
    viewMode,
    watchTermResults.data?.results,
    watchTermResultsTiles.data?.pages,
  ]);

  const totalRowCount = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      const tilesPagesData = watchTermResultsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    return watchTermResults.data?.total || 0;
  }, [
    viewMode,
    watchTermResultsTiles.data?.pages,
    watchTermResults.data?.total,
  ]);

  const duplicatesResults = useWatchTermResults({
    options: { enabled: !!duplicatesIds.length },
    params: {
      path: { queryId },
      body: {
        ...makeQueryDefinition(filterQuery),
        includeFilter: duplicatesIds,
      },
      query: {
        from: 0,
        size: duplicatesIds.length,
        sortby: duplicatesSortBy,
        orderby: duplicatesOrderBy,
      },
    },
    keepPreviousData: false,
  });

  const duplicatesCountCurrentPage = watchTermResults.data?.results
    ? watchTermResults.data?.results.reduce((sum, event) => {
        return sum + event.duplicates.length;
      }, 0)
    : 0;

  const setPaginationWrapper = useCallback(
    (nextPagination: GridPaginationModel) => {
      if (groupDuplicates) {
        if (nextPagination.pageSize !== pagination.pageSize) {
          // change page size:
          setPagesHistory([""], "replaceIn");
          setDuplicatesCounts([], "replaceIn");
        }
        const nextPage = watchTermResults.data?.configuration?.next;
        if (nextPagination.page > pagination.page && nextPage) {
          // go to the next page:
          setPagesHistory([...pagesHistory, nextPage], "replaceIn");
          setDuplicatesCounts(
            [...duplicatesCounts, duplicatesCountCurrentPage],
            "replaceIn"
          );
        }
        if (nextPagination.page < pagination.page) {
          // go to the previous page:
          setPagesHistory(pagesHistory.slice(0, -1), "replaceIn");
          setDuplicatesCounts(duplicatesCounts.slice(0, -1), "replaceIn");
        }
      }
      setPagination(nextPagination);
    },
    [
      groupDuplicates,
      pagination,
      setPagination,
      setPagesHistory,
      watchTermResults.data?.configuration?.next,
      pagesHistory,
      duplicatesCountCurrentPage,
      duplicatesCounts,
      setDuplicatesCounts,
    ]
  );

  const navToTheFirstPage = useCallback(() => {
    setPagesHistory([""], "replaceIn");
    setDuplicatesCounts([], "replaceIn");
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [setPagesHistory, setDuplicatesCounts, setPagination, pagination]);

  useEffect(() => {
    if (keepPreviousData === false) {
      if (
        (viewMode === ViewMode.tile && watchTermResultsTiles.isFetched) ||
        (viewMode !== ViewMode.tile && watchTermResults.isFetched)
      ) {
        setKeepPreviousData(true);
      }
    }
  }, [
    keepPreviousData,
    viewMode,
    watchTermResultsTiles.isFetched,
    watchTermResults.isFetched,
  ]);

  const selectedPageEventsLength = pageEventsState.areAllPagesSelected
    ? totalRowCount - pageEventsState.excludeList.length
    : pageEventsState.list.length ?? 0;

  const selectedEventsIncludingDrawer = useMemo(
    () => [...pageEventsState.list, ...duplicatesDrawerEventsState.list],
    [pageEventsState.list, duplicatesDrawerEventsState.list]
  );

  const selectedDrawerEventsLength = duplicatesDrawerEventsState.list.length;
  const selectedEventsLength =
    selectedPageEventsLength + selectedDrawerEventsLength;

  const disableShareButton = selectedEventsLength > limitItemsPerEmail;

  const [filterText, setFilterText] = useState("");
  const filterData = useListFilter(
    watchTermResultsData || emptyList,
    filterText,
    ["title", "highlights"]
  );

  const addItemToReport = useCallback(
    (item: WatchTermResultItem) => {
      reportScratchAppend.mutate(
        { events: [item] },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `${item.title} was successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from it`,
              {
                variant: "success",
              }
            );
            setHighlightedEventsIds([item.id]);
          },
        }
      );
    },
    [reportScratchAppend, setHighlightedEventsIds, enqueueSnackbar]
  );

  const excludeFilter = useMemo(() => {
    return pageEventsState.excludeList.reduce((acc: string[], item) => {
      return [...acc, item.id, ...item.duplicates];
    }, []);
  }, [pageEventsState.excludeList]);

  const includeFilter = useMemo(
    () => duplicatesDrawerEventsState.list.map((ev) => ev.id),
    [duplicatesDrawerEventsState.list]
  );

  const preselectEvents = useCallback(() => {
    const successMessage =
      'Events were successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from them';
    if (pageEventsState.areAllPagesSelected) {
      reportScratchAppendFromQuery.mutate(
        {
          query: {
            ...makeQueryDefinition(filterQuery),
            excludeFilter,
            includeFilter,
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar(successMessage, {
              variant: "success",
            });
            setAllAddedEventsLenght(selectedEventsLength);
            showTooltip();
          },
        }
      );
    } else {
      reportScratchAppend.mutate(
        { events: selectedEventsIncludingDrawer },
        {
          onSuccess: () => {
            enqueueSnackbar(successMessage, {
              variant: "success",
            });
            setHighlightedEventsIds(
              selectedEventsIncludingDrawer.map((event) => event.id)
            );
            showTooltip();
          },
        }
      );
    }
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
  }, [
    enqueueSnackbar,
    reportScratchAppend,
    reportScratchAppendFromQuery,
    setHighlightedEventsIds,
    showTooltip,
    selectedEventsIncludingDrawer,
    filterQuery,
    selectedEventsLength,
    setAllAddedEventsLenght,
    pageEventsState,
    duplicatesDrawerEventsState,
    excludeFilter,
    includeFilter,
  ]);

  const reportCreateOptions = {
    onSuccess: () => {
      reportCreateDialog.hide();
      pageEventsState.unselectAll();
      duplicatesDrawerEventsState.unselectAll();
      reportCreateSnackBar.show();
    },
  };

  const reportCreateMutation = useReportCreate({
    options: reportCreateOptions,
  });

  const reportCreateFromQueryMutation = useReportCreateFromQuery({
    options: reportCreateOptions,
  });

  const createReport = useCallback(
    (data: ReportCreateFormValues) => {
      if (pageEventsState.areAllPagesSelected) {
        reportCreateFromQueryMutation.mutate({
          queryDefinition: {
            ...makeQueryDefinition(filterQuery),
            excludeFilter,
            includeFilter,
          },
          params: {
            query: {
              queryId,
              startTime: formatDateTimeWithoutTimeZone(dateRange[0]),
              endTime: formatDateTimeWithoutTimeZone(dateRange[1]),
              title: data.title,
              description: data.description,
              archive: true,
              groupDuplicates,
            },
          },
        });
      } else {
        reportCreateMutation.mutate({
          title: data.title,
          description: data.description,
          selectedEvents: selectedEventsIncludingDrawer,
          archive: true,
        });
      }
    },
    [
      reportCreateMutation,
      pageEventsState,
      dateRange,
      filterQuery,
      queryId,
      groupDuplicates,
      selectedEventsIncludingDrawer,
      reportCreateFromQueryMutation,
      excludeFilter,
      includeFilter,
    ]
  );

  // const downloadPdfMutation = useExportPdf({
  //   options: {
  //     onSuccess: () => {
  //       pageEventsState.unselectAll();
  //       duplicatesDrawerEventsState.unselectAll();
  //     },
  //   },
  // });
  // const downloadPdf = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: selectedEventsIncludingDrawer,
  //   });
  // }, [
  //   downloadPdfMutation,
  //   downloadFilesSnackBar,
  //   selectedEventsIncludingDrawer,
  // ]);

  const downloadCsvMutation = useExportCsv({
    options: {
      onSuccess: () => {
        pageEventsState.unselectAll();
        duplicatesDrawerEventsState.unselectAll();
      },
    },
  });
  const downloadCsv = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: selectedEventsIncludingDrawer,
    });
  }, [
    downloadCsvMutation,
    downloadFilesSnackBar,
    selectedEventsIncludingDrawer,
  ]);

  const currentQueryData = useWatchQuery({
    path: {
      queryId,
    },
  });

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: pageEventsState.list,
    areAllPagesSelected: pageEventsState.areAllPagesSelected,
    excludeEventsLength: pageEventsState.excludeList?.length,
  });

  const hasMorePages = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      return totalRowCount > filterData.length;
    }
    return totalRowCount / pagination.pageSize > 1;
  }, [viewMode, totalRowCount, filterData.length, pagination.pageSize]);

  const selectAllPagesIsShown = isAllSelected && hasMorePages;

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        pageEventsState.selectAll(filterData);
      } else {
        pageEventsState.unselectAll();
      }
    },
    [filterData, pageEventsState]
  );

  const renderAllCheckboxSelector = useCallback(
    ({ hideLabel }: { hideLabel?: boolean }) => {
      if ([ViewMode.tile, ViewMode.list].includes(viewMode)) {
        return (
          <AllCheckboxSelector
            checked={isAllSelected || isPartiallySelected}
            isPartiallySelected={isPartiallySelected}
            onChange={handleChangeAllCheckbox}
            sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
            hideLabel={hideLabel}
          />
        );
      }
      return null;
    },
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const onSortChange = (sortBy: SortOption) => {
    setSortOption(sortBy.value);
    setSortBy(sortBy.field);
    setOrderBy(sortBy.order);
  };

  const showExportShareButtons = selectedEventsLength > 0;

  const copyShareLinkCallback = () => {
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
    shareLinkSnackBar.show();
  };

  const handleShareDialogSubmit = () => {
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
    shareFormSnackBar.show();
  };

  const filterInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
        sx={{ minWidth: 168 }}
        fullWidth
      />
    ),
    [isMobile]
  );

  const setDateRangeWrapper = useCallback(
    (dateRange: DateRange) => {
      setDateRange(dateRange);
      navToTheFirstPage();
    },
    [setDateRange, navToTheFirstPage]
  );

  const dateSelector = (
    <DateTimeSelector
      value={dateRange}
      onChange={setDateRangeWrapper}
      isMobile={isMobile}
      mode={DateTimeSelectorMode.variable}
      views={["year", "month", "day", "hours", "minutes"]}
      sx={{ flex: 1 }}
    />
  );

  const modifyWatchQueryButton = useMemo(
    () => (
      <ToggleButton
        value={true}
        size={isMobile ? "small" : "medium"}
        onClick={watchQueryUpdateDialog.show}
      >
        <Edit sx={{ color: "text.primary" }} />
      </ToggleButton>
    ),
    [watchQueryUpdateDialog.show, isMobile]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
          SortBy.viewershipDesc,
          SortBy.viewershipAsc,
          SortBy.disctanceAsc,
          SortBy.distanceDesc,
        ]}
      />
    ),
    [sortOption]
  );

  const onChangeViewMode = useCallback(
    (nextMode: ViewMode) => {
      if (viewMode !== nextMode) {
        pageEventsState.unselectAll();
        duplicatesDrawerEventsState.unselectAll();
      }
      setViewMode(nextMode);
    },
    [viewMode, setViewMode, pageEventsState, duplicatesDrawerEventsState]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={onChangeViewMode}
        size={isMobile ? "small" : "medium"}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
      />
    ),
    [isMobile, onChangeViewMode, viewMode]
  );

  const handleNavigateToQuery = useCallback(
    (query: { id: string }) =>
      navigate({
        pathname: watchListTermResultsRoute.makeUrl({
          queryId: query.id,
        }),
        // this is to preserve expanded state across history
        search: search.toString(),
      }),
    [navigate, search]
  );

  const watchQueryNavBarDesktop = (
    <WatchTermNavBar
      value={currentQueryData.data}
      onChange={handleNavigateToQuery}
      onOpenUpdateDialog={watchQueryUpdateDialog.show}
      loading={currentQueryData.isFetching}
    />
  );

  const showDuplicatesDrawer = useCallback(
    (eventId: string, duplicatesIds: string[]) => {
      setDrawerParentEventId(eventId);
      setDuplicatesIds(duplicatesIds);
      duplicatesDrawerState.show();
      intercomButtonState.hide();
    },
    [duplicatesDrawerState, intercomButtonState]
  );

  const hideDuplicatesDrawer = useCallback(() => {
    setDrawerParentEventId("");
    duplicatesDrawerState.hide();
    intercomButtonState.show();
    setPaginationDrawer({
      ...paginationDrawer,
      page: 0,
    });
  }, [
    duplicatesDrawerState,
    intercomButtonState,
    paginationDrawer,
    setPaginationDrawer,
  ]);

  const changeGroupDuplicates = useCallback(() => {
    setKeepPreviousData(false);
    navToTheFirstPage();
    if (groupDuplicates) {
      setGroupDuplicates(false, "replaceIn");
    } else {
      setGroupDuplicates(true, "replaceIn");
    }
    pageEventsState.unselectAll();
    duplicatesDrawerEventsState.unselectAll();
  }, [
    navToTheFirstPage,
    groupDuplicates,
    setGroupDuplicates,
    pageEventsState,
    duplicatesDrawerEventsState,
  ]);

  const onGroupDuplicatesSwitchChange = useCallback(
    (_e: ChangeEvent, checked: boolean) => {
      setKeepPreviousData(false);
      navToTheFirstPage();
      setGroupDuplicates(checked, "replaceIn");
      pageEventsState.unselectAll();
      duplicatesDrawerEventsState.unselectAll();
    },
    [
      navToTheFirstPage,
      setGroupDuplicates,
      pageEventsState,
      duplicatesDrawerEventsState,
    ]
  );

  const groupDuplicatesSwitch = useMemo(
    () => (
      <FormControlLabel
        label={<Typography variant="caption">Group duplicates</Typography>}
        sx={{ ml: 2 }}
        control={
          <Switch
            size="small"
            checked={!!groupDuplicates}
            onChange={onGroupDuplicatesSwitchChange}
          />
        }
      />
    ),
    [groupDuplicates, onGroupDuplicatesSwitchChange]
  );

  const changeExpandedTextMode = useCallback(() => {
    if (isExpandedTextMode) {
      setExpandedTextMode(false);
    } else {
      setExpandedTextMode(true);
    }
  }, [isExpandedTextMode, setExpandedTextMode]);

  const expandedTextSwitch = useMemo(
    () => (
      <FormControlLabel
        checked={!!isExpandedTextMode}
        control={
          <Switch
            size={isMobile ? "medium" : "small"}
            onChange={(_e, checked) =>
              setExpandedTextMode(checked, "replaceIn")
            }
          />
        }
        label={<Typography variant="caption">Expanded Text</Typography>}
        sx={{ mx: 0, whiteSpace: "nowrap" }}
      />
    ),
    [isExpandedTextMode, setExpandedTextMode, isMobile]
  );

  const renderCustomFooterEl = useCallback(() => {
    if (isMobile) {
      return null;
    }
    return (
      <Stack direction="row">
        {groupDuplicatesSwitch}
        {viewMode !== ViewMode.table && expandedTextSwitch}
      </Stack>
    );
  }, [isMobile, groupDuplicatesSwitch, expandedTextSwitch, viewMode]);

  const renderLabelDisplayedRows = useCallback(
    (paginationInfo: LabelDisplayedRowsArgs) => {
      const { count, page } = paginationInfo;
      // duplicatesCountsCopy - is a fix for the typescript error
      const duplicatesCountsCopy: Array<(typeof duplicatesCounts)[number]> =
        duplicatesCounts;
      const duplicatesSumPrevious = duplicatesCountsCopy.reduce(
        (acc, count) => {
          return (acc || 0) + (count || 0);
        },
        0
      );
      const fromWithDuplicates =
        page * pagination.pageSize + (duplicatesSumPrevious || 0) + 1;
      const to = fromWithDuplicates + pagination.pageSize - 1;
      return (
        <DuplicatesPaginationLabel
          from={fromWithDuplicates}
          to={to}
          duplicatesCount={duplicatesCountCurrentPage}
          totalCount={count}
        />
      );
    },
    [duplicatesCountCurrentPage, duplicatesCounts, pagination.pageSize]
  );

  const currentQueryTitle = currentQueryData.data?.title || "Loading...";

  const headerDesktop = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      flexWrap="wrap"
      columnGap={1}
    >
      <Stack alignItems="flex-start">
        {watchQueryNavBarDesktop}
        <AppTopNavBar
          sx={{ mb: 2 }}
          lastBreadcrumbLabel={`Watch Term: ${currentQueryTitle}`}
        />
      </Stack>

      <Stack
        direction="row"
        columnGap={2}
        mb={2}
        flex={1}
        justifyContent="flex-end"
      >
        <GlobalSearchDesktopButton />
        <PreselectEventsButton
          badgeCounter={selectedEventsLength}
          disabled={
            !selectedEventsLength || selectedEventsLength > maxEventsToPreselect
          }
          onClick={preselectEvents}
        />
        <EventsResultsExportButton
          disabled={!showExportShareButtons}
          value={selectedEventsIncludingDrawer}
          selectedEventsLength={selectedEventsLength}
          showCreateReport={reportCreateDialog.show}
          // downloadPdf={downloadPdf}
          downloadCsv={downloadCsv}
        />
        <TooltipBase
          title={t("limitItemsPerEmail", { limit: `"${limitItemsPerEmail}"` })}
          disableHoverListener={!disableShareButton}
        >
          <Box>
            <ShareButtonDesktop
              disabled={!showExportShareButtons || disableShareButton}
              showShareDialog={shareDialog.show}
              badgeContent={selectedEventsLength}
            />
          </Box>
        </TooltipBase>
      </Stack>
    </Stack>
  );

  const mobileNavBar = (
    <Stack
      minHeight={56}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <AppTopNavBarMobile title="Watch List Results" />
    </Stack>
  );

  const mobileHeader = useMemo(() => {
    return (
      <Stack rowGap={2} pt={1} pb={2}>
        <WatchTermNavBarMobile
          onChange={handleNavigateToQuery}
          value={currentQueryData.data}
          loading={currentQueryData.isFetching}
          px={2}
        />
        <Stack py={1} px={2}>
          {filterInput}
        </Stack>
        {!selectAllPagesIsShown && (
          <Stack direction="row" justifyContent="space-between" px={2}>
            <Stack direction="row" spacing={1}>
              {renderAllCheckboxSelector({})}
              {modifyWatchQueryButton}
            </Stack>
            <Stack flexDirection="row" gap={1.5}>
              <DateTimeRangePickerMobile
                value={dateRange}
                onChange={setDateRangeWrapper}
                iconButtonSize="small"
              />
              {filterButton}
              {sortingMenu}
              {viewModeSwitch}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }, [
    renderAllCheckboxSelector,
    dateRange,
    modifyWatchQueryButton,
    setDateRangeWrapper,
    sortingMenu,
    filterButton,
    filterInput,
    viewModeSwitch,
    handleNavigateToQuery,
    currentQueryData,
    selectAllPagesIsShown,
  ]);

  const renderMobileHeader = useCallback<
    NonNullable<WatchTermResultTilesProps["renderHeader"]>
  >(() => mobileHeader, [mobileHeader]);

  const renderSelectAll = useCallback(() => {
    if (!selectAllPagesIsShown) return null;
    return (
      <SelectAllPages
        rowCount={totalRowCount}
        selectedCount={
          pageEventsState.areAllPagesSelected
            ? totalRowCount
            : pageEventsState.list.length
        }
        areAllPagesSelected={pageEventsState.areAllPagesSelected}
        setAllPagesSelected={pageEventsState.setAllPagesSelected}
        unselectAll={pageEventsState.unselectAll}
        allCheckboxSelector={renderAllCheckboxSelector({ hideLabel: true })}
        groupDuplicates={groupDuplicates}
      />
    );
  }, [
    selectAllPagesIsShown,
    pageEventsState,
    totalRowCount,
    renderAllCheckboxSelector,
    groupDuplicates,
  ]);

  const nextPageButtonIsDisabled = useMemo(() => {
    if (groupDuplicates) {
      const loading = watchTermResults.isLoading || watchTermResults.isFetching;
      const nextPage = watchTermResults.data?.configuration?.next;
      if (loading || !nextPage) return true;
    }
    return false;
  }, [
    groupDuplicates,
    watchTermResults.isLoading,
    watchTermResults.isFetching,
    watchTermResults.data?.configuration?.next,
  ]);

  const isLoading = watchTermResults.isLoading || watchTermResults.isFetching;
  const dataViewElement = useMemo(() => {
    //For podcast or youtube events
    const hideMarket = filterData?.every((item) => !item.market);

    const hasFilters = Number(countQueryFilters(filterQuery)) > 0;

    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <WatchTermResultsTable
              id="watch-term-results-table"
              queryId={queryId}
              rows={filterData}
              rowCount={totalRowCount}
              loading={isLoading}
              paginationModel={pagination}
              onPaginationModelChange={setPaginationWrapper}
              isMobile={isMobile}
              addItemToReport={addItemToReport}
              renderCustomFooterEl={renderCustomFooterEl}
              columnVisibilityModel={{
                duplicates: !!groupDuplicates,
                market: !hideMarket,
              }}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              renderLabelDisplayedRows={
                groupDuplicates ? renderLabelDisplayedRows : undefined
              }
              hasFilters={hasFilters}
            />
          ),
          list: (
            <WatchTermResultsList
              id="watch-term-results-list"
              queryId={queryId}
              isExpandedTextMode={!!isExpandedTextMode}
              rows={filterData}
              rowCount={totalRowCount}
              loading={isLoading}
              paginationModel={pagination}
              onPaginationModelChange={setPaginationWrapper}
              addItemToReport={addItemToReport}
              renderCustomFooterEl={renderCustomFooterEl}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
              hideMarket={hideMarket}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              renderLabelDisplayedRows={
                groupDuplicates ? renderLabelDisplayedRows : undefined
              }
              hasFilters={hasFilters}
            />
          ),
          tile: (
            <WatchTermResultTiles
              id="watch-term-results-tiles"
              queryId={queryId}
              isExpandedTextMode={!!isExpandedTextMode}
              addItemToReport={addItemToReport}
              renderHeader={
                isMobile
                  ? (params) => (
                      <Fragment>
                        {renderMobileHeader(params)}
                        {renderSelectAll()}
                      </Fragment>
                    )
                  : undefined
              }
              watchTermResults={watchTermResultsTiles}
              rows={filterData}
              renderCustomFooterEl={renderCustomFooterEl}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              hasFilters={hasFilters}
            />
          ),
        }}
      />
    );
  }, [
    viewMode,
    queryId,
    filterData,
    totalRowCount,
    isLoading,
    pagination,
    setPaginationWrapper,
    isMobile,
    addItemToReport,
    renderCustomFooterEl,
    groupDuplicates,
    nextPageButtonIsDisabled,
    isExpandedTextMode,
    watchTermResultsTiles,
    renderMobileHeader,
    renderSelectAll,
    showDuplicatesDrawer,
    drawerParentEventId,
    renderLabelDisplayedRows,
    filterQuery,
  ]);

  const toolbarDesktop = (
    <Stack
      p={2}
      gap={2}
      direction={{ sm: "column", md: "row" }}
      justifyContent="space-between"
    >
      <Stack direction="row" flex={1}>
        {renderAllCheckboxSelector({})}
        <Stack
          sx={{
            justifyContent: "flex-end",
            mr: 2,
          }}
        >
          {filterInput}
        </Stack>
        {dateSelector}
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {filterButton}
        {sortingMenu}
        {viewModeSwitch}
      </Stack>
    </Stack>
  );

  const isCustomQuery =
    !!currentQueryData.data?.queryBuilderDefinition.customQuery;

  const commonContent = (
    <Fragment>
      {currentQueryData.data && !isCustomQuery && (
        <WatchQueryUpdateDialog
          title="Modify term"
          initial={currentQueryData.data}
          open={watchQueryUpdateDialog.isOpen}
          onClose={watchQueryUpdateDialog.hide}
        />
      )}

      {currentQueryData.data && isCustomQuery && (
        <WatchQueryUpdateDialogAdvanced
          title="Modify term"
          initial={currentQueryData.data}
          open={watchQueryUpdateDialog.isOpen}
          onClose={watchQueryUpdateDialog.hide}
        />
      )}

      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
      {selectedEventsLength > 0 && (
        <ShareEventsDialog
          title={selectedEventsLength > 1 ? "Share Events" : "Share Event"}
          open={shareDialog.isOpen}
          events={selectedEventsIncludingDrawer}
          onClose={shareDialog.hide}
          onSubmit={handleShareDialogSubmit}
          copyShareLinkCallback={copyShareLinkCallback}
          areAllPagesSelected={pageEventsState.areAllPagesSelected}
          queryParams={{
            queryDefinition: {
              ...makeQueryDefinition(filterQuery),
              excludeFilter,
              includeFilter,
            },
            queryId,
            startTime: formatDateTimeWithoutTimeZone(dateRange[0]),
            endTime: formatDateTimeWithoutTimeZone(dateRange[1]),
            groupDuplicates,
          }}
          selectedEventsLength={selectedEventsLength}
        />
      )}
      {selectedEventsLength > 0 && (
        <ReportCreateDialog
          dateRange={dateRange}
          open={reportCreateDialog.isOpen}
          onClose={reportCreateDialog.hide}
          createReport={createReport}
          isLoading={
            reportCreateMutation.isLoading ||
            reportCreateFromQueryMutation.isLoading
          }
        />
      )}
      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Events was successfully copied to the clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your share is being processed."
        subtitle="You and your recipients will get an email."
      />
      <SnackbarBase
        open={reportCreateSnackBar.isOpen}
        onClose={reportCreateSnackBar.hide}
        title="Your Report is being processed."
        subtitle={
          <>
            It may take some time depending on the amount of items in the
            report.
            <br />
            Finished report appears in your Reports page and your Email
          </>
        }
      />

      <DownloadFilesSnackbar
        open={downloadFilesSnackBar.isOpen}
        onClose={downloadFilesSnackBar.hide}
      />

      <WatchQueryFilterDialog
        open={filterDialog.isOpen}
        initial={filterQuery}
        onClose={filterDialog.hide}
        onSubmit={setFilterQuery}
      />
    </Fragment>
  );

  const mobileContent = (
    <PageLayoutMobile
      content={
        <Paper
          sx={{
            mt: { xs: 0, sm: 1 },
            mx: { xs: 0, sm: 1 },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {mobileNavBar}
          {viewMode === ViewMode.tile ? null : mobileHeader}
          {viewMode === ViewMode.tile ? null : renderSelectAll()}
          {dataViewElement}
          {showExportShareButtons ? (
            <ActionButtonsMobile
              showShareDialog={shareDialog.show}
              showReportDialog={reportCreateDialog.show}
              preselectEvents={preselectEvents}
              selectedEvents={selectedEventsIncludingDrawer}
              selectedEventsLength={selectedEventsLength}
              disableShare={disableShareButton}
              limitItemsPerEmail={limitItemsPerEmail}
              // downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
            />
          ) : null}
          <DuplicatesMobileSettingsMenu
            isExpandTextShown={viewMode !== ViewMode.table}
            isExpandedTextMode={!!isExpandedTextMode}
            changeExpandedTextMode={changeExpandedTextMode}
            isGrouped={groupDuplicates}
            changeGroupDuplicates={changeGroupDuplicates}
          />
          <DuplicatesDrawerMobile
            duplicatesData={duplicatesResults.data?.results || []}
            loading={
              duplicatesResults.isLoading || duplicatesResults.isFetching
            }
            open={duplicatesDrawerState.isOpen}
            onOpen={duplicatesDrawerState.show}
            onClose={hideDuplicatesDrawer}
            addItemToReport={addItemToReport}
          />
        </Paper>
      }
    />
  );

  const desktopContent = (
    <PageLayoutDesktop
      header={headerDesktop}
      overflow="hidden"
      content={
        <Paper
          sx={{
            mt: { sm: 1 },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {!selectAllPagesIsShown && toolbarDesktop}
          {renderSelectAll()}
          <Stack flex={1} overflow="hidden">
            {dataViewElement}
          </Stack>
          <DuplicatesDrawer
            duplicatesData={duplicatesResults.data?.results || []}
            loading={
              duplicatesResults.isLoading || duplicatesResults.isFetching
            }
            open={duplicatesDrawerState.isOpen}
            onClose={hideDuplicatesDrawer}
            addItemToReport={addItemToReport}
            pagination={paginationDrawer}
            setPagination={setPaginationDrawer}
            setSortBy={duplicatesSetSortBy}
            setOrderBy={duplicatesSetOrderBy}
          />
        </Paper>
      }
    />
  );

  return (
    <FormProvider {...formHook}>
      {isMobile ? mobileContent : desktopContent}
      {commonContent}
    </FormProvider>
  );
};
