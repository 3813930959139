import { useState } from "react";
import { TooltipBaseProps } from "../TooltipBase/TooltipBase.model";
import { Box, ClickAwayListener } from "@mui/material";
import { TooltipBase } from "../TooltipBase/TooltipBase";

export const TooltipBaseMobile = ({
  children,
  disableHoverListener,
  ...props
}: TooltipBaseProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
      <Box width="100%">
        <TooltipBase
          open={showTooltip}
          onOpen={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
          disableHoverListener={disableHoverListener}
          {...props}
        >
          <Box
            width="100%"
            onClick={() =>
              !disableHoverListener && setShowTooltip(!showTooltip)
            }
          >
            {children}
          </Box>
        </TooltipBase>
      </Box>
    </ClickAwayListener>
  );
};
