import { create } from "zustand";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { PowerSearchResultItem } from "./usePowerSearch";

type SelectedReportEventsState = {
  events: PowerSearchResultItem[];
  excludeEvents: PowerSearchResultItem[];
  areAllPagesSelected: boolean;
  setAllPagesSelected: (value: boolean) => void;
  setExcludeEvents: (excludeEvents: PowerSearchResultItem[]) => void;
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: (value: GridRowSelectionModel) => void;
  select: (event: PowerSearchResultItem) => void;
  unselect: (event: PowerSearchResultItem) => void;
  unselectSome: (events: PowerSearchResultItem[]) => void;
  selectEvents: (events: PowerSearchResultItem[]) => void;
  unselectAll: () => void;
};

export const useEventsSelectedForReports = create<SelectedReportEventsState>(
  (setState) => ({
    events: [],
    excludeEvents: [],
    areAllPagesSelected: false,
    setAllPagesSelected: (value: boolean) => {
      setState({
        areAllPagesSelected: value,
        excludeEvents: [],
        events: [],
      });
    },
    setExcludeEvents: (excludeEvents: PowerSearchResultItem[]) => {
      setState({
        excludeEvents,
      });
    },
    rowSelectionModel: [],
    setRowSelectionModel: (value: GridRowSelectionModel) => {
      setState({
        rowSelectionModel: value,
      });
    },
    select: (event: PowerSearchResultItem) => {
      setState((state) => ({
        events: [...state.events, event],
        excludeEvents: state.areAllPagesSelected
          ? state.excludeEvents.filter((ev) => event.id !== ev.id)
          : state.excludeEvents,
      }));
    },
    unselect: (event: PowerSearchResultItem) => {
      setState((state) => ({
        events: state.events.filter((stateEvent) => stateEvent.id !== event.id),
        excludeEvents: state.areAllPagesSelected
          ? [...state.excludeEvents, event]
          : state.excludeEvents,
      }));
    },
    unselectSome: (events: PowerSearchResultItem[]) => {
      setState((state) => ({
        events: state.events.filter(
          (stateEvent) => !events.find((ev) => ev.id === stateEvent.id)
        ),
        excludeEvents: state.areAllPagesSelected
          ? [...state.excludeEvents, ...events]
          : state.excludeEvents,
      }));
    },
    selectEvents: (events: PowerSearchResultItem[]) => {
      const selectedEvents = events.filter((event) => event?.id);
      const selectedEventsIds = selectedEvents.map((event) => event.id);
      setState((state) => ({
        events: selectedEvents,
        excludeEvents: state.areAllPagesSelected
          ? state.excludeEvents.filter(
              (ev) => !selectedEventsIds.includes(ev.id)
            )
          : state.excludeEvents,
      }));
    },
    unselectAll: () => {
      setState({
        events: [],
        areAllPagesSelected: false,
        excludeEvents: [],
        rowSelectionModel: [],
      });
    },
  })
);
