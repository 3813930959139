import { IconButton, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useOpenState } from "src/utils/useOpenState";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ReportEditEventsDeleteDialog } from "./ReportEditEventsDeleteDialog";

export const ReportEditEventsItemActions = ({
  value,
  removeEvents,
}: {
  value: PowerSearchResultItem;
  removeEvents: (events: PowerSearchResultItem[]) => void;
}) => {
  const { palette } = useTheme();
  const deleteEventDialog = useOpenState();

  return (
    <>
      <IconButton onClick={deleteEventDialog.show}>
        <DeleteOutlineIcon sx={{ color: palette.action.active }} />
      </IconButton>
      <ReportEditEventsDeleteDialog
        open={deleteEventDialog.isOpen}
        onClose={deleteEventDialog.hide}
        events={[value]}
        handleDelete={removeEvents}
      />
    </>
  );
};
