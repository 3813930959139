import { User } from "@auth0/auth0-react";

export const ROLES = ["Admin", "Account Manager", "User Manager", "User"];

export const STATUSLIST = ["trial", "paying", "expired", "disabled", "active"];

export const getRoleFromUser = (user?: User) => {
  const noRole = "n/a";
  const roleKey = user && Object.keys(user).find((key) => key.includes("role"));

  if (!user || !roleKey) {
    return noRole;
  }

  const roleObj = user[roleKey];

  return Array.isArray(roleObj) ? roleObj.join(", ") : `${roleObj}`;
};

export const getSelectedRoles = (role: (typeof ROLES)[number]) => {
  switch (role) {
    case "Admin":
      return ROLES;
    case "Account Manager":
      return ROLES.filter((r) => r !== "Admin");
    case "User Manager":
      return [role, "User"];
    default:
      return [role];
  }
};
