import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicReportEventPage } from "./PublicReportEvent.page";

export type PublicReportEventPathParams = {
  reportId: string;
  eventId: string;
};

export const publicReportEventRoute =
  new AppRouteBuilder<PublicReportEventPathParams>({
    path: "/public/report/:reportId/:eventId",
    element: <PublicReportEventPage />,
  });
