import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Slider,
  Grid,
  Button,
  IconButton,
  ButtonProps,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export function UploadImageControls({
  scale,
  handleZoomChange,
  formatLabelZoom,
  rotation,
  handleRotation,
  formatLabelRotate,
  resetChanges,
}: {
  scale: number;
  resetChanges?: () => void;
  handleZoomChange: (
    _event: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newValue: number | number[]
  ) => void;
  formatLabelZoom: (value: number) => string;
  rotation: number;
  handleRotation: (
    _event: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newValue: number | number[]
  ) => void;
  formatLabelRotate: (value: number) => string;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const handleZoomDecrease: ButtonProps["onClick"] = (event) => {
    if (scale > 0.1) {
      handleZoomChange(event, Number((scale - 0.1).toFixed(2)));
    }
  };

  const handleZoomIncrease: ButtonProps["onClick"] = (event) => {
    if (scale < 2) {
      handleZoomChange(event, Number((scale + 0.1).toFixed(2)));
    }
  };

  const handleRotationDecrease: ButtonProps["onClick"] = (event) => {
    if (rotation > 0) {
      handleRotation(event, rotation - 1);
    }
  };

  const handleRotationIncrease: ButtonProps["onClick"] = (event) => {
    if (rotation < 360) {
      handleRotation(event, rotation + 1);
    }
  };

  return (
    <>
      <Grid item xs={isMobile ? 12 : 6} px={0}>
        <Typography variant="subtitle1">Zoom</Typography>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <IconButton
            sx={{ padding: 0, color: "#000" }}
            onClick={handleZoomDecrease}
            disabled={scale === 0.1}
          >
            <RemoveIcon
              width={"14px"}
              height={"14px"}
              fill="black"
              fillOpacity="0.54"
            />
          </IconButton>
          <Slider
            aria-label="Zoom"
            value={scale}
            onChange={handleZoomChange}
            defaultValue={1}
            step={0.01}
            min={0.1}
            max={2}
            valueLabelFormat={formatLabelZoom}
            valueLabelDisplay="auto"
          />
          <IconButton
            sx={{ padding: 0, color: "#000" }}
            onClick={handleZoomIncrease}
            disabled={scale > 1.99}
          >
            <AddIcon
              width={"14px"}
              height={"14px"}
              fill="black"
              fillOpacity="0.54"
            />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} px={0}>
        <Typography variant="subtitle1">Rotate</Typography>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <IconButton
            sx={{ padding: 0, color: "#000" }}
            onClick={handleRotationDecrease}
            disabled={rotation === 0}
          >
            <RemoveIcon
              width={"14px"}
              height={"14px"}
              fill="black"
              fillOpacity="0.54"
            />
          </IconButton>

          <Slider
            aria-label="Rotation"
            value={rotation}
            onChange={handleRotation}
            defaultValue={0}
            step={1}
            min={0}
            valueLabelDisplay="auto"
            max={360}
            valueLabelFormat={formatLabelRotate}
          />
          <IconButton
            sx={{ padding: 0, color: "#000" }}
            onClick={handleRotationIncrease}
            disabled={rotation === 360}
          >
            <AddIcon
              width={"14px"}
              height={"14px"}
              fill="black"
              fillOpacity="0.54"
            />
          </IconButton>
        </Stack>
      </Grid>
      {isMobile && (
        <Grid item xs={12} px={0} mb={3}>
          <Button
            sx={{
              display: { md: "flex" },
              width: "100%",
              marginTop: "24px",
            }}
            onClick={resetChanges}
            variant="outlined"
          >
            Reset changes
          </Button>
        </Grid>
      )}
    </>
  );
}
