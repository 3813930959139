import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { UserManagementUpdatePage } from "./UserManagementUpdate.page";

export type UserManagementPathParam = {
  userId: string;
};

export const userManagementUpdateRoute =
  new AppRouteBuilder<UserManagementPathParam>({
    path: "/user-management/update/:userId",
    element: (
      <PermissionGuard
        component={UserManagementUpdatePage}
        allowedRoles={["Admin", "Account Manager", "User Manager"]}
        disableMobile
        redirectTo="/"
      />
    ),
  });
