import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useCallback, useMemo } from "react";

export function usePushNotificationMethods({
  connection,
  setState,
}: {
  setState: (state: HubConnectionState) => void;
  connection: HubConnection;
}) {
  const connect = useCallback(async () => {
    console.log(`Push Notification: starting connection...`);
    setState(HubConnectionState.Connecting);

    try {
      await connection.start();
    } catch (e) {
      console.error(`Push Notification: ${e}`);
    } finally {
      setState(connection.state);
      console.log(`Push Notification: ${connection.state}`);
    }
  }, [connection, setState]);

  const disconnect = useCallback(async () => {
    console.log(`Push Notification: disconnecting..`);
    setState(HubConnectionState.Disconnecting);

    try {
      await connection.stop();
    } catch (e) {
      console.error(`Push Notification: ${e}`);
    } finally {
      setState(connection.state);
      console.log(`Push Notification: ${connection.state}`);
    }
  }, [connection, setState]);

  // return memoized control methods
  return useMemo(
    () => ({
      connect,
      disconnect,
    }),
    [connect, disconnect]
  );
}
