import { format } from "date-fns";
import { StationSnapshot } from "./useSnapshot";

export function getSnapshotDateTime(value: StationSnapshot) {
  const startDateTime = value.thumbnailDateTime
    ? format(new Date(value.thumbnailDateTime), "PP pp")
    : " - ";

  return startDateTime;
}

export function getSnapshotEndDate(startDate?: string) {
  if (startDate) {
    const initialDate = new Date(startDate);
    initialDate.setMinutes(initialDate.getMinutes() + 1);
    const year = initialDate.getFullYear();
    const month = (initialDate.getMonth() + 1).toString().padStart(2, "0");
    const day = initialDate.getDate().toString().padStart(2, "0");
    const hours = initialDate.getHours().toString().padStart(2, "0");
    const minutes = initialDate.getMinutes().toString().padStart(2, "0");
    const seconds = initialDate.getSeconds().toString().padStart(2, "0");
    const milliseconds = initialDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const endDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return endDate;
  }
  return "";
}
