import { sumBy } from "lodash";
import { Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { QueryGeoAnalytics } from "src/api/useQueryGeoAnalytics";
import {
  MapBoxWrapper,
  MapBoxWrapperProps,
} from "../MapBoxWrapper/MapBoxWrapper";
import { AppChartContainer } from "./AppChartContainer";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";
import { transformSourceFeatures } from "./utils/transformSourceFeatures";
import {
  DataLevelSwitch,
  useDataLevelQueryParam,
} from "./components/DataLevelSwitch";
import { calcValueByFeatureId } from "../charts/utils/calcValueByFeatureId";

type AppMapChartProps = {
  title: string;
  data?: QueryGeoAnalytics[];
  isLoading: boolean;
  queryIds: string[];
  dateRange: [Date, Date];
};

export function AppMapChart({ title, data, isLoading }: AppMapChartProps) {
  const [dataLevel, setDataLevel] = useDataLevelQueryParam();

  const dataLevelSwitch = (
    <DataLevelSwitch value={dataLevel} onChange={setDataLevel} size="small" />
  );

  const valueByFeatureId = calcValueByFeatureId(data, dataLevel);

  const renderFeatureDetails: MapBoxWrapperProps<number>["renderFeatureDetails"] =
    ({ features }) => {
      if (!data) return null;

      const queryHitDataList = data.map((queryData) => {
        if (!queryData) return null;

        const regionQueryData = queryData.geoAggregations?.filter(
          ({ shapeId }) => {
            // pick only results related to the query
            return features?.find((feature) => {
              return `${feature.id}` === `${shapeId}`;
            });
          }
        );

        const queryTotalHits = sumBy(regionQueryData, "hits");

        return {
          queryId: queryData.queryId,
          title: queryData.title,
          value: queryTotalHits,
        };
      });

      if (!queryHitDataList.length) return null;

      return (
        <Stack maxWidth={260}>
          <TextLineClamp px={1} py={0.5} variant="subtitle2" lineClamp={1}>
            {features?.[0]?.properties?.ShapeName}
          </TextLineClamp>
          <Divider />
          <Stack direction="row" rowGap={1} columnGap={2} px={1} py={0.5}>
            <Stack>
              {queryHitDataList.map((queryHitData, i) => {
                if (!queryHitData) return null;
                return (
                  <TextLineClamp
                    key={`title:${i}:id=${queryHitData.queryId}`}
                    variant="subtitle2"
                    lineHeight="24px"
                    children={`${queryHitData.title}`}
                    lineClamp={1}
                  />
                );
              })}
            </Stack>

            <Stack>
              {queryHitDataList.map((queryHitData, i) => {
                if (!queryHitData) return null;
                return (
                  <Typography
                    key={`value:${i}:id=${queryHitData.queryId}`}
                    variant="body2"
                    lineHeight="24px"
                    children={queryHitData.value.toLocaleString()}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      );
    };

  return (
    <AppChartContainer title={title} actions={dataLevelSwitch}>
      <Stack flex={1} overflow="hidden" px={2} pb={2} position="relative">
        <MapBoxWrapper
          height="100%"
          renderFeatureDetails={renderFeatureDetails}
          valueByFeatureId={valueByFeatureId}
          transform={transformSourceFeatures}
          dataLevel={dataLevel}
          // showBorders
          // autoZoom
        />
        {isLoading && (
          <Stack
            top={0}
            left={16}
            right={16}
            position="absolute"
            children={<LinearProgress />}
          />
        )}
      </Stack>
    </AppChartContainer>
  );
}
