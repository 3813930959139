import {
  ChipProps,
  Stack,
  Typography,
  capitalize,
  Chip,
  Avatar,
} from "@mui/material";

export function DevChips() {
  const colors: ChipProps["color"][] = [
    "default",
    "primary",
    "secondary",
    "error",
    "warning",
    "info",
    "success",
  ];

  const variants: NonNullable<ChipProps["variant"]>[] = ["filled", "outlined"];

  const sizes: NonNullable<ChipProps>["size"][] = ["medium", "small"];
  return (
    <Stack gap={5}>
      {variants.map((variant) => (
        <Stack direction="column" gap={2} key={variant}>
          <Typography variant="h6">Chips / {capitalize(variant)}</Typography>

          <Stack direction="row" gap={3}>
            {colors.map((color) => (
              <Stack gap={5} key={color}>
                {sizes.map((size) => (
                  <Stack key={size} gap={3} alignItems="center">
                    <Chip
                      variant={variant}
                      color={color}
                      size={size}
                      label="Chip"
                    />
                    <Chip
                      variant={variant}
                      color={color}
                      size={size}
                      label="Chip"
                      avatar={<Avatar>F</Avatar>}
                      onClick={() => null}
                    />

                    <Chip
                      variant={variant}
                      color={color}
                      size={size}
                      label="Chip"
                      onClick={() => null}
                      onDelete={() => null}
                    />

                    <Chip
                      variant={variant}
                      color={color}
                      size={size}
                      label="Chip"
                      avatar={<Avatar>F</Avatar>}
                      onClick={() => null}
                      onDelete={() => null}
                    />
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
