import Joi from "joi";
import { ShareFormValues } from "./ShareForm.model";

export const ShareFormSchema = Joi.object<ShareFormValues>({
  recipients: Joi.array()
    .items(Joi.string().email({ tlds: { allow: false } }))
    .required(),
  subject: Joi.string().required(),
  note: Joi.string().allow("").max(200),
});
