import { EventSourceOption } from "src/api/useEventSources";

export function makeSourceCategoryIds(sources: EventSourceOption[]) {
  const countryList = sources.filter((s) => s.group === "CountryCode");

  const marketList = sources.filter((s) => s.group === "MarketID");

  const stateList = sources.filter((s) => s.group === "Country:MarketState");

  const stationList = sources.filter((s) => s.group === "StationGUID");

  const eventTypeList = sources.filter((s) => s.group === "EventTypeComposite");

  const languageList = sources.filter((s) => s.group === "LanguageCode");

  return {
    countryList,
    marketList,
    stateList,
    stationList,
    eventTypeList,
    languageList,
  };
}
