import { forwardRef } from "react";
import { Paper, PaperProps, Stack } from "@mui/material";
import { Spinner } from "../Spinner/Spinner";

export const makeAppAutocompletePaperComponent = ({
  spinner,
  renderHeader,
  renderFooter,
}: {
  spinner?: boolean;
  renderHeader?: () => JSX.Element;
  renderFooter?: () => JSX.Element;
}) => {
  return forwardRef<HTMLDivElement, PaperProps>(
    ({ children, ...paperProps }, ref) => {
      return (
        <Stack ref={ref} component={Paper} overflow="hidden" {...paperProps}>
          {renderHeader?.()}
          {spinner ? (
            <Stack alignItems="center" justifyContent="center" height={400}>
              <Spinner size={60} />
            </Stack>
          ) : (
            children
          )}
          {renderFooter?.()}
        </Stack>
      );
    }
  );
};
