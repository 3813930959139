import { useState } from "react";
import { paths } from "@tveyes/twosionwebapischema";
import { useEventSources, EventSourceCategory } from "src/api/useEventSources";
import { EventSourceOptionPicker } from "../EventSourceOptionPicker/EventSourceOptionPicker";
import { EventSourceOptionPickerProps } from "../EventSourceOptionPicker/EventSourceOptionPicker.model";

const key: keyof paths = "/api/source/autocomplete/{categories}/{searchText}";
type Endpoint = paths[typeof key]["get"];
type QueryParams = Endpoint["parameters"]["query"];

/**
 * An Autocomplete for picking specific Event Source Station value.
 */
export function EventSourceStationPicker({
  queryParams,
  ...props
}: Omit<
  EventSourceOptionPickerProps,
  "loading" | "onInputChange" | "category" | "options"
> & {
  queryParams?: QueryParams;
}) {
  const [inputQuery, setInputQuery] = useState("");

  const sourceCategoryOptions = useEventSources({
    query: {
      ...queryParams,
    },
    path: {
      categories: [EventSourceCategory.station],
      searchText: inputQuery ? inputQuery : "*",
    },
  });

  return (
    <EventSourceOptionPicker
      label="Station"
      onInputChange={setInputQuery}
      options={sourceCategoryOptions.data?.list ?? []}
      loading={sourceCategoryOptions.isFetching}
      autoComplete={false}
      {...props}
    />
  );
}
