import { Box } from "@mui/material";
import { LogoEye } from "./LogoEye";
import { LogoTVEyes } from "./LogoTVEyes";

export const Logo = (props: { height?: number; dark?: boolean }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      height={props.height}
    >
      <LogoEye
        dark={props.dark}
        minWidth={30}
        maxWidth={56}
        mr={0.5}
        height="100%"
      />

      <LogoTVEyes dark={props.dark} width={80} mt={1.5} mr={1.5} />
    </Box>
  );
};
