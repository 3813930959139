import { useRef, useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Tooltip,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { GlobalSearchFormValues } from "./GlobalSearchForm.model";
import { AdvertisementPicker } from "../AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "../ProgramTagPicker/ProgramTagPicker";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { TextInputBase } from "../TextInputBase";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { PopperInfo } from "../PopperInfo/PopperInfo";
import { AppDateTimePicker } from "../AppDateTimePicker/AppDateTimePicker";

export function GlobalSearchForm({ loading }: { loading?: boolean }) {
  const { breakpoints } = useTheme();
  const { control } = useFormContext<GlobalSearchFormValues>();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const containerRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const watchTermRow = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Keyword
        </Typography>
      </Grid>

      <Tooltip title='The "AND/OR" operators apply to both "Include" and "Exclude" fields at the same time.'>
        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="termsInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <WatchTermInput
                  id="term-include"
                  label="Include"
                  value={field.value}
                  options={[]} // no suggestions for now
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  autoFocus
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Tooltip>

      <Tooltip title='The "AND/OR" operators apply to both "Include" and "Exclude" fields at the same time.'>
        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="termsExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <WatchTermInput
                  id="term-exclude"
                  label="Exclude"
                  value={field.value}
                  options={[]} // no suggestions for now
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Tooltip>
    </Grid>
  );

  const displayNameRow = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Display name
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextInputBase
                id="display-name"
                label="Display name"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={loading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || " "}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  const dateStartEnd = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Dates
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="startDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState.error?.message || " ";

            return (
              <AppDateTimePicker
                label="Start Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                disabled={loading}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={5}>
        <Controller
          name="endDateTime"
          control={control}
          render={({ field, fieldState }) => {
            const message = fieldState?.error?.message || " ";

            return (
              <AppDateTimePicker
                label="End Date"
                value={field.value}
                onChange={field.onChange}
                views={["year", "month", "day", "hours", "minutes"]}
                disabled={loading}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    helperText: message,
                    error: !!fieldState.error,
                    fullWidth: true,
                    onBlur: field.onBlur,
                  },
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Stack>
      {/* create and updates have slight differences in row order */}
      {watchTermRow}
      {displayNameRow}
      {dateStartEnd}

      <Grid container visibility="hidden">
        {/* this is dimension element to align autocomplete dropdown */}
        <Grid item xs={12} md={12} lg={2} />
        <Grid item xs={12} md={12} lg={10} ref={containerRef} />
      </Grid>

      {/* Sources */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Source
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          <Controller
            name="sourcesInclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-include"
                  label="Include"
                  dropDownBoundsEl={containerRef}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          <Controller
            name="sourcesExclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-exclude"
                  label="Exclude"
                  dropDownBoundsEl={containerRef}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Sources */}

      {/* Advertisement */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            height={36.5}
          >
            Advertisement
            <Info
              onClick={handleClick}
              sx={{ ml: 1, color: colors.blueGrey[200], cursor: "pointer" }}
            />
            <PopperInfo
              open={open}
              anchorEl={anchorEl}
              title="Advertisement"
              text="The TVEyes platform introduces a robust and user-centric tool designed to enhance your experience in monitoring and analyzing advertising content across broadcast television and radio channels. This feature-rich popover provides versatile options for managing advertisements with a focus on convenience and customization."
              onClose={handleClose}
            />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          mb={3}
          display="flex"
          alignItems="center"
        >
          <Controller
            name="advertisement"
            control={control}
            render={({ field }) => {
              return (
                <AdvertisementPicker
                  id="advertisement"
                  fullWidth
                  disabled={loading}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5} padding={0} /* layout spacer */ />
      </Grid>
      {/* Advertisement */}

      {/* Program title */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Program Title
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-include"
                  label="Include"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program -exclude"
                  label="Exclude"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
