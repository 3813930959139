import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { accountKey } from "./useAccount";

const apiKey: keyof paths = "/api/Account/{id}";
const method: keyof paths[typeof apiKey] = "put";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Response = Endpoint["responses"]["200"]["content"]["application/json"];
type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];

type RequestMutationFnParams = {
  body: RequestBody;
  path: {
    id: Endpoint["parameters"]["path"]["id"];
  };
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useAccountUpdate({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ body, path }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl(apiKey, {
        path: {
          id: path.id,
        },
      });

      const response = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: accountKey,
      });
      enqueueSnackbar(`
       Account saved`);

      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError?.(error, variables, context);
    },
  });
}
