import { colors, Stack, Typography } from "@mui/material";
import { ANDOperator, NOTOperator } from "./Operators";

export const PopperNotContent = () => {
  return (
    <Stack py={0.5} px={1}>
      {/* start first line */}
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography color={colors.common.white} fontSize={14}>
          iPhone
        </Typography>
        <ANDOperator />
        <NOTOperator />
        <Typography color={colors.common.white} fontSize={14}>
          max
        </Typography>
      </Stack>
      {/* end first line */}

      {/* start second line */}
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography color={colors.common.white} fontSize={14}>
          (iPhone
        </Typography>
        <ANDOperator />
        <Typography color={colors.common.white} fontSize={14}>
          pro)
        </Typography>
        <ANDOperator />
        <NOTOperator />
        <Typography color={colors.common.white} fontSize={14}>
          max
        </Typography>
      </Stack>
      {/* end second line */}
    </Stack>
  );
};
