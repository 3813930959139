import axios from "axios";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

export enum DownloadReportFormat {
  csv = "csv",
  pdf = "pdf",
}

const apiPathPDF: keyof paths = "/api/Report/export/{id}/pdf";
const method: keyof paths[typeof apiPathPDF] = "get";

export async function reportDownloadRequest({
  format,
  reportId,
}: {
  format?: DownloadReportFormat;
  reportId: string;
}) {
  const formatPath = `/api/Report/export/${reportId}/${format}`;
  const url = makeApiUrl(formatPath, {
    path: {
      id: reportId,
    },
  });
  const respose = await axios<Response>(url, { method });
  return respose;
}
