export function makeQueryString(query: Record<string, unknown>) {
  const q = new URLSearchParams();

  Object.entries(query).forEach(([k, v]) => {
    if (v === undefined) {
      return;
    }

    if (Array.isArray(v)) {
      const csv = v.join(",");
      q.append(k, csv);
      return;
    }

    if (typeof v === "object" && v !== null) {
      throw new Error(`invalid key "${k}" - objects are not supported`);
    }

    if (typeof v === "symbol") {
      throw new Error(`invalid key "${k}" - symbols are not supported`);
    }

    const value = `${v}`;
    q.append(k, value);
  }, {} as Record<string, string>);

  q.sort();

  const result = q.toString();

  return result;
}
