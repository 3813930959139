import {
  Badge,
  CircularProgress,
  Stack,
  Typography,
  badgeClasses,
} from "@mui/material";
import { format, isValid, isEqual, max, min, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { components } from "@tveyes/twosionwebapischema";
import { EventDetails } from "src/models/EventDetails";
import { TimeLine } from "../TimeLine/TimeLine";
import { TimeLineZoomControlsProps } from "../TimeLine/components/TimeLineZoomControls/TimeLineZoomControls.model";

type Mention = components["schemas"]["Highlight"];

const renderDisabledBlockContent = (blockWidth: number) => (
  <Stack
    position="absolute"
    bottom={0}
    left={blockWidth}
    width={128}
    alignItems="center"
  >
    <CircularProgress variant="indeterminate" size={15} color="inherit" />
    <Typography variant="caption">Capture in progress</Typography>
  </Stack>
);

/**
 * Draggable TimeLine to render mentions
 */
export function TimeLineMentions({
  value,
  highlights,
  maxDate,
  onChange,
  zoomControlPosition,
  hideCursor,
  hideCursorBounds,
  hideZoomControls,
  showBounderyLines,
  height,
  timeZone,
  cursorBounds,
  disabledColor,
  initialUserScale,
}: {
  value: Date;
  highlights?: EventDetails["highlights"];
  maxDate: Date;
  hideCursor?: boolean;
  hideCursorBounds?: boolean;
  hideZoomControls?: boolean;
  showBounderyLines?: boolean;
  height?: number;
  onChange: (value: Date) => void;
  zoomControlPosition?: TimeLineZoomControlsProps["position"];
  timeZone?: string;
  cursorBounds?: [number, number];
  disabledColor?: string;
  initialUserScale?: number;
}) {
  return (
    <TimeLine
      value={value}
      cursorBounds={cursorBounds}
      maxDate={maxDate}
      onChange={onChange}
      hideCursor={hideCursor}
      showBounderyLines={showBounderyLines}
      zoomControlPosition={zoomControlPosition}
      hideCursorBounds={hideCursorBounds}
      hideZoomControls={hideZoomControls}
      height={height}
      renderDisabledBlockContent={renderDisabledBlockContent}
      disabledColor={disabledColor}
      initialUserScale={initialUserScale}
      renderBlockContent={({ blockStartTime, blockEndTime }) => {
        const date = timeZone
          ? utcToZonedTime(blockStartTime, timeZone)
          : new Date(blockStartTime);

        const label = isValid(date) ? format(date, "p") : " - ";

        const mentions = (highlights || []).reduce<Mention[]>((res, next) => {
          if (!next.timestamp) return res;

          const minDateValue = min([
            new Date(blockEndTime),
            new Date(next.timestamp),
          ]);
          const maxDateValue = max([date, new Date(next.timestamp)]);
          if (isEqual(minDateValue, maxDateValue)) {
            res.push(next);
          }
          return res;
        }, []);

        return (
          <Stack justifyContent="flex-end" height="100%">
            {mentions.length ? (
              <Badge
                color="primary"
                variant={mentions.length !== 1 ? undefined : "dot"}
                badgeContent={
                  mentions.length !== 1 ? mentions.length : undefined
                }
                sx={{
                  [`.${badgeClasses.root}`]: {
                    position: "unset",
                    width: 12,
                    height: 12,
                  },
                  [`.${badgeClasses.badge}`]: {
                    left: "50%",
                    top: "50%",
                    right: "unset",
                    transform: "translate(-50%, -100%)",
                  },
                }}
              />
            ) : null}
            <Typography variant="caption" align="center" children={label} />
          </Stack>
        );
      }}
      renderTimeLineContent={() => {
        const topLabelDate = timeZone
          ? utcToZonedTime(value, timeZone)
          : new Date(value);
        const topLabel = isValid(topLabelDate)
          ? format(startOfDay(topLabelDate), "MMMM dd")
          : " - ";

        return (
          <Typography position="absolute" variant="body1" bottom={52} left={16}>
            {topLabel}
          </Typography>
        );
      }}
    />
  );
}
