import {
  Stack,
  Typography,
  avatarClasses,
  useTheme,
  Avatar,
} from "@mui/material";
import {
  Control,
  Controller,
  UseFormWatch,
  UseFormGetValues,
} from "react-hook-form";
import { BannerFormValues } from "./UploadImageEditor.model";
import { UploadImageBannerForm } from "./UploadImageBannerForm";

export function UploadImageColorSelector({
  control,
  watch,
  previewCanvas,
  getValues,
}: {
  control: Control<BannerFormValues, any>;
  watch: UseFormWatch<BannerFormValues>;
  previewCanvas?: string;
  getValues: UseFormGetValues<BannerFormValues>;
}) {
  const { palette } = useTheme();

  return (
    <Stack>
      <Typography variant="subtitle2" my={1}>
        Banner preview
      </Typography>
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        gap={2}
        sx={{
          borderRadius: "8px",
          border: `1px solid ${palette.divider}`,
          padding: " 4px 16px",
          background: watch("bgcolor"),
          [`.${avatarClasses.root}`]: {
            border: `1px solid ${palette.text.disabled}`,
          },
        }}
      >
        <Avatar
          alt="avatar"
          src={previewCanvas}
          sx={{
            width: "58px",
            height: "58px",
            marginLeft: "8px",
          }}
        />
        <UploadImageBannerForm control={control} watch={watch} />
      </Stack>
      <Stack direction={"row"} mt={2} alignItems={"center"} gap={1}>
        <Typography>Pick color for banner</Typography>

        <Controller
          name="bgcolor"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <input
                style={{
                  height: "24px",
                  width: "24px",
                  border: 0,
                  padding: 0,
                }}
                id="bgcolor"
                type="color"
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
        <label htmlFor="color">{getValues("bgcolor")}</label>
      </Stack>
    </Stack>
  );
}
