import { forwardRef } from "react";
import { Box, PopperPlacementType, PopperProps, useTheme } from "@mui/material";

type AppPopperArrowProps = {
  placement: PopperPlacementType;
  boxShadow: string;
  arrowSize: number;
};

const getArrowContainerStyles = ({
  placement,
  arrowSize,
}: {
  arrowSize: number;
  placement: PopperProps["placement"];
}) => {
  const sizeMultiplier = 3;
  const crossSizeMultiplier = 2;

  switch (placement) {
    case "right":
    case "right-end":
    case "right-start":
      return {
        left: -arrowSize * crossSizeMultiplier,
        // make container larger to accommodate arrow shadows
        width: `${arrowSize * crossSizeMultiplier}px`,
        height: `${arrowSize * sizeMultiplier}px`,
      };

    case "left":
    case "left-end":
    case "left-start":
      return {
        right: -arrowSize * 2,
        // make container larger to accommodate arrow shadows
        width: `${arrowSize * crossSizeMultiplier}px`,
        height: `${arrowSize * sizeMultiplier}px`,
      };

    case "top":
    case "top-end":
    case "top-start":
      return {
        bottom: -arrowSize * crossSizeMultiplier,
        // make container larger to accommodate arrow shadows
        height: `${arrowSize * crossSizeMultiplier}px`,
        width: `${arrowSize * sizeMultiplier}px`,
      };

    case "bottom":
    case "bottom-end":
    case "bottom-start":
      return {
        top: -arrowSize * crossSizeMultiplier,
        // make container larger to accommodate arrow shadows
        height: `${arrowSize * crossSizeMultiplier}px`,
        width: `${arrowSize * sizeMultiplier}px`,
      };
  }
};

const getArrowIconStyles = ({
  placement,
  arrowSize,
}: {
  arrowSize: number;
  placement: PopperProps["placement"];
}) => {
  switch (placement) {
    case "right":
    case "right-end":
    case "right-start":
      return {
        right: `-${arrowSize}px`,
      };

    case "left":
    case "left-end":
    case "left-start":
      return {
        left: `-${arrowSize}px`,
      };

    case "top":
    case "top-end":
    case "top-start":
      return {
        top: `-${arrowSize}px`,
      };

    case "bottom":
    case "bottom-end":
    case "bottom-start":
      return {
        bottom: `-${arrowSize}px`,
      };
  }
};

export const AppPopperArrow = forwardRef<HTMLElement, AppPopperArrowProps>(
  ({ arrowSize, placement, boxShadow }, ref) => {
    const { palette } = useTheme();

    return (
      <Box
        className="arrow"
        ref={ref}
        sx={{
          overflow: "hidden", // required to clip other triangle of the box, that is not used as arrow
          pointerEvents: "none",
          display: "flex",
          ...getArrowContainerStyles({ arrowSize, placement }),
        }}
      >
        <Box
          sx={{
            position: "relative",
            margin: "auto",
            width: `${arrowSize}px`,
            height: `${arrowSize}px`,
            rotate: "-45deg",
            bgcolor: palette.background.paper,
            boxShadow,
            ...getArrowIconStyles({ arrowSize, placement }),
          }}
        />
      </Box>
    );
  }
);
