import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopMacOutlined, Movie, Pageview } from "@mui/icons-material";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { powerSearchRoute } from "src/pages/PowerSearch/PowerSearch.route";
import { mediaCenterRoute } from "src/pages/MediaCenter/MediaCenter.route";

import WatchTermsNoResults from "./watch-terms-no-results.png";
import AnalyticsNoResults from "./analytics-no-results.png";
import MediaCenterNoResults from "./media-center-no-results.png";
import NoUsersResults from "./no-users-results.png";
import ReportsNoResults from "./reports-no-results.png";
import SnapshotNoResults from "./snapshot-no-results.png";

import { InfoIconButton } from "../InfoIconButton/InfoIconButton";

type FeatureType =
  | "mediaCenter"
  | "analytics"
  | "users"
  | "accounts"
  | "events"
  | "reports"
  | "snapshot";

function makeNoResultsImage(featureType: FeatureType) {
  switch (featureType) {
    case "reports":
      return ReportsNoResults;
    case "mediaCenter":
      return MediaCenterNoResults;
    case "snapshot":
      return SnapshotNoResults;
    case "users":
    case "accounts":
      return NoUsersResults;
    case "analytics":
      return AnalyticsNoResults;
    default:
      return WatchTermsNoResults;
  }
}

const LimitsSection = ({ isMobile }: { isMobile?: boolean }) => (
  <Stack alignItems="center" gap={isMobile ? 1 : 4}>
    <Stack>
      <Typography
        variant="caption"
        color="text.secondary"
        textAlign="center"
        maxWidth={320}
      >
        Media Center limits:
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        textAlign="center"
        maxWidth={320}
      >
        All media files are available permanently in Media Center and the
        reports they are included in.
      </Typography>
    </Stack>

    <Typography
      variant="caption"
      color="text.secondary"
      textAlign="center"
      maxWidth={320}
    >
      Power Search and Watch Term results limits: Media is available for 90 days
      for TV and radio.
    </Typography>
  </Stack>
);

const noResultsDueToFilters = (
  <Typography variant="body2" textAlign="center" maxWidth={365}>
    No results match your current filter settings. Please try a different one.
  </Typography>
);

// General component
export function NoResultsOverlay({
  featureType,
  header,
  content,
}: {
  featureType: FeatureType;
  header: JSX.Element;
  content: JSX.Element;
}) {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Stack alignItems="center" justifyContent="center" gap={2}>
        <Box
          component="img"
          alt="No found"
          src={makeNoResultsImage(featureType)}
        />

        {header}

        <Stack alignItems="center">{content}</Stack>
      </Stack>
    </Stack>
  );
}
// General component

// Events
export function EventsNoResultsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No events
    </Typography>
  );

  return (
    <NoResultsOverlay
      featureType="events"
      header={header}
      content={noResultsDueToFilters}
    />
  );
}

export function FilteredEventsNoResultsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No events
    </Typography>
  );

  return (
    <NoResultsOverlay
      featureType="events"
      header={header}
      content={noResultsDueToFilters}
    />
  );
}
// Events

// Snapshots
export function SnapshotsNoEventsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No events
    </Typography>
  );

  const content = (
    <Typography variant="body2">There are no results yet.</Typography>
  );

  return (
    <NoResultsOverlay
      featureType="snapshot"
      header={header}
      content={content}
    />
  );
}
// Snapshots

// MediaCenter
export function MediaCenterNoFilteredEventsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No media
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={5}>
      {noResultsDueToFilters}

      <LimitsSection />
    </Stack>
  );

  return (
    <NoResultsOverlay
      featureType="mediaCenter"
      header={header}
      content={content}
    />
  );
}

export function MediaCenterNoSavedEventsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No saved media yet
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={5}>
      <Stack alignItems="center">
        <Typography variant="body2">
          Here you can find the media files you've "saved for
        </Typography>
        <Typography variant="body2">
          longer" from the event list, clip viewer, or editor.
        </Typography>
      </Stack>

      <LimitsSection />
    </Stack>
  );

  return (
    <NoResultsOverlay
      featureType="mediaCenter"
      header={header}
      content={content}
    />
  );
}
// MediaCenter

// Reports
export function ReportsNoSavedReportsOverlay() {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const header = (
    <Typography variant="h6" color="text.secondary">
      No saved reports yet
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={isMobile ? 2 : 5}>
      <Typography variant="body2" textAlign="center" maxWidth={380}>
        Create your first report from Power Search results, Watch Term results,
        the Clip Viewer, or the Media Center by using the “Export” button when
        selecting events, or from the Preselected Events tab on the Reports
        page.
      </Typography>

      <LimitsSection isMobile={isMobile} />

      <Stack flexDirection="row" justifyContent="space-between" gap={4}>
        {!isMobile && (
          <Button
            startIcon={<Movie />}
            sx={{ height: 56, width: 190 }}
            color="secondary"
            variant="contained"
            onClick={() => navigate(mediaCenterRoute.path)}
          >
            Media Center
          </Button>
        )}
        <Button
          startIcon={isMobile ? undefined : <DesktopMacOutlined />}
          sx={{ height: isMobile ? 36 : 56, width: isMobile ? undefined : 190 }}
          size="large"
          color="info"
          variant="contained"
          onClick={() => navigate(watchListRoute.path)}
        >
          Watch term
        </Button>
        <Button
          startIcon={isMobile ? undefined : <Pageview />}
          sx={{ height: isMobile ? 36 : 56, width: isMobile ? undefined : 190 }}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => navigate(powerSearchRoute.path)}
        >
          Power Search
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <NoResultsOverlay featureType="reports" header={header} content={content} />
  );
}

export function ReportsNoPreselectedEventsOverlay({
  onInfoButtonClick,
}: {
  onInfoButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
}) {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const header = (
    <Typography variant="h6" color="text.secondary">
      No events for your Report
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={isMobile ? 2 : 5}>
      <Typography variant="body2" textAlign="center" maxWidth={380}>
        Your report has no preselected events yet. To get started, go to Power
        Search results, Watch Term results, or the Media Center, select events,
        and add them to the Preselected Events list.
      </Typography>

      <LimitsSection isMobile={isMobile} />

      <Box>
        <InfoIconButton onClick={onInfoButtonClick} text="More info" />
      </Box>
      <Stack flexDirection="row" justifyContent="space-between" gap={4}>
        {!isMobile && (
          <Button
            startIcon={<Movie />}
            sx={{ height: 56, width: 190 }}
            color="secondary"
            variant="contained"
            onClick={() => navigate(mediaCenterRoute.path)}
          >
            Media Center
          </Button>
        )}
        <Button
          startIcon={isMobile ? undefined : <DesktopMacOutlined />}
          sx={{ height: isMobile ? 36 : 56, width: isMobile ? undefined : 190 }}
          size="large"
          color="info"
          variant="contained"
          onClick={() => navigate(watchListRoute.path)}
        >
          Watch term
        </Button>
        <Button
          startIcon={isMobile ? undefined : <Pageview />}
          sx={{ height: isMobile ? 36 : 56, width: isMobile ? undefined : 190 }}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => navigate(powerSearchRoute.path)}
        >
          Power Search
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <NoResultsOverlay featureType="reports" header={header} content={content} />
  );
}

export function ReportsNoFilteredEventsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No events
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={5}>
      {noResultsDueToFilters}

      <LimitsSection />
    </Stack>
  );

  return (
    <NoResultsOverlay featureType="reports" header={header} content={content} />
  );
}

export function ReportsNoFilteredReportsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No Reports
    </Typography>
  );

  const content = (
    <Stack alignItems="center" gap={5}>
      {noResultsDueToFilters}
    </Stack>
  );

  return (
    <NoResultsOverlay featureType="reports" header={header} content={content} />
  );
}
// Reports

// Users Management
export function NoUsersOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No users
    </Typography>
  );

  return (
    <NoResultsOverlay
      featureType="users"
      header={header}
      content={noResultsDueToFilters}
    />
  );
}
// Users Management

// Account Management
export function NoAccountsOverlay() {
  const header = (
    <Typography variant="h6" color="text.secondary">
      No Accounts
    </Typography>
  );

  return (
    <NoResultsOverlay
      featureType="accounts"
      header={header}
      content={noResultsDueToFilters}
    />
  );
}
// Account Management
