import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useRef } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const apiPath: keyof paths =
  "/api/mediacenter/{userid}/items/{itemid}/thumbnail";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type EndpointParameters = {
  path: {
    itemid: Endpoint["parameters"]["path"]["itemid"];
  };
};

// Usually we get response type from the schema.
// For some reason in the schema it is a Blob but actually it's a blob
// type Response = Endpoint["responses"]["200"]["content"]["application/json"];
type Response = Blob;

export function useMediaCenterThumbnail({
  params,
  options,
}: {
  params: EndpointParameters;
  options?: Pick<UseQueryOptions<string>, "onSuccess" | "onError" | "enabled">;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  const url = makeApiUrl(apiPath, {
    path: {
      userid: userId,
      itemid: params.path.itemid,
    },
  });

  const srcRef = useRef("");

  const queryFn = useCallback(async (): Promise<string> => {
    const token = await getAccessTokenSilently();

    const { data } = await axios<Response>(url, {
      method,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (srcRef.current) {
      URL.revokeObjectURL(srcRef.current);
    }

    srcRef.current = URL.createObjectURL(data);
    return srcRef.current;
  }, [url, getAccessTokenSilently]);

  return useQuery({
    ...options,
    // cache images as they don't change often
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000,
    queryKey: [apiPath, params],
    queryFn,
  });
}
