import { create } from "zustand";
import { ReportItem } from "src/models/Report";

type SelectionState<
  T extends {
    id: string;
  }
> = {
  list: T[];
  select: (report: T) => void;
  selectAll: (reports: T[]) => void;
  unselect: (report: T) => void;
  unselectAll: () => void;
};

export const useReportsSelection = create<SelectionState<ReportItem>>(
  (setState, getState) => ({
    list: [],
    select: (report) => {
      setState((state) => ({
        list: [...state.list, report],
      }));
    },
    selectAll: (reports) => {
      setState(() => ({
        list: reports,
      }));
    },
    unselect: (report) => {
      setState((state) => ({
        list: state.list.filter((stateReport) => {
          return report.id !== stateReport.id;
        }),
      }));
    },
    unselectAll: () => {
      const prev = getState();
      if (prev.list.length) {
        // update reference if there are items
        setState(() => ({
          list: [],
        }));
      }
    },
  })
);
