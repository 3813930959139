import axios from "axios";
import { add } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";

const apiPath: keyof paths = "/api/DateTimeSearch";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;

type DateTimeSnapshotParams = {
  station?: string;
  dateTime: Date;
};

type RequestParams = {
  params: {
    fetchParams: DateTimeSnapshotParams | null;
    cursorRangeBounds: [number, number];
  };
  options?: UseQueryOptions<DateTimeSearchResult, unknown>;
};

type Response = Endpoint["responses"][200]["content"]["application/json"];

type DateTimeSearchResult = Response;

export const snapshotsDateTimeQueryKey = apiPath;

export function useDateTimeSnapshot({ params, options }: RequestParams) {
  const { getAccessTokenSilently } = useAuth0();
  const { cursorRangeBounds, fetchParams } = params;

  const dateTimeSearchRange: [Date, Date] | null = fetchParams?.dateTime
    ? [
        add(fetchParams.dateTime, {
          seconds: cursorRangeBounds[0],
        }),
        add(fetchParams.dateTime, {
          seconds: cursorRangeBounds[1],
        }),
      ]
    : null;

  const t0 = dateTimeSearchRange?.[0]
    ? formatDateTimeWithoutTimeZone(dateTimeSearchRange[0])
    : undefined;

  const t1 = dateTimeSearchRange?.[1]
    ? formatDateTimeWithoutTimeZone(dateTimeSearchRange[1])
    : undefined;

  const query: Endpoint["parameters"]["query"] = {
    stationUUID: params?.fetchParams?.station,
    startDateTime: t0,
    endDateTime: t1,
  };
  const isNotUndefined = (v: unknown) => v !== undefined;

  return useQuery<DateTimeSearchResult, unknown>({
    queryKey: [snapshotsDateTimeQueryKey, params],
    enabled: Object.values(query).every(isNotUndefined),
    ...options,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, { query });

      const response = await axios<DateTimeSearchResult>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onError(err) {
      options?.onError?.(err);

      enqueueSnackbar({
        message: `Error fetching snapshots clip result: ${err}`,
        variant: "error",
      });
    },
  });
}
