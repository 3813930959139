import { paths } from "@tveyes/twosionwebapischema";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath = "/api/Export/download/{userId}/{type}/{id}";
const method: keyof paths[typeof apiPath] = "get";
type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export async function downloadReportFile({
  userId,
  type,
  id,
  name = "Report",
}: {
  userId: string;
  type: string;
  id: string;
  name: string;
}) {
  const url = makeApiUrl(apiPath, {
    path: {
      userId,
      type,
      id,
    },
  });

  const fileName = `${name.replace(/ /g, "_")}.${type}`;
  try {
    const { data } = await axios<Response>({
      url,
      responseType: "blob",
    });
    if (type) {
      const blob = new Blob([data], {
        type: type === "pdf" ? "application/pdf" : "text/csv;charset=utf-8",
      });
      const file = new File([blob], fileName);
      const hrefUrl = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.setAttribute("download", fileName);
      link.href = hrefUrl;
      link.setAttribute("target", "_blank");
      link.click();
      URL.revokeObjectURL(hrefUrl);
    }
  } catch (e) {
    console.log("error", e);
    enqueueSnackbar(`Error downloading ${type}`, { variant: "error" });
  }
}
