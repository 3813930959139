import { useCallback } from "react";
import { Box } from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { PowerSearchResultItem } from "src/api/usePowerSearch";

type ReportEditEventsDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  events: PowerSearchResultItem[];
  handleDelete: (events: PowerSearchResultItem[]) => void;
};

export const ReportEditEventsDeleteDialog = ({
  onClose,
  events,
  handleDelete,
  ...props
}: ReportEditEventsDeleteDialogProps) => {
  const deleteEvents = useCallback(() => {
    handleDelete(events);
    onClose && onClose();
  }, [onClose, events, handleDelete]);

  return (
    <DialogPrompt
      title={
        events.length === 1 ? "Delete the event?" : "Delete selected events?"
      }
      children={
        <Box pb={1}>
          {events.length === 1
            ? `Do you want to delete the "${events[0].title}" item?`
            : "Do you want to delete selected events?"}
        </Box>
      }
      onDecline={onClose}
      onConfirm={deleteEvents}
      confirmLabel="Delete"
      {...props}
    />
  );
};
