import { colors, Stack, Typography } from "@mui/material";
import { ANDOperator } from "./Operators";

export const PopperAndContent = () => (
  <Stack py={0.5} px={1}>
    {/* start first line */}
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Typography color={colors.common.white} fontSize={14}>
        iPhone
      </Typography>
      <ANDOperator />
      <Typography color={colors.common.white} fontSize={14}>
        pro
      </Typography>
    </Stack>
    {/* end first line */}

    {/* start second line */}
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Typography color={colors.common.white} fontSize={14}>
        iPhone
      </Typography>
      <ANDOperator />
      <Typography color={colors.common.white} fontSize={14}>
        pro
      </Typography>
      <ANDOperator />
      <Typography color={colors.common.white} fontSize={14}>
        discount
      </Typography>
    </Stack>
    {/* end second line */}
  </Stack>
);
