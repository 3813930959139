import React, { forwardRef, RefObject, useMemo } from "react";
import {
  autocompleteClasses,
  Autocomplete,
  AutocompleteProps,
  Chip,
  Stack,
  useTheme,
  inputBaseClasses,
  outlinedInputClasses,
} from "@mui/material";
import { makeAutocompleteInlinePopperComponent } from "./AutocompleteInlinePopper";
import { makeAutocompleteInlinePaperComponent } from "./AutocompleteInlinePaper";
import { AutocompleteInlineCountBadge } from "./AutocompleteInlineCountBadge";
import {
  AutocompleteInlineTagContainer,
  AutocompleteInlineTagContainerProps,
} from "./AutocompleteInlineTagContainer";

const defaultShowTotalCount = (count: number) => count > 1;

export type AutocompleteInlineProps<
  T = unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  /** Width of the dropdown component */
  dropDownWidth?: number;

  /** Limit size of the dropdown to the bounding element */
  dropDownBoundsEl?: RefObject<HTMLElement>;

  /** Render elements at the beginning of the component layout, they will not be scrolled
   * @param inherited - React elements rendered by inherited component
   * @returns - New React node to be used as start adornment
   */
  renderStartAdornment?: (inherited: React.ReactNode) => React.ReactNode;

  /** Show total tag count badge at the start of the control */
  showTotalCount?: boolean | typeof defaultShowTotalCount;

  /** Automatically scroll to the last tag added  */
  scrollToLast?: AutocompleteInlineTagContainerProps["scrollToLast"];
};

/** This is internal class for proper typecasting with forwardRef */
const AutocompleteInlineComponent = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  _props: AutocompleteInlineProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => {
  return <div />;
};

/**
 * A tuned version of MUI Autocomplete
 * - has horizontal scrolling instead of vertical growth.
 * - can limit dropdown dimensions to width and/or specific container.
 * - shows total number of tags selected at the left.
 * - uses fade gradient for items that are going out of the viewport.
 * - handle input growing on hover and focus.
 * @deprecated use AppAutocomplete for new code
 */
export const AutocompleteInline = forwardRef(
  <
    T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false
  >(
    {
      dropDownWidth,
      dropDownBoundsEl,
      showTotalCount = defaultShowTotalCount,
      scrollToLast,
      renderTags,
      renderStartAdornment,
      getOptionLabel,
      PopperComponent,
      PaperComponent,
      loading,
      size = "medium",
      ...props
    }: AutocompleteInlineProps<T, Multiple, DisableClearable, FreeSolo>,
    ref: React.ForwardedRef<HTMLDivElement | null>
  ) => {
    const { transitions } = useTheme();

    const getOptionLabelWrapper: typeof getOptionLabel = (option) => {
      return getOptionLabel ? getOptionLabel(option) : `${option}`;
    };

    const defaultRenderTags: typeof renderTags = (
      value,
      getTagProps,
      _ownerState
    ) => {
      const tagElements = value.map((option, index) => {
        const label = getOptionLabelWrapper(option);
        const { key, ...tagProps } = getTagProps({ index });
        return <Chip key={key} label={label} {...tagProps} size={size} />;
      });

      return tagElements;
    };

    const renderTotalCountAdornment = () => {
      if (!Array.isArray(props.value)) {
        return null;
      }

      const canRenderCountBadge =
        typeof showTotalCount === "function"
          ? showTotalCount(props.value.length)
          : showTotalCount;

      return (
        canRenderCountBadge && (
          <AutocompleteInlineCountBadge
            count={props.value.length}
            size={size}
          />
        )
      );
    };

    const startAdornment = renderTotalCountAdornment();

    const renderTagsWrapper: typeof renderTags = (
      value,
      getTagProps,
      ownerState
    ) => {
      const tagElements = renderTags
        ? renderTags(value, getTagProps, ownerState)
        : defaultRenderTags(value, getTagProps, ownerState);

      return (
        <Stack direction="row" overflow="hidden">
          {renderStartAdornment
            ? renderStartAdornment(startAdornment)
            : startAdornment}
          <AutocompleteInlineTagContainer
            size={size}
            scrollToLast={scrollToLast}
            children={tagElements}
          />
        </Stack>
      );
    };

    const DefaultPopperComponent = useMemo(() => {
      return makeAutocompleteInlinePopperComponent({
        dropDownBoundsEl,
        dropDownWidth,
      });
    }, [dropDownBoundsEl, dropDownWidth]);

    const DefaultPaperComponent = useMemo(() => {
      return makeAutocompleteInlinePaperComponent({
        spinner: loading,
      });
    }, [loading]);

    return (
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        {...props}
        ref={ref}
        size={size}
        slotProps={{
          ...props.slotProps,
          paper: {
            elevation: 6,
            ...props.slotProps?.paper,
          },
        }}
        sx={{
          [`& .${autocompleteClasses.inputRoot}`]: {
            // for scrolling instead of multiple lines
            flexWrap: "nowrap",
            overflow: "hidden",
            // make better usage of height to put scrollbars there
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: size === "medium" ? "56px" : "40px",
          },
          [`.${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
            paddingTop: 0,
            paddingBottom: 0,
          },
          // below is to make input wider when user interacts with it
          [`.${autocompleteClasses.input}`]: {
            transition: transitions.create(["min-width"], {
              duration: 200,
            }),
          },
          [`& .${autocompleteClasses.input}:hover`]: {
            minWidth: "max(100px, 25%)",
          },
          [`& .${autocompleteClasses.focused} .${autocompleteClasses.input}`]: {
            minWidth: "max(100px, 25%)",
          },
          // if user provides sx in props - spread them last
          ...props.sx,
        }}
        renderTags={renderTagsWrapper}
        getOptionLabel={getOptionLabelWrapper}
        PopperComponent={PopperComponent || DefaultPopperComponent}
        PaperComponent={PaperComponent || DefaultPaperComponent}
      />
    );
  }
  // typecast is the simplest way to forwardRef for generic component
) as typeof AutocompleteInlineComponent;
