import { ReactNode } from "react";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export const PopperBaseInfoContent = ({
  title,
  titleIcon,
  description,
  onClose,
}: {
  title: string;
  titleIcon?: JSX.Element;
  description: string | ReactNode;
  onClose: () => void;
}) => {
  const { palette } = useTheme();
  return (
    <Stack>
      <Stack direction="row" alignItems="center" pl={3} pr={2} py={2}>
        {titleIcon ? titleIcon : null}
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose} sx={{ ml: "auto" }}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Stack
        px={3}
        py={2}
        sx={{
          backgroundColor:
            palette.mode === "light" ? palette.background.default : "inherit",
        }}
      >
        {description}
      </Stack>
      <Divider />
      <Stack
        alignItems="flex-end"
        justifyContent="center"
        sx={{ py: 2, px: 3 }}
      >
        <Button variant="text" color="error" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Stack>
  );
};
