import { AppRouteBuilder } from "src/models/AppRoute";
import { PowerSearchResultClipEditorPage } from "./PowerSearchResultClipEditor.page";

export type PowerSearchResultClipEditorPathParam = {
  eventId: string;
};

export const powerSearchResultClipEditorRoute =
  new AppRouteBuilder<PowerSearchResultClipEditorPathParam>({
    path: "/power-search/result/:eventId/edit",
    element: <PowerSearchResultClipEditorPage />,
  });
