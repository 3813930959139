import { useMemo } from "react";
import { makeOffsetsFromHighlights } from "src/utils/makeOffsetsFromHighlights";
import { Highlight } from "src/models/Highlight";

const empty: number[] = [];

export function useClipViewerOffsets(highlights?: Highlight[]): number[] {
  return useMemo(() => {
    if (!highlights) {
      return empty;
    }

    const nextOffsets = makeOffsetsFromHighlights({
      highlights,
    });

    return nextOffsets;
  }, [highlights]);
}
