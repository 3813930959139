import { create } from "zustand";
import { useEffect, useMemo } from "react";
import { useAppChartColorPalette } from "./useAppChartColorPalette";

type BgFg = { bg: string; fg: string; icon: string };

type ColorMapperState = {
  colors: BgFg[];
  fallback: BgFg;
  colorMap: {
    [key: string]: BgFg | undefined;
  };
  releaseColors: (id?: (string | null)[]) => void;
  getColorForId: (id?: string | null) => BgFg;
  setFallback: (color: BgFg) => void;
  setAvailable: (colors: BgFg[]) => void;
};

const useAppChartColorMapperStore = create<ColorMapperState>((set, get) => ({
  fallback: {
    bg: "rgba(127,127,127, 0.5)",
    fg: "rgba(255,255,255, 0.75)",
    icon: "rgba(255,255,255, 0.75)",
  },
  colors: [],
  colorMap: {},
  setFallback: (fallback) => {
    set({ fallback });
  },
  setAvailable: (colors) => {
    set({ colors });
  },
  releaseColors: (ids) => {
    const { colorMap } = get();
    const nextColorMap = { ...colorMap };

    if (!ids) {
      ids = Object.keys(colorMap);
    }

    for (const id of ids) {
      if (id) {
        delete nextColorMap[id];
      }
    }

    set({ colorMap: nextColorMap });
  },
  getColorForId: (id) => {
    const { colors, colorMap, fallback } = get();

    if (!id) {
      return fallback;
    }

    const prevColor = colorMap[id];

    if (prevColor) {
      // already selected color
      return prevColor;
    }

    // get next color from available colors and add to color map
    const used = Object.values(colorMap);

    // find unused color
    const nextColor = colors.find((c) => !used.includes(c));

    if (nextColor) {
      const nextColorMap = {
        ...colorMap,
        [id]: nextColor,
      };

      set({
        colorMap: nextColorMap,
      });

      return nextColor;
    }

    return fallback;
  },
}));

export const useAppChartColorMapper = () => {
  const { getColorForId, releaseColors } = useAppChartColorMapperStore();

  const { setAvailable, fallback, setFallback } = useAppChartColorMapperStore();

  const nextAvailableColors = useAppChartColorPalette();

  useEffect(() => {
    setAvailable(nextAvailableColors);
    setFallback(fallback);
  }, [fallback, nextAvailableColors, setAvailable, setFallback]);

  useEffect(() => {
    return () => releaseColors();
  }, [releaseColors]);

  return useMemo(
    () => ({
      getColorForId,
      releaseColors,
    }),
    [getColorForId, releaseColors]
  );
};
