import { useMemo } from "react";
import { Badge, BadgeProps } from "@mui/material";
import { DrawerNavMenuItem, DrawerNavMenuItemProps } from "./DrawerNavMenuItem";

type BadgeDotProps = {
  type: "dot";
};

type BadgeContentProps = {
  type: "content";
} & BadgeProps;

type NavMenuBadgeProps = BadgeDotProps | BadgeContentProps;

type DrawerNavMenuItemBadgeProps = Omit<DrawerNavMenuItemProps, "icon"> & {
  icon: JSX.Element;
  badgeProps?: NavMenuBadgeProps;
};

export const DrawerNavMenuItemBadge = ({
  badgeProps,
  icon,
  ...props
}: DrawerNavMenuItemBadgeProps) => {
  const menuIcon = useMemo(() => {
    if (badgeProps?.type === "dot") {
      return (
        <Badge color="info" overlap="circular" variant="dot">
          {icon}
        </Badge>
      );
    }

    if (badgeProps?.type === "content") {
      return (
        <Badge color="info" badgeContent={badgeProps.badgeContent}>
          {icon}
        </Badge>
      );
    }

    return icon;
  }, [badgeProps, icon]);

  return <DrawerNavMenuItem icon={menuIcon} {...props} />;
};
