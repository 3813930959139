import { Typography, styled, useTheme } from "@mui/material";
import AutoAwesome from "@mui/icons-material/AutoAwesome";

const textShine = `
  @keyframes textShine {
    0% {
      background-position: 0% 50%;
      filter: brightness(1.5);
    }
    100% {
      background-position: 100% 50%;
      filter: brightness(0.8);
    }
  }
`;

const StyledTypography = styled(Typography)`
  ${textShine}
  color: ${({ theme }) => theme.palette.text.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 13px;
  background: linear-gradient(
    to right,
    #7953cd 20%,
    #00affa 30%,
    #0190cd 70%,
    #764ada 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 2s ease-in-out infinite alternate;

  & .animated-icon {
    font-size: inherit;
    height: 20px;
    margin-right: ${({ theme }) => theme.spacing(1)};
    animation: inherit;
    color: inherit;
    filter: brightness(1.5);
  }
`;

export const AnimatedHint = ({ isLoading }: { isLoading: boolean }) => {
  const { palette } = useTheme();
  if (!isLoading) {
    return (
      <Typography
        color={palette.text.disabled}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontStyle="italic"
        fontSize="13px"
      >
        <AutoAwesome fontSize="small" sx={{ mr: 1 }} />
        AI Query Assistant
      </Typography>
    );
  }

  return (
    <StyledTypography>
      <span className="animated-icon">
        <AutoAwesome fontSize="small" />
      </span>
      AI Query Assistant
    </StyledTypography>
  );
};
