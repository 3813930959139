import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { ClearOutlined, SearchOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  TextFieldDebounced,
  TextFieldDebouncedProps,
} from "./TextFieldDebounced/TextFieldDebounced";

export type SearchInputProps = Omit<
  TextFieldDebouncedProps,
  "value" | "onChange" | "startAdornment" | "endAdornment"
> & {
  value?: string;
  onChange?: (value: string) => void;
  loading?: boolean;
  hideClear?: boolean;
};

export function SearchInput({
  value,
  onChange,
  loading,
  hideClear,
  InputProps,
  ...props
}: SearchInputProps) {
  const [inputValue, setInputValue] = useState(value || "");

  const onChangeWrapper: TextFieldDebouncedProps["onChange"] = (e) => {
    setInputValue(e.target.value);
  };

  const handleClear = () => {
    setInputValue("");
  };

  useEffect(() => {
    if (value !== undefined && value !== inputValue) {
      setInputValue(value);
    }
  }, [value, inputValue]);

  useEffect(() => {
    onChange && onChange(inputValue);
  }, [onChange, inputValue]);

  const clearButton = !!inputValue && !hideClear && (
    <IconButton onClick={handleClear} edge="end">
      <ClearOutlined />
    </IconButton>
  );

  const activityIndicator = loading && (
    <CircularProgress size={18} color="secondary" sx={{ m: 0.5 }} />
  );

  return (
    <TextFieldDebounced
      placeholder="Search..."
      variant="standard"
      {...props}
      value={inputValue}
      onChange={onChangeWrapper}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {clearButton}
            {activityIndicator}
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
}
