import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  Analytics,
  BookmarkAdded,
  ModeEdit,
  MoreVertOutlined,
  RemoveRedEyeOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { IconButtonDropDown } from "src/components/IconButtonDropDown/IconButtonDropDown";
import { SaveDateTimeSearchClipDialog } from "src/components/SaveDateTimeSearchClipDialog/SaveDateTimeSearchClipDialog";
import { dateTimeSearchClipRoute } from "src/pages/DateTimeSearchClip/DateTimeSearchClip.route";
import { useDateTimeSearchDetails } from "src/api/useDateTimeSearchDetails";
import { useReportScratchAppendFromDateTime } from "src/api/useReportScratchAppendFromDateTime";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { useOpenState } from "src/utils/useOpenState";
import { dateTimeSearchClipEditorRoute } from "src/pages/DateTimeSearchClipEditor/DateTimeSearchClipEditor.route";
import { ShareDateTimeSearchDialog } from "src/components/ShareDateTimeSearchDialog/ShareDateTimeSearchDialog";
import { IconButtonDropDownProps } from "src/components/IconButtonDropDown/IconButtonDropDown.model";
import { useSessionContext } from "src/api/useSessionContext";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";

export type WatchTermResultsActionsProps = Omit<
  IconButtonDropDownProps,
  "children" | "value" | "renderDropDown" | "ref"
> & {
  value: DateTimeSearchResultItem;
};

export const DateTimeSearchResultActions = ({
  value,
  ...props
}: WatchTermResultsActionsProps) => {
  const navigate = useNavigate();
  const [openSaveClipDialog, setOpenSaveClipDialog] = useState(false);
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();

  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;

  const clipViewerUrl = dateTimeSearchClipRoute.makeUrl({});
  const clipEditorUrl = dateTimeSearchClipEditorRoute.makeUrl({});

  const details = useDateTimeSearchDetails({});

  const { setHighlightedEventsIds } = useEventsPreselected();

  const reportScratchAppend = useReportScratchAppendFromDateTime({});
  const addItemToReport = useCallback(() => {
    reportScratchAppend.mutate({ items: [value] });
    setHighlightedEventsIds([value.sourceEventIds?.[0] || ""]);
    enqueueSnackbar(
      `${value.title} was successfully pre selected and sent to the "Report pre selected" page, now you can create a new Report from it`,
      {
        variant: "success",
      }
    );
  }, [reportScratchAppend, value, setHighlightedEventsIds]);

  return (
    <>
      <IconButtonDropDown
        {...props}
        children={<MoreVertOutlined />}
        renderDropDown={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                shareDialog.show();
              }}
            >
              <ListItemIcon>
                <ShareOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                addItemToReport();
              }}
            >
              <ListItemIcon>
                <Analytics fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Add to Report</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                navigate(clipViewerUrl);
                details.mutate({
                  body: value,
                });
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                details.mutate({
                  body: value,
                });

                closeMenu();
                navigate(clipEditorUrl);
              }}
            >
              <ListItemIcon>
                <ModeEdit fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            {/* FIXME: no eventType provided */}
            <TooltipBase
              width={181}
              textAlign="left"
              title={
                enableMediaDownload
                  ? "Save for 90 days to the Media Center from where you can select clips when create report or share items"
                  : "You can't save media as you have an account limitation. Please contact your account manager to extend your limits."
              }
            >
              <Box>
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setOpenSaveClipDialog(true);
                  }}
                  disabled={!enableMediaDownload}
                >
                  <ListItemIcon>
                    <BookmarkAdded fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Save To Media Center</ListItemText>
                </MenuItem>
              </Box>
            </TooltipBase>
          </Menu>
        )}
      />
      <SaveDateTimeSearchClipDialog
        open={openSaveClipDialog}
        event={value}
        onClose={() => setOpenSaveClipDialog(false)}
      />
      <ShareDateTimeSearchDialog
        open={shareDialog.isOpen}
        items={[value]}
        onClose={shareDialog.hide}
        onSubmit={shareFormSnackBar.show}
        copyShareLinkCallback={shareLinkSnackBar.show}
      />
    </>
  );
};
