import useResizeObserver from "@react-hook/resize-observer";
import { useState, useLayoutEffect, RefObject } from "react";

export const useElementSize = (
  targetRef: RefObject<HTMLElement> | null | undefined
) => {
  const [size, setSize] = useState<DOMRect>();

  useResizeObserver(targetRef ?? null, (entry) => setSize(entry.contentRect));

  useLayoutEffect(() => {
    setSize(targetRef?.current?.getBoundingClientRect());
  }, [targetRef]);

  return size;
};
