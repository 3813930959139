import { Palette, alpha } from "@mui/material";
import {
  AccessAlarmOutlined,
  CalendarMonth,
  CalendarToday,
  DateRange,
  HourglassEmpty,
  MoreTime,
  QueryBuilder,
  WbSunnyOutlined,
} from "@mui/icons-material";
import { lime, purple, indigo, deepPurple } from "@mui/material/colors";
import { ScheduledAlertType } from "../ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  WeekDays,
  scheduledAlertTypeDict,
} from "../ScheduledAlertPopover.utils";

export function getBackgroundColor(
  palette: Palette,
  type: ScheduledAlertType,
  darkMode: boolean = false
) {
  switch (type) {
    case "Custom Monthly":
      return alpha(palette["info"]["main"], 0.12);
    case "Custom Day of Week":
      return alpha(palette["error"]["main"], 0.12);
    case "Custom Every Day":
      return alpha(palette["secondary"]["main"], 0.12);
    case "Hourly":
      return alpha(palette["warning"]["main"], 0.12);
    case "First Day of the Month":
      return darkMode ? "#f9fbe71f" : [lime["50"]];
    case "Monday Morning":
      return darkMode ? "#f3e5f51f" : [purple["50"]];
    case "Three Times Daily":
      return darkMode ? "#e8eaf61f" : [indigo["50"]];
    case "Every Weekday Morning":
      return alpha(palette["warning"]["main"], 0.12);
  }
}

export function getAlertIcon(type: ScheduledAlertType) {
  switch (type) {
    case "Custom Monthly":
      return CalendarMonth;
    case "Custom Day of Week":
      return DateRange;
    case "Custom Every Day":
      return QueryBuilder;
    case "Hourly":
      return HourglassEmpty;
    case "First Day of the Month":
      return CalendarToday;
    case "Monday Morning":
      return WbSunnyOutlined;
    case "Three Times Daily":
      return AccessAlarmOutlined;
    case "Every Weekday Morning":
      return MoreTime;
  }
}

export function getIconColor(palette: Palette, type: ScheduledAlertType) {
  switch (type) {
    case "Custom Monthly":
      return palette["info"]["main"];
    case "Custom Day of Week":
      return palette["error"]["main"];
    case "Custom Every Day":
      return palette["secondary"]["main"];
    case "Hourly":
      return palette["warning"]["main"];
    case "First Day of the Month":
      return [lime["800"]];
    case "Monday Morning":
      return [deepPurple["500"]];
    case "Three Times Daily":
      return [indigo["400"]];
    case "Every Weekday Morning":
      return palette["warning"]["main"];
  }
}

export function getAlertName(type: ScheduledAlertType) {
  switch (type) {
    case "Custom Monthly":
      return "Monthly";
    case "Custom Day of Week":
      return "Weekly";
    case "Custom Every Day":
      return "Every Day";
    case "Hourly":
      return "Hourly";
    case "First Day of the Month":
      return "First day of the Month";
    case "Monday Morning":
      return "Monday morning";
    case "Three Times Daily":
      return "Three times daily";
    case "Every Weekday Morning":
      return "Every Weekday";
  }
}

export function getAlertScheduleTime(
  alertType: ScheduledAlertType,
  value: string
) {
  const type = scheduledAlertTypeDict[alertType];
  switch (type) {
    case AlertTypesKey.hourly:
      return value;

    case AlertTypesKey.daily: {
      const time = value.split(/(pm|am)/)[0];
      const parsedTime = value.toLocaleUpperCase().replace(time, `${time} `);
      return parsedTime;
    }

    case AlertTypesKey.weekly: {
      const splittedValue = value.split(",");
      const time = splittedValue[0].split(/(pm|am)/)[0];
      const parsedTime = splittedValue[0]
        .toLocaleUpperCase()
        .replace(time, `${time} `);

      const splittedDays = splittedValue[1].split("/");
      const parsedDays = splittedDays.map(
        (d) => d.charAt(0).toLocaleUpperCase() + d.slice(1)
      );
      return `${parsedDays.join(" ")} ${parsedTime}`;
    }

    case AlertTypesKey.monthly: {
      const splittedValue = value.split(",");
      const time = splittedValue[0].split(/(pm|am)/)[0];
      const parsedTime = splittedValue[0]
        .toLocaleUpperCase()
        .replace(time, `${time} `);

      const digitRegex = /\d/;
      const anyDigitExist = digitRegex.test(splittedValue[1]);
      if (anyDigitExist) {
        return `Each ${splittedValue[1]
          .split("/")
          .join(", ")} day of the month at ${parsedTime}`;
      }

      const splittedDaysValue = splittedValue[1].split(" ");
      const values = [splittedDaysValue[0]];

      if (splittedDaysValue[1] in WeekDays) {
        const updatedValue = splittedDaysValue[1].toLocaleUpperCase();
        values.push(updatedValue);
      } else {
        values.push(splittedDaysValue[1]);
      }
      return `Every ${values.join(" ")} of the month at ${parsedTime}`;
    }
  }
}
