import { differenceInSeconds } from "date-fns";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import { TimeLineProgramLayoutProps } from "./TimeLineProgramLayout.model";
import { TimeLineProgram } from "../TimeLineProgram/TimeLineProgram";

export const TimeLineProgramLayout = ({
  time,
  schedule,
  secondsPerPixel,
}: TimeLineProgramLayoutProps) => {
  const scheduleStartDateTime = useMemo(
    () =>
      schedule.startDateTime ? new Date(schedule.startDateTime + "Z") : null,
    [schedule.startDateTime]
  );

  const scheduleEndDateTime = schedule.endDateTime
    ? new Date(schedule.endDateTime + "Z")
    : null;

  const scheduleSpanSec =
    scheduleEndDateTime && scheduleStartDateTime
      ? differenceInSeconds(scheduleEndDateTime, scheduleStartDateTime)
      : 0;

  const scheduleTotalWidth = scheduleSpanSec / secondsPerPixel;
  const scheduleBlockOffsetSec = scheduleStartDateTime
    ? differenceInSeconds(time, scheduleStartDateTime)
    : 0;

  const scheduleBlockOffset = scheduleBlockOffsetSec / secondsPerPixel;

  const programBlocks = useMemo(() => {
    if (!scheduleStartDateTime) {
      return null;
    }

    const programs = schedule.programs || [];
    return programs.map((program) => {
      if (!program.startDateTime || !program.endDateTime) {
        return null;
      }
      const blockStartTime = new Date(program.startDateTime + "Z");

      // offset inside container
      const blockOffsetSec = differenceInSeconds(
        blockStartTime,
        scheduleStartDateTime
      );

      const offset = blockOffsetSec / secondsPerPixel;

      return (
        <TimeLineProgram
          key={`${program.startDateTime}-${program.endDateTime}:${program.title}`}
          program={program}
          secondsPerPixel={secondsPerPixel}
          offset={offset}
        />
      );
    });
  }, [schedule, secondsPerPixel, scheduleStartDateTime]);

  return (
    <Stack
      direction="row"
      position="absolute"
      top={0}
      bottom={0}
      left={`calc(50% - ${scheduleBlockOffset}px)`}
      width={scheduleTotalWidth}
      children={programBlocks}
    />
  );
};
