import { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Palette,
  Stack,
  alpha,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Spinner } from "src/components/Spinner/Spinner";
import {
  TagPickerProps,
  TagPickerValue,
} from "src/components/TagPicker/TagPicker.model";
import { EventSourceOption } from "src/api/useEventSources";

export type SortedOptionsType<T> = {
  [k: string]: T[];
};

type BuildAccordionDetailsProps<T> = {
  options: TagPickerProps<T>["options"];
  selectedOptions: TagPickerValue<T>;
  setSelectedOptions: Dispatch<SetStateAction<TagPickerValue<T>>>;
};

type ModalContentSectionProps<T> = {
  tags: TagPickerValue<T>;
  setTags: Dispatch<SetStateAction<TagPickerValue<T>>>;
  options: SortedOptionsType<T>;
  openedSection: {
    [k: string]: boolean;
  };
  setOpenedSection: Dispatch<
    SetStateAction<{
      [k: string]: boolean;
    }>
  >;
  getGroupLabel?: TagPickerProps<T>["getGroupLabel"];
  loading?: boolean;
};

function getSectionLabel<T>(
  group: string,
  getGroupLabel?: TagPickerProps<T>["getGroupLabel"]
) {
  return getGroupLabel ? getGroupLabel(group) : group;
}

function buildAccordionSummary(label: string, palette: Palette) {
  return (
    <AccordionSummary
      expandIcon={<ExpandMore />}
      sx={{
        flexDirection: "row-reverse",
        gap: 4,
        backgroundColor: alpha(palette.primary.main, 0.12),
        mt: 0.5,
        "& .MuiAccordionSummary-expandIconWrapper": {
          color: palette.primary.main,
        },
      }}
    >
      {label}
    </AccordionSummary>
  );
}

function buildAccordionDetails({
  options,
  selectedOptions,
  setSelectedOptions,
}: BuildAccordionDetailsProps<EventSourceOption>) {
  return (
    <AccordionDetails>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={
                  !!selectedOptions.list.find(
                    (tag) =>
                      tag.group === option.group && tag.value === option.value
                  ) || false
                }
              />
            }
            label={option.name}
            onChange={(e: ChangeEvent<unknown>, checked: boolean) => {
              const value = checked;
              if (value) {
                setSelectedOptions((prevValue) => ({
                  ...prevValue,
                  list: [...prevValue.list, option],
                }));
              } else {
                setSelectedOptions((prevValue) => {
                  const updatedOptions = prevValue.list.filter(
                    (opt) =>
                      opt.group !== option.group || opt.value !== option.value
                  );
                  return {
                    ...prevValue,
                    list: updatedOptions,
                  };
                });
              }
            }}
          />
        ))}
      </FormGroup>
    </AccordionDetails>
  );
}

export function ModalContentSection({
  tags,
  setTags,
  options,
  openedSection,
  setOpenedSection,
  getGroupLabel,
  loading,
}: ModalContentSectionProps<EventSourceOption>) {
  const { palette } = useTheme();

  if (loading) {
    return (
      <DialogContent sx={{ px: 2, pb: 0, pt: 0, overflow: "auto" }}>
        <Stack
          width="100%"
          height="45vh"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size={80} />
        </Stack>
      </DialogContent>
    );
  }

  return (
    <DialogContent sx={{ px: 2, pb: 0, pt: 0, overflow: "auto" }}>
      {Object.keys(options).map((sectionName) => (
        <Accordion
          key={sectionName}
          disableGutters
          expanded={!!openedSection[sectionName]}
          onChange={() => {
            setOpenedSection((prevState) => ({
              ...prevState,
              [sectionName]: !prevState[sectionName],
            }));
          }}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          {buildAccordionSummary(
            getSectionLabel(sectionName, getGroupLabel),
            palette
          )}
          {buildAccordionDetails({
            options: options[sectionName],
            selectedOptions: tags,
            setSelectedOptions: setTags,
          })}
        </Accordion>
      ))}
    </DialogContent>
  );
}
