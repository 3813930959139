import { useCallback, useState } from "react";
import { Assessment, BookmarkAdd, MpOutlined } from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { isAvailableEventType } from "src/api/useClipSave";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { DropDownButtonProps } from "src/components/DropDownButton/DropDownButton.model";
import { SaveClipDialog } from "src/components/SaveClipDialog/SaveClipDialog";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { EventDetails } from "src/models/EventDetails";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { getEventDetailsMediaDownloadExtension } from "src/api/usePowerSearchResult.getters";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { useSessionContext } from "src/api/useSessionContext";

type ClipViewerExportButtonProps = Omit<DropDownButtonProps, "renderMenu"> & {
  createReport: () => void;
  handleDownload: () => void;
  value: EventDetails;
  itemType?: string;
  hideSaveForLongerOption?: boolean;
  state?: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
};

export const ClipViewerExportButton = ({
  createReport,
  handleDownload,
  state,
  value,
  itemType,
  hideSaveForLongerOption,
  ...props
}: ClipViewerExportButtonProps) => {
  const [openSaveClipDialog, setOpenSaveClipDialog] = useState(false);
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;
  // const downloadPdfMutation = useExportPdf({});
  // const downloadPdfEventItem = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: [value],
  //   });
  // }, [downloadPdfMutation, downloadFilesSnackBar, value]);

  const downloadCsvMutation = useExportCsv({});
  const downloadCsvEventItem = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: [value],
    });
  }, [downloadCsvMutation, downloadFilesSnackBar, value]);

  return (
    <>
      <DropDownButton
        {...props}
        state={state}
        children="Export"
        renderMenu={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                createReport();
              }}
            >
              <ListItemIcon>
                <Assessment color="primary" />
              </ListItemIcon>
              <ListItemText>Create Report</ListItemText>
            </MenuItem>
            <TooltipBase
              width={181}
              textAlign="left"
              title={
                "You can't download media as you have an account limitation. Please contact your account manager to extend your limits."
              }
              placement="right"
              disableHoverListener={enableMediaDownload}
            >
              <Box>
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    handleDownload();
                  }}
                  disabled={!enableMediaDownload}
                >
                  <ListItemIcon>
                    <MpOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Download {getEventDetailsMediaDownloadExtension(value)}
                  </ListItemText>
                </MenuItem>
              </Box>
            </TooltipBase>
            {/* <MenuItem
              onClick={() => {
                downloadPdfEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadPDF />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </MenuItem> */}

            <MenuItem
              onClick={() => {
                downloadCsvEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadCSV />
              </ListItemIcon>
              <ListItemText>Download CSV</ListItemText>
            </MenuItem>

            {!hideSaveForLongerOption &&
              value.eventType &&
              isAvailableEventType(value.eventType) && (
                <TooltipBase
                  width={181}
                  textAlign="left"
                  title={
                    enableMediaDownload
                      ? "Save for 90 days to the Media Center from where you can select clips when create report or share items"
                      : "You can't save media as you have an account limitation. Please contact your account manager to extend your limits."
                  }
                  placement="right"
                >
                  <Box>
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        setOpenSaveClipDialog(true);
                      }}
                      disabled={!enableMediaDownload}
                    >
                      <ListItemIcon>
                        <BookmarkAdd color="primary" />
                      </ListItemIcon>
                      <ListItemText>Save To Media Center</ListItemText>
                    </MenuItem>
                  </Box>
                </TooltipBase>
              )}
          </Menu>
        )}
      />
      <SaveClipDialog
        open={openSaveClipDialog}
        event={value}
        onClose={() => setOpenSaveClipDialog(false)}
      />
    </>
  );
};
