/**
 * Returns offset in minutes for a specific timeZone.
 */
export function getTimeZoneOffsetMinutes(timeZone: string) {
  // Create the formatter with the desired options.
  const format = new Intl.DateTimeFormat("en", {
    timeZone,
    timeZoneName: "longOffset",
  });

  // Get the time zone name component, and slice off any leading abbreviation.
  const formatParts = format.formatToParts();
  const timeZonePart = formatParts.find((p) => p.type === "timeZoneName");
  const offsetString = timeZonePart?.value.slice(3);

  // UTC and its equivalents must be handled directly, because Intl will return 'GMT'.
  if (!offsetString) {
    return 0;
  }

  // Parse the hours and minutes from the result.
  const hours = Number(offsetString.slice(0, 3));
  const minutes = Number(offsetString.slice(4));
  const offsetMinutes = -hours * 60 + (hours < 0 ? 1 : -1) * minutes;

  return offsetMinutes;
}
