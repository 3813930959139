import { Fragment, useState } from "react";
import { MoreVertOutlined } from "@mui/icons-material";
import { ButtonProps, IconButton } from "@mui/material";
import { AccountsManagementConvertDialog } from "src/pages/AccountsManagement/components/AccountsManagementConvertDialog/AccountsManagementConvertDialog";
import { useOpenState } from "src/utils/useOpenState";
import { AccountsManagementItemMenu } from "../AccountsManagementItemMenu/AccountsManagementItemMenu";

type AccountsManagementItemMenuButtonProps = {
  accountName?: string | null;
  expiration?: string;
  seatCount?: number;
  status?: string | null;
  id?: string;
  config?: {
    isTrial?: boolean;
    isActive?: boolean;
    isPaying?: boolean;
  };
  accountManager?: string | null;
  accountManagerEmail?: string | null;
  annualizedRecurringRevenue?: number;
};

export function AccountsManagementItemMenuButton({
  id = "",
  accountName,
  expiration,
  seatCount,
  status,
  config,
  accountManager,
  accountManagerEmail,
  annualizedRecurringRevenue = 0,
}: AccountsManagementItemMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const convertAccountDialog = useOpenState();

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <IconButton
        id={id}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertOutlined color={"primary"} />
      </IconButton>
      <AccountsManagementItemMenu
        id={id}
        anchorEl={anchorEl}
        open={!!anchorEl}
        showConvertAccountDialog={convertAccountDialog.show}
        onClose={handleClose}
      />
      <AccountsManagementConvertDialog
        title={`Convert ${accountName} account`}
        open={convertAccountDialog.isOpen}
        onClose={convertAccountDialog.hide}
        accountId={id}
        accountExpirationDate={expiration}
        seatCount={seatCount}
        status={status}
        isActive={config?.isActive}
        isTrial={config?.isTrial}
        isPaying={config?.isPaying}
        accountManager={accountManager}
        accountManagerEmail={accountManagerEmail}
        annualizedRecurringRevenue={annualizedRecurringRevenue}
      />
    </Fragment>
  );
}
