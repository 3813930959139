import {
  Box,
  TextField,
  inputBaseClasses,
  textFieldClasses,
  useTheme,
} from "@mui/material";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { BannerFormValues } from "./UploadImageEditor.model";

export function UploadImageBannerForm({
  control,
  watch,
}: {
  control: Control<BannerFormValues, any>;
  watch: UseFormWatch<BannerFormValues>;
}) {
  const { palette } = useTheme();

  return (
    <Box
      component="form"
      sx={{
        [`.${textFieldClasses.root}`]: { width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              id="standard-basic"
              variant="standard"
              sx={{
                [`.${inputBaseClasses.root}::before`]: {
                  borderBottom: `1px solid ${palette.getContrastText(
                    watch("bgcolor")
                  )}`,
                },
              }}
              inputProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "150%",
                  letterSpacing: "0.1px",
                  color: palette.getContrastText(watch("bgcolor")),
                },
              }}
            />
          );
        }}
      />
      <Controller
        name="subtitle"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              id="outlined-multiline-flexible"
              variant="standard"
              multiline
              rows={2}
              sx={{
                [`.${inputBaseClasses.root}::before`]: {
                  borderBottom: `1px solid ${palette.getContrastText(
                    watch("bgcolor")
                  )}`,
                },
              }}
              inputProps={{
                maxLength: 70,
                sx: {
                  color: palette.getContrastText(watch("bgcolor")),
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "143%",
                  letterSpacing: "0.17px",
                },
              }}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
            />
          );
        }}
      />
    </Box>
  );
}
