import {
  Box,
  ButtonProps,
  IconButton,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import { QueryBuilderOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "src/api/useSessionContext";

export function ScheduleAlertButton({
  onClick,
  active,
}: {
  onClick?: ButtonProps["onClick"];
  active?: boolean;
}) {
  const { palette } = useTheme();
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();
  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;
  const Icon = QueryBuilderOutlined;
  return (
    <Tooltip
      title={
        enableEditWatchList
          ? active
            ? "Turn off Scheduled  Alert"
            : "Turn on Scheduled Alert"
          : t("editWatchListDisabled")
      }
    >
      <Box>
        <IconButton
          disabled={!enableEditWatchList}
          sx={{
            borderRadius: 2,
            color: palette.text.secondary,
            backgroundColor: active
              ? alpha(palette.primary.light, 0.08)
              : undefined,
          }}
          onClick={onClick}
        >
          <Icon color={active ? "primary" : undefined} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
