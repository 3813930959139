import { AppRouteBuilder } from "src/models/AppRoute";
import { PowerSearchResultAdvancedPage } from "./PowerSearchResultAdvanced.page";

type PowerSearchRoutePathParams = {
  // no path params for now
};

type PowerSearchRouteQueryParams = {
  startDate: string;
  endDate: string;
};

export const powerSearchResultAdvancedRoute = new AppRouteBuilder<
  PowerSearchRoutePathParams,
  PowerSearchRouteQueryParams
>({
  path: "/power-search/advanced/result",
  element: <PowerSearchResultAdvancedPage />,
});
