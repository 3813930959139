import { useEffect, useMemo, useState } from "react";
import { Dialog, dialogClasses } from "@mui/material";
import { EventSourceOption } from "src/api/useEventSources";
import {
  TagPickerProps,
  TagPickerValue,
} from "src/components/TagPicker/TagPicker.model";
import { ModalBottomSection } from "./ModalBottomSection";
import { ModalTitleSection } from "./ModalTitleSection";
import { ModalContentSection, SortedOptionsType } from "./ModalContentSection";
import {
  buildInitialSectionState,
  useTagPickerMobileSectionState,
} from "./hooks/useTagPickerMobileSectionState";

type MobileTagPickerCompactProps<T> = TagPickerProps<T> & {
  menuState: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
};

export function TagPickerMobileModal({
  menuState,
  options,
  value,
  onChange,
  onInputChange,
  getGroupLabel,
  presets,
  loading,
  ...props
}: MobileTagPickerCompactProps<EventSourceOption>) {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<TagPickerValue<EventSourceOption>>(value);
  const [isExpandAllOpen, setIsExpandAllOpen] = useState(true);
  const [openedSection, setOpenedSection] = useTagPickerMobileSectionState([]);

  useEffect(() => {
    onInputChange && onInputChange(inputValue);
  }, [inputValue, onInputChange]);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const aggregatedOptions = useMemo(() => {
    const sortedOptions = options.reduce(
      (res: SortedOptionsType<EventSourceOption>, next) => {
        if (!res[next.group]) {
          res[next.group] = [next];
        } else {
          res[next.group].push(next);
        }
        return res;
      },
      {}
    );

    return sortedOptions;
  }, [options]);

  useEffect(() => {
    const sectionNames = Object.keys(aggregatedOptions);
    const updatedOptionsSection = buildInitialSectionState(sectionNames);
    setOpenedSection(updatedOptionsSection);
  }, [aggregatedOptions, setOpenedSection]);

  useEffect(() => {
    const values = Object.values(openedSection);
    if (values.length === 0) return;

    const isEverySectionOpened = values.every((value) => value === true);
    const isEverySectionClosed = values.every((value) => value === false);
    if (isEverySectionOpened && !isExpandAllOpen) {
      setIsExpandAllOpen(true);
    }
    if (isEverySectionClosed && isExpandAllOpen) {
      setIsExpandAllOpen(false);
    }
  }, [openedSection, isExpandAllOpen]);

  const changePresets = (value: TagPickerValue<EventSourceOption>["list"]) => {
    setTags((prevValue) => ({ ...prevValue, list: value }));
  };

  const resetChange = () => {
    setTags(value);
  };

  return (
    <Dialog
      open={menuState.isOpen}
      onClose={() => {
        setTags(value);
        menuState.hide();
      }}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          m: "32px 24px",
          width: "100%",
          minHeight: "calc(100% - 64px)",
        },
      }}
    >
      <ModalTitleSection
        {...props}
        loading={loading}
        inputValue={inputValue}
        setInputValue={setInputValue}
        isExpandAllOpen={isExpandAllOpen}
        setIsExpandAllOpen={setIsExpandAllOpen}
        setOpenedSection={setOpenedSection}
        setTags={setTags}
        tags={tags}
        value={value}
        onChange={onChange}
        options={options}
        presets={presets}
      />
      <ModalContentSection
        tags={tags}
        setTags={setTags}
        options={aggregatedOptions}
        openedSection={openedSection}
        setOpenedSection={setOpenedSection}
        loading={loading}
        getGroupLabel={getGroupLabel}
      />
      <ModalBottomSection<EventSourceOption>
        tags={tags}
        changePresets={changePresets}
        resetChange={resetChange}
        menuState={menuState}
        onChange={onChange}
        presets={presets}
      />
    </Dialog>
  );
}
