import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { create } from "zustand";
import { AccountsManagementFilterFormValues } from "../components/AccountsManagementFilterForm/AccountsManagementFilterForm.model";
import { AccountsManagementFilterFormSchema } from "../components/AccountsManagementFilterForm/AccountsManagementFilterForm.schema";

export const makeEmptyAccountsManagementFilterFormValues = (
  merge?: Partial<AccountsManagementFilterFormValues>
): AccountsManagementFilterFormValues => ({
  status: merge?.status || [],
  expirationDateMinimum: merge?.expirationDateMinimum || null,
  expirationDateMaximum: merge?.expirationDateMaximum || null,
  orgTypes: merge?.orgTypes || null,
  states: merge?.states || null,
  scoreMinimum: merge?.scoreMinimum || 0,
  scoreMaximum: merge?.scoreMaximum || 100,
  accountManager: merge?.accountManager || null,
  accountActivity: merge?.accountActivity || [0, 100],
});

type UseAccountsManagementFilterFormArgs = {
  initial?: Partial<AccountsManagementFilterFormValues>;
};

export function useAccountsManagementFilterForm(
  params?: UseAccountsManagementFilterFormArgs
) {
  const { initial } = params || {};

  const emptyFormValues = makeEmptyAccountsManagementFilterFormValues();
  const formHook = useForm<AccountsManagementFilterFormValues>({
    mode: "all",
    resolver: joiResolver(AccountsManagementFilterFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}

type UseAccountsManagementFilterState = {
  form: AccountsManagementFilterFormValues;
  reset: () => void;
  update: (changes: Partial<AccountsManagementFilterFormValues>) => void;
};

const emptyWatchQueryFilter = makeEmptyAccountsManagementFilterFormValues();

const stateHook = create<UseAccountsManagementFilterState>((set, get) => {
  return {
    form: emptyWatchQueryFilter,
    reset: () => {
      const prev = get();
      set(
        {
          ...prev,
          form: {
            ...emptyWatchQueryFilter,
          },
        },
        true
      );
    },
    update: (changes) => {
      const { form } = get();
      set({
        form: {
          ...form,
          ...changes,
          scoreMinimum: changes?.accountActivity?.[0],
          scoreMaximum: changes?.accountActivity?.[1],
        },
      });
    },
  };
});

export function useAccountsManagementFilter(): [
  AccountsManagementFilterFormValues,
  (changes: AccountsManagementFilterFormValues) => void,
  () => void
] {
  const state = stateHook();
  const { form, update, reset } = state;

  return [form, update, reset];
}
