import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortZA = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_1_54461)">
          <path
            d="M16 6.26953L11.5 17.7295H13.34L14.26 15.2795H19.37L20.29 17.7295H22.13L17.64 6.26953H16ZM14.87 13.6395L16.81 8.45953L18.75 13.6395H14.87Z"
            fill="currentColor"
          />
          <path
            d="M10.2197 4.66078H14.9397L12.5797 2.30078L10.2197 4.66078Z"
            fill="currentColor"
          />
          <path
            d="M14.9097 19.3708H10.2497L12.5797 21.7008L14.9097 19.3708Z"
            fill="currentColor"
          />
          <path
            d="M11.03 16.1393H4.91L10.84 7.5393V6.2793H2.54V7.8793H8.42L2.5 16.4393V17.7293H11.03V16.1393Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_54461">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
