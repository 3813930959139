import { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Paper, Stack } from "@mui/material";
import { AppTopBarMobile } from "./AppTopBarMobile/AppTopBarMobile";

const isHTMLInputElement = (el: Element): el is HTMLInputElement => {
  const tagName = document.activeElement?.tagName;
  return tagName === "INPUT";
};

const isHTMLTextAreaElement = (el: Element): el is HTMLTextAreaElement => {
  const tagName = document.activeElement?.tagName;
  return tagName === "TEXTAREA";
};

export function PageLayoutMobile({
  content,
  footer,
  hideTopBar,
  hideGlobalSearchBtn = false,
}: {
  content: ReactNode;
  footer?: ReactNode;
  hideTopBar?: boolean;
  hideGlobalSearchBtn?: boolean;
}) {
  const [themeBgColor, setThemeBgColor] = useState<string>();
  const handleContentElChange = (el: HTMLElement | null) => {
    const style = el && getComputedStyle(el);
    setThemeBgColor(style?.backgroundColor);
  };

  // this is workaround for Safari on IOS mobile devices when input is
  // focused the keyboard appears and shifts document in a weird way.
  useEffect(() => {
    const onTouchMove = () => {
      if (!document.activeElement) {
        return;
      }

      if (
        !isHTMLInputElement(document.activeElement) &&
        !isHTMLTextAreaElement(document.activeElement)
      ) {
        return;
      }

      // blur and thus hide keyboard
      document.activeElement.blur();
    };

    document.addEventListener("touchmove", onTouchMove);
    return () => {
      document.removeEventListener("touchmove", onTouchMove);
    };
  }, []);

  return (
    <Stack id="page-layout-mobile-root" flex={1} width="100%" overflow="hidden">
      {!hideTopBar && (
        <AppTopBarMobile hideGlobalSearchBtn={hideGlobalSearchBtn} />
      )}
      <Helmet>
        <meta name="theme-color" content={themeBgColor} />
      </Helmet>
      <Stack
        id="page-layout-mobile-content"
        ref={handleContentElChange}
        flexGrow={1}
        component={Paper}
        children={content}
        overflow="hidden"
        borderRadius={0}
      />
      {footer}
    </Stack>
  );
}
