import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { AccountAutocomplete } from "src/components/AccountAutocomplete/AccountAutocomplete";
import { TimeZonePicker } from "src/components/TimeZonePicker/TimeZonePicker";
import { ROLES, STATUSLIST } from "src/utils/useUserRoleList";
import { UserManagementFilterFormValues } from "./UserManagementFilterForm.model";

export function UserManagementFilterForm() {
  const { control } = useFormContext<UserManagementFilterFormValues>();
  const { accountId = "" } = useParams();

  return (
    <Stack pt={3}>
      {/* Account */}
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={2}>
            Account:
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="accountIds"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <AccountAutocomplete
                    disabled={!!accountId}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    multiple
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Account */}

      {/* Status */}
      <Grid container spacing={3} mb={2} pt={2.75}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={2}>
            Status:
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="status"
                    label="Status"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    fullWidth
                    sx={{ textTransform: "capitalize" }}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                        textTransform: "capitalize",
                      },
                    }}
                  >
                    {STATUSLIST.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Status */}

      {/* Timezone */}
      <Grid container spacing={3} pt={2.75}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={2}>
            Time Zone:
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TimeZonePicker
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Timezone */}

      {/* Role */}
      <Grid container spacing={3} pt={4.75}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={2}>
            Role:
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="roles"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    id="role"
                    label="Role"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    multiple
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                      },
                    }}
                  >
                    {ROLES.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Role */}
    </Stack>
  );
}
