import { WatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { emptyWatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.hook";
import { emptyWatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.hook";
import { PowerSearchQueryValues } from "src/api/usePowerSearchQuery";

export const makePowerSearchQuery = (
  formValues: WatchQueryKeywordFormValues | WatchQueryCustomFormValues
): PowerSearchQueryValues => {
  if ("customQuery" in formValues) {
    return {
      ...formValues,
      customQuery: formValues.customQuery,
      termsInclude: emptyWatchQueryKeywordFormValues.termsInclude,
      termsExclude: emptyWatchQueryKeywordFormValues.termsExclude,
    };
  }
  return {
    ...formValues,
    termsInclude: formValues.termsInclude,
    termsExclude: formValues.termsExclude,
    customQuery: emptyWatchQueryCustomFormValues.customQuery,
  };
};

export const makePowerSearchKeywordQuery = (
  powerSearch: PowerSearchQueryValues
): WatchQueryKeywordFormValues => {
  const { customQuery, ...keywordFormValues } = powerSearch;
  return {
    ...keywordFormValues,
    termsInclude:
      keywordFormValues.termsInclude ||
      emptyWatchQueryKeywordFormValues.termsInclude,
    termsExclude:
      keywordFormValues.termsExclude ||
      emptyWatchQueryKeywordFormValues.termsExclude,
  };
};

export const makePowerSearchCustomQuery = (
  powerSearch: PowerSearchQueryValues
): WatchQueryCustomFormValues => {
  const { termsInclude, termsExclude, ...customFormValues } = powerSearch;
  return {
    ...customFormValues,
    customQuery:
      customFormValues.customQuery ||
      emptyWatchQueryCustomFormValues.customQuery,
  };
};
