import { useCallback, useMemo } from "react";
import { NumberParam, useQueryParams } from "use-query-params";
import { GridPaginationModel } from "@mui/x-data-grid-premium";

const pageKeyDefault = "page";
const pageSizeKeyDefault = "pageSize";
const pageDefault = 0;
const pageSizeDefault = 25;

type SetPagination = (pagination: GridPaginationModel) => void;
type QueryPaginationParams = {
  initial?: GridPaginationModel;
  pageKey?: string;
  pageSizeKey?: string;
};

export function useQueryPagination(
  params: QueryPaginationParams = {}
): [GridPaginationModel, SetPagination] {
  const { pageKey = pageKeyDefault, pageSizeKey = pageSizeKeyDefault } = params;
  const [queryParams, setQueryParams] = useQueryParams(
    {
      [pageKey]: NumberParam,
      [pageSizeKey]: NumberParam,
    },
    {
      removeDefaultsFromUrl: true,
    }
  );

  const page = queryParams[pageKey] ?? pageDefault;
  const pageSize = queryParams[pageSizeKey] ?? pageSizeDefault;

  const model: GridPaginationModel = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const update = useCallback(
    (params: { page?: number; pageSize?: number }) => {
      setQueryParams({
        [pageKey]:
          pageSize !== params.pageSize ? pageDefault : params.page ?? page,
        [pageSizeKey]: params.pageSize ?? pageSize,
      });
    },
    [page, pageSize, setQueryParams, pageKey, pageSizeKey]
  );

  return [model, update];
}
