import { Typography, dialogContentClasses } from "@mui/material";
import { useParams } from "react-router-dom";
import { useUserDetailsUpdate } from "src/api/useUserDetailsUpdate";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type UpdateUserStatusDialogProps = Omit<
  DialogPromptProps,
  "onConfirm" | "onClose"
> & {
  onClose: () => void;
  isUserActive?: boolean;
  userFullName?: string;
  accountName?: string;
};

export function UpdateUserStatusDialog({
  onClose,
  userFullName,
  isUserActive,
  accountName,
  ...props
}: UpdateUserStatusDialogProps) {
  const { userId } = useParams();
  const userGeneralDataUpdate = useUserDetailsUpdate({
    options: {
      origin: "updatePage",
      onSuccess: () => onClose(),
    },
  });

  const onUserStatusUpdate = () => {
    if (!userId) return;
    userGeneralDataUpdate.mutate({
      body: {
        isActive: !isUserActive,
      },
      params: {
        path: {
          id: userId,
        },
      },
    });
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      sx={{
        [`.${dialogContentClasses.root}`]: {
          paddingTop: 1,
        },
      }}
      {...props}
      children={
        <Typography variant="body1" pb={1}>
          Do you want to {isUserActive ? `deactivate` : `activate`} user "
          {userFullName}" from the account "{accountName}"?
        </Typography>
      }
      onDecline={onClose}
      onConfirm={onUserStatusUpdate}
    />
  );
}
