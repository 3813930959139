import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useForm, useFormContext } from "react-hook-form";

type WatchQueryCloneFormValues = {
  title?: string;
  includeAlerts?: boolean;
};

const watchQueryCloneFormSchema = Joi.object<WatchQueryCloneFormValues>({
  title: Joi.string().trim().min(1).required(),
  includeAlerts: Joi.bool().required(),
}).required();

export const useWatchQueryCloneForm = () => {
  const formHook = useForm<WatchQueryCloneFormValues>({
    mode: "all",
    defaultValues: {
      title: "",
      includeAlerts: false,
    },
    resolver: joiResolver(watchQueryCloneFormSchema),
  });

  return formHook;
};

export const useWatchQueryCloneFormContext = () => {
  return useFormContext<WatchQueryCloneFormValues>();
};
