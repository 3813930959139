import { FormState } from "react-hook-form";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import {
  getFieldLabel,
  getSectionName,
} from "./AccountsManagementLeaveConfirmationDialog.utils";
import { EditAccountDetailsFormValues } from "../components/AccountsManagementEditGeneralTab/AccountsManagementEditDetails.model";
import { EditAccountContentFilterFormValues } from "../components/AccountsManagementEditContentTab/AccountsManagementContentTab.model";
import { EditAccountFeaturesFormValues } from "../components/AccountsManagementEditFeaturesTab/AccountManagementEditFeatures.model";

type AccountsManagementLeaveConfirmationDialogProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  onSubmit: () => void;
  onDataReset: () => void;
  accountDetailsDirtyFields: FormState<EditAccountDetailsFormValues>["dirtyFields"];
  accountContentFiltersDirtyFields: FormState<EditAccountContentFilterFormValues>["dirtyFields"];
  accountFeaturesDirtyFields: FormState<EditAccountFeaturesFormValues>["dirtyFields"];
  errors: FormState<EditAccountDetailsFormValues>["errors"];
};

export function AccountsManagementLeaveConfirmationDialog({
  accountDetailsDirtyFields,
  accountContentFiltersDirtyFields,
  accountFeaturesDirtyFields,
  onClose,
  onSubmit,
  onDataReset,
  errors,
  ...props
}: AccountsManagementLeaveConfirmationDialogProps) {
  const { palette } = useTheme();
  const accountDetailsItems = makeTypedPropList(accountDetailsDirtyFields).map(
    (item) => (
      <Stack key={item}>
        <Stack direction="row" gap={1} height={42} alignItems="center" px={1}>
          <InfoOutlined fontSize="small" />
          <Typography variant="subtitle2" color="primary">
            {`${getSectionName(item)}: ${getFieldLabel(item)}`}
          </Typography>
        </Stack>
        <Divider />
      </Stack>
    )
  );
  const accountContentFilterItems = makeTypedPropList(
    accountContentFiltersDirtyFields
  ).map((item) => (
    <Stack key={item}>
      <Stack direction="row" gap={1} height={42} alignItems="center" px={1}>
        <InfoOutlined fontSize="small" />
        <Typography variant="subtitle2" color="primary">
          {`${getSectionName(item)}: ${getFieldLabel(item)}`}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  ));
  const accountFeaturesItems = makeTypedPropList(
    accountFeaturesDirtyFields
  ).map((item) => (
    <Stack key={item}>
      <Stack direction="row" gap={1} height={42} alignItems="center" px={1}>
        <InfoOutlined fontSize="small" />
        <Typography variant="subtitle2" color="primary">
          {`${getSectionName(item)}: ${getFieldLabel(item)}`}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  ));

  const content = (
    <Stack gap={2}>
      <Typography>
        You have unsaved changes. Would you like to save them before leaving?
      </Typography>
      <Box
        bgcolor={palette.background.default}
        height={210}
        py={1}
        overflow={"auto"}
      >
        {accountDetailsItems}
        {accountContentFilterItems}
        {accountFeaturesItems}
      </Box>
      {errors.name?.message && (
        <Typography color={"error"} variant="body2">
          {errors.name?.message}
        </Typography>
      )}
    </Stack>
  );

  return (
    <DialogBase
      maxWidth="sm"
      extraMobileMargin={3}
      disableHeaderDivider
      children={content}
      onClose={onClose}
      {...props}
      footer={
        <DialogActions sx={{ p: 0 }}>
          <Stack direction="row" justifyContent="end" width="100%" gap={1}>
            <Button
              variant="outlined"
              sx={{
                width: 90,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                width: 90,
              }}
              onClick={onDataReset}
            >
              Discard
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: 90,
              }}
              onClick={onSubmit}
            >
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      }
    />
  );
}
