import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipViewerPage } from "src/components/ClipViewerPage/ClipViewerPage";
import { useDateTimeSnapshot } from "src/api/useDateTimeSnapshot";
import { useDateTimeSearchDetails } from "src/api/useDateTimeSearchDetails";
import { useDateTimeSearchResultCache } from "src/api/useDateTimeSearchResultCache";
import { useEventByStationAndDate } from "src/api/useEventByStationAndDate";
import { SnapshotsClipViewerParams } from "./SnapshotsClipViewer.route";
import { SnapshotsClipEditorRoute } from "../SnapshotsClipEditor/SnapshotsClipEditor.route";
import { ShiftPlayerDataProps } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";

//Range to target least amount of results to match snapshot datetime
const cursorRangeBounds: [number, number] = [-5, 70];

export function SnapshotsClipViewer() {
  const cache = useDateTimeSearchResultCache();
  const navigate = useNavigate();
  const { stationId = "", date = "" } = useParams<SnapshotsClipViewerParams>();
  const details = useDateTimeSearchDetails({});
  const clipEditorUrl = SnapshotsClipEditorRoute.makeUrl({});

  const [dateTime, setDateTime] = useState<undefined | ShiftPlayerDataProps>(
    undefined
  );

  const { isLoading } = useDateTimeSnapshot({
    params: {
      cursorRangeBounds,
      fetchParams: {
        station: stationId,
        dateTime: new Date(date),
      },
    },
    options: {
      onSuccess: (response) => {
        if (response.length) {
          //Closest result to match snapshot datetime
          details.mutate({
            body: response[0],
          });
        } else {
          cache.clear();
        }
      },
    },
  });

  const dateTimeEventDetails = useEventByStationAndDate({
    request: {
      query: {
        stationUUID: details.data?.sourceId ?? "",
        startDateTime: dateTime?.startDateTime ?? "",
        endDateTime: dateTime?.endDateTime ?? "",
      },
    },
    body: {},
    options: {
      enabled: Boolean(dateTime),
    },
  });

  const event = useMemo(() => {
    if (dateTime && dateTimeEventDetails.data) {
      return (
        {
          ...details.data,
          startDateTime: dateTime.startDateTime,
          endDateTime: dateTime.endDateTime,
          transcript: dateTimeEventDetails.data.transcript,
          transcriptLines: dateTimeEventDetails.data.transcriptLines,
          transcriptLineBundles:
            dateTimeEventDetails.data.transcriptLineBundles,
        } || {}
      );
    }

    return details.data || {};
  }, [details.data, dateTimeEventDetails.data, dateTime]);

  const onShiftPlayerPlay = (data: ShiftPlayerDataProps) => setDateTime(data);

  const goToEditor = () => {
    navigate(clipEditorUrl);
  };

  return (
    <ClipViewerPage
      initialStartDateTime={details.data?.startDateTime}
      event={event}
      loading={isLoading || details.isLoading}
      shiftOffset={dateTime?.shiftOffset}
      onShiftPlayerPlay={onShiftPlayerPlay}
      routeRoot="Snapshots"
      onEdit={goToEditor}
    />
  );
}
