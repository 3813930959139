import TuneIcon from "@mui/icons-material/Tune";
import { Badge, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { countUsersManagementFilters } from "src/utils/countUserManagementFilters";
import { UserManagementFilterButtonProps } from "./UserManagementFilterButton.model";

export function UserManagementFilterButton({
  value,
  isMobile,
  onClick,
  ...props
}: UserManagementFilterButtonProps) {
  const title = props.title || "Apply filters";
  const badgeContent = countUsersManagementFilters(value);

  return (
    <Tooltip title={title}>
      <Badge color="primary" badgeContent={badgeContent}>
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton value={true} onClick={onClick}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Tooltip>
  );
}
