import { editor, MarkerSeverity } from "monaco-editor";

export const validateLuceneQuery = (
  monaco: {
    MarkerSeverity: { Error: MarkerSeverity; Warning: MarkerSeverity };
    editor: {
      setModelMarkers: (
        arg0: editor.ITextModel,
        arg1: string,
        arg2: {
          severity: any;
          startLineNumber: any;
          startColumn: any;
          endLineNumber: any;
          endColumn: any;
          message: any;
        }[]
      ) => void;
    };
  },
  model: editor.ITextModel
) => {
  if (!monaco) return;
  const text = model.getValue(); // Get the entire text from the model
  const markers: {
    severity: any;
    startLineNumber: any;
    startColumn: any;
    endLineNumber: any;
    endColumn: any;
    message: any;
  }[] = [];
  const keywords = ["AND", "OR", "NOT"];
  const lowercaseKeywords = ["and", "or", "not"];
  const proximityPattern = /\s~\d+/g; // Pattern to detect spaces before proximity operator

  const stack = [];
  const quoteStack = []; // Track positions of unmatched quotes

  function addMarker(
    startLine: number,
    startColumn: number,
    endLine: number,
    endColumn: number,
    message: string,
    severity: MarkerSeverity
  ) {
    markers.push({
      severity: severity,
      startLineNumber: startLine,
      startColumn: startColumn,
      endLineNumber: endLine,
      endColumn: endColumn,
      message: message,
    });
  }

  const lines = text.split("\n");

  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const lineText = lines[lineNumber];
    const words = lineText.match(/\S+/g) || []; // Split line into words ignoring whitespace

    // Check for invalid whitespace before proximity operator
    let proximityMatch;
    while ((proximityMatch = proximityPattern.exec(lineText)) !== null) {
      const startColumn = proximityMatch.index + 1;
      const endColumn = startColumn + proximityMatch[0].length;

      addMarker(
        lineNumber + 1,
        startColumn,
        lineNumber + 1,
        endColumn,
        "Whitespace before a proximity operator is invalid.",
        monaco.MarkerSeverity.Error
      );
    }

    let currentOffset = 0;
    let openQuote = null;

    for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
      let word = words[wordIndex];

      // Calculate the start column of the current word
      const startColumn = lineText.indexOf(word, currentOffset) + 1;

      for (let column = 0; column < word.length; column++) {
        const char = word[column];

        if (char === '"') {
          if (openQuote) {
            // If there's an open quote and we encounter another quote, it closes the open quote
            quoteStack.pop();
            openQuote = null;
          } else {
            // If no open quote, this opens a new quote
            openQuote = {
              lineNumber: lineNumber + 1,
              column: startColumn + column,
            };
            quoteStack.push(openQuote);
          }
        } else if (char === "(" && !openQuote) {
          // Push opening parenthesis to the stack only if no open quote
          stack.push({
            lineNumber: lineNumber + 1,
            column: startColumn + column,
          });
        } else if (char === ")" && !openQuote) {
          if (stack.length === 0) {
            // Condition for unmatched closing parenthesis
            addMarker(
              lineNumber + 1,
              startColumn + column,
              lineNumber + 1,
              startColumn + column + 1,
              "Unmatched closing parenthesis.",
              monaco.MarkerSeverity.Error
            );
          } else {
            // Pop the last matched opening parenthesis
            stack.pop();
          }
        } else if (keywords.includes(word.toUpperCase()) && openQuote) {
          // Condition 4: Keyword encountered without closing the quote
          addMarker(
            openQuote.lineNumber,
            openQuote.column,
            openQuote.lineNumber,
            openQuote.column + 1,
            "Unclosed double quote.",
            monaco.MarkerSeverity.Error
          );
          openQuote = null;
          quoteStack.pop();
        }
      }

      // Handle composite keyword "AND NOT"
      if (
        word.toUpperCase() === "AND" &&
        words[wordIndex + 1]?.toUpperCase() === "NOT"
      ) {
        word = "AND NOT";
        wordIndex++; // Skip the next word ("NOT")
      }

      // Detect lowercase operators outside quotes
      if (!openQuote && lowercaseKeywords.includes(word)) {
        addMarker(
          lineNumber + 1,
          startColumn,
          lineNumber + 1,
          startColumn + word.length,
          `Operator '${word}' should be uppercase.`,
          monaco.MarkerSeverity.Warning
        );
      }

      // Detect adjacent operators (except "AND NOT")
      if (!openQuote && keywords.includes(word.toUpperCase())) {
        if (
          wordIndex > 0 &&
          keywords.includes(words[wordIndex - 1].toUpperCase())
        ) {
          addMarker(
            lineNumber + 1,
            startColumn,
            lineNumber + 1,
            startColumn + word.length,
            `Keyword '${word}' should not be adjacent to another keyword.`,
            monaco.MarkerSeverity.Warning
          );
        }
      }

      currentOffset = startColumn + word.length - 1; // Update the offset to the end of the current word
    }

    if (openQuote) {
      // Condition 2: Newline encountered before closing the quote
      addMarker(
        openQuote.lineNumber,
        openQuote.column,
        openQuote.lineNumber,
        openQuote.column + 1,
        "Unclosed double quote.",
        monaco.MarkerSeverity.Error
      );
      openQuote = null;
      quoteStack.pop();
    }
  }

  // Final check for unmatched opening parentheses left in the stack
  stack.forEach((unmatched) => {
    addMarker(
      unmatched.lineNumber,
      unmatched.column,
      unmatched.lineNumber,
      unmatched.column + 1,
      "Unmatched opening parenthesis.",
      monaco.MarkerSeverity.Error
    );
  });

  monaco.editor.setModelMarkers(model, "owner", markers);
};
