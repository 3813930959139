import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Link, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useEventsPreselected } from "src/api/useEventsPreselected";

export const ReportsNavItemPopperContent = () => {
  const { palette } = useTheme();

  const { hideTooltip } = useEventsPreselected();

  const handleClose = () => {
    hideTooltip();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="10px"
      sx={{
        backgroundColor: palette.background.paper,
        p: 2,
        borderRadius: "8px",
      }}
    >
      <ErrorOutline color="primary" />
      <Typography fontSize={14}>
        You’ve added new preselected events for the Report,&nbsp;
        <Link
          underline="always"
          component={RouterLink}
          to={"/reports"}
          onClick={handleClose}
          color="primary"
          sx={{
            fontWeight: 600,
          }}
        >
          go there
        </Link>
        &nbsp; and check it.
      </Typography>
    </Stack>
  );
};
