import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Info, Redo, Undo } from "@mui/icons-material";
import { colors, IconButton, Stack, Typography } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { AppPopper } from "../AppPopper/AppPopper";
import { QueryExamplesModalContent } from "../QueryExamplesModalContent/QueryExamplesModalContent";
import { AppPopperArrowSize } from "../AppPopper/components/AppPopperArrow/AppPopperArrow.model";

const MAXACTIONNUMBER = 20;

export function CodeEditorControls({
  value,
  onChange,
  hideTitle = false,
}: {
  value?: string;
  onChange: (value?: string) => void;
  hideTitle?: boolean;
}) {
  const popperState = useOpenState();
  const containerRef = useRef<HTMLDivElement>(null);

  const [actionList, setActionList] = useState<Array<string>>([""]);
  const [currentActionListIndex, setCurrentActionListIndex] = useState(0);

  const [debouncedValue] = useDebounce(value, 1000);

  useEffect(() => {
    if (!debouncedValue) return;
    if (debouncedValue === actionList[currentActionListIndex]) return;
    if (debouncedValue === actionList[currentActionListIndex - 1]) return;
    if (currentActionListIndex < actionList.length - 1) return;

    setCurrentActionListIndex((prevValue) => {
      if (prevValue === MAXACTIONNUMBER - 1) return prevValue;
      return prevValue + 1;
    });

    if (actionList.length !== MAXACTIONNUMBER) {
      return setActionList((prevValue) => {
        const updatedArray = [...prevValue, debouncedValue];
        return updatedArray;
      });
    }

    setActionList((prevValue) => [...prevValue.slice(1), debouncedValue]);
  }, [debouncedValue, actionList, currentActionListIndex]);

  const handleUndoChange = () => {
    setCurrentActionListIndex((prevValue) => {
      if (prevValue === 0) return prevValue;
      onChange(actionList[prevValue - 1]);
      return prevValue - 1;
    });
  };
  const handleRedoChange = () => {
    setCurrentActionListIndex((prevValue) => {
      if (prevValue === MAXACTIONNUMBER - 1) return prevValue;
      onChange(actionList[prevValue + 1]);
      return prevValue + 1;
    });
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {!hideTitle && (
        <Typography
          variant="subtitle1"
          display="flex"
          alignItems="center"
          ref={containerRef}
        >
          Custom Query
          <Info
            sx={{ ml: 1, color: colors.blueGrey[200], cursor: "pointer" }}
            onClick={popperState.show}
          />
        </Typography>
      )}
      <Stack
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        gap={2.5}
      >
        <IconButton
          disabled={currentActionListIndex === 0}
          onClick={handleUndoChange}
          edge="end"
          sx={{ height: 30 }}
        >
          <Undo />
        </IconButton>
        <IconButton
          disabled={currentActionListIndex === actionList.length - 1}
          onClick={handleRedoChange}
          edge="end"
          sx={{ height: 30 }}
        >
          <Redo />
        </IconButton>
      </Stack>
      <AppPopper
        onClose={popperState.hide}
        open={popperState.isOpen}
        anchorEl={containerRef.current}
        placement="right"
        arrow={AppPopperArrowSize.medium}
        children={<QueryExamplesModalContent onClose={popperState.hide} />}
      />
    </Stack>
  );
}
