import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, Stack } from "@mui/material";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";

type DemoModeConfirmationModalProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  onSubmit: () => void;
  submitLabel: string;
  content: JSX.Element;
  cancelLabel?: string;
  isLoading?: boolean;
};
export function DemoModeConfirmationModal({
  open,
  onClose,
  onSubmit,
  submitLabel,
  cancelLabel = "Cancel",
  content,
  title,
  isLoading,
  ...props
}: DemoModeConfirmationModalProps) {
  return (
    <DialogBase
      open={open}
      maxWidth="sm"
      extraMobileMargin={3}
      disableHeaderDivider
      children={content}
      title={title}
      onClose={onClose}
      {...props}
      footer={
        <DialogActions sx={{ p: 0 }}>
          <Stack direction="row" justifyContent="end" width="100%" gap={1}>
            <Button
              variant="outlined"
              sx={{
                width: 90,
              }}
              onClick={onClose}
            >
              {cancelLabel}
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              sx={{
                width: 90,
              }}
              onClick={onSubmit}
            >
              {submitLabel}
            </LoadingButton>
          </Stack>
        </DialogActions>
      }
    />
  );
}
