import { formatISO } from "date-fns";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePowerSearchQuery } from "src/api/usePowerSearchQuery";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { DialogBase } from "../DialogBase/DialogBase";
import { WatchQueryDialogActions } from "../WatchQueryDialogActions/WatchQueryDialogActions";
import { GlobalSearchFormSimple } from "../GlobalSearchFormSimple/GlobalSearchFormSimple";
import { GlobalSearchForm } from "../GlobalSearchForm/GlobalSearchForm";
import { WatchQueryFormValues } from "../WatchQueryForm/WatchQueryForm.model";
import { usePowerSearchKeywordForm } from "../PowerSearchKeywordForm/PowerSearchKeywordForm.hook";
import { MoreOptionsButton } from "../buttons/MoreOptionsButton";
import { GlobalSearchDialogProps } from "./GlobalSearchDialog.model";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { TextInputBase } from "../TextInputBase";
import { GlobalSearchFormSchema } from "../GlobalSearchForm/GlobalSearchForm.schema";
import { GlobalSearchFormValues } from "../GlobalSearchForm/GlobalSearchForm.model";

export function GlobalSearchDialog({
  onClose,
  initial,
  ...props
}: GlobalSearchDialogProps) {
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const powerSearchQuery = usePowerSearchQuery();
  const [isTitlePromptOpen, setTitlePromptOpen] = useState(false);
  const [prevDisplayName, setPrevDisplayName] = useState("");
  const [globalSearchStage, setGlobalSearch] = useState<"initial" | "extended">(
    "initial"
  );
  const isIOS = window?.navigator?.platform === "iPhone";

  const handleChangeGlobalSearchStage = () =>
    setGlobalSearch((prevValue) =>
      prevValue === "initial" ? "extended" : "initial"
    );

  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSettled: () => {
        onClose && onClose();
        setTitlePromptOpen(false);
      },
    },
  });

  const formHook = usePowerSearchKeywordForm({
    initial,
    schema: GlobalSearchFormSchema,
  });

  const { formState, handleSubmit, reset, watch, resetField, control } =
    formHook;
  const { displayName } = watch();

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  const isBusy = formState.isSubmitting || watchQueryCreate.isLoading;

  const handleWatchTermCreateConfirm = handleSubmit(
    (data: WatchQueryFormValues) => {
      const query = makeQueryDefinition(data);
      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    }
  );

  const submitWatchQueryCreate = () => {
    if (globalSearchStage === "initial") {
      setTitlePromptOpen(true);
      setPrevDisplayName(displayName);
    } else {
      handleWatchTermCreateConfirm();
    }
  };

  const submitGlobalSearch = handleSubmit(
    (data: GlobalSearchFormValues) => {
      const { startDateTime, endDateTime, ...form } = data;

      powerSearchQuery.save(form);
      const url = powerSearchResultRoute.makeUrl(
        {},
        {
          startDate: startDateTime ? formatISO(startDateTime) : "",
          endDate: endDateTime ? formatISO(endDateTime) : "",
        }
      );
      navigate(url);

      onClose?.();
    },
    (errors) => {
      console.log("@@ DEBUG:submitGlobalSearch:error", errors);
    }
  );

  const hideWatchQueryTitleDialog = () => {
    setTitlePromptOpen(false);
  };

  const handleWatchTermCreateDecline = () => {
    hideWatchQueryTitleDialog();
    resetField("displayName", { defaultValue: prevDisplayName });
  };

  const onFormClear = () => reset(initial);

  const leftSide = isMobile ? undefined : (
    <MoreOptionsButton
      id="watch-query-more-options-button"
      onClick={handleChangeGlobalSearchStage}
      isOpen={globalSearchStage === "extended"}
    />
  );

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        closeOnLeftSide={isMobile}
        mobileFullHeight={isMobile}
        title="Search watch term"
        {...props}
        children={
          <Stack pt={3} pr={isIOS ? 1 : 0}>
            {globalSearchStage === "initial" && !isMobile ? (
              <GlobalSearchFormSimple
                hideButtons
                setTitlePromptOpen={setTitlePromptOpen}
                setPrevDisplayName={setPrevDisplayName}
              />
            ) : (
              <GlobalSearchForm loading={watchQueryCreate.isLoading} />
            )}
          </Stack>
        }
        footer={
          <WatchQueryDialogActions
            leftSide={leftSide}
            isValid={formState.isValid}
            isBusy={isBusy}
            onClose={onClose}
            onFormClear={onFormClear}
            submitWatchQueryCreate={submitWatchQueryCreate}
            submitPowerSearch={submitGlobalSearch}
          />
        }
      />
      <DialogPrompt
        title="Save to Watchlist"
        open={isTitlePromptOpen}
        onDecline={handleWatchTermCreateDecline}
        onConfirm={handleWatchTermCreateConfirm}
        confirmLabel="Ok"
        confirmDisabled={isBusy || !formState.isValid}
        children={
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Box pt={1}>
                  <TextInputBase
                    label="Display name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                </Box>
              );
            }}
          />
        }
      />
    </FormProvider>
  );
}
