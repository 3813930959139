import { AppRouteBuilder } from "src/models/AppRoute";
import { DateTimeSearchClipEditorPage } from "./DateTimeSearchClipEditor.page";

export type DateTimeSearchClipEditorPathParam = {
  // no params for now
};

export const dateTimeSearchClipEditorRoute =
  new AppRouteBuilder<DateTimeSearchClipEditorPathParam>({
    path: "/date-time-search/results/edit",
    element: <DateTimeSearchClipEditorPage />,
  });
