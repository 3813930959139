import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { UserManagementPage } from "./UserManagement.page";

export const userManagementRoute = new AppRouteBuilder({
  path: "/user-management",
  element: (
    <PermissionGuard
      component={UserManagementPage}
      allowedRoles={["Admin", "Account Manager", "User Manager"]}
      disableMobile
      redirectTo="/"
      // silent
    />
  ),
});
