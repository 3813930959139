import {
  Box,
  BoxProps,
  ButtonProps,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { useSessionContext } from "src/api/useSessionContext";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryAlertDelete } from "src/api/useWatchQueryAlertDelete";
import { InstantAlertButton } from "src/components/AlertButtons/InstantAlertButton";
import { ScheduleAlertButton } from "src/components/AlertButtons/ScheduleAlertButton";
import { ScheduledAlertMobile } from "src/components/ScheduledAlertMobile/ScheduledAlertMobile";
import { ScheduledAlertPopover } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover";
import { alertTypePresets } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { WatchQueryInstantAlertDialog } from "src/components/WatchQueryInstantAlertDialog/WatchQueryInstantAlertDialog";
import { WatchQueryThumbnail } from "src/components/WatchQueryThumbnail/WatchQueryThumbnail";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { getSkippedInstantAlert } from "src/utils/instantAlertHelpers";
import { useQueryDateRange } from "src/utils/useQueryDateRange";
import { WatchQueryItemMenuButton } from "../WatchQueryItemMenu/WatchQueryItemMenuButton";
import { WatchQueryTitle } from "../WatchQueryTable/components/WatchQueryTitle";
import { WatchQueryComplexityIconBar } from "../WatchQueryTable/components/WatchQueryComplexityIconBar";
import { isWatchQueryComplex } from "src/utils/isWatchQueryComplex";

export function WatchQueryTile({
  query,
  alerts,
  width = "100%",
  height = "100%",
  loading,
}: {
  query: WatchListUserQueryDTO;
  alerts: UserQueryDTO["alerts"];
  loading?: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
}) {
  const { palette, spacing, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const totalHits = (query?.hits || []).reduce((acc, item) => {
    if (!item.hits) return acc;
    return (acc += item.hits);
  }, 0);

  const { userId } = useSessionContext();
  const [dateRange] = useQueryDateRange();

  const [showInstantAlert, setShowInstantAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const instantAlert = alerts.filter((alert) => alert.type === "realtime");
  const scheduledAlerts = alerts.filter((alert) => alert.type !== "realtime");

  const isAlertsEnabled = scheduledAlerts.every((alert) => alert.enabled);

  const alertDelete = useWatchQueryAlertDelete({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });
  const instantAlertDelete = () => {
    if (!instantAlert.length) return;

    const alertId = instantAlert[0].id;
    if (!alertId) return;

    alertDelete.mutate({
      params: {
        path: {
          queryId: query.id,
          alertId,
        },
      },
    });
  };

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });

  const instantAlertCreate = () => {
    if (!query.id) return;

    alertCreate.mutate({
      params: {
        path: {
          queryId: query.id,
        },
        query: {
          type: "realtime",
          archive: false,
        },
      },
    });
  };

  const skippedInstantAlert = getSkippedInstantAlert();

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const watchQueryHeader = (
    <Stack
      direction="row"
      alignItems="flex-start"
      padding={spacing(2)}
      paddingRight={spacing(0.5)}
    >
      <Stack flex={1} alignItems="flex-start">
        {totalHits > 0 ? (
          <WatchQueryTitle
            query={query}
            dateRange={dateRange}
            placement={isMobile ? undefined : "right"}
          />
        ) : (
          <Typography
            color="primary"
            variant="subtitle1"
            children={query.title}
          />
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" color={palette.text.secondary}>
            Events:
          </Typography>
          {loading ? (
            <CircularProgress size={14} color="primary" />
          ) : (
            <Typography fontSize={14} variant="subtitle1">
              {totalHits}
            </Typography>
          )}
        </Stack>
      </Stack>
      <WatchQueryItemMenuButton query={query} />
    </Stack>
  );

  const watchQueryThumbnail = (
    <WatchQueryThumbnail
      queryId={query.id}
      userId={userId}
      width="100%"
      aspectRatio="16/9"
      alt={`Video thumbnail of ${query.title}`}
      type="tile"
    />
  );

  const alertActionBar = (
    <Box display="flex">
      <InstantAlertButton
        onClick={() => {
          if (skippedInstantAlert && !instantAlert.length) {
            return instantAlertCreate();
          }
          if (instantAlert.length !== 0) {
            instantAlertDelete();
          } else {
            setShowInstantAlert(true);
          }
        }}
        active={instantAlert.length !== 0}
      />
      {!skippedInstantAlert && (
        <WatchQueryInstantAlertDialog
          open={showInstantAlert}
          queryId={query.id}
          origin="watchlist"
          onClose={() => setShowInstantAlert(false)}
        />
      )}
      <ScheduleAlertButton
        active={scheduledAlerts.length !== 0 && isAlertsEnabled}
        onClick={handleClick}
      />
      <ScheduledAlertPopover
        queryId={query.id}
        presets={alertTypePresets}
        open={open && !isMobile}
        anchorEl={anchorEl}
        alerts={alerts}
        origin="watchlist"
        onClose={handleClose}
      />
      <ScheduledAlertMobile
        open={open && isMobile}
        queryId={query.id}
        title={query.title}
        origin="watchlist"
        alerts={alerts}
        onClose={handleClose}
      />
    </Box>
  );

  const clusterTitle = query.clusterTitle ? (
    <Stack direction="row" columnGap={1} alignItems="center" flexWrap="wrap">
      <Typography
        variant="body2"
        color={palette.text.secondary}
        children="Cluster:"
      />
      <Typography variant="subtitle2" children={query.clusterTitle} />
    </Stack>
  ) : null;

  const isQueryComplex = isWatchQueryComplex({
    queryBuilderDefinition: query.queryBuilderDefinition,
  });

  const complexityIcons = isMobile && isQueryComplex && (
    <WatchQueryComplexityIconBar
      queryDefinition={query.queryBuilderDefinition}
    />
  );

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
    >
      {watchQueryHeader}
      {watchQueryThumbnail}
      <Stack
        direction="row"
        justifyContent={clusterTitle ? "space-between" : "flex-end"}
        alignItems="center"
        padding={spacing(2)}
        columnGap={1}
      >
        {clusterTitle}
        {complexityIcons}
        {alertActionBar}
      </Stack>
    </Stack>
  );
}
