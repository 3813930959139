import { Add } from "@mui/icons-material";
import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { makeWatchQueryKeywordFormValues } from "src/utils/makeWatchQueryFormValues";
import { DialogBase } from "../DialogBase/DialogBase";
import { WatchQueryKeywordForm } from "../WatchQueryKeywordForm/WatchQueryKeywordForm";
import { useWatchQueryKeywordForm } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.hook";
import { WatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { WatchQueryKeywordFormSchema } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.schema";
import { LoadingButton } from "../buttons/LoadingButton";
import { WatchQueryUpdateDialogProps } from "./WatchQueryUpdateDialog.model";

export function WatchQueryUpdateDialog({
  onClose,
  initial,
  ...props
}: WatchQueryUpdateDialogProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const defaultFormValues = useMemo(
    () => makeWatchQueryKeywordFormValues(initial),
    [initial]
  );

  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSettled: onClose,
    },
  });

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      onSuccess: onClose,
    },
  });

  const formHook = useWatchQueryKeywordForm({
    initial: defaultFormValues,
    schema: WatchQueryKeywordFormSchema,
  });

  const { formState, handleSubmit, reset } = formHook;

  const { isSubmitting, isDirty, isValid } = formState;

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultFormValues);
    }
  }, [reset, props.open, defaultFormValues]);

  const isBusy = isSubmitting || watchQueryCreate.isLoading;

  const submitWatchQueryCreate = handleSubmit(
    (data: WatchQueryKeywordFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryCreate:error", errors);
    }
  );

  const submitWatchQueryUpdate = handleSubmit(
    (data: WatchQueryKeywordFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryUpdate.mutate({
        id: initial.id,
        title: data.displayName,
        userId: initial.userId,
        sort: initial.sort,
        alerts: [],
        queryBuilderDefinition: query,
      });

      onClose && onClose();
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryUpdate:error", errors);
    }
  );

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
        children={<WatchQueryKeywordForm layout="update" />}
        footer={
          <Stack
            direction="row"
            justifyContent={isMobile ? "justify-between" : undefined}
            width={isMobile ? "100%" : undefined}
            spacing={2}
            minHeight={42}
          >
            <Hidden lgDown>
              <Button
                sx={{ width: 100, display: { xs: "none", md: "flex" } }}
                onClick={onClose}
                color="error"
              >
                Cancel
              </Button>
            </Hidden>

            <LoadingButton
              variant="contained"
              color="info"
              id="update-watch-query"
              loading={isBusy}
              disabled={isBusy || !isDirty || !isValid}
              startIcon={<Add />}
              onClick={submitWatchQueryCreate}
              sx={{
                minWidth: isMobile ? 160 : 176,
                width: isMobile ? "100%" : undefined,
                flex: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              Watch term
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="primary"
              loading={isBusy}
              disabled={isBusy || !isDirty || !isValid}
              onClick={submitWatchQueryUpdate}
              sx={{
                minWidth: isMobile ? 160 : 176,
                width: isMobile ? "100%" : undefined,
              }}
            >
              Save
            </LoadingButton>
          </Stack>
        }
      />
    </FormProvider>
  );
}
