import axios from "axios";
import { components, paths } from "@tveyes/twosionwebapischema";
import { useQuery } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useLocalStorageFlags } from "src/utils/useLocalStorageFlags";

const apiKey: keyof paths = `/api/Event/{eventSource}/{eventId}`;
const method: keyof paths[typeof apiKey] = "get";

type Endpoint = paths[typeof apiKey][typeof method];

type EventDetailParams = {
  path: Omit<Endpoint["parameters"]["path"], "eventSource"> & {
    source: "query" | "report" | "archive";
    sourceId: string;
    eventId: string;
  };
};

export type SingleEventResultItem = Required<
  components["schemas"]["EventHighlightsDTO"]
>;

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type Response = SingleEventResultItem;

export function useEventDetails({ params }: { params: EventDetailParams }) {
  const flags = useLocalStorageFlags();

  return useQuery({
    queryKey: [apiKey, params],
    queryFn: async () => {
      const eventSource = `${params.path.source}-${params.path.sourceId}`;

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        query: {
          extend: flags.isExtendedEvent,
        },
        path: {
          eventSource,
          eventId: params.path.eventId,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
      });

      return data;
    },
  });
}
