import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/banner/account/{accountId}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];
export type BannerDataOriginal = ResponseBody;

//FIXME Wrong types from BE
export type BannerData = Omit<BannerDataOriginal, "bannerOwner"> & {
  bannerOwner?: "User" | "Account" | "Default";
};

type RequestOptions = {
  params: {
    path: {
      accountId: Parameters["path"]["accountId"];
    };
  };
};

export const accountBannerKey = "accountBannerKey";

export function useAccountBanner({
  request,
  options,
}: {
  request: RequestOptions;
  options: UseQueryOptions<BannerData, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { accountId } = request.params.path;
  const parameters: Parameters = {
    path: {
      accountId: accountId,
    },
  };

  return useQuery<BannerData, unknown>({
    ...options,
    queryKey: [accountBannerKey],
    enabled: !!accountId,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, parameters);
      const { data } = await axios<BannerData>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
