import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
  formHelperTextClasses,
  inputClasses,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { AccountResponse } from "src/api/useAccount";
import { TextInputBase } from "src/components/TextInputBase";
import { useAccountEntitlementsUpdate } from "src/api/useAccountManagementEntitlements";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import { makeEntitlementsFromAccountFeaturesFormValues } from "../../utils/sourceFilterMapper";
import { EditAccountFeaturesFormValues } from "./AccountManagementEditFeatures.model";

export function AccountsManagementEditFeaturesTab({
  data,
}: {
  data?: AccountResponse;
}) {
  const { control, reset, handleSubmit, formState } =
    useFormContext<EditAccountFeaturesFormValues>();
  const accountFeaturesDataUpdate = useAccountEntitlementsUpdate({});
  const { accountId = "" } = useParams();
  const { data: defaultEntitlements } = useGlobalEntitlements();
  const defaultData = defaultEntitlements?.globalEntitlements;

  const transformValue = (value: string) => {
    if (value === "0") return "";
    return value;
  };
  const handleSubmitWrapper = handleSubmit(
    (formData) => {
      const entitlements =
        makeEntitlementsFromAccountFeaturesFormValues(formData);
      accountFeaturesDataUpdate.mutate({
        body: { ...entitlements },
        params: {
          path: {
            id: accountId,
          },
        },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:AccountsManagementEditFeaturesTab:error", errors);
    }
  );

  const resetFeatures = () => {
    if (data?.entitlements) {
      reset({
        enableMediaDownloads: data.entitlements.enableMediaDownloads?.value,
        enableEditWatchList: data.entitlements.enableEditWatchList?.value,
        enableAdFiltering: data.entitlements.enableAdFiltering?.value,
        enableCustomReportBanner:
          data.entitlements.enableCustomReportBanner?.value,
        enableReportDownloads: data.entitlements.enableReportDownloads?.value,
        limitWatchlistSize: data.entitlements.limitWatchlistSize?.value,
        limitItemsPerEmail: data.entitlements.limitItemsPerEmail?.value,
        limitEventsInReport: data.entitlements.limitEventsInReport?.value,
        limitEventsInCSV: data.entitlements.limitEventsInCSV?.value,
        limitEventsInPDF: data.entitlements.limitEventsInPDF?.value,
        limitScratchReportItems:
          data.entitlements.limitScratchReportItems?.value,
        limitEventsInReportEmail:
          data.entitlements.limitEventsInReportEmail?.value,
        limitEventsInAlertEmail:
          data.entitlements.limitEventsInAlertEmail?.value,
      });
    }
  };

  return (
    <>
      <Grid container gap={2} direction={"row"} flexWrap={"nowrap"} mb={2}>
        <Grid item xs={12} md={6}>
          <Stack component={Paper} px={4} pb={2} width={"568px"}>
            <Typography pt={3} variant="subtitle1">
              Enable Features
            </Typography>
            <Stack py={2} pb={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="enableMediaDownloads"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable save clips"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableEditWatchList"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Edit Watchlist"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableAdFiltering"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable ad filtering"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableCustomReportBanner"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable report customization"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="enableReportDownloads"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                        />
                      )}
                    />
                  }
                  label="Enable report downloads"
                />
              </FormGroup>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack component={Paper} px={4} width={"568px"}>
            <Typography pt={3} variant="subtitle1">
              Configure Limits
            </Typography>
            <Stack py={2}>
              {/*1 Limit Watchlist Size */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitWatchlistSize"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          autoComplete="off"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Watchlist Size
                  </Typography>
                </Stack>
              </Stack>
              {/*1 Limit Watchlist Size */}

              {/*2 Limit items per email */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitItemsPerEmail"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit items per email
                  </Typography>
                </Stack>
              </Stack>
              {/*2 Limit items per email */}

              {/*3 Limit limitEventsInReport */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInReport"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: 0,
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in reports
                  </Typography>
                </Stack>
              </Stack>
              {/*3 Limit limitEventsInReport */}

              {/*4 Limit limitEventsInCSV */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInCSV"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in CSV
                  </Typography>
                </Stack>
              </Stack>

              {/*4 Limit limitEventsInCSV */}

              {/*5 Limit limitEventsInPDF */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInPDF"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit Events in PDF
                  </Typography>
                </Stack>
              </Stack>
              {/*5 Limit limitEventsInPDF */}

              {/*6 Limit limitScratchReportItems */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitScratchReportItems"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit scratch report items
                  </Typography>
                </Stack>
              </Stack>
              {/*6 Limit limitScratchReportItems */}

              {/*7 Limit limitEventsInReportEmail */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInReportEmail"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit events in report email
                  </Typography>
                </Stack>
              </Stack>
              {/*7 Limit limitEventsInReportEmail */}

              {/*8 Limit limitEventsInAlertEmail */}
              <Stack direction={"row"} gap={4} mt={1}>
                <Stack width="150px">
                  <Controller
                    name="limitEventsInAlertEmail"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextInputBase
                          autoComplete="off"
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          size="small"
                          placeholder={defaultData?.[
                            field.name
                          ]?.value.toString()}
                          label={field.value ? "Number" : "Default limit"}
                          InputLabelProps={{ shrink: true }}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(transformValue(e.target.value))
                          }
                          onBlur={field.onBlur}
                          sx={{
                            [`.${inputClasses.root}`]: {
                              width: "150px",
                            },
                            [`& .${formHelperTextClasses.root}`]: {
                              margin: "0px",
                              width: "max-content",
                            },
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message || " "}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    variant="body1"
                    pt={1}
                  >
                    Limit events in alert email
                  </Typography>
                </Stack>
              </Stack>
              {/*8 Limit limitEventsInAlertEmail */}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={2}
            mb={3}
            mt={2}
          >
            <Button
              variant="outlined"
              sx={{ height: "42px", width: "124px", fontSize: "15px" }}
              onClick={resetFeatures}
              disabled={!formState.isDirty}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: "42px", width: "124px", fontSize: "15px" }}
              onClick={handleSubmitWrapper}
              disabled={!formState.isValid || !formState.isDirty}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
