import { AppRouteBuilder } from "src/models/AppRoute";
import { DownloadReportFormat } from "src/api/useReportExport";
import { PublicFileDownload } from "./PublicFileDownload.page";

export type FileDownloadPathParams = {
  userId: string;
  id: string;
  type: DownloadReportFormat;
};

export type FileDownloadQueryParams = {
  name: string;
};
export const publicFileDownloadRoute = new AppRouteBuilder<
  FileDownloadPathParams,
  FileDownloadQueryParams
>({
  path: "/public/download/:userId/:type/:id",
  element: <PublicFileDownload />,
});
