import clsx from "clsx";
import {
  useState,
  useEffect,
  useCallback,
  Children,
  useMemo,
  useRef,
} from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  Divider,
  useTheme,
} from "@mui/material";
import { TranscriptLineBundle } from "src/models/TranscriptLine";
import { Spinner } from "src/components/Spinner/Spinner";
import { transcriptLineViewClasses } from "src/components/TranscriptLineView/TranscriptLineView.const";
import { TranscriptLineBundleView } from "src/components/TranscriptLineBundleView/TranscriptLineBundleView";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";
import { TranscriptViewProps } from "./TranscriptView.model";
import { transcriptViewClasses } from "./TranscriptView.const";

export const TranscriptView = ({
  offset,
  event,
  loading,
  placeholder = "No transcript available",
  mentionBgColor,
  mentionFontColor,

  overflow = "scroll",
  autoScroll,
  hideHighlight,
  pageScrollOffset = 0,
  hideAutoScrollSwitch,
  showTimeLabel,
  showTrimToolBar,
  onAutoScrollChange,
  onClickLineBlock,
  onClickParagraphLabel,
  onClickParagraphSetStart,
  onClickParagraphSetEnd,

  // inner paddings
  p,
  pl = 2,
  pr = 1,
  pt,
  pb,
  px,
  py,

  variant,
  // outer container props
  ...props
}: TranscriptViewProps) => {
  const { palette } = useTheme();
  const scrollBusyRef = useRef(false);
  const [scrollSpeed, setScrollSpeed] = useState<"smooth">();
  const [anchorContainer, setAnchorContainer] = useState<HTMLElement | null>(
    null
  );

  const scrollbarStyleSx = useCustomScrollBarStyles({
    overflowX: "hidden",
    overflowY: overflow,
    size: "thin",
  });

  // when autoScroll is set, use scroll trigger to detect manual scroll and disable autoScroll
  const handleScroll = useCallback(() => {
    if (scrollBusyRef.current) {
      return;
    }

    scrollBusyRef.current = true;
    // FIXME: how to disable onAutoScrollChange(false);
    // Looks like there is no reliable way to detect manual scroll vs auto scroll
    // Also layout change triggers scroll event which can not be distinguished from manual scroll
  }, []);

  const handleScrollEnd = useCallback(() => {
    // do not track scroll end before scroll start
    if (!scrollBusyRef.current) {
      return;
    }

    scrollBusyRef.current = false;

    if (!scrollSpeed) {
      setScrollSpeed("smooth");
    }
  }, [scrollSpeed]);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    const element = anchorContainer;

    element?.addEventListener("scroll", handleScroll);
    element?.addEventListener("scrollend", handleScrollEnd);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
      element?.removeEventListener("scrollend", handleScrollEnd);
    };
  }, [autoScroll, anchorContainer, handleScroll, handleScrollEnd]);

  /**
   * Updates active state on offset change
   */
  useEffect(() => {
    const blocks = Array.from(
      anchorContainer?.querySelectorAll(
        `.${transcriptLineViewClasses.block}`
      ) || []
    );

    for (let i = 0; i < blocks.length; i++) {
      // use line bundles here for calculation?
      const defaultBlockDurationMs = 2000;
      const block = blocks[i];
      const t0 = parseInt(block.id);
      const t1 = parseInt(blocks[i + 1]?.id) || t0 + defaultBlockDurationMs;
      const hasActiveBlock = offset >= t0 && offset < t1;

      // remove previously set active mark
      block.classList.remove(transcriptLineViewClasses.current);

      if (hasActiveBlock) {
        if (!hideHighlight) {
          block.classList.add(transcriptLineViewClasses.current);
        }

        const anchor = block.querySelector<HTMLElement>(
          `.${transcriptLineViewClasses.anchor}`
        );

        const containerOffset = anchorContainer?.offsetTop ?? 0;
        const anchorOffset = anchor?.parentElement
          ? anchor.parentElement.offsetTop + anchor.offsetTop
          : 0;

        /** this is to compensate for a page scroll */
        const scrollBackOffset = Math.max(
          containerOffset + pageScrollOffset,
          0
        );

        const top = anchorOffset - scrollBackOffset;

        if (autoScroll) {
          anchorContainer?.scroll({
            top,
          });
        }
      }
    }
  }, [
    offset,
    autoScroll,
    hideHighlight,
    pageScrollOffset,
    scrollSpeed,
    anchorContainer,
  ]);

  const renderTranscriptBundle = useCallback(
    (bundle: TranscriptLineBundle, index: number) => {
      const handleClickSetStartWrapper = () => {
        if (bundle.bundleStartDateTime && onClickParagraphSetStart) {
          const date = new Date(bundle.bundleStartDateTime);
          onClickParagraphSetStart(date);
        }
      };

      const handleClickSetEndWrapper = () => {
        if (bundle.bundleStartDateTime && onClickParagraphSetEnd) {
          const date = new Date(bundle.bundleStartDateTime);
          onClickParagraphSetEnd(date);
        }
      };

      return (
        <TranscriptLineBundleView
          variant={variant}
          key={index}
          showTimeLabel={showTimeLabel}
          showTrimToolBar={showTrimToolBar}
          bundle={bundle}
          baseTime={event.startDateTime}
          onClickLineBlock={onClickLineBlock}
          onClickLabel={onClickParagraphLabel}
          onClickSetStart={handleClickSetStartWrapper}
          onClickSetEnd={handleClickSetEndWrapper}
          mentionBgColor={mentionBgColor}
          mentionFontColor={mentionFontColor || palette.primary.main}
        />
      );
    },
    [
      variant,
      showTimeLabel,
      showTrimToolBar,
      event.startDateTime,
      mentionBgColor,
      mentionFontColor,
      palette.primary.main,
      onClickLineBlock,
      onClickParagraphLabel,
      onClickParagraphSetStart,
      onClickParagraphSetEnd,
    ]
  );

  const transcriptLineBundleElements = useMemo(() => {
    return event.transcriptLineBundles?.map(renderTranscriptBundle);
  }, [renderTranscriptBundle, event.transcriptLineBundles]);

  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" {...props}>
        <Spinner />
      </Stack>
    );
  }

  if (!Children.count(transcriptLineBundleElements)) {
    return (
      <Stack justifyContent="center" alignItems="center" {...props}>
        <Typography variant={variant}>{placeholder}</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      overflow="hidden"
      rowGap={2}
      {...props}
      className={clsx(transcriptViewClasses.root, props.className)}
    >
      {!hideAutoScrollSwitch && (
        <Stack mx={2} pt={1}>
          <FormControlLabel
            label="Auto scroll"
            control={
              <Switch
                checked={autoScroll}
                onChange={(_e, v) => {
                  onAutoScrollChange && onAutoScrollChange(v);
                }}
              />
            }
          />
          <Divider sx={{ mt: 1 }} />
        </Stack>
      )}

      <Stack
        ref={setAnchorContainer}
        overflow={overflow}
        flex={1}
        // internal paddings
        p={p}
        px={px}
        py={py}
        pl={pl}
        pr={pr}
        pt={pt}
        pb={pb}
        //
        rowGap={2}
        className={transcriptViewClasses.scroll}
        sx={scrollbarStyleSx}
        children={transcriptLineBundleElements}
      />
    </Stack>
  );
};
