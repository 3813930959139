import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useMemo, useState } from "react";
import {
  PushNotificationEventEmitter,
  usePushNotificationEmitter,
} from "./usePushNotificationEmitter";
import { usePushNotificationMethods } from "./usePushNotificationMethods";

export type PushNotificationState = {
  /** An underlying hub connection state */
  state: HubConnectionState;

  /** An emitter of the application-level notification messages */
  emitter: PushNotificationEventEmitter;

  /** Method to explicitly connect to remote notification Hub */
  connect: HubConnection["start"];

  /** Method to explicitly disconnect from remote notification Hub */
  disconnect: HubConnection["stop"];
};

export const PushNotificationEmptyState: PushNotificationState = {
  state: HubConnectionState.Disconnected,
  connect: () => new Promise<void>(() => undefined),
  disconnect: () => new Promise<void>(() => undefined),
  emitter: new PushNotificationEventEmitter(),
};

/**
 * A hook to obtain Push Notification service internal state.
 */
export function usePushNotificationState({
  connection,
}: {
  /** underlying transport connection */
  connection: HubConnection;
}) {
  const emitter = usePushNotificationEmitter({ connection });
  const [state, setState] = useState<HubConnectionState>(
    HubConnectionState.Disconnected
  );

  const { connect, disconnect } = usePushNotificationMethods({
    connection,
    setState,
  });

  // return memoized state to consumer
  return useMemo(() => {
    return {
      state,
      connect,
      disconnect,
      emitter,
    };
  }, [state, connect, disconnect, emitter]);
}
