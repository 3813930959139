import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicEventPage } from "./PublicEvent.page";

export type PublicEventPathParams = {
  stationId: string;
  dateTime: string;
};

export type PublicEventQueryParams = {
  queryId: string;
};

export const publicEventRoute = new AppRouteBuilder<
  PublicEventPathParams,
  PublicEventQueryParams
>({
  path: "/public/broadcast-datetime/:stationId/:dateTime/",
  element: <PublicEventPage />,
});
