import { useMediaQuery, useTheme } from "@mui/material";
import { gridClasses } from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { makeReportEventsTableColumns } from "./ReportEventsTable.utils";
import { ReportEventsTableProps } from "./ReportEventsTable.model";
import { ReportEventsTableClasses } from "./ReportEventsTable.const";

export function ReportEventsTable({
  renderEventsItemActions,
  getEventUrl,
  highlightedEventsIds,
  ...props
}: ReportEventsTableProps) {
  const { breakpoints, typography } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const columns = makeReportEventsTableColumns({
    renderEventsItemActions,
    getEventUrl,
  });

  return (
    <TableBase
      columns={columns}
      columnHeaderHeight={isMobile ? 42 : undefined}
      {...props}
      slots={{
        noRowsOverlay: EventsNoResultsOverlay,
        noResultsOverlay: EventsNoResultsOverlay,
        ...props.slots,
      }}
      getRowId={(row) => row.id}
      getRowClassName={(params) =>
        highlightedEventsIds?.includes(params.row.id) ? "row-highlighted" : ""
      }
      sx={{
        // cell styles, these override TableBase cell styles
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
        [`.${ReportEventsTableClasses.cellEvent}`]: {
          ...typography.body1,
        },
      }}
    />
  );
}
