import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ExportReportFile } from "src/components/ExportReportFile/ExportReportFile";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { DownloadReportFormat } from "src/api/useReportExport";
import { FileDownloadPathParams } from "./PublicFileDownload.route";
import { downloadFileRoute } from "../DownloadFile/DownloadFile.route";

export const PublicFileDownload = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();
  const {
    userId = "",
    type = DownloadReportFormat.pdf,
    id = "",
  } = useParams<FileDownloadPathParams>();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("name");

  useEffect(() => {
    if (isAuthenticated) {
      navigate(
        downloadFileRoute.makeUrl(
          {
            userId,
            type,
            id,
          },
          {
            name: fileName || "",
          }
        )
      );
    }
  }, [isAuthenticated, fileName, userId, type, navigate, id]);

  if (isLoading) return <LoadingOverlay />;

  return (
    <ExportReportFile
      userId={userId}
      type={type}
      id={id}
      fileName={fileName || ""}
    />
  );
};
