import { FormLabel, Stack, Switch, Typography } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro";
import { addDays, format } from "date-fns";
import { useState } from "react";
import { AppDateTimePicker } from "src/components/AppDateTimePicker/AppDateTimePicker";
import { DateRange } from "src/components/DateSelector/DateSelector";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DateTimeZonedPicker } from "src/components/DateTimeZonedPicker/DateTimeZonedPicker";

const initialStart = new Date();
initialStart.setHours(0, 0, 0);
const initialEnd = addDays(initialStart, 1);

export function DevDateTimePickers() {
  const [date, setDate] = useState<Date | null>(new Date());
  const [dates, onChange] = useState<DateRange>([initialStart, initialEnd]);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [dateMode, setDateMode] = useState<DateTimeSelectorMode>(
    DateTimeSelectorMode.fixed
  );

  const handleChangeDate = (dateRange: DateRange) => {
    console.log(`@@ DEBUG:`, dateRange);
    setDateRange(dateRange);
  };

  return (
    <Stack direction="column" rowGap={2} p={2}>
      <Stack pt={2}>
        <Typography>
          current locale: {Intl.DateTimeFormat().resolvedOptions().locale}
        </Typography>

        <Typography>
          time format:{" "}
          {new Date().toLocaleString(undefined, { timeStyle: "short" })}
        </Typography>
      </Stack>
      <Stack pt={2}>
        <DateTimeZonedPicker
          label="Station Time"
          value={date}
          format="PPP pp"
          onChange={setDate}
          timezone="America/New_York"
        />
      </Stack>

      <Stack>
        <Typography children="Mobile DateTime Picker" />
        <MobileDateTimePicker />
      </Stack>

      <Stack>
        <Typography children="Auto open date picker" />
        <AppDateTimePicker
          slotProps={{
            field: {
              clearable: true,
            },
          }}
          timeSteps={{
            minutes: 1,
            seconds: 1,
          }}
        />
      </Stack>

      <Stack>
        <Typography>Mobile Date Time Picker</Typography>
        <Typography>Example of formatting with locale in code</Typography>
        <Stack direction="row" columnGap={2} alignItems="center">
          <DateTimeRangePickerMobile value={dates} onChange={onChange} />
          <Typography>
            {dates[0] && format(dates[0], "p")}
            {" - "}
            {dates[1] && format(dates[1], "p")}
          </Typography>
        </Stack>
      </Stack>

      <FormLabel title="Picker Mode">
        <Stack direction="row" columnGap={2} alignItems="center" height={48}>
          <Typography>Picker Mode</Typography>
          <Switch
            onClick={() =>
              setDateMode((prev) =>
                prev === DateTimeSelectorMode.fixed
                  ? DateTimeSelectorMode.variable
                  : DateTimeSelectorMode.fixed
              )
            }
          />
          <DateTimeSelector
            value={dateRange}
            onChange={handleChangeDate}
            mode={dateMode}
            views={["hours", "minutes"]}
          />
        </Stack>
      </FormLabel>
    </Stack>
  );
}
