import {
  Advertisement,
  queryAdvertisementValues,
} from "src/models/Advertisement";

/**
 * parse backend advertisement value to typed values
 * @param unknown value to be parsed
 * @returns typed advertisement value or "all" by default
 */
export const parseWatchQueryAdvert = (value?: string | null): Advertisement => {
  const options: unknown[] = Object.values(queryAdvertisementValues);
  if (options.includes(value)) {
    return value as Advertisement;
  } else {
    console.error(`Unknown ad value: "${value}"`);
  }

  return queryAdvertisementValues.all;
};
