import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import CheckIcon from "@mui/icons-material/Check";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { PageLayout } from "src/components/PageLayout";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import {
  AccountCreateResponse,
  useCreateAccount,
} from "src/api/useAccountCreate";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { useBulkUsers } from "src/api/useBulkUsersCreate";
import { useGlobalEntitlements } from "src/api/useGlobalEntitlements";
import { AccountsManagementFeaturesTab } from "./components/AccountsManagementFeaturesTab/AccountsManagementFeaturesTab";
import { AccountsManagementGeneralTab } from "./components/AccountsManagementGeneralTab/AccountsManagementGeneralTab";
import { AccountsContentTab } from "./components/AccountsManagementContentTab/AccountsManagementContentTab";
import { NewAccountFormValues } from "./AccountsManagementCreateAccount.model";
import {
  limitsConfiguration,
  NewAccountSchema,
} from "./AccountsManagementCreateAccount.schema";
import { createAccountDefaultValues } from "./AccountsManagementCreateAccount.const";
import { accountsManagementRoute } from "../AccountsManagement/AccountsManagement.route";
import { createNewAccountBody } from "../AccountsManagement/utils/createNewAccountBody";
import { accountsManagementEditAccountRoute } from "../AccountsManagementEditAccount/AccountsManagementEditAccount.route";

enum AccountsManagementTabs {
  general = "general",
  content = "content",
  features = "features",
  customization = "customization",
}

export const AccountsManagementCreateAccount = () => {
  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);

  const { data: globalEntitlementsData } = useGlobalEntitlements();
  const systemLimits = globalEntitlementsData?.globalEntitlements;

  const [accountsTab, setAccountsTab] =
    useViewModeQueryParam<AccountsManagementTabs>({
      paramKey: "create",
      defaultValue: AccountsManagementTabs.general,
    });
  const navigate = useNavigate();
  const theme = useTheme();
  const createAccountUsers = useBulkUsers({});

  const createAccountOptions = {
    onSuccess: (response: AccountCreateResponse) => {
      if (response.id) {
        if (formUsers.length) {
          createAccountUsers.mutate({
            query: formUsers,
            accountId: response.id,
          });
        }
        const accountUrl = accountsManagementEditAccountRoute.makeUrl({
          accountId: response.id,
        });
        navigate(response.id ? accountUrl : accountsManagementRoute.path, {
          state: { section: "account-users" },
        });
      }
    },
  };

  const accountCreate = useCreateAccount({
    options: createAccountOptions,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formHook = useForm<NewAccountFormValues>({
    mode: "all",
    resolver: joiResolver(
      NewAccountSchema(systemLimits || null, limitsConfiguration.system)
    ),
    defaultValues: createAccountDefaultValues,
  });

  const { handleSubmit, watch } = formHook;
  const excludeValues = watch("sourcesExclude");
  const includeValues = watch("sourcesInclude");
  const formUsers = watch("users");

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const filters = makeContentFilterQuery(includeValues, excludeValues);
    setContentFilters(filters);
  }, [excludeValues, includeValues]);

  const handleSubmitWrapper = handleSubmit(
    (data) => {
      const body = createNewAccountBody(data, contentFilters);
      accountCreate.mutate({ body });
    },
    (errors) => {
      console.log("@@ DEBUG:AccountsManagementCreateAccount:error", errors);
    }
  );

  const createAccountButton = (
    <Button
      variant={!isMobile ? "contained" : "text"}
      color="primary"
      sx={
        isMobile
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      onClick={handleSubmitWrapper}
    >
      {!isMobile ? "Create Account" : "Save"}
    </Button>
  );

  const payingButton = (
    <Button
      disabled
      startIcon={<CheckIcon />}
      variant={!isMobile ? "contained" : "text"}
      color="primary"
      sx={
        isMobile
          ? { fontSize: "16px" }
          : { height: "42px", width: "196px", fontSize: "15px" }
      }
      onClick={() => {
        console.log("submitting");
      }}
    >
      Paying
    </Button>
  );

  const pageHeaderMobile = (
    <Typography variant="subtitle2">Create Account</Typography>
  );

  const pageHeaderDesktop = (
    <Stack
      flexWrap="wrap"
      direction="row"
      mb={3}
      justifyContent="space-between"
      alignItems="center"
      marginBottom={3.75}
    >
      <Stack>
        <Typography flexGrow={1} variant="h6" mr={2}>
          Create Account
        </Typography>
        <AppTopNavBar
          lastBreadcrumbLabel="Create Account"
          sx={{ mb: 2, mt: 0.5 }}
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          sx={{ height: "42px", width: "196px", fontSize: "15px" }}
          onClick={goBack}
        >
          Cancel
        </Button>
        {payingButton}
        {createAccountButton}
      </Stack>
    </Stack>
  );

  return (
    <PageLayout pb={0} hideTopBar>
      <Stack height="100%">
        {isMobile ? pageHeaderMobile : pageHeaderDesktop}
        <FormProvider {...formHook}>
          <AppTabLayout
            flex={1}
            overflow="auto"
            variant="scrollable"
            currentTab={accountsTab}
            onCurrentTabChange={(tab) =>
              setAccountsTab(tab as AccountsManagementTabs)
            }
          >
            <AppTab
              label="General"
              value={AccountsManagementTabs.general}
              children={<AccountsManagementGeneralTab />}
            />
            <AppTab
              label="Content Filter"
              value={AccountsManagementTabs.content}
              children={<AccountsContentTab contentFilters={contentFilters} />}
            />
            <AppTab
              label="Features"
              value={AccountsManagementTabs.features}
              children={<AccountsManagementFeaturesTab />}
            />
            <AppTab
              label="Customization"
              disabled
              value={AccountsManagementTabs.customization}
              children={<p>Customization</p>}
            />
          </AppTabLayout>
        </FormProvider>
      </Stack>
    </PageLayout>
  );
};
