import { Box } from "@mui/material";
import { useWatchQueryDelete } from "src/api/useWatchQueryDelete";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "../DialogPrompt/DialogPrompt.model";

type WatchQueryDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  query: WatchListUserQueryDTO;
};

export const WatchQueryDeleteDialog = ({
  onClose,
  query,
  ...props
}: WatchQueryDeleteDialogProps) => {
  const deleteMutation = useWatchQueryDelete({});

  const handleDelete = () => {
    deleteMutation.mutate(query);
    onClose && onClose();
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      title="Delete the watch term?"
      children={
        <Box pb={1}>Do you want to delete the {query.title} watch term?</Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      {...props}
    />
  );
};
