import { Stack, StackProps } from "@mui/material";
import { format } from "date-fns";
import { EventDetails } from "src/models/EventDetails";
import { getEventTooltipTitle } from "src/utils/getEventIcon";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function BCastProgram({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock title="Program name" value={event.title} />
        <EventDetailsDataBlock value={event.program?.description} />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Date"
          value={
            event.startDateTime &&
            format(new Date(event.startDateTime), "PPP pp")
          }
        />
        <EventDetailsDataBlock
          title="Event Type"
          value={getEventTooltipTitle(
            event.eventType ?? "",
            event.mediaType ?? undefined
          )}
        />
        <EventDetailsDataBlock title="Source" value={event.source} />
        <EventDetailsDataBlock title="Market" value={event.market} />
        <EventDetailsDataBlock title="Country" value={event.country} />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Category"
          value={event.program?.category}
        />
        <EventDetailsDataBlock title="Genres" value={event.program?.genre} />
      </Stack>
    </Stack>
  );
}
