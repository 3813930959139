import { useCallback } from "react";
import { create } from "zustand";
import { makeEmptyMediaCenterFilterFormValues } from "../components/MediaCenterFilterForm/MediaCenterFilterForm.hook";
import { MediaCenterFilterFormValues } from "../components/MediaCenterFilterForm/MediaCenterFilterForm.model";

type UseMediaCenterFilterState = {
  form: MediaCenterFilterFormValues;
  reset: () => void;
  update: (changes: Partial<MediaCenterFilterFormValues>) => void;
};

const emptyWatchQueryFilter = makeEmptyMediaCenterFilterFormValues();

const stateHook = create<UseMediaCenterFilterState>((set, get) => {
  return {
    form: emptyWatchQueryFilter,
    reset: () => {
      const prev = get();
      set(
        {
          ...prev,
          form: emptyWatchQueryFilter,
        },
        true
      );
    },
    update: (changes) => {
      const { form } = get();
      set({ form: { ...form, ...changes } });
    },
  };
});

export function useMediaCenterFilter(): [
  MediaCenterFilterFormValues,
  (changes: MediaCenterFilterFormValues) => void,
  () => void
] {
  const state = stateHook();
  const { form, update, reset } = state;

  const setUpdateWrapper = useCallback(
    (changes: MediaCenterFilterFormValues) => {
      update(changes);
    },
    [update]
  );

  return [form, setUpdateWrapper, reset];
}
