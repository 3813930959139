import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { userManagementKey } from "./useUsersManagement";
import { userManagementInfiniteKey } from "./useUsersManagementInfinite";

const apiKey: keyof paths = "/api/User/bulk";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  query: RequestBody;
  accountId: string;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useBulkUsers({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ query, accountId }) => {
      const token = await getAccessTokenSilently();

      const url = apiKey;
      const body: RequestBody = query.map((user) => ({
        ...user,
        //FIXME: For now backend needs value to be hardcoded
        userId: "00000000-0000-0000-0000-000000000000",
        accountId,
      }));
      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: userManagementKey,
      });
      queryClient.refetchQueries({
        queryKey: userManagementInfiniteKey,
      });
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError?.(error, variables, context);
    },
  });
}
