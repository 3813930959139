import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation } from "react-query";
import { EventDetails } from "src/models/EventDetails";
import { makeOffsetRange } from "src/utils/makeOffsetRange";

const apiKey: keyof paths = "/api/editor/podcast";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  event: EventDetails;
  trimRange?: [Date | null, Date | null];
  clipTitle?: string;
  archive?: boolean;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useClipSavePCast(params?: { options?: RequestOptions }) {
  const { options } = params || {};
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ event, trimRange, clipTitle, archive }) => {
      const token = await getAccessTokenSilently();
      const [offsetT0, offsetT1] = makeOffsetRange({
        baseDate: event.startDateTime,
        trimRange,
      });

      const body: RequestBody = {
        eventDetails: event,
        clipTitle,
        archive,
        assetId: event.assetId,
        eventId: event.id,
        startOffsetMs: offsetT0,
        endOffsetMs: offsetT1,
      };

      const { data } = await axios<Response>({
        url: apiKey,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return {
        id: crypto.randomUUID(),
        ...data,
      };
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        `Your Podcast is being prepared. You will receive an email pointing you to the clip location shortly. Thank You`
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
  });
}
