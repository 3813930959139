import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

// pulled out of external-api.service.ts removed in master
const callExternalApi = async (options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

// pulled from message.service.ts removed in master
interface AppError {
  message: string;
}

interface Message {
  text: string;
}

interface ApiResponse {
  data: Message | null;
  error: AppError | null;
}

const getUserInfo = async (accessToken: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `/api/Auth0User`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export function DevAuth0() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [copyStatus, setCopyStatus] = useState<string>("idle");

  useEffect(() => {
    let isMounted = true;

    const getToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getUserInfo(accessToken);
      setToken(accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setMessage(JSON.stringify(data, null, 2));
      }

      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }
    };

    getToken();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  const copyToClipboard = async (value: string) => {
    setCopyStatus("idle");
    try {
      if (!navigator.clipboard) {
        setCopyStatus("error");
        throw new Error("browser does not support navigation.clipboard");
      }

      await navigator.clipboard.writeText(value);
      setCopyStatus("success");

      setTimeout(() => setCopyStatus("idle"), 5000);
    } catch (thrown) {
      setCopyStatus("error");
    }
  };

  const copyButtonColor = () => {
    if (copyStatus === "success") return "secondary";
    if (copyStatus === "error") return "error";
    return "primary";
  };

  const copyButtonText = () => {
    if (copyStatus === "success") return "Copied to Clipboard";
    if (copyStatus === "error") return "Copy not supported by browser";
    return "Copy Token to Clipboard";
  };

  return (
    <Stack direction="column" spacing={2}>
      {token.length > 0 && (
        <Card sx={{ mb: 4, overflowX: "auto" }}>
          <CardHeader title={<Typography>Auth0 Raw Token</Typography>} />
          <CardContent>
            <Button
              variant="contained"
              size="small"
              color={copyButtonColor()}
              onClick={() => copyToClipboard(token)}
            >
              {copyButtonText()}
            </Button>
            <Typography variant="subtitle2">
              <code>{token}</code>
            </Typography>
          </CardContent>
        </Card>
      )}
      {message && (
        <Card sx={{ mb: 4, overflowX: "auto" }}>
          <Divider light />
          <CardHeader title={<Typography>UserInfo API</Typography>} />
          <CardContent>
            <Typography variant="subtitle2">
              <code style={{ whiteSpace: "pre" }}>{message}</code>
            </Typography>
          </CardContent>
        </Card>
      )}
      {user && (
        <Card sx={{ mb: 4, overflowX: "auto" }}>
          <Divider light />
          <CardHeader title={<Typography>Auth0 Token</Typography>} />
          <CardContent>
            <Typography variant="subtitle2">
              <code style={{ whiteSpace: "pre" }}>
                {JSON.stringify(user, null, 2)}
              </code>
            </Typography>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
