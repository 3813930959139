import { useEffect } from "react";

import {
  DensityMediumOutlined,
  FormatListBulletedOutlined,
  GridViewOutlined,
  CalendarViewMonth,
  ViewCompactOutlined,
} from "@mui/icons-material";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";

import {
  svgIconClasses,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ViewMode, ViewModeSwitchProps } from "./ViewModeSwitch.model";

export function ViewModeSwitch({
  size,
  value,
  onChange,
  options = Object.values(ViewMode),
}: ViewModeSwitchProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  if (options.length < 1) {
    throw new Error("View options must contain at least one value");
  }

  useEffect(() => {
    // if current value is not present in the options - switch to the
    if (!options.includes(value)) {
      onChange(options[0]);
    } else {
      onChange(value);
    }
  }, [onChange, options, value]);

  const quiltViewModeButton = options.includes(ViewMode.portrait) && (
    <ToggleButton value={ViewMode.portrait} sx={{ width: 42 }}>
      <Tooltip title="Portrait View">
        <ViewQuiltOutlinedIcon />
      </Tooltip>
    </ToggleButton>
  );

  const columnViewModeButton = options.includes(ViewMode.book) && (
    <ToggleButton value={ViewMode.book} sx={{ width: 42 }}>
      <Tooltip title="Book View">
        <ViewColumnOutlinedIcon />
      </Tooltip>
    </ToggleButton>
  );

  const compactViewModeButton = options.includes(ViewMode.landscape) && (
    <ToggleButton value={ViewMode.landscape} sx={{ width: 42 }}>
      <Tooltip title="Landscape View">
        <ViewComfyOutlinedIcon sx={{ width: 21, height: 21 }} />
      </Tooltip>
    </ToggleButton>
  );

  const tableViewModeButton = options.includes(ViewMode.table) && (
    <ToggleButton
      value={ViewMode.table}
      sx={{ width: !isMobile ? 48 : undefined }}
    >
      <Tooltip title="Table view mode">
        <DensityMediumOutlined />
      </Tooltip>
    </ToggleButton>
  );

  const listViewModeButton = options.includes(ViewMode.list) && (
    <ToggleButton
      value={ViewMode.list}
      sx={{ width: !isMobile ? 48 : undefined }}
    >
      <Tooltip title="Normal view mode">
        <FormatListBulletedOutlined />
      </Tooltip>
    </ToggleButton>
  );

  const tileViewModeButton = options.includes(ViewMode.tile) && (
    <ToggleButton
      value={ViewMode.tile}
      sx={{ width: !isMobile ? 48 : undefined }}
    >
      <Tooltip title="Tiles view mode">
        <GridViewOutlined />
      </Tooltip>
    </ToggleButton>
  );

  const smallTilesViewModeButton = options.includes(ViewMode.smallTiles) && (
    <ToggleButton
      value={ViewMode.smallTiles}
      sx={{ width: !isMobile ? 48 : undefined }}
    >
      <Tooltip title="Small tiles view mode">
        <ViewCompactOutlined />
      </Tooltip>
    </ToggleButton>
  );

  const largeTilesViewModeButton = options.includes(ViewMode.largeTiles) && (
    <ToggleButton
      value={ViewMode.largeTiles}
      sx={{ width: !isMobile ? 48 : undefined }}
    >
      <Tooltip title="Large tiles view mode">
        <CalendarViewMonth />
      </Tooltip>
    </ToggleButton>
  );

  return (
    <ToggleButtonGroup
      size={size}
      value={value}
      onChange={(_e, value) => {
        if (!value) return;
        onChange(value);
      }}
      exclusive
      sx={{
        minWidth: "fit-content",
        [`.${svgIconClasses.root}`]: {
          color: "text.primary",
        },
      }}
    >
      {tableViewModeButton}
      {listViewModeButton}
      {tileViewModeButton}
      {quiltViewModeButton}
      {columnViewModeButton}
      {compactViewModeButton}
      {smallTilesViewModeButton}
      {largeTilesViewModeButton}
    </ToggleButtonGroup>
  );
}
