import { Button, Stack, useTheme } from "@mui/material";

export function ReportAnalysisFooterMobile() {
  const { palette } = useTheme();

  return (
    <Stack
      px={2}
      py={1}
      borderTop={`1px solid ${palette.divider}`}
      flexDirection="row"
      justifyContent="space-between"
      gap={2}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        children="Export"
        fullWidth
      />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        children="Share"
        fullWidth
      />
    </Stack>
  );
}
