import { forwardRef, RefObject } from "react";
import { Popper, PopperProps } from "@mui/material";

export const makeAutocompleteInlinePopperComponent = ({
  dropDownBoundsEl,
  dropDownWidth,
}: {
  dropDownBoundsEl?: RefObject<HTMLElement>;
  dropDownWidth?: number;
}) => {
  return forwardRef<HTMLDivElement, PopperProps>((popperProps, ref) => {
    return (
      <Popper
        {...popperProps}
        ref={ref}
        modifiers={[
          {
            // this will keep dropdown popper inside provided
            name: "preventOverflow",
            options: {
              boundary: dropDownBoundsEl?.current,
            },
          },
        ]}
        style={{
          ...popperProps.style,
          width: dropDownWidth || popperProps.style?.width,
        }}
      />
    );
  });
};
