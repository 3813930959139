import { addMinutes } from "date-fns";
import { DateTimePickerProps } from "@mui/x-date-pickers-pro";
import { AppDateTimePicker } from "../AppDateTimePicker/AppDateTimePicker";
import { getTimeZoneOffsetMinutes } from "./utils/getTimeZoneOffsetMinutes";

/**
 * DateTime picker that shows time in specified timezone
 */
export function DateTimeZonedPicker(props: DateTimePickerProps<Date>) {
  const zoneTime = props.value
    ? new Date(
        props.value.toLocaleString("en-US", {
          timeZone: props.timezone,
        })
      )
    : undefined;

  const offsetMin = props.timezone
    ? getTimeZoneOffsetMinutes(props.timezone)
    : 0;

  const handleChangeWrapper: DateTimePickerProps<Date>["onChange"] = (
    date,
    context
  ) => {
    const localOffsetMin = date?.getTimezoneOffset() ?? 0;
    const utcDate = date ? addMinutes(date, offsetMin - localOffsetMin) : null;
    props.onChange?.(utcDate, context);
  };

  return (
    <AppDateTimePicker
      {...props}
      value={zoneTime}
      onChange={handleChangeWrapper}
    />
  );
}
