import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack, ArrowDropDownOutlined, Edit } from "@mui/icons-material";
import {
  Stack,
  Typography,
  ButtonBase,
  ListItemButton,
  IconButton,
  CircularProgress,
  Box,
  Popover,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ListBase } from "src/components/ListBase/ListBase";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { SearchInput } from "src/components/SearchInput";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { useOpenState } from "src/utils/useOpenState";
import { isNormalQuery } from "src/utils/isNormalQuery";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { useSessionContext } from "src/api/useSessionContext";
import { WatchTermNavBarProps } from "./WatchTermNavBar.model";

export function WatchTermNavBar({
  value,
  onChange,
  onOpenUpdateDialog,
  loading,
  ...props
}: WatchTermNavBarProps) {
  const navigate = useNavigate();
  const goBack = () => navigate(watchListRoute.path);
  const watchQueryList = useWatchQueryList({});
  const anchorRef = useRef<HTMLButtonElement>(null);
  const menuState = useOpenState();
  const [filterText, setFilterText] = useState("");
  const [filterOptions, setFilterOptions] = useState<WatchListUserQueryDTO[]>(
    []
  );
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();
  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;

  const selectWatchQuery = (nextWatchQuery: WatchListUserQueryDTO) => {
    menuState.hide();
    onChange(nextWatchQuery);
  };

  useEffect(() => {
    if (!filterText) {
      setFilterOptions(
        (watchQueryList.data?.results || []).filter(isNormalQuery)
      );
      return;
    }

    const prevOptions = (watchQueryList.data?.results || []).filter(
      isNormalQuery
    );
    const nextOptions = prevOptions.filter((query) => {
      const q = filterText.toLowerCase();
      return query.title.toLowerCase().includes(q);
    });

    setFilterOptions(nextOptions);
  }, [watchQueryList.data?.results, filterText]);

  const dropDownContent = (
    <Stack
      overflow="hidden"
      maxHeight={400}
      minWidth={anchorRef.current?.clientWidth}
    >
      <SearchInput
        onTextChangeThrottled={setFilterText}
        loading={watchQueryList.isLoading || loading}
        placeholder="Watch terms in watchlist"
        hideClear
        sx={{
          maxWidth: "inherit",
          m: 1,
        }}
        InputProps={{
          endAdornment: <CircularProgress size={18} color="primary" />,
        }}
      />
      {filterOptions.length ? (
        <ListBase<WatchListUserQueryDTO>
          rows={filterOptions}
          hideFooter
          loading={watchQueryList.isLoading}
          ListProps={{
            sx: {
              rowGap: 0,
            },
          }}
          renderItem={(item) => (
            <Typography
              key={item.id}
              component={ListItemButton}
              onClick={() => selectWatchQuery(item)}
              selected={item.id === value?.id}
            >
              {item.title}
            </Typography>
          )}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          m="auto"
          py={2}
        >
          <Typography>No options</Typography>
        </Box>
      )}
    </Stack>
  );

  const desktopDropDown = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      elevation={4}
      open={menuState.isOpen}
      onClose={menuState.hide}
    >
      {dropDownContent}
    </Popover>
  );

  return (
    <Stack>
      <Stack direction="row" alignItems="center" {...props}>
        <IconButton onClick={goBack} edge="start">
          <ArrowBack />
        </IconButton>
        <Tooltip title={value?.title || "loading"}>
          <ButtonBase
            ref={anchorRef}
            onClick={menuState.isOpen ? menuState.hide : menuState.show}
            sx={{
              pl: 2,
              pr: 1,
              py: 0.5,
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <TextLineClamp variant="h6" lineClamp={1}>
              {value?.title}
            </TextLineClamp>
            <ArrowDropDownOutlined sx={{ ml: 1 }} />
          </ButtonBase>
        </Tooltip>
        <Tooltip
          title={
            enableEditWatchList ? "Edit watch term" : t("editWatchListDisabled")
          }
        >
          <Box>
            <IconButton
              onClick={onOpenUpdateDialog}
              disabled={!enableEditWatchList}
            >
              <Edit />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
      {desktopDropDown}
    </Stack>
  );
}
