import { Stack, Tooltip } from "@mui/material";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCategory,
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultEventType,
  getPowerSearchResultLanguage,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultSource,
  getPowerSearchResultTranscript,
  getPowerSearchResultViews,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultDuplicates,
  getPowerSearchResultId,
} from "src/api/usePowerSearchResult.getters";
import { WatchTermResultItem } from "src/api/useWatchTermResults";
import { getEventIcon } from "src/utils/getEventIcon";
import { makeTranscriptHTML } from "src/utils/makeTranscriptHTML";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AppLink } from "src/components/AppLink/AppLink";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { DuplicatesChip } from "src/components/DuplicatesChip/DuplicatesChip";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { WatchTermResultsTableColDef } from "./WatchTermResultsTable.model";
import { WatchTermResultsActions } from "../WatchTermResultsActions/WatchTermResultsActions";
import { WatchTermResultsTableClasses } from "./WatchTermResultsTable.const";

const commonCellParams: Pick<
  WatchTermResultsTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu"
> = {
  sortable: false,
  disableColumnMenu: true,
};

export function makeWatchTermResultTableColumns({
  queryId,
  addItemToReport,
  showDuplicatesDrawer,
  selectedDuplicatesIds,
}: {
  queryId: string;
  addItemToReport: (value: WatchTermResultItem) => void;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  selectedDuplicatesIds: string[];
}): WatchTermResultsTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Event",
      field: "event",
      minWidth: 160,
      flex: 1,
      cellClassName: WatchTermResultsTableClasses.cellEvent,
      renderCell(params) {
        const detailsUrl = watchListTermResultClipRoute.makeUrl({
          queryId,
          eventId: getPowerSearchResultId(params.row),
        });

        return (
          <Tooltip title={getPowerSearchResultEvent(params.row)}>
            <AppLink to={detailsUrl}>
              <TextLineClamp lineClamp={2} color="primary">
                {getPowerSearchResultEvent(params.row)}
              </TextLineClamp>
            </AppLink>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Type",
      field: "type",
      width: 56,
      renderCell(params) {
        const eventType = getPowerSearchResultEventType(params.row);
        const mediaType = getPowerSearchResultMediaType(params.row);
        const Icon = getEventIcon(eventType, mediaType);

        return (
          <Stack m="auto">
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Date",
      field: "date",
      type: "dateTime",
      renderCell(params) {
        return (
          <Tooltip title={getPowerSearchResultDateTime(params.row)}>
            <TextLineClamp lineClamp={2}>
              {getPowerSearchResultDateTime(params.row)}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Has ads",
      field: "adTrackIds",
      renderCell(params) {
        const value = getPowerSearchResultTag(params.row);

        return value.length ? <AdvChip /> : undefined;
      },
    },
    {
      ...commonCellParams,
      headerName: "Transcription",
      field: "transcription",
      minWidth: 160,
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultTranscript(params.row);
      },
      renderCell(params) {
        const transcript = params.value
          ? makeTranscriptHTML({ transcript: params.value })
          : "";

        const tooltipText = params.value ? stripHTMLTags({ transcript }) : "";
        return (
          <Tooltip title={tooltipText}>
            <TranscriptText
              transcript={transcript}
              variant="inherit"
              lineClamp={2}
            />
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Country",
      field: "country",
      width: 56,
      valueGetter(params) {
        return getPowerSearchResultCountry(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Market",
      field: "market",
      valueGetter(params) {
        return getPowerSearchResultMarket(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Source",
      field: "source",
      valueGetter(params) {
        return getPowerSearchResultSource(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Language",
      field: "language",
      valueGetter(params) {
        return getPowerSearchResultLanguage(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Duplicates",
      field: "duplicates",
      align: "center",
      renderCell(params) {
        const eventId = getPowerSearchResultId(params.row);
        const duplicatesIds = getPowerSearchResultDuplicates(params.row);
        const itemSelectedDuplicates = duplicatesIds.filter((id) =>
          selectedDuplicatesIds.includes(id)
        );
        return (
          !!duplicatesIds.length && (
            <DuplicatesChip
              count={duplicatesIds.length}
              selectedCount={itemSelectedDuplicates.length}
              onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
            />
          )
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Category",
      field: "category",
      valueGetter(params) {
        return getPowerSearchResultCategory(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Views",
      headerAlign: "center",
      field: "views",
      align: "center",
      type: "number",
      valueGetter(params) {
        return getPowerSearchResultViews(params.row);
      },
      valueFormatter({ value }) {
        if (value === undefined) {
          return "";
        }

        if (Number.isNaN(value)) {
          return "-";
        }

        return value.toLocaleString();
      },
    },
    {
      ...commonCellParams,
      headerName: "Actions",
      headerAlign: "center",
      field: "actions",
      align: "center",
      type: "actions",
      cellClassName: WatchTermResultsTableClasses.cellEventActions,
      renderCell(params) {
        return (
          <WatchTermResultsActions
            value={params.row}
            addItemToReport={addItemToReport}
            queryId={queryId}
          />
        );
      },
    },
  ];
}
