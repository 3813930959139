import { Fragment } from "react";
import { Button, Typography } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { EventDetails } from "src/models/EventDetails";
import { ClipEditorSaveDialogMobile } from "./ClipEditorSaveDialogMobile";

export const ClipEditorSaveButtonMobile = ({
  handleSaveToMediaCenter,
  handleDownload,
  disabled,
  event,
}: {
  handleSaveToMediaCenter: (clipTitle: string) => void;
  handleDownload: () => void;
  disabled?: boolean;
  event: Pick<EventDetails, "mediaDownloadExtension">;
}) => {
  const saveDialog = useOpenState();

  return (
    <Fragment>
      <Button variant="text" onClick={saveDialog.show} disabled={disabled}>
        <Typography variant="subtitle1" children="Save" />
      </Button>
      <ClipEditorSaveDialogMobile
        open={saveDialog.isOpen}
        onClose={saveDialog.hide}
        handleSaveToMediaCenter={handleSaveToMediaCenter}
        handleDownload={handleDownload}
        disabled={disabled}
        event={event}
      />
    </Fragment>
  );
};
