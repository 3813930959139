import { useCallback, useState } from "react";

export const useLocalStorage = (
  key: string
): [value: string | null, handleSetValue: (value: string) => void] => {
  const [value, setValue] = useState(localStorage.getItem(key));

  const handleSetValue = useCallback(
    (newValue: string) => {
      localStorage.setItem(key, newValue);
      setValue(newValue);
    },
    [key]
  );

  return [value, handleSetValue];
};
