import { CircularProgress, FormControlLabel, Stack } from "@mui/material";
import { TextInputBase } from "../TextInputBase";
import { useWatchQueryCloneFormContext } from "./utils/useWatchQueryCloneForm";
import { Controller } from "react-hook-form";
import { AppSwitch } from "../AppSwitch";

export function WatchQueryCloneForm({ isLoading }: { isLoading?: boolean }) {
  const { control } = useWatchQueryCloneFormContext();
  return (
    <Stack rowGap={1} mb={2} pt={1}>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              label="Title"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              fullWidth
              InputProps={{
                autoComplete: "off",
                endAdornment: isLoading ? (
                  <CircularProgress size={24} color="primary" />
                ) : null,
              }}
            />
          );
        }}
      />

      <Controller
        name="includeAlerts"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label="Include Alerts"
            sx={{ alignSelf: "flex-end" }}
            control={<AppSwitch disabled={isLoading} {...field} />}
          />
        )}
      />
    </Stack>
  );
}
