import { Box, Stack, Tooltip } from "@mui/material";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";

type AppChartLegendItem = {
  queryId?: string | null;
  queryTitle?: string | null;
};

export type AppChartLegendProps = {
  data?: AppChartLegendItem[] | null;
};

export function AppChartLegend({ data }: AppChartLegendProps) {
  const { getColorForId } = useAppChartColorMapper();

  return (
    <Stack
      minHeight={40}
      overflow="hidden"
      flexWrap="wrap"
      display="inline-grid"
      alignItems="flex-start"
      gridTemplateColumns={"fit-content(50%) fit-content(50%)"}
    >
      {data?.map(({ queryId, queryTitle }) => {
        const bgcolor: string = getColorForId(queryId).bg;
        return (
          <Stack
            direction="row"
            gap={1}
            key={queryId}
            alignItems="center"
            pr={2}
            minHeight={20}
          >
            <Box
              minWidth={8}
              minHeight={8}
              borderRadius={2}
              bgcolor={bgcolor}
            />
            <Tooltip title={queryTitle} key={queryId}>
              <TextLineClamp
                variant="caption"
                children={queryTitle}
                textOverflow="ellipsis"
                noWrap
                lineClamp={1}
              />
            </Tooltip>
          </Stack>
        );
      })}
    </Stack>
  );
}
