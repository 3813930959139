import axios from "axios";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { accountKey } from "./useAccount";
import { userDataKey } from "./useUserData";
import { apiSessionPath } from "./useSessionQuery";

const apiPath: keyof paths = "/api/User/{id}/entitlements";
const method: keyof paths[typeof apiPath] = "put";

type Endpoint = paths[typeof apiPath][typeof method];

type Parameters = Endpoint["parameters"];

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];

type RequestMutationFnParams = {
  body: RequestBody;
  params: Parameters;
};

type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type UseUserDataUpdate = Omit<
  UseMutationOptions<ResponseBody, unknown, RequestMutationFnParams>,
  "mutationFn"
>;

export function useUserEntitlementsUpdate({
  options,
}: {
  options?: UseUserDataUpdate;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<ResponseBody, unknown, RequestMutationFnParams>({
    ...options,
    mutationKey: ["user-general-data-update"],
    mutationFn: async ({ body, params }) => {
      const apiUrl = makeApiUrl(apiPath, {
        path: {
          id: params.path.id,
        },
      });

      const token = await getAccessTokenSilently();

      const response = await axios<ResponseBody>(apiUrl, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        `User “${data.firstName} ${data.lastName}” was successfully changed`,
        {
          variant: "success",
        }
      );
      queryClient.refetchQueries({
        queryKey: accountKey,
      });
      queryClient.refetchQueries({
        queryKey: userDataKey,
      });
      queryClient.refetchQueries({
        queryKey: apiSessionPath,
      });

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
