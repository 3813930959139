import axios from "axios";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/broadcaststation/{station}/time";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Omit<
  Endpoint["responses"]["200"]["content"]["application/json"],
  "timeZone"
> & {
  ianaTimeZone?: string;
};

export type StationTimeData = Response;
export const stationTimeQueryKey = apiPath;

type UseStationTimeArgs = {
  params: Endpoint["parameters"];
  options?: Pick<UseQueryOptions<Response>, "onSuccess" | "onError">;
};

export function useStationTime({ params, options }: UseStationTimeArgs) {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!params.path.station) {
      queryClient.resetQueries(stationTimeQueryKey);
    }
  }, [queryClient, params.path.station]);

  return useQuery<Response>({
    keepPreviousData: true,
    enabled: !!params.path.station,
    ...options,
    queryKey: [stationTimeQueryKey, params],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiPath, params);

      const { data } = await axios<Response>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        ...data,
        ianaTimeZone: data.ianaTimeZone ?? undefined,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
