import { forwardRef, useMemo } from "react";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { isClusterQuery } from "src/utils/isClusterQuery";
import { AppAutocomplete } from "../AppAutocomplete/AppAutocomplete";
import { ClusterOption, ClusterTagPickerProps } from "./ClusterTagPicker.model";

export const ClusterTagPicker = forwardRef<HTMLElement, ClusterTagPickerProps>(
  ({ customOptions = [], ...props }, ref) => {
    const watchList = useWatchQueryList({
      params: {
        query: {
          from: 0,
          size: 9999,
        },
      },
    });

    const options: ClusterOption[] = useMemo(() => {
      const availableClusters =
        watchList.data?.results?.filter(isClusterQuery).map((item) => ({
          id: item.id,
          title: item.title,
        })) || [];

      return [...customOptions, ...availableClusters];
    }, [customOptions, watchList.data?.results]);

    return (
      <AppAutocomplete<ClusterOption>
        {...props}
        ref={ref}
        options={options}
        getOptionLabel={(o) => o.title}
        getOptionKey={(o) => `${o.id}`}
        getOptionFromInput={(inputValue) =>
          inputValue
            ? {
                id: null,
                title: inputValue,
              }
            : null
        }
      />
    );
  }
);
