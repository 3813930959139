import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useInfiniteQuery, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { UsersManagementResponse } from "./useUsersManagement";

const apiKey: keyof paths = "/api/Users/management-view";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

type RequestParams = {
  params: {
    query: Omit<Endpoint["parameters"]["query"], "from" | "size">;
  };
  body: RequestBody;
};
export const userManagementInfiniteKey = "userManagementInfiniteKey";

const pageSize = 24;

export function useUsersManagementInfinite({
  request,
  options,
}: {
  request: RequestParams;
  options?: UseQueryOptions<UsersManagementResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useInfiniteQuery<UsersManagementResponse, unknown>({
    queryKey: [userManagementInfiniteKey, request],
    keepPreviousData: true,
    initialData: () => {
      const cacheWatchListResults =
        queryClient.getQueryData<UsersManagementResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchListResults || {};
      const pages: UsersManagementResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = (results || []).slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;

      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl(apiKey, {
        query: {
          from,
          size,
          ...request.params.query,
        },
      });

      const { data } = await axios<UsersManagementResponse>(apiPath, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request.body,
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
