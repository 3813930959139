import { useState } from "react";
import { Box, Paper, PaperProps, createFilterOptions } from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { useMediaCenterTagAppend } from "src/api/useMediaCenterTagAppend";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useMediaCenterTagAppendSeveral } from "src/api/useMediaCenterTagAppendSeveral";
import { TagListDataItem } from "src/api/useTagListData";
import { MediaCenterTagsAutocomplete } from "../MediaCenterTagsAutocomplete/MediaCenterTagsAutocomplete";

type AssignNewTagModalProps = {
  value: MediaCenterItem | string[];
  open: boolean;
  onClose: () => void;
};

export const tagsAutocompletefilter = createFilterOptions<TagListDataItem>();

export const TagsAutocompleteCustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

export const AssignNewTagModal = ({
  value,
  open,
  onClose,
}: AssignNewTagModalProps) => {
  const [tagName, setTagName] = useState<TagListDataItem[]>([]);

  const handleClose = () => {
    setTagName([]);
    onClose();
  };

  const appendTag = useMediaCenterTagAppend({
    options: {
      onSettled: handleClose,
    },
  });
  const appendSeveralItemTag = useMediaCenterTagAppendSeveral({
    options: {
      onSettled: handleClose,
    },
  });

  const handleAppendTags = () => {
    if (tagName.length === 0) return;

    const tags = tagName.map((v) => v.tagName);

    if (Array.isArray(value)) {
      return appendSeveralItemTag.mutate({
        body: {
          itemIds: value,
          tags: tags,
        },
      });
    }

    appendTag.mutate({
      path: {
        itemid: value.id,
      },
      body: tags,
    });
  };

  const content = (
    <Box py={1}>
      <MediaCenterTagsAutocomplete
        onChange={setTagName}
        value={tagName}
        isAbleToCreateNewTags
      />
    </Box>
  );

  return (
    <DialogPrompt
      title="Assign new tag"
      open={open}
      onDecline={handleClose}
      onConfirm={handleAppendTags}
      confirmLabel="Ok"
      children={content}
    />
  );
};
