import {
  useState,
  useCallback,
  ChangeEvent,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { UseInfiniteQueryResult } from "react-query";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BooleanParam, useQueryParam } from "use-query-params";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SearchInput } from "src/components/SearchInput";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DateRange } from "src/components/DateSelector/DateSelector";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useListFilter } from "src/utils/useListFilter";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { useEventSelectionFromPreselected } from "src/api/useEventSelectionFromPreselected";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { useReportEventsDataView } from "../ReportEvents/ReportEvents.hook";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function PreselectedEvents({
  isLoading,
  events,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  sortOption,
  setSortOption,
  setSortBy,
  setOrderBy,
  dateRange,
  setDateRange,
  reportEventsTiles,
}: {
  isLoading: boolean;
  events: PowerSearchResultItem[];
  rowCount: number;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  sortOption: SortBy;
  setSortOption: Dispatch<SetStateAction<SortBy>>;
  setSortBy: Dispatch<SetStateAction<SortByParameters>>;
  setOrderBy: Dispatch<SetStateAction<OrderByParameters>>;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  reportEventsTiles: UseInfiniteQueryResult;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.table;
  const [viewMode, setViewMode] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });

  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);
  const [filterText, setFilterText] = useState("");

  const { areAllPagesSelected } = useEventsSelectedForReports();
  const selectionState = useEventSelectionFromPreselected();

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortOption, setSortBy, setOrderBy]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [sortOption, onSortChange, isMobile]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewMode}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [viewMode, setViewMode, isMobile]
  );

  const filterData = useListFilter(events, filterText, ["title", "highlights"]);

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: selectionState.list,
  });

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectionState.selectAll(filterData);
      } else {
        selectionState.unselectAll();
      }
    },
    [filterData, selectionState]
  );

  const allCheckboxSelector = useMemo(
    () =>
      [ViewMode.tile, ViewMode.list].includes(viewMode) && (
        <AllCheckboxSelector
          checked={isAllSelected || isPartiallySelected}
          isPartiallySelected={isPartiallySelected}
          onChange={handleChangeAllCheckbox}
          sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
        />
      ),
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
      />
    ),
    [isMobile, setFilterText]
  );

  const toolbarDesktop = useMemo(
    () => (
      <Stack
        p={2}
        gap={2}
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row">
          {allCheckboxSelector}
          <Stack sx={{ justifyContent: "flex-end", mr: 2 }}>
            {searchInput}
          </Stack>
          <DateTimeSelector
            mode={DateTimeSelectorMode.variable}
            views={["year", "month", "day", "hours", "minutes"]}
            value={dateRange}
            onChange={setDateRange}
            sx={{ flex: 1 }}
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {!areAllPagesSelected && sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [
      allCheckboxSelector,
      searchInput,
      dateRange,
      setDateRange,
      areAllPagesSelected,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack>
        <Stack sx={{ mx: 2, mt: 1 }}>{searchInput}</Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-end"
          justifyContent="space-between"
          sx={{
            height: 40,
            m: 2,
            mx: 1,
          }}
        >
          <Box>{allCheckboxSelector}</Box>
          <Stack flexDirection="row" gap={1}>
            {sortingMenu}
            <DateTimeRangePickerMobile
              value={dateRange}
              onChange={setDateRange}
              iconButtonSize="small"
            />
            {viewModeSwitch}
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      searchInput,
      allCheckboxSelector,
      sortingMenu,
      dateRange,
      setDateRange,
      viewModeSwitch,
    ]
  );

  const renderMobileHeader = useCallback(() => {
    return toolbarMobile;
  }, [toolbarMobile]);

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    isExpandedTextMode,
    isLoading,
    selectionState,
    rowCount,
    compactHorizontalPadding: isMobile ? true : false,
    paginationModel,
    onPaginationModelChange,
    reportEventsTiles,
    renderHeader: renderMobileHeader,
  });

  const toolbar = useMemo(() => {
    if (isMobile) {
      return viewMode === ViewMode.tile ? null : toolbarMobile;
    }
    return toolbarDesktop;
  }, [isMobile, viewMode, toolbarMobile, toolbarDesktop]);

  return (
    <>
      {toolbar}
      <Stack flex={1} overflow="hidden">
        {dataViewElement}
      </Stack>
    </>
  );
}
