import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { ReportItem } from "src/models/Report";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { useReportDelete } from "src/api/useReportDelete";
import { useReportDeleteSnackbar } from "src/api/useReportDeleteSnackbar";

type ReportDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  values: ReportItem[];
};

export const ReportDeleteDialog = ({
  onClose,
  values,
  ...props
}: ReportDialogProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const reportDeleteSnackbar = useReportDeleteSnackbar();

  const deleteMutation = useReportDelete({
    options: {
      onSuccess: (_, variables) => {
        onClose && onClose();
        reportDeleteSnackbar.show(variables.title);
      },
    },
  });

  const handleDelete = () => {
    values.forEach((value) => {
      deleteMutation.mutate({
        params: {
          path: { id: value.id },
        },
        title: value.title || "",
        isMobile,
      });
    });
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      title={
        values.length === 1 ? "Delete the report?" : "Delete selected reports?"
      }
      children={
        <Box pb={1}>
          {values.length === 1
            ? `Do you want to delete the "${values[0].title}" report?`
            : "Do you want to delete selected reports?"}
        </Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      isLoading={deleteMutation.isLoading}
      {...props}
    />
  );
};
