import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDebounce } from "use-debounce";
import { queryAdvertisementValues } from "src/models/Advertisement";
import {
  emptySharedValues,
  PowerSearchSharedValues,
  usePowerSearchSharedForm,
} from "src/api/usePowerSearchSharedForm";
import { useValidateCustomQueryBasic } from "src/api/useValidateCustomQueryBasic";
import { useCustomQueryValidationData } from "src/api/useCustomQueryValidationData";
import { PowerSearchCustomQueryFormValues } from "./PowerSearchCustomQueryForm.model";

export const emptyPowerSearchCustomQueryFormValues: PowerSearchCustomQueryFormValues =
  {
    customQuery: "",
    displayName: "",
    startDateTime: null,
    endDateTime: null,
    sourcesInclude: {
      list: [],
      logic: "or",
    },
    sourcesExclude: {
      list: [],
      logic: "or",
    },
    advertisement: queryAdvertisementValues.all,
    programInclude: {
      list: [],
      logic: "or",
    },
    programExclude: {
      list: [],
      logic: "or",
    },
  };

type UseWatchQueryFormArgs = {
  initial?: Partial<PowerSearchCustomQueryFormValues>;
  schema: Joi.Schema;
};

export function usePowerSearchCustomQueryForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<PowerSearchCustomQueryFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: {
      ...emptyPowerSearchCustomQueryFormValues,
      ...initial,
    },
  });

  const { formState, watch, setValue, setError, trigger, clearErrors } =
    formHook;

  const { dirtyFields } = formState;

  const { saveSharedValue } = usePowerSearchSharedForm();
  const sharedValues = Object.keys(emptySharedValues);

  const { customQuery } = watch();
  const [debouncedCustomQuery] = useDebounce(customQuery, 500);

  const basicValData = useValidateCustomQueryBasic({
    customQuery: debouncedCustomQuery || "",
  });

  useEffect(() => {
    if (
      (basicValData.data && !basicValData.data?.isValid) ||
      basicValData.isLoading
    ) {
      setError("customQuery", {
        type: "custom",
      });
    }
    if (basicValData.data?.isValid) {
      clearErrors("customQuery");
      trigger();
    }
  }, [
    setError,
    basicValData.data,
    basicValData.isLoading,
    clearErrors,
    trigger,
  ]);

  const { validationData } = useCustomQueryValidationData();

  useEffect(() => {
    if (!validationData.isValid) {
      setError("customQuery", {
        type: "custom",
        message: validationData.message || "",
      });
    } else {
      clearErrors("customQuery");
      trigger();
    }
  }, [
    setError,
    validationData.isValid,
    validationData.message,
    clearErrors,
    trigger,
  ]);

  useEffect(() => {
    if (
      validationData.isValid &&
      validationData.titleSuggestions?.length &&
      !dirtyFields.displayName
    ) {
      const suggestedDisplayName = validationData.titleSuggestions[0];
      if (suggestedDisplayName) {
        setValue("displayName", suggestedDisplayName, { shouldValidate: true });
      }
    }
  }, [validationData, dirtyFields.displayName, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "endDateTime") {
        trigger("startDateTime");
      }

      if (
        type === "change" &&
        sharedValues.includes(name as keyof PowerSearchSharedValues)
      ) {
        const itemValue = value[name as keyof PowerSearchSharedValues];
        saveSharedValue(
          name as keyof PowerSearchSharedValues,
          itemValue as PowerSearchSharedValues[keyof PowerSearchSharedValues]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, saveSharedValue, sharedValues]);

  return formHook;
}
