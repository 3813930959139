import { Fragment } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputBase } from "src/components/TextInputBase";
import { ReportCreateFormValues } from "./ReportCreateForm.model";

export function ReportCreateForm({ isBusy }: { isBusy: boolean }) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { control } = useFormContext<ReportCreateFormValues>();

  return (
    <Fragment>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              id="report-title"
              label="Report title"
              fullWidth
              placeholder="Report title"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              disabled={isBusy}
              sx={{
                mb: { xs: 1, md: 2.5 },
              }}
            />
          );
        }}
      />

      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextInputBase
              id="report-description"
              label="Description"
              fullWidth
              multiline
              rows={isMobile ? 4 : 3}
              placeholder="Description"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!fieldState.error}
              helperText={
                fieldState.error?.message || `${field.value?.length}/600`
              }
              disabled={isBusy}
            />
          );
        }}
      />
    </Fragment>
  );
}
