import { CloseFullscreen, Fullscreen } from "@mui/icons-material";
import { Fab, IconButtonProps } from "@mui/material";

export type ClipViewDisplayFABProps = Omit<IconButtonProps, "children"> & {
  collapsed?: boolean;
};

export function ClipViewDisplayFAB({
  collapsed,
  ...props
}: ClipViewDisplayFABProps) {
  return (
    <Fab
      size="medium"
      {...props}
      children={collapsed ? <Fullscreen /> : <CloseFullscreen />}
    />
  );
}
