import {
  Box,
  BoxProps,
  Checkbox,
  svgIconClasses,
  useTheme,
} from "@mui/material";

export function TileCheckBox({
  left = 0,
  top = 0,
  width = 42,
  height = 42,
  position = "absolute",
  onChange,
  value,
  disabled = false,
  ...props
}: {
  top?: BoxProps["top"];
  left?: BoxProps["left"];
  width?: BoxProps["width"];
  height?: BoxProps["height"];
  position?: BoxProps["position"];
  onChange: (value: boolean) => void;
  value: boolean;
  zIndex?: BoxProps["zIndex"];
  disabled?: boolean;
}) {
  const { palette } = useTheme();
  return (
    <Box
      top={top}
      left={left}
      width={width}
      height={height}
      position={position}
      {...props}
    >
      <Checkbox
        checked={value}
        onChange={(_e, value) => onChange(value)}
        sx={{
          [`.${svgIconClasses.root}`]: {
            background: palette.background.paper,
            borderRadius: 1,
          },
        }}
        disabled={disabled}
      />
    </Box>
  );
}
