import { StackProps } from "@mui/material";
import { create } from "zustand";

type ClipViewLayoutData = {
  main: {
    flex: StackProps["flex"];
    flexBasis: StackProps["flex"];
  };
  right: {
    flex: StackProps["flex"];
    flexBasis: StackProps["flex"];
  };
};

export const useClipViewLayout = create<ClipViewLayoutData>(() => {
  return {
    main: {
      flex: 2,
      flexBasis: 760,
    },
    right: {
      flex: 1,
      flexBasis: 368,
    },
  };
});
