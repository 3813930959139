import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { useIsMobile } from "src/utils/useIsMobile";
import { useStationTime } from "src/api/useStationTime";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";
import { DateTimeSearchForm } from "./DateTimeSearchForm/DateTimeSearchForm";
import { dateTimeSearchResultsRoute } from "../DateTimeSearchResults/DateTimeSearchResults.route";

export const DateTimeSearchInitialPage = () => {
  const navigate = useNavigate();
  const [maxDateTime] = useState(new Date());
  const isMobile = useIsMobile();
  const formHook = useFormContext<DateTimeSearchFormValues>();
  const formValues = formHook.watch();

  const gotoDateTimeSearchResults = () => {
    const url = dateTimeSearchResultsRoute.makeUrl();
    navigate(url);
  };

  const { station } = formValues;

  const stationTime = useStationTime({
    params: {
      path: {
        station: station?.value ?? "",
      },
    },
  });

  const { ianaTimeZone } = stationTime.data || {};

  const contentInitial = (
    <Stack
      my="auto"
      px={{ xs: 2, sm: 0 }}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h5" align="center" mb={3}>
        Date/Time Search
      </Typography>

      <DateTimeSearchForm
        maxDateTime={maxDateTime}
        timeZone={ianaTimeZone}
        isLoading={stationTime.isFetching}
        onSubmit={gotoDateTimeSearchResults}
      />

      <Typography
        variant="h6"
        color="text.secondary"
        align="center"
        mt={isMobile ? 5 : 12}
        mb={5}
        maxWidth={560}
        alignSelf="center"
      >
        Search for an event{isMobile && <br />} at a date/time
        <Typography
          variant="body1"
          children="Select Market, Stations, and Date with Time to see what event on TV."
        />
      </Typography>
    </Stack>
  );

  if (isMobile) {
    return <PageLayoutMobile content={<Fragment>{contentInitial}</Fragment>} />;
  }

  return <PageLayoutDesktop content={contentInitial} overflow="hidden" />;
};
