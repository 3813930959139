import { UIEventHandler, useCallback, useMemo } from "react";
import { LinearProgress, Stack, useTheme } from "@mui/material";
import {
  MarketSnapshot,
  StationSnapshot,
  useSnapshotInfinite,
} from "src/api/useSnapshot";
import { SnapshotsNoEventsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { groupSnapshotsByMarketId } from "src/utils/groupSnapshotsByMarketId";
import { useIsFullScreen } from "src/utils/useIsFullScreen";
import { scrollBoxClasses } from "src/components/ScrollBox/ScrollBox.model";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { SnapshotTilesProps } from "./SnapshotTiles.model";
import { SnapshotTile } from "./SnapshotTile";
import { SnapshotTileGroup } from "../SnapshotTileGroup/SnapshotTileGroup";
import { snapshotTileGroupConstants } from "../SnapshotTileGroup/SnapshotTileGroup.const";

const delta = 200;
const stickyHeaderHeight = 32;

export function SnapshotTiles({
  viewMode,
  sources,
  sortBy,
  orderBy,
}: SnapshotTilesProps) {
  const isFullScreen = useIsFullScreen();
  const { palette } = useTheme();
  const snapshotsResults = useSnapshotInfinite({
    options: {
      enabled: !!sources,
    },
    params: {
      body: sources,
      query: {
        sortby: sortBy,
        orderby: orderBy,
      },
    },
  });

  const { isLoading, isFetching, data: dataResults } = snapshotsResults;

  const handleScroll: UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    const { scrollTop, offsetHeight, scrollHeight } = currentTarget;

    if (!currentTarget || !snapshotsResults.hasNextPage) {
      return;
    }

    if (
      scrollHeight - offsetHeight - scrollTop > delta ||
      snapshotsResults.isLoading
    ) {
      return;
    }
    snapshotsResults.fetchNextPage();
  };

  const renderItem = useCallback(
    (data: StationSnapshot) => (
      <SnapshotTile key={data?.station?.id} value={data} />
    ),
    []
  );

  const renderGroup = useCallback(
    (snapshot: MarketSnapshot) => {
      const key = `${snapshot.marketInfo?.id}:${snapshot.stationSnapshots
        ?.map((id) => id)
        .join(",")}`;

      return (
        <SnapshotTileGroup
          key={key}
          value={snapshot}
          isFullScreen={isFullScreen}
          isLoading={isLoading || isFetching}
          viewMode={viewMode}
          headerHeight={stickyHeaderHeight}
          renderItem={renderItem}
        />
      );
    },
    [isFetching, isFullScreen, isLoading, viewMode, renderItem]
  );

  const snapshotGroups = useMemo(() => {
    const groupedSnapshots = groupSnapshotsByMarketId(snapshotsResults);
    return groupedSnapshots.map(renderGroup);
  }, [snapshotsResults, renderGroup]);

  const linearProgress = snapshotsResults.isFetching && (
    <LinearProgress
      color="secondary"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: snapshotTileGroupConstants.headerZIndex + 1,
      }}
    />
  );

  return (
    <Stack
      flex={1}
      position="relative"
      overflow="hidden"
      sx={{
        //FIXME: Workaround hiding scroll box shadow manually, useResizeObserver sometimes doesn't update when 1 result,
        [`& .${scrollBoxClasses.effectEnd}`]: {
          display: dataResults?.pages[0]?.total === 1 ? "none" : "inherit",
        },

        [`& .${scrollBoxClasses.scroll}::-webkit-scrollbar`]: {
          // By applying these styles to scrollbar I make top-most sticky header have a better look
          background: palette.background.default,
        },

        [`& .${scrollBoxClasses.scroll}::-webkit-scrollbar-track`]: {
          // By applying these styles to scrollbar I make top-most sticky header have a better look
          marginTop: `${stickyHeaderHeight}px`,
          background: palette.background.paper,
        },

        [`& .${scrollBoxClasses.effectStart}`]: {
          marginRight: "8px",
        },
      }}
    >
      {dataResults?.pages[0]?.total ? (
        <ScrollBox
          flex={1}
          shadowTopOffset={stickyHeaderHeight}
          onScroll={handleScroll}
          children={snapshotGroups}
        />
      ) : (
        <Stack
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SnapshotsNoEventsOverlay />
        </Stack>
      )}

      {linearProgress}
    </Stack>
  );
}
