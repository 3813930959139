import { Tab, alpha, tabClasses, useTheme } from "@mui/material";
import { AppTabProps } from "./AppTab.model";

/**
 * An extension of MUI Tab component that accepts children as tab content
 */
export function AppTab({ children, layout, ...props }: AppTabProps) {
  const { palette, typography } = useTheme();

  // MUI Tab component does not accept children
  // We use it in AppTabLayout to display tab content to keep API sleek
  return (
    <Tab
      {...props}
      sx={{
        ...(layout !== "desktop" && {
          borderTop: `1px solid ${palette.divider}`,
          borderBottom: `1px solid ${palette.divider}`,
          minHeight: 56,
          p: 0,
          textTransform: "capitalize",
          ...typography.caption,

          [`.${tabClasses.iconWrapper}`]: {
            mb: 0,
          },

          [`&.${tabClasses.selected}`]: {
            backgroundColor: alpha(palette.primary.main, 0.08),
          },
        }),
        ...props.sx,
      }}
    />
  );
}
