import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { reportScratchEventsKey } from "./useReportScratchEvents";
import { reportsListKey } from "./useReportList";
import { reportsListKeyInfinite } from "./useReportListInfinite";
import { reportScratchEventsInfiniteKey } from "./useReportScratchEventsInfinite";

const apiKey: keyof paths = "/api/Report/{id}/append";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
  events: PowerSearchResultItem[];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useReportEventsAppend({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ params, events }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, params);
      const body: RequestBody = events;

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: reportScratchEventsInfiniteKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsKey,
      });
      queryClient.refetchQueries({
        queryKey: reportsListKeyInfinite,
      });
      queryClient.refetchQueries({
        queryKey: reportsListKey,
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
    onSettled: () => {
      queryClient.refetchQueries({
        queryKey: reportScratchEventsInfiniteKey,
      });
      queryClient.refetchQueries({
        queryKey: reportScratchEventsKey,
      });
    },
  });
}
