import clsx from "clsx";
import useResizeObserver from "@react-hook/resize-observer";
import {
  Fragment,
  UIEventHandler,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Stack, alpha, useTheme } from "@mui/material";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";
import { useForwardRef } from "src/utils/useForwardRef";
import { ScrollBoxProps, scrollBoxClasses } from "./ScrollBox.model";

/**
 * Component shows shadow effect when overflowed.
 * Uses custom scroll bars.
 */
export const ScrollBox = forwardRef<HTMLDivElement, ScrollBoxProps>(
  (
    {
      // inner paddings
      p,
      pl,
      pr,
      pt,
      pb,
      px,
      py,
      // inner margins
      m,
      ml,
      mr,
      mt,
      mb,
      mx,
      my,
      // inner layout
      alignItems,
      justifyContent,
      direction = "column",
      flexDirection,
      columnGap,
      rowGap,
      gap,
      //
      color,
      children,
      shadowColor,
      shadowSize = 32,
      shadowOpacity = 0.7,
      darkShadowOpacity = 0.5,
      shadowTopOffset = 0,
      shadowBottomOffset = 0,
      shadowLeftOffset = 0,
      shadowRightOffset = 0,
      //
      disableOverflowShadow,
      customScrollBarStyle = "normal",
      itemsRowGap,
      onScroll,
      scrollbarColor,
      //
      ...outerProps
    },
    outerRef
  ) => {
    const { palette } = useTheme();
    const isHorizontal = direction === "row";
    const isVertical = !isHorizontal;

    // TODO: for now only one axis can be scrolled
    // need to test if that works with two axis scroll
    const overflowX = isHorizontal ? "scroll" : "hidden";
    const overflowY = isHorizontal ? "hidden" : "scroll";

    const scrollStyleSx = useCustomScrollBarStyles({
      overflowX,
      overflowY,
      size: customScrollBarStyle,
      scrollbarColor,
    });

    const defaultShadowColor =
      palette.mode === "light"
        ? alpha(palette.common.white, shadowOpacity)
        : alpha(palette.common.black, darkShadowOpacity);

    const boxShadowColor = shadowColor
      ? alpha(shadowColor, shadowOpacity)
      : defaultShadowColor;

    const scrollContainerRef = useForwardRef(outerRef);
    const [size, setSize] = useState<ResizeObserverEntry>();

    useResizeObserver(scrollContainerRef, setSize);

    const [scrollTop, setScrollTop] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scrollWidth = size?.target.scrollWidth ?? 0;
    const scrollHeight = size?.target.scrollHeight ?? 0;
    const clientWidth = size?.target.clientWidth ?? 0;
    const clientHeight = size?.target.clientHeight ?? 0;

    const parentPaddingTop: string = scrollContainerRef.current?.parentElement
      ? getComputedStyle(
          scrollContainerRef.current.parentElement
        ).getPropertyValue("padding-top")
      : "0";

    const parentPaddingBottom: string = scrollContainerRef.current
      ?.parentElement
      ? getComputedStyle(
          scrollContainerRef.current.parentElement
        ).getPropertyValue("padding-top")
      : "0";

    const parentPaddingLeft: string = scrollContainerRef.current?.parentElement
      ? getComputedStyle(
          scrollContainerRef.current.parentElement
        ).getPropertyValue("padding-left")
      : "0";

    const parentPaddingRight: string = scrollContainerRef.current?.parentElement
      ? getComputedStyle(
          scrollContainerRef.current.parentElement
        ).getPropertyValue("padding-right")
      : "0";

    const top = `calc(
      ${parentPaddingTop} + 
      ${shadowTopOffset}px + 
      ${Math.min(scrollTop - shadowSize, 0)}px
    )`;

    const scrollBottom = scrollTop + clientHeight;
    const bottom = `calc(
      ${parentPaddingBottom} + 
      ${shadowBottomOffset}px + 
      ${Math.min(-(shadowSize + scrollBottom - scrollHeight), 0)}px
    )`;

    const left = `calc(
      ${parentPaddingLeft} + 
      ${shadowLeftOffset}px +
      ${Math.min(-shadowSize + scrollLeft, 0)}px
    )`;

    const scrollRight = scrollLeft + clientWidth;
    const right = `calc(
      ${parentPaddingRight} +
      ${shadowRightOffset}px +
      ${Math.min(0, -(shadowSize + scrollRight - scrollWidth))}px
    )`;

    const isEmpty = isVertical
      ? scrollHeight <= clientHeight
      : scrollWidth <= clientWidth;

    const onScrollWrapper: UIEventHandler<HTMLDivElement> = (e) => {
      setScrollTop(e.currentTarget.scrollTop);
      setScrollLeft(e.currentTarget.scrollLeft);

      onScroll && onScroll(e);
    };

    useEffect(() => {
      const { scrollTop = 0, scrollLeft = 0 } =
        scrollContainerRef.current || {};

      setScrollTop(scrollTop);
      setScrollLeft(scrollLeft);
    }, [scrollContainerRef]);

    const shadows = useMemo(
      () =>
        !disableOverflowShadow &&
        !isEmpty && (
          <Fragment>
            <Box className={scrollBoxClasses.effectStart} />
            <Box className={scrollBoxClasses.effectEnd} />
          </Fragment>
        ),
      [disableOverflowShadow, isEmpty]
    );

    const startDegree = isVertical ? "180deg" : "90deg";
    const endDegree = isVertical ? "0deg" : "270deg";

    return (
      <Stack
        ref={outerRef}
        direction={direction}
        position="relative"
        overflow="hidden"
        {...outerProps}
        className={clsx(scrollBoxClasses.root, outerProps.className)}
        sx={{
          [`& .${scrollBoxClasses.effectStart}`]: {
            position: "absolute",
            pointerEvents: "none",
            background: `linear-gradient(${startDegree}, ${boxShadowColor}, transparent)`,

            height: isVertical ? shadowSize : undefined,
            width: isVertical ? undefined : shadowSize,

            top: isVertical ? top : 0,
            right: isVertical ? 0 : undefined,
            left: isHorizontal ? left : 0,
            bottom: isHorizontal ? 0 : undefined,
          },
          [`& .${scrollBoxClasses.effectEnd}`]: {
            position: "absolute",
            pointerEvents: "none",
            background: `linear-gradient(${endDegree}, ${boxShadowColor}, transparent)`,

            height: isVertical ? shadowSize : undefined,
            width: isVertical ? undefined : shadowSize,

            bottom: isVertical ? bottom : 0,
            top: isHorizontal ? 0 : undefined,
            left: isVertical ? 0 : undefined,
            right: isHorizontal ? right : 0,
          },
          ...outerProps.sx,
        }}
      >
        <Stack
          ref={scrollContainerRef}
          position="relative"
          // internal paddings
          p={p}
          px={px}
          py={py}
          pl={pl}
          pr={pr}
          pt={pt}
          pb={pb}
          // internal margins
          m={m}
          mx={mx}
          my={my}
          ml={ml}
          mr={mr}
          mt={mt}
          mb={mb}
          //
          direction={direction}
          flexDirection={flexDirection}
          columnGap={columnGap}
          rowGap={rowGap}
          gap={gap}
          alignItems={alignItems}
          justifyContent={justifyContent}
          //
          flex={1}
          children={children}
          onScroll={onScrollWrapper}
          className={clsx(scrollBoxClasses.scroll)}
          sx={{
            rowGap: itemsRowGap,
            ...scrollStyleSx,
            // be careful when adding new styles as this may overwrite scrollStyleSx
          }}
        />

        {shadows}
      </Stack>
    );
  }
);
