import { useCallback } from "react";
import { Box } from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";

type ReportEventsDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  isAllSelected: boolean;
  events: PowerSearchResultItem[];
};

export const ReportCreateEventsDeleteDialog = ({
  onClose,
  isAllSelected,
  events,
  ...props
}: ReportEventsDeleteDialogProps) => {
  const eventsSelectedForReports = useEventsSelectedForReports();

  const handleDelete = useCallback(() => {
    if (isAllSelected) {
      eventsSelectedForReports.unselectAll();
    } else {
      eventsSelectedForReports.unselectSome(events);
    }
    onClose && onClose();
  }, [isAllSelected, eventsSelectedForReports, events, onClose]);

  return (
    <DialogPrompt
      title={
        events.length === 1 ? "Delete the event?" : "Delete selected events?"
      }
      children={
        <Box pb={1}>
          {events.length === 1
            ? `Do you want to delete the "${events[0].title}" item?`
            : "Do you want to delete selected events?"}
        </Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      {...props}
    />
  );
};
