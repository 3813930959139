import { endOfDay, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { BarChart, DateRange as DateRangeIcon } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { TextInputBase } from "src/components/TextInputBase";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { WatchQueryPicker } from "src/components/WatchQueryPicker/WatchQueryPicker";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { isNormalQuery } from "src/utils/isNormalQuery";
import { useIsMobile } from "src/utils/useIsMobile";
import { useQueryDateTimeRangeAnalyticsLite } from "src/utils/useQueryDateTimeRangeAnalyticsLite";
import { useQueryParamList } from "src/utils/useQueryParamList";
import { AnalyticsLiteDateTimeRangePickerActions } from "./components/AnalyticsLiteDateTimeRangePickerActions";

export function AnalyticsLiteEmptyPage() {
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const { releaseColors } = useAppChartColorMapper();
  const [queryIds, setQueryIds] = useQueryParamList({ key: "queryIds" });
  const [activeQueryIds, setActiveQueryIds] = useState<string[]>(queryIds);

  const [dateTimeRange, setDateTimeRange] = useQueryDateTimeRangeAnalyticsLite({
    updateType: "replaceIn",
  });

  // get watch queries for picking analytics for
  const watchQueryListData = useWatchQueryList({});
  const watchQueryList = useMemo(
    () => watchQueryListData.data?.results?.filter(isNormalQuery) ?? [],
    [watchQueryListData.data?.results]
  );

  const activeQueries = useMemo(
    () => watchQueryList.filter(({ id }) => activeQueryIds.includes(id)),
    [activeQueryIds, watchQueryList]
  );

  const watchTermInput = (
    <WatchQueryPicker
      label="Select Watch Term"
      value={activeQueries}
      options={watchQueryList}
      openOnFocus
      disableCloseOnSelect
      onChange={(nextWatchQueries) => {
        const nextActiveQueryIds = nextWatchQueries.map(({ id }) => `${id}`);
        setActiveQueryIds((prevActiveQueryIds) => {
          const removed =
            prevActiveQueryIds?.filter(
              (id) => !nextActiveQueryIds.includes(`${id}`)
            ) || [];

          releaseColors(removed);

          return nextActiveQueryIds;
        });
      }}
    />
  );

  const dateRangePicker = (
    <DateRangePicker
      calendars={2}
      value={dateTimeRange}
      onChange={(date) =>
        setDateTimeRange([
          // ensure proper day bounds
          date[0] ? startOfDay(date[0]) : null,
          date[1] ? endOfDay(date[1]) : null,
        ])
      }
      localeText={{ start: "Start date", end: "End date" }}
      format="PP"
      disableFuture
      slots={{
        fieldSeparator: () => null,
        actionBar: (actionBarProps) => (
          <AnalyticsLiteDateTimeRangePickerActions
            setDateTimeRange={setDateTimeRange}
            {...actionBarProps}
          />
        ),
        textField: TextInputBase,
      }}
      slotProps={{
        textField: {
          InputProps: {
            endAdornment: !isMobile && (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );

  const handleAnalyze = () => {
    setQueryIds(activeQueryIds);
  };

  const mobileContent = (
    <Stack
      my="auto"
      children={
        <Stack px={2} rowGap={4}>
          <Typography variant="h5" align="center">
            Data Analysis
          </Typography>
          {watchTermInput}
          {dateRangePicker}
          <Button
            children="Analyze"
            variant="contained"
            startIcon={<BarChart />}
            disabled={!activeQueryIds.length}
            onClick={handleAnalyze}
            sx={{
              height: 56,
            }}
          />
          <Typography
            variant="h6"
            align="center"
            color={palette.text.secondary}
          >
            Analyzing data within a defined date range
          </Typography>

          <Typography
            variant="body1"
            align="center"
            color={palette.text.secondary}
          >
            To get started with your analysis, please select up to&nbsp;
            <strong>6 watch terms</strong> and specify the start and end dates
            for your analysis period.
          </Typography>
        </Stack>
      }
    />
  );

  if (isMobile) {
    return <PageLayoutMobile content={mobileContent} />;
  }

  return (
    <PageLayoutDesktop
      content={
        <Stack my="auto">
          <Typography variant="h5" align="center">
            Analyze and Compare Mentions and Reach
          </Typography>

          <Stack
            direction={{ xs: "column", lg: "row" }}
            mt={5}
            mb={10}
            columnGap={2}
            rowGap={6}
          >
            <Stack width={{ xs: "100%", lg: 375 }} flexGrow={1}>
              {watchTermInput}
            </Stack>

            {dateRangePicker}

            <Button
              children="Analyze"
              variant="contained"
              startIcon={<BarChart />}
              disabled={!activeQueryIds.length}
              onClick={handleAnalyze}
              sx={{
                width: { lg: 170 },
                height: 56,
              }}
            />
          </Stack>
          <Stack rowGap={2}>
            <Typography
              variant="h6"
              align="center"
              color={palette.text.secondary}
            >
              Analyzing data within a defined date range
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color={palette.text.secondary}
            >
              To get started with your analysis, please select up to&nbsp;
              <strong>6 watch terms</strong>
              <br />
              and specify the start and end dates for your analysis period.
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
}
