import { useEffect, useMemo } from "react";
import { create } from "zustand";

type UseLocalStorageFlagsArgs = {
  storageKey: string;
};

type LocalFlagMap = {
  isExtendedEvent: boolean;
  drawerState: boolean;
};

type LocalFlagState = {
  write: (key: keyof LocalFlagMap, value: LocalFlagMap[typeof key]) => void;
  clear: () => void;
} & LocalFlagMap;

/**
 * Creates a global state that is automatically stored into local storage.
 */
export const useLocalStorageFlags = (args?: UseLocalStorageFlagsArgs) => {
  const { storageKey = "flags" } = args || {};

  const useState = useMemo(() => {
    const hook = create<LocalFlagState>((set) => {
      const json = localStorage.getItem(storageKey);
      const saved = json ? JSON.parse(json) : {};

      return {
        isExtendedEvent: false,
        drawerState: true,
        ...saved,
        write: (key, value) => {
          set({ [key]: value });
        },
        clear: () => localStorage.clear(),
      };
    });

    return hook;
  }, [storageKey]);

  // save state if changed
  const state = useState();
  const stateJson = JSON.stringify(state);

  useEffect(() => {
    localStorage.setItem(storageKey, stateJson);
  }, [stateJson, storageKey]);

  return state;
};
