import { makeQueryString } from "./makeQueryString";

type MakeApiUrlParams = {
  path?: void | Record<string, string | number | boolean>;
  query?: void | Record<string, string | number | boolean | undefined | null>;
};

export function makeApiUrl<T extends MakeApiUrlParams>(
  template: string,
  params?: T
): string {
  const keyRegEx = /{\w+}/gim;

  const keys = Array.from(template.matchAll(keyRegEx)).map((match) => match[0]);

  const path =
    keys.reduce((acc, templateKey) => {
      const paramKey = templateKey.slice(1, -1);
      const value = params?.path ? params.path[paramKey] : undefined;

      if (value === undefined) {
        throw new Error(`value for template key "${templateKey}" is undefined`);
      }

      return acc.replace(templateKey, `${value}`);
    }, template) || template;

  const queryString = params?.query ? makeQueryString(params.query) : "";
  const parts = [path, queryString].filter(Boolean).join("?");

  return parts;
}
