import { Paper, Stack, Typography } from "@mui/material";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { TileLayout } from "src/components/TileLayout/TileLayout";

const headerSize = 56;
const mockTileItems = [...new Array(8)].map((_value, index) => {
  return {
    value: index,
  };
});

function TilesWithStickyHeader({ header }: { header: string }) {
  return (
    <>
      <Stack
        top={0}
        bgcolor="wheat"
        position="sticky"
        children={
          <Typography variant="h4" children={header} justifyItems="center" />
        }
        zIndex={1}
        minHeight={headerSize}
        maxHeight={headerSize}
        justifyContent="center"
      />
      <TileLayout
        gap={2}
        rows={mockTileItems}
        renderItem={({ value }) => {
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              component={Paper}
              variant="outlined"
              width="100%"
              sx={{ aspectRatio: "1" }}
              children={<Typography variant="h1" children={value} />}
            />
          );
        }}
      />
    </>
  );
}

export function DevTileView() {
  return (
    <ScrollBox position="relative" shadowTopOffset={headerSize} height="100%">
      <TilesWithStickyHeader header="Header 1" />
      <TilesWithStickyHeader header="Header 2" />
    </ScrollBox>
  );
}
