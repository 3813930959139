import { forwardRef } from "react";
import { Paper, PaperProps, Stack } from "@mui/material";
import { Spinner } from "../Spinner/Spinner";

export const makeAutocompleteInlinePaperComponent = ({
  spinner,
}: {
  spinner?: boolean;
}) => {
  return forwardRef<HTMLDivElement, PaperProps>(
    ({ children, ...paperProps }, ref) => {
      return (
        <Paper {...paperProps} style={{ overflow: "hidden" }} ref={ref}>
          {spinner ? (
            <Stack alignItems="center" justifyContent="center" height={400}>
              <Spinner size={60} />
            </Stack>
          ) : (
            children
          )}
        </Paper>
      );
    }
  );
};
