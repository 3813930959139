import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { AccountsManagementCreateAccount } from "./AccountsManagementCreateAccount.page";

export const accountsManagementCreateAccountRoute = new AppRouteBuilder({
  path: "/accounts-management/create-account",
  element: (
    <PermissionGuard
      component={AccountsManagementCreateAccount}
      allowedRoles={["Admin", "Account Manager", "User Manager"]}
      disableMobile
      redirectTo="/"
      // silent
    />
  ),
});
