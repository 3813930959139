import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseQueryOptions, useQuery } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/user/query/validate/basic/custom-query";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export function useValidateCustomQueryBasic({
  customQuery,
  options,
}: {
  customQuery: RequestBody;
  options?: UseQueryOptions<Response, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<Response, unknown>({
    ...options,
    enabled: !!customQuery,
    queryKey: [apiKey, customQuery],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey);

      const response = await axios<Response>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(customQuery),
      });

      return response.data;
    },
  });
}
