import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  ArrowDropDownOutlined,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";

import {
  ButtonBase,
  IconButton,
  Menu,
  Stack,
  SxProps,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";

import {
  DateCalendar,
  DateCalendarProps,
  DateTimePickerProps,
} from "@mui/x-date-pickers-pro";
import { makeDateRange } from "src/utils/makeDateRange";
import { DateRange } from "./DateSelector";
import { AppDateTimePicker } from "../AppDateTimePicker/AppDateTimePicker";

export enum DateTimeSelectorMode {
  fixed = "fixed-range",
  variable = "variable-range",
}

// TODO: Why don't we use DateRange Picker from MUI-X ?
// https://mui.com/x/react-date-pickers/date-time-range-picker/
export function DateTimeSelector({
  mode,
  variant = "standard",
  value = [null, null],
  pagerDays = 7,
  pagerFormat = "PPP",
  views,
  onChange,
  isMobile,
  clearable,
  sx,
}: {
  mode: DateTimeSelectorMode;
  variant?: TextFieldProps["variant"];
  value?: DateRange;
  pagerDays?: number;
  pagerFormat?: string;
  views?: DateTimePickerProps<Date>["views"];
  onChange: (range: DateRange) => void;
  isMobile?: boolean;
  clearable?: boolean;
  sx?: SxProps;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { palette } = useTheme();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const [startDate, endDate] = value;

  useEffect(() => {
    if (mode === DateTimeSelectorMode.fixed) {
      if (startDate) {
        // set range to prevStartDate + pagerDays
        const nextRange: DateRange = makeDateRange(startDate, pagerDays);

        // compare serialized dates seems to be enough
        const prevRange = [startDate, endDate];
        if (nextRange.toString() !== prevRange.toString()) {
          onChange && onChange(nextRange);
        }
      }
    }
  }, [startDate, endDate, mode, pagerDays, onChange]);

  const setStartDate = (nextStartDate: Date | null) => {
    const nextRange: DateRange = [nextStartDate, value[1]];

    onChange && onChange(nextRange);
  };

  const setEndDate = (nextEndDate: Date | null) => {
    const nextRange: DateRange = [value[0], nextEndDate];

    onChange && onChange(nextRange);
  };

  const handlePrevDate = () => {
    if (!startDate || !endDate) {
      return;
    }

    const nextRange = makeDateRange(startDate, -pagerDays);
    onChange && onChange(nextRange);
  };

  const handleNextDate = () => {
    if (!startDate || !endDate) {
      return;
    }

    const nextRange = makeDateRange(endDate, +pagerDays);
    onChange && onChange(nextRange);
  };

  if (mode === DateTimeSelectorMode.variable) {
    return (
      <Stack
        display="flex"
        direction="row"
        alignItems="flex-end"
        spacing={3}
        sx={sx}
      >
        <AppDateTimePicker
          label="Start date"
          value={startDate}
          maxDateTime={endDate ? endDate : undefined}
          onChange={setStartDate}
          views={views}
          slotProps={{
            field: {
              clearable,
            },
            textField: {
              variant,
              fullWidth: true,
            },
          }}
          sx={{
            flex: "1 1 50%",
          }}
        />
        <AppDateTimePicker
          label="End date"
          value={endDate}
          onChange={setEndDate}
          views={views}
          minDateTime={startDate ? startDate : undefined}
          slotProps={{
            field: {
              clearable,
            },
            textField: {
              variant,
              fullWidth: true,
            },
          }}
          sx={{
            flex: "1 1 50%",
          }}
        />
      </Stack>
    );
  }

  const showCalendar = () => setIsCalendarVisible(true);
  const hideCalendar = () => setIsCalendarVisible(false);

  const handleCalendarChange: DateCalendarProps<Date>["onChange"] = (
    nextEndDate
  ) => {
    hideCalendar();
    if (!nextEndDate) {
      onChange && onChange([null, null]);
    } else {
      const nextRange = makeDateRange(nextEndDate, -pagerDays);
      onChange && onChange(nextRange);
    }
  };

  const pagerLabel = startDate
    ? format(startDate, pagerFormat)
    : "Invalid Date";

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isMobile ? "space-between" : "center"}
      height={48}
      spacing={2}
      ref={containerRef}
      sx={sx}
    >
      <IconButton onClick={handleNextDate} disabled={!startDate || !endDate}>
        <ChevronLeft color="action" />
      </IconButton>

      <ButtonBase
        sx={{ borderRadius: 6, py: 1, px: 1, minWidth: 200 }}
        onClick={showCalendar}
      >
        <Stack pl={2} pr={1} direction="row">
          <Typography
            noWrap
            mr={1}
            fontWeight={!isMobile ? "400" : "500"}
            color={palette.text.primary}
          >
            {pagerLabel}
          </Typography>
          <ArrowDropDownOutlined />
        </Stack>
      </ButtonBase>

      <IconButton onClick={handlePrevDate} disabled={!startDate || !endDate}>
        <ChevronRight />
      </IconButton>

      <Menu
        open={isCalendarVisible}
        anchorEl={containerRef.current}
        // set attach point of the element to be center of the bottom
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        // set attach point in the popover to be center at the top
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={hideCalendar}
      >
        <DateCalendar onChange={handleCalendarChange} value={endDate} />
      </Menu>
    </Stack>
  );
}
