import {
  Button,
  DialogActions,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogBase } from "../DialogBase/DialogBase";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";

type SwitchToSimpleModeModalProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  onSwitch: () => void;
};

export function SwitchToSimpleModeModal({
  onClose,
  onSwitch,
  ...props
}: SwitchToSimpleModeModalProps) {
  const { palette } = useTheme();

  const content = (
    <Typography variant="body1">
      Switching to Simple Mode will result in query loss.
    </Typography>
  );

  return (
    <DialogBase
      maxWidth="sm"
      extraMobileMargin={3}
      disableHeaderDivider
      children={content}
      onClose={onClose}
      {...props}
      footer={
        <DialogActions sx={{ p: 0 }}>
          <Stack direction="row" justifyContent="end" width="100%" gap={1}>
            <Button
              variant="outlined"
              sx={{
                width: 84,
                color: palette.text.primary,
                borderColor: palette.text.primary,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                width: 84,
              }}
              onClick={onSwitch}
            >
              Switch
            </Button>
          </Stack>
        </DialogActions>
      }
    />
  );
}
