import { PropsWithChildren } from "react";
import { Chip, Grid, Typography } from "@mui/material";

export function TagPickerListFooter<T>({
  onChange,
  onClear,
  presets,
  layout,
}: PropsWithChildren<{
  onChange: (list: T[]) => void;
  onClear: () => void;
  presets?: Record<string, T[]>;
  layout?: "row" | "column";
}>) {
  const presetElements =
    presets &&
    Object.entries(presets).map(([k, v]) => {
      const key = `${k}:${JSON.stringify(v)}`;
      return (
        <Grid item key={key}>
          <Chip
            label={k}
            variant="outlined"
            onClick={(_e) => onChange && onChange(v)}
            size="small"
          />
        </Grid>
      );
    });

  return (
    <Grid container spacing={layout === "row" ? 1 : 0.5} p={2} py={1}>
      <Grid item xs={layout === "column" ? 12 : "auto"}>
        <Typography variant="caption" color="text.secondary">
          Most common:
        </Typography>
      </Grid>
      <Grid
        item
        xs={layout === "column" ? 12 : "auto"}
        container
        spacing={1}
        wrap="wrap"
      >
        <Grid item>
          <Chip
            variant="outlined"
            onClick={onClear}
            label="Reset sources"
            size="small"
          />
        </Grid>
        {presetElements}
      </Grid>
    </Grid>
  );
}
