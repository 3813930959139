import { forwardRef } from "react";
import { alpha, Stack, StackProps, useTheme } from "@mui/material";

export const TranscriptEmpty = forwardRef<HTMLDivElement, StackProps>(
  (props: StackProps, ref) => {
    const { palette } = useTheme();

    return (
      <Stack
        ref={ref}
        {...props}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          background: alpha(palette.primary.light, 0.04),
          ...props.sx,
        }}
      />
    );
  }
);
