import { forwardRef } from "react";
import { Stack, StackProps, SxProps } from "@mui/material";
import {
  scrollbarSize,
  useCustomScrollBarStyles,
} from "src/utils/useCustomScrollBarStyles";

export type StackScrollProps = Omit<StackProps, "overflow" | "direction"> & {
  overflow?: "visible" | "scroll" | "hidden";
  scrollBarStyle?: "normal" | "thin" | "hidden";
  scrollOrientation?: "vertical" | "horizontal";
};

/**
 * This is abstraction to apply custom scroll styles to Stack and reserve scroll
 * bar space so layout does not shift when overflow happens
 */
export const StackScroll = forwardRef<HTMLDivElement, StackScrollProps>(
  (
    {
      overflow = "scroll",
      scrollBarStyle = "normal",
      scrollOrientation = "vertical",
      ...props
    }: StackScrollProps,
    forwardRef
  ) => {
    const sx: SxProps = useCustomScrollBarStyles({
      overflowY: scrollOrientation === "horizontal" ? "hidden" : overflow,
      overflowX: scrollOrientation === "horizontal" ? overflow : "hidden",
      size: scrollBarStyle,
    });

    const scrollbarReserveMargin =
      overflow === "scroll"
        ? undefined // scrollbar visible - no margin
        : `${scrollbarSize[scrollBarStyle]}px`;

    return (
      <Stack
        ref={forwardRef}
        {...props}
        sx={{ ...sx, ...props.sx }}
        mr={scrollbarReserveMargin}
      />
    );
  }
);
