import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { userBannerKey } from "./useBanner";

const apiPath: keyof paths = "/api/banner/user/{userId}";
const method: keyof paths[typeof apiPath] = "delete";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"][204];

type RequestMutationFnParams = {
  path: {
    userId: Endpoint["parameters"]["path"]["userId"];
  };
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useBannerDelete({ options }: { options?: RequestOptions }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationFn: async ({ path }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        path: {
          userId: path.userId,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(t("userBannerDeleteMessage", "User Banner deleted"), {
        variant: "success",
      });
      queryClient.refetchQueries({
        queryKey: userBannerKey,
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
