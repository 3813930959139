import { format } from "date-fns";
import { useMemo, useState } from "react";
import {
  Chip,
  ListItemSecondaryAction,
  Stack,
  sliderClasses,
  useTheme,
} from "@mui/material";
import { EventType } from "src/models/EventType";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { DateTimeSearchResultActions } from "../DateTimeSearchResultActions/DateTimeSearchResultActions";
import { useDateTimeSearchPlayState } from "../DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";

export type DateTimeSearchResultListItemProps = {
  id?: string;
  isExpandedTextMode?: boolean;
  value: DateTimeSearchResultItem;
};

const imgWidth = 380;

export function DateTimeSearchResultListItem({
  id,
  value,
  isExpandedTextMode,
  ...props
}: DateTimeSearchResultListItemProps) {
  const [seekTo, setSeekTo] = useState(0);
  const { palette, spacing } = useTheme();
  const {
    isPlaying,
    setPlayState,
    getPlayState,
    getActiveInstanceId,
    setInstanceTime,
    playNext,
  } = useDateTimeSearchPlayState();
  const activeInstanceId = getActiveInstanceId();
  const playState = value.id ? getPlayState(value.id) : null;
  const isCurrent = activeInstanceId === value.id;
  const isCurrentPlaying = isCurrent && isPlaying;

  const blockTimeLabel = value.blockStartTime
    ? format(new Date(value.blockStartTime), "pp")
    : null;

  const transcriptView = useMemo(() => {
    return (
      <TranscriptView
        key={`${value.id}:offset=${value.title}`}
        offset={isCurrent ? playState?.time ?? 0 : 0}
        overflow={isExpandedTextMode ? "visible" : "scroll"}
        maxHeight={isExpandedTextMode ? undefined : (imgWidth / 16) * 9}
        autoScroll
        mr={4}
        hideAutoScrollSwitch
        onClickLineBlock={(offsetMs) => {
          setSeekTo(offsetMs);
          setPlayState(`${value.id}`, true);
        }}
        event={{
          startDateTime: value.blockStartTime,
          transcriptLineBundles: [
            {
              bundleStartDateTime: value.blockStartTime,
              bundleEndDateTime: value.blockEndTime,
              transcriptLines: value.transcriptLines,
              transcript: value.transcript,
            },
          ],
        }}
      />
    );
  }, [
    isCurrent,
    playState?.time,
    isExpandedTextMode,
    value,
    setSeekTo,
    setPlayState,
  ]);

  const clipViewer = useMemo(() => {
    return (
      value.stationUUID && (
        <ClipViewer
          id={`${value.id}`}
          minWidth={imgWidth}
          maxWidth={imgWidth}
          viewMode="compact"
          seekOffsetMs={seekTo}
          play={isCurrentPlaying}
          onCurrentTimeChange={setInstanceTime}
          onChangePlayState={setPlayState}
          onPlayEnd={playNext}
          event={{
            eventType: EventType.BCast,
            mediaType: value.mediaType,
            sourceId: value.stationUUID,
            startDateTime: value.blockStartTime,
            endDateTime: value.blockEndTime,
          }}
          sx={{
            [`& > .MuiBox-root:first-of-type div`]: {
              borderRadius: 2,
              overflow: "hidden",
            },
            [`& .${sliderClasses.root}`]: {
              visibility: isCurrent ? "visible" : "hidden",
            },
          }}
        />
      )
    );
  }, [
    isCurrent,
    isCurrentPlaying,
    seekTo,
    value,
    playNext,
    setInstanceTime,
    setPlayState,
  ]);

  return useMemo(
    () => (
      <Stack
        id={id}
        pl={3}
        children={
          <ListBaseItem
            key={value.id}
            divider
            disableGutters
            sx={{
              alignItems: "flex-start",
              columnGap: 1,
              px: 0,
            }}
          >
            <Chip
              label={blockTimeLabel}
              size="small"
              sx={{
                position: "absolute",
                left: 8,
                top: 24,
                zIndex: 4,
                background: palette.background.paper,
                pointerEvents: "none",
                minWidth: 96,
                maxWidth: 96,
              }}
            />

            {clipViewer}
            {transcriptView}

            <ListItemSecondaryAction
              sx={{ top: spacing(1), right: 1, transform: "none" }}
              children={<DateTimeSearchResultActions value={value} />}
            />
          </ListBaseItem>
        }
      />
    ),
    [
      id,
      blockTimeLabel,
      clipViewer,
      palette.background.paper,
      spacing,
      transcriptView,
      value,
    ]
  );
}
