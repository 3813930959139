import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { TextFieldDebounced } from "src/components/TextFieldDebounced/TextFieldDebounced";

export function DevTextField() {
  const [throttledValue, setThrottledValue] = useState("value1");
  const [inputValue, setInputValue] = useState("value2");

  return (
    <Stack>
      <Typography>Debounced input</Typography>

      <TextFieldDebounced
        value={inputValue}
        onTextChangeThrottled={setThrottledValue}
        onChange={(e: any) => setInputValue(e.target.value)}
      />
      <Typography>{`Debounced value is: ${throttledValue}`}</Typography>

      <Typography>{`Not a debounced value is: ${inputValue}`}</Typography>
    </Stack>
  );
}
