import { useCallback, useState } from "react";
import { LinearProgress, Stack } from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { WatchTermResultItem } from "src/api/useWatchTermResultsInfinite";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { DuplicatesTile } from "./DuplicatesTile";
import { DuplicatesTilesProps } from "./DuplicatesTiles.model";

export function DuplicatesTiles({
  queryId,
  isExpandedTextMode,
  addItemToReport,
  rows,
  isLoading,
}: DuplicatesTilesProps) {
  const [activePlayerId, setActivePlayerId] = useState<string | null>(null);

  const setPlayerIdWrapper = useCallback((id: string, isPlaying: boolean) => {
    if (isPlaying) {
      setActivePlayerId((prevActivePlayerId) =>
        prevActivePlayerId !== id ? id : prevActivePlayerId
      );
    }
  }, []);

  const { list } = useEventSelectionDuplicatesDrawer();

  const renderItem = useCallback(
    (data: PowerSearchResultItem) => {
      const isChecked = !!list.find((event) => event.id === data.id);
      return (
        <DuplicatesTile
          key={data.id}
          value={data}
          queryId={queryId}
          isExpandedTextMode={isExpandedTextMode}
          isChecked={isChecked}
          isPlaying={activePlayerId === data.id}
          onChangePlayState={setPlayerIdWrapper}
          addItemToReport={addItemToReport}
        />
      );
    },
    [
      queryId,
      isExpandedTextMode,
      activePlayerId,
      setPlayerIdWrapper,
      addItemToReport,
      list,
    ]
  );

  return (
    <>
      {isLoading && <LinearProgress />}
      <Stack
        pl={2}
        pr={2}
        pb={1}
        overflow="auto"
        children={
          <TileLayout<WatchTermResultItem>
            rows={rows}
            renderItem={renderItem}
          />
        }
      />
    </>
  );
}
