import { PropsWithChildren } from "react";
import { Stack } from "@mui/material";
import { ScrollBox } from "../ScrollBox/ScrollBox";

export function AppAutocompleteMultilineChipContainer({
  lineClamp,
  size,
  ...props
}: PropsWithChildren<{
  size: "small" | "medium";
  lineClamp: number;
}>) {
  const rowHeight = size === "medium" ? 38 : 29;

  return (
    <ScrollBox direction="column" customScrollBarStyle="thin">
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        maxHeight={`${rowHeight * lineClamp}px`}
        {...props}
      />
    </ScrollBox>
  );
}
