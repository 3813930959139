import { Box, CircularProgress, Typography } from "@mui/material";

interface PageLoaderProps {
  title?: string;
}

export const PageLoader = (props: PageLoaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      {props.title && (
        <Typography variant="subtitle1">{props.title}</Typography>
      )}
      <CircularProgress />
    </Box>
  );
};
