import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryListKey } from "./useWatchQueryList";

export const clusterSpecialOptions = {
  /** this special item is used to unassign from cluster */
  unassign: {
    id: "unassign-from-cluster",
    title: "Unassign from Cluster",
  },
} as const;

const apiKey: keyof paths = `/api/WatchList/cluster/unassign`;
const method: keyof paths[typeof apiKey] = "put";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  body: RequestBody;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useWatchQueryClusterUnassign({
  options,
}: {
  options?: RequestOptions;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ body }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey);

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: watchQueryListKey,
      });

      enqueueSnackbar(t("clusterUnassignMessage", "Unassigned from cluster"), {
        variant: "success",
      });

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      enqueueSnackbar({
        message: `${error}`,
        variant: "error",
      });

      options?.onError?.(error, variables, context);
    },
  });
}
