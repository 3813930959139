import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import { useWatchTermSharedForm } from "src/api/useWatchTermSharedForm";
import { WatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.model";

export const WatchListCreateKeywordFormWrapper = (props: PropsWithChildren) => {
  const { watch, reset, trigger } =
    useFormContext<WatchQueryKeywordFormValues>();
  const formValues = watch();

  const { sharedValues } = useWatchTermSharedForm();

  useEffect(() => {
    reset({
      ...formValues,
      ...sharedValues,
    });
    const sharedKeys = Object.keys(sharedValues) as Array<
      keyof WatchQueryKeywordFormValues
    >;
    trigger(sharedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Stack>{props.children}</Stack>;
};
