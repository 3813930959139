import { isAfter, isBefore } from "date-fns";
import { useMemo } from "react";
import { EventDetails } from "src/models/EventDetails";

/**
 * A hook to validate a date range
 */
export const useTrimRangeErrorMessage = ({
  event,
  range,
}: {
  event: EventDetails;
  range: [Date | null, Date | null];
}): [string, string] => {
  const eventT0 = event.startDateTime ? new Date(event.startDateTime) : null;
  const eventT1 = event.endDateTime ? new Date(event.endDateTime) : null;

  let t0Error = "";
  let t1Error = "";

  const [t0, t1] = range;

  // start time validation
  if (!t0) {
    t0Error = "Start time is required";
  }

  if (t0 && t1 && !isBefore(t0, t1)) {
    t0Error = "Start time must be before end time";
  }

  if (t0 && eventT0 && isBefore(t0, eventT0)) {
    t0Error = "Start time can not be before event start time";
  }

  // end time validation
  if (!t1) {
    t1Error = "End time is required";
  }

  if (t1 && t0 && !isAfter(t1, t0)) {
    t1Error = "End time must be after start time";
  }

  if (t1 && eventT1 && isAfter(t1, eventT1)) {
    t1Error = "End time can not be after event end time";
  }

  return useMemo(() => {
    return [t0Error, t1Error];
  }, [t0Error, t1Error]);
};
