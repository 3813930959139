import { add } from "date-fns";
import { create } from "zustand";

type DateTimeSearchClipState = {
  id: string;
  time: number;
};

type DateTimeSearchPlayAllState = {
  /** index of playing item */
  activeIndex: number;

  /** is playing through the instances */
  isPlaying: boolean;

  /** all player instances  */
  instances: DateTimeSearchClipState[];

  /** initialize playing state with instance ids */
  init: (instanceIds: string[]) => void;

  /** switch active instance to next item */
  playNext: () => void;

  /** set current item by instanceId and play status */
  setPlayState: (instanceId: string, isPlaying?: boolean) => void;

  /** set instance play time  */
  setInstanceTime: (instanceId: string, currentTime: number) => void;

  /** get instance play time  */
  getInstanceTime: (instanceId: string) => number;

  /** instanceId of playing item */
  getActiveInstanceId: () => string;

  getActiveInstancePlayDateTime: (baseDate: Date, timeZone: string) => Date;

  /** return play state for a particular instance */
  getPlayState: (instanceId: string) => DateTimeSearchClipState | undefined;

  /** toggle play/pause */
  togglePlaying: () => void;

  /** pause playing */
  pause: () => void;
};

export const useDateTimeSearchPlayState = create<DateTimeSearchPlayAllState>(
  (set, get) => {
    return {
      activeIndex: -1,
      isPlaying: false,
      instances: [],

      getActiveInstanceId: () => {
        const { activeIndex, instances } = get();
        const instance = instances[activeIndex];

        return instance ? instance.id : "";
      },

      getPlayState: (instanceId: string) => {
        return get().instances.find(({ id }) => id === instanceId);
      },

      setPlayState: (instanceId: string, isPlaying?: boolean) => {
        const { instances } = get();
        const instanceIndex = instances.findIndex(
          ({ id }) => id === instanceId
        );

        if (instanceIndex < 0) {
          set({ activeIndex: -1, isPlaying: false });
        } else {
          //
          if (isPlaying === undefined) {
            set({ activeIndex: instanceIndex });
          } else {
            set({ activeIndex: instanceIndex, isPlaying });
          }
        }
      },

      getInstanceTime: (instanceId: string): number => {
        return get().getPlayState?.(instanceId)?.time ?? 0;
      },

      setInstanceTime: (instanceId: string, currentTime: number) => {
        const nextInstances = get().instances.map((instance) => {
          if (instance.id === instanceId) {
            return {
              ...instance,
              time: currentTime,
            };
          }

          return instance;
        });

        set({ instances: nextInstances });
      },

      togglePlaying: function () {
        const prev = get();

        if (prev.activeIndex < 0) {
          // start first
          const firstInstance = prev.instances[0];
          if (firstInstance) {
            set({ activeIndex: 0, isPlaying: true });
          }
        } else {
          // toggle current
          set({ isPlaying: !prev.isPlaying });
        }
      },

      pause: () => {
        if (get().isPlaying) {
          set({ isPlaying: false });
        }
      },

      playNext: () => {
        const prevIndex = get().activeIndex;

        if (prevIndex < 0) {
          set({
            activeIndex: 0,
            isPlaying: true,
          });

          return;
        }

        const nextIndex = prevIndex + 1;
        const nextInstance = get().instances[nextIndex];
        const nextIsPlaying = nextIndex < get().instances.length;

        set({
          activeIndex: nextInstance ? nextIndex : -1,
          isPlaying: nextIsPlaying,
        });
      },

      init: (instanceIds: string[]) => {
        const nextInstances: DateTimeSearchClipState[] = instanceIds.map(
          (id) => ({
            id,
            time: 0,
          })
        );

        set({
          activeIndex: nextInstances.length ? 0 : -1,
          instances: nextInstances,
          isPlaying: false,
        });
      },

      getActiveInstancePlayDateTime: (baseDate: Date, timeZone: string) => {
        const { getActiveInstanceId, getInstanceTime } = get();

        const activeInstanceId = getActiveInstanceId();
        const instanceOffset = getInstanceTime(activeInstanceId);

        const date = new Date(
          baseDate.toLocaleString("en-US", {
            timeZone,
          })
        );

        return add(date, { seconds: instanceOffset / 1000 });
      },
    };
  }
);
