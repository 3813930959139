import { MaterialDesignContent, SnackbarProvider } from "notistack";
import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material";
import { App } from "../App";
import { SnackCloseButton } from "../components/SnackCloseButton/SnackCloseButton";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "& #notistack-snackbar": {
      gap: "10px",
    },
    "&.notistack-MuiContent": {
      flexWrap: "nowrap",
    },
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: theme.palette.info.main,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
    },
  })
);

export const AppSnackbarProvider = () => (
  <SnackbarProvider
    Components={{
      default: StyledMaterialDesignContent,
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
    }}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    preventDuplicate
    action={(snackKey) => <SnackCloseButton snackKey={snackKey} />}
    children={<App />}
    maxSnack={5}
    iconVariant={{
      success: <CheckCircleOutline />,
      error: <ErrorOutline />,
      info: <InfoOutlined />,
      warning: <WarningAmberOutlined />,
    }}
  />
);
