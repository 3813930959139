import { AddTask } from "@mui/icons-material";
import { Badge, ToggleButton } from "@mui/material";
import {
  maxEventsToPreselect,
  preselectLimitTitle,
} from "src/api/useReportScratchAppenFromQuery";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";

export const PreselectEventsButton = ({
  badgeCounter,
  disabled,
  onClick,
}: {
  badgeCounter: number;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <TooltipBase
      width={250}
      placement="bottom"
      title={
        badgeCounter > maxEventsToPreselect
          ? preselectLimitTitle
          : "Select required events, then click this button in order to create Report with them"
      }
    >
      <Badge badgeContent={badgeCounter} color="primary">
        <ToggleButton
          value={true}
          disabled={disabled}
          sx={{ width: 42 }}
          size="small"
          onClick={onClick}
        >
          <AddTask color={disabled ? "disabled" : "primary"} />
        </ToggleButton>
      </Badge>
    </TooltipBase>
  );
};
