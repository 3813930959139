import { Fragment } from "react";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { BookmarkAdd, MpOutlined } from "@mui/icons-material";
import { useOpenState } from "src/utils/useOpenState";
import { EventDetails } from "src/models/EventDetails";
import { getEventDetailsMediaDownloadExtension } from "src/api/usePowerSearchResult.getters";
import DropDownButton from "../DropDownButton/DropDownButton";
import {
  ClipEditorTitlePrompt,
  ClipEditorTitlePromptProps,
} from "../ClipEditorTitlePrompt/ClipEditorTitlePrompt";
import { TooltipBase } from "../TooltipBase/TooltipBase";

export const ClipEditorSaveButtonDesktop = ({
  handleSaveToMediaCenter,
  handleDownload,
  disabled,
  enableMediaDownload,
  event,
}: {
  handleSaveToMediaCenter: (clipTitle: string) => void;
  handleDownload: () => void;
  disabled?: boolean;
  enableMediaDownload?: boolean;
  event: Pick<EventDetails, "mediaDownloadExtension">;
}) => {
  const titleDialog = useOpenState();

  const handleConfirmTitle: ClipEditorTitlePromptProps["onConfirm"] = (
    values
  ) => {
    titleDialog.hide();
    handleSaveToMediaCenter(values.clipTitle);
  };

  return (
    <Fragment>
      <TooltipBase
        width={181}
        textAlign="left"
        title={
          "You can't save media as you have an account limitation. Please contact your account manager to extend your limits."
        }
        disableHoverListener={enableMediaDownload}
      >
        <Box>
          <DropDownButton
            children="Save"
            sx={{ width: 160 }}
            disabled={disabled}
            renderMenu={(menuProps, closeMenu) => {
              return (
                <Menu {...menuProps}>
                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      closeMenu();
                      titleDialog.show();
                    }}
                  >
                    <ListItemIcon>
                      <BookmarkAdd color="primary" />
                    </ListItemIcon>
                    <ListItemText children="To Media Center" />
                  </MenuItem>

                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      closeMenu();
                      handleDownload();
                    }}
                  >
                    <ListItemIcon>
                      <MpOutlined color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      children={`Download ${getEventDetailsMediaDownloadExtension(
                        event
                      )}`}
                    />
                  </MenuItem>
                </Menu>
              );
            }}
          />
        </Box>
      </TooltipBase>
      <ClipEditorTitlePrompt
        open={titleDialog.isOpen}
        onConfirm={handleConfirmTitle}
        onDecline={titleDialog.hide}
      />
    </Fragment>
  );
};
