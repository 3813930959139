import { create } from "zustand";
import { PowerSearchResultItem } from "./usePowerSearch";

type SelectionState<
  T extends {
    id: string;
  }
> = {
  list: T[];
  select: (event: T) => void;
  selectEvents: (events: T[]) => void;
  unselect: (event: T) => void;
  unselectEvents: (events: T[]) => void;
  unselectAll: () => void;
};

export const useEventSelectionDuplicatesDrawer = create<
  SelectionState<PowerSearchResultItem>
>((setState) => ({
  list: [],
  select: (event: PowerSearchResultItem) => {
    setState((state) => ({
      list: [...state.list, event],
    }));
  },
  selectEvents: (events: PowerSearchResultItem[]) => {
    setState((state) => {
      const selectedIds = state.list.map((ev) => ev.id);
      const addedEvents = events.filter((ev) => !selectedIds.includes(ev.id));
      return {
        list: [...state.list, ...addedEvents],
      };
    });
  },
  unselect: (event: PowerSearchResultItem) => {
    setState((state) => ({
      list: state.list.filter((stateEvent) => stateEvent.id !== event.id),
    }));
  },
  unselectEvents: (events: PowerSearchResultItem[]) => {
    const unselectedIds = events.map((ev) => ev.id);
    setState((state) => ({
      list: state.list.filter(
        (stateEvent) => !unselectedIds.includes(stateEvent.id)
      ),
    }));
  },
  unselectAll: () => {
    setState({
      list: [],
    });
  },
}));
