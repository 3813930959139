import { FileDownload } from "@mui/icons-material";
import { buttonClasses } from "@mui/material";
import { useTranscriptDownload } from "src/api/useTranscriptDownload";
import {
  LoadingButton,
  LoadingButtonProps,
} from "src/components/buttons/LoadingButton";

type FetchParams = {
  stationId: string;
  dateTime: Date;
  rangeBounds: [number, number];
  timeZone?: string;
};

type DateTimeTranscriptDownloadButtonProps = Omit<
  LoadingButtonProps,
  "onClick"
> & {
  fetchParams: FetchParams | null;
  isCompact?: boolean;
};

export const DateTimeSearchDownloadButton = ({
  children = "Transcript",
  fetchParams,
  isCompact,
  ...props
}: DateTimeTranscriptDownloadButtonProps) => {
  const transcriptDownload = useTranscriptDownload();

  const handleOnClickWrapper = () => {
    fetchParams && transcriptDownload.mutate(fetchParams);
  };

  if (isCompact) {
    return (
      <LoadingButton
        variant="contained"
        loading={transcriptDownload.isLoading}
        disabled={transcriptDownload.isLoading}
        startIcon={<FileDownload />}
        onClick={handleOnClickWrapper}
        {...props}
        sx={{
          ...props.sx,
          width: 42,
          paddingLeft: 1,
          paddingRight: 1,
          [`.${buttonClasses.startIcon}`]: {
            marginLeft: 0,
            marginRight: 0,
          },
        }}
      />
    );
  }

  return (
    <LoadingButton
      startIcon={<FileDownload />}
      loading={transcriptDownload.isLoading}
      disabled={transcriptDownload.isLoading}
      variant="contained"
      children={children}
      onClick={handleOnClickWrapper}
      {...props}
    />
  );
};
