import { useMemo } from "react";

/**
 * Filters object list with arbitrary text by checking its props
 */
export function useListFilter<T>(
  source: T[],
  filterText: string,
  propNames: (keyof T)[]
) {
  const empty: T[] = useMemo(() => [], []);

  const nextFilterData = useMemo(
    () =>
      source?.filter((item) => {
        const q = filterText.toLowerCase();

        const check = (propName: keyof T) => {
          const value = Array.isArray(item[propName])
            ? JSON.stringify(item[propName])
            : item[propName];

          if (value === null || value === undefined) {
            return false;
          }

          if (Array.isArray(value)) {
            return value.some((v) => `${v}`.toLowerCase().includes(q));
          }

          return `${value}`.toLowerCase().includes(q);
        };

        return propNames.find(check);
      }),
    [source, filterText, propNames]
  );

  return nextFilterData.length ? nextFilterData : empty;
}
