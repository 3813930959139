import { Stack, useTheme } from "@mui/material";
import { TimeLineEmptyProgramLayoutProps } from "./TimeLineEmptyProgramLayout.model";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";

export const TimeLineEmptyProgramLayout = ({
  station,
}: TimeLineEmptyProgramLayoutProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      boxSizing="border-box"
      height={40}
      position="absolute"
      top="50%"
      left={0}
      right={0}
      sx={{ transform: "translateY(-50%)" }}
      children={
        <Stack
          bgcolor={palette.text.secondary}
          justifyContent="center"
          overflow="hidden"
          px={2}
          py={0.25}
          flex={1}
          children={
            <TextLineClamp
              variant="body1"
              lineHeight="1em"
              lineClamp={1}
              color={palette.info.contrastText}
              children={station}
            />
          }
        />
      }
    />
  );
};
