import { useEffect } from "react";
import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { MediaCenterFilterForm } from "../MediaCenterFilterForm/MediaCenterFilterForm";
import { MediaCenterFilterFormValues } from "../MediaCenterFilterForm/MediaCenterFilterForm.model";
import { useMediaCenterFilterForm } from "../MediaCenterFilterForm/MediaCenterFilterForm.hook";

type MediaCenterFiltersModalProps = Omit<
  DialogBaseProps,
  "content" | "footer" | "onSubmit"
> & {
  initial?: Partial<MediaCenterFilterFormValues>;
  onSubmit: (data: MediaCenterFilterFormValues) => void;
};

export const MediaCenterFiltersModal = ({
  onClose,
  onSubmit,
  initial,
  ...props
}: MediaCenterFiltersModalProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const formHook = useMediaCenterFilterForm({
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;
  const handleSubmitWrapper = handleSubmit((data) => {
    onClose && onClose();
    onSubmit(data);
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
        children={<MediaCenterFilterForm />}
        footer={
          <Stack
            direction="row"
            justifyContent={isMobile ? "justify-between" : undefined}
            width={isMobile ? "100%" : undefined}
            spacing={2}
            minHeight={42}
          >
            <Hidden lgDown>
              <Button
                sx={{ width: 100, display: { xs: "none", md: "flex" } }}
                onClick={onClose}
                color="error"
              >
                Cancel
              </Button>
            </Hidden>

            <LoadingButton
              variant="contained"
              color="primary"
              loading={isBusy}
              disabled={!formState.isValid}
              onClick={handleSubmitWrapper}
              sx={{
                minWidth: isMobile ? 160 : 176,
                width: isMobile ? "100%" : undefined,
              }}
            >
              Apply
            </LoadingButton>
          </Stack>
        }
      />
    </FormProvider>
  );
};
