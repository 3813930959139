import { ListBase } from "src/components/ListBase/ListBase";
import { ReportsNoFilteredReportsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { ReportItem } from "src/models/Report";
import { useReportsSelection } from "src/api/useReportsSelection";
import { ReportsListProps } from "./ReportsList.model";
import { ReportsListItem } from "./ReportsListItem";

export function ReportsList({ ...props }: ReportsListProps) {
  const reportsSelection = useReportsSelection();

  const renderItem = (data: ReportItem) => {
    const isChecked = !!reportsSelection.list.find(
      (report) => report.id === data.id
    );
    return <ReportsListItem value={data} key={data.id} isChecked={isChecked} />;
  };

  return (
    <ListBase<ReportItem>
      renderItem={renderItem}
      renderEmpty={ReportsNoFilteredReportsOverlay}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
