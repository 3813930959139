import {
  Accordion,
  AccordionSummary,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useOpenState } from "src/utils/useOpenState";
import { UserManagementFiltersModal } from "src/pages/UserManagement/components/UserManagementFiltersModal/UserManagementFiltersModal";
import { ManagementUsersList } from "src/pages/UserManagement/components/ManagementUsersList/ManagementUsersList";
import { useUserManagementFilter } from "src/pages/UserManagement/hooks/useUserManagementFilter";
import { getConfigurationKey } from "src/pages/AccountsManagement/components/AccountsManagementConvertDialog/AccountsManagementConvertDialog.utils";
import { AccountResponse } from "src/api/useAccount";
import { UserDataResponse } from "src/api/useUserData";
import { useAccountManagementDetailsUpdate } from "src/api/useAccountManagementDetailsUpdate";
import { AccountsManagementEditAccountForm } from "../AccountsManagementEditAccountForm/AccountsManagementEditAccountForm";
import { EditAccountDetailsFormValues } from "./AccountsManagementEditDetails.model";

export function AccountsManagementGeneralTab({
  deactivateAccount,
  data,
  accountManagerData,
}: {
  deactivateAccount: (convertKey: string, value?: string | boolean) => void;
  data?: AccountResponse;
  accountManagerData?: UserDataResponse;
}) {
  const filterDialog = useOpenState();
  const accountUpdate = useAccountManagementDetailsUpdate({});
  const location = useLocation();

  const { accountId = "" } = useParams();
  const [filterQuery, setFilterQuery, resetFilterQuery] =
    useUserManagementFilter();
  const { reset, handleSubmit, formState } =
    useFormContext<EditAccountDetailsFormValues>();

  useEffect(() => {
    if (location.state) {
      const element = document.getElementById(location.state.section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleDetailsReset = () => {
    reset({
      organizationType: data?.organizationType || "Operating Company",
      name: data?.name || "",
      country: data?.country || "",
      city: data?.city || "",
      stateOrProvince: data?.stateOrProvince || "",
      postalCode: data?.postalCode || "",
    });
  };

  const handleSubmitWrapper = handleSubmit(
    (formData) => {
      accountUpdate.mutate({
        body: {
          ...formData,
          addressLine1: data?.addressLine1 || "",
          addressLine2: data?.addressLine2 || "",
          accountImage: data?.accountImage || "",
          description: data?.description || "",
          email: data?.email || "",
          phone: data?.phone || "",
          website: data?.website || "",
        },
        params: {
          path: {
            id: accountId,
          },
        },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:AccountsManagementGeneralTab:error", errors);
    }
  );

  const accountConfiguration = useMemo(() => {
    return getConfigurationKey(data?.isActive, data?.isTrial, data?.isPaying);
  }, [data?.isActive, data?.isTrial, data?.isPaying]);

  useEffect(() => {
    const defaultUMBody = {
      accountIds: [],
      roles: [],
      timezone: null,
      status: null,
    };

    const defaultUsersBody = {
      ...defaultUMBody,
      accountIds: [accountId],
    };
    setFilterQuery(defaultUsersBody);
    return () => {
      setFilterQuery(defaultUMBody);
    };
  }, [setFilterQuery, accountId]);

  return (
    <Stack direction={"column"} gap={3} width={"100%"}>
      <Stack component={Paper} flex={1} px={3}>
        <AccountsManagementEditAccountForm
          deactivateAccount={deactivateAccount}
          accountConfiguration={accountConfiguration}
          data={data}
          accountManagerData={accountManagerData}
        />
        <Stack direction="row" justifyContent="flex-end" gap={2} mr={2} mb={3}>
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "124px", fontSize: "15px" }}
            onClick={handleDetailsReset}
            disabled={!formState.isDirty}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: "42px", width: "124px", fontSize: "15px" }}
            onClick={handleSubmitWrapper}
            disabled={!formState.isDirty}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Paper
        sx={{
          width: "100%",
          minHeight: "68px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: 2,
        }}
        id="account-users"
      >
        <Accordion sx={{ width: "100%" }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ minHeight: "68px", background: "transparent !important" }}
          >
            <Typography variant="subtitle1" pl={2} pt={1}>
              Account Users
            </Typography>
          </AccordionSummary>

          <Stack flex={1} px={3}>
            {accountId && (
              <ManagementUsersList
                filterDialog={filterDialog}
                showAddUserButton
                filterQuery={filterQuery}
                accountId={accountId}
              />
            )}
          </Stack>
        </Accordion>
      </Paper>
      <UserManagementFiltersModal
        title="Filter options"
        open={filterDialog.isOpen}
        onClose={filterDialog.hide}
        initial={filterQuery}
        onSubmit={setFilterQuery}
        onResetForm={resetFilterQuery}
      />
    </Stack>
  );
}
