import { ReactNode, useCallback, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { UseInfiniteQueryResult } from "react-query";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { ScrollHeaderLayoutProps } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout.model";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ReportEventSelectionState } from "src/api/useEventSelectionReport";
import { ReportEventsTable } from "./ReportEventsTable/ReportEventsTable";
import { ReportEventsList } from "./ReportEventsList/ReportEventsList";
import { ReportEventsTiles } from "./ReportEventsTiles/ReportEventsTiles";

export function useReportEventsDataView({
  data,
  viewMode,
  isExpandedTextMode,
  isLoading = false,
  renderEventsItemActions,
  selectionState,
  rowCount,
  compactHorizontalPadding,
  getEventUrl,
  paginationModel,
  onPaginationModelChange,
  highlightedEventsIds,
  reportEventsTiles,
  renderHeader,
  isCompact,
  headerMinHeight,
  nextPageButtonIsDisabled,
}: {
  data: PowerSearchResultItem[];
  viewMode: ViewMode;
  isExpandedTextMode?: boolean | null;
  isLoading?: boolean;
  renderEventsItemActions?: (value: PowerSearchResultItem) => ReactNode;
  selectionState?: ReportEventSelectionState<PowerSearchResultItem>;
  rowCount: number;
  compactHorizontalPadding?: boolean;
  getEventUrl?: (eventId: string) => string;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  highlightedEventsIds?: string[];
  reportEventsTiles: UseInfiniteQueryResult;
  renderHeader: ScrollHeaderLayoutProps["renderHeader"];
  isCompact?: boolean;
  headerMinHeight?: number;
  nextPageButtonIsDisabled?: boolean;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedEventsIds = useMemo(
    () => selectionState?.list.map((event: PowerSearchResultItem) => event.id),
    [selectionState?.list]
  );

  const onRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      const selectedEvents = data.filter((event) =>
        rowSelectionModel.includes(event.id)
      );
      selectionState?.selectAll(selectedEvents);
    },
    [data, selectionState]
  );

  return useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <ReportEventsTable
              id="report-events-table"
              rows={data}
              rowCount={rowCount}
              paginationMode="server"
              isMobile={isMobile}
              checkboxSelection={!!selectionState}
              onRowSelectionModelChange={onRowSelectionModelChange}
              rowSelectionModel={selectedEventsIds || []}
              loading={isLoading}
              renderEventsItemActions={renderEventsItemActions}
              compactHorizontalPadding={compactHorizontalPadding}
              getEventUrl={getEventUrl}
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              highlightedEventsIds={highlightedEventsIds}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
            />
          ),
          list: (
            <ReportEventsList
              id="report-events-list"
              rows={data}
              rowCount={rowCount}
              loading={isLoading}
              renderEventsItemActions={renderEventsItemActions}
              selectionState={selectionState}
              getEventUrl={getEventUrl}
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              highlightedEventsIds={highlightedEventsIds}
              isCompact={isCompact}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
            />
          ),
          tile: (
            <ReportEventsTiles
              id="report-events-tiles"
              filterData={data}
              lineClamp={isMobile && isExpandedTextMode ? 5 : 2}
              renderEventsItemActions={renderEventsItemActions}
              selectionState={selectionState}
              compactHorizontalPadding={compactHorizontalPadding}
              getEventUrl={getEventUrl}
              highlightedEventsIds={highlightedEventsIds}
              reportEventsTiles={reportEventsTiles}
              renderHeader={isMobile ? renderHeader : undefined}
              headerMinHeight={headerMinHeight}
            />
          ),
        }}
      />
    );
  }, [
    viewMode,
    data,
    isExpandedTextMode,
    isMobile,
    isLoading,
    onRowSelectionModelChange,
    selectedEventsIds,
    renderEventsItemActions,
    selectionState,
    rowCount,
    compactHorizontalPadding,
    getEventUrl,
    paginationModel,
    onPaginationModelChange,
    highlightedEventsIds,
    renderHeader,
    reportEventsTiles,
    isCompact,
    headerMinHeight,
    nextPageButtonIsDisabled,
  ]);
}
