import { Highlight } from "src/models/Highlight";

export function makeOffsetsFromHighlights({
  highlights,
}: {
  highlights: Highlight[];
}) {
  const offsets = highlights.reduce((acc, value) => {
    if (value.offsetMs === undefined) {
      return acc;
    }

    acc.push(value.offsetMs / 1000);
    return acc;
  }, [] as number[]);

  return offsets;
}
