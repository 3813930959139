import clsx from "clsx";
import { Stack, TypographyProps, alpha, useTheme } from "@mui/material";
import { timelineBlockClasses } from "./TimeLineBlock.const";

export function TimeLineBlock({
  width,
  spanSec,
  disabledSec,
  content,
  blockWidth,
  renderDisabledBlockContent,
  disabledColor,
}: {
  width: number;
  spanSec: number;
  disabledSec?: number;
  alignText?: TypographyProps["align"];
  content?: JSX.Element;
  blockWidth?: number;
  renderDisabledBlockContent?: (blockWidth: number) => JSX.Element;
  disabledColor?: string;
}) {
  const { palette } = useTheme();

  const disabledColorDefault = alpha(palette.action.disabled, 0.32);
  const disabledColorValue = disabledColor ?? disabledColorDefault;

  const disabledWidth =
    typeof disabledSec === "number"
      ? width - (disabledSec / spanSec) * width
      : undefined;

  const isDisabledBlockExist = renderDisabledBlockContent && blockWidth;

  return (
    <Stack
      minWidth={width}
      maxWidth={width}
      className={clsx(timelineBlockClasses.root)}
      boxSizing="border-box"
      my={0.5}
      px="1px"
    >
      <Stack
        height="100%"
        position="relative"
        className={clsx(timelineBlockClasses.content)}
        borderRight={
          disabledWidth
            ? `${disabledWidth}px solid ${disabledColorValue}`
            : undefined
        }
        sx={{
          bgcolor: alpha(palette.action.selected, 0.08),
          pointerEvents: "none",
        }}
      >
        {content}
        {isDisabledBlockExist && renderDisabledBlockContent(blockWidth)}
      </Stack>
    </Stack>
  );
}
