import Joi from "joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { WatchQueryFormValues } from "./WatchQueryForm.model";
import { WatchTerm } from "../WatchTermInput/WatchTermInput.model";

const watchTermListSchema = Joi.array().items(Joi.string()).required();
const watchTermLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const programListSchema = Joi.array().items().required();
const programLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

/**
 * This was replaced with ./WatchQueryKeywordForm/WatchQueryKeywordForm.schema
 * and ./WatchQueryCustomForm/WatchQueryCustomForm.schema
 * Please, remove this when it's not needed anymore
 * @deprecated
 */

export const WatchQueryFormSchema = Joi.object<WatchQueryFormValues>({
  displayName: Joi.string().required().label("Display name"),
  //
  customQuery: Joi.string().allow(null, ""),
  //
  termsInclude: Joi.object<WatchTerm>({
    list: watchTermListSchema.label("Include"),
    logic: watchTermLogicSchema.required().label("Logic"),
  })
    .when("customQuery", {
      is: Joi.string().required(),
      then: Joi.object<WatchTerm>({
        list: watchTermListSchema.label("Include"),
        logic: watchTermLogicSchema.required().label("Logic"),
      }),
      otherwise: Joi.object<WatchTerm>({
        list: watchTermListSchema.min(1).label("Include"),
        logic: watchTermLogicSchema.required().label("Logic"),
      }).required(),
    })
    .required(),
  termsExclude: Joi.object<WatchTerm>({
    list: watchTermListSchema.label("Exclude"),
    logic: watchTermLogicSchema.required().label("Logic"),
  }).required(),
  //
  sourcesInclude: {
    list: sourceListSchema.label("Sources"),
    logic: sourceLogicSchema.required().label("Logic"),
  },
  sourcesExclude: {
    list: sourceListSchema.label("Exclude sources"),
    logic: sourceLogicSchema.required().label("Logic"),
  },
  //
  advertisement: Joi.valid(
    ...Object.values(queryAdvertisementValues)
  ).required(),
  //
  programInclude: {
    list: programListSchema.label("Program/Title"),
    logic: programLogicSchema.label("Logic"),
  },
  programExclude: {
    list: programListSchema.label("Program/Title"),
    logic: programLogicSchema.label("Logic"),
  },
});
