import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
  inputAdornmentClasses,
  buttonBaseClasses,
  inputBaseClasses,
} from "@mui/material";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { BooleanParam, useQueryParam } from "use-query-params";
import { UseInfiniteQueryResult } from "react-query";
import { SearchInput } from "src/components/SearchInput";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { useReportEventsDataView } from "src/components/ReportEvents/ReportEvents.hook";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useQueryDateRange } from "src/utils/useQueryDateRange";
import { useListFilter } from "src/utils/useListFilter";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";
import { CustomBannerHeader } from "src/components/CustomBannerHeader/CustomBannerHeader";
import { ReportPreviewAccordion } from "./ReportPreviewAccordion";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function ReportPreviewTab({
  title,
  description,
  audienceInformation,
  localViewership,
  nationalViewership,
  events,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  isLoading = false,
  sortOption,
  setSortOption,
  setSortBy,
  setOrderBy,
  viewMode,
  setViewModeWrapper,
  reportEventsTiles,
}: {
  title: string;
  description?: string;
  audienceInformation?: number;
  localViewership?: number;
  nationalViewership?: number;
  events: PowerSearchResultItem[];
  rowCount: number;
  paginationModel?: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange?: DataGridPremiumProps["onPaginationModelChange"];
  isLoading?: boolean;
  sortOption: SortBy;
  setSortOption: Dispatch<SetStateAction<SortBy>>;
  setSortBy: Dispatch<SetStateAction<SortByParameters>>;
  setOrderBy: Dispatch<SetStateAction<OrderByParameters>>;
  viewMode: ViewMode;
  setViewModeWrapper: (nextViewMode: ViewMode) => void;
  reportEventsTiles: UseInfiniteQueryResult;
}) {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);
  const [filterText, setFilterText] = useState("");
  const [dateRange, setDateRange] = useQueryDateRange();

  const filterData = useListFilter(events || [], filterText, [
    "title",
    "highlights",
  ]);

  const analytics = null;

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewModeWrapper}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [viewMode, setViewModeWrapper, isMobile]
  );

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortOption, setSortBy, setOrderBy]
  );

  const infoAccordion = useMemo(
    () => (
      <ReportPreviewAccordion
        title={<Typography variant="subtitle1">Information</Typography>}
      >
        <Stack p={2} gap={2}>
          <Stack>
            <Typography variant="subtitle2">Description</Typography>
            <Typography>{description || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">Audience information</Typography>
            <Typography>{audienceInformation || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">Local Market Viewership</Typography>
            <Typography>{localViewership || "-"}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2">National Viewership</Typography>
            <Typography>{nationalViewership || "-"}</Typography>
          </Stack>
        </Stack>
      </ReportPreviewAccordion>
    ),
    [description, audienceInformation, localViewership, nationalViewership]
  );

  const analyticsAccordion = useMemo(
    () =>
      !!analytics && (
        <ReportPreviewAccordion
          title={
            <Stack
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">Analytics</Typography>
              {!isMobile && (
                <>
                  <Typography
                    variant="body2"
                    color={alpha(palette.text.secondary, 0.65)}
                  >
                    Sep 14th 2022 06:54 AM - Sep 14th 2022 02: 36 PM
                  </Typography>
                  <Stack />
                </>
              )}
            </Stack>
          }
        >
          <Typography>Content</Typography>
        </ReportPreviewAccordion>
      ),
    [palette.text.secondary, isMobile]
  );
  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [sortOption, onSortChange, isMobile]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
        sx={{ width: 180 }}
      />
    ),
    [isMobile, setFilterText]
  );

  const toolbarDesktop = useMemo(
    () => (
      <Stack
        p={2}
        gap={2}
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row" flex={1}>
          <Stack sx={{ justifyContent: "flex-end", mr: 2 }} maxWidth={220}>
            {searchInput}
          </Stack>
          <DateTimeSelector
            mode={DateTimeSelectorMode.variable}
            views={["year", "month", "day", "hours", "minutes"]}
            value={dateRange}
            onChange={setDateRange}
            sx={{
              flex: 1,
              [`.${inputBaseClasses.root}`]: {
                letterSpacing: "-1px",
              },
              [`.${inputAdornmentClasses.root}`]: {
                marginLeft: 0,
                [`.${buttonBaseClasses.root}`]: {
                  paddingLeft: 0,
                },
              },
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [searchInput, sortingMenu, dateRange, setDateRange, viewModeSwitch]
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{
          height: 40,
          px: 2,
          mb: 2,
        }}
      >
        {searchInput}
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {sortingMenu}
          <DateTimeRangePickerMobile
            value={dateRange}
            onChange={setDateRange}
            iconButtonSize="small"
          />
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [searchInput, sortingMenu, dateRange, setDateRange, viewModeSwitch]
  );

  const mobileHeader = useMemo(
    () => (
      <>
        <Box px={2}>
          <CustomBannerHeader />
        </Box>
        <Stack
          mt={2}
          px={2}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={1}
        >
          <TextLineClamp variant="h6" lineClamp={2}>
            {title}
          </TextLineClamp>

          <Stack flexDirection="row" height={36} width={"100%"} gap={2}>
            <Button
              variant="contained"
              startIcon={<DownloadPDF disabled />}
              sx={{ flex: 1 }}
              disabled
            >
              PDF
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadCSV disabled />}
              sx={{ flex: 1 }}
              disabled
            >
              CSV
            </Button>
          </Stack>
        </Stack>
        <Stack gap={1} mx={2} mt={2}>
          {infoAccordion}
          {analyticsAccordion}
        </Stack>
        {viewMode !== ViewMode.tile ? null : (
          <Stack pt={2}>{toolbarMobile}</Stack>
        )}
      </>
    ),
    [viewMode, title, infoAccordion, analyticsAccordion, toolbarMobile]
  );

  const renderHeader = useCallback(() => {
    return isMobile ? mobileHeader : toolbarDesktop;
  }, [isMobile, toolbarDesktop, mobileHeader]);

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    isExpandedTextMode,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    isLoading,
    reportEventsTiles,
    renderHeader,
    isCompact: true,
    headerMinHeight: 58,
  });

  const mobileContent = (
    <Stack flex={1} maxWidth="100%" mx="auto" height="100%">
      {viewMode === ViewMode.tile ? null : (
        <>
          <Box px={2} pt={2}>
            <CustomBannerHeader />
          </Box>
          <Stack
            mt={2}
            px={2}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={1}
          >
            <TextLineClamp variant="h6" lineClamp={2}>
              {title}
            </TextLineClamp>

            <Stack flexDirection="row" height={36} width={"100%"} gap={2}>
              <Button
                variant="contained"
                startIcon={<DownloadPDF disabled />}
                sx={{ flex: 1 }}
                disabled
              >
                PDF
              </Button>
              <Button
                variant="contained"
                startIcon={<DownloadCSV disabled />}
                sx={{ flex: 1 }}
                disabled
              >
                CSV
              </Button>
            </Stack>
          </Stack>
          <Stack gap={1} mx={2} mt={2}>
            {infoAccordion}
            {analyticsAccordion}
          </Stack>
        </>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <>
          {!!rowCount && (
            <>
              {viewMode === ViewMode.table ? (
                <Stack>{toolbarMobile} </Stack>
              ) : null}
              <Stack flex={1} overflow="hidden">
                {dataViewElement}
              </Stack>
            </>
          )}
        </>
      </Paper>
    </Stack>
  );

  const scrollbarSx = useCustomScrollBarStyles({
    overflowY: "scroll",
    overflowX: "hidden",
    size: "thin",
  });

  const desktopContent = (
    <Stack height="100%" overflow="hidden" flex={1} width="100%" pt={3}>
      <Box width={896} px={2} mx="auto">
        <CustomBannerHeader />
      </Box>
      <Stack height="100%" sx={scrollbarSx}>
        <Stack height="100%" maxWidth={900} mx="auto" pl={"4px"}>
          <Stack
            mt={2}
            px={2}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexWrap: { sm: "wrap", md: "nowrap" } }}
            gap={{ sm: 1, md: 8 }}
          >
            <Stack>
              <TextLineClamp variant="h6" pr={3.5} lineClamp={3}>
                {title}
              </TextLineClamp>
            </Stack>

            <Stack flexDirection="row" height={42} width={222} gap={2}>
              <Button
                variant="contained"
                startIcon={<DownloadPDF disabled />}
                sx={{ width: 104 }}
                disabled
              >
                PDF
              </Button>
              <Button
                variant="contained"
                startIcon={<DownloadCSV disabled />}
                sx={{ width: 102 }}
                disabled
              >
                CSV
              </Button>
            </Stack>
          </Stack>
          <Stack gap={1} mx={2} mt={2}>
            {infoAccordion}
            {analyticsAccordion}
          </Stack>
          <Paper
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${palette.divider}`,
              m: 2,
              minHeight: "500px",
            }}
          >
            {!!rowCount && (
              <>
                {toolbarDesktop}
                <Stack flex={1} overflow="hidden">
                  {dataViewElement}
                </Stack>
              </>
            )}
          </Paper>
        </Stack>
      </Stack>
    </Stack>
  );

  return isMobile ? mobileContent : desktopContent;
}
