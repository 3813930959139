import { AppRouteBuilder } from "src/models/AppRoute";
import { EntitlementGuard } from "src/components/EntitlementGuard";
import { FeatureGateDemoPage } from "./FeatureGateDemo.page";

export const featureGateDemoRoute = new AppRouteBuilder({
  path: "/feature-gate-demo",
  element: (
    <EntitlementGuard
      component={FeatureGateDemoPage}
      entitlementKey="enableEditWatchList"
      allow={() => false}
      redirectTo="/"
      // silent
    />
  ),
});
