import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/mediacenter/{userid}/items/{itemid}/media";

export function downloadMediaCenterMedia({
  userId,
  itemId,
  title,
}: {
  userId: string;
  itemId: string;
  title: string;
}) {
  const url = makeApiUrl(apiKey, {
    path: {
      userid: userId,
      itemid: itemId,
    },
  });

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", title.replace(/ /g, "_"));
  link.click();
}
