import { LinearProgress, List, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { ListBaseProps } from "./ListBase.model";
import { ListBaseEmpty } from "./ListBaseEmpty";
import { ListBaseFooter } from "./ListBaseFooter";
import { ListBaseLoading } from "./ListBaseLoading";

export function ListBase<T>({
  id,
  rows,
  renderItem,
  renderFooter,
  renderEmpty = ListBaseEmpty,
  renderLoading = ListBaseLoading,
  hideFooter,
  ListProps,
  loading,
  rowCount,
  paginationModel,
  onScroll,
  onPaginationModelChange,
  // scroll box props
  customScrollBarStyle,
  renderCustomFooterEl,
  nextPageButtonIsDisabled,
  renderLabelDisplayedRows,
}: ListBaseProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const { page = 0 } = paginationModel || {};

  useEffect(() => {
    // reset scroll position on page change
    ref.current?.scroll({ top: 0 });
  }, [page]);

  const renderFooterWrapper = () => {
    if (hideFooter) {
      return null;
    }

    if (renderFooter) {
      return renderFooter(rows);
    }

    return (
      <ListBaseFooter
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        renderCustomFooterEl={renderCustomFooterEl}
        nextPageButtonIsDisabled={nextPageButtonIsDisabled}
        renderLabelDisplayedRows={renderLabelDisplayedRows}
      />
    );
  };

  return (
    <Stack height="100%" overflow="hidden" position="relative">
      {rows.length
        ? loading && (
            <LinearProgress
              sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
            />
          )
        : loading
        ? renderLoading()
        : renderEmpty()}

      {!!rows.length && (
        <ScrollBox
          flex={1}
          customScrollBarStyle={customScrollBarStyle}
          ref={ref}
          children={
            <List
              {...ListProps}
              disablePadding
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                ...ListProps?.sx,
              }}
              children={rows.map(renderItem)}
            />
          }
          onScroll={onScroll}
        />
      )}

      {renderFooterWrapper()}
    </Stack>
  );
}
