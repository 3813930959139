import { sum } from "lodash";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import { components } from "@tveyes/twosionwebapischema";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import { AppChartContainer } from "./AppChartContainer";

type AppPieChartData = {
  series?: components["schemas"]["MentionCompositeAnalysisSeriesDTO"][] | null;
  dates?: string[] | null;
};

type AppPieChartProps = {
  title: string;
  data?: AppPieChartData;
  isLoading: boolean;
};

export function AppPieChart({ title, data, isLoading }: AppPieChartProps) {
  const { getColorForId } = useAppChartColorMapper();

  const total = data?.series
    ? data?.series?.reduce((acc, value) => {
        const values = value.values?.map(({ value }) => value);
        acc += sum(values);
        return acc;
      }, 0)
    : 0;

  return (
    <AppChartContainer title={title} legend={data?.series}>
      <PieChart
        loading={isLoading}
        slotProps={{
          legend: { hidden: true },
          pieArcLabel: {
            color: "red",
          },
        }}
        margin={{
          bottom: 48,
          right: 8,
          left: 8,
          top: 0,
        }}
        colors={["red", "green", "blue"]}
        series={[
          {
            startAngle: -90,
            endAngle: 90,
            innerRadius: "50%",
            outerRadius: "150%",
            cy: "100%",
            cx: "50%",
            type: "pie",
            valueFormatter: (item) => `${item.value.toFixed(1)}%`,
            data:
              data?.series?.map(({ queryId, queryTitle, values }) => {
                const value = sum(values?.map(({ value }) => value) ?? []);

                return {
                  id: queryId,
                  value: (value / total) * 100,
                  label: queryTitle ?? "",
                  color: getColorForId(queryId).bg,
                };
              }) || [],
            arcLabel: "formattedValue",
            arcLabelMinAngle: 15,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: getColorForId().fg,
            fontSize: 12,
          },
        }}
      />
    </AppChartContainer>
  );
}
