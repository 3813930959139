import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/Account/{id}";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
//FIXME: Update missing property annualizedRecurringRevenue/annualRecurringRevenue
type ResponseBody =
  Endpoint["responses"][200]["content"]["application/json"] & {
    annualRecurringRevenue?: number;
  };

export type AccountResponse = ResponseBody;
export const accountKey = "accountKey";

export function useAccount({
  request,
  options,
}: {
  request?: Parameters;
  options?: UseQueryOptions<ResponseBody, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<ResponseBody, unknown>({
    queryKey: [accountKey, request],
    ...options,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request);
      const { data } = await axios<ResponseBody>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
