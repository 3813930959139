import { AppRouteBuilder } from "src/models/AppRoute";
import { WatchListUpdatePage } from "./WatchListUpdate.page";

export type WatchListUpdatePathParam = {
  queryId: string;
};

export const watchListUpdateRoute =
  new AppRouteBuilder<WatchListUpdatePathParam>({
    path: "/watchlist/update/:queryId",
    element: <WatchListUpdatePage />,
  });
