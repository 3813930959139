import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";

export const createNewAccountBody = (
  data: NewAccountFormValues,
  contentFilters: ContentAccessFilter | null,
  accountId?: string
) => {
  const body = {
    ...data,
    //Default value
    seatCount: 5,
    contentAccessFilter: contentFilters,
    //FIXME: DisplayName needs BE fix, should probably be removed
    entitlements: {
      enableEditWatchList: {
        value: data.enableEditWatchList,
        DisplayName: "string",
      },
      enableAdFiltering: {
        value: data.enableAdFiltering,
        DisplayName: "string",
      },
      enableMediaDownloads: {
        value: data.enableMediaDownloads,
        DisplayName: "string",
      },
      enableReportDownloads: {
        value: data.enableReportDownloads,
        DisplayName: "string",
      },
      enableCustomReportBanner: {
        value: data.enableCustomReportBanner,
        DisplayName: "string",
      },
      limitItemsPerEmail: data.limitItemsPerEmail
        ? {
            value: data.limitItemsPerEmail,
            DisplayName: "string",
          }
        : null,
      limitWatchlistSize: data.limitWatchlistSize
        ? {
            value: data.limitWatchlistSize,
            DisplayName: "string",
          }
        : null,

      limitEventsInReport: data.limitEventsInReport
        ? {
            value: data.limitEventsInReport,
            DisplayName: "string",
          }
        : null,
      limitEventsInCSV: data.limitEventsInCSV
        ? {
            value: data.limitEventsInCSV,
            DisplayName: "string",
          }
        : null,
      limitEventsInPDF: data.limitEventsInPDF
        ? {
            value: data.limitEventsInPDF,
            DisplayName: "string",
          }
        : null,
      limitScratchReportItems: data.limitScratchReportItems
        ? {
            value: data.limitScratchReportItems,
            DisplayName: "string",
          }
        : null,
      limitEventsInReportEmail: data.limitEventsInReportEmail
        ? {
            value: data.limitEventsInReportEmail,
            DisplayName: "string",
          }
        : null,
      limitEventsInAlertEmail: data.limitEventsInAlertEmail
        ? {
            value: data.limitEventsInAlertEmail,
            DisplayName: "string",
          }
        : null,
    },
  };
  if (accountId) {
    body.id = accountId;
  }
  return body;
};
