import { AddToQueue } from "@mui/icons-material";
import { Stack, Typography, alpha, useTheme } from "@mui/material";

export function TableBaseNoRowsOverlay() {
  const { palette } = useTheme();
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Stack alignItems="center" justifyContent="center" gap={5}>
        <AddToQueue
          sx={{
            transform: `scale(4.5)`,
            color: alpha(palette.primary.light, 0.5),
          }}
        />
        <Typography variant="subtitle1" color="text.disabled">
          No Data
        </Typography>
      </Stack>
    </Stack>
  );
}
