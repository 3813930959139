import { SetStateAction, useCallback, useMemo, useState } from "react";
import { createFilterOptions, useMediaQuery, useTheme } from "@mui/material";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  gridClasses,
} from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import {
  MediaCenterNoFilteredEventsOverlay,
  MediaCenterNoSavedEventsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useTagListData } from "src/api/useTagListData";
import { useMediaCenterTagAppend } from "src/api/useMediaCenterTagAppend";
import { useMediaCenterTagsDelete } from "src/api/useMediaCenterTagsDelete";
import { MediaCenterTableClasses } from "./MediaCenterTable.const";
import { MediaCenterTableProps } from "./MediaCenterTable.model";
import { makeMediaCenterTableColumns } from "./MediaCenterTable.utils";

export type ResizedColumns = { [k: string]: number };

export function MediaCenterTable({
  downloadMediaItem,
  addItemToReport,
  hasFilters,
  ...props
}: MediaCenterTableProps) {
  const [inputValue, setInputValue] = useState("");
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [resizedColumns, setResizedColumns] = useState<ResizedColumns>({});

  const { typography, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const tagsListData = useTagListData();

  const appendTag = useMediaCenterTagAppend({});
  const handleAppendTags = useCallback(
    (id: string, tagName: string[]) => {
      appendTag.mutate({
        path: {
          itemid: id,
        },
        body: tagName,
      });
    },
    [appendTag]
  );

  const tagDelete = useMediaCenterTagsDelete({});
  const handleTagDelete = useCallback(
    (id: string, tagName: string[]) => {
      if (!tagName) return;

      tagDelete.mutate({
        path: {
          itemid: id,
        },
        body: tagName,
      });
    },
    [tagDelete]
  );

  const filter = createFilterOptions<string>();
  const tagsData = props.rows.map((row) => ({ id: row.id, tags: row.tags }));
  const columns = useMemo(() => {
    return makeMediaCenterTableColumns({
      options: tagsListData.tags,
      resizedColumns,
      tagsData,
      inputValue,
      setInputValue,
      filter,
      handleAppendTags,
      handleTagDelete,
      downloadMediaItem,
      addItemToReport,
    });
  }, [
    tagsListData.tags,
    resizedColumns,
    tagsData,
    inputValue,
    setInputValue,
    filter,
    handleAppendTags,
    handleTagDelete,
    downloadMediaItem,
    addItemToReport,
  ]);

  const handleCellModesModelChange = useCallback(
    (newModel: SetStateAction<GridCellModesModel>) => {
      setCellModesModel(newModel);
    },
    []
  );

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) return;

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  return (
    <TableBase
      columns={columns}
      columnHeaderHeight={isMobile ? 42 : undefined}
      disableRowSelectionOnClick
      {...props}
      checkboxSelection
      getRowId={(row) => row.id}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      onColumnResize={(params) => {
        setResizedColumns((prevValue) => {
          const newResizedColumn = { [params.colDef.field]: params.width };
          return { ...prevValue, ...newResizedColumn };
        });
      }}
      slots={{
        noRowsOverlay: hasFilters
          ? MediaCenterNoFilteredEventsOverlay
          : MediaCenterNoSavedEventsOverlay,
        noResultsOverlay: hasFilters
          ? MediaCenterNoFilteredEventsOverlay
          : MediaCenterNoSavedEventsOverlay,
        ...props.slots,
      }}
      sx={{
        // cell styles, these override TableBase cell styles
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
        [`.${MediaCenterTableClasses.cellEvent}`]: {
          ...typography.body1,
        },
      }}
    />
  );
}
