import { AppRouteBuilder } from "src/models/AppRoute";
import { WatchListTermResultClipPage } from "./WatchListTermResultClip.page";

export type WatchListTermResultClipPathParam = {
  queryId: string;
  eventId: string;
};

export const watchListTermResultClipRoute =
  new AppRouteBuilder<WatchListTermResultClipPathParam>({
    path: "/watchlist/:queryId/:eventId",
    element: <WatchListTermResultClipPage />,
  });
