import { useEffect, useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { DateTimeSearchResultList } from "../components/DateTimeSearchResultList/DateTimeSearchResultList";
import { useDateTimeSearchPlayState } from "../components/DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";
import { DateTimeSearchCarousel } from "../components/DateTimeSearchCarousel/DateTimeSearchCarousel";

export function useDateTimeSearchResultDataView({
  data,
  rowCount,
  isLoading,
  isMobile,
}: {
  data?: DateTimeSearchResultItem[];
  isLoading?: boolean;
  isMobile?: boolean;
  isExpandedTextMode?: boolean | null;
  rowCount?: number;
}) {
  const [isExpanded] = useQueryParam("expanded", BooleanParam);
  const { init, setPlayState } = useDateTimeSearchPlayState();

  useEffect(() => {
    const isString = (s: unknown): s is string => typeof s === "string";

    const instanceIds: string[] = (data || [])
      .map(({ id }) => id)
      .filter<string>(isString);

    init(isLoading ? [] : instanceIds);
  }, [data, init, isLoading]);

  return useMemo(() => {
    if (isMobile) {
      return (
        <DateTimeSearchCarousel
          id="date-time-search-carousel"
          data={data || []}
          loading={isLoading}
          flex={1}
          onChange={(val) => setPlayState(val.id ?? "")}
        />
      );
    }

    return (
      <DateTimeSearchResultList
        id="date-time-search-results-list"
        rows={data || []}
        rowCount={rowCount}
        loading={isLoading}
        showFullTranscript={!!isExpanded}
        hideFooter
      />
    );
  }, [isMobile, data, rowCount, isLoading, isExpanded, setPlayState]);
}
