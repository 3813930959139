import { ReactNode, useMemo } from "react";
import { Box, LinearProgress, Skeleton, styled } from "@mui/material";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid-premium";
import { useGridScrollPosition } from "src/utils/useGridScrollPosition";
import { randomBetween } from "src/utils/random";

const delta = 40;

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const TableBaseLoadingOverlay = () => {
  const apiRef = useGridApiContext();

  const scrollLeftPosition = useGridScrollPosition(apiRef);

  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions
    ? dimensions.viewportInnerSize.height - delta
    : 0;

  const { rowHeight, rows } = useGridRootProps();
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

  const columns = apiRef.current.getVisibleColumns();

  const children = useMemo(() => {
    // reseed random number generator to create stable lines between renders
    const random = randomBetween(12345, 25, 75);
    const array: ReactNode[] = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      for (const column of columns) {
        const width = Math.round(random());
        array.push(
          <SkeletonCell
            key={`column-${i}-${column.field}`}
            justifyContent={column.align}
          >
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </SkeletonCell>
        );
      }
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [skeletonRowsCount, columns]);

  return (
    <>
      <LinearProgress color="secondary" />
      {!rows.length ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `${columns
              .map(({ computedWidth }) => `${computedWidth}px`)
              .join(" ")} 1fr`,
            gridAutoRows: rowHeight,
            transform: `translate3d(-${scrollLeftPosition}px, 0px, 0px)`,
          }}
        >
          {children}
        </Box>
      ) : (
        <Box
          width="100%"
          height="calc(100% - 4px)"
          m="auto"
          bgcolor="rgba(0, 0, 0, 0.02);"
          boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
        />
      )}
    </>
  );
};
