import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { PageLoader } from "src/components/PageLoader";
import { GlobalSearchFilled } from "src/components/icons/GlobalSearchFilled";

const snackVariants = [
  "default",
  "success",
  "info",
  "warning",
  "error",
] as const;

export function DevMisc() {
  const { t, i18n, ready } = useTranslation();
  const snackBar = useSnackbar();

  if (!ready) {
    return <PageLoader />;
  }

  return (
    <Stack gap={3}>
      <Typography variant="body1">
        {
          // Translations can come from memory or from json files
          // Access translation by natural language
          t("Choose your language") + ": "
        }
        {
          // access translation by key, can be mush shorter that natural phrase
          // and this may be useful for pluralization
          t(`language.selector.${i18n.language}`)
        }
      </Typography>

      <ToggleButtonGroup value={i18n.language} sx={{ alignSelf: "flex-start" }}>
        {Object.keys(i18n.options.resources || {}).map((langCode) => {
          const langLabel = t(`language.selector.${langCode}`);
          return (
            <ToggleButton
              key={langCode}
              value={langCode}
              onClick={() => i18n.changeLanguage(langCode)}
            >
              {langLabel}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <Stack>
        <Typography>Icon colors: </Typography>
        <Stack direction="row" columnGap={2}>
          <GlobalSearchFilled color="inherit" />
          <GlobalSearchFilled color="primary" />
          <GlobalSearchFilled color="secondary" />
          <GlobalSearchFilled color="action" />
          <GlobalSearchFilled color="disabled" />
          <GlobalSearchFilled color="error" />
          <GlobalSearchFilled color="info" />
          <GlobalSearchFilled color="success" />
          <GlobalSearchFilled color="warning" />
        </Stack>
      </Stack>

      <Stack>
        <Typography>Snack variants: </Typography>
        <Stack direction="row" columnGap={2}>
          {snackVariants.map((variant) => (
            <Button
              onClick={() => {
                snackBar.enqueueSnackbar({
                  message:
                    "Test very looooong snack content that should look nice regardless of text length",
                  variant,
                  autoHideDuration: null,
                });
              }}
            >
              {variant}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
