import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { AnalyticsLiteFilterFormValues } from "./AnalyticsLiteFilterForm.model";
import { AnalyticsLiteFilterFormSchema } from "./AnalyticsLiteFilterForm.schema";

export const makeEmptyAnalyticsLiteFilterFormValues = (
  merge?: Partial<AnalyticsLiteFilterFormValues>
): AnalyticsLiteFilterFormValues => ({
  // keyword
  termsInclude: {
    list: [],
    logic: "or",
  },
  termsExclude: {
    list: [],
    logic: "or",
  },
  // keyword

  // sources
  sourcesInclude: {
    list: merge?.sourcesInclude?.list || [],
    logic: merge?.sourcesInclude?.logic || "or",
  },
  sourcesExclude: {
    list: merge?.sourcesExclude?.list || [],
    logic: merge?.sourcesExclude?.logic || "or",
  },
  // sources
  advertisement: merge?.advertisement || queryAdvertisementValues.all,

  // program
  programInclude: {
    list: merge?.programInclude?.list || [],
    logic: merge?.programInclude?.logic || "or",
  },
  programExclude: {
    list: merge?.programExclude?.list || [],
    logic: merge?.programExclude?.logic || "or",
  },
  // program
});

type UseAnalyticsLiteFilterFormArgs = {
  initial?: Partial<AnalyticsLiteFilterFormValues>;
};

export function useAnalyticsLiteFilterForm(
  params?: UseAnalyticsLiteFilterFormArgs
) {
  const { initial } = params || {};
  const emptyFormValues = makeEmptyAnalyticsLiteFilterFormValues();
  const formHook = useForm<AnalyticsLiteFilterFormValues>({
    mode: "all",
    resolver: joiResolver(AnalyticsLiteFilterFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}
