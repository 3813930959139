import { Box, ToggleButtonGroupProps } from "@mui/material";
import { TooltipBaseMobile } from "../TooltipBaseMobile/TooltipBaseMobile";
import { AddIconButton } from "../AddIconButton/AddIconButton";

export const ReportAddIconButton = ({
  disabled,
  onClick,
  size,
}: {
  disabled: boolean;
  onClick: () => void;
  size?: ToggleButtonGroupProps["size"];
}) => {
  return (
    <Box>
      <TooltipBaseMobile
        width={250}
        placement="bottom"
        disableHoverListener={!disabled}
        title='You have no more events from preselected to add to this report. Go to "Power Search" or "Watch Term" to add them.'
      >
        <AddIconButton onClick={onClick} disabled={disabled} size={size} />
      </TooltipBaseMobile>
    </Box>
  );
};
