import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useAuth0 } from "@auth0/auth0-react";
import { useReportsNotify } from "src/api/useReportsNotify";
import { getFullReportDetailsLink } from "src/utils/getFullPublicPageLink";
import { ReportItem } from "src/models/Report";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";
import { ShareFormValues } from "../ShareForm/ShareForm.model";
import { ShareFormSchema } from "../ShareForm/ShareForm.schema";
import { ShareBaseDialog } from "../ShareBaseDialog/ShareBaseDialog";

type ShareReportDialogProps = Omit<DialogBaseProps, "onSubmit" | "onClose"> & {
  reports: ReportItem[];
  onSubmit: () => void;
  onClose: () => void;
  copyShareLinkCallback: () => void;
};

const defaultValues = {
  recipients: [],
  subject: "",
  note: "",
};

export const ShareReportDialog = ({
  reports,
  onClose,
  onSubmit,
  copyShareLinkCallback,
  ...props
}: ShareReportDialogProps) => {
  const { user } = useAuth0();
  const [instantLink, setInstantLink] = useState("");
  const [sendCopyValue, setSendCopyValue] = useState(false);

  const getInstantLink = () => {
    setInstantLink(getFullReportDetailsLink(reports[0].id));
  };

  const formHook = useForm<ShareFormValues>({
    mode: "all",
    resolver: joiResolver(ShareFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;
  const formValues = formHook.watch();

  const reportsNotifyMutation = useReportsNotify({});

  const notifyRecipients = () => {
    const { recipients, subject, note } = formValues;
    reportsNotifyMutation.mutate({
      reportIds: reports.map((report) => report.id),
      recipients:
        sendCopyValue && user?.email ? [user.email, ...recipients] : recipients,
      subject,
      message: note,
    });
  };

  const handleSubmitWrapper = handleSubmit(() => {
    notifyRecipients();
    onClose();
    onSubmit();
  });

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open]);

  return (
    <FormProvider {...formHook}>
      <ShareBaseDialog
        hideInstantLink={reports.length > 1}
        instantLinkValue={instantLink}
        getInstantLink={getInstantLink}
        copyShareLinkCallback={copyShareLinkCallback}
        onClose={onClose}
        isBusy={!formState.isValid || !formState.dirtyFields?.recipients}
        handleSubmit={handleSubmitWrapper}
        sendCopyValue={sendCopyValue}
        setSendCopyValue={setSendCopyValue}
        {...props}
      />
    </FormProvider>
  );
};
