import { PushPin, PushPinOutlined } from "@mui/icons-material";
import { ToggleButton, useTheme } from "@mui/material";

type PinIconProps = {
  /** is pinned */
  value: boolean;
  /** change pinned status */
  onChange: () => void;
};

export const WatchQueryTableColumnPinMobile = ({
  value,
  onChange,
}: PinIconProps) => {
  const { palette } = useTheme();

  const Icon = value ? PushPin : PushPinOutlined;

  return (
    <ToggleButton
      sx={{ ml: 0.5 }}
      value="pinned"
      selected={value}
      onChange={onChange}
    >
      <Icon fontSize="small" sx={{ color: palette.action.active }} />
    </ToggleButton>
  );
};
