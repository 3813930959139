import axios from "axios";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { useSessionContext } from "./useSessionContext";
import { WatchTermResultsResponse } from "./useWatchTermResults";

const apiKey: keyof paths = "/api/user/{userId}/query/{queryId}/matches";
const method: keyof paths[typeof apiKey] = "post";

export const watchTermResultQueryInfiniteKey =
  "watch-term-results-infinite-scroll";

type Endpoint = paths[typeof apiKey][typeof method];
type EndpointParams = Endpoint["parameters"];

export type WatchTermResultItem = Required<
  components["schemas"]["EventHighlightsDTO"]
>;

type UseWatchTermResultsInfiniteParams = {
  path: Omit<Required<Endpoint>["parameters"]["path"], "userId">;
  body?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
  query: EndpointParams["query"];
};

const pageSize = 24;

export function useWatchTermResultsInfinite({
  params,
  options,
  keepPreviousData,
}: {
  params: UseWatchTermResultsInfiniteParams;
  options?: UseInfiniteQueryOptions<WatchTermResultsResponse, unknown>;
  keepPreviousData: boolean;
}) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();
  const { path, query } = params;

  return useInfiniteQuery<WatchTermResultsResponse, unknown>({
    ...options,
    keepPreviousData,
    queryKey: [watchTermResultQueryInfiniteKey, params],
    initialData: () => {
      const cacheWatchTermResults =
        queryClient.getQueryData<WatchTermResultsResponse>(
          [
            apiKey,
            {
              path,
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchTermResults || {};
      const pages: WatchTermResultsResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = results.slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;
      const after = pageParam?.after || undefined;

      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl<EndpointParams>(apiKey, {
        path: {
          userId,
          ...path,
        },
        query: {
          from,
          size,
          after,
          startDate: query?.startDate,
          endDate: query?.endDate,
          sortby: query?.sortby,
          orderby: query?.orderby,
          groupDuplicates: query?.groupDuplicates,
        },
      });

      const { data } = await axios<WatchTermResultsResponse>(apiPath, {
        method,
        data: params.body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      /** in case groupDuplicates is set to true */
      /** "after" is overriding "from" on the BE side */
      if (configuration && configuration.next !== null) {
        return {
          after: configuration.next,
          size: pageSize,
        };
      }

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
