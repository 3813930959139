import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths, components } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import {
  PowerSearchQueryValues,
  usePowerSearchQuery,
} from "./usePowerSearchQuery";

const apiPath: keyof paths = "/api/PowerSearch";
const method: keyof paths[typeof apiPath] = "post";

// FIXME: extract to src/models/QueryDefinition
export type WatchQueryDefinition = components["schemas"]["QueryDefinition"];

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type Parameters = Endpoint["parameters"];
type Request = Endpoint["requestBody"]["content"]["application/json"];
type RequestOptions = {
  params?: Parameters;
  body: PowerSearchQueryValues;
  includeFilter?: string[];
};

export type PowerSearchResultItem = Required<
  components["schemas"]["EventHighlightsDTO"]
>;

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type PowerSearchResponse = {
  total?: number;
  results?: PowerSearchResultItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

export const powerSearchKey = apiPath;

export function usePowerSearch({
  request,
  options,
  keepPreviousData = true,
}: {
  request: RequestOptions;
  options?: UseQueryOptions<PowerSearchResponse, unknown>;
  keepPreviousData?: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const powerSearchQuery = usePowerSearchQuery();

  return useQuery<PowerSearchResponse, unknown>({
    ...options,
    keepPreviousData,
    queryKey: [powerSearchKey, request],
    queryFn: async () => {
      powerSearchQuery.save(request.body);
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request.params);

      const body: Request = {
        queryDefinition: {
          ...makeQueryDefinition(request.body),
          includeFilter: request.includeFilter,
        },
      };

      const response = await axios<PowerSearchResponse>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return response.data;
    },
    onError(err) {
      enqueueSnackbar({
        message: `Error fetching Power Search results: ${err}`,
        variant: "error",
      });
    },
  });
}
