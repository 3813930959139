import { IconButton, useTheme } from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useOpenState } from "src/utils/useOpenState";
import { ReportCreateEventsDeleteDialog } from "./ReportCreateEventsDeleteDialog";

export const ReportCreateEventsItemActions = ({
  value,
}: {
  value: PowerSearchResultItem;
}) => {
  const { palette } = useTheme();
  const deleteEventDialog = useOpenState();

  return (
    <>
      <IconButton onClick={deleteEventDialog.show}>
        <DeleteOutlineIcon sx={{ color: palette.action.active }} />
      </IconButton>
      <ReportCreateEventsDeleteDialog
        open={deleteEventDialog.isOpen}
        onClose={deleteEventDialog.hide}
        isAllSelected={false}
        events={[value]}
      />
    </>
  );
};
