import {
  FormControl,
  FormControlLabel,
  FormLabel,
  PopperProps,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { AppPopper } from "src/components/AppPopper/AppPopper";
import { AppPopperPropsElevation } from "src/components/AppPopper/AppPopper.model";
import { AppPopperArrowSize } from "src/components/AppPopper/components/AppPopperArrow/AppPopperArrow.model";

export function DevAppPopper() {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [placement, setPlacement] = useState<PopperProps["placement"]>("auto");
  const [elevation, setElevation] = useState<AppPopperPropsElevation>(4);
  const [arrowSize, setArrowSize] = useState<AppPopperArrowSize>(
    AppPopperArrowSize.small
  );

  const typographyProps: TypographyProps = {
    align: "center",
    bgcolor: palette.background.default,
    width: 160,
    py: 2,
    variant: "subtitle2",
    color: palette.text.disabled,
  };

  return (
    <Stack height="100%" p={2}>
      <Stack gap={2}>
        <Typography variant="h6">App Popover</Typography>

        <FormControl>
          <FormLabel>Arrow size: {arrowSize}</FormLabel>

          <RadioGroup
            sx={{ flexDirection: "row" }}
            onChange={(_e, nextArrowSize) =>
              setArrowSize(nextArrowSize as AppPopperArrowSize)
            }
          >
            {Object.entries(AppPopperArrowSize).map(([k, v]) => {
              return (
                <FormControlLabel
                  label={k}
                  value={v}
                  key={v}
                  control={<Radio />}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Elevation: {elevation}</FormLabel>
          <Slider
            // min/max must be in range of AppPopperPropsElevation
            min={1}
            max={8}
            value={elevation}
            onChange={(_e, v) => setElevation(v as AppPopperPropsElevation)}
          />
        </FormControl>
      </Stack>

      <Stack m="auto" gap={4}>
        <Stack direction="row" gap={1} justifyContent="center">
          <Typography
            {...typographyProps}
            children="Top End"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("top-end");
            }}
          />

          <Typography
            {...typographyProps}
            children="Top Middle"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("top");
            }}
          />

          <Typography
            {...typographyProps}
            children="Top Start"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("top-start");
            }}
          />
        </Stack>

        <Stack direction="row" gap={4} justifyContent="space-between">
          <Stack gap={1}>
            <Typography
              {...typographyProps}
              children="Left End"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("left-end");
              }}
            />

            <Typography
              {...typographyProps}
              children="Left Middle"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("left");
              }}
            />

            <Typography
              {...typographyProps}
              children="Left Start"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("left-start");
              }}
            />
          </Stack>

          <Stack gap={1}>
            <Typography
              {...typographyProps}
              children="Right End"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("right-end");
              }}
            />

            <Typography
              {...typographyProps}
              children="Right Middle"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("right");
              }}
            />

            <Typography
              {...typographyProps}
              children="Right Start"
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setPlacement("right-start");
              }}
            />
          </Stack>
        </Stack>

        <Stack direction="row" gap={1} justifyContent="center">
          <Typography
            {...typographyProps}
            children="Bottom End"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("bottom-end");
            }}
          />

          <Typography
            {...typographyProps}
            children="Bottom Middle"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("bottom");
            }}
          />

          <Typography
            {...typographyProps}
            children="Bottom Start"
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setPlacement("bottom-start");
            }}
          />
        </Stack>
      </Stack>

      <AppPopper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={placement}
        elevation={elevation}
        arrow={arrowSize}
        disableClickAwayListener
        onClose={() => setAnchorEl(null)}
        children={
          <Stack width={300} height={100} justifyContent="center">
            <Typography
              px={2}
              py={1}
              align="center"
              variant="subtitle2"
              children="Placement"
            />

            <Typography
              px={2}
              py={1}
              align="center"
              variant="subtitle2"
              children={placement?.toUpperCase()}
            />
          </Stack>
        }
      />
    </Stack>
  );
}
