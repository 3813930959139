import { Box, Stack, Typography } from "@mui/material";
import { IntercomButton } from "src/components/IntercomButton/IntercomButton";

export function DevIntercom() {
  return (
    <Stack>
      <Typography variant="h6">Custom Intercom Launcher</Typography>
      <Box
        position="relative"
        width={120}
        height={120}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IntercomButton size="large" color="primary" />
      </Box>
    </Stack>
  );
}
