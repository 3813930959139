import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicReportPDFPage } from "./PublicReportPDF.page";

export type PublicReportPDFPathParams = {
  reportId: string;
  eventId: string;
};

export const publicReportPDFRoute =
  new AppRouteBuilder<PublicReportPDFPathParams>({
    path: "/public/reportPDF/:reportId",
    element: <PublicReportPDFPage />,
  });
