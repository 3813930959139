import {
  Button,
  Stack,
  buttonBaseClasses,
  dialogClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useManagementViewUpdate } from "src/api/useManagementViewUpdate";
import { DialogBase } from "../DialogBase/DialogBase";
import { UserInformationUpdateForm } from "../UserInformationUpdateForm/UserInformationUpdateForm";
import { UserInformationUpdateDialogProps } from "./UserInformationUpdateDialog.model";
import { UserInformationUpdateFormValues } from "../UserInformationUpdateForm/UserInformationUpdateForm.model";
import { UserInformationSettingsFormSchema } from "../UserInformationUpdateForm/UserInformationUpdateForm.schema";
import { LoadingButton } from "../buttons/LoadingButton";

export function UserInformationUpdateDialog({
  onClose,
  user,
  ...props
}: UserInformationUpdateDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const initialFormValues = useMemo(
    () => ({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phoneNumber: user?.phone || "",
    }),
    [user]
  );

  const formHook = useForm<UserInformationUpdateFormValues>({
    mode: "all",
    resolver: joiResolver(UserInformationSettingsFormSchema),
    defaultValues: initialFormValues,
  });
  const { formState, handleSubmit, reset } = formHook;

  useEffect(() => {
    reset(initialFormValues);
  }, [user, reset, initialFormValues]);

  useEffect(() => {
    // reset when Dialog is closed,  but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initialFormValues);
    }
  }, [reset, props.open, initialFormValues]);

  const managementViewUpdate = useManagementViewUpdate({
    options: {
      onSuccess: () => {
        enqueueSnackbar(
          t("managementViewUpdateMessage", "Update request sent"),
          {
            variant: "success",
          }
        );
        onClose && onClose();
      },
    },
  });

  const handleSubmitWrapper = handleSubmit(
    (data) => {
      managementViewUpdate.mutate({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phoneNumber,
        accountName: user?.accountName,
        timezone: user?.timezone,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitUpdateUserInformation:error", errors);
    }
  );

  const isBusy = formState.isSubmitting || managementViewUpdate.isLoading;

  return (
    <FormProvider {...formHook}>
      <DialogBase
        sx={{
          [`.${dialogClasses.container}`]: {
            margin: "24px",
          },
        }}
        disableHeaderDivider
        fullWidth
        {...props}
        children={<UserInformationUpdateForm user={user} isBusy={isBusy} />}
        footer={
          <Stack
            direction="row"
            width={"100%"}
            spacing={2}
            minHeight={42}
            sx={{ justifyContent: "end" }}
          >
            <Button
              sx={{
                width: isMobile ? "50%" : 84,
                display: { md: "flex" },
                color: "rgba(4, 36, 65, 1)",
                borderColor: "rgba(4, 36, 65, 1)",
                "&:hover": {
                  borderColor: "rgba(4, 36, 65, 1)",
                },
              }}
              onClick={onClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: isMobile ? "50%" : 84,
                [`.${buttonBaseClasses.root}`]: { marginLeft: "8px" },
              }}
              loading={isBusy}
              disabled={!formState.isValid || !formState.isDirty}
              onClick={handleSubmitWrapper}
            >
              Send
            </LoadingButton>
          </Stack>
        }
      />
    </FormProvider>
  );
}
