import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PopperBase } from "../PopperBase/PopperBase";

export type DrawerNavMenuItemProps = {
  icon: JSX.Element;
  label: string;
  tooltip?: string;
  path: string;
  secondaryIcon?: JSX.Element;
  popperProps?: {
    isOpen: boolean;
    hide: () => void;
    content: JSX.Element;
  };
  onClick?: () => void;
};
export const DrawerNavMenuItem = ({
  tooltip,
  popperProps,
  ...props
}: DrawerNavMenuItemProps) => {
  const { pathname } = useLocation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const menuItemRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

  useEffect(() => {
    setAnchorEl(menuItemRef.current);
  }, [menuItemRef]);

  const isExternalLink = props.path.startsWith("https://");

  const selected =
    props.path === "/"
      ? pathname === props.path
      : pathname.startsWith(props.path);

  const item = (
    <ListItem disablePadding disableGutters sx={{ height: 48 }}>
      <ListItemButton
        disableGutters
        selected={selected}
        sx={{ borderRadius: 2, padding: 1, minWidth: 48 }}
        component={Link}
        to={props.path}
        target={isExternalLink ? "_blank" : undefined}
        onClick={props.onClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 32,
            marginRight: 2,
            justifyContent: "center",
            color: selected ? "primary.main" : "text.secondary",
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.label}
          sx={{
            "& span": {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          }}
        />
        {props.secondaryIcon && (
          <ListItemIcon
            sx={{
              minWidth: 20,
              justifyContent: "center",
              color: selected ? "primary.main" : "text.secondary",
            }}
          >
            {props.secondaryIcon}
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  );

  const navMenuItem = popperProps ? (
    <Stack position="relative" ref={menuItemRef}>
      {item}
      <PopperBase
        open={popperProps?.isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          popperProps.hide();
        }}
        placement={isMobile ? "right-start" : "right"}
        children={popperProps.content}
        sx={{
          width: isMobile ? 288 : 358,
          ml: isMobile ? "65px !important" : "24px !important",
          top: isMobile ? "-155px !important" : "unset",
        }}
        arrowSx={{
          top: "9px !important",
          ml: "-12px",
        }}
      />
    </Stack>
  ) : (
    item
  );

  return (
    <Tooltip placement="right" title={tooltip} arrow children={navMenuItem} />
  );
};
