import { Fragment, useState } from "react";
import { MoreVertOutlined } from "@mui/icons-material";
import { ButtonProps, IconButton } from "@mui/material";
import { WatchQueryClusterDeleteDialog } from "src/components/WatcClusterDeleteDialog/WatchClusterDeleteDialog";
import { WatchQueryItemMenu } from "./WatchListClusterItemMenu";

export function WatchListClusterItemMenuButton({
  clusterId,
  clusterTitle,
}: {
  clusterId: string;
  clusterTitle: string;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const buttonId = `query-table-row-cluster-${clusterId}-button`;
  const menuId = `query-table-row-cluster-${clusterId}-menu`;

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <IconButton
        id={buttonId}
        aria-controls={!!anchorEl ? menuId : undefined}
        aria-expanded={!!anchorEl ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        children={<MoreVertOutlined />}
      />
      <WatchQueryItemMenu
        setShowDeleteModal={setShowDeleteModal}
        id={menuId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
        clusterId={clusterId}
        clusterTitle={clusterTitle}
      />
      <WatchQueryClusterDeleteDialog
        open={showDeleteModal}
        clusterId={clusterId}
        clusterTitle={clusterTitle}
        onClose={() => setShowDeleteModal(false)}
      />
    </Fragment>
  );
}
