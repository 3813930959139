import {
  Button,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { AccountsManagementContentFilterForm } from "../AccountsManagementContentFilterForm/AccountsManagementContentFilterForm";
import { AccountsManagementContentCodeFilter } from "../AccountsManagementContentCodeFilter/AccountsManagementContentCodeFilter";

export function AccountsContentTab({
  contentFilters,
  onSubmit,
  onReset,
  disabled,
}: {
  contentFilters?: ContentAccessFilter | null;
  onSubmit?: () => void;
  onReset?: () => void;
  disabled?: boolean;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));

  return (
    <Grid
      container
      gap={2}
      direction={isMobile ? "column" : "row"}
      flexWrap={"nowrap"}
    >
      <Grid item xs={12} md={6}>
        <Stack component={Paper} px={4}>
          <AccountsManagementContentFilterForm
            disabled={disabled}
            height={onReset && onSubmit ? undefined : 338}
          />
          {onReset && onSubmit ? (
            <Stack direction="row" justifyContent="flex-end" gap={2} mb={3}>
              <Button
                variant="outlined"
                sx={{ height: "42px", width: "124px", fontSize: "15px" }}
                onClick={onReset}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: "42px", width: "124px", fontSize: "15px" }}
                onClick={onSubmit}
              >
                Save
              </Button>
            </Stack>
          ) : null}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack component={Paper} px={4}>
          <AccountsManagementContentCodeFilter
            contentFilters={contentFilters}
            height={onReset && onSubmit ? undefined : 338}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
