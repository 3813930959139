import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { updateDateTimeRange } from "src/utils/updateDateTimeRange";

export type UseTrimRangeValue = [Date | null, Date | null];
type UseTrimRangeUpdateFn = (args: {
  offsetInSec?: number;
  offsetOutSec?: number;
}) => void;
type UseTrimRangeStateResult = [
  UseTrimRangeValue,
  UseTrimRangeUpdateFn,
  Dispatch<SetStateAction<UseTrimRangeValue>>
];

/**
 * A hook to manage trim range state
 */
export const useTrimRangeState = ({
  bounds,
  minSpanSec,
}: {
  bounds: [Date, Date] | null;
  minSpanSec: number;
}): UseTrimRangeStateResult => {
  const [trimRange, setTrimRange] = useState<UseTrimRangeValue>([null, null]);

  /** update trim range in out offsets */
  const update: UseTrimRangeUpdateFn = useCallback(
    ({ offsetInSec, offsetOutSec }) => {
      if (!bounds) {
        return;
      }

      setTrimRange((prevRange) => {
        const nextRange = updateDateTimeRange({
          offsetInSec,
          offsetOutSec,
          prev: prevRange,
          bounds: bounds,
          minSpanSec,
        });

        return nextRange;
      });
    },
    [bounds, minSpanSec]
  );

  return [trimRange, update, setTrimRange];
};
