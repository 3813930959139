import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const apiKey: keyof paths = "/api/mediacenter/{userid}/items";
const method: keyof paths[typeof apiKey] = "post";

export const mediaCenterFetchKey = "fetch-media-center-items";

type Endpoint = paths[typeof apiKey][typeof method];
type Parameters = Endpoint["parameters"];
type RequestOptions = {
  params?: {
    query: Parameters["query"];
    body?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
  };
};

export type MediaCenterItem = Required<
  components["schemas"]["MediaCenterEventItemHeader"]
>;

export type Response = {
  total?: Endpoint["responses"]["200"]["content"]["application/json"]["total"];
  results?: MediaCenterItem[];
  configuration?: Endpoint["responses"]["200"]["content"]["application/json"]["configuration"];
};

export function useMediaCenter({
  request,
  options,
}: {
  request: RequestOptions;
  options: UseQueryOptions<Response, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  return useQuery<Response, unknown>({
    ...options,
    keepPreviousData: true,
    queryKey: [mediaCenterFetchKey, request],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiKey, {
        path: {
          userid: userId,
        },
        query: request.params?.query,
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request.params?.body,
      });

      return data;
    },
    onError(err) {
      enqueueSnackbar({
        message: `Error fetching Media Center results: ${err}`,
        variant: "error",
      });
    },
  });
}
