import { ReactNode } from "react";
import {
  useTheme,
  Stack,
  TablePagination,
  TablePaginationProps,
  Box,
  LabelDisplayedRowsArgs,
} from "@mui/material";
import {
  DataGridPremiumProps,
  GridPaginationModel,
} from "@mui/x-data-grid-premium";
import { PaginationActions } from "src/components/PaginationActions/PaginationActions";

type ListBaseFooterProps = {
  rowCount?: number;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: NonNullable<
    DataGridPremiumProps["onPaginationModelChange"]
  >;
  renderCustomFooterEl?: () => ReactNode;
  nextPageButtonIsDisabled?: boolean;
  renderLabelDisplayedRows?: (
    paginationInfo: LabelDisplayedRowsArgs
  ) => ReactNode;
};

export function ListBaseFooter({
  rowCount = 0,
  paginationModel,
  onPaginationModelChange,
  renderCustomFooterEl,
  nextPageButtonIsDisabled = false,
  renderLabelDisplayedRows,
}: ListBaseFooterProps) {
  const { palette } = useTheme();
  const { page = 0, pageSize = 0 } = paginationModel || {};

  const onPageChangeWrapper: TablePaginationProps["onPageChange"] = (
    e,
    nextPage
  ) => {
    onPaginationModelChange &&
      onPaginationModelChange(
        {
          page: nextPage,
          pageSize,
        },
        {}
      );
  };

  const onRowsPerPageChangeWrapper: TablePaginationProps["onRowsPerPageChange"] =
    (e) => {
      onPaginationModelChange &&
        onPaginationModelChange(
          {
            page,
            pageSize: parseInt(e.target.value, 10),
          },
          {}
        );
    };

  const renderLabelDisplayedRowsContent = (
    paginationInfo: LabelDisplayedRowsArgs
  ) => {
    const { from, to, count } = paginationInfo;
    return `${from.toLocaleString()} - ${to.toLocaleString()} of ${count.toLocaleString()}`;
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderTop={`1px solid ${palette.divider}`}
      minHeight={53}
    >
      {renderCustomFooterEl ? renderCustomFooterEl() : null}
      {rowCount !== undefined && paginationModel && onPaginationModelChange && (
        <Box ml="auto">
          <TablePagination
            count={rowCount}
            page={page}
            rowsPerPage={pageSize}
            component="div"
            rowsPerPageOptions={[25, 50, 100]}
            onPageChange={onPageChangeWrapper}
            onRowsPerPageChange={onRowsPerPageChangeWrapper}
            ActionsComponent={PaginationActions}
            labelDisplayedRows={
              renderLabelDisplayedRows || renderLabelDisplayedRowsContent
            }
            slotProps={{
              actions: {
                nextButton: { disabled: nextPageButtonIsDisabled },
              },
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
