import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { accountBannerKey } from "./useAccountBanner";

const apiPath: keyof paths = "/api/banner/account/{accountId}";
const method: keyof paths[typeof apiPath] = "delete";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"][204];

type RequestMutationFnParams = {
  path: {
    accountId: Endpoint["parameters"]["path"]["accountId"];
  };
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

const accountBannerDeleteKey = "accountBannerDeleteKey";

export function useAccountBannerDelete({
  options,
}: {
  options?: RequestOptions;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [accountBannerDeleteKey],
    mutationFn: async ({ path }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        path: {
          accountId: path.accountId,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: accountBannerKey,
      });
      enqueueSnackbar(t("accountBannerDelete", "Account banner deleted"), {
        variant: "success",
      });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError?.(error, variables, context);
    },
  });
}
