import { Badge, Button, ButtonProps, badgeClasses } from "@mui/material";

export const ShareButtonDesktop = ({
  showShareDialog,
  badgeContent,
  ...props
}: ButtonProps & {
  showShareDialog: () => void;
  badgeContent?: number;
}) => {
  const shareButton = (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        minWidth: { xs: 100, md: 160 },
        height: 42,
      }}
      onClick={showShareDialog}
      {...props}
    >
      Share
    </Button>
  );

  return badgeContent ? (
    <Badge
      badgeContent={badgeContent}
      color={props.disabled ? "default" : "success"}
      sx={{
        [`.${badgeClasses.badge}`]: {
          top: 21,
          right: 34,
        },
      }}
    >
      {shareButton}
    </Badge>
  ) : (
    shareButton
  );
};
