import { Chip, chipClasses, Stack } from "@mui/material";
import FilterIcon from "@mui/icons-material/Filter";

export const DuplicatesPaginationLabel = ({
  from,
  to,
  duplicatesCount,
  totalCount,
}: {
  from: number;
  to: number;
  duplicatesCount: number;
  totalCount: number;
}) => {
  return (
    <Stack component="span" direction="row" alignItems="center">
      {from.toLocaleString()} - {to.toLocaleString()}
      {!!duplicatesCount && (
        <Chip
          icon={
            <FilterIcon
              fontSize="small"
              sx={{
                width: "11px",
                height: "11px",
              }}
            />
          }
          label={`+${duplicatesCount}`}
          component="span"
          sx={{
            mx: "2px",
            fontSize: "12px",
            height: 16,
            [`.${chipClasses.label}`]: {
              paddingLeft: "10px",
              paddingRight: "4px",
            },
          }}
        />
      )}{" "}
      of {totalCount.toLocaleString()}
    </Stack>
  );
};
