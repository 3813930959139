import { ChangeEvent, useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useClipsSave } from "src/api/useClipsSave";
import { WatchTermResultItem } from "src/api/useWatchTermResults";
import { EventType } from "src/models/EventType";
import { EventDetails } from "src/models/EventDetails";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";

type SaveClipDialogProps = {
  open: boolean;
  event: WatchTermResultItem | EventDetails;
  onClose: () => void;
};

export const SaveClipDialog = ({
  open,
  event,
  onClose,
}: SaveClipDialogProps) => {
  const [title, setTitle] = useState(event.title);

  useEffect(() => {
    if (!event.title) return;

    setTitle(event.title);
  }, [event]);

  const handleClose = () => {
    setTitle(event.title);
    onClose();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const saveClip = useClipsSave({
    options: {
      onSettled: handleClose,
    },
  });
  const handleClipSave = () => {
    if (!title) return;

    saveClip.mutate({
      query: [
        {
          eventHighlights: event,
          clipTitle: title,
        },
      ],
    });
  };

  const content = (
    <Box py={1}>
      <TextField
        value={title}
        onChange={handleInputChange}
        label="Clip title"
        variant="outlined"
        fullWidth
      />
    </Box>
  );

  return (
    <DialogPrompt
      title={`Save ${event.eventType === EventType.PCast ? "Podcast" : "Clip"}`}
      open={open}
      onDecline={handleClose}
      onConfirm={handleClipSave}
      confirmLabel="Ok"
      children={content}
    />
  );
};
