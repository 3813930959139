import { useParams, useSearchParams } from "react-router-dom";
import { ExportReportFile } from "src/components/ExportReportFile/ExportReportFile";
import { DownloadReportFormat } from "src/api/useReportExport";
import { DownloadFilePathParams } from "./DownloadFile.route";

export const DownloadFilePage = () => {
  const {
    userId = "",
    type = DownloadReportFormat.pdf,
    id = "",
  } = useParams<DownloadFilePathParams>();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("name");

  return (
    <ExportReportFile
      userId={userId}
      type={type}
      id={id}
      fileName={fileName || ""}
    />
  );
};
