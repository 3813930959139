import {
  AddToQueue,
  CropOriginal,
  HeadphonesOutlined,
  LiveTvOutlined,
  RadioOutlined,
} from "@mui/icons-material";
import { ReportsIcon } from "src/components/icons/ReportsIcon";
import { YouTubeIcon } from "src/components/icons/YouTubeIcon";
import { EventType } from "src/models/EventType";

export const getEventIcon = (type: string, mediaType?: string) => {
  if (type === EventType.Logo) return CropOriginal;

  if (type === EventType.BCast && mediaType === EventType.Radio)
    return RadioOutlined;

  if (type === EventType.BCast) return LiveTvOutlined;

  if (type === EventType.PCast) return HeadphonesOutlined;

  if (type === EventType.Report) return ReportsIcon;

  if (type === EventType.YouTube) return YouTubeIcon;

  return AddToQueue;
};

export const getEventTooltipTitle = (type: string, mediaType?: string) => {
  if (type === EventType.Logo) return EventType.Logo;

  if (type === EventType.BCast && mediaType === EventType.Radio) return "Radio";

  if (type === EventType.YouTube) return "YouTube";

  if (type === EventType.BCast) return "Broadcast";

  if (type === EventType.PCast) return "Podcast";

  if (type === EventType.Report) return EventType.Report;

  return type;
};
