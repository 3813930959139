import { useCallback } from "react";
import { Typography, alpha, useTheme } from "@mui/material";
import { TranscriptParser } from "../TranscriptParser/TranscriptParser";
import { transcriptLineViewClasses } from "./TranscriptLineView.const";
import { TranscriptLineViewProps } from "./TranscriptLineView.model";

export function TranscriptLineView({
  offsetMs,
  text,
  onClick,
  anchorOffset = 48,
  mentionBgColor,
  mentionFontColor,
  variant,
}: TranscriptLineViewProps) {
  const { palette } = useTheme();

  const onClickWrapper = useCallback(() => {
    onClick && onClick(offsetMs);
  }, [onClick, offsetMs]);

  return (
    <Typography
      id={`${offsetMs}`}
      title={`Offset: ${offsetMs / 1000} seconds`}
      component="span"
      display="inline"
      variant="inherit"
      className={transcriptLineViewClasses.block}
      onClick={onClickWrapper}
      sx={{
        position: "relative",
        cursor: onClick ? "pointer" : "default",
        "& em": {
          fontWeight: 800,
          bgcolor: mentionBgColor,
          color: mentionFontColor,
        },
        [`&:hover`]: {
          bgcolor: palette.action.hover,
        },
        [`&.${transcriptLineViewClasses.current}`]: {
          bgcolor: alpha(palette.info.main, 0.32),
        },
      }}
    >
      <Typography
        component="span"
        display="inline-block"
        className={transcriptLineViewClasses.anchor}
        sx={{
          // this is anchor for scrolling just a bit before the current line starts
          position: "absolute",
          top: -anchorOffset,
          visibility: "hidden",
          display: "block",
          border: "2px solid red",
        }}
      />
      <TranscriptParser variant={variant ?? "body1"} transcript={text} />
    </Typography>
  );
}
