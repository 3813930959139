import { GridRowId } from "@mui/x-data-grid-premium";
import { create } from "zustand";

type State = {
  isActive: Readonly<boolean>;
  isAssignDialogShown: Readonly<boolean>;

  queryList: GridRowId[];
  clusterNames: string[];

  activate: () => void;
  deactivate: () => void;

  assignDialogShow: () => void;
  assignDialogHide: () => void;

  setQueryList: (ids: GridRowId[]) => void;
  setClusterNames: (clusterNames: string[]) => void;
};

const initialData: Omit<
  State,
  | "activate"
  | "deactivate"
  | "assignDialogShow"
  | "assignDialogHide"
  | "setQueryList"
  | "setClusterNames"
> = {
  isActive: false,
  isAssignDialogShown: false,
  queryList: [],
  clusterNames: [],
};

export const useWatchListClusterEditMode = create<State>((set, get) => ({
  ...initialData,

  /** List of existing cluster names */
  clusterNames: [],

  /** Activate cluster edit mode */
  activate: () => set({ isActive: true }),

  /** Deactivate cluster edit mode */
  deactivate: () => {
    set({
      ...initialData,
      // preserve cluster names
      clusterNames: get().clusterNames,
    });
  },

  /** Show cluster selection dialog */
  assignDialogShow: () => set({ isAssignDialogShown: true }),

  /** Hide cluster selection dialog */
  assignDialogHide: () => set({ isAssignDialogShown: false }),

  /** Set queries for the cluster */
  setQueryList: (ids: GridRowId[]) => set({ queryList: ids }),

  /** Set existing cluster names */
  setClusterNames: (clusterNames: string[]) => set({ clusterNames }),
}));
