import { useCallback, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import {
  MediaViewport,
  BroadcastConfig,
  PodcastConfig,
  YouTubeConfig,
  GenericConfig,
  MediaViewportProps,
  PlayerErrorTypes,
} from "@tveyes/mediaviewport-ui";
import { EventType } from "src/models/EventType";
import { ClipViewerProps } from "./ClipViewer.model";
import { ClipViewerError } from "./ClipViewerError";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { useClipViewerOffsets } from "./utils/useClipViewerOffsets";

export function ClipViewer({
  id,
  event,
  loading,
  seekOffsetMs,
  showMedia = true,
  onCurrentTimeChange,
  onDurationChange,
  onChangePlayState,
  onPlayEnd,
  onPreviewEnd,
  onMentionChange,
  ...props
}: ClipViewerProps) {
  const {
    eventType,
    mediaType,
    sourceId,
    startDateTime,
    endDateTime,
    assetId,
    mediaCenter,
    mediaCenterMedia,
    mediaCenterThumbnail,
  } = event || {};

  const setPlaying = useState(false)[1];
  const [playerErrorType, setPlayerErrorType] = useState<PlayerErrorTypes>();

  const onCurrentTimeWrapper = useCallback(
    (id: string, timeSec: number) => {
      // player reports time in seconds but highlights come in milliseconds
      const timeMs = timeSec * 1000;
      onCurrentTimeChange && onCurrentTimeChange(id, timeMs);
    },
    [onCurrentTimeChange]
  );

  const onChangePlayStateWrapper = useCallback<
    NonNullable<MediaViewportProps["onPaused"]>
  >(
    (instanceId, nextIsPlaying) => {
      if (instanceId === id) {
        setPlaying((prevIsPlaying) => {
          if (nextIsPlaying !== prevIsPlaying) {
            onChangePlayState?.(instanceId, nextIsPlaying);
            return nextIsPlaying;
          }

          return prevIsPlaying;
        });
      }
    },
    [id, setPlaying, onChangePlayState]
  );

  const onPlayEndWrapper = useCallback<
    NonNullable<MediaViewportProps["onPlayEnd"]>
  >(() => {
    onPlayEnd?.(id);
  }, [id, onPlayEnd]);

  const onDurationChangeWrapper = useCallback<
    NonNullable<MediaViewportProps["onDurationChange"]>
  >(
    (duration) => {
      // FIXME: workaround, seems to be bad types
      if (typeof duration === "number") {
        onDurationChange?.(id, duration);
      }
    },
    [id, onDurationChange]
  );

  const config = useMemo(() => {
    const bCastConfig: BroadcastConfig | null =
      eventType === EventType.BCast &&
      sourceId &&
      startDateTime &&
      endDateTime &&
      mediaType
        ? {
            eventType,
            station: sourceId,
            mediaType: mediaType,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          }
        : null;

    const pCastConfig: PodcastConfig | null =
      eventType === EventType.PCast && assetId && startDateTime && endDateTime
        ? {
            eventType,
            assetId,
            startDateTime: startDateTime,
          }
        : null;

    const youTubeConfig: YouTubeConfig | null =
      eventType === EventType.YouTube && assetId && startDateTime && endDateTime
        ? {
            eventType,
            assetId,
            startDateTime: startDateTime,
          }
        : null;

    const genericConfig: GenericConfig | null =
      mediaCenter &&
      mediaCenterMedia &&
      mediaCenterThumbnail &&
      startDateTime &&
      assetId
        ? {
            eventType: EventType.Generic,
            startDateTime,
            mediaURL: mediaCenterMedia,
            posterURL: mediaCenterThumbnail,
          }
        : null;

    // FIXME: interface design flaw!
    // player should be feature-agnostic and receive only config
    const nextConfig = mediaCenter
      ? genericConfig
      : bCastConfig || pCastConfig || youTubeConfig;

    return nextConfig;
  }, [
    assetId,
    endDateTime,
    eventType,
    mediaCenter,
    mediaCenterMedia,
    mediaCenterThumbnail,
    mediaType,
    sourceId,
    startDateTime,
  ]);

  const content: JSX.Element | undefined = useMemo(() => {
    if (loading) {
      return <LoadingOverlay />;
    }

    if (playerErrorType) {
      return <ClipViewerError errorType={playerErrorType} />;
    }

    if (!config && mediaCenter) {
      return <Typography align="center">Upload in progress..</Typography>;
    }

    if (!config) {
      console.error("Invalid event for ClipViewer", event);
      return (
        <Typography align="center">
          Invalid ClipViewer event <br />
          {process.env.NODE_ENV !== "production" ? (
            <code>{JSON.stringify(event, null, 2)}</code>
          ) : (
            ""
          )}
        </Typography>
      );
    }
  }, [config, event, loading, mediaCenter, playerErrorType]);

  const onPlayerErrorTypeWrapper = useCallback<
    NonNullable<MediaViewportProps["onPlayerError"]>
  >((errorType) => {
    setPlayerErrorType(errorType);
  }, []);

  const offsets = useClipViewerOffsets(event?.highlights);

  return useMemo(() => {
    if (!config || loading || playerErrorType) {
      return (
        <Stack
          id={`clip-viewer-placeholder:${id}`}
          minWidth={props.minWidth}
          maxWidth={props.maxWidth}
          minHeight={props.minHeight}
          maxHeight={props.maxHeight}
          sx={props.sx}
          children={
            <Stack
              children={content}
              flex={1}
              className="player-container"
              sx={{
                aspectRatio: showMedia ? 1600 / 1154 : undefined,
                height: showMedia ? undefined : 128,
              }}
            />
          }
        />
      );
    }

    const seekOffset =
      seekOffsetMs === undefined ? undefined : seekOffsetMs / 1000;

    return (
      <MediaViewport
        id={id}
        config={config}
        seekOffset={seekOffset}
        showMedia={showMedia}
        offsets={offsets}
        onCurrentTime={onCurrentTimeWrapper}
        onDurationChange={onDurationChangeWrapper}
        onPaused={onChangePlayStateWrapper}
        onPlayEnd={onPlayEndWrapper}
        onPreviewEnd={onPreviewEnd}
        onPlayerError={onPlayerErrorTypeWrapper}
        onOffsetChange={onMentionChange}
        prerollOffset={10}
        {...props}
      />
    );
  }, [
    props,
    config,
    loading,
    seekOffsetMs,
    offsets,
    id,
    content,
    showMedia,
    playerErrorType,
    onCurrentTimeWrapper,
    onDurationChangeWrapper,
    onChangePlayStateWrapper,
    onPlayerErrorTypeWrapper,
    onPlayEndWrapper,
    onPreviewEnd,
    onMentionChange,
  ]);
}
