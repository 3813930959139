import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { enqueueSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { ReportScratchEventsResponse } from "./useReportScratchEvents";

const apiKey: keyof paths = "/api/Report/scratch/matches";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type EndpointParams = Endpoint["parameters"];

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

type UseReportScratchEventsInfiniteParams = {
  query: EndpointParams["query"];
  body?: RequestBody;
};

const pageSize = 24;
export const reportScratchEventsInfiniteKey =
  "report-scratch-events-infinite-scroll";
export function useReportScratchEventsInfinite({
  request,
  options,
}: {
  request: UseReportScratchEventsInfiniteParams;
  options?: UseInfiniteQueryOptions<ReportScratchEventsResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { query } = request;

  return useInfiniteQuery<ReportScratchEventsResponse, unknown>({
    ...options,
    queryKey: [reportScratchEventsInfiniteKey, request],
    keepPreviousData: true,
    initialData: () => {
      const cacheWatchTermResults =
        queryClient.getQueryData<ReportScratchEventsResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchTermResults || {};
      const pages: ReportScratchEventsResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = results.slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;

      const token = await getAccessTokenSilently();

      const apiPath = makeApiUrl<EndpointParams>(apiKey, {
        query: {
          from,
          size,
          startDate: query?.startDate,
          endDate: query?.endDate,
          sortby: query?.sortby,
          orderby: query?.orderby,
        },
      });

      const { data } = await axios<ReportScratchEventsResponse>(apiPath, {
        method,
        data: request?.body || [],
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
