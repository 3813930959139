import { Box, BoxProps, alpha, useTheme } from "@mui/material";
import { getEventIcon } from "src/utils/getEventIcon";

type NoImagePlaceholderProps = {
  iconScale?: number;
  eventType?: string;
  mediaType?: string;
} & Omit<BoxProps, "children">;

export function ImagePlaceholder({
  iconScale = 4.5,
  eventType = "",
  mediaType = "",
  ...props
}: NoImagePlaceholderProps) {
  const { palette } = useTheme();
  const bgColor =
    palette.mode === "dark" ? palette.primary.dark : palette.primary.light;

  const Icon = getEventIcon(eventType, mediaType);

  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: alpha(bgColor, 0.04),
        ...props.sx,
      }}
    >
      <Icon
        sx={{
          transform: `scale(${iconScale})`,
          color: alpha(bgColor, 0.08),
        }}
      />
    </Box>
  );
}
