import { PropsWithChildren, useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { setDefaultOptions } from "date-fns";

function useCurrentLocale() {
  const code = Intl.DateTimeFormat().resolvedOptions().locale;
  const [currentLocale, setCurrentLocale] = useState<Locale>();

  useEffect(() => {
    import(`date-fns/locale/${code}`)
      .then((module) => {
        const locale = module.default as Locale;

        // required for date-fns format calls, otherwise defaults to en_US
        setDefaultOptions({ locale });

        // used to set locale for MUI components
        setCurrentLocale(locale);
      })
      .catch((e) => console.error(`Error loading locale: ${code}`, e));
  }, [code]);

  return currentLocale;
}

export function AppLocalizationProvider({ children }: PropsWithChildren<{}>) {
  const locale = useCurrentLocale();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      // date-fns/format required manual locale specification as per docs:
      // https://date-fns.org/v2.22.1/docs/I18n
      adapterLocale={locale}
      children={children}
    />
  );
}
