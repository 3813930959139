import Joi from "joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { PowerSearchCustomQueryFormValues } from "./PowerSearchCustomQueryForm.model";

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

const programListSchema = Joi.array().items().required();
const programLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

export const PowerSearchCustomQueryFormSchema =
  Joi.object<PowerSearchCustomQueryFormValues>({
    customQuery: Joi.string(),
    displayName: Joi.string().required().label("Display name"),
    //
    startDateTime: Joi.date()
      .allow(null)
      .when(Joi.ref("endDateTime"), {
        is: Joi.date(),
        then: Joi.date().max(Joi.ref("endDateTime")),
      })
      .label("Start date")
      .messages({
        "date.max": "Start date must be before End date",
      }),
    endDateTime: Joi.date().allow(null).max("now").label("End date"),
    //
    sourcesInclude: {
      list: sourceListSchema.label("Sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    sourcesExclude: {
      list: sourceListSchema.label("Exclude sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    //
    advertisement: Joi.valid(
      ...Object.values(queryAdvertisementValues)
    ).required(),
    //
    programInclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
    programExclude: {
      list: programListSchema.label("Program/Title"),
      logic: programLogicSchema.label("Logic"),
    },
  });
