export enum transcriptLineBundleViewClasses {
  root = "root",
}

export enum TranscriptLineBundleViewLabelLayout {
  left = "label-left",
  top = "label-top",
}

export enum TranscriptLineBundleViewTrimShowMode {
  hover = "hover",
  always = "always",
}
