import { useCallback } from "react";
import { Box } from "@mui/material";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { useReportScratchDelete } from "src/api/useReportScratchDelete";
import { useReportScratchRemoveEvents } from "src/api/useReportScratchRemoveEvents";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";

type ReportDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  events: PowerSearchResultItem[];
};

export const PreselectedEventsDeleteDialog = ({
  onClose,
  events,
  ...props
}: ReportDialogProps) => {
  const { highlightedEventsIds, removeHighlighting, allAddedEventsLenght } =
    useEventsPreselected();
  const { unselectAll, excludeEvents, areAllPagesSelected, unselectSome } =
    useEventsSelectedForReports();

  const isSingleEvent = events.length === 1;

  const deleteScratchReportMutation = useReportScratchDelete({
    options: {
      onSuccess: () => {
        onClose && onClose();
        unselectAll();
        if (highlightedEventsIds.length || allAddedEventsLenght) {
          removeHighlighting();
        }
      },
    },
  });

  const reportScratchRemoveEvents = useReportScratchRemoveEvents({
    options: {
      onSuccess: () => {
        onClose && onClose();
        unselectSome(events);
      },
    },
  });

  const handleDelete = useCallback(() => {
    if (areAllPagesSelected && !isSingleEvent) {
      deleteScratchReportMutation.mutate({
        excludeIds: excludeEvents.map((ev) => ev.id),
      });
    } else {
      reportScratchRemoveEvents.mutate({ events });
    }
  }, [
    areAllPagesSelected,
    isSingleEvent,
    excludeEvents,
    events,
    deleteScratchReportMutation,
    reportScratchRemoveEvents,
  ]);

  return (
    <DialogPrompt
      title={
        isSingleEvent
          ? "Delete event?"
          : areAllPagesSelected && !excludeEvents.length
          ? "Delete all preselected events?"
          : "Delete selected events?"
      }
      children={
        <Box pb={1}>
          {isSingleEvent
            ? "Do you want to delete event?"
            : areAllPagesSelected && !excludeEvents.length
            ? "Do you want to delete all preselected events?"
            : "Do you want to delete selected events?"}
        </Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      isLoading={
        deleteScratchReportMutation.isLoading ||
        reportScratchRemoveEvents.isLoading
      }
      {...props}
    />
  );
};
