import { GeoJSONFeature } from "mapbox-gl";

export function transformSourceFeatures({
  features,
  valueByFeatureId,
}: {
  features: GeoJSONFeature[];
  valueByFeatureId: { [featureId: string]: number };
}): Readonly<GeoJSONFeature>[] {
  const upperLimit = Math.max(...Object.values(valueByFeatureId));
  const lowerLimit = Math.min(...Object.values(valueByFeatureId));
  const range = upperLimit - lowerLimit;

  // attach value to the feature properties
  return features.map((feature) => {
    const id = `${feature.id}`;
    const value = valueByFeatureId[id];

    if (value !== undefined) {
      feature.properties = {
        ...feature.properties,
        range,
        value,
        hits: value / range,
      };
    }

    return feature;
  });
}
