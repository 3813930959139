import { components } from "@tveyes/twosionwebapischema";
import { WatchQueryFormValues } from "src/components/WatchQueryForm/WatchQueryForm.model";
import { makeSourceCategoryIds } from "./makeSourceCategoryIds";
import { queryAdvertisementValues } from "src/models/Advertisement";

export function makeQueryDefinition(
  data: Partial<WatchQueryFormValues>
): components["schemas"]["QueryDefinition"] {
  const sourceIncludeCategoryIds = data.sourcesInclude?.list
    ? makeSourceCategoryIds(data.sourcesInclude?.list)
    : null;

  const sourceExcludeCategoryIds = data.sourcesExclude?.list
    ? makeSourceCategoryIds(data.sourcesExclude?.list)
    : null;

  return {
    customQuery: data.customQuery,
    keywordQuery: {
      include: data.termsInclude?.list,
      includeOperator: data.termsInclude?.logic,
      //
      exclude: data.termsExclude?.list,
      excludeOperator: data.termsExclude?.logic,
    },
    sourceFilter: {
      adverts:
        data.advertisement === queryAdvertisementValues.all
          ? null
          : data.advertisement,
      //
      includeOperator: data.sourcesInclude?.logic,
      excludeOperator: data.sourcesExclude?.logic,
      //
      programs: {
        include: data.programInclude?.list,
        exclude: data.programExclude?.list,
      },
      countries: {
        include: sourceIncludeCategoryIds?.countryList,
        exclude: sourceExcludeCategoryIds?.countryList,
      },
      state: {
        include: sourceIncludeCategoryIds?.stateList,
        exclude: sourceExcludeCategoryIds?.stateList,
      },
      markets: {
        include: sourceIncludeCategoryIds?.marketList,
        exclude: sourceExcludeCategoryIds?.marketList,
      },
      stations: {
        include: sourceIncludeCategoryIds?.stationList,
        exclude: sourceExcludeCategoryIds?.stationList,
      },
      contentType: {
        include: sourceIncludeCategoryIds?.eventTypeList,
        exclude: sourceExcludeCategoryIds?.eventTypeList,
      },
      languages: {
        include: sourceIncludeCategoryIds?.languageList,
        exclude: sourceExcludeCategoryIds?.languageList,
      },
    },
  };
}
