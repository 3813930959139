import { PaidOutlined } from "@mui/icons-material";
import { Chip, SxProps, chipClasses } from "@mui/material";

export function AdvChip({ sx }: { sx?: SxProps }) {
  return (
    <Chip
      size="medium"
      color="secondary"
      sx={{
        width: 32,
        [`.${chipClasses.icon}`]: {
          marginRight: 0,
          marginLeft: 3,
        },
        ...sx,
      }}
      icon={<PaidOutlined />}
    />
  );
}
