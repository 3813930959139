import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useInfiniteQuery, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { useSessionContext } from "./useSessionContext";
import { MediaCenterItem } from "./useMediaCenter";

const apiKey: keyof paths = "/api/mediacenter/{userid}/items";
const method: keyof paths[typeof apiKey] = "post";

export const MediaCenterInfiniteFetchKey =
  "media-center-results-infinite-scroll";

type Endpoint = paths[typeof apiKey][typeof method];
type EndpointParams = Endpoint["parameters"];

type RequestParams = {
  params: {
    body?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
    query: Omit<EndpointParams["query"], "from" | "size">;
  };
};

export type MediaCenterInfiniteResponse = {
  total?: number;
  results?: MediaCenterItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

const pageSize = 24;

export function useMediaCenterInfinite({
  request,
  options,
}: {
  request: RequestParams;
  options?: UseQueryOptions<MediaCenterInfiniteResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { userId } = useSessionContext();

  return useInfiniteQuery<MediaCenterInfiniteResponse, unknown>({
    queryKey: [MediaCenterInfiniteFetchKey, request],
    keepPreviousData: true,
    initialData: () => {
      const cacheMediaCenterResults =
        queryClient.getQueryData<MediaCenterInfiniteResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheMediaCenterResults || {};
      const pages: MediaCenterInfiniteResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = results.slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;

      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl<EndpointParams>(apiKey, {
        path: {
          userid: userId,
        },
        query: {
          from,
          size,
          ...request.params.query,
        },
      });

      const { data } = await axios<MediaCenterInfiniteResponse>(apiPath, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request.params?.body,
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
