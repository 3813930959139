import React, {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import Papa from "papaparse";
import { NewAccountFormValues } from "../../AccountsManagementCreateAccount.model";
import { AccountsManagementUsersFields } from "../AccountsManagementUsersFields/AccountsManagementUsersFields";
import { NewUser } from "./AccountsManagementUsersForm.model";

export function AccountsManagementUsersForm() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const { control, watch } = useFormContext<NewAccountFormValues>();
  const [showUsersGrid, setShowUsersGrid] = useState(false);
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  const addedUsers = watch("users");

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const file = e.target?.files[0];
      if (file) {
        Papa.parse<NewUser>(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            results.data.forEach((value) => {
              append({
                email: value.email,
                firstName: value.firstName,
                lastName: value.lastName,
              });
            });
            if (
              !addedUsers[0]?.email &&
              !addedUsers[0]?.firstName &&
              !addedUsers[0]?.lastName
            ) {
              remove(0);
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    if (fields.length && !showUsersGrid) {
      setShowUsersGrid(true);
    }
  }, [fields, showUsersGrid, setShowUsersGrid]);

  const handleAddUsers = () => {
    setShowUsersGrid(true);
    append({
      email: "",
      firstName: "",
      lastName: "",
    });
  };

  const accountUsersGrid = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid
        item
        xs={12}
        mt={4}
        pt={4}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          Account Users
        </Typography>
        <Grid
          item
          xs={12}
          md={6}
          justifyContent={"end"}
          display={"flex"}
          gap={2}
        >
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
            hidden
            ref={inputRef}
          />
          <Button
            variant="text"
            onClick={() => inputRef?.current?.click()}
            color="inherit"
            sx={{
              fontWeight: 600,
            }}
          >
            Import from CSV
          </Button>

          <Button
            variant="text"
            onClick={() =>
              append({
                email: "",
                firstName: "",
                lastName: "",
              })
            }
          >
            Add User
          </Button>
        </Grid>
      </Grid>

      <AccountsManagementUsersFields
        isMobile={isMobile}
        fields={fields}
        remove={remove}
        control={control}
        setShowUsersGrid={setShowUsersGrid}
      />
    </Grid>
  );

  const addUsersPlaceholder = (
    <Grid container direction={"column"} width={"100%"} alignItems={"center"}>
      <Grid item py={1}>
        <Typography variant="subtitle1">Account Users</Typography>
      </Grid>
      <Grid item pb={3}>
        <Typography variant="body2" color={"text.secondary"}>
          You have no users added
        </Typography>
      </Grid>
      <Grid item gap={2} display={"flex"} justifyContent={"space-around"}>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          hidden
          ref={inputRef}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={() => inputRef?.current?.click()}
          sx={{
            fontWeight: 500,
          }}
        >
          Import CSV
        </Button>

        <Button variant="contained" onClick={handleAddUsers}>
          Add User
        </Button>
      </Grid>
    </Grid>
  );

  return showUsersGrid ? accountUsersGrid : addUsersPlaceholder;
}
