import { useEffect, useMemo, Fragment } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  dialogContentClasses,
  dialogTitleClasses,
} from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  AccountConvertBody,
  useAccountConvert,
} from "src/api/useAccountConvert";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { AccountsManagementConvertForm } from "../AccountsManagementConvertForm/AccountsManagementConvertForm";
import { AccountsManagementConvertFormValues } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.model";
import { AccountsManagementConvertDialogProps } from "./AccountsManagementConvertDialog.model";
import { AccountsManagementConvertFormSchema } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.schema";
import { STATUS_CONFIG } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.const";
import { getConfigurationKey } from "./AccountsManagementConvertDialog.utils";

export function AccountsManagementConvertDialog({
  onClose,
  status,
  accountExpirationDate,
  accountId,
  seatCount = 0,
  isActive = false,
  isTrial = false,
  isPaying = false,
  title,
  accountManager,
  accountManagerEmail,
  accountManagerData,
  annualizedRecurringRevenue,
  ...props
}: AccountsManagementConvertDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const accountConfiguration = useMemo(() => {
    return getConfigurationKey(isActive, isTrial, isPaying);
  }, [isActive, isTrial, isPaying]);

  const defaultValues: AccountsManagementConvertFormValues = useMemo(
    () => ({
      status: accountConfiguration,
      expiration: accountExpirationDate || "",
      seatCount: seatCount,
      note: "",
      notifyUsers: false,
      accountManager: accountManager || "",
      annualizedRecurringRevenue: annualizedRecurringRevenue || 0,
    }),
    [
      accountExpirationDate,
      seatCount,
      accountConfiguration,
      accountManager,
      annualizedRecurringRevenue,
    ]
  );

  const formHook = useForm<AccountsManagementConvertFormValues>({
    mode: "all",
    resolver: joiResolver(AccountsManagementConvertFormSchema),
    defaultValues,
  });

  const accountConvert = useAccountConvert({
    options: {
      onSuccess: () => {
        enqueueSnackbar(
          t(
            "AccountsManagementConvertDialogMessage",
            "Account converted successfully"
          ),
          {
            variant: "success",
          }
        );
        onClose();
      },
    },
  });

  const { reset, handleSubmit, control, formState } = formHook;

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open, defaultValues]);

  const resetValues = () => {
    reset({}, { keepDefaultValues: true });
  };

  const handleSubmitWrapper = handleSubmit(
    (formData) => {
      const { status } = formData;
      const body: AccountConvertBody = {
        accountId,
        expiration: formData?.expiration || "",
        note: formData.note,
        seatCount: formData.seatCount,
        notifyUsers: formData.notifyUsers,
        accountManagerId: formData.accountManager || "",
        annualizedRecurringRevenue: Number(formData.annualizedRecurringRevenue),
      };
      if (formData.status) {
        body.isTrial = STATUS_CONFIG[status].isTrial;
        body.isActive = STATUS_CONFIG[status].isActive;
        body.isPaying = STATUS_CONFIG[status].isPaying;
      }
      accountConvert.mutate({
        body: body,
        path: { id: accountId },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:AccountsManagementConvertForm:error", errors);
    }
  );

  return (
    <FormProvider {...formHook}>
      <DialogBase
        title={title}
        disableHeaderDivider
        fullWidth
        sx={{
          [`.${dialogTitleClasses.root}`]: {
            paddingY: 2,
            paddingX: 3,
          },
          [`.${dialogContentClasses.root}`]: {
            padding: 0,
          },
        }}
        {...props}
        children={
          <Stack
            pl={3}
            pr={2.5}
            paddingRight={2}
            py={0}
            justifyContent={"space-between"}
            overflow={"auto"}
          >
            <Typography pb={2}>
              You can convert account to another status, expiration date, change
              amount of seats and etc
            </Typography>
            <AccountsManagementConvertForm
              accountId={accountId}
              accountManager={accountManager}
              accountManagerEmail={
                accountManagerEmail || accountManagerData?.email
              }
            />
          </Stack>
        }
        footer={
          <Fragment>
            <Stack direction="row" width={"50%"} spacing={2} minHeight={42}>
              <FormGroup sx={{ flexDirection: "row" }}>
                <Controller
                  name="notifyUsers"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        checked={field.value}
                        control={<Checkbox />}
                        label="Notify users"
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </FormGroup>
            </Stack>
            <Stack
              direction="row"
              width={"50%"}
              spacing={2}
              minHeight={42}
              justifyContent={"end"}
            >
              <Button
                color="error"
                onClick={onClose}
                variant="text"
                sx={{ width: "80px" }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={resetValues}
                variant="outlined"
                sx={{ width: "80px" }}
                disabled={!formState.isDirty}
              >
                Reset
              </Button>
              <LoadingButton
                disabled={!formState.isDirty}
                variant="contained"
                color="primary"
                onClick={handleSubmitWrapper}
                sx={{ width: "80px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Fragment>
        }
      />
    </FormProvider>
  );
}
