import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  MediaCenterItemResponse,
  useMediaCenterItem,
} from "src/api/useMediaCenterItem";
import { useMediaCenterThumbnail } from "src/api/useMediaCenterThumbnail";
import { useSessionContext } from "src/api/useSessionContext";
import { ClipViewerPage } from "src/components/ClipViewerPage/ClipViewerPage";
import { MediaCenterClipPathParam } from "./MediaCenterClip.route";

function buildEventData(
  data: MediaCenterItemResponse,
  thumbnail?: string,
  mediaUrl?: string
) {
  return {
    ...data.eventDetails,
    mediaCenter: true,
    mediaCenterThumbnail: thumbnail,
    mediaCenterMedia: mediaUrl,
  };
}

export const MediaCenterClipPage = () => {
  const { eventId = "" } = useParams<MediaCenterClipPathParam>();

  const { userId } = useSessionContext();

  const mediaCenterItem = useMediaCenterItem({
    request: {
      params: {
        path: {
          userId,
          itemId: eventId,
        },
      },
    },
    options: {},
  });

  const thumbnail = useMediaCenterThumbnail({
    params: {
      path: {
        itemid: eventId,
      },
    },
  });

  const event = useMemo(() => {
    return mediaCenterItem.data || {};
  }, [mediaCenterItem.data]);

  return (
    <ClipViewerPage
      mediaItemId={event.id}
      event={
        buildEventData(
          event,
          thumbnail.data,
          `/api/mediacenter/${userId}/items/${eventId}/media`
        ) || {}
      }
      loading={mediaCenterItem.isLoading}
      routeRoot="MediaCenter"
    />
  );
};
