import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { parseISO } from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { UpdateUserStatusDialog } from "src/pages/UserManagement/components/UpdateUserStatusDialog/UpdateUserStatusDialog";
import { getSelectedRoles } from "src/utils/useUserRoleList";
import { useAccount } from "src/api/useAccount";
import { TextInputBase } from "../TextInputBase";
import { TimeZonePicker } from "../TimeZonePicker/TimeZonePicker";
import { UserRolePicker } from "../UserRolePicker/UserRolePicker";
import { UserInformationUpdateFormValues } from "../UserInformationUpdateForm/UserInformationUpdateForm.model";
import { AccountAutocomplete } from "../AccountAutocomplete/AccountAutocomplete";

export function UserManagementFormCreate({
  loading,
  origin,
  isUserActive,
  userFullName,
  accountName,
}: {
  loading?: boolean;
  origin: "create" | "edit";
  isUserActive?: boolean;
  userFullName?: string;
  accountName?: string;
}) {
  const { control, formState, watch, setValue } =
    useFormContext<UserInformationUpdateFormValues>();
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;
  const updateUserStatusDialog = useOpenState();
  const accountId = watch("accountId");

  const { data: accountData } = useAccount({
    request: {
      path: {
        id: accountId,
      },
    },
  });

  useEffect(() => {
    if (accountId && accountData) {
      setValue("timezone", accountData?.timeZone || "");
    }
  }, [accountData, accountId, setValue]);

  const userSection = (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={5}
        mt={4}
        pt={4}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          User Information
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        mt={{ md: 4 }}
        pt={{ md: 4 }}
        justifyContent={"end"}
        display={"flex"}
      >
        <Stack flexDirection="row" gap={3}>
          {origin === "create" ? (
            <FormGroup sx={{ flexDirection: "row" }}>
              <Controller
                name="notify"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      checked={field.value}
                      control={<Checkbox />}
                      label="Notify user"
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </FormGroup>
          ) : null}
          {origin === "edit" ? (
            <FormControlLabel
              label="Active"
              control={
                <Switch
                  checked={isUserActive || false}
                  onChange={updateUserStatusDialog.show}
                />
              }
            />
          ) : (
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    checked={field.value}
                    control={<Switch />}
                    label="Active"
                    onChange={field.onChange}
                  />
                );
              }}
            />
          )}
        </Stack>
      </Grid>

      <Grid container spacing={3} mb={1} m={2} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="First name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isBusy}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Last name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isBusy}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isBusy}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Phone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isBusy}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="accountId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <AccountAutocomplete
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    disabled={isBusy || origin === "edit"}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <TimeZonePicker
                value={field.value ?? ""}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
              />
            )}
          />
        </Grid>

        {origin === "edit" && (
          <>
            <Grid item xs={12} md={6} mb={2}>
              <Controller
                name="createdDate"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="Creation date"
                    value={parseISO(field.value ?? "")}
                    onChange={field.onChange}
                    disabled
                    format="MM/dd/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                      },
                    }}
                    slots={{
                      textField: TextInputBase,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="expirationDate"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="Expiration date"
                    value={parseISO(field.value ?? "")}
                    onChange={field.onChange}
                    disabled
                    format="MM/dd/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                      },
                    }}
                    slots={{
                      textField: TextInputBase,
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );

  const roles = (
    <Grid container spacing={3} mb={2} pl={2}>
      <Grid item xs={12} md={12}>
        <Controller
          name="roles"
          control={control}
          render={({ field }) => {
            return (
              <UserRolePicker
                value={field.value ?? []}
                onChange={(e) => {
                  const inputValue = field.value ?? [];
                  const { value } = e.target;
                  if (inputValue.includes(value)) {
                    field.onChange(inputValue.filter((v) => v !== value));
                  } else {
                    const roles = getSelectedRoles(value);
                    field.onChange(roles);
                  }
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Stack component={Paper} flex={1}>
      {userSection}
      {roles}
      <UpdateUserStatusDialog
        isUserActive={isUserActive}
        title={isUserActive ? "Deactivate user?" : "Activate user"}
        open={updateUserStatusDialog.isOpen}
        onClose={updateUserStatusDialog.hide}
        userFullName={userFullName}
        accountName={accountName}
      />
    </Stack>
  );
}
