import { useMemo } from "react";
import { useQueryParamList } from "src/utils/useQueryParamList";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { isNormalQuery } from "src/utils/isNormalQuery";
import { AnalyticsLiteResultPage } from "./AnalyticsLiteResult.page";
import { AnalyticsLiteEmptyPage } from "./AnalyticsLiteEmpty.page";
import { WatchListCreatePage } from "../WatchListCreate/WatchListCreate.page";

export function AnalyticsLiteIndex() {
  const [queryIds] = useQueryParamList({ key: "queryIds" });
  const watchQueryListData = useWatchQueryList({});
  const watchQueryList = useMemo(
    () => watchQueryListData.data?.results?.filter(isNormalQuery) ?? [],
    [watchQueryListData.data?.results]
  );

  if (watchQueryListData.isLoading) {
    return <LoadingOverlay />;
  }

  // this route can have states:
  // 1. no queries
  // 2. no selection
  // 3. no results
  if (!watchQueryList.length) {
    // 1. no watch queries at all - show watch query create page
    return <WatchListCreatePage />;
  }

  if (!queryIds.length) {
    // 2. no watch queries selected -> show query picker and date controls
    return <AnalyticsLiteEmptyPage />;
  }

  return (
    // 3. results view
    <AnalyticsLiteResultPage />
  );
}
