import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useOpenState } from "src/utils/useOpenState";
import { WatchTermResultItem } from "src/api/useWatchTermResults";
import { PreselectedEventsDeleteDialog } from "../PreselectedEventsDeleteDialog";

export const PreselectedEventsItemActions = ({
  value,
}: {
  value: WatchTermResultItem;
}) => {
  const deleteEventDialog = useOpenState();

  return (
    <>
      <IconButton onClick={deleteEventDialog.show}>
        <DeleteOutlineIcon />
      </IconButton>
      <PreselectedEventsDeleteDialog
        open={deleteEventDialog.isOpen}
        onClose={deleteEventDialog.hide}
        events={[value]}
      />
    </>
  );
};
