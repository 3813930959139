import { Alert, alertClasses, AlertTitle, Snackbar } from "@mui/material";

type SystemLimitSnackbarProps = {
  open: boolean;
  onClose: () => void;
};

export function SystemLimitSnackbar({
  open,
  onClose,
}: SystemLimitSnackbarProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        severity="warning"
        variant="filled"
        onClose={onClose}
        sx={{
          borderRadius: 1,
          alignItems: "center",
          [`.${alertClasses.action}`]: {
            pt: 0,
          },
        }}
      >
        <AlertTitle>System limit</AlertTitle>
        The time you are trying to enter is outside the current clip, please use
        zoom timeline feature for precise cropping.
      </Alert>
    </Snackbar>
  );
}
