import { EventSourceOption } from "src/api/useEventSources";

export function makeSourceCategoryValues(sources: EventSourceOption[]) {
  const countryList = sources
    .filter((s) => s.group === "CountryCode")
    .map((item) => item.value);

  const marketList = sources
    .filter((s) => s.group === "MarketID")
    .map((item) => Number(item.value));

  const stateList = sources
    .filter((s) => s.group === "Country:MarketState")
    .map((item) => item.value);

  const stationList = sources
    .filter((s) => s.group === "StationGUID")
    .map((item) => item.value);

  const eventTypeList = sources
    .filter((s) => s.group === "EventTypeComposite")
    .map((item) => item.value);

  const podcastSources = sources
    .filter((s) => s.group === "podcastSources")
    .map((item) => item.value);

  const language = sources
    .filter((s) => s.group === "LanguageCode")
    .map((item) => item.value);

  return {
    countryList,
    marketList,
    stateList,
    stationList,
    eventTypeList,
    podcastSources,
    language,
  };
}
