import { useNavigate } from "react-router-dom";
import CopyIcon from "@mui/icons-material/CopyAllOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  svgIconClasses,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/icons-material";
import { watchListUpdateRoute } from "src/pages/WatchListUpdate/WatchListUpdate.route";
import { AnalyticsLiteRoute } from "src/pages/ReportAnalysisLite/AnalyticsLite.route";
import { useSessionContext } from "src/api/useSessionContext";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { useWatchQueryCloneDialogState } from "src/components/WatchQueryCloneDialog/utils/useWatchQueryCloneDialogState";

type Query = {
  id: string;
  title: string;
};

type WatchQueryItemMenuProps = {
  query: Query;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  setShowDeleteModal: (value: boolean) => void;
} & Omit<MenuProps, "children">;

export const WatchQueryItemMenu = ({
  query,
  setShowDeleteModal,
  ...props
}: WatchQueryItemMenuProps) => {
  const navigate = useNavigate();

  const { palette } = useTheme();
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();
  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;
  const queryCloneDialog = useWatchQueryCloneDialogState();

  const handleOpenDeleteDialog = () => {
    setShowDeleteModal(true);
    props.onClose();
  };

  const handleEdit = () => {
    const url = watchListUpdateRoute.makeUrl({
      queryId: query.id,
    });
    navigate(url);
  };

  const handleOpenCloneDialog = () => {
    queryCloneDialog.show(query.id);
    props.onClose();
  };

  const handleOpenAnalytics = () => {
    const analyticsUrl = AnalyticsLiteRoute.makeUrl(undefined, {
      queryIds: [query.id],
      startDateTime: "",
      endDateTime: "",
    });
    navigate(analyticsUrl);
    props.onClose();
  };

  return (
    <Menu
      {...props}
      sx={{
        [`.${svgIconClasses.root}`]: {
          color: palette.primary.main,
        },
      }}
    >
      <MenuItem onClick={handleOpenAnalytics}>
        <ListItemIcon>
          <BarChart fontSize="small" />
        </ListItemIcon>
        <ListItemText>Analytics</ListItemText>
      </MenuItem>

      <TooltipBase
        width={181}
        textAlign="left"
        title={t("editWatchListDisabled")}
        placement="left"
        disableHoverListener={enableEditWatchList}
      >
        <Box>
          <MenuItem onClick={handleEdit} disabled={!enableEditWatchList}>
            <ListItemIcon>
              <ModeEditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </Box>
      </TooltipBase>
      <MenuItem onClick={handleOpenCloneDialog}>
        <ListItemIcon>
          <CopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Clone</ListItemText>
      </MenuItem>
      <TooltipBase
        width={181}
        textAlign="left"
        title={t("editWatchListDisabled")}
        placement="left"
        disableHoverListener={enableEditWatchList}
      >
        <Box>
          <MenuItem
            onClick={handleOpenDeleteDialog}
            disabled={!enableEditWatchList}
          >
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Box>
      </TooltipBase>
    </Menu>
  );
};
