import axios from "axios";
import { add, min } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";

const apiPath: keyof paths = "/api/DateTimeSearch";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type Body = Endpoint["requestBody"]["content"]["application/json"];

type FetchParamsType = DateTimeSearchFormValues & {
  stationCurrentTime?: Date;
};

type RequestParams = {
  params: {
    fetchParams: FetchParamsType;
    body?: Body;
    cursorRangeBounds: [number, number];
  };
  options?: Pick<
    UseQueryOptions<DateTimeSearchResult, unknown>,
    "onSuccess" | "onError" | "enabled"
  >;
};

export type UseShiftPlayerResponse =
  Endpoint["responses"][200]["content"]["application/json"];

type DateTimeSearchResult = UseShiftPlayerResponse;

export const dateTimeQueryKey = apiPath;

function makeDateTimeSearchRange(params: RequestParams["params"]) {
  const t0Date = add(params.fetchParams.dateTime, {
    minutes: params.cursorRangeBounds[0],
  });
  const t1Date = add(params.fetchParams.dateTime, {
    minutes: params.cursorRangeBounds[1],
  });

  const timeRange = [formatDateTimeWithoutTimeZone(t0Date)];

  const minT1Date = params.fetchParams.stationCurrentTime
    ? min([t1Date, params.fetchParams.stationCurrentTime])
    : t1Date;
  timeRange.push(formatDateTimeWithoutTimeZone(minT1Date));

  return timeRange;
}

export function useShiftPlayerData({ params, options }: RequestParams) {
  const { getAccessTokenSilently } = useAuth0();

  const dateTimeSearcRange = makeDateTimeSearchRange(params);

  const query: Endpoint["parameters"]["query"] = {
    stationUUID: params.fetchParams?.station?.value,
    startDateTime: dateTimeSearcRange[0],
    endDateTime: dateTimeSearcRange[1],
    sentenceAlign: params.fetchParams?.sentenceAlign,
  };

  const isNotUndefined = (v: unknown) => v !== undefined;

  return useQuery<DateTimeSearchResult, unknown>({
    queryKey: [dateTimeQueryKey, params],
    keepPreviousData: true,
    enabled: options?.enabled ?? Object.values(query).every(isNotUndefined),
    ...options,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, { query });

      const response = await axios<DateTimeSearchResult>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: params.body ?? {},
      });

      return response.data;
    },
    onError(err) {
      options?.onError?.(err);

      enqueueSnackbar({
        message: `Error fetching Date Time search results: ${err}`,
        variant: "error",
      });
    },
  });
}
