import { TimePicker, TimePickerProps } from "@mui/x-date-pickers-pro";
type AppTimePickerProps = TimePickerProps<Date> & {
  isMobile?: boolean;
  layoutWidth?: string;
  disablePortal?: boolean;
};

export function AppTimePicker({
  value,
  onChange,
  onAccept,
  label,
  disablePortal,
  layoutWidth = "350px",
}: AppTimePickerProps) {
  return (
    <TimePicker
      slotProps={{
        popper: {
          disablePortal: disablePortal,
        },
        layout: {
          sx: {
            width: layoutWidth,
            "& .MuiMultiSectionDigitalClockSection-root": {
              marginX: "auto",
            },
            "& .MuiMultiSectionDigitalClock-root": {
              padding: 2,
              paddingX: 5,
            },
            ".MuiMultiSectionDigitalClockSection-root:after": {
              display: "none",
            },
            "& .MuiDialogActions-root": {
              justifyContent: "right",
              paddingY: 1,
              paddingRight: 2,
            },
            "& .MuiList-root": {
              borderLeft: "none !important",
            },
          },
        },
        digitalClockSectionItem: {},
      }}
      label={label}
      value={value}
      onChange={onChange}
      onAccept={onAccept}
    />
  );
}
