import {
  ContentCopy,
  Edit,
  MoreVertOutlined,
  PersonPin,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { useSessionContext } from "src/api/useSessionContext";
import { IconButtonDropDown } from "src/components/IconButtonDropDown/IconButtonDropDown";
import { useIsDemoMode } from "src/api/useIsDemoMode";
import { useOpenState } from "src/utils/useOpenState";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { getIsImpersonateDisabled } from "src/api/useUserManagementResult.getters";
import {
  getStatusIcon,
  getStatusLabel,
} from "src/pages/UserManagement/UserManagement.utils";
import { DemoModeConfirmationModal } from "../DemoModeConfirmationModal/DemoModeConfirmationModal";
import { EndDemoModeModal } from "../EndDemoModeModal/EndDemoModeModal";

export type UserManagementActionsProps = {
  id: string;
  status: string;
  authUserHighestRole: string;
  userHighestRole: string;
  onUserEdit?: () => void;
  onUserClone?: () => void;
  onUserDeactivate: () => void;
};

export const UserManagementActions = ({
  id,
  status,
  authUserHighestRole,
  userHighestRole,
  onUserEdit,
  onUserClone,
  onUserDeactivate,
}: UserManagementActionsProps) => {
  const StatusIcon = getStatusIcon(status);
  const startDemoDialog = useOpenState();
  const endDemoDialog = useOpenState();
  const navigate = useNavigate();
  const isImpersonateDisabled = getIsImpersonateDisabled(
    authUserHighestRole,
    userHighestRole
  );

  const { isImpersonatingUser, userId } = useSessionContext();
  const isDemoMode = useIsDemoMode();
  const impersonateUser = useImpersonateUser({
    options: {
      onSuccess: () => {
        startDemoDialog.hide();
        navigate(watchListRoute.path);
      },
    },
  });

  const impersonateDemo = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id:
            isImpersonatingUser === true && id === userId
              ? "00000000-0000-0000-0000-000000000000"
              : id,
        },
        query: {
          mode: "demo",
        },
      },
    });
  };

  const demoModeModal = (
    <DemoModeConfirmationModal
      open={startDemoDialog.isOpen}
      onClose={startDemoDialog.hide}
      onSubmit={impersonateDemo}
      content={
        <Typography variant="body1">
          Are you sure you want to start a demo?
        </Typography>
      }
      submitLabel="Yes"
      title="Start Demo"
      isLoading={impersonateUser.isLoading}
    />
  );

  return (
    <>
      {demoModeModal}
      <EndDemoModeModal
        isOpen={endDemoDialog.isOpen}
        onClose={endDemoDialog.hide}
      />
      <IconButtonDropDown
        renderDropDown={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            {onUserEdit && (
              <MenuItem
                onClick={() => {
                  closeMenu();
                  onUserEdit();
                }}
              >
                <ListItemIcon>
                  <Edit fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}

            {onUserClone && (
              <MenuItem
                onClick={() => {
                  closeMenu();
                  onUserClone();
                }}
              >
                <ListItemIcon>
                  <ContentCopy fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                closeMenu();
                onUserDeactivate();
              }}
            >
              <ListItemIcon>
                <StatusIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>{getStatusLabel(status)}</ListItemText>
            </MenuItem>

            <MenuItem
              disabled={isImpersonateDisabled}
              onClick={() => {
                closeMenu();
                impersonateUser.mutate({
                  params: {
                    path: {
                      id:
                        isImpersonatingUser === true && id === userId
                          ? "00000000-0000-0000-0000-000000000000"
                          : id,
                    },
                  },
                });
              }}
            >
              <ListItemIcon>
                <PersonPin fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {isImpersonatingUser === true && id === userId && !isDemoMode
                  ? "Stop Impersonating"
                  : "Impersonate user"}
              </ListItemText>
            </MenuItem>
            <MenuItem
              disabled={isImpersonateDisabled}
              onClick={() => {
                closeMenu();
                isDemoMode === true && id === userId
                  ? endDemoDialog.show()
                  : startDemoDialog.show();
              }}
            >
              <ListItemIcon>
                <PersonPin fontSize="small" color="info" />
              </ListItemIcon>
              <ListItemText>
                {isDemoMode === true && id === userId
                  ? "Stop Demo Mode"
                  : "Start Demo Mode"}
              </ListItemText>
            </MenuItem>
          </Menu>
        )}
      >
        <MoreVertOutlined />
      </IconButtonDropDown>
    </>
  );
};
