import { Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { getRoleFromUser } from "src/utils/useUserRoleList";
import { getAuthUserRole } from "src/api/useUserManagementResult.getters";
import { UserItem, defaultUMBody } from "src/api/useUsersManagement";
import { useSessionContext } from "src/api/useSessionContext";
import { useUsersManagementInfinite } from "src/api/useUsersManagementInfinite";
import { useUserDetailsUpdate } from "src/api/useUserDetailsUpdate";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { NoUsersOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { UserManagementTilesProps } from "./UserManagementTiles.model";
import { UserManagementTile } from "./UserManagementTile";

function buildBody(newValues = {}, search: string, userId?: string) {
  return {
    ...defaultUMBody,
    ...newValues,
    accountManagerId: userId ?? null,
    search,
  };
}

export function UserManagementTiles({
  filterText,
  filterQuery,
  myUsersOnly,
  orderBy,
  sortBy,
  accountId,
  onUserDuplicate,
  ...props
}: UserManagementTilesProps) {
  const { userId } = useSessionContext();
  const { user } = useAuth0();
  const role = getRoleFromUser(user);
  const authUserHighestRole = getAuthUserRole(role);

  const userGeneralDataUpdate = useUserDetailsUpdate({
    options: {
      origin: "resultsPage/infinite",
    },
  });
  const onUserDeactivate = (id: string, isActive: boolean) => {
    userGeneralDataUpdate.mutate({
      body: {
        isActive,
      },
      params: {
        path: {
          id,
        },
      },
    });
  };

  const usersData = useUsersManagementInfinite({
    request: {
      params: {
        query: { accountIdFilter: accountId, sortBy, orderBy },
      },
      body: buildBody(
        filterQuery,
        filterText,
        myUsersOnly ? userId : undefined
      ),
    },
  });

  const handleScroll = useScrollQueryLoader({
    query: usersData,
  });

  const isLoading = usersData.isLoading || usersData.isFetching;

  const renderItem = (data: UserItem) => {
    return (
      <UserManagementTile
        key={data.id}
        value={data}
        onUserDuplicate={onUserDuplicate}
        onUserDeactivate={onUserDeactivate}
        authUserHighestRole={authUserHighestRole}
      />
    );
  };

  return (
    <ScrollHeaderLayout
      onScroll={handleScroll}
      isLoading={isLoading}
      children={
        <Stack
          px={2}
          pb={1}
          flex={1}
          children={
            <TileLayout<UserItem>
              rows={combineInfiniteTilesData(usersData.data?.pages)}
              renderItem={renderItem}
              renderEmpty={NoUsersOverlay}
              minTileWidth={420}
            />
          }
        />
      }
    />
  );
}
