import { Stack, useTheme } from "@mui/material";
import { blue, green, purple } from "@mui/material/colors";

type OperatorProps = {
  fontSize?: number;
  lineHeight?: number;
};

type TILDAOperatorProps = OperatorProps & {
  value?: string;
};

export function ANDOperator({ fontSize = 14, lineHeight = 1 }: OperatorProps) {
  return (
    <Stack
      sx={{
        fontFamily: "monospace",
        color: green["500"],
        fontSize,
        fontWeight: 400,
        lineHeight,
      }}
    >
      AND
    </Stack>
  );
}

export function NOTOperator({ fontSize = 14, lineHeight = 1 }: OperatorProps) {
  const { palette } = useTheme();

  return (
    <Stack
      sx={{
        fontFamily: "monospace",
        color: palette.warning.main,
        fontSize,
        fontWeight: 400,
        lineHeight,
      }}
    >
      NOT
    </Stack>
  );
}

export function OROperator({ fontSize = 14, lineHeight = 1 }: OperatorProps) {
  return (
    <Stack
      sx={{
        fontFamily: "monospace",
        color: blue["500"],
        fontSize,
        fontWeight: 400,
        lineHeight,
      }}
    >
      OR
    </Stack>
  );
}

export function TILDAOperator({
  fontSize = 14,
  lineHeight = 1,
  value = "",
}: TILDAOperatorProps) {
  return (
    <Stack
      sx={{
        fontFamily: "monospace",
        color: purple["A200"],
        fontSize,
        fontWeight: 400,
        lineHeight,
      }}
    >
      ~{value}
    </Stack>
  );
}
