import { AppRouteBuilder } from "src/models/AppRoute";
import { ReportCreatePage } from "./ReportCreate.page";

export type ReportCreateQueryParams = {
  all?: number;
} | void;

export const reportCreateRoute = new AppRouteBuilder<
  void,
  ReportCreateQueryParams
>({
  path: "/reports/create",
  element: <ReportCreatePage />,
});
