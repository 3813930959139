import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { MediaCenterItem } from "src/api/useMediaCenter";

const apiKey: keyof paths = "/api/Export/csv";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  selectedEvents:
    | PowerSearchResultItem[]
    | MediaCenterItem["eventHighlights"][];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useExportCsv({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ selectedEvents }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey);

      const { data, headers } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: selectedEvents,
      });

      let fileName = "export_csv.csv";
      const contentDispositionHeader = headers["content-disposition"];
      const splittedContentDispositionData = contentDispositionHeader.match(
        /filename\*?=(UTF-8['']'')?"(.*?)"(?=;|$)/
      );
      if (splittedContentDispositionData) {
        fileName = splittedContentDispositionData[2];
      }
      const blob = new Blob([data]);
      const file = new File([blob], fileName);
      const href = URL.createObjectURL(file);
      const aElement = document.createElement("a");

      aElement.setAttribute("download", fileName);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();

      URL.revokeObjectURL(href);

      return data;
    },
  });
}
