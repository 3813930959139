import { ReactComponent as LogoColorSmall } from "src/components/logo/logo-color-small.svg";
import { PropsWithChildren, useEffect, useState } from "react";
import {
  useTheme,
  Box,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";

export type SpinnerProps = {
  size?: number;
};

const AbsoluteContainer = ({
  visible,
  children,
}: PropsWithChildren<{ visible: boolean }>) => {
  const { transitions } = useTheme();
  const props = ["scale", "opacity"];
  const params = { duration: 500 };
  const transition = transitions.create(props, params);

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      right={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        scale: visible ? "1.0" : "0.75",
        opacity: visible ? 1 : 0,
        transition,
      }}
    >
      {children}
    </Box>
  );
};

const MuiSpinnerWrapper = ({
  variant,
  thickness,
}: {
  variant: "primary" | "secondary";
  thickness: number;
}) => {
  const { palette } = useTheme();
  return (
    <CircularProgress
      variant="indeterminate"
      disableShrink
      size="50%"
      thickness={thickness}
      sx={{
        rotate: variant === "primary" ? "0" : "120deg",
        color: palette[variant].main,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: "round",
        },
      }}
    />
  );
};

export const Spinner = ({ size = 40 }: SpinnerProps) => {
  const thickness = 4;
  const stageDelay = 2000;
  const [stage, setStage] = useState<"logo" | "spinner">("spinner");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStage((prevStage) => (prevStage === "logo" ? "spinner" : "logo"));
    }, stageDelay);
    return () => clearTimeout(timeoutId);
  }, [stage]);

  return (
    <Box position="relative" width={size} height={size}>
      <AbsoluteContainer visible={stage === "logo"}>
        <LogoColorSmall width="100%" height="100%" />
      </AbsoluteContainer>

      <AbsoluteContainer visible={stage === "spinner"}>
        <MuiSpinnerWrapper variant="primary" thickness={thickness} />
      </AbsoluteContainer>

      <AbsoluteContainer visible={stage === "spinner"}>
        <MuiSpinnerWrapper variant="secondary" thickness={thickness} />
      </AbsoluteContainer>
    </Box>
  );
};
