import { UseInfiniteQueryResult } from "react-query";
import {
  MarketSnapshot,
  MarketSnapshotPagedResultSet,
  StationSnapshot,
} from "src/api/useSnapshot";

type SnapshotsByMarketName = {
  [marketName: string]: MarketSnapshot[];
};

export function groupSnapshotsByMarketId(
  snapshotsResults: UseInfiniteQueryResult<MarketSnapshotPagedResultSet>
) {
  const groupedResults =
    snapshotsResults?.data?.pages.reduce(
      (grouped: SnapshotsByMarketName, snapshot) => {
        snapshot?.results?.forEach((result) => {
          const marketName = result?.marketInfo?.name;
          if (marketName) {
            if (!grouped[marketName]) {
              grouped[marketName] = [];
            }
            grouped[marketName].push(result as MarketSnapshot);
          }
        });
        return grouped;
      },
      {}
    ) || {};

  const groupedResultsArray = Object.entries(groupedResults).map(
    ([_marketId, results]) => {
      const resultsArray = results
        ?.map((result) => result?.stationSnapshots)
        ?.flat();

      return {
        stationSnapshots: resultsArray as StationSnapshot[],
        marketInfo: results[0]?.marketInfo,
      };
    }
  );

  return groupedResultsArray;
}
