import { add, addSeconds, isAfter, isBefore, subSeconds } from "date-fns";

export const updateDateTimeRange = ({
  offsetInSec,
  offsetOutSec,
  prev,
  bounds,
  minSpanSec,
}: {
  offsetInSec?: number;
  offsetOutSec?: number;
  prev: [Date | null, Date | null];
  bounds: [Date | null, Date | null];
  minSpanSec: number;
}): [Date | null, Date | null] => {
  const [bound0, bound1] = bounds;

  if (!bound0 || !bound1) {
    return prev;
  }

  let nextT0 = prev[0];
  let nextT1 = prev[1];

  if (typeof offsetInSec === "number") {
    // move start to current offset
    nextT0 = add(bound0, { seconds: offsetInSec });

    // check if start offset is beyond bound start
    if (isBefore(nextT0, bound0)) {
      nextT0 = new Date(bound0);
    }

    // check if start offset if beyond bound end
    if (isAfter(nextT0, bound1)) {
      nextT0 = new Date(bound1);
    }

    // use existing end value or default to start + minimal span
    nextT1 = prev[1] ? new Date(prev[1]) : addSeconds(nextT0, minSpanSec);

    // check that end is after minimal span from start
    if (isBefore(nextT1, addSeconds(nextT0, minSpanSec))) {
      nextT1 = addSeconds(nextT0, minSpanSec);
    }

    // check if next end if beyond event end bound
    if (isAfter(nextT1, bound1)) {
      // shift minimal span back from end bound
      nextT1 = new Date(bound1);
      // adjust start to be minSpanSec before new end
      nextT0 = subSeconds(bound1, minSpanSec);
    }
  }

  if (typeof offsetOutSec === "number") {
    // move end to current offset
    nextT1 = add(bound0, { seconds: offsetOutSec });

    // check if start offset is beyond bound start
    if (isBefore(nextT1, bound0)) {
      nextT1 = new Date(bound0);
    }

    // check if start offset if beyond bound end
    if (isAfter(nextT1, bound1)) {
      nextT1 = new Date(bound1);
    }

    // use existing start value or default to end - minimal span
    if (!nextT0) {
      nextT0 = subSeconds(nextT1, minSpanSec);
    }

    // check that start is before minimal span from end
    if (isAfter(nextT0, subSeconds(nextT1, minSpanSec))) {
      nextT0 = subSeconds(nextT1, minSpanSec);
    }

    // check if next start if before event start bound
    if (isBefore(nextT0, bound0)) {
      // shift minimal span back from end bound
      nextT0 = new Date(bound0);
      // adjust end to be minSpanSec after start
      nextT1 = addSeconds(nextT0, minSpanSec);
    }
  }

  const next: [Date | null, Date | null] = [nextT0, nextT1];

  if (JSON.stringify(prev) === JSON.stringify(next)) {
    return prev;
  }

  return next;
};
