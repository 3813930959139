import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react";
import {
  Box,
  IconButton,
  iconButtonClasses,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  addMinutes,
  addSeconds,
  differenceInSeconds,
  format,
  isEqual,
  max,
  min,
} from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import { Update } from "@mui/icons-material";
import { UseShiftPlayerResponse } from "src/api/useShiftPlayerData";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { EventDetails } from "src/models/EventDetails";
import { UseTrimRangeValue } from "src/components/ClipEditorPage/hook/useTrimRangeState";
import { ShiftPlayerSetStartEndButtons } from "src/components/ShiftPlayerDrawerMobile/ShiftPlayerEventsCarousel/ShiftPlayerSetStartEndButtons";
import { TranscriptEmpty } from "src/components/TranscriptEmpty/TranscriptEmpty";
import { updateDateTimeRange } from "src/utils/updateDateTimeRange";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { ShifterPlayerEventType } from "./ShiftPlayerEventsList.model";

export function ShiftPlayerListItem({
  value,
  rows,
  event,
  isExpandedTextMode,
  onShiftPlayerPlay,
  inititalEventId,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange = () => null,
  mode,
  offset,
}: {
  value: ShifterPlayerEventType;
  rows: UseShiftPlayerResponse;
  event: EventDetails;
  isExpandedTextMode?: boolean;
  inititalEventId: string | null;
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  bounds?: [Date, Date] | null;
  minSpanSec: number;
  trimRange?: UseTrimRangeValue;
  setTrimRange?: Dispatch<SetStateAction<UseTrimRangeValue>>;
  mode: "edit" | "view";
  offset: number;
}) {
  const itemRef = useRef<null | HTMLLIElement>(null);
  const { palette } = useTheme();

  useEffect(() => {
    if (!inititalEventId) return;

    // if we don't have an event with the same id as the inititalEventId (closest to a specific event, in component ShiftPlayerListItem "event" param), we will scroll the list to the beginning
    const isIdExistInList = rows.some((item) => {
      return item.id === inititalEventId;
    });
    if (!isIdExistInList && value.id === rows[0].id) {
      itemRef?.current?.scrollIntoView();
    }
    //

    if (value.id !== inititalEventId) return;

    // otherwise we will jump to that event from the event list.
    itemRef?.current?.scrollIntoView();
  }, [value.id, inititalEventId, rows]);

  const onChangeWrapper = useDebouncedCallback(setTrimRange, 500);

  // "force" param used to combine different events into one directly
  const handleSetStart = (t0?: Date, force?: boolean, t1?: Date | null) => {
    if (!bounds || !trimRange) return;

    if (force && t0) {
      const outputNextRange: [Date, Date | null] = [t0, t1 ?? null];
      return onChangeWrapper(outputNextRange);
    }

    const nextRange = updateDateTimeRange({
      offsetInSec: bounds[0] && t0 ? differenceInSeconds(t0, bounds[0]) : 0,
      prev: trimRange,
      bounds,
      minSpanSec,
    });
    onChangeWrapper(nextRange);
  };

  // "force" param used to combine different events into one directly
  const handleSetEnd = (t1?: Date, force?: boolean, t0?: Date | null) => {
    if (!bounds || !trimRange) return;

    if (force && t1) {
      const outputNextRange: [Date | null, Date] = [t0 ?? null, t1];
      return onChangeWrapper(outputNextRange);
    }

    const nextRange = updateDateTimeRange({
      offsetOutSec: bounds[0] && t1 ? differenceInSeconds(t1, bounds[0]) : 0,
      prev: trimRange,
      bounds,
      minSpanSec,
    });
    onChangeWrapper(nextRange);
  };

  const handlePlay = () => {
    if (!value.blockEndTime || !value.blockStartTime) return;
    if (!event.startDateTime || !event.endDateTime) return;

    const startDate = addMinutes(new Date(value.blockStartTime), -1);
    const endDate = addMinutes(new Date(value.blockStartTime), 9);

    const outputData: ShiftPlayerDataProps = {};
    outputData.startDateTime = formatDateTimeWithoutTimeZone(startDate);
    outputData.endDateTime = formatDateTimeWithoutTimeZone(endDate);

    const currentCursorPosition = addSeconds(
      new Date(event.startDateTime),
      offset / 1000
    );

    const minDate = min([endDate, new Date(event.startDateTime)]);
    const maxDate = max([startDate, new Date(event.endDateTime)]);
    const isCurrentCPEarlierThanEventStartDate = isEqual(
      min([currentCursorPosition, startDate]),
      currentCursorPosition
    );
    if (
      isEqual(minDate, endDate) ||
      isEqual(maxDate, startDate) ||
      isCurrentCPEarlierThanEventStartDate
    ) {
      outputData.shiftOffset = 60;
    }

    onShiftPlayerPlay && onShiftPlayerPlay(outputData);
  };

  const blockTimeLabel = value.blockStartTime
    ? format(new Date(value.blockStartTime), "pp")
    : "";

  const isTranscriptNotAvailable =
    value.transcript === "No Transcript Available";
  const transcriptView = useMemo(() => {
    return isExpandedTextMode ? (
      <Typography variant="body2">
        <TranscriptParser
          highlightColor={palette.primary.main}
          transcript={value.transcript ?? ""}
          color={isTranscriptNotAvailable ? palette.text.secondary : undefined}
        />
      </Typography>
    ) : (
      <TranscriptText
        variant="body2"
        transcript={value.transcript ?? ""}
        lineClamp={4}
        color={isTranscriptNotAvailable ? palette.text.secondary : undefined}
      />
    );
  }, [
    value,
    isExpandedTextMode,
    isTranscriptNotAvailable,
    palette.primary.main,
    palette.text.secondary,
  ]);

  const TranscriptWrapper = isTranscriptNotAvailable
    ? TranscriptEmpty
    : TranscriptBlock;

  return (
    <ListBaseItem
      key={value.id}
      innerRef={itemRef}
      sx={{
        alignItems: "stretch",
        p: 1.5,
        columnGap: 2,
        [`&:hover .${iconButtonClasses.root}`]: {
          visibility: "visible",
        },
      }}
    >
      <Box
        position="relative"
        overflow="hidden"
        height={115}
        sx={{ aspectRatio: "16 / 9", cursor: "pointer" }}
        borderRadius={1}
        component="img"
        alt="Thumbnail"
        src={value.thumbnail ?? ""}
        onClick={handlePlay}
      />
      <IconButton
        size="large"
        onClick={handlePlay}
        sx={{
          position: "absolute",
          top: 45,
          left: 82,
          color: palette.primary.contrastText,
          visibility: "hidden",
          backgroundColor: palette.action.active,
        }}
      >
        <Update fontSize="large" />
      </IconButton>

      <Stack flex={1} gap={1} justifyContent="space-between" overflow="hidden">
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{blockTimeLabel}</Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            minWidth="fit-content"
            sx={{
              display: mode === "view" ? "none" : undefined,
            }}
          >
            <ShiftPlayerSetStartEndButtons
              value={value}
              event={event}
              onShiftPlayerPlay={onShiftPlayerPlay}
              handleSetStart={handleSetStart}
              handleSetEnd={handleSetEnd}
              trimRange={trimRange}
            />
          </Stack>
        </Stack>

        <TranscriptWrapper height="100%" px={2} borderRadius={1}>
          {transcriptView}
        </TranscriptWrapper>
      </Stack>
    </ListBaseItem>
  );
}
