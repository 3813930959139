import Joi from "joi";
import { UserManagementFilterFormValues } from "./UserManagementFilterForm.model";

export const UserManagementFilterFormSchema =
  Joi.object<UserManagementFilterFormValues>({
    accountIds: Joi.array().items(Joi.string()),

    status: Joi.string().allow(null),

    timezone: Joi.string().allow(null),

    roles: Joi.array().items(Joi.string()),
  });
