import { Fragment, useCallback, useEffect, useState } from "react";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  ToggleButton,
  typographyClasses,
} from "@mui/material";
import {
  StaticDateTimePicker,
  StaticDateTimePickerProps,
  dateTimePickerToolbarClasses,
} from "@mui/x-date-pickers-pro";
import { useOpenState } from "src/utils/useOpenState";

import { DateTimeRangePickerMobileProps } from "./DateTimeRangePickerMobile.model";

enum PickerStage {
  from = "from",
  to = "to",
}

// FIXME: we should probably use built in DateTimeRangePicker from MUI
export const DateTimeRangePickerMobile = ({
  value,
  onChange,
  iconButtonSize = "medium",
}: DateTimeRangePickerMobileProps) => {
  const modalState = useOpenState();
  const [fromDate, setFromDate] = useState<Date | null>(value[0]);
  const [toDate, setToDate] = useState<Date | null>(value[1]);
  const [stage, setStage] = useState<PickerStage>(PickerStage.from);
  const isFromStage = stage === PickerStage.from;

  const handlePrev = useCallback(() => {
    if (isFromStage) {
      modalState.hide();
    } else {
      setStage(PickerStage.from);
    }
  }, [isFromStage, modalState]);

  const handleNext = useCallback(() => {
    if (isFromStage) {
      setStage(PickerStage.to);
    } else {
      modalState.hide();
      // accept range and notify caller
      onChange([fromDate, toDate]);
    }
  }, [isFromStage, modalState, fromDate, toDate, onChange]);

  const renderActionBar = useCallback(() => {
    const nextButtonLabel = isFromStage ? "Next" : "OK";
    const prevButtonLabel = isFromStage ? "Cancel" : "Prev";
    return (
      <DialogActions sx={{ gridColumn: "1/4", gridRow: 3 }}>
        <Button onClick={handlePrev}>{prevButtonLabel}</Button>
        <Button onClick={handleNext}>{nextButtonLabel}</Button>
      </DialogActions>
    );
  }, [isFromStage, handlePrev, handleNext]);

  const renderPicker = useCallback(
    (params: Omit<StaticDateTimePickerProps<Date>, "sx" | "slots">) => {
      return (
        <StaticDateTimePicker<Date>
          {...params}
          sx={{
            ".MuiPickersToolbar-content": {
              alignItems: "flex-end",
            },
            [`.${dateTimePickerToolbarClasses.timeDigitsContainer}`]: {
              alignItems: "flex-end",

              [`.${typographyClasses.h3}.MuiPickersToolbarText-root`]: {
                fontSize: 34,
              },
              [`.${dateTimePickerToolbarClasses.separator}`]: {
                fontSize: 34,
              },
            },
          }}
          slots={{
            actionBar: renderActionBar,
          }}
        />
      );
    },
    [renderActionBar]
  );

  const fromContent = renderPicker({
    value: fromDate,
    onChange: setFromDate,
    maxDate: toDate || undefined,
    onAccept: (nextFromDateTime) => {
      setFromDate(nextFromDateTime);
      setStage(PickerStage.to);
    },
    localeText: {
      toolbarTitle: "START DATE",
    },
  });

  const toContent = renderPicker({
    value: toDate,
    onChange: setToDate,
    minDate: fromDate || undefined,
    onAccept: (nextToDateTime) => {
      modalState.hide();
      setToDate(nextToDateTime);

      // accept range and notify caller
      onChange([fromDate, nextToDateTime]);
    },
    localeText: {
      toolbarTitle: "END DATE",
    },
  });

  useEffect(() => {
    if (modalState.isOpen) {
      // set values on open and prop change
      setFromDate(value[0]);
      setToDate(value[1]);
    } else {
      // reset stage on close
      setStage(PickerStage.from);
    }
  }, [value, modalState.isOpen]);

  return (
    <Fragment>
      <ToggleButton
        sx={{ color: "text.primary" }}
        value={true}
        size={iconButtonSize}
        onClick={modalState.show}
      >
        <DateRangeIcon />
      </ToggleButton>

      <Dialog open={modalState.isOpen} onClose={modalState.hide}>
        {stage === PickerStage.from && fromContent}
        {stage === PickerStage.to && toContent}
      </Dialog>
    </Fragment>
  );
};
