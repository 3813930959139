import { AppRouteBuilder } from "src/models/AppRoute";
import { SnapshotsClipEditorPage } from "./SnapshotsClipEditor.page";

export type SnapshotsClipEditorPathParam = {
  // no params for now
};

export const SnapshotsClipEditorRoute =
  new AppRouteBuilder<SnapshotsClipEditorPathParam>({
    path: "/snapshot/result/edit",
    element: <SnapshotsClipEditorPage />,
  });
