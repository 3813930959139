import { HTMLAttributes, PropsWithChildren, useRef } from "react";
import { List, Paper, Stack } from "@mui/material";
import { useElementSize } from "src/utils/useElementSize";
import { TagPickerListFooter } from "./TagPickerListFooter";

type NativeListProps = Omit<HTMLAttributes<HTMLUListElement>, "onChange">;

export type TagPickerListProps<T> = PropsWithChildren<
  NativeListProps & {
    presets?: Record<string, T[]>;
    layout?: "row" | "column";
    onChange: (list: T[]) => void;
    onClear: () => void;
    listRef: React.Ref<HTMLUListElement>;
    renderListHeader?: () => JSX.Element;
  }
>;

export function TagPickerList<T>({
  onChange,
  onClear,
  presets,
  listRef,
  layout,
  renderListHeader,
  ...props
}: TagPickerListProps<T>) {
  const footerRef = useRef<HTMLDivElement>(null);
  const footerContainer = useElementSize(footerRef);

  if (layout === "column") {
    return (
      <Stack>
        {renderListHeader?.()}
        <List
          {...props}
          ref={listRef}
          style={{
            ...props.style,
            padding: 0,
            display: "flex",
            borderRadius: 1,
            maxHeight: 396,

            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {props.children}
          {presets && (
            <Paper
              ref={footerRef}
              elevation={2}
              sx={{
                position: "sticky",
                bottom: 0,
                zIndex: 1,
              }}
            >
              <TagPickerListFooter
                onChange={onChange}
                onClear={onClear}
                presets={presets}
                layout={layout}
              />
            </Paper>
          )}
        </List>
      </Stack>
    );
  }

  return (
    <Stack>
      {renderListHeader?.()}
      <List
        {...props}
        ref={listRef}
        style={{
          ...props.style,
          padding: 0,
          display: "flex",
          borderRadius: 1,
          width: "100%",
          maxHeight: 396,

          overflow: "hidden",
          position: "relative",
          paddingBottom: `${footerContainer?.height}px`, // to compensate for footer
        }}
      >
        {props.children}
        {presets && (
          <Paper
            ref={footerRef}
            elevation={2}
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <TagPickerListFooter
              onChange={onChange}
              onClear={onClear}
              presets={presets}
              layout={layout}
            />
          </Paper>
        )}
      </List>
    </Stack>
  );
}
