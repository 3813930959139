import { Box } from "@mui/material";
import { useWatchQueryClusterDelete } from "src/api/useWatchQueryClusterDelete";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "../DialogPrompt/DialogPrompt.model";

type WatchQueryDeleteDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  clusterId: string;
  clusterTitle: string;
};

export const WatchQueryClusterDeleteDialog = ({
  onClose,
  clusterId,
  clusterTitle,
  ...props
}: WatchQueryDeleteDialogProps) => {
  const deleteMutation = useWatchQueryClusterDelete({});

  const handleDelete = () => {
    deleteMutation.mutate({ clusterId, clusterTitle });
    onClose && onClose();
  };

  return (
    <DialogPrompt
      maxWidth="sm"
      title="Delete the cluster?"
      children={
        <Box pb={1}>
          Do you want to delete the {clusterTitle} cluster? Queries will not be
          deleted.
        </Box>
      }
      onDecline={onClose}
      onConfirm={handleDelete}
      confirmLabel="Delete"
      {...props}
    />
  );
};
