import React, { useCallback } from "react";
import { Box, Drawer, Stack, drawerClasses, useTheme } from "@mui/material";
import { useDrawer } from "src/utils/useDrawer";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";
import { CollapseExpandButton } from "src/components/buttons/CollapseExpandButton";
import { DrawerNavMenu } from "./DrawerNavMenu";
import { ProfileButton } from "./ProfileButton";
import { ThemeSwitch } from "./ThemeSwitch";
import { Logo } from "../logo/Logo";
import { AppLink } from "../AppLink/AppLink";

export const DrawerDesktop = () => {
  const theme = useTheme();
  const scrollbarSx = useCustomScrollBarStyles({
    overflowY: "scroll",
    overflowX: "hidden",
    size: "thin",
  });

  const { isOpen, hide, show } = useDrawer();

  const onToggle = useCallback(() => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  }, [isOpen, hide, show]);

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        open={isOpen}
        onClose={hide}
        elevation={16}
        sx={{
          [`.${drawerClasses.paper}`]: {
            ...scrollbarSx,
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Stack flexGrow={1} ml={1} mr={0.5}>
          <Box ml={1} mt={0.5} mb={1.5}>
            <AppLink to={"/"}>
              <Logo height={64} dark={theme.palette.mode === "dark"} />
            </AppLink>
          </Box>

          <DrawerNavMenu mr={0.5} flexGrow={1} />

          <ThemeSwitch collapsed={!isOpen} />

          <Box mt={2} mb={2.5}>
            <ProfileButton collapsed={!isOpen} />
          </Box>
        </Stack>
      </Drawer>

      <Box
        sx={{
          // this has overflow-y: auto and conflicts with overflow-x: visible on paper
          // so I moved collapse-expand container outside of the drawer's paper
          // and set it to move accordingly with the drawer's width
          position: "fixed",
          left: isOpen
            ? theme.mixins.drawer.expanded.width
            : theme.mixins.drawer.collapsed.width,
          top: "20px",
          transform: "translate(-50%, 0)",
          zIndex: theme.zIndex.drawer,
          transition: theme.transitions.create(
            "left",
            isOpen
              ? theme.mixins.drawer.expanded.transition
              : theme.mixins.drawer.collapsed.transition
          ),
        }}
      >
        <CollapseExpandButton onClick={onToggle} collapsed={!isOpen} />
      </Box>
    </React.Fragment>
  );
};
