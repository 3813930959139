import { useRef } from "react";
import {
  Alert,
  Grid,
  Stack,
  Typography,
  alertClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { UserManagementView } from "src/pages/Settings/Settings.model";
import { UserInformationUpdateFormValues } from "./UserInformationUpdateForm.model";
import { TextInputBase } from "../TextInputBase";

export function UserInformationUpdateForm({
  isBusy,
}: {
  user?: UserManagementView;
  isBusy: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext<UserInformationUpdateFormValues>();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Stack ref={containerRef}>
      <Grid container spacing={1} pt={1}>
        <Grid item xs={12} md={12}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="First name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  disabled={isBusy}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} pt={0}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Last name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  disabled={isBusy}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  disabled={isBusy}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Phone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  disabled={isBusy}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          mt={1}
          width={isMobile ? "280px" : "inherit"}
        >
          <Alert
            severity="info"
            sx={{
              padding: "6px 8px",
              [`&.${alertClasses.root}`]: {
                minWidth: "280px",
              },
              [`.${alertClasses.icon}`]: {
                marginRight: 1,
              },
            }}
          >
            <Typography variant="body2">
              Your account manager will check and update your info.
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </Stack>
  );
}
