import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Stack, Typography } from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { PageLayout } from "src/components/PageLayout";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { useUserCreate } from "src/api/useUserCreate";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { makeContentFilterQuery } from "src/utils/makeContentFilterQuery";
import { accountsManagementRoute } from "../AccountsManagement/AccountsManagement.route";
import { AccountsManagementAddUserGeneralTab } from "./components/AccountsManagementAddUserGeneralTab/AccountsManagementAddUserGeneralTab";
import { SettingsCustomizationTab } from "../Settings/components/SettingsCustomizationTab";
import { AccountsManagementFeaturesTab } from "../AccountsManagementCreateAccount/components/AccountsManagementFeaturesTab/AccountsManagementFeaturesTab";
import { AccountsContentTab } from "../AccountsManagementCreateAccount/components/AccountsManagementContentTab/AccountsManagementContentTab";
import {
  sourceFilterMapper,
  makeCreateUserDefaultValues,
  makeEntitlementsFromAddUserFormValues,
} from "../AccountsManagementEditAccount/utils/sourceFilterMapper";
import { AccountsManagementAddUserFormValues } from "../AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.model";
import { AccountsManagementAddUserFormSchema } from "../AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.schema";

enum AddUserTabs {
  general = "general",
  content = "content",
  features = "features",
  customization = "customization",
}

export function AccountsManagementAddUserPage() {
  const [addUserTab, setAddUserTab] = useViewModeQueryParam<AddUserTabs>({
    paramKey: "addUser",
    defaultValue: AddUserTabs.general,
  });

  const { data: sourcesData } = useEventSources({
    path: {
      categories: Object.values(EventSourceCategory),
      searchText: "*",
    },
  });

  const userCreate = useUserCreate({
    options: {
      onSuccess: (data) => {
        const { firstName, lastName } = data;
        enqueueSnackbar(
          `User “${firstName ?? ""} ${
            lastName ?? ""
          }” was successfully added to "${accountData?.name}" account`,
          { variant: "success" }
        );
      },
    },
  });
  const { isSuccess } = userCreate;

  const {
    state: { accountData = {} },
  } = useLocation();

  const [contentFilters, setContentFilters] =
    useState<ContentAccessFilter | null>(null);

  const sources = sourceFilterMapper(
    accountData.contentAccessFilter,
    sourcesData
  );

  const initialFormValues = useMemo(
    () => makeCreateUserDefaultValues(accountData, sources),
    [accountData, sources]
  );

  const formHook = useForm<AccountsManagementAddUserFormValues>({
    mode: "all",
    resolver: joiResolver(AccountsManagementAddUserFormSchema),
    defaultValues: initialFormValues,
  });

  const { handleSubmit, watch } = formHook;
  const navigate = useNavigate();
  const newUserFormData = watch();

  useEffect(() => {
    const filters = makeContentFilterQuery(
      newUserFormData.sourcesInclude,
      newUserFormData.sourcesExclude
    );
    setContentFilters(filters);
  }, [newUserFormData.sourcesExclude, newUserFormData.sourcesInclude]);

  const submitNewUser = handleSubmit(
    (data: AccountsManagementAddUserFormValues) => {
      const bodyData = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        accountId: accountData.id,
        phoneNumber: data.phoneNumber,
        timeZone: data.timezone,
        roles: data.roles,
        isActive: data.isActive,
        contentAccessFilter: contentFilters,
        entitlements: makeEntitlementsFromAddUserFormValues(data),
      };

      userCreate.mutate({
        body: bodyData,
        query: { notify: data.notify },
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitAccountsNewUser:error", errors);
    }
  );

  useEffect(() => {
    if (isSuccess) {
      navigate(accountsManagementRoute.path);
    }
  }, [navigate, isSuccess]);

  const createUserButton = (
    <Button
      variant={"contained"}
      color="primary"
      sx={{ height: "42px", width: "196px", fontSize: "15px" }}
      onClick={submitNewUser}
    >
      Create User
    </Button>
  );

  const customizationTab = (
    <Stack height="100%" overflow="auto">
      <SettingsCustomizationTab disabled page="account" />
    </Stack>
  );

  return (
    <PageLayout pb={0} hideTopBar>
      <Stack height="100%">
        <Stack
          flexWrap="wrap"
          direction="row"
          mb={3}
          justifyContent="space-between"
          alignItems="center"
          marginBottom={3.75}
        >
          <Stack>
            <Typography flexGrow={1} variant="h6" mr={2}>
              Add User
            </Typography>
            <AppTopNavBar
              hidePenultimateRoute
              lastBreadcrumbLabel={"New User"}
              sx={{ mb: 2, mt: 0.5 }}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={{ height: "42px", width: "196px", fontSize: "15px" }}
              onClick={() => navigate(accountsManagementRoute.path)}
            >
              Cancel
            </Button>
            {createUserButton}
          </Stack>
        </Stack>
        <FormProvider {...formHook}>
          <AppTabLayout
            flex={1}
            overflow="auto"
            variant="scrollable"
            currentTab={addUserTab}
            onCurrentTabChange={(tab) => setAddUserTab(tab as AddUserTabs)}
          >
            <AppTab
              label="General"
              value={AddUserTabs.general}
              children={<AccountsManagementAddUserGeneralTab />}
            />
            <AppTab
              label="Content Filter"
              value={AddUserTabs.content}
              children={<AccountsContentTab contentFilters={contentFilters} />}
            />
            <AppTab
              label="Features"
              value={AddUserTabs.features}
              children={<AccountsManagementFeaturesTab disabled />}
            />
            <AppTab
              disabled
              label="Customization"
              value={AddUserTabs.customization}
              children={customizationTab}
            />
          </AppTabLayout>
        </FormProvider>
      </Stack>
    </PageLayout>
  );
}
