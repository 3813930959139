import { Stack, StackProps } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function PCastSource({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock value={event.title || "title"} />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock title="Source" value={event.source} />
        <EventDetailsDataBlock title="Categories" value={event.category} />
        <EventDetailsDataBlock title="Country" value={event.country} />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock title="Author" />
        <EventDetailsDataBlock title="Owner" />
      </Stack>
    </Stack>
  );
}
