import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useWatchQueryClusterAssign } from "src/api/useWatchQueryClusterAssign";
import { useWatchQueryClusterCreate } from "src/api/useWatchQueryClusterCreate";
import {
  clusterSpecialOptions,
  useWatchQueryClusterUnassign,
} from "src/api/useWatchQueryClusterUnassign";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { WatchListClusterAssignForm } from "../WatchListClusterAssignForm/WatchListClusterAssignForm";
import { WatchListClusterAssignFormValues } from "../WatchListClusterAssignForm/WatchListClusterAssignForm.model";
import { makeWatchListClusterAssignFormSchema } from "../WatchListClusterAssignForm/WatchListClusterAssignForm.schema";
import { useWatchListClusterEditMode } from "../WatchListClusterEditToolbar/hooks/useWatchListClusterEditMode";

type WatchListClusterAssignDialogProps = {
  open: DialogPromptProps["open"];
  queryIds: string[];
};

export function WatchListClusterAssignDialog({
  queryIds = [],
  open,
  ...props
}: WatchListClusterAssignDialogProps) {
  const { setQueryList, clusterNames, assignDialogHide } =
    useWatchListClusterEditMode();
  const clusterCreate = useWatchQueryClusterCreate({});
  const clusterAssign = useWatchQueryClusterAssign({});
  const clusterUnassign = useWatchQueryClusterUnassign({});

  const WatchListClusterAssignFormSchema = makeWatchListClusterAssignFormSchema(
    {
      clusterNames,
    }
  );

  const formHook = useForm<WatchListClusterAssignFormValues>({
    resolver: joiResolver(WatchListClusterAssignFormSchema),
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      queryIds,
      cluster: null,
    },
  });

  const { formState, handleSubmit, setValue, reset } = formHook;

  useEffect(() => {
    if (open) {
      setValue("queryIds", queryIds);
    } else {
      reset();
    }
  }, [open, reset, setValue, queryIds]);

  const onSubmitWrapper = handleSubmit((formValues) => {
    assignDialogHide();
    setQueryList([]);

    if (
      // special value to unassign queries from cluster
      formValues.cluster?.title === clusterSpecialOptions.unassign.title &&
      formValues.cluster?.id === clusterSpecialOptions.unassign.id
    ) {
      return clusterUnassign.mutate({
        body: formValues.queryIds,
      });
    }

    if (formValues.cluster?.id) {
      // update existing cluster
      return clusterAssign.mutate({
        title: formValues.cluster.title,
        body: formValues.queryIds,
        params: {
          path: {
            clusterId: formValues.cluster.id,
          },
        },
      });
    }

    if (formValues.cluster?.title) {
      // create new cluster
      return clusterCreate.mutate({
        body: formValues.queryIds,
        params: {
          query: {
            title: formValues.cluster.title,
          },
        },
      });
    }
  });

  return (
    <DialogPrompt
      title="Assign to Cluster"
      open={open}
      onDecline={assignDialogHide}
      declineLabel="Cancel"
      onConfirm={onSubmitWrapper}
      confirmDisabled={!formState.isValid}
      confirmColor="primary"
      confirmLabel="Apply"
      children={
        <FormProvider {...formHook} children={<WatchListClusterAssignForm />} />
      }
    />
  );
}
