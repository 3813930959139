import { ReactNode } from "react";
import { Article, Close } from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ANDOperator,
  NOTOperator,
  OROperator,
  TILDAOperator,
} from "../CodeEditorSection/Operators";

const ContentHeader = ({ onClose }: { onClose: () => void }) => (
  <Stack flexDirection="row" justifyContent="space-between">
    <Typography px={{ xs: 2, sm: 3 }} py={2} variant="subtitle1">
      Query Examples
    </Typography>
    <IconButton edge="start" onClick={onClose} sx={{ mr: 3 }}>
      <Close />
    </IconButton>
  </Stack>
);

const ContentFooter = ({ onClose }: { onClose: () => void }) => (
  <Stack flexDirection="row" justifyContent="space-between" mx={3} my={2}>
    <Stack flexDirection="row" gap={2}>
      <Button
        startIcon={<Article />}
        href="https://insight.tveyes.com/help/en/collections/10239193-custom-query"
      >
        View Article
      </Button>
      {/* <Button startIcon={<PlayCircleFilledOutlined />}>
        Watch Tutorial
      </Button> */}
    </Stack>
    <Button variant="text" color="error" onClick={onClose}>
      Close
    </Button>
  </Stack>
);

const BoxWrapper = ({ children }: { children: ReactNode }) => {
  const { palette } = useTheme();

  return (
    <Box
      py={0.5}
      px={1}
      height={54}
      width={320}
      sx={{
        backgroundColor: alpha(palette.info.main, 0.04),
        borderRadius: 1.5,
        border: `1px solid ${alpha(palette.info.main, 0.12)}`,
      }}
    >
      {children}
    </Box>
  );
};

export function QueryExamplesModalContent({
  onClose,
}: {
  onClose: () => void;
}) {
  const { palette } = useTheme();

  return (
    <Stack width={{ xs: 500 }}>
      <ContentHeader onClose={onClose} />
      <Divider />

      <Stack mx={3}>
        {/* start AND block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <ANDOperator fontSize={16} lineHeight={3} />
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>iPhone</Typography>
              <ANDOperator fontSize={16} />
              <Typography>pro iPhone</Typography>
              <ANDOperator fontSize={16} />
              <Typography>pro</Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <ANDOperator fontSize={16} />
              <Typography>discount</Typography>
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* end AND block */}

        <Divider />

        {/* start OR block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <OROperator fontSize={16} lineHeight={3} />
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>iPhone</Typography>
              <OROperator fontSize={16} />
              <Typography>“i phone”</Typography>
              <OROperator fontSize={16} />
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>(telephone</Typography>
              <ANDOperator fontSize={16} />
              <Typography>apple)</Typography>
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* end OR block */}

        <Divider />

        {/* start NOT block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <NOTOperator fontSize={16} lineHeight={3} />
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>Apple</Typography>
              <ANDOperator fontSize={16} />
              <NOTOperator fontSize={16} />
              <Typography>(iPhone</Typography>
              <OROperator fontSize={16} />
              <Typography>iPad)</Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <ANDOperator fontSize={16} />
              <NOTOperator fontSize={16} />
              <Typography>(MacBook</Typography>
              <OROperator fontSize={16} />
              <Typography>iMac)</Typography>
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* end NOT block */}

        <Divider />

        {/* start ~ block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Stack>
            <TILDAOperator fontSize={16} />
            <Typography fontSize={14}>nearness</Typography>
          </Stack>
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center">
              <Typography>"renewable energy funding</Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Typography>investment"</Typography>
              <TILDAOperator fontSize={16} value="6" />
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* start ~ block */}

        <Divider />

        {/* start "" block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Stack>
            <Stack
              sx={{
                fontFamily: "monospace",
                color: palette.text.primary,
                fontSize: 16,
                fontWeight: 400,
                lineHeight: 1,
              }}
            >
              ""
            </Stack>
            <Typography fontSize={14}>exact phrase</Typography>
          </Stack>
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>“McDonald's breakfast”</Typography>
              <ANDOperator fontSize={16} />
              <NOTOperator fontSize={16} />
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>“Big Mac”</Typography>
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* end "" block */}

        <Divider />

        {/* start () block */}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Stack>
            <Stack
              sx={{
                fontFamily: "monospace",
                color: palette.text.primary,
                fontSize: 16,
                fontWeight: 400,
                lineHeight: 1,
              }}
            >
              ( )
            </Stack>
            <Typography fontSize={14}>group together</Typography>
          </Stack>
          <BoxWrapper>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>("artificial intelligence"</Typography>
              <OROperator fontSize={16} />
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
              <Typography>"machine learning")</Typography>
            </Stack>
          </BoxWrapper>
        </Stack>
        {/* end () block */}
      </Stack>

      <Divider />
      <ContentFooter onClose={onClose} />
    </Stack>
  );
}
