import { useEffect } from "react";
import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { AccountsManagementFilterForm } from "../AccountsManagementFilterForm/AccountsManagementFilterForm";
import { AccountsManagementFilterDialogProps } from "./AccountsManagementFilterDialog.model";
import { useAccountsManagementFilterForm } from "../../hooks/useAccountsManagementFilter";

export function AccountsManagementFilterDialog({
  onClose,
  onSubmit,
  initial,
  onResetForm,
  showUserAccounts,
  ...props
}: AccountsManagementFilterDialogProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const formHook = useAccountsManagementFilterForm({
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;

  const handleSubmitWrapper = handleSubmit((data) => {
    onClose && onClose();
    onSubmit(data);
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
        children={
          <AccountsManagementFilterForm showUserAccounts={showUserAccounts} />
        }
        footer={
          <Stack
            direction="row"
            justifyContent={isMobile ? "justify-between" : "end"}
            width={isMobile ? "100%" : undefined}
            spacing={2}
            minHeight={42}
          >
            <Hidden lgDown>
              <Button
                sx={{ width: 100, display: { xs: "none", md: "flex" } }}
                onClick={onClose}
                color="error"
              >
                Cancel
              </Button>
            </Hidden>
            <Button sx={{ width: 160 }} onClick={onResetForm}>
              Clear options
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isBusy}
              onClick={handleSubmitWrapper}
              sx={{
                minWidth: isMobile ? 160 : 176,
                width: isMobile ? "100%" : undefined,
              }}
            >
              Apply
            </LoadingButton>
          </Stack>
        }
      />
    </FormProvider>
  );
}
