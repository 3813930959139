import { components } from "@tveyes/twosionwebapischema";
import { SourcesPickerValue } from "src/components/SourcesPicker/SourcesPicker.model";
import { makeSourceCategoryIds } from "src/utils/makeSourceCategoryIds";

export function makeSourceFilterQuery(
  data: SourcesPickerValue
): components["schemas"]["QueryDefinition"]["sourceFilter"] {
  const sourceIncludeCategoryIds = data.list
    ? makeSourceCategoryIds(data.list)
    : null;

  return {
    includeOperator: data?.logic,
    excludeOperator: data?.logic,
    countries: {
      include: sourceIncludeCategoryIds?.countryList,
    },
    state: {
      include: sourceIncludeCategoryIds?.stateList,
    },
    markets: {
      include: sourceIncludeCategoryIds?.marketList,
    },
    stations: {
      include: sourceIncludeCategoryIds?.stationList,
    },
    languages: {
      include: sourceIncludeCategoryIds?.languageList,
    },
    contentType: {
      include: sourceIncludeCategoryIds?.eventTypeList,
    },
  };
}
