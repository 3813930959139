import { AppRouteBuilder } from "src/models/AppRoute";
import { PowerSearchResultClipPage } from "./PowerSearchResultClip.page";

export type PowerSearchResultClipPathParam = {
  eventId: string;
};

export const powerSearchResultClipRoute =
  new AppRouteBuilder<PowerSearchResultClipPathParam>({
    path: "/power-search/result/:eventId",
    element: <PowerSearchResultClipPage />,
  });
