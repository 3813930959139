import { useState } from "react";
import { ButtonProps, Stack, useMediaQuery, useTheme } from "@mui/material";
import { InstantAlertButton } from "src/components/AlertButtons/InstantAlertButton";
import { ScheduleAlertButton } from "src/components/AlertButtons/ScheduleAlertButton";
import { ScheduledAlertPopover } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover";
import { WatchQueryInstantAlertDialog } from "src/components/WatchQueryInstantAlertDialog/WatchQueryInstantAlertDialog";
import { ScheduledAlertMobile } from "src/components/ScheduledAlertMobile/ScheduledAlertMobile";
import { alertTypePresets } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { useWatchQueryAlertDelete } from "src/api/useWatchQueryAlertDelete";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { getSkippedInstantAlert } from "src/utils/instantAlertHelpers";
import { UserQueryDTO } from "src/models/UserQueryDTO";

type WatchQueryAlertsProps = {
  queryId: string;
  title: string;
  alerts: UserQueryDTO["alerts"];
};

export function WatchQueryTableAlerts({
  queryId,
  title,
  alerts,
}: WatchQueryAlertsProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [showInstantAlert, setShowInstantAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const instantAlert = alerts.filter((alert) => alert.type === "realtime");
  const scheduledAlerts = alerts.filter((alert) => alert.type !== "realtime");

  const isAlertsEnabled = scheduledAlerts.every((alert) => alert.enabled);

  const alertDelete = useWatchQueryAlertDelete({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });
  const instantAlertDelete = () => {
    if (!instantAlert.length) return;

    const alertId = instantAlert[0].id;
    if (!alertId) return;

    alertDelete.mutate({
      params: {
        path: {
          queryId,
          alertId,
        },
      },
    });
  };

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => setShowInstantAlert(false),
      origin: "watchlist",
      type: "instant",
    },
  });

  const instantAlertCreate = () => {
    if (!queryId) return;

    alertCreate.mutate({
      params: {
        path: {
          queryId,
        },
        query: {
          type: "realtime",
          archive: false,
        },
      },
    });
  };

  const skippedInstantAlert = getSkippedInstantAlert();

  const handleClick: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack direction="row" spacing={1}>
      <InstantAlertButton
        active={instantAlert.length !== 0}
        onClick={() => {
          if (skippedInstantAlert && !instantAlert.length) {
            return instantAlertCreate();
          }
          if (instantAlert.length !== 0) {
            instantAlertDelete();
          } else {
            setShowInstantAlert(true);
          }
        }}
      />
      {!skippedInstantAlert && (
        <WatchQueryInstantAlertDialog
          open={showInstantAlert}
          origin="watchlist"
          queryId={queryId}
          onClose={() => setShowInstantAlert(false)}
        />
      )}
      <ScheduleAlertButton
        active={scheduledAlerts.length !== 0 && isAlertsEnabled}
        onClick={handleClick}
      />
      <ScheduledAlertPopover
        queryId={queryId}
        presets={alertTypePresets}
        open={open && !isMobile}
        anchorEl={anchorEl}
        alerts={alerts}
        origin="watchlist"
        onClose={handleClose}
      />
      <ScheduledAlertMobile
        open={open && isMobile}
        queryId={queryId}
        title={title}
        origin="watchlist"
        alerts={alerts}
        onClose={handleClose}
      />
    </Stack>
  );
}
