import { colors, Stack, Typography } from "@mui/material";
import { TILDAOperator } from "./Operators";

export const PopperTildaContent = () => (
  <Stack py={0.5} pt={1} px={1}>
    {/* start first line */}
    <Stack flexDirection="row" alignItems="center">
      <Typography color={colors.common.white} fontSize={14}>
        "Elon Mask investment"
      </Typography>
      <TILDAOperator value={"5"} />
    </Stack>
    {/* end first line */}
  </Stack>
);
