import { UIEventHandler, useMemo } from "react";
import { UseInfiniteQueryResult } from "react-query";

/** This hook abstracts infinite loading logic with onScroll event and react-query infinite query */
export function useScrollQueryLoader<T>({
  delta = 200,
  query,
  onScroll,
}: {
  /** range before scrolling end to trigger loading */
  delta?: number;

  /** react-query module to fetch next page for */
  query: UseInfiniteQueryResult<T>;

  /**
   * pass-though onScroll handler for additional logic
   */
  onScroll?: UIEventHandler<HTMLDivElement>;
}): UIEventHandler<HTMLDivElement> {
  const { fetchNextPage, hasNextPage, isLoading, isFetching } = query;

  return useMemo(() => {
    const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
      const { currentTarget } = e;
      const { scrollTop, offsetHeight, scrollHeight } = currentTarget;

      if (!currentTarget || !hasNextPage) {
        return;
      }

      if (
        scrollHeight - offsetHeight - scrollTop > delta ||
        isLoading ||
        isFetching
      ) {
        return;
      }

      fetchNextPage();

      // pass the event to the callback if provided
      onScroll?.(e);
    };

    return handleScroll;
  }, [onScroll, fetchNextPage, hasNextPage, isLoading, isFetching, delta]);
}
