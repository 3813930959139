import { JSX, useCallback, useMemo } from "react";
import {
  Button,
  IconButton,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";

export const SelectAllPages = ({
  selectedCount,
  rowCount,
  areAllPagesSelected,
  setAllPagesSelected,
  unselectAll,
  allCheckboxSelector,
  deleteIconProps,
  groupDuplicates,
}: {
  selectedCount: number;
  rowCount: number;
  areAllPagesSelected: boolean;
  setAllPagesSelected: (value: boolean) => void;
  unselectAll: () => void;
  allCheckboxSelector?: JSX.Element | null;
  deleteIconProps?: {
    handleDelete: () => void;
    disabled?: boolean;
  };
  groupDuplicates?: boolean;
}) => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const clearAll = useCallback(() => {
    setAllPagesSelected(false);
    unselectAll();
  }, [setAllPagesSelected, unselectAll]);

  const deleteIconButton = useMemo(
    () => (
      <IconButton
        onClick={deleteIconProps?.handleDelete}
        disabled={deleteIconProps?.disabled}
      >
        <DeleteOutlineOutlined
          sx={{
            color: deleteIconProps?.disabled ? palette.text.disabled : "unset",
          }}
        />
      </IconButton>
    ),
    [palette.text, deleteIconProps]
  );

  const mobileText = useMemo(() => {
    if (areAllPagesSelected) {
      return groupDuplicates ? (
        <>
          <strong>All</strong> Events except duplicates.
        </>
      ) : (
        <>
          All <strong>{selectedCount}</strong> Events are selected
        </>
      );
    }
    return (
      <>
        <strong>
          {selectedCount} of {rowCount}
        </strong>{" "}
        events selected
      </>
    );
  }, [areAllPagesSelected, selectedCount, rowCount, groupDuplicates]);

  const mobileContent = useMemo(
    () => (
      <Stack
        direction="row"
        height={40}
        alignItems="center"
        sx={{
          backgroundColor: alpha(palette.primary.main, 0.08),
          mx: 2,
          mb: 2,
          borderRadius: "4px",
        }}
      >
        {allCheckboxSelector}
        <Typography
          variant="body2"
          sx={{
            ml: allCheckboxSelector ? 0 : 2,
            flex: 1,
          }}
        >
          {mobileText}
        </Typography>
        {areAllPagesSelected ? (
          <Button variant="text" onClick={clearAll} sx={{ ml: "auto", mr: 1 }}>
            Clear all
          </Button>
        ) : (
          <Button
            variant="text"
            sx={{ ml: "auto", mr: 1 }}
            onClick={() => setAllPagesSelected(true)}
          >
            Select all
          </Button>
        )}
        {!!deleteIconProps && deleteIconButton}
      </Stack>
    ),
    [
      allCheckboxSelector,
      mobileText,
      areAllPagesSelected,
      clearAll,
      palette.primary.main,
      setAllPagesSelected,
      deleteIconProps,
      deleteIconButton,
    ]
  );

  const desktopText = useMemo(() => {
    if (areAllPagesSelected) {
      return groupDuplicates ? (
        <>
          <strong>All</strong> Events except duplicates are selected
        </>
      ) : (
        <>
          All <strong>{selectedCount}</strong> Events are selected
        </>
      );
    }
    return (
      <>
        <strong>
          {selectedCount} of {rowCount}
        </strong>{" "}
        Events on this page are selected
      </>
    );
  }, [areAllPagesSelected, selectedCount, rowCount, groupDuplicates]);

  const desktopButton = useMemo(() => {
    if (areAllPagesSelected) {
      return (
        <Button
          variant="text"
          onClick={clearAll}
          sx={{
            padding: "4px 5px",
            fontWeight: 600,
          }}
        >
          Clear all
        </Button>
      );
    }
    return (
      <Button
        variant="text"
        onClick={() => setAllPagesSelected(true)}
        sx={{
          padding: "4px 5px",
          fontWeight: 600,
        }}
      >
        {groupDuplicates
          ? "Select all (except duplicates) from all pages"
          : `Select all ${rowCount}`}
      </Button>
    );
  }, [
    areAllPagesSelected,
    setAllPagesSelected,
    clearAll,
    rowCount,
    groupDuplicates,
  ]);

  const desktopContent = useMemo(
    () => (
      <Stack
        direction="row"
        height={48}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: alpha(palette.primary.main, 0.08),
          mx: 2,
          my: 2,
          borderRadius: "4px",
        }}
      >
        <Stack direction="row" alignItems="center">
          {allCheckboxSelector}
          <Typography
            variant="body2"
            sx={{
              mr: 2,
              ml: allCheckboxSelector ? -2 : 2,
            }}
          >
            {desktopText}
          </Typography>
          {desktopButton}
        </Stack>
        {!!deleteIconProps && deleteIconButton}
      </Stack>
    ),
    [
      allCheckboxSelector,
      palette.primary.main,
      deleteIconProps,
      deleteIconButton,
      desktopButton,
      desktopText,
    ]
  );

  return isMobile ? mobileContent : desktopContent;
};
