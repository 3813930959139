import { Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { ViewMode } from "../ViewModeSwitch/ViewModeSwitch.model";

export function ViewModeLayout({
  current,
  viewMap,
}: {
  current: ViewMode;
  viewMap: { [k in keyof typeof ViewMode]?: ReactNode };
}) {
  const { transitions } = useTheme();
  const viewKeys = Object.keys(viewMap) as (keyof typeof viewMap)[];
  const currentIndex = viewKeys.findIndex((key) => key === current);

  return (
    <Stack flex={1} position="relative" overflow="hidden">
      {viewKeys.map((viewKey, index) => {
        const content = viewMap[viewKey];
        const isCurrent = current === viewKey;
        const getCurrentLeft = () => {
          if (index < currentIndex) {
            return "-100%";
          }

          if (index > currentIndex) {
            return "+100%";
          }

          return 0;
        };

        return (
          <Stack
            id={`${viewKey}`}
            key={viewKey}
            overflow="hidden"
            children={content}
            position={isCurrent ? "relative" : "absolute"}
            left={getCurrentLeft()}
            width="100%"
            height="100%"
            sx={{
              // disable interactions for inactive elements
              tabIndex: isCurrent ? undefined : -1,
              pointerEvents: isCurrent ? "auto" : "none",
              transition: transitions.create(["left", "opacity"], {
                duration: transitions.duration.standard,
              }),
            }}
          />
        );
      })}
    </Stack>
  );
}
