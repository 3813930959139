import { useCallback, useMemo, useEffect, useState } from "react";
import {
  badgeClasses,
  Badge,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useBlocker, BlockerFunction } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTopNavBarMobile } from "src/components/AppTopNavBarMobile/AppTopNavBarMobile";
import { ReportCreateFormValues } from "src/components/ReportCreateForm/ReportCreateForm.model";
import { ReportCreateFormSchema } from "src/components/ReportCreateForm/ReportCreateForm.schema";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { PageLoader } from "src/components/PageLoader";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { LeaveConfirmationDialog } from "src/components/LeaveConfirmationDialog/LeaveConfirmationDialog";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { GlobalSearchDesktopButton } from "src/components/GlobalSearchDesktopButton/GlobalSearchDesktopButton";
import { useReportScratchPublish } from "src/api/useReportScratchPublish";
import { useReportScratchEvents } from "src/api/useReportScratchEvents";
import { useReportScratchEventsInfinite } from "src/api/useReportScratchEventsInfinite";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { useReportCreateFormValues } from "src/utils/useReportCreateFormValues";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
} from "src/components/SortingMenu/SortingMenu.model";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ReportCreateActionButtonsMobile } from "./components/ReportCreateActionButtonsMobile";
import { ReportCreateInfoTab } from "./components/ReportCreateInfoTab/ReportCreateInfoTab";
import { ReportCreateEventsTab } from "./components/ReportCreateEventsTab/ReportCreateEventsTab";
import { ReportPreviewTab } from "./components/ReportPreviewTab/ReportPreviewTab";
import { reportsRoute } from "../Reports/Reports.route";
import { ReportsPageTabMode } from "../Reports/Reports.const";

enum PageTabMode {
  info = "info",
  events = "events",
  preview = "preview",
}

const pageTabKey = "pageTab";

export function ReportCreatePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageTabMode, setPageTabMode] = useViewModeQueryParam<PageTabMode>({
    paramKey: pageTabKey,
    defaultValue: PageTabMode.info,
  });
  const [isAll] = useQueryParam("all", BooleanParam);
  const [dateRange, setDateRange] = useQueryDateRangeWithEmptyDefault();
  const [pagination, setPagination] = useQueryPagination();
  const [sortBy, setSortBy] = useState<SortByParameters>("default");
  const [sortOption, setSortOption] = useState(SortBy.defaultDesc);
  const [orderBy, setOrderBy] = useState<OrderByParameters>("desc");
  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewModeWrapper] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });
  const watchQuerySearchDialog = useGlobalSearchOpen();

  const {
    events: selectedEvents,
    selectEvents,
    areAllPagesSelected,
    setAllPagesSelected,
    excludeEvents,
  } = useEventsSelectedForReports();

  const reportScratchEventsWithoutDateFilter = useReportScratchEvents({
    request: {
      query: {
        from: 0,
        size: 1,
      },
      body: excludeEvents.map((event) => event.id),
    },
  });

  const reportScratchEvents = useReportScratchEvents({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    request: {
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
      },
      body: excludeEvents.map((event) => event.id),
    },
  });

  const reportScratchEventsTiles = useReportScratchEventsInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    request: {
      query: {
        sortby: sortBy,
        orderby: orderBy,
        startDate: dateRange[0]
          ? formatDateTimeWithoutTimeZone(dateRange[0])
          : undefined,
        endDate: dateRange[1]
          ? formatDateTimeWithoutTimeZone(dateRange[1])
          : undefined,
      },
      body: excludeEvents.map((event) => event.id),
    },
  });

  const preselectedEventsTotal = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportScratchEventsTiles.isError) return 0;
      const tilesPagesData = reportScratchEventsTiles.data?.pages;
      return tilesPagesData?.[tilesPagesData.length - 1]?.total || 0;
    }
    if (reportScratchEvents.isError) return 0;
    return reportScratchEvents.data?.total || 0;
  }, [
    viewMode,
    reportScratchEventsTiles.data?.pages,
    reportScratchEvents.data?.total,
    reportScratchEvents.isError,
    reportScratchEventsTiles.isError,
  ]);

  const selectedEventsLength =
    preselectedEventsTotal && areAllPagesSelected
      ? preselectedEventsTotal
      : selectedEvents.length ?? 0;

  const preselectedEventsTotalWithoutDateFilter =
    reportScratchEventsWithoutDateFilter.data?.total || 0;

  const selectedEventsWithoutDateFilterLength =
    preselectedEventsTotalWithoutDateFilter && areAllPagesSelected
      ? preselectedEventsTotalWithoutDateFilter
      : selectedEvents.length ?? 0;

  const eventsData = useMemo(() => {
    if (!areAllPagesSelected) {
      return selectedEvents;
    }
    if (viewMode === ViewMode.tile) {
      if (reportScratchEventsTiles.isError) return [];
      return combineInfiniteTilesData(reportScratchEventsTiles.data?.pages);
    }
    if (reportScratchEvents.isError) return [];
    return reportScratchEvents.data?.results || [];
  }, [
    areAllPagesSelected,
    selectedEvents,
    viewMode,
    reportScratchEvents.data,
    reportScratchEventsTiles.data,
    reportScratchEvents.isError,
    reportScratchEventsTiles.isError,
  ]);

  useEffect(() => {
    if (isAll) {
      setAllPagesSelected(true);
    }
  }, [isAll, setAllPagesSelected]);

  const addEvents = useCallback(
    (events: PowerSearchResultItem[]) => {
      selectEvents([...events, ...selectedEvents]);
    },
    [selectEvents, selectedEvents]
  );

  const defaultValues = useReportCreateFormValues();

  const formHook = useForm<ReportCreateFormValues>({
    mode: "all",
    resolver: joiResolver(ReportCreateFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, getValues } = formHook;

  const shouldBlockLeaving = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        !formState.isSubmitted &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [formState.isSubmitted]
  );
  const blocker = useBlocker(shouldBlockLeaving);

  const reportCreate = useReportScratchPublish({
    options: {
      onSuccess: () => {
        const url = reportsRoute.makeUrl(undefined, {
          pageTab: ReportsPageTabMode.reports,
        });
        navigate(url);
      },
    },
  });

  const eventsFilterParams = useMemo(() => {
    if (areAllPagesSelected && !excludeEvents.length) {
      return {};
    }
    if (areAllPagesSelected) {
      return {
        excludeFilter: excludeEvents.map((ev) => ev.id),
      };
    }
    return {
      includeFilter: selectedEvents.map((ev) => ev.id),
    };
  }, [areAllPagesSelected, excludeEvents, selectedEvents]);

  const saveReport = handleSubmit(
    ({ title, description }) => {
      if (preselectedEventsTotal) {
        const params = {
          title,
          description,
          ...eventsFilterParams,
        };
        reportCreate.mutate({
          ...params,
          isMobile,
        });
      }
    },
    (errors) => {
      console.log("@@ DEBUG:submitReportCreate:error", errors);
    }
  );

  const isBusy = formState.isSubmitting || reportCreate.isLoading;

  const handleChangePageTabMode = (newValue: string) => {
    setPageTabMode(newValue as PageTabMode);
  };

  const tabContent = useMemo(() => {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {pageTabMode === PageTabMode.events ? (
          <ReportCreateEventsTab
            data={eventsData}
            isLoading={
              reportScratchEvents.isLoading || reportScratchEvents.isFetching
            }
            isBusy={isBusy}
            addEvents={addEvents}
            rowCount={selectedEventsLength}
            rowCountWithoutDateFilter={selectedEventsWithoutDateFilterLength}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            sortOption={sortOption}
            setSortOption={setSortOption}
            setSortBy={setSortBy}
            setOrderBy={setOrderBy}
            dateRange={dateRange}
            setDateRange={setDateRange}
            viewMode={viewMode}
            setViewModeWrapper={setViewModeWrapper}
            reportEventsTiles={reportScratchEventsTiles}
          />
        ) : pageTabMode === PageTabMode.info ? (
          <ReportCreateInfoTab isBusy={isBusy} />
        ) : pageTabMode === PageTabMode.preview ? (
          <ReportPreviewTab
            title={getValues().title}
            description={getValues().description}
            events={eventsData}
            rowCount={selectedEventsLength}
            sortOption={sortOption}
            setSortOption={setSortOption}
            setSortBy={setSortBy}
            setOrderBy={setOrderBy}
            viewMode={viewMode}
            setViewModeWrapper={setViewModeWrapper}
            reportEventsTiles={reportScratchEventsTiles}
          />
        ) : null}
      </Paper>
    );
  }, [
    pageTabMode,
    isBusy,
    eventsData,
    selectedEventsLength,
    selectedEventsWithoutDateFilterLength,
    reportScratchEvents.isLoading,
    reportScratchEvents.isFetching,
    addEvents,
    getValues,
    pagination,
    setPagination,
    sortOption,
    dateRange,
    setDateRange,
    viewMode,
    setViewModeWrapper,
    reportScratchEventsTiles,
  ]);

  const reportTabs = (
    <AppTabLayout
      variant={isMobile ? "fullWidth" : "scrollable"}
      height="100%"
      currentTab={pageTabMode}
      onCurrentTabChange={handleChangePageTabMode}
    >
      <AppTab
        label={isMobile ? "Info" : "General Info"}
        value={PageTabMode.info}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
      <AppTab
        label={
          <Badge
            badgeContent={selectedEventsLength}
            color="primary"
            sx={{
              [`.${badgeClasses.badge}`]: {
                top: 10,
                right: 4,
              },
            }}
          >
            <Typography sx={{ pr: 3.5, fontSize: 14, fontWeight: 500 }}>
              {isMobile ? "Events" : "Report Events"}
            </Typography>
          </Badge>
        }
        value={PageTabMode.events}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
      <AppTab
        label="Preview"
        value={PageTabMode.preview}
        children={tabContent}
        sx={{ px: 1, width: "180px" }}
      />
    </AppTabLayout>
  );

  const headerDesktop = useMemo(
    () => (
      <PageHeaderDesktop
        title="New report"
        hideBackButton
        toolbar={
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ sm: 1, md: 2 }}
            ml="auto"
          >
            <GlobalSearchDesktopButton />
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: 160, height: 42 }}
              onClick={() => navigate(reportsRoute.path)}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ width: 160, height: 42 }}
              disabled={!formState.isValid || !selectedEventsLength}
              loading={isBusy}
              onClick={saveReport}
            >
              Save report
            </LoadingButton>
          </Stack>
        }
        breadcrumbLabels={{
          "1": "Reports",
          "0": "New Report",
        }}
      />
    ),
    [formState.isValid, isBusy, navigate, saveReport, selectedEventsLength]
  );

  const mobileContent = (
    <>
      <Stack height="100%" sx={{ overflowY: "auto" }}>
        <Stack direction="row" justifyContent="space-between" pr={2}>
          <AppTopNavBarMobile title="Create Report" />
        </Stack>
        {reportTabs}
      </Stack>
      {!!selectedEventsLength && (
        <ReportCreateActionButtonsMobile
          disabled={!formState.isValid || !selectedEventsLength}
          isLoading={isBusy}
          saveReport={saveReport}
        />
      )}
    </>
  );

  if (reportScratchEvents.isLoading) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formHook}>
      {isMobile ? (
        <PageLayoutMobile content={mobileContent} />
      ) : (
        <PageLayoutDesktop
          header={headerDesktop}
          content={reportTabs}
          overflow="hidden"
        />
      )}

      {blocker && (
        <LeaveConfirmationDialog
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset?.()}
          onConfirm={() => blocker.proceed?.()}
        />
      )}
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
    </FormProvider>
  );
}
