import { useMemo } from "react";
import { format } from "date-fns";
import { DateRange } from "../components/DateSelector/DateSelector";

export const useReportCreateFormValues = (dateRange?: DateRange) => {
  return useMemo(() => {
    let title = `Report from ${format(new Date(), "MMMM do y")}`;

    if (dateRange?.[0] && dateRange?.[1]) {
      const startDate = format(dateRange[0], "MMMM do y");
      const endDate = format(dateRange[1], "MMMM do y");
      title = `Report from ${startDate} - ${endDate}`;
    }

    return {
      title,
      description: "",
    };
  }, [dateRange]);
};
