import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { WatchQueryFilterFormValues } from "./WatchQueryFilterForm.model";
import { WatchQueryFilterFormSchema } from "./WatchQueryFilterForm.schema";

export const makeEmptyWatchQueryFilterFormValues = (
  merge?: Partial<WatchQueryFilterFormValues>
): WatchQueryFilterFormValues => ({
  // keyword
  termsInclude: {
    list: [],
    logic: "or",
  },
  termsExclude: {
    list: [],
    logic: "or",
  },
  // keyword

  // sources
  sourcesInclude: {
    list: merge?.sourcesInclude?.list || [],
    logic: merge?.sourcesInclude?.logic || "or",
  },
  // sources

  advertisement: merge?.advertisement || queryAdvertisementValues.all,

  // program
  programInclude: {
    list: merge?.programInclude?.list || [],
    logic: merge?.programInclude?.logic || "or",
  },
  programExclude: {
    list: merge?.programExclude?.list || [],
    logic: merge?.programExclude?.logic || "or",
  },
  // program
});

type UseWatchQueryFilterFormArgs = {
  initial?: Partial<WatchQueryFilterFormValues>;
};

export function useWatchQueryFilterForm(params?: UseWatchQueryFilterFormArgs) {
  const { initial } = params || {};
  const emptyFormValues = makeEmptyWatchQueryFilterFormValues();
  const formHook = useForm<WatchQueryFilterFormValues>({
    mode: "all",
    resolver: joiResolver(WatchQueryFilterFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}
