import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { LngLat, Map, MapEventOf, MapMouseEvent, Point } from "mapbox-gl";
import { Box, useTheme } from "@mui/material";
import { AppPopper } from "src/components/AppPopper/AppPopper";
import { AppPopperArrowSize } from "src/components/AppPopper/components/AppPopperArrow/AppPopperArrow.model";

/**
 * This is AppPopper for usage with MapBox only and it anchors to map coordinates.
 * The map to screen coordinate transformation uses virtual element and relative positioning,
 * so the parent must also have position="relative" or another compatible position property.
 */
export function MapBoxPopper({
  map,
  lngLat,
  onClose,
  open,
  children,
}: PropsWithChildren<{
  map: Map | null;
  open: boolean;
  lngLat?: LngLat;
  onClose?: () => void;
}>) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<Point | null>(null);

  // Combined handler for all relevant events.
  // May be split into separate in future.
  const updatePopperPosition = useCallback(
    (e: MapEventOf<"move" | "zoom"> | MapMouseEvent) => {
      if (!lngLat) {
        setAnchorPosition(null);
        return;
      }

      const anchorPosition = e.target.project(lngLat);
      setAnchorPosition(anchorPosition);
    },
    [lngLat]
  );

  useEffect(() => {
    map?.on("move", updatePopperPosition);
    map?.on("zoom", updatePopperPosition);
    map?.on("mousemove", updatePopperPosition);

    return () => {
      map?.off("move", updatePopperPosition);
      map?.off("zoom", updatePopperPosition);
      map?.off("mousemove", updatePopperPosition);
    };
  }, [map, lngLat, updatePopperPosition]);

  return (
    <Fragment>
      {anchorPosition && (
        // this element is used to provide an anchor to the popper since
        // underlying popper does not accept screen coordinates directly
        <Box
          ref={setAnchorEl}
          position="absolute"
          left={anchorPosition?.x}
          top={anchorPosition?.y}
          visibility="hidden"
          borderRadius={"50%"}
          bgcolor={palette.primary.main}
          sx={{
            pointerEvents: "none",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}

      {anchorEl && (
        <AppPopper
          arrow={AppPopperArrowSize.small}
          disablePortal // use disablePortal to clip AppPopper inside map container
          anchorEl={anchorEl}
          placement="top"
          open={open}
          onClose={() => {
            setAnchorEl(null);
            setAnchorPosition(null);
            onClose?.();
          }}
          children={children}
        />
      )}
    </Fragment>
  );
}
