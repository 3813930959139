import {
  Button,
  Chip,
  DialogActions,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import {
  TagPickerProps,
  TagPickerValue,
} from "src/components/TagPicker/TagPicker.model";

interface ModalBottomSectionProps<T> {
  tags: TagPickerValue<T>;
  changePresets: (value: TagPickerValue<T>["list"]) => void;
  resetChange: () => void;
  menuState: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
  onChange: TagPickerProps<T>["onChange"];
  presets: TagPickerProps<T>["presets"];
}

export function ModalBottomSection<T>({
  tags,
  changePresets,
  resetChange,
  menuState,
  onChange,
  presets,
}: ModalBottomSectionProps<T>) {
  const { palette } = useTheme();

  const presetElements =
    presets &&
    Object.entries(presets).map(([k, v]) => {
      const key = `${k}:${JSON.stringify(v)}`;
      return (
        <Chip
          label={k}
          key={key}
          variant="outlined"
          onClick={(_e) => {
            changePresets(v);
          }}
          size="medium"
        />
      );
    });

  return (
    <DialogActions sx={{ px: 2, pt: 1, width: "100%" }}>
      <Stack width="100%">
        {presetElements && presetElements.length !== 0 && (
          <ScrollBox direction="row">
            <Stack
              flexDirection="row"
              gap={1}
              width="100%"
              height={32}
              alignItems="center"
            >
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ textWrap: "nowrap" }}
              >
                Most common:
              </Typography>

              {presetElements}
            </Stack>
          </ScrollBox>
        )}

        <Stack
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
          mt={1.5}
        >
          <Button
            variant="outlined"
            sx={{
              flex: 1,
              color: palette.text.primary,
              borderColor: palette.text.primary,
            }}
            onClick={() => {
              resetChange();
              menuState.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ flex: 1 }}
            onClick={() => {
              onChange(tags);
              menuState.hide();
            }}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </DialogActions>
  );
}
