import { Fragment, useCallback, useMemo } from "react";
import { DataGridPremiumProps, GridRowParams } from "@mui/x-data-grid-premium";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { UseInfiniteQueryResult } from "react-query";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { ReportItem } from "src/models/Report";
import { useReportsSelection } from "src/api/useReportsSelection";
import { getReportStatus } from "src/api/useReport.getters";
import { ReportsTiles } from "./ReportsTiles/ReportsTiles";
import { ReportsList } from "./ReportsList/ReportsList";
import { ReportsTable } from "./ReportsTable/ReportsTable";

export function useReportsDataView({
  loading,
  filterData,
  viewMode,
  isMobile,
  isExpandedTextMode,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  renderHeader,
  reportsListTiles,
}: {
  loading?: boolean;
  filterData: ReportItem[];
  viewMode: ViewMode;
  isMobile?: boolean;
  isExpandedTextMode?: boolean | null;
  rowCount?: number;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  renderHeader: () => JSX.Element;
  reportsListTiles: UseInfiniteQueryResult;
}) {
  const reportsSelection = useReportsSelection();

  const onRowSelectionModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      const selectedReports = filterData.filter((report) =>
        rowSelectionModel.includes(report.id)
      );
      reportsSelection.selectAll(selectedReports);
    },
    [filterData, reportsSelection]
  );

  const selectedReportsIds = useMemo(
    () => reportsSelection.list.map((report: ReportItem) => report.id),
    [reportsSelection.list]
  );

  return useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <Fragment>
              {renderHeader()}
              <ReportsTable
                id="reports-table"
                rows={filterData}
                rowCount={rowCount}
                loading={loading}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  getReportStatus(params.row) === "ready"
                }
                onRowSelectionModelChange={onRowSelectionModelChange}
                rowSelectionModel={selectedReportsIds}
              />
            </Fragment>
          ),
          list: (
            <Fragment>
              {renderHeader()}
              <ReportsList
                id="reports-list"
                rows={filterData}
                rowCount={rowCount}
                loading={loading}
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
              />
            </Fragment>
          ),
          tile: (
            <Fragment>
              {isMobile
                ? null // tiles have their own dynamic header
                : renderHeader()}
              <ReportsTiles
                id="reports-tiles"
                filterData={filterData}
                lineClamp={isMobile && isExpandedTextMode ? 5 : 2}
                renderHeader={isMobile ? renderHeader : undefined}
                reportsListTiles={reportsListTiles}
              />
            </Fragment>
          ),
        }}
      />
    );
  }, [
    viewMode,
    filterData,
    rowCount,
    loading,
    paginationModel,
    onPaginationModelChange,
    onRowSelectionModelChange,
    selectedReportsIds,
    isMobile,
    isExpandedTextMode,
    renderHeader,
    reportsListTiles,
  ]);
}
