import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  dialogClasses,
  useMediaQuery,
  useTheme,
  paperClasses,
  rgbToHex,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Close } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { useProfileImageUpdate } from "src/api/useProfileImageUpdate";
import { useBannerCreate } from "src/api/useBannerCreate";
import { useBanner } from "src/api/useBanner";
import { useSessionContext } from "src/api/useSessionContext";
import { useAccountBanner } from "src/api/useAccountBanner";
import { useUserManagementProfileImageUpdate } from "src/api/useUserManagementProfileImageUpdate";
import { useAccountBannerCreate } from "src/api/useAccountBannerCreate";
import { DialogBase } from "../DialogBase/DialogBase";
import { UploadImageEditor } from "../UploadImageEditor/UploadImageEditor";
import { DialogModes, UploadImageDialogProps } from "./UploadImageDialog.model";
import { LoadingButton } from "../buttons/LoadingButton";
import { InfoPopover } from "../InfoPopover/InfoPopover";
import { ScrollBox } from "../ScrollBox/ScrollBox";
import {
  BannerFormValues,
  BannerFormValuesSchema,
} from "../UploadImageEditor/UploadImageEditor.model";

export function UploadImageDialog({
  mode = DialogModes.avatar,
  page = "settings",
  selectedAvatar,
  setSelectedAvatar,
  onClose,
  ...props
}: UploadImageDialogProps) {
  const [preSelectedImage, setPreselectedImage] = useState<
    string | Blob | null | undefined
  >(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const { palette, breakpoints } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { accountId = "", userId: managementUser = "" } = useParams();
  const { userId } = useSessionContext();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const { data } = useBanner({
    request: {
      params: {
        path: {
          userId: (page === "user" && managementUser) || userId,
        },
      },
    },
  });

  const userManagementProfileImageUpdate = useUserManagementProfileImageUpdate({
    request: {
      params: {
        path: {
          id: managementUser,
        },
      },
    },
    options: {
      onSuccess: () => {
        setSelectedAvatar?.(selectedAvatar);
        setPreselectedImage(null);
        onClose();
      },
    },
  });

  const { data: accountData } = useAccountBanner({
    request: {
      params: {
        path: {
          accountId: accountId,
        },
      },
    },
    options: {},
  });

  const tabData = page === "account" ? accountData : data;

  const formHook = useForm<BannerFormValues>({
    mode: "all",
    resolver: joiResolver(BannerFormValuesSchema),
    defaultValues: {
      title: tabData?.title || "",
      subtitle: tabData?.subTitle || "",
      bgcolor: palette.background.default,
    },
  });
  const { formState, handleSubmit, reset, setValue } = formHook;
  const isBusy = formState.isSubmitting;

  useEffect(() => {
    if (tabData?.bgColor) setValue("bgcolor", tabData?.bgColor);
    if (tabData?.title) setValue("title", tabData?.title);
    if (tabData?.subTitle) setValue("subtitle", tabData?.subTitle);
  }, [data, setValue, accountData, tabData]);

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleClosePopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const profileImageUpdate = useProfileImageUpdate({
    options: {
      onSuccess: () => {
        setSelectedAvatar?.(selectedAvatar);
        setPreselectedImage(null);
        onClose();
      },
    },
  });

  const bannerCreate = useBannerCreate({
    options: {
      onSuccess: () => {
        reset({
          title: "",
          subtitle: "",
          bgcolor: tabData?.bgColor || palette.background.default,
        });
        setPreselectedImage(null);
        onClose();
      },
    },
  });

  const accountBannerCreate = useAccountBannerCreate({
    options: {
      onSuccess: () => {
        reset({
          title: tabData?.title || "",
          subtitle: tabData?.subTitle || "",
          bgcolor: tabData?.bgColor || palette.background.default,
        });
        setPreselectedImage(null);
        onClose();
      },
    },
  });

  const resetChanges = () => {
    setScale(1);
    setRotation(0);
    if (mode === DialogModes.banner) {
      reset({
        title: tabData?.title || "",
        subtitle: tabData?.subTitle || "",
        bgcolor: tabData?.bgColor || palette.background.default,
      });
    }
  };

  const handleAvatarChange = () => {
    if (preSelectedImage) {
      const formData = new FormData();
      formData.append("profileImage", preSelectedImage);
      if (managementUser) {
        userManagementProfileImageUpdate.mutate(formData);
      } else {
        profileImageUpdate.mutate(formData);
      }
    }
  };

  const makeBannerRequestBody = (
    data: BannerFormValues,
    hexColor: string,
    formData: FormData,
    id: string
  ) => {
    return {
      query: {
        title: data.title,
        subtitle: data.subtitle,
        bgcolor: data.bgcolor,
        color: hexColor,
      },
      bannerImage: formData,
      params: {
        path: {
          userId: id,
        },
      },
    };
  };

  const onSubmit = (data: BannerFormValues) => {
    if (preSelectedImage) {
      const formData = new FormData();
      formData.append("bannerImage", preSelectedImage);
      const contrastColor = palette.getContrastText(data.bgcolor);
      const hexColor = contrastColor.includes("rgb")
        ? rgbToHex(contrastColor)
        : contrastColor;
      if (page === "account") {
        accountBannerCreate.mutate({
          query: {
            title: data.title,
            subtitle: data.subtitle,
            bgcolor: data.bgcolor,
            color: hexColor,
          },
          bannerImage: formData,
          path: {
            accountId,
          },
        });
      } else if (page === "user") {
        const requestBody = makeBannerRequestBody(
          data,
          hexColor,
          formData,
          managementUser
        );
        bannerCreate.mutate(requestBody);
      } else {
        const requestBody = makeBannerRequestBody(
          data,
          hexColor,
          formData,
          userId
        );
        bannerCreate.mutate(requestBody);
      }
    }
  };

  const onDialogClose = () => {
    setPreselectedImage(null);
    onClose();
  };

  const mobileDialogHeader = (
    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
      <Stack direction={"row"} alignItems={"center"} gap={5}>
        <IconButton
          edge="end"
          onClick={onClose}
          sx={{ width: "14px", height: "14px" }}
        >
          <Close />
        </IconButton>
        <Typography variant="subtitle1">{props.title}</Typography>
      </Stack>
      {preSelectedImage && (
        <LoadingButton
          sx={{
            display: { md: "flex" },
            justifyContent: "right",
          }}
          onClick={
            mode === DialogModes.banner
              ? handleSubmit(onSubmit)
              : handleAvatarChange
          }
          disabled={
            mode === DialogModes.banner ? isBusy || !formState.isValid : false
          }
          variant="text"
          loading={profileImageUpdate.isLoading || isBusy}
        >
          Crop and Save
        </LoadingButton>
      )}
    </Stack>
  );

  const mobileContent = (
    <Dialog
      fullScreen
      {...props}
      sx={{
        [`.${dialogClasses.container}`]: {
          paddingTop: "51px",
          [`.${paperClasses.root}`]: {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          },
        },
      }}
    >
      <FormProvider {...formHook}>
        <DialogTitle sx={{ py: 1.5, px: 2 }}>{mobileDialogHeader}</DialogTitle>
        <ScrollBox direction="column">
          <DialogContent sx={{ px: 2, pt: 0 }}>
            <UploadImageEditor
              mode={mode}
              preSelectedImage={preSelectedImage}
              setPreselectedImage={setPreselectedImage}
              setScale={setScale}
              scale={scale}
              setRotation={setRotation}
              rotation={rotation}
              resetChanges={resetChanges}
            />
          </DialogContent>
        </ScrollBox>
      </FormProvider>
    </Dialog>
  );

  const desktopContent = (
    <FormProvider {...formHook}>
      <DialogBase
        onClose={onDialogClose}
        sx={{
          [`.${paperClasses.root}`]: {
            width: 1004,
          },
        }}
        {...props}
        children={
          <UploadImageEditor
            mode={mode}
            preSelectedImage={preSelectedImage}
            setPreselectedImage={setPreselectedImage}
            setScale={setScale}
            scale={scale}
            setRotation={setRotation}
            rotation={rotation}
          />
        }
        footer={
          <Stack
            direction="row"
            width={"100%"}
            spacing={2}
            minHeight={42}
            sx={{ justifyContent: "space-between" }}
          >
            <Stack direction={"row"} alignItems="center">
              <Button
                onClick={handleHelpClick}
                sx={{ minWidth: "35px", position: "relative" }}
              >
                <InfoIcon sx={{ fill: "rgb(176, 190, 197)" }} />
                <InfoPopover
                  id={open ? "image-uploader" : undefined}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  open={open}
                  handleClosePopover={handleClosePopover}
                  title="How it works?"
                >
                  <Stack
                    py={2}
                    px={3}
                    sx={{ background: palette.background.default }}
                  >
                    <Typography variant="body2">
                      With this feature, you will be able to upload your
                      {mode === DialogModes.banner ? " logo " : " avatar "}
                      to the system. Also you can rotate and zoom it.
                    </Typography>

                    {mode === DialogModes.avatar ? (
                      <Typography variant="body2" py={2.5}>
                        Using your photo it will be easier to find you in the
                        list of users.
                      </Typography>
                    ) : (
                      <hr />
                    )}
                    <Typography variant="body2">
                      Requirements: png, jpg or jpeg and with max size of 3.1
                      MB.
                    </Typography>
                  </Stack>
                </InfoPopover>
              </Button>
              <Typography
                variant="body1"
                sx={{
                  color: palette.text.secondary,
                }}
              >
                Click here for help
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              width={"50%"}
              justifyContent={"end"}
              gap={2}
            >
              <Button
                sx={{ width: 84, display: { md: "flex" } }}
                onClick={onClose}
                variant="text"
                color={"error"}
              >
                Cancel
              </Button>
              {preSelectedImage && (
                <>
                  <Button
                    sx={{ display: { md: "flex" } }}
                    onClick={resetChanges}
                    variant="text"
                    disabled={
                      !formState.isDirty && scale === 1 && rotation === 0
                    }
                  >
                    Reset changes
                  </Button>
                  <LoadingButton
                    sx={{ display: { md: "flex" }, width: "180px" }}
                    onClick={
                      mode === DialogModes.banner
                        ? handleSubmit(onSubmit)
                        : handleAvatarChange
                    }
                    variant="contained"
                    disabled={
                      mode === DialogModes.banner
                        ? isBusy || !formState.isValid
                        : false
                    }
                    loading={
                      profileImageUpdate.isLoading ||
                      userManagementProfileImageUpdate.isLoading ||
                      isBusy
                    }
                  >
                    Crop and Save
                  </LoadingButton>
                </>
              )}
            </Stack>
          </Stack>
        }
      />
    </FormProvider>
  );

  return isMobile ? mobileContent : desktopContent;
}
