import { Fragment } from "react";
import { Snackbar, Alert, alertClasses } from "@mui/material";
import { GlobalSearchDialog } from "src/components/GlobalSearchDialog/GlobalSearchDialog";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";

export function DateTimeSearchCommon() {
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();

  return (
    <Fragment>
      <GlobalSearchDialog
        open={watchQuerySearchDialog.isOpen}
        onClose={watchQuerySearchDialog.hide}
      />
      <Snackbar
        open={shareLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={shareLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={shareLinkSnackBar.hide}
          sx={{
            borderRadius: "4px",
            alignItems: "center",
            [`.${alertClasses.action}`]: {
              pt: 0,
            },
          }}
        >
          The link to the Event was successfully copied to the clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={shareFormSnackBar.isOpen}
        onClose={shareFormSnackBar.hide}
        title="Your share is being processed."
        subtitle="You and your recipients will get an email."
      />
    </Fragment>
  );
}
