import { Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { NameValuePair } from "src/models/NameValuePair";
import { QueryDefinition } from "src/models/QueryDefinition";
import { getEventSourceIcon } from "src/utils/getEventSourceIcon";
import { SourceBlockType } from "./WatchQueryExplanation";

const renderSourceItem = (
  item: NameValuePair,
  i: number,
  all: NameValuePair[]
) => {
  const Icon = getEventSourceIcon(item.value);

  return (
    <Stack
      key={item.value}
      direction="row"
      flexWrap="nowrap"
      columnGap={0.25}
      alignItems="center"
    >
      {Icon ? <Icon fontSize="small" /> : null}
      <Typography variant="subtitle2" noWrap>
        {item.name}
      </Typography>
    </Stack>
  );
};

export const renderSourceBlocks = (
  type: SourceBlockType,
  queryDefinition?: QueryDefinition | null
) => {
  const { sourceFilter } = queryDefinition || {};
  const includeExcludeCountries = sourceFilter?.countries?.[type] || [];
  const includeExcludeMarkets = sourceFilter?.markets?.[type] || [];
  const includeExcludeState = sourceFilter?.state?.[type] || [];
  const includeExcludeStations = sourceFilter?.stations?.[type] || [];
  const includeExcludeContentType = sourceFilter?.contentType?.[type] || [];

  const blocks = [
    ...includeExcludeCountries.map(renderSourceItem),
    ...includeExcludeMarkets.map(renderSourceItem),
    ...includeExcludeState.map(renderSourceItem),
    ...includeExcludeStations.map(renderSourceItem),
    ...includeExcludeContentType.map(renderSourceItem),
  ].map((el, i, all) => {
    if (i < all.length - 1) {
      return (
        <Fragment>
          {el}
          <Typography variant="subtitle2">,&nbsp;</Typography>
        </Fragment>
      );
    }

    return el;
  });

  return blocks.length ? blocks : null;
};

export function WatchQueryExplanationMobile({
  queryDefinition,
}: {
  queryDefinition?: QueryDefinition | null;
}) {
  const { keywordQuery, sourceFilter } = queryDefinition || {};
  const lColumnMin = 140;
  const sourcesBlocksInclude = renderSourceBlocks(
    SourceBlockType.include,
    queryDefinition
  ) || <Typography variant="subtitle2">All</Typography>;
  const sourcesBlocksExclude = renderSourceBlocks(
    SourceBlockType.exclude,
    queryDefinition
  );

  return (
    <Stack gap={2} py={2}>
      <Stack columnGap={{ xs: 1, sm: 2 }}>
        <Typography minWidth={lColumnMin} variant="body2">
          Keyword Include:
        </Typography>
        <Typography
          variant="subtitle2"
          children={keywordQuery?.include?.join(", ")}
        />
      </Stack>

      {keywordQuery?.exclude?.length ? (
        <Stack columnGap={{ xs: 1, sm: 2 }}>
          <Typography minWidth={lColumnMin} variant="body2">
            Keyword Exclude:
          </Typography>
          <Typography
            variant="subtitle2"
            children={keywordQuery?.exclude?.join(", ")}
          />
        </Stack>
      ) : null}

      {sourcesBlocksInclude && (
        <Stack columnGap={{ xs: 1, sm: 2 }}>
          <Typography minWidth={lColumnMin} variant="body2">
            Sources Include:
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            // columnGap={1}
            rowGap={1}
            children={sourcesBlocksInclude}
          />
        </Stack>
      )}

      {sourcesBlocksExclude && (
        <Stack columnGap={{ xs: 1, sm: 2 }}>
          <Typography minWidth={lColumnMin} variant="body2">
            Sources Exclude:
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            // columnGap={1}
            rowGap={1}
            children={sourcesBlocksExclude}
          />
        </Stack>
      )}

      <Stack columnGap={{ xs: 1, sm: 2 }}>
        <Typography minWidth={lColumnMin} variant="body2">
          Advertisements:
        </Typography>
        <Typography
          variant="subtitle2"
          children={sourceFilter?.adverts ?? "All"}
        />
      </Stack>
    </Stack>
  );
}
