import { ComponentType, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  EntitlementKey,
  Entitlements,
  useEntitlement,
} from "src/api/useEntitlement";

const defaultAllow = (entitlement?: Entitlements[EntitlementKey]) => {
  return !!entitlement?.value;
};

/**
 * Checks for specific entitlements to be enabled for the user session.
 */
export function EntitlementGuard({
  component: Component,
  entitlementKey,
  allow = defaultAllow,
  redirectTo = "/",
  silent,
}: {
  component: ComponentType;
  entitlementKey: EntitlementKey;
  /** Customize function that returns true if the feature is enabled or false otherwise */
  allow?: (entitlement: Entitlements[EntitlementKey]) => boolean;
  redirectTo?: string;
  silent?: boolean;
}) {
  const navigate = useNavigate();
  const entitlement = useEntitlement(entitlementKey);
  const isEnabled = allow(entitlement);

  useEffect(() => {
    if (!isEnabled && redirectTo && silent) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo, isEnabled, silent]);

  if (isEnabled) {
    return <Component />;
  }

  if (silent) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle>Entitlement Guard</DialogTitle>
      <DialogContent>
        <Box
          m="auto"
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="subtitle1">
            This feature requires following entitlement:
          </Typography>
          <Typography mb={4}>{entitlement?.displayName}</Typography>
          {process.env.NODE_ENV === "development" && (
            <Typography variant="body2">
              Check the entitlement in the session object or custom comparator
              function, if provided.
            </Typography>
          )}
          <Button onClick={() => navigate(redirectTo)}>OK</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
