import { Chip, ChipProps, useTheme } from "@mui/material";
import { add, format } from "date-fns";
import { useDateTimeSearchPlayState } from "src/pages/DateTimeSearchResults/components/DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";

export function ClipViewerTimeBadge({
  baseTime,
  ...props
}: Omit<ChipProps, "label"> & {
  baseTime?: string;
}) {
  const { palette } = useTheme();
  const { getActiveInstanceId, getInstanceTime } = useDateTimeSearchPlayState();
  const activeInstanceId = getActiveInstanceId();
  const offset = getInstanceTime(activeInstanceId);

  const currentDate = baseTime
    ? add(new Date(baseTime), { seconds: offset })
    : null;

  const label = currentDate ? format(currentDate, "pp") : "-";

  return (
    <Chip
      label={label}
      size="small"
      {...props}
      sx={{
        minWidth: 80,
        width: "fit-content",
        bgcolor: palette.background.paper,
        ...props.sx,
      }}
    />
  );
}
