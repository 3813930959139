import { STATUS_CONFIG } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.const";
import { StatusConfig } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.model";

export function getConfigurationKey(
  isActive?: boolean,
  isTrial?: boolean,
  isPaying?: boolean
): keyof StatusConfig {
  for (const [key, value] of Object.entries(STATUS_CONFIG)) {
    if (
      value.isTrial === isTrial &&
      value.isActive === isActive &&
      value.isPaying === isPaying
    ) {
      return key as keyof StatusConfig;
    }
  }
  return "Disabled Account";
}
