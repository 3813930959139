import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const ReportsIconSmall = (
  props: SvgIconProps & { isDisabled: boolean }
) => {
  const { palette } = useTheme();
  const iconColor = props.isDisabled
    ? palette.text.disabled
    : palette.text.primary;
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33333 6.17184L5.94928 6.17492C5.15217 6.17492 4.5 6.82709 4.5 7.62419V19.2184C4.5 20.0155 5.14493 20.6677 5.94203 20.6677H14.6449C15.442 20.6677 16.0942 20.0155 16.0942 19.2184V19.168L9.7527 19.168C8.85272 19.168 8.2291 18.8409 7.83763 18.3843C7.46291 17.9473 7.33333 17.4295 7.33333 17.075V6.17184Z"
          fill={iconColor}
        />
        <path
          d="M16.0942 18.168V18.1608H9.78283C8.98573 18.1608 8.3408 17.5086 8.3408 16.7115V6.1696L8.33333 6.16961V17.075C8.33333 17.2203 8.39569 17.4989 8.59678 17.7334C8.78113 17.9484 9.11719 18.168 9.7527 18.168L16.0942 18.168Z"
          fill={iconColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.79009 3.66797H15.5872L19.935 8.0158V16.7114C19.935 17.5086 19.2828 18.1607 18.4857 18.1607L9.78283 18.1608C8.98573 18.1608 8.3408 17.5086 8.3408 16.7115L8.34082 5.11724C8.34082 4.32014 8.99299 3.66797 9.79009 3.66797ZM14.8626 4.75493V8.74043H18.8481L14.8626 4.75493ZM14.8624 10.1898H11.2392V11.2043H14.8624V10.1898ZM11.2392 12.0013H14.8624V13.0158H11.2392V12.0013ZM13.4129 15.0993L15.2581 16.7115L18.4854 13.8916L17.5661 13.0883L15.2515 15.0993L14.3322 14.296L13.4129 15.0993Z"
          fill={iconColor}
        />
      </svg>
    </SvgIcon>
  );
};
