import { differenceInMilliseconds } from "date-fns";

export function makeOffsetRange({
  baseDate,
  trimRange,
}: {
  baseDate?: string;
  trimRange?: [Date | null, Date | null];
}): [number, number] {
  const boundDate0 = baseDate ? new Date(baseDate) : null;

  const trimDate0 = trimRange?.[0] ? new Date(trimRange[0]) : null;
  const trimDate1 = trimRange?.[1] ? new Date(trimRange[1]) : null;

  const offsetT0 =
    trimDate0 && boundDate0
      ? differenceInMilliseconds(trimDate0, boundDate0)
      : 0;

  const offsetT1 =
    trimDate1 && boundDate0
      ? differenceInMilliseconds(trimDate1, boundDate0)
      : 0;

  return [offsetT0, offsetT1];
}
