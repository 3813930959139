import { SourcesPickerValue } from "src/components/SourcesPicker/SourcesPicker.model";
import { create } from "zustand";

type UseSnapshotQueryFilterState = {
  sources: SourcesPickerValue;
  update: (changes: Partial<SourcesPickerValue>) => void;
};

const emptySnapshotQueryFilter = {
  list: [],
  logic: "or",
};

const stateHook = create<UseSnapshotQueryFilterState>((set, get) => {
  return {
    sources: emptySnapshotQueryFilter,
    update: (changes) => {
      const { sources } = get();
      set({ sources: { ...sources, ...changes } });
    },
  };
});

export function useSnapshotQueryFilter(): [
  SourcesPickerValue,
  (changes: SourcesPickerValue) => void
] {
  const state = stateHook();
  const { sources, update } = state;

  return [sources, update];
}
