export const mockSnapshot = {
  thumbnails: [
    "/api/thumbnail/broadcast/WFMZ?stationdatetime=10%2F02%2F2023%2001%3A28%3A36",
  ],
  startDateTime: "2023-10-02T01:25:56.95",
  eventType: "BroadcastTaggedTranscriptEvent",
};

export const mockStapshots = Array.from({ length: 20 }, (_, index) => {
  return { ...mockSnapshot, id: index, title: `CNN ${index}` };
});

export const mockSnapshotsData = [
  { id: 1, group: "U.S Cable", snapshots: mockStapshots },
  { id: 2, group: "NY, New York", snapshots: mockStapshots },
];

export const MOST_COMMON_PRESETS = {
  "Top markets": [
    {
      name: "U.S. Cable",
      value: "1",
      group: "MarketID",
    },
    { name: "U.K. National", value: "2", group: "MarketID" },
  ],
  "Top stations": [{ name: "CNN", value: "CNN", group: "StationGUID" }],
};
