import axios from "axios";
import { utcToZonedTime } from "date-fns-tz";
import { add } from "date-fns";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { useSessionContext } from "./useSessionContext";

const apiPath: keyof paths = "/api/broadcast/transcript";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type ResponseBody = string;

type UseTranscriptDownload = {
  stationId: string;
  dateTime: Date;
  rangeBounds: [number, number];
  timeZone?: string;
};

const transcriptDownloadQueryKey = "transcriptDownloadQueryKey";

export function useTranscriptDownload() {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  return useMutation<void, unknown, UseTranscriptDownload>({
    mutationKey: [transcriptDownloadQueryKey, userId],
    mutationFn: async (params: UseTranscriptDownload) => {
      const token = await getAccessTokenSilently();

      const startDateTime = add(
        params.timeZone
          ? utcToZonedTime(params.dateTime, params.timeZone)
          : new Date(params.dateTime),
        { minutes: params.rangeBounds[0] }
      );

      const endDateTime = add(
        params.timeZone
          ? utcToZonedTime(params.dateTime, params.timeZone)
          : new Date(),
        { minutes: params.rangeBounds[1] }
      );

      const parameters: Parameters = {
        query: {
          stationUUID: params.stationId,
          startDateTime: formatDateTimeWithoutTimeZone(startDateTime),
          endDateTime: formatDateTimeWithoutTimeZone(endDateTime),
          format: "text",
        },
      };

      const url = makeApiUrl<Parameters>(apiPath, parameters);
      const { data } = await axios<ResponseBody>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fileName = `transcript-${params.stationId}.txt`;
      const blob = new Blob([data]);
      const file = new File([blob], fileName);
      const href = URL.createObjectURL(file);
      const aElement = document.createElement("a");

      aElement.setAttribute("download", fileName);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();

      URL.revokeObjectURL(href);
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
