import { useEffect } from "react";
import {
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogProps,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { useReportCreateFormValues } from "src/utils/useReportCreateFormValues";
import { LoadingButton } from "../buttons/LoadingButton";
import { ReportCreateForm } from "../ReportCreateForm/ReportCreateForm";
import { ReportCreateFormValues } from "../ReportCreateForm/ReportCreateForm.model";
import { ReportCreateFormSchema } from "../ReportCreateForm/ReportCreateForm.schema";
import { CustomBannerHeader } from "../CustomBannerHeader/CustomBannerHeader";

type ReportCreateDialogProps = Omit<DialogProps, "onSubmit" | "onClose"> & {
  onClose: () => void;
  dateRange?: DateRange;
  createReport: (data: ReportCreateFormValues) => void;
  isLoading: boolean;
};

export const ReportCreateDialog = ({
  onClose,
  dateRange,
  createReport,
  isLoading,
  ...props
}: ReportCreateDialogProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const defaultValues = useReportCreateFormValues(dateRange);

  const formHook = useForm<ReportCreateFormValues>({
    mode: "all",
    resolver: joiResolver(ReportCreateFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;

  const handleSubmitWrapper = handleSubmit(createReport, (errors) => {
    console.log("@@ DEBUG:submitReportCreate:error", errors);
  });

  const isBusy = formState.isSubmitting || isLoading;

  useEffect(() => {
    // reset when Dialog is closed,  but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open, dateRange, defaultValues]);

  return (
    <FormProvider {...formHook}>
      <Dialog
        maxWidth="lg"
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 864,
            mx: 3.5,
          },
        }}
        {...props}
      >
        <Stack direction={isMobile ? "column-reverse" : "column"}>
          <CustomBannerHeader />
          <DialogTitle>Create Report</DialogTitle>
        </Stack>
        <Stack
          sx={{
            px: isMobile ? 2 : 3,
            pt: isMobile ? 3 : 1,
          }}
        >
          <ReportCreateForm isBusy={isBusy} />
        </Stack>
        <DialogActions sx={{ pt: isMobile ? 1 : 2, px: isMobile ? 2 : 3 }}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "space-between" : "flex-end"}
            gap={1}
            sx={{
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: isMobile ? 84 : "unset",
                flex: isMobile ? 1 : "unset",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: isMobile ? 84 : "unset",
                flex: isMobile ? 1 : "unset",
              }}
              loading={isBusy}
              disabled={!formState.isValid}
              onClick={handleSubmitWrapper}
            >
              {isMobile ? "Create" : "Ok"}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
