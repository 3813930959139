import { useState } from "react";
import { format, sub } from "date-fns";
import { Stack, Typography } from "@mui/material";
import { TimeLine } from "src/components/TimeLine/TimeLine";
import { TimeLineProgram } from "src/components/TimeLineProgram/TimeLineProgram";
import { TimeLineMentions } from "src/components/TimeLineMentions/TimeLineMentions";

const mockTimeBase = new Date();
const mockStationData = {
  startDateTime: sub(mockTimeBase, { hours: 2 }).toISOString(),
  endDateTime: mockTimeBase.toISOString(),
  stationGUID: "CNN",
  programs: [
    {
      programID: 50815337,
      title: "CNN This Morning",
      description:
        "Stories from across the world and refreshing conversations.",
      genre: "public affairs",
      category: "News",
      airingType: "New",
      startDateTime: sub(mockTimeBase, { hours: 1 }).toISOString(),
      endDateTime: mockTimeBase.toISOString(),
    },
    {
      programID: 50815338,
      title: "CNN News Central",
      description:
        "John Berman, Kate Bolduan and Sara Sidner will anchor the morning program, based in New York. Brianna Keilar, Boris Sanchez and Jim Sciutto will anchor in the afternoon from Washington. The blocks will be produced in Atlanta. Inside Politics will remain at noon-1 p.m. ET, anchored by John King.",
      genre: "documentary",
      category: "News",
      airingType: "New",
      startDateTime: sub(mockTimeBase, { hours: 3 }).toISOString(),
      endDateTime: sub(mockTimeBase, { hours: 1 }).toISOString(),
    },
  ],
};

export function DevTimeLine() {
  const now = new Date();
  const [currentDate, setCurrentDate] = useState(now);

  const handleChange = (date: Date) => {
    setCurrentDate(date);
  };

  return (
    <Stack>
      <Typography>Timeline {format(currentDate, "pp")}</Typography>

      <TimeLine value={currentDate} onChange={handleChange} maxDate={now} />

      <TimeLineProgram
        value={currentDate}
        onChange={handleChange}
        maxDate={now}
        timeZone="America/New_York"
        schedule={mockStationData}
      />

      <TimeLineMentions
        value={currentDate}
        onChange={handleChange}
        maxDate={now}
      />
    </Stack>
  );
}
