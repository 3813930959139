import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { Outlet, useNavigate } from "react-router-dom";
import { dateTimeSearchInitialRoute } from "../DateTimeSearchInitial/DateTimeSearchInitial.route";
import { useDateTimeSearchForm } from "./hooks/useDateTimeSearchForm";

export function DateTimeSearchRoot() {
  const navigate = useNavigate();
  const formHook = useDateTimeSearchForm();

  useEffect(() => {
    navigate(dateTimeSearchInitialRoute.makeUrl(), { replace: true });
  }, [navigate]);

  return (
    <FormProvider {...formHook}>
      {/* Outlet is here because I need form state shared between child routes */}
      <Outlet />
    </FormProvider>
  );
}
