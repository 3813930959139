import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { enqueueSnackbar } from "notistack";
import { apiSessionPath } from "./useSessionQuery";

const apiKey: keyof paths = "/api/User/{id}/Impersonate";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useImpersonateUser({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ params }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, params);

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: apiSessionPath,
      });

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError(error) {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
    onSettled(data, error, variables, context) {
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
