import {
  Alert,
  alertClasses,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useReadOnlySnackbar } from "src/api/usReadOnlySnackbar";

export const CustomQueryReadonlySnackbar = () => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const readOnlySnackbar = useReadOnlySnackbar();

  if (!isMobile) {
    return null;
  }

  return (
    <Snackbar
      open={readOnlySnackbar.isOpen}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={(event, reason) => {
        // We use this Snackbar on mobile when touching the Monaco Editor
        // "clickaway" event is fired on "touchend" event
        if (reason === "clickaway") return;
        readOnlySnackbar.hide();
      }}
    >
      <Alert
        severity="info"
        variant="filled"
        onClose={readOnlySnackbar.hide}
        sx={{
          borderRadius: "4px",
          alignItems: "center",
          backgroundColor: palette.primary.main,
          [`.${alertClasses.action}`]: {
            pt: 0,
          },
        }}
      >
        You can edit custom query only on desktop
      </Alert>
    </Snackbar>
  );
};
