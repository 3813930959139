import { alpha, useTheme } from "@mui/material";
import { useMemo } from "react";

export const useAppChartColorPalette = () => {
  const { palette } = useTheme();

  const paletteLight = useMemo(
    () => [
      {
        bg: palette.primary.main,
        fg: palette.primary.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
      {
        bg: palette.info.light,
        fg: palette.info.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
      {
        bg: palette.secondary.dark,
        fg: palette.secondary.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
      {
        bg: palette.secondary.main,
        fg: palette.secondary.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
      {
        bg: palette.warning.main,
        fg: palette.warning.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
      {
        bg: palette.error.light,
        fg: palette.error.contrastText,
        icon: alpha(palette.common.white, 0.7),
      },
    ],
    [palette]
  );

  const paletteDark = useMemo(
    () => [
      {
        bg: palette.primary.main,
        fg: palette.primary.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
      {
        bg: palette.info.light,
        fg: palette.info.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
      {
        bg: palette.secondary.dark,
        fg: palette.primary.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
      {
        bg: palette.secondary.main,
        fg: palette.secondary.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
      {
        bg: palette.warning.main,
        fg: palette.warning.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
      {
        bg: palette.error.light,
        fg: palette.secondary.contrastText,
        icon: alpha(palette.common.black, 0.7),
      },
    ],
    [palette]
  );

  return palette.mode === "dark" ? paletteDark : paletteLight;
};
