import { FormState } from "react-hook-form";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { UserInformationUpdateFormValues } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.model";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import {
  getSectionField,
  getSectionName,
} from "./LeaveConfirmationDialog.utils";

type LeaveConfirmationDialogProps = Omit<
  DialogBaseProps,
  "onSubmit" | "onClose"
> & {
  onClose: () => void;
  onSubmit: () => void;
  onDataReset: () => void;
  userDirtyFields: FormState<UserInformationUpdateFormValues>["dirtyFields"];
  accountDirtyFields: FormState<NewAccountFormValues>["dirtyFields"];
};

export function LeaveConfirmationDialog({
  userDirtyFields,
  accountDirtyFields,
  onClose,
  onSubmit,
  onDataReset,
  ...props
}: LeaveConfirmationDialogProps) {
  const { palette } = useTheme();

  const userItems = makeTypedPropList(userDirtyFields).map((item) => (
    <Stack key={item}>
      <Stack direction="row" gap={1} height={42} alignItems="center" px={1}>
        <InfoOutlined fontSize="small" />
        <Typography variant="subtitle2" color="primary">
          {`${getSectionName(item)}: ${getSectionField(item)}`}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  ));

  const accountItems = makeTypedPropList(accountDirtyFields).map((item) => (
    <Stack key={item}>
      <Stack direction="row" gap={1} height={42} alignItems="center" px={1}>
        <InfoOutlined fontSize="small" />
        <Typography variant="subtitle2" color="primary">
          {`${getSectionName(item)}: ${getSectionField(item)}`}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  ));

  const content = (
    <Stack gap={2}>
      <Typography>
        You have unsaved changes. Would you like to save them before leaving?
      </Typography>
      <Box
        sx={{
          background: palette.background.default,
          height: 210,
          py: 1,
          overflow: "auto",
        }}
      >
        {userItems}
        {accountItems}
      </Box>
    </Stack>
  );

  return (
    <DialogBase
      maxWidth="sm"
      extraMobileMargin={3}
      disableHeaderDivider
      children={content}
      onClose={onClose}
      {...props}
      footer={
        <DialogActions sx={{ p: 0 }}>
          <Stack direction="row" justifyContent="end" width="100%" gap={1}>
            <Button
              variant="outlined"
              sx={{
                width: 90,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                width: 90,
              }}
              onClick={onDataReset}
            >
              Discard
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: 90,
              }}
              onClick={onSubmit}
            >
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      }
    />
  );
}
