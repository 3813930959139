import { StackProps } from "@mui/material";

export enum scrollBoxClasses {
  root = "scroll-box-root",
  scroll = "scroll-box-scroller",
  effectStart = "scroll-box-shadow-start",
  effectEnd = "scroll-box-shadow-end",
}

export type ScrollBoxProps = Omit<StackProps, "direction" | "overflow"> & {
  color?: string;
  direction?: "row" | "column";
  shadowColor?: string;
  shadowSize?: number;
  shadowOpacity?: number;
  /**
   * top shadow position offset
   */
  shadowTopOffset?: number;
  /**
   * bottom shadow position offset
   */
  shadowBottomOffset?: number;
  /**
   * left shadow position offset
   */
  shadowLeftOffset?: number;
  /**
   * right shadow position offset
   */
  shadowRightOffset?: number;

  /**
   * Do not use shadow effect to indicate presence of scrollable content
   */
  disableOverflowShadow?: boolean;

  /**
   * Style of the custom scroll bar
   */
  customScrollBarStyle?: "thin" | "normal" | "hidden";

  /** A property to set background of the custom scrollbar */
  scrollbarColor?: string;

  itemsRowGap?: number;

  /**
   * Custom shadow opacity for dark theme
   */
  darkShadowOpacity?: number;
};
