import { ListBase } from "src/components/ListBase/ListBase";
import { EventsNoResultsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ReportEventsListProps } from "./ReportEventsList.model";
import { ReportEventsListItem } from "./ReportEventsListItem";

export function ReportEventsList({
  renderEventsItemActions,
  selectionState,
  getEventUrl,
  highlightedEventsIds,
  isCompact,
  ...props
}: ReportEventsListProps) {
  const renderItem = (data: PowerSearchResultItem) => {
    const isChecked = !!selectionState?.list.find(
      (event) => event.id === data.id
    );
    return (
      <ReportEventsListItem
        value={data}
        isChecked={isChecked}
        key={data.id}
        renderEventsItemActions={renderEventsItemActions}
        selectionState={selectionState}
        getEventUrl={getEventUrl}
        highlightedEventsIds={highlightedEventsIds}
        isCompact={isCompact}
      />
    );
  };

  return (
    <ListBase<PowerSearchResultItem>
      renderItem={renderItem}
      renderEmpty={EventsNoResultsOverlay}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
