import { QueryDefinition } from "src/models/QueryDefinition";

export function isWatchQueryComplex({
  queryBuilderDefinition,
}: {
  queryBuilderDefinition?: QueryDefinition | null;
}): boolean {
  const { sourceFilter, keywordQuery, customQuery } =
    queryBuilderDefinition || {};

  const {
    countries = {},
    markets = {},
    state = {},
    programs = {},
    stations = {},
    contentType = {},
  } = sourceFilter || {};

  const hasAdvertSettings = !!sourceFilter?.adverts;
  const includeKeywordCount = keywordQuery?.include?.length ?? 0;
  const includeCountriesCount = countries?.include?.length ?? 0;
  const includeMarketCount = markets?.include?.length ?? 0;
  const includeStateCount = state?.include?.length ?? 0;
  const includeProgramsCount = programs?.include?.length ?? 0;
  const includeStationsCount = stations?.include?.length ?? 0;
  const includeContentType = contentType?.include?.length ?? 0;
  const excludeCountriesCount = countries?.exclude?.length ?? 0;
  const excludeeMarketCount = markets?.exclude?.length ?? 0;
  const excludeStateCount = state?.exclude?.length ?? 0;
  const excludeProgramsCount = programs?.exclude?.length ?? 0;
  const excludeStationsCount = stations?.exclude?.length ?? 0;
  const excludeContentType = contentType?.exclude?.length ?? 0;

  return !!(
    customQuery ||
    includeKeywordCount > 1 ||
    hasAdvertSettings ||
    includeCountriesCount ||
    includeMarketCount ||
    includeStateCount ||
    includeProgramsCount ||
    includeStationsCount ||
    includeContentType ||
    excludeCountriesCount ||
    excludeeMarketCount ||
    excludeStateCount ||
    excludeProgramsCount ||
    excludeStationsCount ||
    excludeContentType
  );
}
