import { Paper, Stack, useMediaQuery, useTheme } from "@mui/material";

import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { DevAccounts } from "./components/DevAccounts";
import { DevAuth0 } from "./components/DevAuth0";

// FIXME: has too many errors, deprecated symbol usage and impossible to read
import { DevMap } from "./components/DevMap";
import { DevTheme } from "./components/DevTheme";
import { DevIntercom } from "./components/DevIntercom";
import { DevPushNotifications } from "./components/DevNotifications";
import { DevCodeEditor } from "./components/DevCodeEditor";

enum DevTab {
  mapBox = "mapBox",
  notifications = "notifications",
  intercom = "intercom",
  theme = "theme",
  auth0 = "auth0",
  accounts = "accounts",
}

export const DevPlaygroundPage = () => {
  const [devTab, setDevTab] = useViewModeQueryParam<DevTab>({
    paramKey: "devTab",
    defaultValue: DevTab.theme,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const content = (
    <Stack flex={1} rowGap={2} overflow="hidden" component={Paper}>
      <AppTopNavBar />

      <AppTabLayout
        id="app-tab-layout-theme"
        flex={1}
        overflow="hidden"
        currentTab={devTab}
        variant="scrollable"
        onCurrentTabChange={(tab) => setDevTab(tab as DevTab)}
      >
        <AppTab label="MapBox" value={DevTab.mapBox} children={<DevMap />} />

        <AppTab
          label="Signal R"
          value={DevTab.notifications}
          children={<DevPushNotifications />}
        />

        <AppTab
          label="Intercom"
          value={DevTab.intercom}
          children={<DevIntercom />}
        />
        <AppTab label="Theme" value={DevTab.theme} children={<DevTheme />} />
        <AppTab label="Auth0" value={DevTab.auth0} children={<DevAuth0 />} />
        <AppTab
          label="Accounts"
          value={DevTab.accounts}
          children={<DevAccounts />}
        />
        <AppTab label="Map" value="map" children={<DevMap />} />
        <AppTab
          label="CodeEditor"
          value="codeEditor"
          children={<DevCodeEditor />}
        />
      </AppTabLayout>
    </Stack>
  );

  if (isMobile) {
    return <PageLayoutMobile content={content} hideGlobalSearchBtn />;
  }

  return <PageLayoutDesktop content={content} />;
};
