import { Stack } from "@mui/material";
import { usePublicPageLayout } from "src/utils/usePublicPageLayout";
import { StackScroll, StackScrollProps } from "./StackScroll/StackScroll";

export function PageLayoutPublicDesktop({
  overflow = "scroll",
  header,
  content,
}: {
  overflow?: StackScrollProps["overflow"];
  header?: JSX.Element;
  content: JSX.Element;
}) {
  const { maxContentWidth } = usePublicPageLayout();

  return (
    <StackScroll
      id="page-layout-public-desktop"
      pb={3}
      pl={overflow === "scroll" ? 1 : 0}
      flex={1}
      width="100%"
      overflow={overflow}
    >
      {header ? (
        <Stack maxWidth={maxContentWidth} width="100%" mx="auto">
          {header}
        </Stack>
      ) : null}
      <Stack
        maxWidth={maxContentWidth}
        width="100%"
        mx="auto"
        flex={1}
        overflow={
          overflow === "hidden"
            ? // when page layout does not scroll - limit content to page
              "hidden"
            : // when page layout scrolls - show entre content
              "visible"
        }
        children={content}
      />
    </StackScroll>
  );
}
