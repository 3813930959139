import { Stack, alpha, useTheme } from "@mui/material";
import { TimeLineCursorRangeProps } from "./TimeLineCursorRange.model";

export function TimeLineCursorRange({
  bounds,
  secondsPerPixel,
  showBounderyLines,
}: TimeLineCursorRangeProps) {
  const { palette } = useTheme();
  const [min, max] = bounds;
  const absMin = Math.abs(min);
  const absMax = Math.abs(max);

  const width = ((absMin + absMax) * 60) / secondsPerPixel;

  const translate = -50 + (50 * (min + max)) / (absMin + absMax);

  const linesBoundsStyles = showBounderyLines
    ? {
        [`&:before`]: {
          content: "'['",
          position: "absolute",
          left: -5,
          top: 6,
          fontSize: "3rem",
          color: palette.secondary.main,
        },
        [`&:after`]: {
          content: "']'",
          position: "absolute",
          right: -5,
          top: 6,
          fontSize: "3rem",
          color: palette.secondary.main,
        },
      }
    : undefined;

  return (
    <Stack
      left="50%"
      height="100%"
      position="absolute"
      width={width}
      children={
        <Stack
          top={4}
          bottom={4}
          width="100%"
          position="absolute"
          bgcolor={alpha(palette.secondary.main, 0.3)}
          sx={linesBoundsStyles}
        />
      }
      sx={{
        pointerEvents: "none",
        transform: `translate(${translate}%)`,
      }}
    />
  );
}
