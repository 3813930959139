import { useMediaQuery, useTheme } from "@mui/material";

/**
 * Detects screen size that is treated as mobile device
 */
export const useIsMobile = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.only("xs"));

  return isMobile;
};
