import {
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useBanner } from "src/api/useBanner";
import { useSessionContext } from "src/api/useSessionContext";
import { LogoEye } from "src/components/logo/LogoEye";
import { useBannerPublic } from "src/api/useBannerPublic";
import { PublicMediaCenterItemPathParams } from "src/pages/PublicMediaCenterItem/PublicMediaCenterItem.route";
import { BannerData } from "src/api/useEventDetailsPublic";
import { ReportBanner } from "../ReportBanner/ReportBanner";
import { LoginLogoutButton } from "../LoginLogoutButton/LoginLogoutButton";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";

export function CustomBannerHeader({
  width = "100%",
  maxWidth = "864px",
  isPublicPage,
  publicUserId,
  eventBannerData,
}: {
  width?: string;
  maxWidth?: string;
  isPublicPage?: boolean;
  publicUserId?: string;
  eventBannerData?: BannerData | null;
}) {
  const { palette, breakpoints } = useTheme();
  const { userId } = useSessionContext();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  //Get userId from url for media center public items
  const { userId: paramsUserid = "" } =
    useParams<PublicMediaCenterItemPathParams>();

  const { data, isLoading } = useBanner({
    request: {
      params: {
        path: {
          userId: eventBannerData ? "" : userId,
        },
      },
    },
  });

  const {
    data: publicData,
    isLoading: isLoadingPublicData,
    error,
  } = useBannerPublic({
    request: {
      params: {
        path: {
          userId: publicUserId || paramsUserid || "",
        },
      },
    },
  });

  const bannerData = eventBannerData
    ? eventBannerData
    : isPublicPage
    ? publicData
    : data;
  const isBannerDataLoading = isPublicPage ? isLoadingPublicData : isLoading;

  if (error) return <ReportBanner />;

  return isBannerDataLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack
      direction="row"
      width={width}
      alignItems="center"
      bgcolor={bannerData?.bgColor || palette.background.brand}
      maxWidth={maxWidth}
      minHeight={100}
      px={2}
    >
      {bannerData?.bannerData ? (
        <Stack mr={{ xs: 1, md: 2 }}>
          <img
            alt="banner logo"
            src={`data:image/png;base64,${bannerData?.bannerData}`}
            style={{
              borderRadius: "30px",
              maxWidth: isMobile ? 54 : 58,
            }}
          />
        </Stack>
      ) : (
        <LogoEye
          dark
          maxHeight={isMobile ? 54 : 58}
          maxWidth={isMobile ? 54 : 58}
          mr={{ xs: 1, md: 2 }}
        />
      )}
      <Stack flex={1}>
        <Typography
          variant="subtitle1"
          mb={0.25}
          fontSize={{ xs: 14, md: 16 }}
          sx={{ letterSpacing: isMobile ? 0.1 : 0.15 }}
          color={
            bannerData?.color ||
            palette.getContrastText(palette.background.brand)
          }
        >
          {bannerData?.title || "TVEyes Insight"}
        </Typography>
        <Typography
          variant="body2"
          color={
            bannerData?.color ||
            alpha(palette.getContrastText(palette.background.brand), 0.7)
          }
          fontSize={{ xs: 12, md: 14 }}
        >
          {bannerData?.subTitle || "Advanced Media Monitoring"}
        </Typography>
      </Stack>
      {isPublicPage && <LoginLogoutButton color={bannerData?.color} />}
    </Stack>
  );
}
