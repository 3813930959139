import { Button, Paper, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "src/api/useSessionContext";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { useOpenState } from "src/utils/useOpenState";
import { useLocalStorage } from "src/utils/useLocalStorage";
import { useIsDemoMode } from "src/api/useIsDemoMode";
import { UserManagementFiltersModal } from "./components/UserManagementFiltersModal/UserManagementFiltersModal";
import { ManagementUsersList } from "./components/ManagementUsersList/ManagementUsersList";
import { useUserManagementFilter } from "./hooks/useUserManagementFilter";
import { userManagementCreateRoute } from "../UserManagementCreate/UserManagementCreate.route";

const EXPANDED_SCREEN_WIDTH = 1528;

export function UserManagementPage() {
  const navigate = useNavigate();
  const { isImpersonatingUser } = useSessionContext();
  const isDemoMode = useIsDemoMode();

  const [filterQuery, setFilterQuery, resetFilterQuery] =
    useUserManagementFilter();
  const [isExpanded, setIsExpandend] = useLocalStorage(
    "UserManagementExpandedView"
  );
  const filterDialog = useOpenState();

  const impersonateUser = useImpersonateUser({});

  const cancelImpersonation = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id: "00000000-0000-0000-0000-000000000000",
        },
      },
    });
  };

  const toolbar = (
    <Stack
      direction="row"
      flex={1}
      justifyContent="flex-end"
      alignItems="center"
      columnGap={2}
    >
      {isImpersonatingUser === true && !isDemoMode && (
        <Button
          variant="outlined"
          onClick={() => cancelImpersonation()}
          sx={{ height: 42 }}
          children="Stop Impersonating"
        />
      )}
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={() => navigate(userManagementCreateRoute.path)}
        children="New User"
        sx={{ width: 164, height: 42 }}
      />
    </Stack>
  );

  const content = (
    <Stack
      id="user-management-content"
      flex={1}
      width="100%"
      height="100%"
      component={Paper}
      overflow="hidden"
      children={
        <ManagementUsersList
          filterDialog={filterDialog}
          filterQuery={filterQuery}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpandend}
        />
      }
    />
  );

  return (
    <>
      <PageLayoutDesktop
        maxWidthNormal={isExpanded ? EXPANDED_SCREEN_WIDTH : undefined}
        header={
          <PageHeaderDesktop
            title="Users Management"
            toolbar={toolbar}
            hideBreadcrumbs
            hideBackButton
          />
        }
        content={content}
        overflow="hidden"
      />
      <UserManagementFiltersModal
        title="Filter options"
        open={filterDialog.isOpen}
        initial={filterQuery}
        onClose={filterDialog.hide}
        onSubmit={setFilterQuery}
        onResetForm={resetFilterQuery}
      />
    </>
  );
}
