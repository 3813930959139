import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Analytics,
  BookmarkAdded,
  ModeEdit,
  MoreVertOutlined,
  RemoveRedEyeOutlined,
  ShareOutlined,
} from "@mui/icons-material";

import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import { isAvailableEventType } from "src/api/useClipSave";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import {
  useShareEventFormSnackbar,
  useShareEventLinkSnackbar,
} from "src/api/useShareEventSnackbar";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
import { useOpenState } from "src/utils/useOpenState";
import { IconButtonDropDown } from "src/components/IconButtonDropDown/IconButtonDropDown";
import { SaveClipDialog } from "src/components/SaveClipDialog/SaveClipDialog";
import { ShareEventsDialog } from "src/components/ShareEventsDialog/ShareEventsDialog";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { powerSearchResultClipEditorRoute } from "src/pages/PowerSearchResultClipEditor/PowerSearchResultClipEditor.route";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { getPowerSearchResultId } from "src/api/usePowerSearchResult.getters";
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { useSessionContext } from "src/api/useSessionContext";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";

export type PowerSearchResultActionsProps = {
  value: PowerSearchResultItem;
  addItemToReport: (value: PowerSearchResultItem) => void;
};

export const PowerSearchResultActions = ({
  value,
  addItemToReport,
}: PowerSearchResultActionsProps) => {
  const [openSaveClipDialog, setOpenSaveClipDialog] = useState(false);
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareEventFormSnackbar();
  const shareLinkSnackBar = useShareEventLinkSnackbar();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;

  const navigate = useNavigate();

  // const downloadPdfMutation = useExportPdf({});

  // const downloadPdfEventItem = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: [value],
  //   });
  // }, [downloadPdfMutation, downloadFilesSnackBar, value]);

  const downloadCsvMutation = useExportCsv({});

  const downloadCsvEventItem = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: [value],
    });
  }, [downloadCsvMutation, downloadFilesSnackBar, value]);

  const detailsUrl = powerSearchResultClipRoute.makeUrl({
    eventId: getPowerSearchResultId(value),
  });

  const editUrl = powerSearchResultClipEditorRoute.makeUrl({
    eventId: getPowerSearchResultId(value),
  });

  return (
    <>
      <IconButtonDropDown
        renderDropDown={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                shareDialog.show();
              }}
            >
              <ListItemIcon>
                <ShareOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                addItemToReport(value);
              }}
            >
              <ListItemIcon>
                <Analytics fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Add to Report</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                navigate(detailsUrl);
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                navigate(editUrl);
              }}
            >
              <ListItemIcon>
                <ModeEdit fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            {value.eventType && isAvailableEventType(value.eventType) && (
              <TooltipBase
                width={181}
                textAlign="left"
                title={
                  enableMediaDownload
                    ? "Save for 90 days to the Media Center from where you can select clips when create report or share items"
                    : "You can't save media as you have an account limitation. Please contact your account manager to extend your limits."
                }
              >
                <Box>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      setOpenSaveClipDialog(true);
                    }}
                    disabled={!enableMediaDownload}
                  >
                    <ListItemIcon>
                      <BookmarkAdded fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>Save To Media Center</ListItemText>
                  </MenuItem>
                </Box>
              </TooltipBase>
            )}

            {/* <MenuItem
              onClick={() => {
                downloadPdfEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadPDF />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </MenuItem> */}

            <MenuItem
              onClick={() => {
                downloadCsvEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadCSV />
              </ListItemIcon>
              <ListItemText>Download CSV</ListItemText>
            </MenuItem>
          </Menu>
        )}
      >
        <MoreVertOutlined />
      </IconButtonDropDown>

      <SaveClipDialog
        open={openSaveClipDialog}
        event={value}
        onClose={() => setOpenSaveClipDialog(false)}
      />
      <ShareEventsDialog
        open={shareDialog.isOpen}
        events={[value]}
        onClose={shareDialog.hide}
        onSubmit={shareFormSnackBar.show}
        copyShareLinkCallback={shareLinkSnackBar.show}
      />
    </>
  );
};
