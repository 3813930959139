import clsx from "clsx";
import { forwardRef } from "react";
import { SxProps, Typography } from "@mui/material";
import { TextLineClampProps } from "./TextLineClamp.model";

export const TextLineClamp = forwardRef<HTMLSpanElement, TextLineClampProps>(
  ({ lineClamp, wordBreak, sx, ...props }, ref) => {
    const lineClampSx: SxProps = lineClamp
      ? {
          overflow: "hidden",
          display: "-webkit-box",
          lineClamp: `${lineClamp}`,
          WebkitLineClamp: `${lineClamp}`,
          WebkitBoxOrient: "vertical",
          whiteSpace: "break-spaces",
          wordBreak: wordBreak
            ? wordBreak
            : lineClamp > 1
            ? "break-word"
            : "break-all",
        }
      : {};

    return (
      <Typography
        ref={ref}
        variant="inherit"
        color="inherit"
        className={clsx("TextLineClamp", props.className)}
        {...props}
        sx={{
          ...lineClampSx,
          ...sx,
        }}
      />
    );
  }
);
