import { useState } from "react";

export function useParentBgColor(element?: Element | null) {
  const [color, setState] = useState<string>();
  let currentElement = element;

  while (currentElement) {
    const { backgroundColor } = getComputedStyle(currentElement);

    if (backgroundColor !== "rgba(0, 0, 0, 0)") {
      if (backgroundColor !== color) {
        setState(backgroundColor);
      }
      return backgroundColor;
    }

    currentElement = currentElement.parentElement;
  }

  return color;
}
