import { useQueryParam } from "use-query-params";

export function useViewModeQueryParam<T extends string>({
  paramKey = "viewMode",
  defaultValue,
}: {
  paramKey?: string;
  defaultValue: T;
}): [T, (nextViewMode: T) => void] {
  const [viewMode = defaultValue, setViewMode] = useQueryParam<T>(paramKey);

  const setViewModeWrapper = (nextViewMode: T) => {
    setViewMode(nextViewMode, "replaceIn");
  };

  return [viewMode, setViewModeWrapper];
}
