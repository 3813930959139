import { Assessment, Print } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import DropDownButton from "src/components/DropDownButton/DropDownButton";
import { DownloadPDF } from "src/components/icons/DownloadPDF";

export function ReportAnalysisToolbarDesktop() {
  return (
    <Stack flex={1} flexDirection="row" gap={2} justifyContent="flex-end">
      <ToggleButtonGroup sx={{ width: 42, height: 42 }}>
        <ToggleButton value={true} children={<Print />} />
      </ToggleButtonGroup>

      <DropDownButton
        variant="contained"
        color="primary"
        size="large"
        children="Export"
        sx={{ minWidth: 176 }}
        renderMenu={(menuProps, closeMenu) => {
          return (
            <Menu {...menuProps}>
              <MenuItem
                onClick={() => {
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <Assessment color="primary" />
                </ListItemIcon>
                <ListItemText>Option 1</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <DownloadPDF />
                </ListItemIcon>
                <ListItemText>Option 2</ListItemText>
              </MenuItem>
            </Menu>
          );
        }}
      />

      <Button
        variant="contained"
        color="secondary"
        size="large"
        children="Share"
        sx={{ minWidth: 176 }}
      />
    </Stack>
  );
}
