import { Fragment, useRef, RefObject } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonProps, Menu, MenuItem, Tooltip } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";

/**
 *  A simple button dropdown menu. Value is set as button title.
 */
export function OptionPicker<V extends string>(props: {
  value: V;
  options: V[];
  onChange: (value: V) => void;
  disabled?: ButtonProps["disabled"];
  getOptionLabel?: (option: V) => string;
  onFocus?: ButtonProps["onFocus"];
  onBlur?: ButtonProps["onBlur"];
  size?: ButtonProps["size"];
  anchorEl?: RefObject<Element>;
  displayTooltip?: boolean;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const openState = useOpenState();

  const defaultGetOptionLabel = (option: V) => option.toString();

  const valueLabel = props.getOptionLabel
    ? props.getOptionLabel(props.value)
    : defaultGetOptionLabel(props.value);

  const optionsButton = (
    <Button
      onClick={openState.show}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      disabled={props.disabled}
      ref={buttonRef}
      size={props.size}
      sx={{
        alignSelf: "stretch",
        pt: 0,
        pr: 0.5,
        pb: 0,
        pl: 0,
        borderRadius: 1,
        margin: "3px",
      }}
    >
      <ArrowDropDown color="action" />
      {valueLabel}
    </Button>
  );
  return (
    <Fragment>
      {props.displayTooltip ? (
        <Tooltip
          title={
            'Use "or" for including results that contain any of several keywords. Use "and" for including results with multiple keywords.'
          }
        >
          {optionsButton}
        </Tooltip>
      ) : (
        optionsButton
      )}

      <Menu
        anchorEl={props.anchorEl?.current || buttonRef.current}
        open={openState.isOpen}
        onClose={openState.hide}
        onKeyDown={(e) => e.stopPropagation()}
        disableAutoFocusItem
      >
        {props.options.map((option, index) => {
          const key = `${option}:${index}`;
          const handleValueChange = () => {
            openState.hide();
            props.onChange(option);
          };

          const optionLabel = props.getOptionLabel
            ? props.getOptionLabel(option)
            : defaultGetOptionLabel(option);

          return (
            <MenuItem onClick={handleValueChange} key={key} disableRipple>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}
