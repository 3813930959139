import { PlayArrowOutlined, PauseOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDateTimeSearchPlayState } from "./hooks/useDateTimeSearchPlayState";

export const DateTimeSearchPlayButton = ({
  isLoading,
  compact,
}: {
  isLoading?: boolean;
  compact?: boolean;
}) => {
  const playAllState = useDateTimeSearchPlayState();
  const { instances, isPlaying, togglePlaying } = playAllState;

  const icon = isPlaying ? <PauseOutlined /> : <PlayArrowOutlined />;

  if (compact) {
    return (
      <Button
        color={isPlaying ? "secondary" : "primary"}
        startIcon={icon}
        onClick={togglePlaying}
        disabled={!instances.length || isLoading}
        children={isPlaying ? "Pause" : "Play"}
      />
    );
  }

  return (
    <Button
      sx={{ width: 140, height: 42 }}
      color="secondary"
      variant="contained"
      startIcon={icon}
      onClick={togglePlaying}
      disabled={!instances.length || isLoading}
      children={isPlaying ? "Pause" : "Play all"}
    />
  );
};
