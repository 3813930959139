import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { TimeLineDragBoxProps } from "./TimeLineDragBox.model";

export const TimeLineDragBox = ({
  onDragStart,
  onDrag,
  onDragEnd,
  ...props
}: TimeLineDragBoxProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const handlePointerDown = useCallback(
    (e: MouseEvent) => {
      onDragStart && onDragStart(e.x);
    },
    [onDragStart]
  );

  const handlePointerMove = useCallback(
    (e: MouseEvent) => {
      onDrag && onDrag(e.x);
    },
    [onDrag]
  );

  const handlePointerUp = useCallback(() => {
    onDragEnd && onDragEnd();
  }, [onDragEnd]);

  const handleTouchDown = useCallback(
    (e: TouchEvent) => {
      const x = e.touches[0].clientX;
      onDragStart && onDragStart(x);
    },
    [onDragStart]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      const x = e.touches[0].clientX;
      onDrag && onDrag(x);
    },
    [onDrag]
  );

  const handleTouchUp = useCallback(
    (e: TouchEvent) => {
      onDragEnd && onDragEnd();
    },
    [onDragEnd]
  );

  useEffect(() => {
    const element = ref;
    const opt = {
      passive: true,
    };

    element?.addEventListener("mousedown", handlePointerDown, opt);
    element?.addEventListener("mousemove", handlePointerMove, opt);
    element?.addEventListener("mouseup", handlePointerUp, opt);
    element?.addEventListener("mouseout", handlePointerUp, opt);
    element?.addEventListener("mouseleave", handlePointerUp, opt);

    element?.addEventListener("touchstart", handleTouchDown, opt);
    element?.addEventListener("touchmove", handleTouchMove, opt);
    element?.addEventListener("touchend", handleTouchUp, opt);

    return () => {
      element?.removeEventListener("mousedown", handlePointerDown);
      element?.removeEventListener("mousemove", handlePointerMove);
      element?.removeEventListener("mouseup", handlePointerUp);
      element?.removeEventListener("mouseout", handlePointerUp);
      element?.removeEventListener("mouseleave", handlePointerUp);

      element?.removeEventListener("touchstart", handleTouchDown);
      element?.removeEventListener("touchmove", handleTouchMove);
      element?.removeEventListener("touchend", handleTouchUp);
    };
  }, [
    ref,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleTouchDown,
    handleTouchMove,
    handleTouchUp,
  ]);

  return (
    <Box
      ref={setRef}
      {...props}
      sx={{
        cursor: "pointer",
      }}
    />
  );
};
