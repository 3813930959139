import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import { usePowerSearchSharedForm } from "src/api/usePowerSearchSharedForm";
import { PowerSearchKeywordFormValues } from "./PowerSearchKeywordForm.model";

export const PowerSearchKeywordFormWrapper = (props: PropsWithChildren) => {
  const { watch, reset, trigger } =
    useFormContext<PowerSearchKeywordFormValues>();
  const formValues = watch();

  const { sharedValues } = usePowerSearchSharedForm();

  useEffect(() => {
    reset({
      ...formValues,
      ...sharedValues,
    });
    const sharedKeys = Object.keys(sharedValues) as Array<
      keyof PowerSearchKeywordFormValues
    >;
    trigger(sharedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Stack>{props.children}</Stack>;
};
