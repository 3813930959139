import { ExpandLess } from "@mui/icons-material";
import { Button, ButtonProps, useTheme } from "@mui/material";

export function MoreOptionsButton({
  isOpen,
  sx,
  ...props
}: Omit<ButtonProps, "children"> & { isOpen?: boolean }) {
  const { transitions } = useTheme();
  const icon = (
    <ExpandLess
      color="primary"
      sx={{
        rotate: isOpen ? 0 : "180deg",
        transition: transitions.create("rotate", {
          duration: 300,
        }),
      }}
    />
  );

  return (
    <Button
      variant="text"
      endIcon={icon}
      {...props}
      sx={{ textTransform: "uppercase", ...sx }}
      children="More options"
    />
  );
}
