import Joi from "joi";
import { EditAccountDetailsFormValues } from "./AccountsManagementEditDetails.model";

export const EditAccountDetailsSchema =
  Joi.object<EditAccountDetailsFormValues>({
    name: Joi.string().required().label("Organization name"),
    organizationType: Joi.string().required().label("Organization type"),
    country: Joi.string().allow("").label("Country"),
    city: Joi.string().allow("").label("City"),
    stateOrProvince: Joi.string().allow("").label("State or Province"),
    postalCode: Joi.string().allow("").label("Zip Code"),
    timezone: Joi.string().optional().label("Timezone").allow(""),
  });
