import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

type UserManagementLayoutButtonProps = {
  isExpanded?: string | null;
  onClick?: (value: string) => void;
};

export function UserManagementLayoutButton({
  isExpanded,
  onClick,
}: UserManagementLayoutButtonProps) {
  const title = isExpanded ? "Compact view" : "Expanded view";

  const handleExpandedViewChange = () => {
    if (isExpanded) {
      onClick?.("");
    } else {
      onClick?.("expanded");
    }
  };

  return (
    <Tooltip title={title}>
      <ToggleButtonGroup size={"medium"}>
        <ToggleButton value={true} onClick={handleExpandedViewChange}>
          {isExpanded ? (
            <CloseFullscreenIcon sx={{ color: "text.primary" }} />
          ) : (
            <OpenInFullIcon sx={{ color: "text.primary" }} />
          )}
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
}
