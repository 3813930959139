import { Box } from "@mui/material";
import { DownloadReportFormat } from "src/api/useReportExport";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type DownloadMessageDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  handleReportExport: (format: DownloadReportFormat) => void;
  format: DownloadReportFormat;
};
export const DownloadMessageDialog = ({
  onClose,
  handleReportExport,
  format,
  ...props
}: DownloadMessageDialogProps) => {
  return (
    <DialogPrompt
      maxWidth="xs"
      title="Download the report?"
      children={
        <Box pb={1}>
          There are a lot of events in the selected report(s).
          <br />
          It will take some time to export this report.
          <br />
          Do you want to continue downloading?
        </Box>
      }
      onDecline={onClose}
      onConfirm={() => {
        handleReportExport(format);
        onClose && onClose();
      }}
      confirmLabel="Ok"
      {...props}
    />
  );
};
