import {
  HeadphonesOutlined,
  LiveTvOutlined,
  RadioOutlined,
} from "@mui/icons-material";
import { YouTubeIcon } from "src/components/icons/YouTubeIcon";

export const getEventSourceIcon = (type: string, mediaType?: string) => {
  if (type === "Radio") {
    return RadioOutlined;
  }

  if (type === "Television") {
    return LiveTvOutlined;
  }

  if (type === "Podcast") {
    return HeadphonesOutlined;
  }

  if (type === "YouTube") {
    return YouTubeIcon;
  }

  return null;
};
