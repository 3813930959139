import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { UseMutationOptions, useMutation } from "react-query";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";

const apiKey: keyof paths = "/api/Account";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
export type AccountCreateResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  body: NewAccountFormValues;
};

type RequestOptions = Pick<
  UseMutationOptions<AccountCreateResponse, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useCreateAccount({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<
    AccountCreateResponse,
    unknown,
    RequestMutationFnParams,
    void
  >({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ body }) => {
      const token = await getAccessTokenSilently();

      const url = apiKey;
      const { data } = await axios<AccountCreateResponse>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(`
       Account saved`);
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError?.(error, variables, context);
    },
  });
}
