import {
  Dialog,
  Typography,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { MpOutlined, Assessment } from "@mui/icons-material";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { MediaCenterExportDialogMobileProps } from "./MediaCenterExportDialogMobile.model";

export function MediaCenterExportDialogMobile({
  open,
  onClose,
  createReport,
  downloadMedia,
  isDownloadDisabled,
  // downloadPdf,
  downloadCsv,
}: MediaCenterExportDialogMobileProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 319,
          py: 2,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          px: 3,
        }}
      >
        Export
      </Typography>
      <List>
        <MenuItem
          onClick={() => {
            onClose();
            createReport();
          }}
        >
          <ListItemIcon>
            <Assessment fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Create Report</ListItemText>
        </MenuItem>

        <TooltipBaseMobile
          width={181}
          title="Download is available for up to 10 clips. Please select less items"
          placement="top"
          disableHoverListener={!isDownloadDisabled}
        >
          <MenuItem
            disabled={isDownloadDisabled}
            onClick={() => {
              onClose();
              downloadMedia();
            }}
          >
            <ListItemIcon>
              <MpOutlined fontSize="medium" color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ ml: 0.5 }}>Download Media</ListItemText>
          </MenuItem>
        </TooltipBaseMobile>
        {/* <MenuItem
          onClick={() => {
            onClose();
            downloadPdf();
          }}
        >
          <ListItemIcon>
            <DownloadPDF />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Download PDF</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            onClose();
            downloadCsv();
          }}
        >
          <ListItemIcon>
            <DownloadCSV />
          </ListItemIcon>
          <ListItemText sx={{ ml: 0.5 }}>Download CSV</ListItemText>
        </MenuItem>
      </List>
      <Button
        variant="outlined"
        size="medium"
        color="inherit"
        sx={{
          width: 84,
          ml: "auto",
          mr: 2,
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Dialog>
  );
}
