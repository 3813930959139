import { SvgIcon, SvgIconProps } from "@mui/material";

export const GlobalSearchFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 22"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M18.3 17.9C18.7 17.2 19 16.4 19 15.5C19 13 17 11 14.5 11C12 11 10 13 10 15.5C10 18 12 20 14.5 20C15.4 20 16.2 19.7 16.9 19.3L19.6 22L21 20.6L18.3 17.9ZM14.5 18C13.1 18 12 16.9 12 15.5C12 14.1 13.1 13 14.5 13C15.9 13 17 14.1 17 15.5C17 16.9 15.9 18 14.5 18Z" />
        <path d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 10.6118 19.945 11.2108 19.8396 11.7924C19.3495 11.088 18.7237 10.4851 18 10.0218C18 10.0145 18 10.0073 18 10C18 9.31 17.9 8.64 17.74 8H14.36C14.4003 8.33252 14.4355 8.66504 14.4606 9.00012C13.7689 9.00423 13.1028 9.11638 12.4783 9.32055C12.4509 8.87348 12.3999 8.43231 12.34 8H7.66C7.57 8.65 7.5 9.32 7.5 10C7.5 10.68 7.57 11.34 7.66 12H9.02182C8.63413 12.6055 8.3441 13.2796 8.17393 14H8.09C8.10591 14.0529 8.12212 14.1057 8.13863 14.1583C8.04777 14.5913 8 15.04 8 15.5C8 17.2457 8.68817 18.8306 9.80801 19.9984C4.37209 19.9012 0 15.4592 0 10ZM13.97 6H16.92C15.96 4.35 14.43 3.07 12.59 2.44C13.19 3.55 13.65 4.75 13.97 6ZM11.91 6C11.48 4.57 10.83 3.24 10 2.04C9.17 3.24 8.52 4.57 8.09 6H11.91ZM2 10C2 10.69 2.1 11.36 2.26 12H5.64C5.56 11.34 5.5 10.68 5.5 10C5.5 9.32 5.56 8.66 5.64 8H2.26C2.1 8.64 2 9.31 2 10ZM6.03 14H3.08C4.04 15.66 5.57 16.93 7.41 17.56C6.81 16.45 6.35 15.25 6.03 14ZM3.08 6H6.03C6.35 4.75 6.81 3.55 7.41 2.44C5.57 3.07 4.04 4.34 3.08 6Z" />
      </svg>
    </SvgIcon>
  );
};
