import { alpha, Collapse, IconButton, Stack, useTheme } from "@mui/material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { useOpenState } from "src/utils/useOpenState";
import { CodeEditorControls } from "src/components/CodeEditorSection/CodeEditorControls";
import { MonacoCodeEditor } from "src/components/CodeEditor/MonacoCodeEditor";
import { CodeEditorHelpSection } from "src/components/CodeEditorSection/CodeEditorSection";
import { CustomQueryHighlightedText } from "../CustomQueryHighlightedText/CustomQueryHighlightedText";

export function CustomQueryInput({
  value,
  error,
  helperText,
  onChange,
}: {
  value?: string;
  error: boolean;
  helperText: string;
  onChange: (value?: string) => void;
}) {
  const { palette } = useTheme();
  const inputState = useOpenState();

  return (
    <>
      {inputState.isOpen ? (
        <>
          <Stack
            height={56}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            sx={{
              backgroundColor: alpha(palette.action.hover, 0.04),
              border: `1px solid ${palette.action.disabled}`,
              borderBottom: "none",
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
            }}
          >
            <CodeEditorControls value={value} onChange={onChange} hideTitle />
            <IconButton
              size="large"
              aria-label="hide custom query"
              onClick={inputState.hide}
            >
              <KeyboardArrowUp />
            </IconButton>
          </Stack>
        </>
      ) : (
        <CustomQueryHighlightedText
          value={value}
          error={error}
          helperText={helperText}
          onClick={inputState.show}
        />
      )}
      <Collapse in={inputState.isOpen}>
        <Stack
          p={2}
          mb={3}
          sx={{
            border: `1px solid ${palette.action.disabled}`,
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <MonacoCodeEditor value={value} onChange={onChange} />
          <CodeEditorHelpSection value={value} onChange={onChange} />
        </Stack>
      </Collapse>
    </>
  );
}
