import { FaceRetouchingOff } from "@mui/icons-material";
import { Fab, IconButtonProps, Tooltip } from "@mui/material";

export type StopImpersonateUserFABProps = Omit<IconButtonProps, "children">;

export function StopImpersonateUserFAB({
  ...props
}: StopImpersonateUserFABProps) {
  return (
    <Tooltip title="Exit impersonation">
      <Fab size="medium" {...props} children={<FaceRetouchingOff />} />
    </Tooltip>
  );
}
