import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { AppLinkProps } from "./AppLink.model";

export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(
  ({ to, title, placement, ...props }, ref) => {
    return (
      <Tooltip title={title} placement={placement}>
        <Link
          ref={ref}
          underline="hover"
          component={RouterLink}
          to={to}
          children={props.children || title}
          {...props}
        />
      </Tooltip>
    );
  }
);
