import { useEffect } from "react";
import { QueryDefinition } from "src/models/QueryDefinition";
import { useEventCreate } from "./useEventCreate";

/**
 * Updates power search event details when parameters change
 * @param query query to watch
 * @param eventId eventId to watch
 * @returns event details with highlights
 */
export function usePowerSearchEventDetails(
  query: QueryDefinition,
  eventId: string
) {
  const eventCreate = useEventCreate({});
  const { mutate, reset } = eventCreate;

  useEffect(() => {
    // update data when parameters change
    if (!eventId || !query) {
      reset();
      return;
    }

    return mutate({
      params: {
        path: {
          eventId,
        },
      },
      query,
    });
  }, [mutate, reset, eventId, query]);

  return eventCreate;
}
