import { Map, MapEventOf } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";

type FeatureCursor = {
  featureId: string | number | null;
  sourceId: string;
};

export function useMapBoxLayerEvents({
  map,
  layerId,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onMouseClick,
}: {
  map: Map | null;
  layerId: string;
  onMouseEnter?: (e: MapEventOf<"mouseenter">) => void;
  onMouseMove?: (e: MapEventOf<"mousemove">) => void;
  onMouseLeave?: (e: MapEventOf<"mousemove">) => void;
  onMouseClick?: (e: MapEventOf<"click">) => void;
}) {
  const [hoverPolygonId, setHoverPolygonId] = useState<FeatureCursor | null>(
    null
  );

  const onMouseEnterWrapper = useCallback(
    (e: MapEventOf<"mouseenter">) => {
      const nextFeatureId = e.features?.[0].id;
      const nextSourceId = e.features?.[0].source;

      if (!nextFeatureId || !nextSourceId) return;

      setHoverPolygonId((prev) => {
        if (prev?.featureId && prev.featureId !== nextFeatureId) {
          // remove hover from previous polygon
          e.target.setFeatureState(
            {
              id: prev.featureId,
              source: prev.sourceId,
            },
            { hover: undefined }
          );
        }

        // set new hover polygon id
        if (nextFeatureId) {
          e.target.setFeatureState(
            {
              id: nextFeatureId,
              source: nextSourceId,
            },
            { hover: true }
          );
        }

        return {
          sourceId: nextSourceId,
          featureId: nextFeatureId,
        };
      });

      onMouseEnter?.(e);
    },
    [onMouseEnter]
  );

  const onMouseMoveWrapper = useCallback(
    (e: MapEventOf<"mousemove">) => {
      const nextFeatureId = e.features?.[0].id;
      const nextSourceId = e.features?.[0].source;

      if (!nextFeatureId || !nextSourceId) return;

      setHoverPolygonId((prev) => {
        if (prev?.featureId && prev.featureId !== nextFeatureId) {
          // remove hover from previous polygon
          e.target.setFeatureState(
            {
              id: prev.featureId,
              source: prev.sourceId,
            },
            { hover: undefined }
          );
        }

        // set new hover polygon id
        if (nextFeatureId) {
          e.target.setFeatureState(
            {
              id: nextFeatureId,
              source: nextSourceId,
            },
            { hover: true }
          );
        }

        return {
          sourceId: nextSourceId,
          featureId: nextFeatureId,
        };
      });

      onMouseMove?.(e);
    },
    [onMouseMove]
  );

  const onMouseLeaveWrapper = useCallback(
    (e: MapEventOf<"mouseleave">) => {
      setHoverPolygonId((prev) => {
        // remove hover state from prev
        if (prev?.featureId) {
          e.target.setFeatureState(
            {
              id: prev.featureId,
              source: prev.sourceId,
            },
            { hover: undefined }
          );
        }

        return null;
      });

      onMouseLeave?.(e);
    },
    [onMouseLeave]
  );

  const onMouseClickWrapper = useCallback(
    (e: MapEventOf<"click">) => {
      onMouseClick?.(e);
    },
    [onMouseClick]
  );

  useEffect(() => {
    if (!map) return;

    map.on("mouseenter", layerId, onMouseEnterWrapper);
    map.on("mousemove", layerId, onMouseMoveWrapper);
    map.on("mouseleave", layerId, onMouseLeaveWrapper);

    map.on("click", layerId, onMouseClickWrapper);

    return () => {
      map.off("mouseenter", layerId, onMouseEnterWrapper);
      map.off("mousemove", layerId, onMouseMoveWrapper);
      map.off("mouseleave", layerId, onMouseLeaveWrapper);

      map.off("click", layerId, onMouseClickWrapper);
    };
  }, [
    map,
    layerId,
    onMouseClickWrapper,
    onMouseEnterWrapper,
    onMouseLeaveWrapper,
    onMouseMoveWrapper,
  ]);

  return {
    hoverPolygonId,
  };
}
