import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { useSessionContext } from "src/api/useSessionContext";
import { useWatchListClusterEditMode } from "../WatchListClusterEditToolbar/hooks/useWatchListClusterEditMode";

type WatchListClusterItemMenuProps = {
  clusterId: string;
  clusterTitle: string;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  setShowDeleteModal: (value: boolean) => void;
} & Omit<MenuProps, "children">;

export const WatchQueryItemMenu = ({
  clusterId,
  clusterTitle,
  setShowDeleteModal,
  ...props
}: WatchListClusterItemMenuProps) => {
  const { palette } = useTheme();
  const clusterEditMode = useWatchListClusterEditMode();
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();

  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;

  const handleOpenDeleteDialog = () => {
    setShowDeleteModal(true);
    props.onClose();
  };

  const handleEdit = () => {
    props.onClose();
    clusterEditMode.activate();
  };

  return (
    <Menu {...props}>
      <TooltipBase
        width={181}
        textAlign="left"
        title={t("editWatchListDisabled")}
        placement="left"
        disableHoverListener={enableEditWatchList}
      >
        <Box>
          <MenuItem onClick={handleEdit} disabled={!enableEditWatchList}>
            <ListItemIcon>
              <ModeEditIcon
                fontSize="small"
                sx={{ color: palette.primary.main }}
              />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </Box>
      </TooltipBase>
      <TooltipBase
        width={181}
        textAlign="left"
        title={t("editWatchListDisabled")}
        placement="left"
        disableHoverListener={enableEditWatchList}
      >
        <Box>
          <MenuItem
            onClick={handleOpenDeleteDialog}
            disabled={!enableEditWatchList}
          >
            <ListItemIcon>
              <DeleteOutlineIcon
                fontSize="small"
                sx={{ color: palette.primary.main }}
              />
            </ListItemIcon>
            <ListItemText>Delete Cluster</ListItemText>
          </MenuItem>
        </Box>
      </TooltipBase>
    </Menu>
  );
};
