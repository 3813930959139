import { MutableRefObject, useEffect, useState } from "react";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

export const useGridScrollPosition = (
  apiRef: MutableRefObject<GridApiCommunity>
) => {
  const [scrollLeftPosition, setScrollLeftPosition] = useState(0);

  useEffect(() => {
    const handleRowClick: GridEventListener<"scrollPositionChange"> = (
      params
    ) => {
      setScrollLeftPosition(params.left);
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent(
      "scrollPositionChange",
      handleRowClick
    );
  }, [apiRef]);

  return scrollLeftPosition;
};
