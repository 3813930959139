import { InfiniteData } from "react-query";
import { WatchTermListInfiniteResponse } from "src/api/useWatchQueryListInfinite";
import { isNormalQuery } from "src/utils/isNormalQuery";

export function makeInfiniteNormalQueries(
  data?: InfiniteData<WatchTermListInfiniteResponse>["pages"]
) {
  return data?.reduce<WatchTermListInfiniteResponse[]>((res, next) => {
    const nextResults = next.results?.filter(isNormalQuery);
    res.push({
      ...next,
      results: nextResults,
    });
    return res;
  }, []);
}
