import { AppRouteBuilder } from "src/models/AppRoute";
import { AnalyticsLiteIndex } from "./AnalyticsLite.index";

export type AnalyticsLiteRouteQueryParams = {
  queryIds: string[];
  startDateTime: string;
  endDateTime: string;
};

export const AnalyticsLiteRoute = new AppRouteBuilder<
  void,
  AnalyticsLiteRouteQueryParams
>({
  path: "/analytics/lite",
  element: <AnalyticsLiteIndex />,
});
