import { useEffect } from "react";
import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DialogBase } from "../DialogBase/DialogBase";
import { WatchQueryFilterForm } from "../WatchQueryFilterForm/WatchQueryFilterForm";
import {
  makeEmptyWatchQueryFilterFormValues,
  useWatchQueryFilterForm,
} from "../WatchQueryFilterForm/WatchQueryFilterForm.hook";
import { LoadingButton } from "../buttons/LoadingButton";
import { WatchQueryFilterDialogProps } from "./WatchQueryFilterDialog.model";

export function WatchQueryFilterDialog({
  onClose,
  onSubmit,
  initial,
  title,
  ...props
}: WatchQueryFilterDialogProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const formHook = useWatchQueryFilterForm({
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;
  const handleSubmitWrapper = handleSubmit((data) => {
    onClose && onClose();
    onSubmit(data);
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  const onFormClear = () => {
    const empty = makeEmptyWatchQueryFilterFormValues({});
    reset(empty);
  };

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        closeOnLeftSide={isMobile}
        mobileFullHeight={isMobile}
        maxWidth="lg"
        onClose={onClose}
        title="More filter options"
        {...props}
        children={<WatchQueryFilterForm />}
        footer={
          <Stack
            direction="row"
            justifyContent={isMobile ? "justify-between" : undefined}
            width={isMobile ? "100%" : undefined}
            spacing={2}
            minHeight={42}
          >
            <Hidden lgDown>
              <Button
                sx={{ width: 100, display: { xs: "none", md: "flex" } }}
                onClick={onClose}
                color="error"
              >
                Cancel
              </Button>
            </Hidden>

            <Hidden xlUp>
              <Button
                sx={{
                  minWidth: isMobile ? 160 : 176,
                  width: isMobile ? "100%" : undefined,
                  display: { xs: "flex", sm: "none" },
                  flex: {
                    xs: 1,
                  },
                }}
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Hidden>

            <Hidden mdDown>
              <Button sx={{ width: 160 }} onClick={onFormClear}>
                Clear options
              </Button>
            </Hidden>

            <LoadingButton
              variant="contained"
              color="primary"
              loading={isBusy}
              disabled={!formState.isValid}
              onClick={handleSubmitWrapper}
              children="Apply"
              sx={{
                minWidth: isMobile ? 160 : 176,
                width: isMobile ? "50%" : undefined,
              }}
            />
          </Stack>
        }
      />
    </FormProvider>
  );
}
