import { Dispatch, SetStateAction } from "react";
import Joi from "joi";
import { DialogModes } from "../UploadImageDialog/UploadImageDialog.model";

export type UploadImageEditorProps = {
  preSelectedImage?: string | Blob | null;
  setPreselectedImage?: Dispatch<
    SetStateAction<string | Blob | null | undefined>
  >;
  setScale: Dispatch<SetStateAction<number>>;
  setRotation: Dispatch<SetStateAction<number>>;
  rotation: number;
  scale: number;
  resetChanges?: () => void;
  mode: DialogModes;
};

export type BannerFormValues = {
  title: string;
  subtitle: string;
  bgcolor: string;
};

export const BannerFormValuesSchema = Joi.object<BannerFormValues>({
  title: Joi.string().required().label("Title"),
  subtitle: Joi.string().required().label("Subtitle"),
  bgcolor: Joi.string().required().label("Background color"),
});
