import { isValid } from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import { useMemo } from "react";
import { DateTimeParam, UrlUpdateType, useQueryParams } from "use-query-params";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { makeDateRange } from "./makeDateRange";

type UseQueryDateRangeResult = [[Date, Date], (dateRange: DateRange) => void];

const defaultQueryDays = 7;

type UseQueryDateRangeArgs = {
  updateType?: UrlUpdateType;
};

export function useQueryDateRange(
  args?: UseQueryDateRangeArgs
): UseQueryDateRangeResult {
  const { updateType = "replaceIn" } = args || {};
  const today = useMemo(() => new Date(), []);

  const [defaultStartDate, defaultEndDate] = useMemo(
    () => makeDateRange(today, -defaultQueryDays),
    [today]
  );

  const [{ startDate, endDate }, setQueryParams] = useQueryParams({
    startDate: DateTimeParam,
    endDate: DateTimeParam,
  });

  const nextDateRange: [Date, Date] = useMemo(
    () => [startDate || defaultStartDate, endDate || defaultEndDate],
    [defaultEndDate, defaultStartDate, endDate, startDate]
  );

  const setDateRange = useDebouncedCallback((dateRange: DateRange) => {
    if (!isValid(dateRange[0]) || !isValid(dateRange[1])) {
      return;
    }

    setQueryParams(
      {
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
      updateType
    );
  }, 300);

  return [nextDateRange, setDateRange];
}
