import { format } from "date-fns";
import { AlertType } from "src/models/AlertType";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import {
  AlertConfig,
  AlertConfigMonthly,
  PresetTypeList,
  ScheduledAlertType,
} from "./ScheduledAlertPopover.model";

export enum WeekDays {
  sun = "sun",
  mon = "mon",
  tue = "tue",
  wed = "wed",
  thu = "thu",
  fri = "fri",
  sat = "sat",
}

export enum ActiveGroupKey {
  selectors = "selectors",
  calendar = "calendar",
}

export enum AlertTypesKey {
  weekdayMorning = "weekly-weekdayMorning",
  threeTimes = "daily-threeTimes",
  mondayMorning = "weekly-mondayMorning",
  firstDay = "monthly-firstDay",

  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

export enum AlertScheduleDayKey {
  first = "first",
  second = "second",
  third = "third",
  fourth = "fourth",
  fifth = "fifth",
  last = "last",

  sun = "sun",
  mon = "mon",
  tue = "tue",
  wed = "wed",
  thu = "thu",
  fri = "fri",
  sat = "sat",

  day = "day",
  weekday = "weekday",
  weekend = "weekend",

  each = "each",
}

export const scheduledAlertTypeDict = {
  "Every Weekday Morning": AlertTypesKey.weekly,
  "Three Times Daily": AlertTypesKey.daily,
  "Monday Morning": AlertTypesKey.weekly,
  "First Day of the Month": AlertTypesKey.monthly,
  Hourly: AlertTypesKey.hourly,
  "Custom Every Day": AlertTypesKey.daily,
  "Custom Day of Week": AlertTypesKey.weekly,
  "Custom Monthly": AlertTypesKey.monthly,
} as const;

export const alertTypePresets: PresetTypeList = [
  { label: "Every Weekday Morning", value: AlertTypesKey.weekdayMorning },
  { label: "Three Times Daily", value: AlertTypesKey.threeTimes },
  { label: "Monday Morning", value: AlertTypesKey.mondayMorning },
  { label: "First Day of the Month", value: AlertTypesKey.firstDay },
];

export function formatTimeValue(time: Date) {
  const formatToAmPm = format(time, "p");
  return formatToAmPm.toLocaleLowerCase().split(" ").join("");
}

export function getWeeklyScheduleTime(
  activeDays: Array<keyof typeof WeekDays>,
  alertTime: Date[]
) {
  const index = 0;

  const days = activeDays.join("/");
  const parsedTime = formatTimeValue(new Date(alertTime[index])).split(",");
  const updatedValue = `${parsedTime[index]},${days}`;
  return [`${updatedValue}`];
}

export function getMonthlyScheduleTime(config: AlertConfigMonthly) {
  const index = 0;
  const formattedTime = formatTimeValue(new Date(config.time)).split(",");

  if (config.activeGroup === ActiveGroupKey.selectors) {
    const keys = makeTypedPropList(config);
    const filteredKeys = keys.filter((item) => {
      if (["type", "preset", "time", "activeGroup", "each"].includes(item))
        return false;

      return config[item];
    });
    const updatedValue = `${formattedTime[index]},${filteredKeys.join(" ")}`;
    return [`${updatedValue}`];
  }

  const orderedDays = config.each.sort((a, b) => a - b);
  const updatedValue = orderedDays.map(
    (day) => `${formattedTime[index]},${day}`
  );
  return updatedValue;
}

export function getScheduledAlertType(alert: AlertType): ScheduledAlertType {
  const daysOfTheWeek = makeTypedPropList(WeekDays);

  if (alert.type === AlertTypesKey.hourly) {
    return "Hourly";
  }

  if (alert.type === AlertTypesKey.daily && alert.schedule?.length === 3) {
    return "Three Times Daily";
  }

  if (alert.type === AlertTypesKey.daily) {
    return "Custom Every Day";
  }

  if (
    alert.type === AlertTypesKey.weekly &&
    alert.schedule &&
    alert.schedule[0].includes("mon/tue/wed/thu/fri")
  ) {
    return "Every Weekday Morning";
  }

  if (alert.type === AlertTypesKey.weekly) {
    const defaultTime = "8:00am";
    const weekWithoutMonday = daysOfTheWeek.filter((v) => v !== "mon");
    const containOnlyMonday = weekWithoutMonday.every((v) => {
      return !(alert.schedule || [])[0].includes(v);
    });
    const isTimeNotChanged = (alert.schedule || [])[0].includes(defaultTime);

    return containOnlyMonday && isTimeNotChanged
      ? "Monday Morning"
      : "Custom Day of Week";
  }

  if (
    alert.type === AlertTypesKey.monthly &&
    alert.schedule &&
    alert.schedule[0].includes("first day")
  ) {
    return "First Day of the Month";
  }

  if (alert.type === AlertTypesKey.monthly) {
    return "Custom Monthly";
  }

  return "Hourly";
}

function getTwentyFourHourTime(amPmString: string) {
  var d = new Date("1/1/2023 " + amPmString);
  return d.getHours() + ":" + d.getMinutes();
}

export function preperaMonthlyValue(value?: string[] | null) {
  let schedule: string[] = value || [];
  const haveSeveralValues = schedule.length !== 1;
  if (haveSeveralValues) {
    schedule = [
      schedule.reduce((res, next) => {
        const splittedCurValue = next.split(",");
        return !res ? next : `${res}/${splittedCurValue[1]}`;
      }, ""),
    ];
  }

  return schedule;
}

export function getScheduledAlertRuleData(alert: AlertType): AlertConfig {
  switch (alert.type) {
    case AlertTypesKey.hourly:
      return {
        type: alert.type,
      };

    case AlertTypesKey.daily: {
      const times = (alert.schedule || []).map((alertTime) => {
        const time = alertTime.split(/(pm|am)/)[0];
        const splitedTime = getTwentyFourHourTime(
          alertTime.replace(time, `${time} `)
        ).split(":");
        const now = new Date();
        now.setHours(Number(splitedTime[0]), Number(splitedTime[1]), 0);
        return now;
      });

      return {
        type: AlertTypesKey.daily,
        time: times,
        preset: times.length === 3 ? AlertTypesKey.threeTimes : null,
      };
    }

    case AlertTypesKey.weekly: {
      const schedule = (alert.schedule || [])[0];
      const splitedSchedule = schedule.split(",");
      const time = splitedSchedule[0].split(/(pm|am)/)[0];
      const splitedTime = getTwentyFourHourTime(
        splitedSchedule[0].replace(time, `${time} `)
      ).split(":");
      const times = new Date();
      times.setHours(Number(splitedTime[0]), Number(splitedTime[1]), 0);

      const days = splitedSchedule[1].split("/") as Array<
        keyof typeof WeekDays
      >;
      const weekDayValues = {
        sun: days.includes(AlertScheduleDayKey.sun),
        mon: days.includes(AlertScheduleDayKey.mon),
        tue: days.includes(AlertScheduleDayKey.tue),
        wed: days.includes(AlertScheduleDayKey.wed),
        thu: days.includes(AlertScheduleDayKey.thu),
        fri: days.includes(AlertScheduleDayKey.fri),
        sat: days.includes(AlertScheduleDayKey.sat),
      };

      let preset = null;
      const isWeekdayMorningPreset = splitedSchedule[1].includes(
        "mon/tue/wed/thu/fri"
      );
      if (isWeekdayMorningPreset) {
        preset = AlertTypesKey.weekdayMorning as const;
      }
      const defaultTime = "8:00am";
      const weekWithoutMonday = days.filter((v) => v !== "mon");
      const containOnlyMonday = weekWithoutMonday.every((v) => {
        return !(alert.schedule || [])[0].includes(v);
      });
      const isTimeNotChanged = (alert.schedule || [])[0].includes(defaultTime);
      if (containOnlyMonday && isTimeNotChanged) {
        preset = AlertTypesKey.mondayMorning as const;
      }

      return {
        type: AlertTypesKey.weekly,
        time: times,
        preset,
        ...weekDayValues,
      };
    }

    case AlertTypesKey.monthly: {
      const schedule = preperaMonthlyValue(alert?.schedule)[0];
      const splitedSchedule = schedule.split(",");
      const time = splitedSchedule[0].split(/(pm|am)/)[0];
      const splitedTime = getTwentyFourHourTime(
        splitedSchedule[0].replace(time, `${time} `)
      ).split(":");
      const times = new Date();
      times.setHours(Number(splitedTime[0]), Number(splitedTime[1]), 0);

      const digitRegex = /\d/;
      const anyDigitExist = digitRegex.test(splitedSchedule[1]);

      if (anyDigitExist) {
        const days = splitedSchedule[1].split("/").map((v) => Number(v));
        return {
          type: AlertTypesKey.monthly,
          time: times,
          preset: null,

          activeGroup: ActiveGroupKey.calendar,

          each: days,

          first: true,
          second: false,
          third: false,
          fourth: false,
          fifth: false,
          last: false,

          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          day: true,
          weekday: false,
          weekend: false,
        };
      } else {
        const daySelector = splitedSchedule[1].split(" ") as [string, string];

        return {
          type: AlertTypesKey.monthly,
          time: times,
          preset:
            splitedSchedule[1] === "first day"
              ? (AlertTypesKey.firstDay as const)
              : null,

          activeGroup: ActiveGroupKey.selectors,

          each: [],

          first: daySelector.includes(AlertScheduleDayKey.first),
          second: daySelector.includes(AlertScheduleDayKey.second),
          third: daySelector.includes(AlertScheduleDayKey.third),
          fourth: daySelector.includes(AlertScheduleDayKey.fourth),
          fifth: daySelector.includes(AlertScheduleDayKey.fifth),
          last: daySelector.includes(AlertScheduleDayKey.last),

          sun: daySelector.includes(AlertScheduleDayKey.sun),
          mon: daySelector.includes(AlertScheduleDayKey.mon),
          tue: daySelector.includes(AlertScheduleDayKey.tue),
          wed: daySelector.includes(AlertScheduleDayKey.wed),
          thu: daySelector.includes(AlertScheduleDayKey.thu),
          fri: daySelector.includes(AlertScheduleDayKey.fri),
          sat: daySelector.includes(AlertScheduleDayKey.sat),
          day: daySelector.includes(AlertScheduleDayKey.day),
          weekday: daySelector.includes(AlertScheduleDayKey.weekday),
          weekend: daySelector.includes(AlertScheduleDayKey.weekend),
        };
      }
    }

    default: {
      const times = (alert.schedule || []).map((alertTime) => {
        const time = alertTime.split(/(pm|am)/)[0];
        const splitedTime = getTwentyFourHourTime(
          alertTime.replace(time, `${time} `)
        ).split(":");
        const now = new Date();
        now.setHours(Number(splitedTime[0]), Number(splitedTime[1]), 0);
        return now;
      });

      return {
        type: AlertTypesKey.daily,
        time: times,
        preset: times.length === 3 ? AlertTypesKey.threeTimes : null,
      };
    }
  }
}

export function getDefaultAlertStateData(
  type:
    | AlertTypesKey.hourly
    | AlertTypesKey.daily
    | AlertTypesKey.weekly
    | AlertTypesKey.monthly
    | AlertTypesKey.firstDay
    | AlertTypesKey.threeTimes
    | AlertTypesKey.mondayMorning
    | AlertTypesKey.weekdayMorning
): AlertConfig {
  const defaultTime = new Date();
  defaultTime.setHours(16, 0, 0);

  switch (type) {
    case AlertTypesKey.hourly:
      return {
        type,
      };

    case AlertTypesKey.threeTimes:
    case AlertTypesKey.daily: {
      const firstTime = new Date();
      firstTime.setHours(8, 0, 0);
      const secondTime = new Date();
      secondTime.setHours(13, 0, 0);
      const thirdTime = new Date();
      thirdTime.setHours(18, 0, 0);
      return {
        type: AlertTypesKey.daily,
        time:
          type === AlertTypesKey.daily
            ? [defaultTime]
            : [firstTime, secondTime, thirdTime],
        preset: type === AlertTypesKey.daily ? null : type,
      };
    }

    case AlertTypesKey.weekdayMorning:
    case AlertTypesKey.mondayMorning:
    case AlertTypesKey.weekly: {
      const morningTime = new Date();
      morningTime.setHours(8, 0, 0);

      return {
        type: AlertTypesKey.weekly,
        time: type === AlertTypesKey.weekly ? defaultTime : morningTime,
        preset: type === AlertTypesKey.weekly ? null : type,

        sun: false,
        mon: type !== AlertTypesKey.weekly,
        tue: type === AlertTypesKey.weekdayMorning,
        wed: type !== AlertTypesKey.mondayMorning,
        thu: type === AlertTypesKey.weekdayMorning,
        fri: type === AlertTypesKey.weekdayMorning,
        sat: false,
      };
    }

    case AlertTypesKey.monthly:
    case AlertTypesKey.firstDay: {
      const defaultFirstDayTime = new Date();
      defaultFirstDayTime.setHours(0, 0, 0);

      return {
        type: AlertTypesKey.monthly,
        time:
          type === AlertTypesKey.monthly ? defaultTime : defaultFirstDayTime,
        preset: type === AlertTypesKey.monthly ? null : type,

        activeGroup: ActiveGroupKey.selectors,

        each: [],

        first: true,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        last: false,

        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        day: true,
        weekday: false,
        weekend: false,
      };
    }

    default:
      return {
        type: AlertTypesKey.daily,
        time: [defaultTime],
        preset: null,
      };
  }
}

export const isAlertConfigType = (
  value: string
): value is AlertConfig["type"] => {
  const values: string[] = Object.values(AlertTypesKey);
  return values.includes(value);
};
