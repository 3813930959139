import { queryAdvertisementValues } from "src/models/Advertisement";
import { MediaCenterFilterFormValues } from "src/pages/MediaCenter/components/MediaCenterFilterForm/MediaCenterFilterForm.model";

export function countMediaCenterFilters(
  data?: MediaCenterFilterFormValues
): number {
  const {
    tags,
    sourcesInclude,
    programInclude,
    programExclude,
    advertisement,
  } = data || {};

  const totalFilters = [
    tags?.length ?? 0,
    sourcesInclude?.list.length ?? 0,
    programInclude?.list.length ?? 0,
    programExclude?.list.length ?? 0,
    advertisement !== queryAdvertisementValues.all ? 1 : 0,
  ].reduce((acc = 0, filterCount = 0) => {
    acc += filterCount ? 1 : 0;
    return acc;
  }, 0);

  return totalFilters;
}
