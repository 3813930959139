import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { reportsRoute } from "src/pages/Reports/Reports.route";

export function ReportCreateActionButtonsMobile({
  disabled,
  isLoading,
  saveReport,
}: {
  disabled: boolean;
  isLoading: boolean;
  saveReport: () => void;
}) {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        px: 2,
        py: 3,
      }}
      spacing={2}
    >
      <Button
        variant="outlined"
        color="primary"
        sx={{ height: 42, flex: 1 }}
        onClick={() => navigate(reportsRoute.path)}
      >
        Cancel
      </Button>
      <LoadingButton
        loading={isLoading}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        sx={{ height: 42, flex: 1 }}
        onClick={saveReport}
      >
        Save Report
      </LoadingButton>
    </Stack>
  );
}
