import { SyntheticEvent } from "react";
import { Stack, Tooltip, useTheme, Chip } from "@mui/material";
import InsertLink from "@mui/icons-material/InsertLink";
import Cached from "@mui/icons-material/Cached";
import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getFullReportDetailsLink } from "src/utils/getFullPublicPageLink";
import { useCopyReportLinkSnackbar } from "src/api/useCopyReportLinkSnackbar";

export const ReportLink = ({
  reportId,
  reportStatus,
}: {
  reportId: string;
  reportStatus: string | null;
}) => {
  const { palette } = useTheme();
  const copyReportLinkSnackBar = useCopyReportLinkSnackbar();

  const fullReportDetailsLink = getFullReportDetailsLink(reportId);

  const copyLinkToClipboard = async (event: SyntheticEvent) => {
    event.stopPropagation();
    if (!navigator.clipboard) {
      console.error("browser does not support navigation.clipboard");
    }
    await navigator.clipboard.writeText(fullReportDetailsLink);
    copyReportLinkSnackBar.show();
  };

  if (reportStatus !== "ready") {
    return (
      <Chip
        label={reportStatus === "loading" ? "Loading..." : "Generating..."}
        icon={<Cached color="disabled" />}
        variant="outlined"
        size="small"
        sx={{
          color: palette.text.disabled,
        }}
      />
    );
  }

  return (
    <Stack direction="row" columnGap={1} alignItems="center">
      <Tooltip title="Copy report link">
        <InsertLink
          sx={{ color: palette.text.secondary, cursor: "pointer" }}
          onClick={copyLinkToClipboard}
        />
      </Tooltip>
      <Tooltip title={fullReportDetailsLink}>
        <AppLink to={publicReportRoute.makeUrl({ reportId })}>
          <TextLineClamp
            lineClamp={1}
            color="primary"
            wordBreak="break-all"
            fontSize={14}
          >
            Instant link
          </TextLineClamp>
        </AppLink>
      </Tooltip>
    </Stack>
  );
};
