import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  emptySharedValues,
  WatchTermSharedValues,
  useWatchTermSharedForm,
} from "src/api/useWatchTermSharedForm";
import { convertToCustomQuery } from "src/utils/convertToCustomQuery";
import { WatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { emptyWatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.hook";

type UseWatchQueryFormArgs = {
  initial?: WatchQueryKeywordFormValues;
  schema: Joi.Schema;
};

export function useWatchListCreateKeywordForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<WatchQueryKeywordFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: initial || emptyWatchQueryKeywordFormValues,
  });

  const { formState, watch, setValue } = formHook;
  const { dirtyFields } = formState;

  const {
    saveSharedValue,
    saveCustomQueryFromKeyword,
    saveDisplayNameFromKeyword,
  } = useWatchTermSharedForm();
  const sharedValues = Object.keys(emptySharedValues);

  const formValues = watch();

  const createCustomQueryFromKeyword = useCallback(() => {
    const customQueryString = convertToCustomQuery({
      termsInclude: formValues.termsInclude,
      termsExclude: formValues.termsExclude,
    });
    saveCustomQueryFromKeyword(customQueryString);
    saveDisplayNameFromKeyword(formValues.displayName);
  }, [
    formValues.termsInclude,
    formValues.termsExclude,
    formValues.displayName,
    saveCustomQueryFromKeyword,
    saveDisplayNameFromKeyword,
  ]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "termsInclude" &&
        !dirtyFields.displayName
      ) {
        const updatedValue = value.termsInclude?.list?.join(", ") || "";
        setValue("displayName", updatedValue);
      }

      if (
        type === "change" &&
        sharedValues.includes(name as keyof WatchTermSharedValues)
      ) {
        const itemValue = value[name as keyof WatchTermSharedValues];
        saveSharedValue(
          name as keyof WatchTermSharedValues,
          itemValue as WatchTermSharedValues[keyof WatchTermSharedValues]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, dirtyFields, saveSharedValue, sharedValues]);

  return {
    ...formHook,
    createCustomQueryFromKeyword,
  };
}
