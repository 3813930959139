import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicReportPage } from "./PublicReport.page";

export type PublicReportPathParams = {
  reportId: string;
};

export const publicReportRoute = new AppRouteBuilder<PublicReportPathParams>({
  path: "/public/report/:reportId",
  element: <PublicReportPage />,
});
