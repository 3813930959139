import TuneIcon from "@mui/icons-material/Tune";

import {
  Badge,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Tooltip,
} from "@mui/material";
import { WatchQueryFilterFormValues } from "src/components/WatchQueryFilterForm/WatchQueryFilterForm.model";
import { countQueryFilters } from "src/utils/countQueryFilters";

export function AnalyticsLiteFilterButton({
  size,
  value,
  onClick,
  ...props
}: {
  title?: string;
  size?: "medium" | "small";
  value?: WatchQueryFilterFormValues;
  onClick?: ToggleButtonProps["onClick"];
}) {
  const badgeContent = countQueryFilters(value);
  const title = props.title || "Apply filters";

  return (
    <Tooltip title={title}>
      <Badge color="primary" badgeContent={badgeContent}>
        <ToggleButtonGroup size={size}>
          <ToggleButton value={true} onClick={onClick}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Tooltip>
  );
}
