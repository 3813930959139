import { Add, SearchOutlined } from "@mui/icons-material";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePowerSearchQuery } from "src/api/usePowerSearchQuery";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { WatchQueryKeywordFormValues } from "../WatchQueryKeywordForm/WatchQueryKeywordForm.model";
import { LoadingButton } from "../buttons/LoadingButton";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { WatchTermInput } from "../WatchTermInput/WatchTermInput";
import { TextInputBase } from "../TextInputBase";

export function WatchQueryFormSimple({
  displayTitlePrompt,
  setDisplayTitlePrompt,
}: {
  displayTitlePrompt?: boolean;
  setDisplayTitlePrompt?: Dispatch<SetStateAction<boolean>>;
}) {
  const { breakpoints } = useTheme();
  const [isTitlePromptOpen, setTitlePromptOpen] = useState(false);
  const [prevDisplayName, setPrevDisplayName] = useState("");
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSuccess: () => {
        if (setDisplayTitlePrompt) {
          setDisplayTitlePrompt(false);
        }
      },
    },
  });

  const powerSearchQuery = usePowerSearchQuery();

  const { handleSubmit, control, formState, watch, resetField } =
    useFormContext<WatchQueryKeywordFormValues>();

  const { displayName } = watch();

  const isBusy = formState.isSubmitting || watchQueryCreate.isLoading;

  const showWatchQueryTitleDialog = () => {
    setPrevDisplayName(displayName);
    if (setDisplayTitlePrompt) {
      setDisplayTitlePrompt(true);
    } else {
      setTitlePromptOpen(true);
    }
  };

  const hideWatchQueryTitleDialog = () => {
    if (setDisplayTitlePrompt) {
      setDisplayTitlePrompt(false);
    } else {
      setTitlePromptOpen(false);
    }
  };

  const handleWatchTermCreateDecline = () => {
    hideWatchQueryTitleDialog();
    resetField("displayName", { defaultValue: prevDisplayName });
  };

  const handleWatchTermCreateConfirm = handleSubmit(
    (data: WatchQueryKeywordFormValues) => {
      const query = makeQueryDefinition(data);
      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    }
  );

  const handlePowerSearch = (data: WatchQueryKeywordFormValues) => {
    powerSearchQuery.save(data);

    navigate(powerSearchResultRoute.path);
  };

  const watchTermIncludeRow = (
    <Grid item xs={12} md={6} lg={4}>
      <Controller
        name="termsInclude"
        control={control}
        render={({ field, fieldState, formState }) => {
          const { list, logic } = formState.errors[field.name] || {};
          const message = list?.message || logic?.message || " ";

          return (
            <WatchTermInput
              id="watch-term-input"
              label="Keyword"
              value={field.value}
              options={[]} // no suggestions for now
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={message}
              displayTooltip
            />
          );
        }}
      />
    </Grid>
  );

  const sourcesRow = (
    <Grid item xs={12} md={6} lg={4}>
      <Controller
        name="sourcesInclude"
        control={control}
        render={({ field, fieldState }) => {
          const defaultLabel = field.value.list.length
            ? "Source"
            : "All Sources";

          return (
            <SourcesPicker
              id="source-picker"
              label={defaultLabel}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={isBusy}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || " "}
              dropDownBoundsEl={containerRef}
            />
          );
        }}
      />
    </Grid>
  );

  return (
    <form>
      <div ref={containerRef} style={{ width: "100%" }} />
      <Grid
        container
        rowSpacing={isMobile ? 1.5 : 3}
        columnSpacing={{ xs: 2, xl: 3 }}
      >
        {watchTermIncludeRow}
        {sourcesRow}

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          display="flex"
          columnGap={{ xs: 2, xl: 3 }}
        >
          <LoadingButton
            variant="contained"
            color="info"
            id="add-watch-term"
            sx={{
              height: 56,
              width: { xs: 160, md: "50%", xl: 176 },
              whiteSpace: "nowrap",
            }}
            disabled={isBusy || !formState.isValid}
            onClick={showWatchQueryTitleDialog}
            loading={isBusy}
            startIcon={<Add />}
            children="Watch term"
          />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              height: 56,
              width: { xs: 160, md: "50%", xl: 176 },
              whiteSpace: "nowrap",
            }}
            disabled={isBusy || !formState.isValid}
            loading={isBusy}
            startIcon={<SearchOutlined />}
            onClick={handleSubmit(handlePowerSearch)}
            children="Search"
          />
        </Grid>
      </Grid>

      <DialogPrompt
        title="Save to Watchlist"
        open={isTitlePromptOpen || !!displayTitlePrompt}
        onDecline={handleWatchTermCreateDecline}
        onConfirm={handleWatchTermCreateConfirm}
        confirmLabel="Ok"
        confirmDisabled={isBusy || !formState.isValid}
        children={
          <Controller
            name="displayName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Box pt={1}>
                  <TextInputBase
                    label="Display name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                </Box>
              );
            }}
          />
        }
      />
    </form>
  );
}
