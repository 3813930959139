import { SnackbarBase } from "../SnackbarBase/SnackbarBase";

type DownloadFilesSnackbarProps = {
  open: boolean;
  onClose: () => void;
};

export const DownloadFilesSnackbar = ({
  open,
  onClose,
}: DownloadFilesSnackbarProps) => {
  return (
    <SnackbarBase
      open={open}
      onClose={onClose}
      title="Files downloading was successfully started."
      subtitle="It may take some time, depending on the amount of items."
    />
  );
};
