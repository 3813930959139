import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortAZ = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M14.9396 4.66078H10.2196L12.5796 2.30078L14.9396 4.66078ZM10.2496 19.3708H14.9096L12.5796 21.7008L10.2496 19.3708ZM6.09961 6.27078L1.59961 17.7308H3.43961L4.35961 15.2808H9.46961L10.3896 17.7308H12.2296L7.73961 6.27078H6.09961ZM4.96961 13.6408L6.90961 8.46078L8.84961 13.6408H4.96961ZM15.7296 16.1408H21.8496V17.7308H13.3196V16.4408L19.2396 7.88078H13.3596V6.28078H21.6596V7.54078L15.7296 16.1408Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
