import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Autocomplete } from "@mui/material";
import {
  UsersManagementResponse,
  defaultUMBody,
  useUsersAutocomplete,
} from "src/api/useUsersAutocomplete";
import { useUsersData } from "src/api/useUsers";
import {
  AccountAutocompleteProps,
  OptionType,
} from "./UsersAutocomplete.model";
import { TextInputBase } from "../TextInputBase";

function buildOption(value: string, options: Array<OptionType>) {
  if (!value || !options.length) return null;

  return options.find((option) => option.value === value) ?? null;
}

function buildOptions(
  value: Array<string> | string,
  options: Array<OptionType>
) {
  if (!Array.isArray(value)) {
    return buildOption(value, options);
  }

  return value.reduce((res: Array<OptionType>, next) => {
    const value = buildOption(next, options);
    if (value) {
      res.push(value);
    }

    return res;
  }, []);
}

function buildAccountOptions(data?: UsersManagementResponse) {
  if (!data) return [];

  const { results = [] } = data;
  return results.map((result) => ({
    label: result.email ?? "",
    value: result?.id ?? "",
  }));
}

export const UsersAutocomplete = ({
  onChange,
  onBlur,
  disabled,
  multiple,
  value,
  roles = [],
}: AccountAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");

  const [searchText] = useDebounce(inputValue, 500);

  //Get total users value for autocomplete request size
  const { data: usersData } = useUsersData({
    request: {
      params: {
        query: {
          from: 0,
          size: 1,
        },
      },
    },
  });

  //Store all available options to avoid loosing options/selected values on query search
  const { data: allData } = useUsersAutocomplete({
    request: {
      params: {
        query: {
          from: 0,
          size: usersData?.total,
        },
      },
      body: {
        ...defaultUMBody,
        search: "",
        roles,
      },
    },
  });

  const { data } = useUsersAutocomplete({
    request: {
      params: {
        query: {
          from: 0,
          size: 20,
        },
      },
      body: {
        ...defaultUMBody,
        search: searchText,
        roles,
      },
    },
  });

  const allOptions = buildAccountOptions(allData);
  const queryOptions = buildAccountOptions(data);

  return (
    <Autocomplete
      value={buildOptions(value, allOptions)}
      id="accountManager"
      multiple={multiple}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (!event) return;
        setInputValue(newInputValue);
      }}
      onChange={(_e, selectedOption) => {
        if (Array.isArray(selectedOption)) {
          const value = selectedOption.map((option) => option.value) ?? [];
          return onChange(value);
        }
        const value = selectedOption?.value ?? null;
        onChange(value);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      onBlur={onBlur}
      options={queryOptions}
      getOptionLabel={(item) => item.label}
      //Ignore MUI warning - open issue for async search https://github.com/mui/material-ui/issues/29727
      isOptionEqualToValue={(option, currentValue) =>
        option.value === currentValue.value || currentValue.value === ""
      }
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <TextInputBase {...params} label="Email" placeholder="Email" />
      )}
    />
  );
};
