import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { useIsDemoMode } from "src/api/useIsDemoMode";
import { useBreakpointName } from "src/utils/useBreakpointName";
import { useElementSize } from "src/utils/useElementSize";

export function DebugOverlay() {
  const containerRef = useRef(null);
  const breakpointName = useBreakpointName();
  const size = useElementSize(containerRef);
  const isDemoMode = useIsDemoMode();

  if (process.env.NODE_ENV === "production") {
    return null;
  }

  if (isDemoMode) return null;

  return (
    <Box
      ref={containerRef}
      display="flex"
      flexDirection="column"
      alignItems="center"
      children={
        <Typography
          textAlign="center"
          pl={1}
          pr={1}
          children={`${size?.width}px ${breakpointName}`}
          sx={{
            background: "rgba(255, 0, 255, 0.5)",
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        />
      }
      sx={{
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: "none",
      }}
    />
  );
}
