import { useMemo } from "react";
import {
  Accordion,
  Typography,
  useTheme,
  alpha,
  AccordionSummary,
  IconButton,
  FormHelperText,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { blue, green, purple } from "@mui/material/colors";

export function CustomQueryHighlightedText({
  value,
  error,
  helperText,
  onClick,
}: {
  value?: string;
  error?: boolean;
  helperText?: string;
  onClick: () => void;
}) {
  const { palette } = useTheme();

  type ColorMap = {
    [key: string]: string;
  };

  const colorMap: ColorMap = useMemo(() => {
    return {
      AND: green["500"],
      OR: blue["500"],
      NOT: palette.warning.main,
      "~": purple["A200"],
    };
  }, [palette.warning.main]);
  const words = value?.split(/(\s+)/);

  const highlightWord = (word: string) => {
    const color = colorMap[word];
    return color ? (
      <Typography component={"span"} style={{ color }}>
        {word}
      </Typography>
    ) : (
      word
    );
  };

  return (
    <>
      <Accordion
        sx={{
          height: 56,
          background: alpha(palette.action.hover, 0.04),
          border: `1px solid ${
            error ? palette.error.main : palette.action.disabled
          }`,
          borderRadius: "4px",
        }}
      >
        <AccordionSummary
          onClick={onClick}
          sx={{ height: 56 }}
          expandIcon={
            <IconButton
              size="large"
              aria-label="show custom query"
              onClick={onClick}
              edge="end"
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
            }}
          >
            {words?.map((word, index) => (
              <Typography component={"span"} key={index}>
                {highlightWord(word)}
              </Typography>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>
      <FormHelperText>
        <Typography variant="caption" color="error" m={"3px 14px 0"}>
          {helperText}
        </Typography>
      </FormHelperText>
    </>
  );
}
