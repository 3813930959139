import { HubConnection } from "@microsoft/signalr";
import EventEmitter from "events";
import { useCallback, useEffect, useMemo } from "react";
import { InProductNotification } from "src/models/InProductNotification";
import { NotificationType } from "src/models/NotificationType";

type EmitterEventMap = {
  [k in NotificationType]: [InProductNotification];
};

export class PushNotificationEventEmitter extends EventEmitter<EmitterEventMap> {
  // for a typing purposes
}

const notificationKey = "NotificationReceived";
const messageKey = "MessageReceived";

export function usePushNotificationEmitter({
  connection,
}: {
  connection: HubConnection;
}) {
  const emitter = useMemo(() => new PushNotificationEventEmitter(), []);

  const handleIncomingMessage = useCallback(
    (data: InProductNotification) => {
      if (!data.notificationType) {
        // required because all types are optional
        return;
      }

      console.log(`Push Notification: received ${data.notificationType}`);
      emitter.emit(data.notificationType, data);
    },
    [emitter]
  );

  useEffect(() => {
    // subscribe to events
    connection.on(notificationKey, handleIncomingMessage);
    connection.on(messageKey, handleIncomingMessage);

    return () => {
      // unsubscribe from events
      connection.off(notificationKey, handleIncomingMessage);
      connection.off(messageKey, handleIncomingMessage);
    };
  }, [connection, handleIncomingMessage]);

  return emitter;
}
