import { Fragment } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Home from "@mui/icons-material/Home";
import { LogoutOutlined } from "@mui/icons-material";
import DesktopMacOutlined from "@mui/icons-material/DesktopMacOutlined";
import { useNavigate } from "react-router-dom";
import { Logo } from "src/components/logo/Logo";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import HeroImage from "./hero-swirl.png";
import { useAuth0 } from "@auth0/auth0-react";

export const PageNotFoundPage = ({
  isUnregistered = false,
}: {
  isUnregistered?: boolean;
}): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const toSiteButton = (
    <Button
      href="https://www.tveyes.com/"
      startIcon={<Home />}
      variant="contained"
      color="info"
      size="large"
      children="To Website"
      sx={{
        width: isMobile ? "100%" : "169px",
        color: palette.text.primary,
        borderRadius: "20px",
      }}
    />
  );

  const toWatchListButton = (
    <Button
      startIcon={<DesktopMacOutlined />}
      variant="outlined"
      color="primary"
      size="large"
      children="To Watchlist"
      sx={{
        width: isMobile ? "100%" : "169px",
        borderRadius: "20px",
        color: palette.common.white,
        borderColor: palette.common.white,
      }}
      onClick={() => {
        console.log(watchListRoute.path, "NAV");
        navigate(watchListRoute.path);
      }}
    />
  );

  const logoutButton = (
    <Button
      startIcon={<LogoutOutlined />}
      onClick={handleLogout}
      variant="contained"
      color="secondary"
      size="large"
      sx={{
        width: isMobile ? "100%" : "169px",
        borderRadius: "20px",
        color: palette.text.primary,
        borderColor: palette.common.white,
      }}
      children="Log Out"
    />
  );

  const mobileContent = (
    <Stack
      direction="column"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        width="100%"
        gap={3}
        sx={{
          alignItems: "center",
          px: 2,
          pt: 2,
        }}
      >
        <Logo dark />
        <Typography
          sx={{
            fontSize: 140,
            lineHeight: "140px",
            color: palette.primary.light,
            my: 1,
          }}
        >
          404
        </Typography>
        <Typography variant="h4" sx={{ color: palette.common.white }}>
          Page Not Found
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          width={300}
          sx={{ color: palette.common.white }}
        >
          We`re sorry, the page you requested could not be found. Please go back
          to the homepage.
        </Typography>
        {/* zIndex is needed on the small height of the screen when buttons are overlapped with the image */}
        <Stack direction="column" gap={2} width="100%" zIndex={1}>
          {toSiteButton}
          {!isUnregistered && toWatchListButton}
          {user && logoutButton}
        </Stack>
      </Stack>
      <Box
        component="img"
        alt="Wave"
        src={HeroImage}
        sx={{
          width: "100%",
          scale: "1.5",
          mb: 4,
        }}
      />
    </Stack>
  );

  const desktopContent = (
    <Fragment>
      <Stack
        direction="row"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="space-around"
        alignSelf="center"
        pb="10%"
        px={4}
      >
        <Stack gap={4}>
          <Logo dark />
          <Typography
            variant="h3"
            fontSize={44}
            fontWeight={300}
            sx={{ color: palette.common.white }}
          >
            Page Not Found
          </Typography>
          <Typography
            variant="body1"
            maxWidth={463}
            sx={{ color: palette.common.white }}
          >
            We`re sorry, the page you requested could not be found. Please go
            back to the homepage.
          </Typography>
          {/* zIndex is needed on the small height of the screen when buttons are overlapped with the image */}
          <Stack direction={{ sm: "column", md: "row" }} gap={2} zIndex={1}>
            {toSiteButton}
            {!isUnregistered && toWatchListButton}
            {user && logoutButton}
          </Stack>
        </Stack>
        <Typography
          sx={{
            fontSize: { sm: 140, lg: 280 },
            lineHeight: "280px",
            color: palette.primary.light,
          }}
        >
          404
        </Typography>
      </Stack>
      <Box
        component="img"
        alt="Wave"
        src={HeroImage}
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          transform: "translateY(35%)",
        }}
      />
    </Fragment>
  );

  return (
    <Stack
      sx={{
        backgroundColor: palette.background.brand,
        position: "relative",
        overflow: "hidden",
        flex: 1,
      }}
    >
      {isMobile ? mobileContent : desktopContent}
    </Stack>
  );
};
