import { Stack, Typography } from "@mui/material";
import { MapBoxWrapper } from "src/components/MapBoxWrapper/MapBoxWrapper";

export function DevMap() {
  return (
    <Stack p={2} gap={2}>
      <Typography>AppMapBox</Typography>

      <MapBoxWrapper
        border="1px solid gray"
        width="100%"
        height={400}
        dataLevel="Regional"
      />
    </Stack>
  );
}
