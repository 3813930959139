import React, { PropsWithChildren, useEffect, useState } from "react";
import { autocompleteClasses, Stack } from "@mui/material";
import { useParentBgColor } from "src/utils/useParentBgColor";
import { useCustomScrollBarStyles } from "src/utils/useCustomScrollBarStyles";

export type AutocompleteInlineTagContainerProps = PropsWithChildren<{
  scrollToLast?: boolean;
  size: "small" | "medium";
}>;

export const AutocompleteInlineTagContainer = ({
  scrollToLast,
  size,
  ...props
}: AutocompleteInlineTagContainerProps) => {
  const scrollStylesSx = useCustomScrollBarStyles({
    overflowX: "scroll",
    overflowY: "hidden",
    size: "thin",
  });

  const [scrollContainerEl, setScrollContainerEl] =
    useState<HTMLDivElement | null>(null);
  const [lastTagElement, setLastTagElement] = useState<HTMLDivElement | null>(
    null
  );

  useEffect(() => {
    if (!scrollToLast) {
      return;
    }

    scrollContainerEl?.scrollTo({ left: lastTagElement?.offsetLeft });
  }, [scrollContainerEl, lastTagElement, scrollToLast]);

  const total = React.Children.count(props.children);
  const tagElements = React.Children.map(props.children, (child, i) => {
    if (i === total - 1) {
      return <div ref={(ref) => setLastTagElement(ref)}>{child}</div>;
    }

    return child;
  });

  const gradientEndColor = useParentBgColor(scrollContainerEl);

  return (
    <Stack
      ref={setScrollContainerEl}
      direction="row"
      sx={{
        flex: 1,
        position: "relative",
        // this is required to compensate vertical alignment with custom scroll bars
        pt: size === "medium" ? "5px" : "8px",
        pb: size === "medium" ? "0px" : "2px",

        [`.${autocompleteClasses.tag}`]: {
          // chips take width of content because of horizontal scrolling
          minWidth: "max-content",
        },
        [`&:after`]: {
          // this is gradient at the right edge of the chip container
          content: "''",
          display: "block",
          position: "sticky",
          right: 0,
          minWidth: 24,
          pointerEvents: "none",
          background: `linear-gradient(90deg, transparent, ${gradientEndColor})`,
        },
        ...scrollStylesSx,
      }}
    >
      {tagElements}
    </Stack>
  );
};
