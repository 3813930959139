import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { userBannerKey } from "./useBanner";

const apiPath: keyof paths = "/api/banner/user/{userId}";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"][201];
type RequestBody = FormData;

type RequestMutationFnParams = {
  query: Endpoint["parameters"]["query"];
  bannerImage: FormData;
  params: Endpoint["parameters"];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

const bannerCreateKey = "bannerCreateKey";

export function useBannerCreate({ options }: { options?: RequestOptions }) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [bannerCreateKey],
    mutationFn: async ({ query, bannerImage, params }) => {
      const { userId } = params.path;

      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        query,
        path: {
          userId: userId,
        },
      });
      const body: RequestBody = bannerImage;

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: body,
      });
      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: userBannerKey,
      });
      enqueueSnackbar(t("bannerCreate", "Banner saved"), {
        variant: "success",
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
