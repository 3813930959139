import { differenceInMilliseconds, format, isValid } from "date-fns";
import { Fragment, useCallback } from "react";
import { Stack, Chip, Tooltip, Fade, Button } from "@mui/material";
import { TranscriptLineDTO } from "src/models/TranscriptLine";
import { useOpenState } from "src/utils/useOpenState";
import { TranscriptLineBundleViewProps } from "./TranscriptLineBundleView.model";
import { TranscriptLineView } from "../TranscriptLineView/TranscriptLineView";
import {
  TranscriptLineBundleViewLabelLayout,
  transcriptLineBundleViewClasses,
} from "./TranscriptLineBundleView.const";

export function TranscriptLineBundleView({
  bundle,
  showTimeLabel,
  showTrimToolBar,
  mentionBgColor,
  mentionFontColor,
  onClickLineBlock,
  onClickLabel,
  onClickSetStart,
  onClickSetEnd,
  variant,
  ...props
}: TranscriptLineBundleViewProps) {
  const hoverState = useOpenState();

  const renderTranscriptLineView = useCallback(
    (transcriptLine: TranscriptLineDTO, index: number) => {
      if (
        transcriptLine.offsetMs === undefined ||
        transcriptLine.lineText === undefined ||
        transcriptLine.lineDateTime === undefined
      ) {
        return <span key={`${index}:invalid`}>&nbsp;</span>;
      }

      return (
        <Fragment key={`${index}:offset=${transcriptLine.offsetMs}`}>
          <TranscriptLineView
            variant={variant}
            text={transcriptLine.lineText}
            offsetMs={transcriptLine.offsetMs}
            onClick={onClickLineBlock}
            mentionBgColor={mentionBgColor}
            mentionFontColor={mentionFontColor}
          />
          &nbsp;
        </Fragment>
      );
    },
    [onClickLineBlock, mentionBgColor, mentionFontColor, variant]
  );

  const baseDateTime = new Date(props.baseTime || "");
  if (!isValid(baseDateTime)) {
    console.error("baseTime is invalid");
    return null;
  }

  const bundleStartDateTime = new Date(bundle.bundleStartDateTime || "");
  if (bundleStartDateTime === undefined) {
    console.error("bundleStartDateTime is not defined");
    return null;
  }

  const bundleEndDateTime = new Date(bundle.bundleEndDateTime || "");
  if (bundleEndDateTime === undefined) {
    console.error("bundleEndDateTime is not defined");
    return null;
  }

  const offsetMs = differenceInMilliseconds(bundleStartDateTime, baseDateTime);

  const blockDateTime = new Date(bundleStartDateTime);
  const blockTimeLabel = format(blockDateTime, "pp");
  const titleLabel = `Offset ${offsetMs / 1000} seconds`;

  const labelChip = !!showTimeLabel && (
    <Tooltip title={titleLabel}>
      <Chip
        label={blockTimeLabel}
        size="small"
        sx={{ minWidth: 80 }}
        onClick={() => onClickLabel && onClickLabel(offsetMs)}
      />
    </Tooltip>
  );

  const trimStartButton = onClickSetStart && (
    <Button
      children="Set Start"
      size="small"
      color="success"
      variant="contained"
      onClick={onClickSetStart}
      sx={{ minWidth: 80 }}
    />
  );

  const trimEndButton = onClickSetEnd && (
    <Button
      children="Set End"
      size="small"
      color="error"
      variant="contained"
      onClick={onClickSetEnd}
      sx={{ minWidth: 80 }}
    />
  );

  const trimButtonHBar = showTrimToolBar && (
    <Fade in={showTrimToolBar === "always" || hoverState.isOpen}>
      <Stack direction="row" alignItems="center" columnGap={1} ml="auto">
        {trimStartButton}
        {trimEndButton}
      </Stack>
    </Fade>
  );

  const trimButtonVBar = showTrimToolBar && (
    <Fade in={showTrimToolBar === "always" || hoverState.isOpen}>
      <Stack direction="column" alignItems="center" rowGap={1} ml="auto">
        {trimStartButton}
        {trimEndButton}
      </Stack>
    </Fade>
  );

  const controls = showTimeLabel ? (
    showTimeLabel === TranscriptLineBundleViewLabelLayout.left ? (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        rowGap={2}
      >
        {labelChip}
        {trimButtonVBar}
      </Stack>
    ) : (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={2}
      >
        {labelChip}
        {trimButtonHBar}
      </Stack>
    )
  ) : null;

  return (
    <Stack
      id={`${offsetMs}`}
      rowGap={1}
      columnGap={2}
      direction={
        showTimeLabel === TranscriptLineBundleViewLabelLayout.left
          ? "row"
          : "column"
      }
      onMouseEnter={hoverState.show}
      onMouseLeave={hoverState.hide}
      className={transcriptLineBundleViewClasses.root}
    >
      {controls}
      <Stack display="inline">
        {bundle.transcriptLines?.map(renderTranscriptLineView)}
      </Stack>
    </Stack>
  );
}
