import { AppRouteBuilder } from "src/models/AppRoute";
import { WatchListTermResultClipEditorPage } from "./WatchListTermResultClipEditor.page";

export type WatchListTermResultClipEditorPathParam = {
  queryId: string;
  eventId: string;
};

export const watchListTermResultClipEditorRoute =
  new AppRouteBuilder<WatchListTermResultClipEditorPathParam>({
    path: "/watchlist/:queryId/:eventId/edit",
    element: <WatchListTermResultClipEditorPage />,
  });
