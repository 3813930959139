import { useState } from "react";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { OriginType } from "src/api/useWatchQueryAlertCreate";
import { AlertType } from "src/models/AlertType";
import { ScheduledAlertMobileCreate } from "./ScheduledAlertMobileCreate/ScheduledAlertMobileCreate";
import { ScheduledAlertMobileView } from "./ScheduledAlertMobileView/ScheduledAlertMobileView";
import { alertTypePresets } from "../ScheduledAlertPopover/ScheduledAlertPopover.utils";

type ScheduledAlertMobileProps = {
  queryId?: string;
  title: string;
  open: boolean;
  alerts: UserQueryDTO["alerts"];
  origin: OriginType;
  editedRule?: AlertType | null;
  onSuccessEditRule?: (rule?: AlertType | null) => void;
  onClose: () => void;
  scheduledAlertCreateHandler?: (
    newValue: AlertType | AlertType[],
    action?: "delete" | "edit"
  ) => void;
};

export const ScheduledAlertMobile = ({
  queryId,
  title,
  open,
  alerts,
  origin,
  editedRule,
  onSuccessEditRule,
  onClose,
  scheduledAlertCreateHandler,
}: ScheduledAlertMobileProps) => {
  const scheduledAlerts = alerts.filter((alert) => alert.type !== "realtime");
  const [sheduledAlertArchiveEnabled, setSheduledAlertArchiveEnabled] =
    useState(false);

  const openCreateModal =
    open && (scheduledAlerts.length === 0 || Boolean(editedRule));

  return (
    <>
      <ScheduledAlertMobileCreate
        sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
        setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        queryId={queryId}
        presets={alertTypePresets}
        open={openCreateModal}
        onClose={onClose}
        origin={origin}
        alerts={alerts}
        editedRule={editedRule}
        onSuccessEditRule={onSuccessEditRule}
        scheduledAlertCreateHandler={scheduledAlertCreateHandler}
      />
      <ScheduledAlertMobileView
        sheduledAlertArchiveEnabled={sheduledAlertArchiveEnabled}
        setSheduledAlertArchiveEnabled={setSheduledAlertArchiveEnabled}
        open={open && scheduledAlerts.length !== 0 && !editedRule}
        title={`${title} alert`}
        queryId={queryId}
        origin={origin}
        alerts={alerts}
        onClose={onClose}
        scheduledAlertCreateHandler={scheduledAlertCreateHandler}
      />
    </>
  );
};
