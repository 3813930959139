import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { QueryDefinition } from "src/models/QueryDefinition";
import { useLocalStorageFlags } from "src/utils/useLocalStorageFlags";

const apiKey: keyof paths = `/api/Event/{eventId}`;
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
  query: QueryDefinition;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useEventCreate({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const flags = useLocalStorageFlags();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ params, query }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        ...params,
        query: {
          extend: flags.isExtendedEvent,
        },
      });

      const body: RequestBody = {
        queryDefinition: query,
      };

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return {
        id: crypto.randomUUID(),
        ...data,
      };
    },
  });
}
