import { Stack, StackProps } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function BCastViewership({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  const {
    localAdValue,
    nationalAudience,
    nationalAdValue,
    totalAudience,
    localAudience,
  } = event.audience || {};
  const AdValueUnits = "$";

  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Audience information"
          value={totalAudience ? totalAudience?.toLocaleString() : "-"}
        />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Local Market Viewership"
          value={localAudience ? localAudience?.toLocaleString() : "-"}
        />
        <EventDetailsDataBlock
          title="Local Market AdValue"
          value={
            localAdValue
              ? `${localAdValue?.toLocaleString()}${AdValueUnits}`
              : "-"
          }
        />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="National Viewership"
          value={nationalAudience ? nationalAudience.toLocaleString() : "-"}
        />
        <EventDetailsDataBlock
          title="National AdValue"
          value={
            nationalAdValue
              ? `${nationalAdValue?.toLocaleString()}${AdValueUnits}`
              : "-"
          }
        />
      </Stack>
    </Stack>
  );
}
