import { useState, ReactNode } from "react";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  PopoverProps,
  Popper,
  PopperProps,
  SxProps,
  useTheme,
} from "@mui/material";

/**
 * Please replace this with src/components/AppPopper
 * @deprecated
 */
export const PopperBase = ({
  anchorEl,
  onClose,
  children,
  sx,
  arrowSx,
  elevation = 10,
  borderRadius = "8px",
  popperOffset = [],
  ...props
}: PopperProps & {
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
  children: ReactNode;
  popperOffset?: number[];
  sx?: PopperProps["sx"];
  arrowSx?: SxProps;
  elevation?: number;
  borderRadius?: string;
}) => {
  const { palette, zIndex } = useTheme();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      anchorEl={anchorEl}
      placement="right-end"
      transition
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: popperOffset,
          },
        },
      ]}
      sx={{
        width: "485px",
        borderRadius,
        zIndex: zIndex.tooltip,
        boxShadow:
          `0px 9px 44px 8px rgba(92, 113, 132, 0.12);` +
          `0px 23px 36px 3px rgba(92, 113, 132, 0.14);` +
          `0px 11px 14px -7px rgba(92, 113, 132, 0.2);`,
        ...sx,
      }}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={50}>
          <Paper sx={{ borderRadius }}>
            <ClickAwayListener onClickAway={onClose}>
              <Paper
                elevation={elevation}
                sx={{ overflow: "inherit", borderRadius }}
              >
                <Box
                  className="arrow"
                  sx={{
                    position: "absolute",
                    boxSizing: "border-box",
                    width: 18,
                    height: 18,
                    "&::before": {
                      content: '""',
                      margin: "auto",
                      display: "block",
                      width: "100%",
                      height: "100%",
                      backgroundColor: palette.background.paper,
                      transform: "rotate(-45deg)",
                      transformOrigin: "0 0",
                    },
                    ...arrowSx,
                  }}
                  ref={setArrowRef}
                />
                {children}
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
