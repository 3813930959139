import { useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { getRoleFromUser } from "src/utils/useUserRoleList";
import { getAuthUserRole } from "src/api/useUserManagementResult.getters";
import { useUserDetailsUpdate } from "src/api/useUserDetailsUpdate";
import { TableBase } from "src/components/TableBase/TableBase";
import { NoUsersOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { userManagementUpdateRoute } from "src/pages/UserManagementUpdate/UserManagementUpdate.route";
import { AccountsManagementTableClasses } from "src/pages/AccountsManagement/components/AccountsManagementTable/AccountsManagementTable.const";
import { UserManagementTableProps } from "./UserManagementTable.model";
import { makeUserManagementhResultTableColumns } from "./UserManagementTable.utils";

export function UserManagementTable({
  onUserDuplicate,
  ...props
}: UserManagementTableProps) {
  const { palette } = useTheme();
  const { user } = useAuth0();
  const role = getRoleFromUser(user);
  const authUserHighestRole = getAuthUserRole(role);

  const userGeneralDataUpdate = useUserDetailsUpdate({
    options: {
      origin: "resultsPage",
    },
  });
  const onUserDeactivate = (id: string, isActive: boolean) => {
    userGeneralDataUpdate.mutate({
      body: {
        isActive,
      },
      params: {
        path: {
          id,
        },
      },
    });
  };

  const navigate = useNavigate();

  const onUserEdit = (userId: string) => {
    const url = userManagementUpdateRoute.makeUrl({
      userId,
    });
    navigate(url);
  };

  const columns = makeUserManagementhResultTableColumns(
    palette,
    onUserEdit,
    onUserDuplicate,
    onUserDeactivate,
    authUserHighestRole
  );

  return (
    <TableBase
      columns={columns}
      {...props}
      slots={{
        noRowsOverlay: NoUsersOverlay,
        noResultsOverlay: NoUsersOverlay,
        ...props.slots,
      }}
      sx={{
        ...props.sx,
        [`.${AccountsManagementTableClasses.cellStatus}`]: {
          textTransform: "capitalize",
        },
      }}
      getRowId={(row) => row.id}
    />
  );
}
