import Joi from "joi";
import { AccountsManagementFilterFormValues } from "./AccountsManagementFilterForm.model";

export const AccountsManagementFilterFormSchema =
  Joi.object<AccountsManagementFilterFormValues>({
    status: Joi.array().items(Joi.string()).label("Status").allow(null),
    expirationDateMinimum: Joi.date()
      .label("Start date")
      .allow(null)
      .when(Joi.ref("expirationDateMaximum"), {
        is: Joi.date(),
        then: Joi.date().max(Joi.ref("expirationDateMaximum")).messages({
          "date.max": "Start date must be before End date",
        }),
      }),
    expirationDateMaximum: Joi.date().label("End date").allow(null),
    orgTypes: Joi.array()
      .items(Joi.string())
      .label("Organization Type")
      .allow(null),
    states: Joi.array().items(Joi.string()).label("State").allow(null),
    accountManager: Joi.array()
      .items(Joi.string())
      .label("Account Manager")
      .allow(null),
    scoreMinimum: Joi.number().label("Account activity minimum"),
    scoreMaximum: Joi.number().label("Account activity maximum"),
    accountActivity: Joi.array().items(Joi.number()).allow(null),
  });
