import { Button } from "@mui/material";
import { SnackbarKey } from "notistack";
import { downloadReportFile } from "src/api/useReportDownloadFile";
import { SnackCloseButton } from "../SnackCloseButton/SnackCloseButton";

type ExportCompleteNotification = {
  snackbarId: SnackbarKey;
  userId?: string | null;
  reportDataObj: {
    type: string;
    exportId: string;
    title: string;
  };
};
export const ExportCompleteNotificationActions = ({
  snackbarId,
  userId,
  reportDataObj,
}: ExportCompleteNotification) => (
  <>
    <Button
      sx={{
        color: "primary.contrastText",
        marginLeft: "32px",
      }}
      onClick={() => {
        downloadReportFile({
          userId: userId || "",
          type: reportDataObj.type || "",
          id: reportDataObj.exportId,
          name: reportDataObj.title || "",
        });
      }}
    >
      DOWNLOAD
    </Button>
    <SnackCloseButton snackKey={snackbarId} />
  </>
);
