import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

import { components, paths } from "@tveyes/twosionwebapischema";
import { AnimatedBackground } from "src/components/AnimatedBackground/AnimatedBackground";
import { Spinner } from "src/components/Spinner/Spinner";
import { useSessionQuery } from "./useSessionQuery";

const apiPath: keyof paths = "/api/Session";

type Response = paths[typeof apiPath]["get"]["responses"];
//FIXME: Missing type
type SessionData = Response["200"]["content"]["application/json"] & {
  managementContact?: components["schemas"]["UserCardViewDTO"] | null;
};

const emptyBooleanEntitlement = {
  value: false,
  displayName: "",
};

const emptyNumberEntitlement = {
  value: 0,
  displayName: "",
};

const emptySessionContext: SessionData = {
  userId: "",
  accountId: "",
  timezone: "",
  expiry: "",
  effectiveAccessMode: "null",
  effectiveEntitlements: {
    enableEditWatchList: emptyBooleanEntitlement,
    enableAdFiltering: emptyBooleanEntitlement,
    enableCustomReportBanner: emptyBooleanEntitlement,
    enableMediaDownloads: emptyBooleanEntitlement,
    enableReportDownloads: emptyBooleanEntitlement,
    limitItemsPerEmail: emptyNumberEntitlement,
    limitWatchlistSize: emptyNumberEntitlement,
  },
  isImpersonatingUser: false,
  effectiveAccessFilter: {
    contentTypes: {
      included: [],
      excluded: [],
    },
    countries: {
      included: [],
      excluded: [],
    },
    markets: {
      included: [],
      excluded: [],
    },
    podcastSources: {
      included: [],
      excluded: [],
    },
    stations: {
      included: [],
      excluded: [],
    },
  },
  managementContact: {
    userTitle: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    website: undefined,
    profileImage: undefined,
  },
};

const SessionContext = createContext<SessionData>(emptySessionContext);

export function SessionProvider(props: PropsWithChildren<{}>) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { isLoading, isAuthenticated } = useAuth0();
  const { data = emptySessionContext, isLoading: isSessionLoading } =
    useSessionQuery();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // reset all cached queries
      queryClient.resetQueries();

      // clear user preferences on
      localStorage.clear();

      // clear intercom cookies
      window.Intercom("shutdown");
    }
  }, [isLoading, isAuthenticated, queryClient]);

  if (isLoading || isSessionLoading) {
    return (
      <AnimatedBackground>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: theme.palette.background.brand,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size={80} />
        </Box>
      </AnimatedBackground>
    );
  }

  return (
    <SessionContext.Provider value={data}>
      {props.children}
    </SessionContext.Provider>
  );
}

export const useSessionContext = () => useContext(SessionContext);
