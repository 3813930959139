import { Fragment } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { PageHeaderDesktop } from "src/components/PageHeaderDesktop/PageHeaderDesktop";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { useIsMobile } from "src/utils/useIsMobile";
import { ReportAnalysisPageTabMode } from "./ReportAnalysis.const";
import { ReportAnalysisFooterMobile } from "./components/ReportAnalysisFooterMobile";
import { ReportAnalysisToolbarDesktop } from "./components/ReportAnalysisToolbarDesktop";

export function ReportAnalysisPage() {
  const isMobile = useIsMobile();

  const headerDesktop = (
    <PageHeaderDesktop
      title="Report Analysis"
      hideBreadcrumbs
      hideBackButton
      toolbar={<ReportAnalysisToolbarDesktop />}
    />
  );

  const newByWatchlist = (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      component={Paper}
    >
      <Typography>New By Watchlist</Typography>
    </Stack>
  );

  const newBySearch = (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      component={Paper}
    >
      <Typography>New By Search</Typography>
    </Stack>
  );

  const listOfAnalysis = (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      component={Paper}
    >
      <Typography>List Of Analyses</Typography>
    </Stack>
  );

  const desktopTabs = (
    <AppTabLayout overflow="auto" flex={1} variant="standard">
      <AppTab
        label="New By Watchlist"
        value={ReportAnalysisPageTabMode.newByWatchlist}
        children={newByWatchlist}
        layout="desktop"
      />

      <AppTab
        label="New By Search"
        value={ReportAnalysisPageTabMode.newBySearch}
        children={newBySearch}
      />

      <AppTab
        label="List Of Analyses"
        value={ReportAnalysisPageTabMode.listOfAnalysis}
        children={listOfAnalysis}
      />
    </AppTabLayout>
  );

  if (isMobile) {
    return (
      <Fragment>
        <PageLayoutMobile
          content={
            <Stack height="100%" justifyContent="center" alignItems="center">
              <Typography> Mobile content </Typography>
            </Stack>
          }
          footer={<ReportAnalysisFooterMobile />}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageLayoutDesktop
        header={headerDesktop}
        content={desktopTabs}
        overflow="hidden"
      />
    </Fragment>
  );
}
