import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EventDetails } from "src/models/EventDetails";
import { ClipEditorSaveButtonDesktop } from "./ClipEditorSaveButtonDesktop";

export function ClipEditorToolBarDesktop({
  handleSaveToMediaCenter,
  handleDownload,
  disabled,
  event,
  enableMediaDownload,
}: {
  handleSaveToMediaCenter: (clipTitle: string) => void;
  handleDownload: () => void;
  disabled?: boolean;
  enableMediaDownload?: boolean;
  event: Pick<EventDetails, "mediaDownloadExtension">;
}) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Stack direction="row" columnGap={2} flex={1} justifyContent="flex-end">
      <Button
        variant="outlined"
        color="primary"
        children="Cancel"
        onClick={goBack}
        sx={{
          minWidth: 160,
        }}
      />

      <ClipEditorSaveButtonDesktop
        handleSaveToMediaCenter={handleSaveToMediaCenter}
        handleDownload={handleDownload}
        disabled={disabled}
        enableMediaDownload={enableMediaDownload}
        event={event}
      />
    </Stack>
  );
}
