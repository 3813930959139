import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { joiResolver } from "@hookform/resolvers/joi";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { useSettingsContact } from "src/api/useSettingsContact";
import { AppLink } from "src/components/AppLink/AppLink";
import { ContactFormSchema } from "./SettingsContactForm.schema";
import { ContactFormValues } from "./SettingsContactForm.model";

const CONTACT_REASONS = [
  "Software Question",
  "Account Management",
  "Monitoring Request",
];

export function SettingsContactForm({
  onClose,
}: {
  onClose?: (() => void) | undefined;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const initialValues = { reason: "", note: "" };
  const { control, formState, handleSubmit, reset, watch } =
    useForm<ContactFormValues>({
      defaultValues: initialValues,
      mode: "all",
      resolver: joiResolver(ContactFormSchema),
    });
  const notesWatch = watch("note") || "";

  const contactFormSubmit = useSettingsContact({
    options: {
      onSuccess: () => {
        reset(initialValues);
        enqueueSnackbar(
          t("useSettingsContactMessage", "Contact request send"),
          {
            variant: "success",
          }
        );
        onClose?.();
      },
    },
  });

  const isBusy = formState.isSubmitting || contactFormSubmit.isLoading;

  const handleSubmitWrapper = handleSubmit(
    (formData) => {
      contactFormSubmit.mutate({
        formData,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:UserInformationSettingsForm:error", errors);
    }
  );

  return (
    <Stack>
      <Stack gap={2}>
        {isMobile && (
          <Typography variant="body1" color={"text.secondary"}>
            To learn more about TVEyes, call{" "}
            <AppLink title="203-254-3600" to={`mailto:203-254-3600`} /> and
            select option 1 or email us at:{" "}
            <AppLink title="hello@tveyes.com" to={`mailto:hello@tveyes.com`} />.
            If you prefer, fill out this form and the TVEyes team will contact
            you!
          </Typography>
        )}
        <Controller
          name="reason"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel>Select reason</InputLabel>
              <Select
                {...field}
                id="reason"
                label={"Select reason"}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                fullWidth
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    width: 100,
                  },
                }}
              >
                {CONTACT_REASONS.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-multiline-static"
              multiline
              rows={14}
              placeholder="Note"
              onChange={field.onChange}
              inputProps={{ maxLength: 200 }}
            />
          )}
        />
      </Stack>
      <Typography variant="body2" color={"text.secondary"} mt={0.5}>
        {200 - notesWatch?.length}
      </Typography>
      <Stack
        alignItems={isMobile ? "center" : "end"}
        direction={"row"}
        gap={1}
        justifyContent={"end"}
      >
        {isMobile && (
          <Button variant="outlined" sx={{ width: "50%" }} onClick={onClose}>
            Cancel
          </Button>
        )}
        <LoadingButton
          variant="contained"
          color="secondary"
          sx={{
            width: isMobile ? "50%" : "84px",
          }}
          loading={isBusy}
          disabled={!formState.isValid}
          onClick={handleSubmitWrapper}
        >
          Send
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
