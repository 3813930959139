import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { managementViewKey } from "./useManagementView";
import { useSessionContext } from "./useSessionContext";

const apiKey: keyof paths = "/api/User/{id}/management-view";
const method: keyof paths[typeof apiKey] = "put";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  timezone?: string | null;
  accountName?: string | null;
  trackLocation?: boolean | null;
  roles?: string[];
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useManagementViewUpdate({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ ...userManagementViewData }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        path: {
          id: userId,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: userManagementViewData,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: managementViewKey,
      });
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
