import { addDays, endOfDay, startOfDay } from "date-fns";

/**
 * Creates Date tuple that is used as a range.
 * @param days
 * positive - date range starts now and end in days
 * negative - date range starts days before until end of today
 * if zero - date range starts at the beginning of today and ends at today's end
 */
export function makeDateRange(date: Date, days: number): [Date, Date] {
  if (days < 0) {
    const end = endOfDay(date);
    const start = startOfDay(addDays(date, days + 1));

    return [start, end];
  }

  if (days > 0) {
    const date0 = startOfDay(date);
    const date1 = addDays(endOfDay(date0), days - 1);

    return [date0, date1];
  }

  const date0 = startOfDay(date);
  const date1 = endOfDay(date);

  return [date0, date1];
}
