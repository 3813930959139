import { useRef } from "react";
import { useReportThumbnail } from "src/api/useReportThumbnail";
import { useElementSize } from "src/utils/useElementSize";
import { ReportThumbnailProps } from "./ReportThumbnail.model";
import { ImageLoader } from "../ImageLoader/ImageLoader";

export const ReportThumbnail = ({
  reportId,
  ...props
}: ReportThumbnailProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const size = useElementSize(ref);

  const { data, isFetching } = useReportThumbnail({
    options: {
      enabled: !!size,
    },
    params: {
      path: {
        id: reportId,
      },
    },
  });

  return (
    <ImageLoader innerRef={ref} src={data} loading={isFetching} {...props} />
  );
};
