import { components } from "@tveyes/twosionwebapischema";
import { PagedResponseResults } from "src/models/PagedResponseResults";

/**
 * Counts total amount of rows loaded across all pages
 * @param pages A paged API response
 * @returns - total amount of rows
 */
export function countPagedResultsTotal(pages: PagedResponseResults[]) {
  return pages.reduce((count, page) => {
    const { length = 0 } = page?.results || {};
    return count + length;
  }, 0);
}

export function countPagedSnapshotResultsTotal(
  pages: PagedResponseResults<components["schemas"]["MarketSnapshot"] | null>[]
) {
  const totalCount: number = pages.reduce((acc, page) => {
    return (
      acc +
      (page?.results || []).reduce((resultAcc, result) => {
        return resultAcc + (result?.stationSnapshots?.length || 0);
      }, 0)
    );
  }, 0);
  return totalCount;
}
