import { DownloadReportFormat } from "src/api/useReportExport";
import { useSessionContext } from "src/api/useSessionContext";

export const useEventsDownloadLimits = () => {
  const {
    effectiveEntitlements: { limitEventsInPDF, limitEventsInCSV },
  } = useSessionContext();
  const reportDownloadLimitMap = {
    [DownloadReportFormat.pdf]: limitEventsInPDF?.value,
    [DownloadReportFormat.csv]: limitEventsInCSV?.value,
  };
  return (format: DownloadReportFormat) => reportDownloadLimitMap[format];
};
