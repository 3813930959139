import { Dispatch, SetStateAction } from "react";
import { Grid, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import { ContentAccessFilter } from "src/models/ContentAccessFilter";
import { AccountResponse } from "src/api/useAccount";
import { IncludeExcludeListGroupLogic } from "src/utils/makeWatchQueryFormValues";
import { AccountsManagementEditContentFilterForm } from "../AccountsManagementEditContentFilterForm/AccountsManagementEditContentFilterForm";
import { AccountsManagementContentCodeFilter } from "../AccountsManagementContentCodeFilter/AccountsManagementContentCodeFilter";

export function AccountsManagementEditContentTab({
  contentFilters,
  data,
  selectedPreset,
  setSelectedPreset,
}: {
  contentFilters?: ContentAccessFilter | null;
  data?: AccountResponse;
  selectedPreset?: IncludeExcludeListGroupLogic | null;
  setSelectedPreset?: Dispatch<
    SetStateAction<IncludeExcludeListGroupLogic | null>
  >;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));

  return (
    <Grid
      container
      gap={2}
      direction={isMobile ? "column" : "row"}
      flexWrap={"nowrap"}
    >
      <Grid item xs={12} md={6}>
        <Stack component={Paper} px={4} width={"568px"}>
          <AccountsManagementEditContentFilterForm
            data={data}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack component={Paper} px={4} width={"568px"}>
          <AccountsManagementContentCodeFilter
            contentFilters={contentFilters}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
