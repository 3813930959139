import { Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { DemoModeConfirmationModal } from "../DemoModeConfirmationModal/DemoModeConfirmationModal";

export function EndDemoModeModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const impersonateUser = useImpersonateUser({
    options: {
      onSuccess: () => {
        onClose();
        enqueueSnackbar("Demo has ended. Your session has been restored", {
          autoHideDuration: 8000,
        });
        setTimeout(
          () =>
            (window.location.href =
              "http://insight.tveyes.com/help/demo-whats-next"),
          8000
        );
      },
      onSettled: () => {
        if (isOpen) onClose();
      },
    },
  });

  const stopImpersonating = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id: "00000000-0000-0000-0000-000000000000",
        },
      },
    });
  };

  return (
    <DemoModeConfirmationModal
      open={isOpen}
      onClose={onClose}
      onSubmit={stopImpersonating}
      content={
        <Typography variant="subtitle1">
          Are you sure you want to close the demo mode?
        </Typography>
      }
      submitLabel="Yes"
      title="End Demo"
      cancelLabel="No"
      isLoading={impersonateUser.isLoading}
    />
  );
}
