import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { parseISO } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { UsersAutocomplete } from "src/components/UsersAutocomplete/UsersAutocomplete";
import { STATUSLIST } from "src/utils/useUserRoleList";
import {
  ORGANIZATION_TYPES_OPTIONS,
  STATES,
} from "./AccountsManagementFilterForm.conts";
import { AccountsManagementFilterFormValues } from "./AccountsManagementFilterForm.model";

export function AccountsManagementFilterForm({
  showUserAccounts,
}: {
  showUserAccounts?: boolean;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { control } = useFormContext<AccountsManagementFilterFormValues>();

  return (
    <Stack pt={3}>
      {/* Status */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={3}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="Status"
                    multiple
                    label="Status"
                    value={field.value ?? []}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                        textTransform: "capitalize",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        textTransform: "capitalize",
                      },
                    }}
                  >
                    {STATUSLIST.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>

      {/* Status */}

      {/* Expiration date range */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={1}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Expiration Date:
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={true}>
          <Controller
            name="expirationDateMinimum"
            control={control}
            render={({ field, fieldState }) => {
              const message = fieldState.error?.message || " ";
              return (
                <DatePicker
                  label="Start date"
                  value={
                    typeof field.value === "string"
                      ? parseISO(field.value ?? "")
                      : field.value
                  }
                  onChange={field.onChange}
                  slotProps={{
                    textField: {
                      helperText: message,
                      id: "start-date-input",
                      fullWidth: true,
                      onBlur: field.onBlur,
                      error: !!fieldState.error,
                    },
                  }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={true}>
          <Controller
            name="expirationDateMaximum"
            control={control}
            render={({ field, fieldState }) => {
              const message = fieldState.error?.message || " ";

              return (
                <DatePicker
                  label="End date"
                  value={
                    typeof field.value === "string"
                      ? parseISO(field.value ?? "")
                      : field.value
                  }
                  onChange={field.onChange}
                  slotProps={{
                    textField: {
                      helperText: message,
                      id: "end-date-input",
                      fullWidth: true,
                      onBlur: field.onBlur,
                      error: !!fieldState.error,
                    },
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Expiration date range */}

      {/* Organization type */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={3}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 1}>
            Organization Type
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="orgTypes"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel>Organization type</InputLabel>

                  <Select
                    id="orgTypes"
                    multiple
                    label="Organization type"
                    value={field.value ?? []}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                      },
                    }}
                  >
                    {ORGANIZATION_TYPES_OPTIONS.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
      {/* organization types */}

      {/* states */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={3}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            State
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="states"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>

                <Select
                  id="states"
                  label="State"
                  value={field.value ?? []}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  fullWidth
                  multiple
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      width: 100,
                    },
                  }}
                >
                  {STATES.map((state) => (
                    <MenuItem key={state.code} value={state.code}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      {/* states */}

      {/* Account Manager */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={3}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Account Manager
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="accountManager"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <UsersAutocomplete
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || []}
                  multiple
                  disabled={showUserAccounts}
                  roles={["Account Manager", "User Manager", "Admin"]}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      {/* Account Manager */}

      {/* Account Activity */}
      <Grid container spacing={isMobile ? 1.5 : 3} my={1}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 0.5}>
            Account Activity
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="accountActivity"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Slider
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  max={100}
                  min={0}
                  marks={true}
                  step={10}
                  valueLabelDisplay="on"
                />
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Stack>0</Stack>
                  <Stack>100</Stack>
                </Stack>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      {/* Account Manager */}
    </Stack>
  );
}
