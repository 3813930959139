import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/System";
const method: keyof paths[typeof apiPath] = "get";

type ResponseBody = components["schemas"]["SystemResponse"];

export function useGlobalEntitlements() {
  return useQuery<ResponseBody, unknown>({
    queryKey: [apiPath],
    queryFn: async () => {
      const url = makeApiUrl(apiPath);
      const { data } = await axios<ResponseBody>(url, {
        method,
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
