import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  Advertisement,
  queryAdvertisementValues,
} from "src/models/Advertisement";
import { useSessionContext } from "src/api/useSessionContext";
import { useIsMobile } from "src/utils/useIsMobile";
import { ButtonSelector } from "../ButtonSelector/ButtonSelector";
import { ButtonSelectorProps } from "../ButtonSelector/ButtonSelector.model";
import { TooltipBaseMobile } from "../TooltipBaseMobile/TooltipBaseMobile";
import { TooltipBase } from "../TooltipBase/TooltipBase";

const getAdvertisementLabel = (o: Advertisement) => {
  switch (o) {
    case queryAdvertisementValues.all:
      return "All";
    case queryAdvertisementValues.none:
      return "Hide Ads";
    case queryAdvertisementValues.only:
      return "Only Ads";
    default:
      return o;
  }
};

export function AdvertisementPicker(
  props: Omit<ButtonSelectorProps<Advertisement>, "options" | "getOptionLabel">
) {
  const isMobile = useIsMobile();
  const { effectiveEntitlements } = useSessionContext();
  const enableAdFilters = effectiveEntitlements.enableAdFiltering?.value;
  const { t } = useTranslation();

  const advertisementPickerContent = (
    <Box>
      <ButtonSelector<Advertisement>
        getOptionLabel={getAdvertisementLabel}
        options={Object.values(queryAdvertisementValues)}
        {...props}
        disabled={!enableAdFilters}
      />
    </Box>
  );

  return isMobile ? (
    <TooltipBaseMobile
      width={249}
      title={enableAdFilters ? "" : t("adFiltersDisabled")}
      placement="top"
      disableHoverListener={enableAdFilters}
    >
      {advertisementPickerContent}
    </TooltipBaseMobile>
  ) : (
    <TooltipBase
      width={249}
      textAlign="left"
      title={enableAdFilters ? "" : t("adFiltersDisabled")}
      placement="bottom"
      disableHoverListener={enableAdFilters}
    >
      {advertisementPickerContent}
    </TooltipBase>
  );
}
