import { Dialog, Stack, Typography } from "@mui/material";
import { PopperBaseInfoContent } from "src/components/PopperBaseInfoContent/PopperBaseInfoContent";

export function AdvertisementInfoDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ sx: { mx: 2 } }}>
      <PopperBaseInfoContent
        title="Advertisement"
        onClose={onClose}
        description={
          <Stack gap={2}>
            <Typography variant="body1">
              The TVEyes platform introduces a robust and user-centric tool
              designed to enhance your experience in monitoring and analyzing
              advertising content across broadcast television and radio
              channels.
            </Typography>
            <Typography>
              This feature-rich popover provides versatile options for managing
              advertisements with a focus on convenience and customization.
            </Typography>
          </Stack>
        }
      />
    </Dialog>
  );
}
