import { HTMLAttributes, forwardRef, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  List,
  Paper,
  PaperProps,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { TagListDataItem, useTagListData } from "src/api/useTagListData";
import { hashStringToColor } from "src/pages/MediaCenter/MediaCenter.utils";

type MediaCenterTagsAutocompleteProps = {
  onChange: (v: TagListDataItem[]) => void;
  value: TagListDataItem[];
  isAbleToCreateNewTags?: boolean;
};

const tagsAutocompletefilter = createFilterOptions<TagListDataItem>();

const TagsAutocompleteCustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

function getNewValue(
  inputValue: string,
  allTags: TagListDataItem[],
  isAbleToCreateNewTags?: boolean
) {
  if (isAbleToCreateNewTags) {
    return [
      {
        tagName: inputValue,
        color: hashStringToColor(inputValue),
      },
    ];
  }

  const newTag = allTags.filter(
    (tag) => tag.tagName.toLocaleLowerCase() === inputValue.toLocaleLowerCase()
  );
  return newTag.length ? newTag : [];
}

export const MediaCenterTagsAutocomplete = ({
  onChange,
  value,
  isAbleToCreateNewTags,
}: MediaCenterTagsAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");

  const tagsListData = useTagListData();

  const ListBoxComponent = useMemo(() => {
    return forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
      (listBoxProps, ref) => {
        return (
          <List
            {...listBoxProps}
            ref={ref}
            style={{
              ...listBoxProps.style,
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              borderRadius: 1,
              width: "100%",
              maxHeight: 396,

              overflow: "hidden",
              position: "relative",
            }}
          >
            {listBoxProps.children}
          </List>
        );
      }
    );
  }, []);

  return (
    <Autocomplete
      multiple
      freeSolo
      id="tag-select-autocomplete"
      inputValue={inputValue}
      PaperComponent={TagsAutocompleteCustomPaper}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.tagName;
      }}
      onChange={(_e, newValue) => {
        if (typeof newValue === "string") {
          onChange([...value, newValue]);
        } else {
          const updatedValue = newValue.map((v) => {
            return typeof v === "string"
              ? { tagName: v, color: hashStringToColor(v) }
              : v;
          });
          onChange(updatedValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = tagsAutocompletefilter(options, params);

        if (!isAbleToCreateNewTags) return filtered;

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.tagName
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            tagName: inputValue,
            color: hashStringToColor(inputValue),
          });
        }

        return filtered;
      }}
      value={value}
      options={tagsListData.tags}
      ListboxComponent={ListBoxComponent}
      renderTags={(value: TagListDataItem[], getTagProps) => {
        const tagElements = value.map((option, index) => {
          return (
            <Chip
              label={option.tagName}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: option.color,
                "&:hover": {
                  backgroundColor: option.color,
                },
                color: "white",
              }}
              onDelete={() => {
                onChange(value.filter((tag) => tag.tagName !== option.tagName));
              }}
              size="medium"
            />
          );
        });

        return (
          <Stack
            flexDirection="row"
            overflow="auto"
            flexWrap="wrap"
            maxHeight={114}
          >
            {tagElements}
          </Stack>
        );
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Chip
            label={option.tagName}
            sx={{
              backgroundColor: option.color,
              "&:hover": {
                backgroundColor: option.color,
              },
              color: "white",
            }}
          />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tag Name"
          variant="outlined"
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();

              const newValue = getNewValue(
                inputValue,
                tagsListData.tags,
                isAbleToCreateNewTags
              );

              onChange([...value, ...newValue]);
              setInputValue("");
            }
          }}
        />
      )}
    />
  );
};
