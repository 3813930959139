import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/user/query/validate/smart/custom-query";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
export type CustomQueryValidationData = Required<
  components["schemas"]["LuceneValidationResponse"]
>;

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type Response = CustomQueryValidationData;

type RequestMutationFnParams = {
  customQuery: RequestBody;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError"
>;

export function useValidateCustomQuerySmart({
  options,
}: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ customQuery }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey);

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(customQuery),
      });

      return data;
    },
  });
}
