import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { makeTypedPropList } from "src/utils/makeTypedPropList";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryAlertEdit } from "src/api/useWatchQueryAlertEdit";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { useWatchQuery } from "src/api/useWatchQuery";
import { AlertType } from "src/models/AlertType";
import { ScheduledAlertControls } from "./ScheduledAlertControls/ScheduledAlertControls";
import { ScheduledAlertDeleteDialog } from "./ScheduledAlertDeleteDialog/ScheduledAlertDeleteDialog";
import { ScheduledAlertCardView } from "./ScheduledAlertCardView/ScheduledAlertCardView";
import {
  AlertConfig,
  ScheduledAlertPopoverProps,
} from "./ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  formatTimeValue,
  getDefaultAlertStateData,
  getMonthlyScheduleTime,
  getScheduledAlertRuleData,
  getScheduledAlertType,
  getWeeklyScheduleTime,
} from "./ScheduledAlertPopover.utils";
import { StackScroll } from "../StackScroll/StackScroll";
import { PopperBase } from "../PopperBase/PopperBase";

export const ScheduledAlertPopover = ({
  queryId,
  editedRule,
  onSuccessEditRule,
  open,
  anchorEl,
  origin,
  onClose,
  presets = [],
  alerts,
  scheduledAlertCreateHandler,
  defaultAlertId,
}: ScheduledAlertPopoverProps) => {
  const { user } = useAuth0();
  const { palette } = useTheme();

  const [scheduledAlertEnabled, setScheduledAlertEnabled] = useState(true);
  const [sheduledAlertArchiveEnabled, setSheduledAlertArchiveEnabled] =
    useState(false);
  const [editRule, setEditRule] = useState<AlertType | null>(null);
  const [showAlertDeleteDialog, setShowAlertDeleteDialog] = useState(false);
  const [alertDeleteData, setAlertDeleteData] = useState<AlertType | null>(
    null
  );

  const [config, setConfig] = useState<AlertConfig>(
    getDefaultAlertStateData(AlertTypesKey.daily)
  );

  const [selectOpen, setSelectOpen] = useState(false);
  const updateSelectOpen = (v: boolean) => setSelectOpen(v);
  const themeBackground =
    palette.mode === "dark"
      ? palette.background.default
      : palette.background.paper;

  useEffect(() => {
    const isAlertsEnabled = alerts.every((alert) => alert.enabled);
    setScheduledAlertEnabled(isAlertsEnabled);
  }, [alerts]);

  const currentQueryData = useWatchQuery({
    enabled: !!queryId && !!open && origin !== "edit",
    path: {
      queryId: queryId ?? "",
    },
  });

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => {
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
      },
      origin,
      type: "scheduled",
    },
  });
  const scheduledAlertCreate = () => {
    if (!queryId) return;

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    alertCreate.mutate({
      params: {
        path: {
          queryId,
        },
        query: {
          type: config.type,
          archive: sheduledAlertArchiveEnabled,
          enabled: scheduledAlertEnabled,
        },
        schedule: scheduleTime,
      },
    });
  };

  const alertEdit = useWatchQueryAlertEdit({
    options: {
      onSettled: () => {
        setEditRule(null);
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
        onSuccessEditRule && onSuccessEditRule(null);
      },
      origin,
    },
  });
  const scheduledAlertEdit = (editRule?: AlertType) => {
    if (!queryId || !editRule || !editRule.id) return;

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    const updatedAlert = {
      ...editRule,
      type: config.type,
      schedule: scheduleTime,
      archive: sheduledAlertArchiveEnabled,
    };

    alertEdit.mutate({
      params: {
        path: {
          queryId,
          alertId: editRule.id,
        },
      },
      alert: updatedAlert,
    });
  };

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      origin,
    },
  });
  const handleScheduledAlertChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (scheduledAlertCreateHandler) {
      const updatedAlerts = (alerts || []).map((v) => {
        return { ...v, enabled: event.target.checked };
      });
      return scheduledAlertCreateHandler(updatedAlerts);
    }

    const data = currentQueryData.data;
    if (!data) return;

    const updatedAlerts = alerts.map((v) => {
      if (v.type === "realtime") return v;
      return { ...v, enabled: event.target.checked };
    });

    watchQueryUpdate.mutate({
      ...data,
      alerts: updatedAlerts,
    });
  };

  const editRuleHandler = useCallback((rule?: AlertType | null) => {
    if (!rule) return;

    setEditRule(rule);

    const parsedRuleScheduledData = getScheduledAlertRuleData(rule);
    setConfig(parsedRuleScheduledData);
    setSheduledAlertArchiveEnabled(rule.archive || false);
  }, []);

  useEffect(() => {
    editRuleHandler(editedRule);
  }, [editedRule, editRuleHandler]);

  const goBack = () => {
    if (!editRule) onClose && onClose();

    setEditRule(null);
    setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
    setSheduledAlertArchiveEnabled(false);

    onSuccessEditRule && onSuccessEditRule(null);
  };

  const presetElements = Object.entries(presets).map(([k, v]) => {
    const key = `${k}:${v.value}`;
    return (
      <Chip
        label={v.label}
        key={key}
        variant="outlined"
        onClick={(_e) => {
          setConfig(() => getDefaultAlertStateData(v.value));
        }}
        size="medium"
      />
    );
  });

  const scheduledAlertRules = useMemo(() => {
    const updatedRules = alerts.filter((v) => {
      if (v.type === "realtime") return false;

      return !editRule;
    });
    return (
      updatedRules.length !== 0 && (
        <StackScroll
          pl={3}
          pr={2}
          py={2}
          sx={{ backgroundColor: palette.background.paper }}
          maxHeight={250}
        >
          {updatedRules.map((alert) => (
            <ScheduledAlertCardView
              sx={{
                "&:not(:first-of-type)": {
                  mt: 2,
                },
                background: palette.background.default,
              }}
              key={alert.id}
              type={getScheduledAlertType(alert)}
              value={alert.schedule || []}
              enabled={alert.enabled}
              onDelete={() => {
                if (!scheduledAlertCreateHandler) {
                  setAlertDeleteData(alert);
                  return setShowAlertDeleteDialog(true);
                }

                scheduledAlertCreateHandler(alert, "delete");
              }}
              onEdit={() => editRuleHandler(alert)}
            />
          ))}
          <ScheduledAlertDeleteDialog
            open={showAlertDeleteDialog}
            onClose={() => setShowAlertDeleteDialog(false)}
            queryId={queryId}
            alert={alertDeleteData}
            origin={origin}
          />
        </StackScroll>
      )
    );
  }, [
    alerts,
    alertDeleteData,
    editRule,
    queryId,
    origin,
    showAlertDeleteDialog,
    palette.background.default,
    scheduledAlertCreateHandler,
    editRuleHandler,
    palette.background.paper,
  ]);

  const addAlert = () => {
    if (!scheduledAlertCreateHandler) {
      return editRule ? scheduledAlertEdit(editRule) : scheduledAlertCreate();
    }

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }
    const alert = {
      id: defaultAlertId
        ? "00000000-0000-0000-0000-000000000000"
        : `${config.type}-${alerts.length + 1}`,
      type: config.type,
      archive: sheduledAlertArchiveEnabled,
      destination: user?.email,
      schedule: scheduleTime,
      enabled: true,
      muted: false,
    };
    const updatedAlert = {
      ...editRule,
      type: config.type,
      schedule: scheduleTime,
      archive: sheduledAlertArchiveEnabled,
    };
    scheduledAlertCreateHandler(
      editRule ? updatedAlert : alert,
      editRule ? "edit" : undefined
    );

    setEditRule(null);
    setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
    setSheduledAlertArchiveEnabled(false);
  };

  const scheduledAlertPopperContent = (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        px={3}
        py={2}
        gap={2}
        sx={{
          background: themeBackground,
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
        }}
      >
        <Switch
          disabled={alerts.length === 0}
          checked={scheduledAlertEnabled}
          onChange={handleScheduledAlertChange}
        />
        <Typography variant="h6">Get scheduled alert</Typography>
      </Stack>
      <Divider />
      {scheduledAlertRules}
      <Stack
        p={3}
        gap={2}
        sx={{
          background: themeBackground,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
      >
        <ScheduledAlertControls
          updateSelectOpen={updateSelectOpen}
          config={config}
          setConfig={setConfig}
        />
        {presets.length && (
          <StackScroll
            scrollOrientation="horizontal"
            flexDirection="row"
            gap={1}
            width="100%"
            height={40}
            alignItems="center"
          >
            {presetElements}
          </StackScroll>
        )}
        <FormControlLabel
          label="Archive Alerts"
          control={
            <Switch
              checked={sheduledAlertArchiveEnabled}
              onChange={() =>
                setSheduledAlertArchiveEnabled(!sheduledAlertArchiveEnabled)
              }
            />
          }
        />
        <Stack flexDirection="row" justifyContent="end" gap={1}>
          <Button
            variant="text"
            sx={{ height: 36, width: 72, fontSize: "14px" }}
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ height: 36, width: 72, fontSize: "14px" }}
            onClick={addAlert}
          >
            {editRule ? "Save" : "Add"}
          </Button>
        </Stack>
      </Stack>
    </>
  );

  return (
    <PopperBase
      open={open}
      anchorEl={anchorEl}
      placement="left"
      onClose={() => {
        if (selectOpen) return;
        goBack();
      }}
      elevation={1}
      sx={{
        background: themeBackground,
        width: 404,
        mt: "10px !important",
        mr: "12px !important",
        //without this prop, the buttons to switch views would be superimposed on the popup
        zIndex: 2,
      }}
      arrowSx={{
        right: 0,
        mr: "-5px",
        [`&:before`]: {
          backgroundColor: themeBackground,
        },
      }}
      borderRadius="16px"
    >
      {scheduledAlertPopperContent}
    </PopperBase>
  );
};
