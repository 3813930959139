import {
  Dialog,
  Typography,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DownloadReportFormat,
  downloadLimitTitle,
} from "src/api/useReportExport";
import { useSessionContext } from "src/api/useSessionContext";
import { TooltipBaseMobile } from "src/components/TooltipBaseMobile/TooltipBaseMobile";
import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";

type ReportsExportDialogMobileProps = Omit<DialogProps, "onClose"> & {
  onClose: () => void;
  exportReports: (format: DownloadReportFormat) => void;
  isDownloadDisabled: boolean;
};

export function ReportsExportDialogMobile({
  open,
  onClose,
  exportReports,
  isDownloadDisabled,
}: ReportsExportDialogMobileProps) {
  const { t } = useTranslation();

  const { effectiveEntitlements } = useSessionContext();
  const enableReportDownloads =
    effectiveEntitlements.enableReportDownloads?.value;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 319,
          py: 2,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          px: 3,
        }}
      >
        Export
      </Typography>
      <List>
        <TooltipBaseMobile
          width={190}
          title={
            enableReportDownloads
              ? downloadLimitTitle
              : t("reportDownloadsDisabled")
          }
          placement="top"
          disableHoverListener={enableReportDownloads && !isDownloadDisabled}
        >
          <MenuItem
            disabled={isDownloadDisabled || !enableReportDownloads}
            onClick={() => {
              exportReports(DownloadReportFormat.pdf);
              onClose();
            }}
          >
            <ListItemIcon>
              <DownloadPDF />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>Download PDF</ListItemText>
          </MenuItem>
        </TooltipBaseMobile>

        <TooltipBaseMobile
          width={190}
          title={
            enableReportDownloads
              ? downloadLimitTitle
              : t("reportDownloadsDisabled")
          }
          placement="top"
          disableHoverListener={enableReportDownloads && !isDownloadDisabled}
        >
          <MenuItem
            disabled={isDownloadDisabled || !enableReportDownloads}
            onClick={() => {
              exportReports(DownloadReportFormat.csv);
              onClose();
            }}
          >
            <ListItemIcon>
              <DownloadCSV />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>Download CSV</ListItemText>
          </MenuItem>
        </TooltipBaseMobile>
      </List>
      <Button
        variant="outlined"
        size="medium"
        color="inherit"
        sx={{
          width: 84,
          ml: "auto",
          mr: 2,
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Dialog>
  );
}
