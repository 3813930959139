import { IconButton, IconButtonProps, alpha, useTheme } from "@mui/material";
import { intercomButtonId } from "src/providers/IntercomProvider";
import { IntercomLauncherIcon } from "../icons/IntercomLauncherIcon";

export const IntercomButton = ({
  ...props
}: Omit<IconButtonProps, "children" | "id">) => {
  const { palette } = useTheme();
  return (
    <IconButton
      {...props}
      id={intercomButtonId}
      children={
        <IntercomLauncherIcon
          sx={{ pt: "4px", color: alpha(palette.text.primary, 0.65) }}
        />
      }
    />
  );
};
