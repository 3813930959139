import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { timezoneData } from "src/utils/timezoneData";

type TimeZonePickerProps = {
  value: string | null;
  onChange: (e: SelectChangeEvent<string>) => void;
  onBlur?: () => void;
  error?: boolean;
};

export function TimeZonePicker({
  value,
  onChange,
  onBlur,
  error,
}: TimeZonePickerProps) {
  return (
    <FormControl fullWidth>
      <InputLabel>Time Zone</InputLabel>
      <Select
        id="timezone"
        label="Time Zone"
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        fullWidth
        MenuProps={{
          style: {
            maxHeight: 400,
            width: 100,
          },
        }}
      >
        {timezoneData.map((timezone) => (
          <MenuItem key={timezone.Id} value={timezone.Id}>
            {timezone.Display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
