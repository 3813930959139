import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  useTheme,
} from "@mui/material";

import { PopperTransitionProps } from "@mui/base";

import { useState } from "react";
import { AppPopperProps } from "./AppPopper.model";
import { AppPopperArrow } from "./components/AppPopperArrow/AppPopperArrow";
import { appPopperArrowSizeMap } from "./components/AppPopperArrow/AppPopperArrow.const";

export { AppPopperArrow, appPopperArrowSizeMap };

export const AppPopper = ({
  open,
  placement,
  arrow,
  anchorEl,
  onClose,
  children,
  elevation = 4,
  borderRadius = 8,
  disableClickAwayListener,
  options,
  paperColor,
  ...props
}: AppPopperProps) => {
  const { shadows, zIndex } = useTheme();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const boxShadow = shadows[elevation];
  const arrowSize = arrow ? appPopperArrowSizeMap[arrow] : 0;

  const popperOffset = [
    // offset along the anchorEl
    0,
    // distance from anchorEl, depends on arrow size
    arrowSize,
  ];

  const renderContent = ({
    TransitionProps,
    placement,
  }: {
    TransitionProps?: PopperTransitionProps;
    placement: PopperPlacementType;
  }) => {
    return (
      <Fade {...TransitionProps} timeout={50}>
        <Stack flex={1} borderRadius={`${borderRadius}px`}>
          <Paper
            children={children}
            sx={{
              boxShadow,
              height: "100%",
              width: "100%",
              backgroundColor: paperColor,
            }}
          />
          {arrow && (
            <AppPopperArrow
              arrowSize={arrowSize}
              placement={placement}
              boxShadow={boxShadow}
              ref={setArrowRef}
            />
          )}
        </Stack>
      </Fade>
    );
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      popperOptions={options}
      transition
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
        },
        {
          name: "flip",
          options: {
            mainAxis: true,
            altAxis: true,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: popperOffset,
          },
        },
      ]}
      sx={{
        minWidth: arrowSize * 3,
        minHeight: arrowSize * 3,
        borderRadius,
        zIndex: zIndex.tooltip,
        display: "flex",
        ".arrow > .MuiBox-root": {
          backgroundColor: paperColor,
        },
      }}
      {...props}
    >
      {({ TransitionProps, placement }) =>
        disableClickAwayListener ? (
          renderContent({ TransitionProps, placement })
        ) : (
          <ClickAwayListener onClickAway={onClose}>
            {renderContent({ TransitionProps, placement })}
          </ClickAwayListener>
        )
      }
    </Popper>
  );
};
