import { Fragment, useMemo } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { UseInfiniteQueryResult } from "react-query";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { PreselectedEventTiles } from "./PreselectedEventTiles/PreselectedEventTiles";
import { PreselectedEventList } from "./PreselectedEventList/PreselectedEventList";
import { PreselectedEventTable } from "./PreselectedEventTable/PreselectedEventsTable";

export function usePreselectedEventsDataView({
  viewMode,
  isExpandedTextMode,
  isLoading,
  data,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  renderHeader,
  renderSelectAll,
  reportScratchEventsTiles,
}: {
  viewMode: ViewMode;
  isExpandedTextMode?: boolean | null;
  isLoading: boolean;
  data: PowerSearchResultItem[];
  rowCount: number;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  renderHeader: () => JSX.Element | null;
  renderSelectAll: () => JSX.Element | null;
  reportScratchEventsTiles: UseInfiniteQueryResult;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return useMemo(() => {
    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <Fragment>
              {renderHeader()}
              {renderSelectAll()}
              <PreselectedEventTable
                id="preselected-events-table"
                rows={data}
                rowCount={rowCount}
                isMobile={isMobile}
                loading={isLoading}
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
              />
            </Fragment>
          ),
          list: (
            <Fragment>
              {renderHeader()}
              {renderSelectAll()}
              <Stack flex={1} overflow="hidden">
                <PreselectedEventList
                  id="preselected-events-list"
                  rows={data}
                  rowCount={rowCount}
                  loading={isLoading}
                  paginationModel={paginationModel}
                  onPaginationModelChange={onPaginationModelChange}
                />
              </Stack>
            </Fragment>
          ),
          tile: (
            <Fragment>
              {isMobile ? null : renderHeader()}
              {isMobile ? null : renderSelectAll()}
              <PreselectedEventTiles
                id="preselected-events-tiles"
                lineClamp={isMobile && isExpandedTextMode ? 5 : 2}
                renderHeader={
                  isMobile
                    ? () => (
                        <Fragment>
                          {renderHeader()}
                          {renderSelectAll()}
                        </Fragment>
                      )
                    : undefined
                }
                reportScratchEventsTiles={reportScratchEventsTiles}
                rows={data}
              />
            </Fragment>
          ),
        }}
      />
    );
  }, [
    viewMode,
    data,
    isExpandedTextMode,
    isMobile,
    isLoading,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    renderHeader,
    renderSelectAll,
    reportScratchEventsTiles,
  ]);
}
