import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { DuplicateUserFormValues } from "./DuplicateUserForm.model";
import { DuplicateDialogFormSchema } from "./DuplicateUserForm.schema";

export const makeEmptyUserManagementDuplicateFormValues = (
  merge?: Partial<DuplicateUserFormValues>
): DuplicateUserFormValues => ({
  email: merge?.email || "",

  firstName: merge?.firstName || "",

  lastName: merge?.lastName || "",

  id: merge?.id || "",
});

type UserManagementDuplicateFormArgs = {
  initial?: Partial<DuplicateUserFormValues>;
};

export function UserManagementDuplicateForm(
  params?: UserManagementDuplicateFormArgs
) {
  const { initial } = params || {};

  const emptyFormValues = makeEmptyUserManagementDuplicateFormValues();
  const formHook = useForm<DuplicateUserFormValues>({
    mode: "all",
    resolver: joiResolver(DuplicateDialogFormSchema),
    defaultValues: { ...emptyFormValues, ...initial },
  });

  return formHook;
}
