import {
  Box,
  Button,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
  paperClasses,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { InfoPopoverProps } from "./InfoPopover.model";
import { InfoPopoverClasses } from "./InfoPopover.const";

export const InfoPopover = ({
  id,
  anchorEl,
  open,
  handleClosePopover,
  children,
  width = "440px",
  title,
  ...props
}: InfoPopoverProps) => {
  const { palette } = useTheme();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        [`.${paperClasses.root}`]: {
          overflow: "inherit !important",
        },
        top: "12px",
        minWidth: "440px",
        left: "7px",
        bottom: "76px",
        ...props.sx,
      }}
    >
      <Box width={width}>
        <Stack
          direction="row"
          justifyContent="space-between"
          py={2}
          px={3}
          align-items="center"
        >
          <Stack direction="row">
            <InfoIcon
              color="primary"
              sx={{
                width: "24px",
                height: "24px",
                margin: "4px 8px 0px 0px",
              }}
            />
            <Typography variant="h6">{title}</Typography>
          </Stack>
          <Button
            sx={{ p: 0, justifyContent: "end", minWidth: "25px" }}
            onClick={handleClosePopover}
          >
            <CloseIcon color="action" />
          </Button>
        </Stack>
        <Divider />
        {children}
        <Divider />
        <Stack
          py={"10px"}
          px={3}
          direction={"row"}
          justifyContent={"end"}
          className={InfoPopoverClasses.popoverPointer}
          sx={{
            [`&.${InfoPopoverClasses.popoverPointer}::after`]: {
              content: '""',
              position: "absolute",
              width: 0,
              height: 0,
              bottom: "20px",
              left: "0px",
              boxSizing: "border-box",
              border: `10px solid ${palette.action.active}`,
              borderColor: `transparent transparent ${palette.info.contrastText} ${palette.info.contrastText}`,
              transformOrigin: "0 0",
              transform: "rotate(45deg)",
              boxShadow: "-2px 2px 4px 0 rgba(92, 113, 132, 0.12)",
            },
          }}
        >
          <Button
            variant="text"
            color="error"
            sx={{ width: "57px" }}
            onClick={handleClosePopover}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
};
