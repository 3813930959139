import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "src/api/useSessionContext";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { WatchQueryCreateDialog } from "src/components/WatchQueryCreateDialog/WatchQueryCreateDialog";
import { useWatchQueryKeywordForm } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.hook";
import { WatchQueryKeywordFormSchema } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.schema";
import { WatchQueryFormSimple } from "src/components/WatchQueryFormSimple/WatchQueryFormSimple";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";

function makeEmptyPageHeader(sources: string[] | null) {
  if (sources === null) {
    return "Search your mention or brand across TV, Radio, Podcasts, and YouTube";
  }

  return sources.reduce((res, next, index) => {
    const nextKey =
      next === "Television" ? "TV" : next === "Podcast" ? "Podcasts" : next;
    const nextTitlePart =
      index === 0
        ? ` ${nextKey}`
        : index === sources.length - 1
        ? ` and ${nextKey}`
        : `, ${nextKey}`;
    return `${res}${nextTitlePart}`;
  }, "Search your mention or brand across");
}

export const WatchListEmpty = () => {
  const { effectiveAccessFilter } = useSessionContext();
  const { contentTypes } = effectiveAccessFilter;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const navigate = useNavigate();

  const formHook = useWatchQueryKeywordForm({
    schema: WatchQueryKeywordFormSchema,
  });
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const formValues = formHook.watch();

  const handleShowMoreOptions = () => setShowMoreOptions(true);
  const handleHideMoreOptions = () => setShowMoreOptions(false);

  const header = (
    <Typography variant="h5" align="center" mb={3}>
      {makeEmptyPageHeader(contentTypes.included)}
    </Typography>
  );

  const footer = (
    <Typography
      variant="h6"
      color="text.secondary"
      align="center"
      mt={isMobile ? 5 : 12}
      mb={5}
      maxWidth={540}
      alignSelf="center"
    >
      No saved watch terms yet.
      <Typography variant="body1" minWidth={340}>
        To view content, please add at least one search term.
      </Typography>
    </Typography>
  );

  const commonContent = (
    <WatchQueryCreateDialog
      title="More options"
      open={showMoreOptions}
      onClose={handleHideMoreOptions}
      onSubmit={() => navigate(powerSearchResultRoute.path)}
      initial={formValues}
      closeOnLeftSide={isMobile}
    />
  );
  const moreOptionsButton = (
    <Button
      id="watch-list-more-options-button"
      sx={{ textTransform: "uppercase" }}
      onClick={handleShowMoreOptions}
    >
      More Options <ExpandMoreOutlined />
    </Button>
  );

  if (isMobile) {
    return (
      <PageLayoutMobile
        content={
          <FormProvider {...formHook}>
            <Stack my="auto" maxWidth={337} mx="auto">
              {header}
              <WatchQueryFormSimple />
              <Box alignSelf="center" mt={4}>
                {moreOptionsButton}
              </Box>
              {footer}
            </Stack>
            {commonContent}
          </FormProvider>
        }
      />
    );
  }

  return (
    <PageLayoutDesktop
      overflow="scroll"
      content={
        <FormProvider {...formHook}>
          <Stack mt="25%">
            {header}
            <WatchQueryFormSimple />
            <Box alignSelf="flex-start" mt={1}>
              {moreOptionsButton}
            </Box>
            {footer}
          </Stack>
          {commonContent}
        </FormProvider>
      }
    />
  );
};
