import { SnackbarKey, useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export function SnackCloseButton({ snackKey }: { snackKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackKey)} color="inherit">
      <Close color="inherit" />
    </IconButton>
  );
}
