import { EventType } from "src/models/EventType";

export const getEventTypeLabel = (type: string) => {
  switch (type) {
    case EventType.Logo:
      return "Logo";
    case EventType.Radio:
      return "Broadcast (Radio)";
    case EventType.BCast:
      return "Broadcast (TV)";
    case EventType.PCast:
      return "Podcast";
    case EventType.YouTube:
      return "Youtube";
    default:
      return "Unknown event type";
  }
};
