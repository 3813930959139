import { DeleteOutline, Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import {
  getBackgroundColor,
  getAlertIcon,
  getIconColor,
  getAlertName,
  getAlertScheduleTime,
} from "./ScheduledAlertCardView.utils";
import { ScheduledAlertType } from "../ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  preperaMonthlyValue,
  scheduledAlertTypeDict,
} from "../ScheduledAlertPopover.utils";

type ScheduledAlertCardViewProps = {
  type: ScheduledAlertType;
  enabled?: boolean;
  value?: string[];
  onEdit: () => void;
  onDelete: () => void;
  sx?: SxProps;
};

export const ScheduledAlertCardView = ({
  type,
  enabled,
  value,
  onEdit,
  onDelete,
  sx,
}: ScheduledAlertCardViewProps) => {
  const { palette, breakpoints } = useTheme();
  const darkMode = palette.mode === "dark";

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const Icon = getAlertIcon(type);
  const iconColor = getIconColor(palette, type);
  const backgroundColor = getBackgroundColor(palette, type, darkMode);
  const ruleValue =
    scheduledAlertTypeDict[type] === AlertTypesKey.monthly
      ? preperaMonthlyValue(value)
      : value;

  return (
    <Card
      sx={{
        border: `1px solid ${palette.action.disabledBackground}`,
        minHeight: 84,
        width: isMobile ? "100%" : "auto",
        opacity: enabled ? 1 : 0.54,
        ...sx,
      }}
    >
      <CardContent sx={{ p: 1, pl: 2, "&:last-child": { pb: 1 } }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <Box
              borderRadius="50%"
              sx={{ backgroundColor }}
              width={32}
              height={32}
            >
              <Icon sx={{ color: iconColor, mt: 0.5, ml: 0.5 }} />
            </Box>
            <Tooltip title={getAlertName(type)}>
              <TextLineClamp variant="subtitle1" lineClamp={1}>
                {getAlertName(type)}
              </TextLineClamp>
            </Tooltip>
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <IconButton disabled={!enabled} onClick={onDelete}>
              <DeleteOutline sx={{ color: "text.primary" }} />
            </IconButton>
            <IconButton disabled={!enabled} onClick={onEdit}>
              <Edit sx={{ color: "text.primary" }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack flexDirection="row" mt={1} gap={1}>
          {ruleValue?.map((item) => (
            <Tooltip title={getAlertScheduleTime(type, item)} key={item}>
              <Typography
                variant="body2"
                color="text.secondary"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {getAlertScheduleTime(type, item)}
              </Typography>
            </Tooltip>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
