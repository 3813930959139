import { ChangeEvent, useState } from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import {
  useWatchQueryAlertCreate,
  OriginType,
} from "src/api/useWatchQueryAlertCreate";
import { setSkippedInstantAlert } from "src/utils/instantAlertHelpers";
import { UserQueryDTO } from "src/models/UserQueryDTO";
import { DialogPrompt } from "../DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "../DialogPrompt/DialogPrompt.model";

type WatchQueryInstantAlertDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  queryId?: UserQueryDTO["id"];
  toggleInstantAlert?: () => void;
  origin: OriginType;
};

export const WatchQueryInstantAlertDialog = ({
  onClose,
  origin,
  queryId,
  toggleInstantAlert,
  ...props
}: WatchQueryInstantAlertDialogProps) => {
  const [skipInstantAlert, setSkipInstantAlert] = useState(false);

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: onClose,
      origin,
      type: "instant",
    },
  });

  const instantAlertCreate = () => {
    if (!queryId && toggleInstantAlert) {
      toggleInstantAlert();
      onClose && onClose();
      return;
    }
    if (!queryId) return;

    alertCreate.mutate({
      params: {
        path: {
          queryId,
        },
        query: {
          type: "realtime",
          archive: false,
        },
      },
    });
  };

  const handleInstantAlert = () => {
    if (!skipInstantAlert) return instantAlertCreate();

    setSkippedInstantAlert();
    instantAlertCreate();
  };

  const content = (
    <Stack gap={1} pb={1}>
      <Typography variant="body1">
        Are you sure you want to turn on notifications? You will receive alerts
        for important updates and information.
      </Typography>
      <Stack flexDirection="row">
        <FormControlLabel
          control={<Checkbox checked={skipInstantAlert} />}
          label="Don't show this message again"
          onChange={(_e: ChangeEvent<unknown>, checked: boolean) => {
            const value = checked;
            setSkipInstantAlert(value);
          }}
        />
      </Stack>
    </Stack>
  );

  return (
    <DialogPrompt
      title="Instant notification"
      children={content}
      onDecline={onClose}
      onConfirm={() => handleInstantAlert()}
      confirmLabel="Yes"
      sx={{ margin: "auto" }}
      {...props}
    />
  );
};
