import { useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import { useMediaCenterInfinite } from "src/api/useMediaCenterInfinite";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useListFilter } from "src/utils/useListFilter";
import { useQueryDateRangeWithEmptyDefault } from "src/utils/useQueryDateRangeWithEmptyDefault";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { useEventSelectionMediaCenter } from "src/api/useEventSelectionMediaCenter";
import {
  MediaCenterNoFilteredEventsOverlay,
  MediaCenterNoSavedEventsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { MediaCenterTile } from "./MediaCenterTile";
import { MediaCenterTilesProps } from "./MediaCenterTiles.model";

export function MediaCenterTiles({
  id,
  filterText,
  filterQuery,
  lineClamp,
  sortBy,
  orderBy,
  downloadMediaItem,
  addItemToReport,
  renderHeader,
  hasFilters,
}: MediaCenterTilesProps) {
  const [activePlayerId, setActivePlayerId] = useState<string | null>(null);
  const [dateRange] = useQueryDateRangeWithEmptyDefault();
  const isIOS = window?.navigator?.platform === "iPhone";

  const mediaCenterResults = useMediaCenterInfinite({
    request: {
      params: {
        body: makeQueryDefinition(filterQuery),
        query: {
          startDateTime: dateRange[0]
            ? formatISO(startOfDay(dateRange[0]))
            : undefined,
          endDateTime: dateRange[1]
            ? formatISO(endOfDay(dateRange[1]))
            : undefined,
          sortby: sortBy,
          orderby: orderBy,
          tags: filterQuery.tags.map((item) => item.tagName).join(","),
        },
      },
    },
    options: {},
  });

  // FIXME: use server side filtering once backend support is available
  const combinedData = combineInfiniteTilesData(mediaCenterResults.data?.pages);
  const filterData = useListFilter(combinedData || [], filterText, ["title"]);
  const isLoading =
    mediaCenterResults.isLoading || mediaCenterResults.isFetching;

  const selectionState = useEventSelectionMediaCenter();

  const setPlayerIdWrapper = useCallback((id: string, isPlaying: boolean) => {
    if (isPlaying) {
      setActivePlayerId((prevActivePlayerId) =>
        prevActivePlayerId !== id ? id : prevActivePlayerId
      );
    }
  }, []);

  const renderItem = useCallback(
    (data: MediaCenterItem) => {
      const isChecked = !!selectionState.list.find(
        (event) => event.id === data.id
      );
      return (
        <MediaCenterTile
          key={data.id}
          value={data}
          lineClamp={lineClamp}
          isChecked={isChecked}
          isPlaying={activePlayerId === data.id}
          onChangePlayState={setPlayerIdWrapper}
          downloadMediaItem={downloadMediaItem}
          addItemToReport={addItemToReport}
        />
      );
    },
    [
      activePlayerId,
      lineClamp,
      selectionState.list,
      setPlayerIdWrapper,
      downloadMediaItem,
      addItemToReport,
    ]
  );

  const handleScroll = useScrollQueryLoader({
    query: mediaCenterResults,
  });

  return (
    <ScrollHeaderLayout
      id={id}
      onScroll={handleScroll}
      renderHeader={renderHeader}
      isLoading={isLoading}
      children={
        <Stack
          pl={2}
          pr={isIOS ? 2 : 1}
          pb={1}
          flex={1}
          children={
            <TileLayout<MediaCenterItem>
              rows={filterData}
              renderItem={renderItem}
              renderEmpty={
                hasFilters
                  ? MediaCenterNoFilteredEventsOverlay
                  : MediaCenterNoSavedEventsOverlay
              }
            />
          }
        />
      }
    />
  );
}
