import { useEffect } from "react";
import { InProductNotification } from "src/models/InProductNotification";
import { NotificationType } from "src/models/NotificationType";
import { usePushNotificationContext } from "src/providers/PushNotificationProvider/PushNotificationProvider";

export function usePushNotificationEvent({
  key,
  cb,
}: {
  key: NotificationType;
  cb: (data: InProductNotification) => void;
}) {
  const { emitter } = usePushNotificationContext();

  useEffect(() => {
    emitter.on(key, cb);
    return () => {
      emitter.off(key, cb);
    };
  }, [cb, emitter, key]);
}
