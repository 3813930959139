import {
  TextField,
  TextFieldProps,
  formLabelClasses,
  inputBaseClasses,
  useTheme,
} from "@mui/material";

export type TextInputBaseProps = TextFieldProps & {
  background?: string;
};

/**
 * Light abstraction over standard TextField input with additional properties that all inputs must have.
 */
export function TextInputBase({ background, ...props }: TextInputBaseProps) {
  const { palette } = useTheme();

  background = background || palette.background.paper;

  return (
    <TextField
      {...props}
      sx={{
        ...props.sx,
        [`.${formLabelClasses.root}`]: {
          zIndex: 1,
        },
        [`.${inputBaseClasses.root}`]: {
          background,
        },
      }}
    />
  );
}
