import axios from "axios";
import { add, sub } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";

const apiPath: keyof paths = "/api/broadcaststation/{station}/programschedule";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export type StationBroadcastData = Response;
export const snapShotQueryKey = apiPath;

export type UseStationBroadcastProgramArgs = {
  params: {
    fetchParams: DateTimeSearchFormValues | null;
    timeZone?: string;
  };
  options?: Pick<UseQueryOptions<Response>, "onSuccess" | "onError">;
};

export function useStationBroadcastProgram({
  params,
  options,
}: UseStationBroadcastProgramArgs) {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const { fetchParams, timeZone } = params || {};
  const programDateRange: [Date, Date] | null =
    fetchParams?.dateTime && timeZone
      ? [
          sub(utcToZonedTime(fetchParams.dateTime, timeZone), { hours: 10 }),
          add(utcToZonedTime(fetchParams.dateTime, timeZone), { hours: 14 }),
        ]
      : null;

  const queryParams: Required<Endpoint["parameters"]["query"]> =
    programDateRange
      ? {
          stationStartDateTime: programDateRange[0].toISOString(),
          stationEndDateTime: programDateRange[1].toISOString(),
        }
      : undefined;

  const pathParams: Required<Endpoint["parameters"]["path"]> = {
    station: `${fetchParams?.station?.value}`,
  };

  return useQuery<Response>({
    keepPreviousData: true,
    enabled: !!queryParams && !!fetchParams,
    ...options,
    queryKey: [snapShotQueryKey, params],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiPath, {
        path: pathParams,
        query: queryParams,
      });

      const { data } = await axios<Response>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
