import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { parseISO } from "date-fns";
import { useManagementView } from "src/api/useManagementView";
import { TextInputBase } from "src/components/TextInputBase";
import { NewAccountFormValues } from "../../AccountsManagementCreateAccount.model";
import { useCountries } from "../../hooks/useCountries";
import { ORGANIZATION_TYPES } from "./AccountsManagementNewAccountForm.const";
import { timezoneData } from "src/utils/timezoneData";

export function AccountsManagementNewAccountForm() {
  const { control, setValue } = useFormContext<NewAccountFormValues>();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { data } = useManagementView();
  const countriesList = useCountries();

  useEffect(() => {
    setValue("accountRepresentative", data?.email || "");
  }, [data?.email, setValue]);

  return (
    <Grid container spacing={isMobile ? 1.5 : 3}>
      <Grid
        item
        xs={12}
        mt={4}
        pt={4}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          Account Information
        </Typography>
        <Grid item xs={12} md={6} justifyContent={"end"} display={"flex"}>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              }
              label="Active"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container spacing={isMobile ? 1.5 : 3} mb={1} m={2} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Organization Name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="organizationType"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Organization Type</InputLabel>
                <Select
                  {...field}
                  id="organizationType"
                  label="Organization Type"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  onBlur={field.onBlur}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      width: 100,
                    },
                  }}
                >
                  {ORGANIZATION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="country"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  {...field}
                  id="country"
                  label="Country"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  onBlur={field.onBlur}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      width: 100,
                    },
                  }}
                >
                  {countriesList.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="City"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="stateOrProvince"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="State"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="postalCode"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Zip Code"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="creationDate"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  label="Creation Date"
                  value={
                    typeof field.value === "string"
                      ? parseISO(field.value ?? "")
                      : field.value
                  }
                  disabled
                  format="MMM dd ,yyyy"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: "outlined",
                    },
                  }}
                  slots={{
                    textField: TextInputBase,
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="expirationDate"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  label="Expires"
                  format="MMM dd ,yyyy"
                  value={
                    typeof field.value === "string"
                      ? parseISO(field.value ?? "")
                      : field.value
                  }
                  minDate={new Date()}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: "outlined",
                      autoComplete: "off",
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message || " ",
                      onBlur: field.onBlur,
                    },
                  }}
                  slots={{
                    textField: TextInputBase,
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="accountRepresentative"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  disabled
                  label="Account Representative"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Time Zone</InputLabel>
                <Select
                  {...field}
                  id="timezone"
                  label="Time Zone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 300,
                      width: 100,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  }}
                  inputProps={{
                    sx: {
                      display: "grid",
                    },
                  }}
                >
                  {timezoneData.map((timezone) => (
                    <MenuItem key={timezone.Id} value={timezone.Id}>
                      <Typography
                        variant="inherit"
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {timezone.Display}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} mt={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="isTrial"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              }
              label="Trial"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );
}
