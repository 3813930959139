import { useMemo, useRef, useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { WatchQueryCustomFormValues } from "./WatchQueryCustomForm.model";
import { AdvertisementPicker } from "../AdvertisementPicker/AdvertisementPicker";
import { ProgramTagPicker } from "../ProgramTagPicker/ProgramTagPicker";
import { SourcesPicker } from "../SourcesPicker/SourcesPicker";
import { TextInputBase } from "../TextInputBase";
import { PopperInfo } from "../PopperInfo/PopperInfo";
import { CustomQueryInput } from "../CustomQueryInput/CustomQueryInput";
import { MonacoCodeEditor } from "../CodeEditor/MonacoCodeEditor";

export function WatchQueryCustomForm({
  loading,
  layout,
  readOnly = false,
}: {
  layout: "create" | "update";
  loading?: boolean;
  readOnly?: boolean;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { control } = useFormContext<WatchQueryCustomFormValues>();

  const dropDownBoundsEl = useRef<HTMLDivElement | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isIOS = window?.navigator?.platform === "iPhone";

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const customQueryRow = useMemo(
    () => (
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Custom Query
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="customQuery"
            control={control}
            render={({ field, fieldState }) => {
              return readOnly ? (
                <MonacoCodeEditor
                  value={field.value}
                  onChange={field.onChange}
                  readOnly
                />
              ) : (
                <CustomQueryInput
                  value={field.value || ""}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    ),
    [control, isMobile, readOnly]
  );

  const displayNameRow = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid item xs={12} md={12} lg={2}>
        <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
          Display name
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextInputBase
                label="Display name"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={loading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || " "}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Stack pt={5} pr={isIOS ? "8px" : "initial"}>
      {/* create and updates have slight differences in row order */}
      {layout === "create" ? customQueryRow : displayNameRow}
      {layout === "update" ? customQueryRow : displayNameRow}

      <Grid container visibility="hidden">
        {/* this is dimension element to align autocomplete dropdown */}
        <Grid item xs={12} md={12} lg={2} />
        <Grid item xs={12} md={12} lg={10} ref={dropDownBoundsEl} />
      </Grid>

      {/* Sources */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Source
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          <Controller
            name="sourcesInclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  dropDownBoundsEl={dropDownBoundsEl}
                  id="source-include"
                  label="Include"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          <Controller
            name="sourcesExclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  dropDownBoundsEl={dropDownBoundsEl}
                  id="source-exclude"
                  label="Exclude"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Sources */}

      {/* Advertisement */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            height={36.5}
          >
            Advertisement
            <Info
              onClick={handleClick}
              sx={{ ml: 1, color: colors.blueGrey[200], cursor: "pointer" }}
            />
            <PopperInfo
              open={open}
              anchorEl={anchorEl}
              title="Advertisement"
              text="The TVEyes platform introduces a robust and user-centric tool designed to enhance your experience in monitoring and analyzing advertising content across broadcast television and radio channels. This feature-rich popover provides versatile options for managing advertisements with a focus on convenience and customization."
              onClose={handleClose}
            />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          mb={3}
          display="flex"
          alignItems="center"
        >
          <Controller
            name="advertisement"
            control={control}
            render={({ field }) => {
              return (
                <AdvertisementPicker
                  fullWidth
                  disabled={loading}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5} padding={0} /* layout spacer */ />
      </Grid>
      {/* Advertisement */}

      {/* Program title */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Program Title
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programInclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-include-input"
                  label="Include"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Controller
            name="programExclude"
            control={control}
            render={({ field, fieldState, formState }) => {
              const { list, logic } = formState.errors[field.name] || {};
              const message = list?.message || logic?.message || " ";

              return (
                <ProgramTagPicker
                  id="program-exclude-input"
                  label="Exclude"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  getOptionLabel={(o) => o.name}
                  disabled={loading}
                  error={!!fieldState.error}
                  helperText={message}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
