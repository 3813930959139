import { ReactNode } from "react";
import {
  Button,
  ButtonProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add } from "@mui/icons-material";

export function ContainerEmpty({
  buttonTitle,
  title,
  subtitle,
  onButtonClick,
  buttonProps,
}: {
  buttonTitle: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  onButtonClick: () => void;
  buttonProps?: Omit<ButtonProps, "children" | "onClick">;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        px: 2,
      }}
    >
      <Button
        startIcon={<Add />}
        variant="contained"
        color="secondary"
        sx={{ width: 177, height: 42, mb: 2 }}
        onClick={onButtonClick}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
      <Typography
        variant="h6"
        color={theme.palette.text.secondary}
        textAlign="center"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="caption"
          color={theme.palette.text.secondary}
          textAlign="center"
          fontSize={isMobile ? 14 : 12}
          sx={{ mt: 1 }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}
