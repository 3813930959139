import { Add } from "@mui/icons-material";
import { Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { useWatchQueryUpdate } from "src/api/useWatchQueryUpdate";
import { useReadOnlySnackbar } from "src/api/usReadOnlySnackbar";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { makeWatchQueryCustomFormValues } from "src/utils/makeWatchQueryFormValues";
import { DialogBase } from "../DialogBase/DialogBase";
import { WatchQueryCustomForm } from "../WatchQueryCustomForm/WatchQueryCustomForm";
import { useWatchQueryCustomForm } from "../WatchQueryCustomForm/WatchQueryCustomForm.hook";
import { WatchQueryCustomFormValues } from "../WatchQueryCustomForm/WatchQueryCustomForm.model";
import { WatchQueryCustomFormSchema } from "../WatchQueryCustomForm/WatchQueryCustomForm.schema";
import { LoadingButton } from "../buttons/LoadingButton";
import { WatchQueryUpdateDialogAdvancedProps } from "./WatchQueryUpdateDialogAdvanced.model";
import { CustomQueryReadonlySnackbar } from "../CustomQueryReadonlySnackbar/CustomQueryReadonlySnackbar";

export function WatchQueryUpdateDialogAdvanced({
  onClose,
  initial,
  ...props
}: WatchQueryUpdateDialogAdvancedProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const readOnlySnackbar = useReadOnlySnackbar();

  const defaultFormValues = useMemo(
    () => makeWatchQueryCustomFormValues(initial),
    [initial]
  );

  const handleOnClose = () => {
    if (readOnlySnackbar.isOpen) {
      readOnlySnackbar.hide();
    }
    onClose && onClose();
  };

  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSettled: handleOnClose,
    },
  });

  const watchQueryUpdate = useWatchQueryUpdate({
    options: {
      onSuccess: handleOnClose,
    },
  });

  const formHook = useWatchQueryCustomForm({
    initial: defaultFormValues,
    schema: WatchQueryCustomFormSchema,
  });

  const { formState, handleSubmit, reset } = formHook;
  const { isSubmitting, isDirty, isValid, errors } = formState;

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultFormValues);
    }
  }, [reset, props.open, defaultFormValues]);

  const isBusy = isSubmitting || watchQueryCreate.isLoading;

  const submitWatchQueryCreate = handleSubmit(
    (data: WatchQueryCustomFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryCreate:error", errors);
    }
  );

  const submitWatchQueryUpdate = handleSubmit(
    (data: WatchQueryCustomFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryUpdate.mutate({
        id: initial.id,
        title: data.displayName,
        userId: initial.userId,
        sort: initial.sort,
        alerts: [],
        queryBuilderDefinition: query,
      });

      handleOnClose();
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchQueryUpdate:error", errors);
    }
  );

  const isSubmitDisabled = isBusy || !isDirty || !isValid || !isEmpty(errors);

  return (
    <>
      <FormProvider {...formHook}>
        <DialogBase
          fullWidth
          maxWidth="lg"
          onClose={handleOnClose}
          {...props}
          children={
            <WatchQueryCustomForm layout="update" readOnly={isMobile} />
          }
          footer={
            <Stack
              direction="row"
              justifyContent={isMobile ? "justify-between" : undefined}
              width={isMobile ? "100%" : undefined}
              spacing={2}
              minHeight={42}
            >
              <Hidden lgDown>
                <Button
                  sx={{ width: 100, display: { xs: "none", md: "flex" } }}
                  onClick={handleOnClose}
                  color="error"
                >
                  Cancel
                </Button>
              </Hidden>

              <LoadingButton
                variant="contained"
                color="info"
                id="update-watch-query"
                loading={isBusy}
                disabled={isSubmitDisabled}
                startIcon={<Add />}
                onClick={submitWatchQueryCreate}
                sx={{
                  minWidth: isMobile ? 160 : 176,
                  width: isMobile ? "100%" : undefined,
                  flex: {
                    xs: 1,
                    sm: 0,
                  },
                }}
              >
                Watch term
              </LoadingButton>

              <LoadingButton
                variant="contained"
                color="primary"
                loading={isBusy}
                disabled={isSubmitDisabled}
                onClick={submitWatchQueryUpdate}
                sx={{
                  minWidth: isMobile ? 160 : 176,
                  width: isMobile ? "100%" : undefined,
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          }
        />
      </FormProvider>
      <CustomQueryReadonlySnackbar />
    </>
  );
}
