import { useMemo, useState } from "react";
import { useDateTimeSearchDetails } from "src/api/useDateTimeSearchDetails";
import { useEventByStationAndDate } from "src/api/useEventByStationAndDate";
import { ClipEditorPage } from "src/components/ClipEditorPage/ClipEditorPage";
import { ShiftPlayerDataProps } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";

export const DateTimeSearchClipEditorPage = () => {
  const details = useDateTimeSearchDetails({});

  const [dateTime, setDateTime] = useState<undefined | ShiftPlayerDataProps>(
    undefined
  );

  const dateTimeEventDetails = useEventByStationAndDate({
    request: {
      query: {
        stationUUID: details.data?.sourceId ?? "",
        startDateTime: dateTime?.startDateTime ?? "",
        endDateTime: dateTime?.endDateTime ?? "",
      },
    },
    body: {},
    options: {
      enabled: Boolean(dateTime),
    },
  });

  const event = useMemo(() => {
    if (dateTime && dateTimeEventDetails.data) {
      return (
        {
          ...details.data,
          startDateTime: dateTime.startDateTime,
          endDateTime: dateTime.endDateTime,
          transcript: dateTimeEventDetails.data.transcript,
          transcriptLines: dateTimeEventDetails.data.transcriptLines,
          transcriptLineBundles:
            dateTimeEventDetails.data.transcriptLineBundles,
        } || {}
      );
    }

    return details.data || {};
  }, [details.data, dateTimeEventDetails.data, dateTime]);

  const onShiftPlayerPlay = (data: ShiftPlayerDataProps) => setDateTime(data);

  return (
    <ClipEditorPage
      initialStartDateTime={details.data?.startDateTime}
      event={event}
      loading={details.isLoading}
      shiftOffset={dateTime?.shiftOffset}
      onShiftPlayerPlay={onShiftPlayerPlay}
      routeRoot="Date/Time search"
    />
  );
};
