import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  useTheme,
} from "@mui/material";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";

export function DeleteIconButton({
  onClick,
  disabled = false,
  size,
}: {
  onClick: () => void;
  disabled?: boolean;
  size?: ToggleButtonGroupProps["size"];
}) {
  const { palette } = useTheme();

  return (
    <ToggleButtonGroup size={size}>
      <ToggleButton value={true} onClick={onClick} disabled={disabled}>
        <DeleteOutlineOutlined
          sx={{
            color: disabled ? palette.text.disabled : palette.text.primary,
          }}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
