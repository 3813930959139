import { useRef } from "react";
import { alpha, Box, Stack, Typography, useTheme } from "@mui/material";
import { MonacoCodeEditor } from "src/components/CodeEditor/MonacoCodeEditor";
import { useOpenState } from "src/utils/useOpenState";
import { PopperAndContent } from "./PopperAndContent";
import { PopperORContent } from "./PopperORContent";
import { PopperNotContent } from "./PopperNotContent";
import { PopperTildaContent } from "./PopperTildaContent";
import { CodeEditorControls } from "./CodeEditorControls";
import { CodeEditoOperatorBlock } from "./CodeEditorOperatorBlock";
import { AppPopper } from "../AppPopper/AppPopper";
import { AppPopperArrowSize } from "../AppPopper/components/AppPopperArrow/AppPopperArrow.model";

export function CodeEditorHelpSection({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string | undefined) => void;
}) {
  const { palette } = useTheme();
  const containerAndRef = useRef<HTMLDivElement>(null);
  const containerOrRef = useRef<HTMLDivElement>(null);
  const containerNotRef = useRef<HTMLDivElement>(null);
  const containerTildaRef = useRef<HTMLDivElement>(null);

  const popperAndState = useOpenState();
  const popperOrState = useOpenState();
  const popperNotState = useOpenState();
  const popperTildaState = useOpenState();

  const addOperator = (v: string) => {
    if (!value) return onChange(`${v} `);

    const lastCharacter = value[value?.length - 1];
    const outputValue = `${value}${lastCharacter === " " ? `${v} ` : ` ${v} `}`;
    onChange(outputValue);
  };

  return (
    <Stack mt={2} gap={1}>
      <Typography variant="subtitle2">Syntax help</Typography>
      <Stack flexDirection="row" gap={1}>
        <Typography variant="body2">Use</Typography>
        <Box
          height={20}
          py={0}
          px={1}
          sx={{
            backgroundColor: alpha(palette.info.main, 0.04),
            borderRadius: 1.5,
            border: `1px solid ${alpha(palette.info.main, 0.12)}`,
          }}
        >
          "&nbsp;&nbsp;"
        </Box>
        <Typography variant="body2">
          for a specific sentence or exact phrase. Click on the operator to add
          it:
        </Typography>
      </Stack>
      <Stack flexDirection="row" gap={1}>
        <CodeEditoOperatorBlock
          containerRef={containerAndRef}
          onMouseEnter={popperAndState.show}
          onMouseLeave={popperAndState.hide}
          onClick={addOperator}
          label="AND"
        />
        <CodeEditoOperatorBlock
          containerRef={containerOrRef}
          onMouseEnter={popperOrState.show}
          onMouseLeave={popperOrState.hide}
          onClick={addOperator}
          label="OR"
        />
        <CodeEditoOperatorBlock
          containerRef={containerNotRef}
          onMouseEnter={popperNotState.show}
          onMouseLeave={popperNotState.hide}
          onClick={addOperator}
          label="NOT"
        />
        <CodeEditoOperatorBlock
          containerRef={containerTildaRef}
          onMouseEnter={popperTildaState.show}
          onMouseLeave={popperTildaState.hide}
          onClick={addOperator}
          label="~"
        />
      </Stack>
      <AppPopper
        onClose={popperAndState.hide}
        open={popperAndState.isOpen}
        anchorEl={containerAndRef.current}
        placement="bottom"
        arrow={AppPopperArrowSize.small}
        children={<PopperAndContent />}
        paperColor="rgb(4, 36, 65)"
      />
      <AppPopper
        onClose={popperOrState.hide}
        open={popperOrState.isOpen}
        anchorEl={containerOrRef.current}
        placement="bottom"
        arrow={AppPopperArrowSize.small}
        children={<PopperORContent />}
        paperColor="rgb(4, 36, 65)"
      />
      <AppPopper
        onClose={popperNotState.hide}
        open={popperNotState.isOpen}
        anchorEl={containerNotRef.current}
        placement="bottom"
        arrow={AppPopperArrowSize.small}
        children={<PopperNotContent />}
        paperColor="rgb(4, 36, 65)"
      />
      <AppPopper
        onClose={popperTildaState.hide}
        open={popperTildaState.isOpen}
        anchorEl={containerTildaRef.current}
        placement="bottom"
        arrow={AppPopperArrowSize.small}
        children={<PopperTildaContent />}
        paperColor="rgb(4, 36, 65)"
      />
    </Stack>
  );
}

export function CodeEditorSection({
  value,
  onChange,
  readOnly = false,
}: {
  value?: string;
  onChange: (value?: string) => void;
  readOnly?: boolean;
}) {
  return (
    <>
      {!readOnly && (
        <Stack mb={1.5}>
          <CodeEditorControls value={value} onChange={onChange} />
        </Stack>
      )}
      <MonacoCodeEditor value={value} onChange={onChange} readOnly={readOnly} />
      {!readOnly && <CodeEditorHelpSection value={value} onChange={onChange} />}
    </>
  );
}
