import { Fragment, ReactNode, useMemo } from "react";
import { UseInfiniteQueryResult } from "react-query";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { LabelDisplayedRowsArgs } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid-premium";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ViewModeLayout } from "src/components/ViewModeLayout/ViewModeLayout";
import { ScrollHeaderLayoutProps } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout.model";
import { PowerSearchResultList } from "./components/PowerSearchResultList/PowerSearchResultList";
import { PowerSearchResultTable } from "./components/PowerSearchResultTable/PowerSearchResultTable";
import { PowerSearchResultTiles } from "./components/PowerSearchResultTiles/PowerSearchResultTiles";

export function usePowerSearchResultDataView({
  loading,
  data,
  viewMode,
  isMobile,
  isExpandedTextMode,
  rowCount,
  pagination,
  setPagination,
  addItemToReport,
  renderHeader,
  renderSelectAll,
  powerSearchResultsTiles,
  rows,
  renderCustomFooterEl,
  nextPageButtonIsDisabled,
  showDuplicatesDrawer,
  drawerParentEventId,
  renderLabelDisplayedRows,
  hasFilters,
}: {
  loading?: boolean;
  data: PowerSearchResultItem[];
  viewMode: ViewMode;
  isMobile?: boolean;
  isExpandedTextMode: boolean;
  rowCount?: number;
  pagination: GridPaginationModel;
  setPagination: (nextPagination: GridPaginationModel) => void;
  addItemToReport: (value: PowerSearchResultItem) => void;
  renderHeader?: ScrollHeaderLayoutProps["renderHeader"];
  renderSelectAll: () => JSX.Element | null;
  powerSearchResultsTiles: UseInfiniteQueryResult;
  rows: PowerSearchResultItem[];
  renderCustomFooterEl?: () => ReactNode;
  nextPageButtonIsDisabled?: boolean;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  drawerParentEventId?: string;
  renderLabelDisplayedRows?: (
    paginationInfo: LabelDisplayedRowsArgs
  ) => ReactNode;
  hasFilters?: boolean;
}) {
  const [groupDuplicates] = useQueryParam(
    "grouped",
    withDefault(BooleanParam, true)
  );

  return useMemo(() => {
    //For podcast or youtube events
    const hideMarket = data?.every((item) => !item.market);

    return (
      <ViewModeLayout
        current={viewMode}
        viewMap={{
          table: (
            <PowerSearchResultTable
              id="power-search-result-table"
              rows={data}
              rowCount={rowCount}
              loading={loading}
              isMobile={isMobile}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              addItemToReport={addItemToReport}
              renderCustomFooterEl={renderCustomFooterEl}
              columnVisibilityModel={{
                duplicates: !!groupDuplicates,
                market: !hideMarket,
              }}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              renderLabelDisplayedRows={renderLabelDisplayedRows}
              hasFilters={hasFilters}
            />
          ),
          list: (
            <PowerSearchResultList
              id="power-search-result-list"
              rows={data}
              rowCount={rowCount}
              loading={loading}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              addItemToReport={addItemToReport}
              renderCustomFooterEl={renderCustomFooterEl}
              isExpandedTextMode={isExpandedTextMode}
              nextPageButtonIsDisabled={nextPageButtonIsDisabled}
              hideMarket={hideMarket}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              renderLabelDisplayedRows={renderLabelDisplayedRows}
              hasFilters={hasFilters}
            />
          ),
          tile: (
            <PowerSearchResultTiles
              id="power-search-result-tiles"
              addItemToReport={addItemToReport}
              renderHeader={
                isMobile
                  ? (params) => (
                      <Fragment>
                        {renderHeader && renderHeader(params)}
                        {renderSelectAll()}
                      </Fragment>
                    )
                  : undefined
              }
              powerSearchResultsTiles={powerSearchResultsTiles}
              rows={rows}
              renderCustomFooterEl={renderCustomFooterEl}
              isExpandedTextMode={isExpandedTextMode}
              showDuplicatesDrawer={showDuplicatesDrawer}
              drawerParentEventId={drawerParentEventId}
              hasFilters={hasFilters}
            />
          ),
        }}
      />
    );
  }, [
    loading,
    data,
    viewMode,
    isMobile,
    isExpandedTextMode,
    rowCount,
    pagination,
    setPagination,
    addItemToReport,
    renderHeader,
    renderSelectAll,
    powerSearchResultsTiles,
    rows,
    renderCustomFooterEl,
    groupDuplicates,
    nextPageButtonIsDisabled,
    showDuplicatesDrawer,
    drawerParentEventId,
    renderLabelDisplayedRows,
    hasFilters,
  ]);
}
