import { FocusEventHandler, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Autocomplete } from "@mui/material";
import {
  AccountAutocompleteResponse,
  useAccountAutocomplete,
} from "src/api/useAccountAutocomplete";
import { TextInputBase } from "../TextInputBase";

type OptionType = {
  label: string;
  value: string;
};

type AccountAutocompleteProps = {
  onChange: (value: string | Array<string> | null) => void;
  onBlur: FocusEventHandler<HTMLDivElement>;
  disabled?: boolean;
  multiple?: boolean;
  value: string | Array<string>;
  error?: boolean;
  helperText?: string;
};

function buildOption(value: string, options: Array<OptionType>) {
  if (!value || !options.length) return null;

  return options.find((option) => option.value === value) ?? null;
}

function buildOptions(
  value: Array<string> | string,
  options: Array<OptionType>
) {
  if (!Array.isArray(value)) {
    return buildOption(value, options);
  }

  return value.reduce((res: Array<OptionType>, next) => {
    const value = buildOption(next, options);
    if (value) {
      res.push(value);
    }

    return res;
  }, []);
}

function buildAccountOptions(
  selectedOptions: OptionType | Array<OptionType> | null,
  data?: AccountAutocompleteResponse
) {
  if (!data) return [];

  const { options = [] } = data;
  const fetchedOptions = options.map((result) => ({
    label: result.name,
    value: result.value,
  }));

  if (!Array.isArray(selectedOptions) && !selectedOptions)
    return fetchedOptions;

  if (!Array.isArray(selectedOptions) && selectedOptions) {
    const filteredFetchedOptions = fetchedOptions.filter(
      (i) => i.value !== selectedOptions.value
    );
    return [selectedOptions, ...filteredFetchedOptions];
  }

  const selectedIds = (selectedOptions || []).map((i) => i.value);
  const filteredFetchedOptions = fetchedOptions.filter(
    (i) => !selectedIds.includes(i.value)
  );
  return [...selectedOptions, ...filteredFetchedOptions];
}

function isFetchingRequired(
  isInitiallyFetched: boolean,
  value?: string | Array<string>
) {
  if (isInitiallyFetched || !value) return false;

  if (Array.isArray(value)) return value.length !== 0;

  return true;
}

export const AccountAutocomplete = ({
  onChange,
  onBlur,
  disabled,
  multiple,
  value,
  error,
  helperText,
}: AccountAutocompleteProps) => {
  const [isInitiallyFetched, setIsInitiallyFetched] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [searchText] = useDebounce(inputValue, 500);

  const { data: initialData } = useAccountAutocomplete({
    options: {
      enabled: isFetchingRequired(isInitiallyFetched, value),
    },
    request: {
      params: {
        query: {
          searchString: "",
        },
      },
      body: {
        includeAccountIds: !value ? [] : Array.isArray(value) ? value : [value],
        excludeAccountIds: [],
      },
    },
  });

  const { data } = useAccountAutocomplete({
    request: {
      params: {
        query: {
          searchString: searchText,
          maxPerGroup: 10,
        },
      },
      body: {
        includeAccountIds: [],
        excludeAccountIds: [],
      },
    },
  });

  const [options, setOptions] = useState(buildAccountOptions([], data));
  const [selectedValue, setSelectedValue] = useState<
    OptionType | Array<OptionType> | null
  >(buildOptions(value, options));

  useEffect(() => {
    if (isInitiallyFetched || !value || !initialData?.options) return;
    if (Array.isArray(value) && value.length === 0) return;

    const fetchedValue = multiple
      ? initialData.options
      : initialData.options[0];
    const values = Array.isArray(fetchedValue)
      ? fetchedValue.map((i) => ({ label: i.name, value: i.value }))
      : { label: fetchedValue.name, value: fetchedValue.value };
    setSelectedValue(values);
    setIsInitiallyFetched(true);
  }, [isInitiallyFetched, multiple, initialData, value]);

  useEffect(() => {
    setOptions(buildAccountOptions(selectedValue, data));
  }, [data, selectedValue]);

  useEffect(() => {
    if (Array.isArray(selectedValue)) return;

    if (selectedValue && inputValue === "") {
      setInputValue(selectedValue?.label ?? "");
    }
  }, [multiple, selectedValue, inputValue]);

  return (
    <Autocomplete
      value={selectedValue}
      id="accountId-field"
      multiple={multiple}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        if (!_event) return;

        setInputValue(newInputValue);
      }}
      onChange={(_e, selectedOption) => {
        if (Array.isArray(selectedOption)) {
          const value = selectedOption.map((option) => option.value) ?? [];
          setSelectedValue(selectedOption);
          return onChange(value);
        }
        const value = selectedOption?.value ?? null;
        setSelectedValue(selectedOption);
        onChange(value);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      onBlur={onBlur}
      options={options}
      getOptionLabel={(item) => item.label}
      //Ignore MUI warning - open issue for async search https://github.com/mui/material-ui/issues/29727
      isOptionEqualToValue={(option, currentValue) =>
        option.value === currentValue.value || currentValue.value === ""
      }
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <TextInputBase
          {...params}
          label="Account"
          placeholder="Account"
          error={error}
          helperText={helperText || " "}
        />
      )}
    />
  );
};
