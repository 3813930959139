import { Stack, Typography, alpha, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";

export function WatchQueryTableHeaderDay({
  caption,
  content,
  headerAlign,
}: {
  caption?: string;
  content: string;
} & Pick<GridColDef, "headerAlign" | "description">) {
  const { palette } = useTheme();

  return (
    <Stack direction="column">
      <Typography
        align={headerAlign}
        color={alpha(palette.text.primary, 0.65)}
        fontSize={10}
      >
        {caption}
        <br />
      </Typography>
      <Typography align={headerAlign} fontWeight={500} fontSize={14}>
        {content}
      </Typography>
      <Typography align={headerAlign} fontSize={10}>
        <br />
      </Typography>
    </Stack>
  );
}
