import {
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
  styled,
} from "@mui/material";
import { TooltipBaseProps } from "./TooltipBase.model";

export const TooltipBase = ({
  children,
  width,
  textAlign,
  hideArrow,
  ...props
}: TooltipBaseProps) => {
  const { palette } = useTheme();

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow={!hideArrow} {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: palette.primary.dark,
      borderRadius: "4px",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "14px",
      px: 1,
      py: 0.5,
      width: width || "unset",
      textAlign: textAlign || "center",
      fontFamily: "montserrat",
    },
    [`& .${tooltipClasses.arrow}:before`]: {
      backgroundColor: palette.primary.dark,
    },
  });

  return <StyledTooltip {...props}>{children}</StyledTooltip>;
};
