import { useEffect } from "react";
import { useQueryParams } from "use-query-params";
import { Typography, TypographyProps } from "@mui/material";
import { makeTranscriptHTML } from "src/utils/makeTranscriptHTML";

// extend global with interface merging
declare global {
  interface Window {
    TVEyes?: {
      setQueryParams?: (queryParams: Record<string, string>) => void;
    };
  }
}

export function TranscriptParser({
  transcript,
  clickable,
  variant = "inherit",
  color,
  highlightColor = "inherit",
  ...props
}: {
  transcript: string;
  clickable?: boolean;
  highlightColor?: string;
} & Omit<TypographyProps, "children">) {
  const setQueryParams = useQueryParams()[1];

  useEffect(() => {
    // this is to allow setting of query parameters from dangerously set HTML generated from transcript
    const updateQueryParamsWrapper = (queryParams: Record<string, string>) => {
      setQueryParams(queryParams);
    };

    // initialize global object
    if (!window.TVEyes) {
      window.TVEyes = {
        setQueryParams: updateQueryParamsWrapper,
      };
    }

    if (!window.TVEyes?.setQueryParams) {
      window.TVEyes.setQueryParams = updateQueryParamsWrapper;
    }

    return () => {
      delete window.TVEyes?.setQueryParams;
    };
  }, [setQueryParams]);

  const html = makeTranscriptHTML({ transcript, clickable });

  return (
    <Typography
      variant={variant}
      color={color}
      component="span"
      display="inline"
      {...props}
      sx={{
        a: {
          color: highlightColor,
          fontWeight: "bold",
          cursor: clickable ? "pointer" : "inherit",
        },
        ...props.sx,
      }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
