import { format, formatDuration, intervalToDuration } from "date-fns";
import { Link } from "@mui/icons-material";
import { Stack, StackProps, Tooltip } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { AppLink } from "src/components/AppLink/AppLink";
import { getEventTooltipTitle } from "src/utils/getEventIcon";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function PCastEpisode({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  const startDateTime = event.startDateTime
    ? new Date(event.startDateTime)
    : undefined;

  const endDateTime = event.endDateTime
    ? new Date(event.endDateTime)
    : undefined;

  const duration =
    startDateTime &&
    endDateTime &&
    intervalToDuration({
      start: startDateTime,
      end: endDateTime,
    });

  const durationLabel = duration
    ? formatDuration(duration, { format: ["hours", "minutes"] })
    : undefined;

  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock value={event.title} />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Date"
          value={
            event.startDateTime &&
            format(new Date(event.startDateTime), "PPP pp")
          }
        />
        <EventDetailsDataBlock
          title="Event Type"
          value={getEventTooltipTitle(event.eventType ?? "")}
        />
        <EventDetailsDataBlock title="Duration" value={durationLabel} />

        <EventDetailsDataBlock
          title="Media URL"
          value={
            <Tooltip title={event.mediaUrl}>
              <AppLink
                to={event.mediaUrl || "#"}
                variant="subtitle1"
                display="inline-flex"
              >
                <Link /> &nbsp;
                {event.mediaUrl ? "Link" : "n/a"}
              </AppLink>
            </Tooltip>
          }
        />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock title="Language" value={event.language} />
      </Stack>
    </Stack>
  );
}
