import { TuneOutlined } from "@mui/icons-material";
import {
  Badge,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  badgeClasses,
} from "@mui/material";
import { countMediaCenterFilters } from "src/utils/countMediaCenterFilters";
import { MediaCenterFilterButtonProps } from "./MediaCenterFilterButton.model";

export function MediaCenterFilterButton({
  value,
  isMobile,
  onClick,
  ...props
}: MediaCenterFilterButtonProps) {
  const title = props.title || "Apply Media Center filters";
  const badgeContent = countMediaCenterFilters(value);

  return (
    <Tooltip title={title}>
      <Badge
        color="primary"
        badgeContent={badgeContent}
        sx={{
          [`.${badgeClasses.badge}`]: {
            width: isMobile ? 20 : 26,
            height: isMobile ? 20 : 26,
          },
        }}
      >
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton value={true} onClick={onClick}>
            <TuneOutlined sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Tooltip>
  );
}
