import { useAuth0 } from "@auth0/auth0-react";
import {
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from "@microsoft/signalr";
import { useMemo } from "react";

const logPrefix = "SignalR:";

export function usePushNotificationSignalRConnection({
  hubURL = process.env.REACT_APP_SIGNAL_R_URL,
}: {
  hubURL?: string;
}) {
  const { getAccessTokenSilently } = useAuth0();

  const connection = useMemo(() => {
    if (!hubURL) {
      throw new Error(`${logPrefix} connection hub is not defined`);
    }

    const options: IHttpConnectionOptions = {
      accessTokenFactory: getAccessTokenSilently,
    };

    const builder = new HubConnectionBuilder();
    const conn = builder
      .withUrl(hubURL, options)
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    console.log(`${logPrefix} connection created: ${conn.baseUrl}`);

    return conn;
  }, [getAccessTokenSilently, hubURL]);

  return connection;
}
