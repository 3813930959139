import { UserManagementFilterFormValues } from "src/pages/UserManagement/components/UserManagementFilterForm/UserManagementFilterForm.model";

export function countUsersManagementFilters(
  data?: UserManagementFilterFormValues
): number | undefined {
  const { accountIds, status, timezone, roles } = data || {};

  let totalFilters = [
    accountIds?.length,
    status !== null ? 1 : 0,
    timezone !== null ? 1 : 0,
    roles?.length,
  ].reduce((acc = 0, filterCount = 0) => {
    acc += filterCount ? 1 : 0;
    return acc;
  }, 0);

  return totalFilters;
}
