import { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BannerData } from "src/api/useBanner";

export function SettingsBannerPreview({
  image,
  data,
  position,
  bannerWidth,
  bannerHeight,
  hasLogin,
  imageWidth,
  imageHeight,
  isMobile,
}: {
  image: string;
  data: BannerData;
  position: { [key: string]: string };
  bannerWidth?: number;
  bannerHeight?: number;
  hasLogin?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  isMobile?: boolean;
}) {
  const [loading, setLoading] = useState(true);
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        display: loading ? "none" : "block",
      }}
    >
      <img
        src={image}
        alt={data?.title || ""}
        height={imageHeight}
        width={imageWidth}
        onLoad={() => setLoading(false)}
      />
      <Stack
        direction={"row"}
        bgcolor={data?.bgColor || palette.primary.contrastText}
        width={bannerWidth}
        height={bannerHeight}
        sx={{
          position: "absolute",
          top: position.top,
          left: position.left,
        }}
      >
        <Stack p={0.5}>
          {data?.bannerData && (
            <img
              alt="banner logo"
              src={`data:image/png;base64,${data?.bannerData}`}
              width={15}
              height={15}
              style={{
                borderRadius: "30px",
              }}
            />
          )}
        </Stack>
        <Stack direction={"column"} padding={"3px"}>
          <Typography color={data.color || "#000"} fontSize={isMobile ? 3 : 5}>
            {data?.title}
          </Typography>
          <Typography
            color={data.color || "#000"}
            fontSize={isMobile ? 2.5 : 4}
          >
            {data?.subTitle}
          </Typography>
        </Stack>
        {hasLogin &&
          (!isMobile ? (
            <Stack
              direction={"row"}
              gap={0.2}
              sx={{
                position: "absolute",
                right: "10px",
                top: "8px",
              }}
            >
              <AccountCircleIcon
                fill={palette.primary.contrastText}
                sx={{
                  width: 4,
                  color: palette.primary.contrastText,
                  height: 4,
                }}
              />
              <Typography color={palette.primary.contrastText} fontSize={3}>
                Login
              </Typography>
            </Stack>
          ) : (
            <AccountCircleIcon
              fill={palette.primary.contrastText}
              sx={{
                width: 8,
                bgcolor: palette.primary.contrastText,
                height: 8,
                position: "absolute",
                right: "5px",
                top: "5px",
                borderRadius: 200,
                padding: "2px",
              }}
            />
          ))}
      </Stack>
    </Box>
  );
}
