import { Dispatch, SetStateAction, useState } from "react";

export type OpenedSectionType = { [k: string]: boolean };

type UseTagPickerMobileSectionStateOutputType = [
  openedSection: OpenedSectionType,
  setOpenedSection: Dispatch<
    SetStateAction<{
      [k: string]: boolean;
    }>
  >
];

export function buildInitialSectionState(c: string[]) {
  return c.reduce((res: OpenedSectionType, next) => {
    res[next] = true;
    return res;
  }, {});
}

export const useTagPickerMobileSectionState = (
  categories: string[]
): UseTagPickerMobileSectionStateOutputType => {
  const [openedSection, setOpenedSection] = useState(
    buildInitialSectionState(categories)
  );

  return [openedSection, setOpenedSection];
};
