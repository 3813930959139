import {
  Button,
  ButtonBase,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import {
  PickersActionBarProps,
  pickersLayoutClasses,
} from "@mui/x-date-pickers-pro";
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { useIsMobile } from "src/utils/useIsMobile";

export function AnalyticsLiteDateTimeRangePickerActions(
  props: PickersActionBarProps & {
    setDateTimeRange: (dateRange: [Date | null, Date | null]) => void;
  }
) {
  const isMobile = useIsMobile();
  const { palette } = useTheme();

  const { onAccept, onCancel, setDateTimeRange } = props;

  const actions = [
    {
      label: "Last week",
      method: () => {
        const today = endOfToday();
        const lastWeekStart = subWeeks(today, 1);
        setDateTimeRange([
          startOfWeek(lastWeekStart),
          endOfWeek(lastWeekStart),
        ]);
      },
    },
    {
      label: "Last 14 days",
      method: () => {
        const today = endOfToday();
        setDateTimeRange([subDays(today, 13), today]);
      },
    },
    {
      label: "Last month",
      method: () => {
        const today = endOfToday();
        const lastMonthStart = subMonths(today, 1);
        setDateTimeRange([
          startOfMonth(lastMonthStart),
          endOfMonth(lastMonthStart),
        ]);
      },
    },
  ];

  if (isMobile) {
    return (
      <Stack className={pickersLayoutClasses.actionBar} direction="column">
        <Stack
          direction="row"
          height={56}
          bgcolor={alpha(palette.text.primary, 0.04)}
          children={actions.map(({ label, method }) => (
            <ButtonBase
              key={label}
              onClick={method}
              sx={{ width: `${100 / actions.length}%` }}
              children={
                <Typography variant="subtitle2" color={palette.primary.main}>
                  {label}
                </Typography>
              }
            />
          ))}
        />
        <Stack direction="row" justifyContent="flex-end" py={1} height={56}>
          <Button
            variant="text"
            onClick={onCancel}
            sx={{ width: 80, textTransform: "uppercase" }}
            children="Cancel"
          />
          <Button
            variant="text"
            onClick={onAccept}
            sx={{ width: 80, textTransform: "uppercase" }}
            children="OK"
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      className={pickersLayoutClasses.actionBar}
      direction="row"
      justifyContent="space-between"
      borderTop={`1px solid ${palette.divider}`}
      px={2}
      py={1}
    >
      <Stack
        direction="row"
        columnGap={2}
        children={actions.map(({ label, method }) => (
          <Button
            key={label}
            onClick={method}
            variant="text"
            children={label}
          />
        ))}
      />
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="text" onClick={onAccept}>
          <Typography variant="subtitle2">OK</Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
