import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { AccountsManagementPage } from "./AccountsManagement.page";

export const accountsManagementRoute = new AppRouteBuilder({
  path: "/accounts-management",
  element: (
    <PermissionGuard
      component={AccountsManagementPage}
      allowedRoles={["Admin", "Account Manager", "User Manager"]}
      disableMobile
      redirectTo="/"
      // silent
    />
  ),
});
