import { Stack, StackProps, Tooltip, Typography } from "@mui/material";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";

export function KeyValue({
  name,
  value,
  flexWrap = { xs: "wrap", md: "nowrap" },
}: {
  name: string;
  value: string;
  flexWrap?: StackProps["flexWrap"];
}) {
  return (
    <Tooltip title={`${name}: ${value}`}>
      <Stack
        direction="row"
        alignItems="center"
        columnGap={1}
        flexWrap={flexWrap}
      >
        <Typography variant="body2">{name}</Typography>
        <TextLineClamp lineClamp={1} variant="subtitle2" noWrap>
          {value}
        </TextLineClamp>
      </Stack>
    </Tooltip>
  );
}
