type MakeTranscriptHTML = {
  transcript: string;
  clickable?: boolean;
};

export function makeTranscriptHTML({
  transcript,
  clickable,
}: MakeTranscriptHTML) {
  const regex =
    /<em\s+timing="(\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d{1,3})?)">(\w+)<\/em>/gim;

  const matches = Array.from(transcript.matchAll(regex));

  const html = matches.reduce((acc, match) => {
    const source = match[0];
    const timing = match[1];
    const label = match[3];

    const onclick = `onclick="TVEyes.setQueryParams({ timing: '${timing}' })"`;

    const link = `<a ${clickable ? onclick : ""}>${label}</a>`;

    return acc.replace(source, link);
  }, transcript);

  return html;
}
