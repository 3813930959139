import { create } from "zustand";

export type TagListDataItem = {
  tagName: string;
  color: string;
};

export type TagsListState<T> = {
  tags: T[];
  set: (event: T[]) => void;
};

export const useTagListData = create<TagsListState<TagListDataItem>>(
  (setState) => ({
    tags: [],
    set: (events) => {
      setState(() => ({
        tags: events,
      }));
    },
  })
);
