import { CheckCircle } from "@mui/icons-material";
import {
  AutocompleteRenderOptionState,
  ListItem,
  ListItemText,
  alpha,
  useTheme,
} from "@mui/material";
import { HTMLAttributes } from "react";

export function TagPickerListItem({
  primary,
  listItemProps,
  state,
}: {
  primary: string;
  listItemProps: HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
}) {
  const { palette, spacing } = useTheme();
  const endAdornmentElement = state.selected ? (
    <CheckCircle
      sx={{ position: "absolute", right: spacing(1) }}
      color="primary"
      fontSize="small"
    />
  ) : null;

  return (
    <ListItem
      {...listItemProps}
      onClick={(e) => listItemProps.onClick && listItemProps.onClick(e)}
      style={{
        height: 36,
        backgroundColor: state.selected
          ? alpha(palette.primary.main, 0.04)
          : undefined,
      }}
    >
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          flex: 1,
          width: `calc(100% - ${state.selected ? 12 : 0}px)`,
          overflow: "clip",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      />
      {endAdornmentElement}
    </ListItem>
  );
}
