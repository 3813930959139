import axios from "axios";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths = "/api/User";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody =
  Required<Endpoint>["requestBody"]["content"]["application/json"];
type RequestParams = {
  body: Omit<RequestBody, "userId">;
  query: Endpoint["parameters"]["query"];
};

type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type RequestOptions = Omit<
  UseMutationOptions<ResponseBody, unknown, RequestParams>,
  "mutationFn"
>;

export function useUserCreate({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<ResponseBody, unknown, RequestParams>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ body, query }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, {
        query,
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...body,
          userId: "00000000-0000-0000-0000-000000000000",
        },
      });

      return {
        id: crypto.randomUUID(),
        ...data,
      };
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error: any, variables, context) => {
      const status = error?.response?.status;
      if (status === 400) {
        const errorMessage = error?.response?.data;
        enqueueSnackbar(`${errorMessage || error}`, { variant: "error" });
      } else {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
