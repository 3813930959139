import { Stack, StackProps, Typography } from "@mui/material";
import { EventDetails } from "src/models/EventDetails";
import { PodcastPartnerLogo } from "src/components/PodcastPartnerLogo/PodcastPartnerLogo";
import { EventDetailsDataBlock } from "../EventDetailsDataBlock/EventDetailsDataBlock";

export function PCastAudience({
  event,
  ...props
}: Omit<StackProps, "children"> & { event: EventDetails }) {
  return (
    <Stack rowGap={2} columnGap={2} px={2} {...props}>
      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          value={
            <Stack direction="row">
              <Typography
                variant="subtitle2"
                color="text.primary"
                children="by"
              />
              &nbsp; <PodcastPartnerLogo height={24} />
            </Stack>
          }
        />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Audience Estimate"
          value={event.audience?.totalAudience}
        />
      </Stack>

      <Stack flex={1} rowGap={2}>
        <EventDetailsDataBlock
          title="Power Score"
          value={event.audience?.nationalAdValue}
        />
      </Stack>
    </Stack>
  );
}
