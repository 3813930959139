import { useTheme } from "@mui/material";
import { forwardRef } from "react";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";
import { TranscriptParser } from "../TranscriptParser/TranscriptParser";
import { TranscriptTextProps } from "./TranscriptText.model";

export const TranscriptText = forwardRef<HTMLSpanElement, TranscriptTextProps>(
  ({ transcript, highlightColor, clickable, color, ...props }, ref) => {
    const { palette } = useTheme();
    const effectiveHighlightColor = highlightColor || palette.primary.main;

    return (
      <TextLineClamp {...props} ref={ref}>
        <TranscriptParser
          highlightColor={effectiveHighlightColor}
          transcript={transcript}
          clickable={clickable}
          color={color}
        />
      </TextLineClamp>
    );
  }
);
