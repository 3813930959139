import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGoogleAnalytics } from "src/api/useGoogleAnalytics";
import { useHotJar } from "src/api/useHotJar";

export function AppRouterRoot({
  element,
}: {
  element: JSX.Element;
}): JSX.Element {
  useGoogleAnalytics();
  useHotJar();

  const { pathname, search } = useLocation();
  const url = search ? `${pathname}?${search}` : pathname;

  useEffect(() => {
    window.Intercom("navigation", url);
  }, [url]);

  return element;
}
