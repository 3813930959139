import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const apiPath: keyof paths = "/api/User/{id}/management-view";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];
type ResponseBody = Endpoint["responses"][200]["content"]["application/json"];

export const managementViewKey = "managementViewKey";

export function useManagementView() {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();
  const parameters: Parameters = {
    path: {
      id: userId,
    },
  };

  return useQuery<ResponseBody, unknown>({
    queryKey: [managementViewKey, userId],
    enabled: !!userId,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, parameters);
      const { data } = await axios<ResponseBody>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
