import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { ReportItem } from "src/models/Report";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ReportsNoFilteredReportsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useReportsSelection } from "src/api/useReportsSelection";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { ReportsTile } from "./ReportsTile";
import { ReportsTilesProps } from "./ReportsTiles.model";

export function ReportsTiles({
  id,
  filterData,
  lineClamp,
  renderHeader,
  reportsListTiles,
}: ReportsTilesProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const reportsSelection = useReportsSelection();
  const isIOS = window?.navigator?.platform === "iPhone";
  const handleScroll = useScrollQueryLoader({
    query: reportsListTiles,
  });

  const renderItem = (data: ReportItem) => {
    const isChecked = !!reportsSelection.list.find(
      (report) => report.id === data.id
    );
    return (
      <ReportsTile
        key={data.id}
        value={data}
        lineClamp={lineClamp}
        isChecked={isChecked}
      />
    );
  };

  return (
    <ScrollHeaderLayout
      id={id}
      headerMinHeight={70}
      onScroll={handleScroll}
      renderHeader={renderHeader}
      isLoading={reportsListTiles.isLoading || reportsListTiles.isFetching}
      children={
        <Stack
          pl={2}
          pr={isIOS ? 2 : 1}
          pb={1}
          flex={1}
          children={
            <TileLayout<ReportItem>
              rows={filterData}
              renderItem={renderItem}
              renderEmpty={ReportsNoFilteredReportsOverlay}
              gap={isMobile ? 3 : undefined}
            />
          }
        />
      }
    />
  );
}
