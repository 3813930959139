import { SvgIcon, SvgIconProps } from "@mui/material";

export const ReportsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.4 3.00464L1.73913 3.00834C0.782609 3.00834 0 3.79095 0 4.74747V18.6605C0 19.617 0.773913 20.3996 1.73043 20.3996H12.1739C13.1304 20.3996 13.913 19.617 13.913 18.6605V18.6L6.30325 18.6C5.22327 18.6 4.47492 18.2075 4.00516 17.6596C3.55549 17.1352 3.4 16.5138 3.4 16.0884V3.00464Z"
          fill="currentColor"
        />
        <path
          d="M13.913 17.4V17.3914H6.3394C5.38288 17.3914 4.60896 16.6088 4.60896 15.6522V3.00195L4.6 3.00197V16.0884C4.6 16.2627 4.67483 16.5971 4.91614 16.8785C5.13735 17.1365 5.54063 17.4 6.30325 17.4L13.913 17.4Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.34811 0H13.3046L18.522 5.21739V15.6522C18.522 16.6087 17.7394 17.3913 16.7829 17.3913L6.3394 17.3914C5.38288 17.3914 4.60896 16.6088 4.60896 15.6522L4.60898 1.73913C4.60898 0.782609 5.39159 0 6.34811 0ZM12.4351 1.30435V6.08696H17.2177L12.4351 1.30435ZM12.4349 7.82617H8.08707V9.04356H12.4349V7.82617ZM8.08707 10.0001H12.4349V11.2174H8.08707V10.0001ZM10.6955 13.7176L12.9097 15.6522L16.7825 12.2683L15.6793 11.3044L12.9018 13.7176L11.7987 12.7537L10.6955 13.7176Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
