import React from "react";
import {
  AutocompleteRenderGroupParams,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  alpha,
  useTheme,
} from "@mui/material";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";
import { StackScroll } from "../StackScroll/StackScroll";

export function TagPickerListGroup({
  total,
  ...params
}: AutocompleteRenderGroupParams & { label: string; total?: number }) {
  const { palette } = useTheme();
  const bgColor = alpha(
    palette.mode === "dark" ? palette.primary.dark : palette.primary.light,
    0.12
  );

  const count = React.Children.count(params.children);
  const more = total && total - count;

  return (
    <Stack flexGrow={1} overflow="hidden" {...params}>
      <ListSubheader disableGutters>
        <TextLineClamp
          variant="subtitle2"
          textOverflow="ellipsis"
          color={palette.text.primary}
          lineClamp={1}
          children={params.label}
          px={2}
          py={1}
          bgcolor={bgColor}
        />
      </ListSubheader>

      <StackScroll flexGrow={1}>
        {params.children}

        {total && total > count && (
          <ListItem>
            <ListItemText
              secondary={
                <span style={{ whiteSpace: "break-spaces" }}>
                  <span style={{ whiteSpace: "nowrap" }}>+{more} more.</span>
                  <br />
                  <span>Please refine your search.</span>
                </span>
              }
            />
          </ListItem>
        )}
      </StackScroll>
    </Stack>
  );
}
