import Joi from "joi";
import { components } from "@tveyes/twosionwebapischema";
import { NewAccountFormValues } from "./AccountsManagementCreateAccount.model";

export type GlobalEntitlementsDTO =
  | components["schemas"]["EntitlementsDTO"]
  | null;

export enum limitsConfiguration {
  system = "system",
  account = "account",
}
const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

export const NewAccountSchema = (
  limits: GlobalEntitlementsDTO,
  limitsConfiguration: limitsConfiguration,
  defaultLimits?: GlobalEntitlementsDTO
) =>
  Joi.object<NewAccountFormValues>({
    //General Tab
    isActive: Joi.boolean().required().label("Active"),
    name: Joi.string().required().label("Organization name"),
    organizationType: Joi.string().required().label("Organization type"),
    country: Joi.string().allow("").label("Country"),
    city: Joi.string().allow("").label("City"),
    stateOrProvince: Joi.string().allow("").label("State"),
    postalCode: Joi.string().allow("").label("Zip Code"),
    creationDate: Joi.date().label("Creation date").allow(null),
    expirationDate: Joi.date().required().label("Expiration date"),
    isTrial: Joi.boolean().optional().label("Trial"),
    accountRepresentative: Joi.string()
      .required()
      .label("Account Representative"),
    timezone: Joi.string().optional().label("Timezone").allow(""),
    status: Joi.string().optional().label("Status"),
    seatCount: Joi.string().label("Seat Count"),
    //Features
    enableMediaDownloads: Joi.boolean().optional().label("Media Download"),
    enableEditWatchList: Joi.boolean().optional().label("Watch List"),
    enableAdFiltering: Joi.boolean().optional().label("Ad Filtering"),
    enableCustomReportBanner: Joi.boolean()
      .optional()
      .label("Custom Report Banner"),
    enableReportDownloads: Joi.boolean().optional().label("Report Downloads"),
    limitWatchlistSize: Joi.number()
      .integer()
      .max(
        limits?.limitWatchlistSize?.value ||
          defaultLimits?.limitWatchlistSize?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitWatchlistSize?.value ||
          defaultLimits?.limitWatchlistSize?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Watchlist size limit")
      .allow(""),
    limitItemsPerEmail: Joi.number()
      .integer()
      .max(
        limits?.limitItemsPerEmail?.value ||
          defaultLimits?.limitItemsPerEmail?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitItemsPerEmail?.value ||
          defaultLimits?.limitItemsPerEmail?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Items per email limit")
      .allow(""),
    limitEventsInReport: Joi.number()
      .integer()
      .max(
        limits?.limitEventsInReport?.value ||
          defaultLimits?.limitEventsInReport?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitEventsInReport?.value ||
          defaultLimits?.limitEventsInReport?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in report limit")
      .allow(""),
    limitEventsInCSV: Joi.number()
      .integer()
      .max(
        limits?.limitEventsInCSV?.value ||
          defaultLimits?.limitEventsInCSV?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitEventsInCSV?.value ||
          defaultLimits?.limitEventsInCSV?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in CSV limit")
      .allow(""),
    limitEventsInPDF: Joi.number()
      .integer()
      .max(
        limits?.limitEventsInPDF?.value ||
          defaultLimits?.limitEventsInPDF?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitEventsInPDF?.value ||
          defaultLimits?.limitEventsInPDF?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in PDF limit")
      .allow(""),
    limitScratchReportItems: Joi.number()
      .integer()
      .max(
        limits?.limitScratchReportItems?.value ||
          defaultLimits?.limitScratchReportItems?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitScratchReportItems?.value ||
          defaultLimits?.limitScratchReportItems?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Scratch report items limit")
      .allow(""),
    limitEventsInReportEmail: Joi.number()
      .integer()
      .max(
        limits?.limitEventsInReportEmail?.value ||
          defaultLimits?.limitEventsInReportEmail?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitEventsInReportEmail?.value ||
          defaultLimits?.limitEventsInReportEmail?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in report email limit")
      .allow(""),
    limitEventsInAlertEmail: Joi.number()
      .integer()
      .max(
        limits?.limitEventsInAlertEmail?.value ||
          defaultLimits?.limitEventsInAlertEmail?.value ||
          0
      )
      .messages({
        "number.base": "Invalid number",
        "number.integer": "Invalid number",
        "number.max": `Max ${limitsConfiguration} limit: ${
          limits?.limitEventsInAlertEmail?.value ||
          defaultLimits?.limitEventsInAlertEmail?.value ||
          0
        }`,
        "number.unsafe": "Invalid number",
      })
      .optional()
      .label("Events in alert email limit")
      .allow(""),
    //Content Filter
    preset: Joi.string().optional().allow(""),
    sourcesInclude: {
      list: sourceListSchema.label("Include sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    sourcesExclude: {
      list: sourceListSchema.label("Exclude sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    users: Joi.array().items({
      email: Joi.string()
        .email({ tlds: false })
        .required()
        .label("User email")
        .messages({ "string.empty": "*Required to create a user" }),
      firstName: Joi.string().required().label("First name"),
      lastName: Joi.string().required().label("Last name"),
    }),
  });
