import { Person, PersonOff } from "@mui/icons-material";
import { Palette } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

export function getRoleColor(palette: Palette, role: string) {
  switch (role) {
    case "User":
      return blueGrey["100"];
    case "User manager":
      return palette.primary.main;
    case "Account Manager":
      return palette.secondary.main;
    case "Admin":
      return palette.error.light;

    default:
      return undefined;
  }
}

export function getStatusIcon(status: string) {
  return status === "active" ? PersonOff : Person;
}

export function getStatusLabel(status: string) {
  return status === "active" ? "Deactivate" : "Activate";
}
