import { create } from "zustand";
import { CustomQueryValidationData } from "./useValidateCustomQuerySmart";

const defaultValidationData = {
  isValid: false,
  message: "",
  suggestions: [],
  titleSuggestions: [],
  recommendedQuery: "",
};

type CustomQueryValidationState = {
  validationData: CustomQueryValidationData;
  saveValidationData: (valData: CustomQueryValidationData) => void;
  clearValidationData: () => void;
};

export const useCustomQueryValidationData = create<CustomQueryValidationState>(
  (setState) => ({
    validationData: defaultValidationData,
    saveValidationData: (valData) => {
      setState({
        validationData: valData,
      });
    },
    clearValidationData: () => {
      setState({
        validationData: defaultValidationData,
      });
    },
  })
);
