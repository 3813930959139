import { Fragment, useState } from "react";
import { SortOutlined } from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  alpha,
  dividerClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SortAZ } from "../icons/SortAZ";
import { SortZA } from "../icons/SortZA";
import { SortingByColumnProps } from "./SortingByColumn.model";
import { SortBy } from "../SortingMenu/SortingMenu.model";

function getIcon(order: "asc" | "desc", type?: string) {
  if (type === "date") return SortOutlined;
  if (order === "asc") return SortAZ;

  return SortZA;
}

export function SortingByColumn({
  onChange,
  sx,
  columns,
  order,
  column,
  type,
  ...props
}: SortingByColumnProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const open = Boolean(anchorEl);

  const handleClick: ToggleButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSorting = (
    selectedColumn: string,
    selectedOrder: "asc" | "desc"
  ) => {
    handleClose();
    onChange(selectedColumn, selectedOrder);
  };
  const iconColor = palette.primary.main;
  return (
    <Fragment>
      <ToggleButtonGroup
        value={column}
        aria-controls={open ? "sort-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size={isMobile ? "small" : "medium"}
        {...props}
      >
        <ToggleButton
          value={true}
          onClick={handleClick}
          aria-controls={open ? "sort-menu" : undefined}
          sx={{
            color: "text.primary",
            bgcolor: open ? alpha(palette.primary.light, 0.12) : "transparent",
          }}
        >
          {type === "date" && order === "asc" && <SortOutlined />}
          {type === "date" && order === "desc" && (
            <SortOutlined
              sx={{
                transform: "scaleY(-1)",
              }}
            />
          )}
          {order === "asc" && !type && <SortAZ />}
          {order === "desc" && !type && <SortZA />}
        </ToggleButton>
      </ToggleButtonGroup>
      <Menu
        sx={sx}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "sort-button",
        }}
      >
        <MenuItem disabled sx={{ height: 48, padding: "4px 16px" }}>
          <ListItemText sx={{ fontSize: "14px" }}>Change Order</ListItemText>
        </MenuItem>
        {columns?.map((columnOption, index) => {
          const Icon = getIcon("asc", columnOption.type);
          const IconReverse = getIcon("desc", columnOption.type);

          return [
            <MenuItem
              onClick={() => handleSorting(columnOption.column, "asc")}
              sx={{
                height: 48,
                background:
                  columnOption.column === column && order === "asc"
                    ? alpha(palette.primary.light, 0.12)
                    : "transparent",
              }}
              key={SortBy.titleAsc}
            >
              <ListItemIcon>
                <Icon sx={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText>{columnOption.label}</ListItemText>
            </MenuItem>,
            <MenuItem
              onClick={() => handleSorting(columnOption.column, "desc")}
              sx={{
                height: 48,
                background:
                  columnOption.column === column && order === "desc"
                    ? alpha(palette.primary.light, 0.12)
                    : "transparent",
              }}
              key={SortBy.titleDesc}
            >
              <ListItemIcon>
                <IconReverse
                  sx={{
                    color: iconColor,
                    transform:
                      columnOption.type === "date" ? "scaleY(-1)" : undefined,
                  }}
                />
              </ListItemIcon>
              <ListItemText>{columnOption.label}-reversed</ListItemText>
            </MenuItem>,
            <Divider
              key={"divider"}
              className={`${SortBy.defaultAsc}`}
              sx={{
                [`&.${dividerClasses.root}`]: {
                  margin: 0,
                  display: index + 1 === columns.length && "none",
                },
              }}
            />,
          ];
        })}
      </Menu>
    </Fragment>
  );
}
