import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/Users";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];

export type UsersDataResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];

type Parameters = Endpoint["parameters"];

type RequestOptions = {
  params?: Parameters;
};

export const usersDataKey = "usersDataKey";

export function useUsersData({
  request,
  options,
}: {
  request: RequestOptions;
  options?: UseQueryOptions<UsersDataResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<UsersDataResponse, unknown>({
    ...options,
    keepPreviousData: true,
    queryKey: [usersDataKey, request],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request.params);
      const { data } = await axios<UsersDataResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
