import { ContentAccessFilter } from "src/models/ContentAccessFilter";

export const CONTENT_FILTER_PRESETS: {
  title: string;
  queryDefinition: any; // FIXME: how to define type of the preset
}[] = [
  {
    title: "US Only (All Media Types)",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        state: {
          include: [],
          exclude: [],
        },
        markets: {
          include: [],
          exclude: [],
        },
        stations: {
          include: [],
          exclude: [],
        },
        contentType: {
          include: [],
          exclude: [],
        },
      },
    },
  },
  {
    title: "Broadcast US",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],

          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
          ],
        },
      },
    },
  },
  {
    title: "Broadcast US/Canada",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
            {
              group: "CountryCode",
              name: "Canada",
              value: "CA",
            },
          ],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
          ],
        },
      },
    },
  },
  {
    title: "Broadcast Global",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],
          include: [],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Television",
              value: "Television",
            },
            {
              group: "EventTypeComposite",
              name: "Radio",
              value: "Radio",
            },
          ],
        },
      },
    },
  },
  {
    title: "Podcast US",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],
          include: [
            {
              group: "CountryCode",
              name: "United States",
              value: "US",
            },
          ],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Podcast",
              value: "Podcast",
            },
          ],
        },
      },
    },
  },
  {
    title: "Podcast Global",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],
          include: [],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "Podcast",
              value: "Podcast",
            },
          ],
        },
      },
    },
  },
  {
    title: "Youtube",
    queryDefinition: {
      keywordQuery: {
        include: [],
        includeOperator: "or",
        exclude: [],
        excludeOperator: "or",
      },
      sourceFilter: {
        includeOperator: "or",
        excludeOperator: "or",
        countries: {
          exclude: [],
          include: [],
        },
        state: {
          exclude: [],
          include: [],
        },
        markets: {
          exclude: [],
          include: [],
        },
        stations: {
          exclude: [],
          include: [],
        },
        contentType: {
          exclude: [],
          include: [
            {
              group: "EventTypeComposite",
              name: "YouTube",
              value: "YouTube",
            },
          ],
        },
      },
    },
  },
];

export const INCLUDE_EXCLUDE_PRESETS: {
  title: string;
  contentAccessFilter: ContentAccessFilter; // FIXME: how to define type of the preset
}[] = [
  {
    title: "US Only (All Media Types)",
    contentAccessFilter: {
      contentTypes: {
        included: [],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast US",
    contentAccessFilter: {
      contentTypes: {
        included: ["Television", "Radio"],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast US/Canada",
    contentAccessFilter: {
      contentTypes: {
        included: ["Television", "Radio"],
        excluded: [],
      },
      countries: {
        included: ["US", "CA"],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Broadcast Global",
    contentAccessFilter: {
      contentTypes: {
        included: ["Television", "Radio"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Podcast US",
    contentAccessFilter: {
      contentTypes: {
        included: ["Podcast"],
        excluded: [],
      },
      countries: {
        included: ["US"],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Podcast Global",
    contentAccessFilter: {
      contentTypes: {
        included: ["Podcast"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
  {
    title: "Youtube",
    contentAccessFilter: {
      contentTypes: {
        included: ["YouTube"],
        excluded: [],
      },
      countries: {
        included: [],
        excluded: [],
      },
      markets: {
        included: [],
        excluded: [],
      },
      stations: {
        included: [],
        excluded: [],
      },
      podcastSources: {
        included: [],
        excluded: [],
      },
    },
  },
];
