import { colors, Stack, Typography } from "@mui/material";
import { ANDOperator, OROperator } from "./Operators";

export const PopperORContent = () => (
  <Stack py={0.5} px={1}>
    {/* start first line */}
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Typography color={colors.common.white} fontSize={14}>
        iPhone
      </Typography>
      <OROperator />
      <Typography color={colors.common.white} fontSize={14}>
        “i phone”
      </Typography>
    </Stack>
    {/* end first line */}

    {/* start second line */}
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <OROperator />
      <Typography color={colors.common.white} fontSize={14}>
        (telephone
      </Typography>
      <ANDOperator />
      <Typography color={colors.common.white} fontSize={14}>
        apple)
      </Typography>
    </Stack>
    {/* end second line */}
  </Stack>
);
