import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  Typography,
  colors,
} from "@mui/material";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { StackScroll } from "src/components/StackScroll/StackScroll";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";

export function DevScroll() {
  const [isLoading, setLoading] = useState(true);

  const [minHeaderElement, setMinHeaderElement] = useState<HTMLElement | null>(
    null
  );

  const minHeaderHeight = minHeaderElement?.clientHeight ?? 0;

  const items = Object.values(colors.blueGrey).map((color, index) => (
    <Stack
      key={`${index}:${index}`}
      justifyContent="center"
      alignItems="center"
      minWidth={100}
      minHeight={100}
      bgcolor={color}
      children={<Typography variant="h6" children={`${index}`} />}
    />
  ));

  return (
    <Stack gap={3} height="100%">
      <Typography children="StackScroll CSS scrollbars" />
      <Stack direction="row">
        <StackScroll
          width="33%"
          height={400}
          children={items}
          scrollBarStyle="normal"
        />
        <StackScroll
          width="33%"
          height={400}
          children={items}
          scrollBarStyle="thin"
        />
        <StackScroll
          width="33%"
          height={400}
          children={items}
          scrollBarStyle="hidden"
        />
      </Stack>

      <Stack>
        <Typography children="ScrollBox with Header" />
        <FormControlLabel
          label="is Loading"
          control={<Switch />}
          checked={isLoading}
          onChange={(_e, checked) => setLoading(checked)}
        />
      </Stack>

      <Stack height={400}>
        <ScrollHeaderLayout
          border="2px solid gray"
          headerMinHeight={minHeaderHeight}
          isLoading={isLoading}
          renderHeader={({ transition }) => {
            return (
              <Stack bgcolor="wheat">
                <Typography variant="h6" children="Header line 1" />
                <Typography variant="h6" children="Header line 2" />
                <Typography
                  variant="h3"
                  children={`Transition: ${transition.toFixed(2)}`}
                  ref={setMinHeaderElement}
                />
              </Stack>
            );
          }}
          children={items}
        />
      </Stack>

      <Stack direction="row" gap={10}>
        <FormControl>
          <FormLabel>Column</FormLabel>
          <ScrollBox width={200} height={200} direction="column">
            {["white", "wheat", "white"].map((color, i) => (
              <Box
                key={i}
                minWidth={200}
                minHeight={200}
                sx={{ background: color }}
              />
            ))}
          </ScrollBox>
        </FormControl>

        <FormControl>
          <FormLabel>Row</FormLabel>
          <ScrollBox width={200} height={200} direction="row">
            {["white", "wheat", "white"].map((color, i) => (
              <Box
                key={i}
                minWidth={200}
                minHeight={200}
                sx={{ background: color }}
              />
            ))}
          </ScrollBox>
        </FormControl>

        <FormControl>
          <FormLabel>Resizable column</FormLabel>
          <ScrollBox width={300} height={200} direction="column">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </ScrollBox>
        </FormControl>
      </Stack>
    </Stack>
  );
}
