import {
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BooleanParam, useQueryParam } from "use-query-params";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { UseInfiniteQueryResult } from "react-query";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SearchInput } from "src/components/SearchInput";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DateRange } from "src/components/DateSelector/DateSelector";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { ContainerEmpty } from "src/components/ContainerEmpty/ContainerEmpty";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import { DeleteIconButton } from "src/components/DeleteIconButton/DeleteIconButton";
import { ReportAddIconButton } from "src/components/ReportAddIconButton/ReportAddIconButton";
import { PreselectedEventsEmpty } from "src/components/PreselectedEventsEmpty/PreselectedEventsEmpty";
import { useQueryDateRangeDialogWithEmptyDefault } from "src/utils/useQueryDateRangeDialogWithEmptyDefault";
import { useListFilter } from "src/utils/useListFilter";
import { useOpenState } from "src/utils/useOpenState";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useQueryPagination } from "src/utils/useQueryPagination";
import { combineInfiniteTilesData } from "src/utils/combineInfiniteTilesData";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionReport } from "src/api/useEventSelectionReport";
import { useReportScratchEvents } from "src/api/useReportScratchEvents";
import { useReportScratchEventsInfinite } from "src/api/useReportScratchEventsInfinite";
import { useReportEventsDataView } from "src/components/ReportEvents/ReportEvents.hook";
import { ReportAddEventsDialog } from "src/components/ReportAddEventsDialog/ReportAddEventsDialog";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { ReportEditEventsDeleteDialog } from "./ReportEditEventsDeleteDialog";
import { ReportEditEventsItemActions } from "./ReportEditEventsItemActions";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function ReportEditEventsTab({
  data,
  addEvents,
  isLoading,
  isBusy,
  removeEvents,
  rowCount,
  rowCountWithoutDateFilter,
  paginationModel,
  onPaginationModelChange,
  sortOption,
  setSortOption,
  setSortBy,
  setOrderBy,
  highlightedEventsIds,
  dateRange,
  setDateRange,
  viewMode,
  setViewModeWrapper,
  reportEventsTiles,
}: {
  data: PowerSearchResultItem[];
  addEvents: (events: PowerSearchResultItem[]) => void;
  isLoading: boolean;
  isBusy: boolean;
  removeEvents: (events: PowerSearchResultItem[]) => void;
  rowCount: number;
  rowCountWithoutDateFilter: number;
  paginationModel?: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange?: DataGridPremiumProps["onPaginationModelChange"];
  sortOption: SortBy;
  setSortOption: Dispatch<SetStateAction<SortBy>>;
  setSortBy: Dispatch<SetStateAction<SortByParameters>>;
  setOrderBy: Dispatch<SetStateAction<OrderByParameters>>;
  highlightedEventsIds: string[];
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  viewMode: ViewMode;
  setViewModeWrapper: (nextViewMode: ViewMode) => void;
  reportEventsTiles: UseInfiniteQueryResult;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);
  const [filterText, setFilterText] = useState("");
  const [addDialogDateRange, setAddDialogDateRange] =
    useQueryDateRangeDialogWithEmptyDefault();
  const deleteEventDialog = useOpenState();
  const addEventsDialog = useOpenState();
  const selectionState = useEventSelectionReport();
  const [sortByDialog, setSortByDialog] = useState<SortByParameters>("default");
  const [sortOptionDialog, setSortOptionDialog] = useState(SortBy.defaultDesc);
  const [orderByDialog, setOrderByDialog] = useState<OrderByParameters>("desc");
  const [pagination, setPagination] = useQueryPagination({
    pageKey: "pageEvents",
    pageSizeKey: "pageSizeEvents",
  });

  const reportEventsIds = useMemo(() => data.map((event) => event.id), [data]);

  const reportEventsForAddDialogWithoutDateFilter = useReportScratchEvents({
    request: {
      query: {
        from: 0,
        size: 1,
      },
      body: reportEventsIds,
    },
  });

  const reportEventsForAddDialog = useReportScratchEvents({
    options: {
      enabled: viewMode !== ViewMode.tile,
    },
    request: {
      query: {
        from: pagination.page * pagination.pageSize,
        size: pagination.pageSize,
        sortby: sortByDialog,
        orderby: orderByDialog,
        startDate: addDialogDateRange[0]
          ? formatDateTimeWithoutTimeZone(addDialogDateRange[0])
          : undefined,
        endDate: addDialogDateRange[1]
          ? formatDateTimeWithoutTimeZone(addDialogDateRange[1])
          : undefined,
      },
      body: reportEventsIds,
    },
  });

  const reportEventsForAddDialogTiles = useReportScratchEventsInfinite({
    options: {
      enabled: viewMode === ViewMode.tile,
    },
    request: {
      query: {
        sortby: sortByDialog,
        orderby: orderByDialog,
        startDate: addDialogDateRange[0]
          ? formatDateTimeWithoutTimeZone(addDialogDateRange[0])
          : undefined,
        endDate: addDialogDateRange[1]
          ? formatDateTimeWithoutTimeZone(addDialogDateRange[1])
          : undefined,
      },
      body: reportEventsIds,
    },
  });

  const preselectedEventsDialog = useMemo(() => {
    if (viewMode === ViewMode.tile) {
      if (reportEventsForAddDialogTiles.isError) return [];
      return combineInfiniteTilesData(
        reportEventsForAddDialogTiles.data?.pages
      );
    }
    if (reportEventsForAddDialog.isError) return [];
    return reportEventsForAddDialog.data?.results || [];
  }, [
    viewMode,
    reportEventsForAddDialog.data,
    reportEventsForAddDialogTiles.data,
    reportEventsForAddDialog.isError,
    reportEventsForAddDialogTiles.isError,
  ]);

  const filterData = useListFilter(data || [], filterText, [
    "title",
    "highlights",
  ]);

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewModeWrapper}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [viewMode, setViewModeWrapper, isMobile]
  );

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: selectionState.list,
  });

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectionState.selectAll(filterData);
      } else {
        selectionState.unselectAll();
      }
    },
    [filterData, selectionState]
  );

  const allCheckboxSelector = useMemo(
    () =>
      [ViewMode.tile, ViewMode.list].includes(viewMode) && (
        <AllCheckboxSelector
          checked={isAllSelected || isPartiallySelected}
          isPartiallySelected={isPartiallySelected}
          onChange={handleChangeAllCheckbox}
          sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
        />
      ),
    [viewMode, isAllSelected, isPartiallySelected, handleChangeAllCheckbox]
  );

  const onSortChange = useCallback(
    (sortBy: SortOption) => {
      setSortOption(sortBy.value);
      setSortBy(sortBy.field);
      setOrderBy(sortBy.order);
    },
    [setSortOption, setSortBy, setOrderBy]
  );

  const renderEventsItemActions = (value: PowerSearchResultItem) => {
    return (
      <ReportEditEventsItemActions value={value} removeEvents={removeEvents} />
    );
  };

  const addIconButton = useMemo(
    () => (
      <ReportAddIconButton
        disabled={
          isBusy || !reportEventsForAddDialogWithoutDateFilter.data?.total
        }
        onClick={addEventsDialog.show}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [
      isBusy,
      reportEventsForAddDialogWithoutDateFilter.data?.total,
      addEventsDialog.show,
      isMobile,
    ]
  );

  const deleteIconButton = useMemo(
    () => (
      <DeleteIconButton
        onClick={deleteEventDialog.show}
        disabled={!selectionState.list.length}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [deleteEventDialog.show, selectionState.list.length, isMobile]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        size={isMobile ? "small" : "medium"}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [sortOption, onSortChange, isMobile]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
      />
    ),
    [isMobile, setFilterText]
  );

  const toolbarDesktop = useMemo(
    () => (
      <Stack
        p={2}
        gap={2}
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row" flex={1}>
          {allCheckboxSelector}
          <Stack sx={{ justifyContent: "flex-end", mr: 2 }}>
            {searchInput}
          </Stack>
          <DateTimeSelector
            mode={DateTimeSelectorMode.variable}
            views={["year", "month", "day", "hours", "minutes"]}
            value={dateRange}
            onChange={setDateRange}
            sx={{ flex: 1 }}
          />
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {addIconButton}
          {deleteIconButton}
          {sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [
      allCheckboxSelector,
      searchInput,
      dateRange,
      setDateRange,
      addIconButton,
      deleteIconButton,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack>
        <Stack sx={{ mx: 2, mt: 2 }}>{searchInput}</Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: 40,
            m: 2,
          }}
        >
          <Box>{allCheckboxSelector}</Box>
          <Stack flexDirection="row" gap={1}>
            {addIconButton}
            {deleteIconButton}
            {sortingMenu}
            <DateTimeRangePickerMobile
              value={dateRange}
              onChange={setDateRange}
              iconButtonSize="small"
            />
            {viewModeSwitch}
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      searchInput,
      allCheckboxSelector,
      addIconButton,
      deleteIconButton,
      sortingMenu,
      dateRange,
      setDateRange,
      viewModeSwitch,
    ]
  );

  const renderMobileHeader = useCallback(() => {
    return toolbarMobile;
  }, [toolbarMobile]);

  const dataViewElement = useReportEventsDataView({
    data: filterData,
    viewMode,
    isExpandedTextMode,
    isLoading,
    renderEventsItemActions,
    selectionState,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    highlightedEventsIds,
    reportEventsTiles,
    renderHeader: renderMobileHeader,
  });

  const toolbar = useMemo(() => {
    if (isMobile) {
      return viewMode === ViewMode.tile ? null : toolbarMobile;
    }
    return toolbarDesktop;
  }, [isMobile, viewMode, toolbarMobile, toolbarDesktop]);

  const getReportCreateEventTabContent = () => {
    return (
      <Stack sx={{ height: "100%" }}>
        {toolbar}
        <Stack flex={1} overflow="hidden">
          {dataViewElement}
          <ReportEditEventsDeleteDialog
            open={deleteEventDialog.isOpen}
            onClose={deleteEventDialog.hide}
            events={selectionState.list}
            handleDelete={removeEvents}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {!!rowCountWithoutDateFilter && getReportCreateEventTabContent()}
      {!rowCountWithoutDateFilter &&
        !reportEventsForAddDialogWithoutDateFilter.data?.total && (
          <PreselectedEventsEmpty />
        )}
      {!rowCountWithoutDateFilter &&
        !!reportEventsForAddDialogWithoutDateFilter.data?.total && (
          <ContainerEmpty
            buttonTitle="Add events"
            title="No saved event(s) yet."
            subtitle={
              <>
                At least Report should have one event(s) here.
                <br />
                You can add item(s) from the Preselected events.
              </>
            }
            onButtonClick={addEventsDialog.show}
          />
        )}
      <ReportAddEventsDialog
        isEditFlow
        open={addEventsDialog.isOpen}
        onClose={addEventsDialog.hide}
        addEvents={addEvents}
        events={preselectedEventsDialog}
        rowCount={reportEventsForAddDialog.data?.total || 0}
        isLoading={
          reportEventsForAddDialog.isLoading ||
          reportEventsForAddDialog.isFetching
        }
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        sortOption={sortOptionDialog}
        setSortOption={setSortOptionDialog}
        setSortBy={setSortByDialog}
        setOrderBy={setOrderByDialog}
        dateRange={addDialogDateRange}
        setDateRange={setAddDialogDateRange}
        reportEventsTiles={reportEventsForAddDialogTiles}
      />
    </>
  );
}
