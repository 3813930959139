import { format } from "date-fns";
/**
 * FIXME: this purpose of this entire file is to provide a value getters for badly typed
 * report result. We may get rid of this once types are stabilized.
 */
import { ReportItem } from "src/models/Report";

export function getReportId(value: ReportItem) {
  return value.id;
}

export function getReportTitle(value: ReportItem) {
  return value.title;
}

export function getReportStatus(value: ReportItem) {
  return value.status;
}

export function getReportDateTime(value: ReportItem) {
  return value.createTime ? format(new Date(value.createTime), "PP pp") : "-";
}

export function getReportDescription(value: ReportItem) {
  return value?.description || "";
}

export function getReportEventsCount(value: ReportItem) {
  return value.count?.toLocaleString() || "-";
}
