import { ListBase } from "src/components/ListBase/ListBase";
import { PaginationActions } from "src/components/PaginationActions/PaginationActions";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { DuplicatesListProps } from "./DuplicatesList.model";
import { DuplicatesListItem } from "./DuplicatesListItem";

export function DuplicatesList({
  addItemToReport,
  isExpandedTextMode,
  isPowerSearch,
  ...props
}: DuplicatesListProps) {
  const { list } = useEventSelectionDuplicatesDrawer();
  const renderItem = (data: PowerSearchResultItem) => {
    const isChecked = !!list.find((event) => event.id === data.id);
    return (
      <DuplicatesListItem
        queryId={props.queryId}
        value={data}
        isChecked={isChecked}
        key={data.id}
        addItemToReport={addItemToReport}
        isExpandedTextMode={isExpandedTextMode}
        isPowerSearch={isPowerSearch}
      />
    );
  };

  return (
    <ListBase<PowerSearchResultItem>
      renderItem={renderItem}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      PaginationActionsComponent={PaginationActions}
      {...props}
    />
  );
}
