import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortLocationDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_1_61363)">
          <path
            d="M19.5 22L22 19.25L20.3333 19.25L20.3333 15L18.6667 15L18.6667 19.25L17 19.25L19.5 22Z"
            fill="currentColor"
          />
          <circle cx="12" cy="12" r="2" fill="currentColor" />
          <circle cx="12" cy="12" r="5" stroke="currentColor" strokeWidth="2" />
          <rect x="2" y="11" width="5" height="2" fill="currentColor" />
          <rect x="17" y="11" width="5" height="2" fill="currentColor" />
          <rect
            x="11"
            y="22"
            width="5"
            height="2"
            transform="rotate(-90 11 22)"
            fill="currentColor"
          />
          <rect
            x="11"
            y="7"
            width="5"
            height="2"
            transform="rotate(-90 11 7)"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_61363">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
