import { ReactNode, useMemo, useState } from "react";
import { DateRange } from "@mui/icons-material";
import {
  alpha,
  Box,
  BoxProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import {
  getPowerSearchResultEvent,
  getPowerSearchResultDateTime,
  getPowerSearchResultEventType,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultIndividualTranscript,
} from "src/api/usePowerSearchResult.getters";
import { ReportEventSelectionState } from "src/api/useEventSelectionReport";
import { getEventIcon } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { TextLineClampProps } from "src/components/TextLineClamp/TextLineClamp.model";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { AppLink } from "src/components/AppLink/AppLink";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";

export function ReportEventsTile({
  value,
  width = "100%",
  height = "100%",
  lineClamp = 2,
  isChecked,
  renderEventsItemActions,
  selectionState,
  getEventUrl,
  highlightedEventsIds,
}: {
  value: PowerSearchResultItem;
  isChecked: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  lineClamp?: TextLineClampProps["lineClamp"];
  renderEventsItemActions?: (value: PowerSearchResultItem) => ReactNode;
  selectionState?: ReportEventSelectionState<PowerSearchResultItem>;
  getEventUrl?: (eventId: string) => string;
  highlightedEventsIds?: string[];
}) {
  const { palette } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const eventType = getPowerSearchResultEventType(value);
  const mediaType = getPowerSearchResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);

  const detailsUrl =
    (getEventUrl && getEventUrl(value.id)) ||
    powerSearchResultClipRoute.makeUrl({
      eventId: value.id,
    });

  const isHighlighted = useMemo(() => {
    return highlightedEventsIds?.includes(value.id);
  }, [highlightedEventsIds, value]);

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectionState?.select(value);
    } else {
      selectionState?.unselect(value);
    }
  };

  return (
    <Stack
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="hidden"
      sx={{
        backgroundColor: isHighlighted
          ? alpha(palette.secondary.main, 0.08)
          : "inherit",
      }}
    >
      <Box position="relative">
        <ClipViewer
          id={value.id}
          onMentionChange={setActiveMention}
          event={value}
          viewMode="compact"
        />
        {!!selectionState && (
          <TileCheckBox value={isChecked} onChange={handleChange} zIndex={3} />
        )}
        {getPowerSearchResultTag(value).length ? (
          <AdvChip
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          />
        ) : null}
      </Box>

      <Divider />

      <Stack
        direction="row"
        height={84}
        pl={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack rowGap={1}>
          <Stack direction="row" alignItems="center">
            <Tooltip title={eventType}>
              <Icon />
            </Tooltip>
            <AppLink to={detailsUrl}>
              <TextLineClamp
                variant="subtitle1"
                color="primary"
                lineClamp={1}
                height={20}
                ml={1}
              >
                {getPowerSearchResultEvent(value)}
              </TextLineClamp>
            </AppLink>
          </Stack>

          <Stack direction="row" alignItems="center">
            <DateRange sx={{ color: palette.action.active }} />
            <Typography variant="body2" ml={1}>
              {getPowerSearchResultDateTime(value).toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
        {!!renderEventsItemActions && renderEventsItemActions(value)}
      </Stack>
      <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
        <TranscriptText
          transcript={getPowerSearchResultIndividualTranscript(
            value,
            activeMention
          )}
          lineClamp={lineClamp}
        />
      </TranscriptBlock>
    </Stack>
  );
}
