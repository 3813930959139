import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  inputBaseClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "src/components/buttons/LoadingButton";
import { DialogPromptProps } from "./DialogPrompt.model";
import { ScrollBox } from "../ScrollBox/ScrollBox";

export function DialogPrompt({
  confirmLabel = "Yes",
  confirmDisabled,
  confirmColor = "primary",
  declineLabel = "Cancel",
  declineDisabled,
  onDecline,
  onConfirm,
  isLoading = false,
  title,
  closeOnLeftSide,
  mobileFullHeight,
  showHeaderDivider = false,
  children,
  onClose,
  ...props
}: DialogPromptProps) {
  const { breakpoints, palette } = useTheme();
  const isCompact = useMediaQuery(breakpoints.down("sm"));
  const buttonWidth = isCompact ? "50%" : "95px";
  const isIOS = window?.navigator?.platform === "iPhone";

  const themeBackground =
    palette.mode === "dark"
      ? palette.background.default
      : palette.background.paper;

  const dialogTitle = (
    <DialogTitle sx={{ px: 3, py: 2 }} columnGap={2}>
      {closeOnLeftSide && onClose && (
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      )}

      {title}

      {!closeOnLeftSide && onClose && (
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </DialogTitle>
  );

  const footer = (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="flex-end"
      mt={1}
      mb={2}
      mx={3}
    >
      {onDecline ? (
        <Button
          id="dialog-prompt-decline-button"
          variant="outlined"
          sx={{ width: buttonWidth }}
          onClick={onDecline}
          disabled={declineDisabled}
          color="inherit"
        >
          {declineLabel}
        </Button>
      ) : null}
      {onConfirm ? (
        <LoadingButton
          id="dialog-prompt-confirm-button"
          type="submit"
          variant="contained"
          color={confirmColor}
          sx={{ width: buttonWidth }}
          onClick={onConfirm}
          disabled={confirmDisabled}
          loading={isLoading}
        >
          {confirmLabel}
        </LoadingButton>
      ) : null}
    </Stack>
  );

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      {...props}
      PaperProps={{
        sx: {
          margin: "auto",
          width: "calc(100% - 32px)",
          background: themeBackground,
          borderRadius: mobileFullHeight ? 0 : undefined,
          [`.${inputBaseClasses.root}`]: {
            background:
              palette.mode === "dark"
                ? `${themeBackground}!important`
                : "inherit",
          },
        },
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onConfirm && onConfirm();
        }

        if (e.key === "Escape") {
          onDecline && onDecline();
        }
      }}
    >
      <Stack>
        {dialogTitle}
        {showHeaderDivider && <Divider />}
      </Stack>
      {children && (
        // the ScrollBox works in conjunction with DialogContent
        // ba careful with changing styles here as overall padding
        // depend on scrollbar right/bottom padding reservation
        <ScrollBox direction="column">
          <DialogContent
            children={children}
            sx={{
              padding: "0px 16px 0px 24px",
              paddingRight: isIOS ? "24px" : "16px",
            }}
          />
        </ScrollBox>
      )}
      {footer}
    </Dialog>
  );
}
