import { useState } from "react";
import { EventSourceCategory, useEventSources } from "src/api/useEventSources";
import { EventSourceOptionPickerProps } from "../EventSourceOptionPicker/EventSourceOptionPicker.model";
import { EventSourceOptionPicker } from "../EventSourceOptionPicker/EventSourceOptionPicker";

/**
 * An Autocomplete for picking specific Event Source Market value.
 */
export function EventSourceMarketPicker(
  props: Omit<
    EventSourceOptionPickerProps,
    "loading" | "category" | "options" | "onInputChange"
  >
) {
  const [inputQuery, setInputQuery] = useState("");

  const sourceCategoryOptions = useEventSources({
    path: {
      categories: [EventSourceCategory.market],
      searchText: inputQuery ? inputQuery : "*",
    },
  });

  return (
    <EventSourceOptionPicker
      onInputChange={setInputQuery}
      label="Market"
      options={sourceCategoryOptions.data?.list ?? []}
      loading={sourceCategoryOptions.isFetching}
      {...props}
    />
  );
}
