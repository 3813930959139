import FirstImage from "./hero-swirl-1.png";
import SecondImage from "./hero-swirl-2.png";
import { PropsWithChildren } from "react";
import { Box, styled, useTheme } from "@mui/material";

type KeyFramesProps = {
  mode?: "normal" | "reverse";
};

const opacity = 0.5;
const fromTransformValue = "60vh";
const toTransformValue = "10vh";
const duration = "40s";
const scale = "2.5";

const KeyFrames = styled("div")<KeyFramesProps>(({ mode }) => ({
  "@keyframes forward": {
    from: {
      transform: `translateX(${fromTransformValue})`,
    },
    to: {
      transform: `translateX(${toTransformValue})`,
    },
  },
  animation: `forward ${duration} linear 1 ${mode || "normal"} forwards`,
  position: "absolute",
  bottom: 300,
}));

const ImageContainer = ({ src }: PropsWithChildren<{ src: string }>) => (
  <Box
    component="img"
    alt="Wave"
    src={src}
    sx={{
      opacity: opacity,
      scale,
    }}
  />
);

export const AnimatedBackground = ({ children }: PropsWithChildren) => {
  const { transitions } = useTheme();
  const props = ["opacity"];
  const params = { duration: 300 };
  const transition = transitions.create(props, params);

  return (
    <Box
      m="auto"
      sx={{
        position: "relative",
        overflow: "hidden",
        opacity: 1,
        transition,
      }}
    >
      <KeyFrames mode="reverse">
        <ImageContainer src={FirstImage} />
      </KeyFrames>
      <KeyFrames>
        <ImageContainer src={SecondImage} />
      </KeyFrames>
      {children}
    </Box>
  );
};
