import { AppTab } from "src/components/AppTabLayout/AppTab";
import { AppTabLayout } from "src/components/AppTabLayout/AppTabLayout";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { DevButtons } from "./DevButtons";
import { DevChips } from "./DevChip";
import { DevDateTimePickers } from "./DevDateTimePicker";
import { DevTranscript } from "./DevTranscript";
import { DevMisc } from "./DevMisc";
import { DevPaper } from "./DevPaper";
import { DevScroll } from "./DevScroll";
import { DevSourcesPicker } from "./DevSourcesPicker";
import { DevTextField } from "./DevTextField";
import { DevClipViewer } from "./DevClipViewer";
import { DevTimeLine } from "./DevTimeLine";
import { DevTileView } from "./DevTileView";
import { DevAppAutocomplete } from "./DevAppAutocomplete";
import { DevAppPopper } from "./DevAppPopper";

enum ThemeTab {
  popper = "popper",
  appAutocomplete = "appAutocomplete",
  grid = "grid",
  scroll = "scroll",
  timeline = "timeline",
  clipViewer = "clipViewer",
  paper = "paper",
  chips = "chips",
  buttons = "buttons",
  dateTime = "dateTime",
  textField = "textField",
  sources = "sources",
  transcript = "transcript",
  misc = "misc",
}

export function DevTheme() {
  const [themeTab, setThemeTab] = useViewModeQueryParam<ThemeTab>({
    paramKey: "themeTab",
    defaultValue: ThemeTab.appAutocomplete,
  });

  return (
    <AppTabLayout
      id="app-tab-layout-theme"
      variant="scrollable"
      height="100%"
      overflow="auto"
      currentTab={themeTab}
      onCurrentTabChange={(tab) => setThemeTab(tab as ThemeTab)}
    >
      <AppTab
        label="App Popover"
        value={ThemeTab.popper}
        children={<DevAppPopper />}
      />

      <AppTab
        label="App Autocomplete"
        value={ThemeTab.appAutocomplete}
        children={<DevAppAutocomplete />}
      />

      <AppTab
        label="Transcript"
        value={ThemeTab.transcript}
        children={<DevTranscript />}
      />

      <AppTab
        label="TileView"
        value={ThemeTab.grid}
        children={<DevTileView />}
        overflow="hidden"
      />

      <AppTab label="Scroll" value={ThemeTab.scroll} children={<DevScroll />} />

      <AppTab
        label="TimeLine"
        value={ThemeTab.timeline}
        children={<DevTimeLine />}
      />

      <AppTab
        label="Clip Viewer"
        value={ThemeTab.clipViewer}
        children={<DevClipViewer />}
      />

      <AppTab label="Paper" value={ThemeTab.paper} children={<DevPaper />} />

      <AppTab label="Chips" value={ThemeTab.chips} children={<DevChips />} />

      <AppTab
        label="Buttons"
        value={ThemeTab.buttons}
        children={<DevButtons />}
      />

      <AppTab
        label="Date & Time"
        value={ThemeTab.dateTime}
        children={<DevDateTimePickers />}
      />

      <AppTab
        label="Text Field"
        value={ThemeTab.textField}
        children={<DevTextField />}
      />

      <AppTab
        label="Sources"
        value={ThemeTab.sources}
        children={<DevSourcesPicker />}
      />

      <AppTab label="Misc" value={ThemeTab.misc} children={<DevMisc />} />
    </AppTabLayout>
  );
}
