import { differenceInSeconds } from "date-fns";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { EventDetails } from "src/models/EventDetails";

//This function helps us find the closest event from the list to a given one (the closest one with a difference in seconds).
//returns difference in seconds ("val" param), event data ("data" param) and index ("idx" param)
export function makeClosestEventForExistingByTime(
  data: DateTimeSearchResultItem[],
  event: EventDetails
) {
  const eventStartDateTime = event.startDateTime ?? "";
  const timeDiffForFirstEvent = differenceInSeconds(
    new Date(eventStartDateTime),
    new Date(data[0].blockStartTime ?? "")
  );

  return data.reduce(
    (res, next, idx) => {
      const timeDifference = differenceInSeconds(
        new Date(eventStartDateTime),
        new Date(next.blockStartTime ?? "")
      );
      return Math.abs(timeDifference) < res.val
        ? { val: timeDifference, data: next, idx }
        : res;
    },
    { val: timeDiffForFirstEvent, data: data[0], idx: 0 }
  );
}
