import { Box, BoxProps } from "@mui/material";
import { ReactComponent as LogoColorNormal } from "./logo-color-normal.svg";
import { ReactComponent as LogoMonoNormal } from "./logo-mono-normal.svg";

export const LogoTVEyes = ({
  dark,
  ...props
}: Omit<BoxProps, "children"> & { dark?: boolean }) => {
  return (
    <Box overflow="hidden" {...props}>
      {dark ? (
        <LogoMonoNormal width="100%" height="100%" />
      ) : (
        <LogoColorNormal width="100%" height="100%" />
      )}
    </Box>
  );
};
