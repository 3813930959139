import { useMediaQuery, useTheme } from "@mui/material";
import { TableBase } from "src/components/TableBase/TableBase";
import { NoAccountsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { makeAccountsManagementColumns } from "./AccountsManagementTable.utils";
import { AccountsManagementTableProps } from "./AccountsManagementTable.model";
import { AccountsManagementTableClasses } from "./AccountsManagementTable.const";

export function AccountsManagementTable(props: AccountsManagementTableProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const columns = makeAccountsManagementColumns();

  return (
    <TableBase
      columns={columns}
      columnHeaderHeight={isMobile ? 42 : 56}
      rowHeight={56}
      getRowId={(row) => row?.account?.id || ""}
      {...props}
      slots={{
        noRowsOverlay: NoAccountsOverlay,
        noResultsOverlay: NoAccountsOverlay,
        ...props.slots,
      }}
      sx={{
        [`.${AccountsManagementTableClasses.cellAccountName}`]: {
          textTransform: "capitalize",
          padding: "16px",
        },
        [`.${AccountsManagementTableClasses.cellStatus}`]: {
          textTransform: "capitalize",
        },
        [`.${AccountsManagementTableClasses.accountNameheader}`]: {
          padding: "16px",
        },
        [`.${AccountsManagementTableClasses.cellActiveUsers}`]: {
          justifyContent: "center",
        },
      }}
    />
  );
}
