import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { UseQueryOptions, useQuery } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { PowerSearchResultItem } from "./usePowerSearch";

const apiPath: keyof paths = "/api/Report/scratch/matches";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

type UseReportScratchEventsParams = {
  query: Endpoint["parameters"]["query"];
  body?: RequestBody;
};

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type ReportScratchEventsResponse = {
  total?: number;
  results?: PowerSearchResultItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

export const reportScratchEventsKey = apiPath;

export function useReportScratchEvents({
  request,
  options,
}: {
  request: UseReportScratchEventsParams;
  options?: UseQueryOptions<ReportScratchEventsResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<ReportScratchEventsResponse, unknown>({
    ...options,
    queryKey: [reportScratchEventsKey, request],
    keepPreviousData: true,
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl(apiPath, {
        query: request?.query,
      });
      const { data } = await axios<ReportScratchEventsResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request?.body || [],
      });

      return data;
    },
  });
}
