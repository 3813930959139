import Joi from "joi";
import { EditAccountContentFilterFormValues } from "./AccountsManagementContentTab.model";

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

export const EditAccountContentFilterSchema =
  Joi.object<EditAccountContentFilterFormValues>({
    sourcesInclude: {
      list: sourceListSchema.label("Include sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    sourcesExclude: {
      list: sourceListSchema.label("Exclude sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    preset: Joi.string().optional().allow(""),
  });
