import { AppRouteBuilder } from "src/models/AppRoute";
import { MediaCenterClipPage } from "./MediaCenterClip.page";

export type MediaCenterClipPathParam = {
  eventId: string;
};

export const mediaCenterClipRoute =
  new AppRouteBuilder<MediaCenterClipPathParam>({
    path: "/media-center/:eventId",
    element: <MediaCenterClipPage />,
  });
