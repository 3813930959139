import { AppRouteBuilder } from "src/models/AppRoute";
import { PowerSearchResultPage } from "./PowerSearchResult.page";

type PowerSearchRoutePathParams = {
  // no path params for now
};

type PowerSearchRouteQueryParams = {
  startDate: string;
  endDate: string;
};

export const powerSearchResultRoute = new AppRouteBuilder<
  PowerSearchRoutePathParams,
  PowerSearchRouteQueryParams
>({
  path: "/power-search/result",
  element: <PowerSearchResultPage />,
});
