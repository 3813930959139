import { ComponentType, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";
import { unregisteredUserRoute } from "src/pages/UnregisteredUser/UnregisteredUser.route";
import { useIsUserRegistered } from "src/api/useIsUserRegistered";
import { PageLoader } from "./PageLoader";

interface AuthGuardProps {
  component: ComponentType;
}

export const AuthGuard = ({ component }: AuthGuardProps) => {
  const navigate = useNavigate();
  const isUserRegistered = useIsUserRegistered();
  if (!isUserRegistered) {
    navigate(unregisteredUserRoute.path);
  }

  const Component = useMemo(() => {
    // do not re-create component on every render
    return withAuthenticationRequired(component, {
      onRedirecting: () => <PageLoader />,
    });
  }, [component]);

  return (
    <AppQueryParamsProvider>
      <Component />
    </AppQueryParamsProvider>
  );
};
