import { useState, MouseEvent } from "react";
import {
  Dialog,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Info from "@mui/icons-material/Info";
import { PopperBase } from "../PopperBase/PopperBase";
import { PopperBaseInfoContent } from "../PopperBaseInfoContent/PopperBaseInfoContent";
import { ReportsNoPreselectedEventsOverlay } from "../NoResultsOverlay/NoResultsOverlay";

export function PreselectedEventsEmpty() {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const openPopper = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const closePopper = () => {
    setOpen(false);
  };

  const moreInfoPopperContent = (
    <PopperBaseInfoContent
      title="More information"
      titleIcon={
        <Info
          sx={{
            mr: 1,
            color: palette.mode === "dark" ? "text.seconday" : "primary.main",
          }}
        />
      }
      onClose={closePopper}
      description={
        <Stack gap={2}>
          <Typography variant="body1">
            You don’t have any preselected events for the Report.
          </Typography>
          <Typography variant="body1">
            To add new events, visit the “Power Search” or “Watch term result”
            page, select and add the ones you would like to the preselected
            list.
          </Typography>
          <Typography variant="body1">
            When you edit the Report again, you will be able to update events
            from the preselected list.
          </Typography>
        </Stack>
      }
    />
  );

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        overflow: "auto",
        mx: "auto",
        px: isMobile ? 3 : 0,
      }}
    >
      <ReportsNoPreselectedEventsOverlay onInfoButtonClick={openPopper} />

      {isMobile ? (
        <Dialog
          open={open}
          onClose={closePopper}
          PaperProps={{ sx: { mx: 2 } }}
        >
          {moreInfoPopperContent}
        </Dialog>
      ) : (
        <PopperBase
          open={open}
          anchorEl={anchorEl}
          onClose={closePopper}
          sx={{
            mb: "-10px !important",
            ml: "12px !important",
          }}
          arrowSx={{
            left: 0,
            ml: "-12px",
          }}
        >
          {moreInfoPopperContent}
        </PopperBase>
      )}
    </Stack>
  );
}
