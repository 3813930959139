import { useCallback } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { GridRowSelectionModel, gridClasses } from "@mui/x-data-grid-premium";
import { TableBase } from "src/components/TableBase/TableBase";
import { ReportsNoFilteredEventsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { makePreselectedEventTableColumns } from "./PreselectedEventTable.utils";
import { PreselectedEventTableProps } from "./PreselectedEventsTable.model";
import { PreselectedEventTableClasses } from "./PreselectedEventTable.const";

export function PreselectedEventTable({
  ...props
}: PreselectedEventTableProps) {
  const { breakpoints, typography } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { highlightedEventsIds } = useEventsPreselected();

  const eventsSelectedForReports = useEventsSelectedForReports();

  const columns = makePreselectedEventTableColumns();

  const onRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      if (newRowSelectionModel.length === 0) {
        eventsSelectedForReports.setAllPagesSelected(false);
        eventsSelectedForReports.unselectAll();
      }

      if (eventsSelectedForReports.areAllPagesSelected) {
        const newExcludeEvents = props.rows.filter(
          (event) =>
            !newRowSelectionModel.includes(event.id) &&
            !eventsSelectedForReports.excludeEvents.find(
              (ev) => ev.id === event.id
            )
        );
        const excludeEvents = [
          ...eventsSelectedForReports.excludeEvents.filter(
            (event) => !newRowSelectionModel.includes(event.id)
          ),
          ...newExcludeEvents,
        ];
        eventsSelectedForReports.setExcludeEvents(excludeEvents);
      } else {
        const selectedEvents = newRowSelectionModel.map(
          (id) =>
            eventsSelectedForReports.events.find((ev) => ev.id === id) ||
            props.rows.find((ev) => ev.id === id)
        );
        eventsSelectedForReports.selectEvents(
          selectedEvents as PowerSearchResultItem[]
        );
      }

      eventsSelectedForReports.setRowSelectionModel(newRowSelectionModel);
    },
    [props.rows, eventsSelectedForReports]
  );

  return (
    <TableBase
      columns={columns}
      columnHeaderHeight={isMobile ? 42 : undefined}
      {...props}
      slots={{
        noRowsOverlay: ReportsNoFilteredEventsOverlay,
        noResultsOverlay: ReportsNoFilteredEventsOverlay,
        ...props.slots,
      }}
      disableRowSelectionOnClick
      checkboxSelection
      pagination
      paginationMode="server"
      keepNonExistentRowsSelected
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={
        eventsSelectedForReports.areAllPagesSelected
          ? props.rows
              .filter(
                (ev) =>
                  !eventsSelectedForReports.excludeEvents.find(
                    (event) => event.id === ev.id
                  )
              )
              .map((event) => event.id)
          : eventsSelectedForReports.rowSelectionModel
      }
      getRowId={(row) => row.id}
      getRowClassName={(params) =>
        highlightedEventsIds.includes(params.row.id) ? "row-highlighted" : ""
      }
      sx={{
        // cell styles, these override TableBase cell styles
        [`.${gridClasses.cell}`]: {
          ...typography.body2,
        },
        [`.${PreselectedEventTableClasses.cellEvent}`]: {
          ...typography.body1,
        },
      }}
    />
  );
}
