import { Switch, SwitchProps } from "@mui/material";

export type AppSwitchProps = {
  value?: boolean;
  onChange: (nextValue: boolean) => void;
  size?: SwitchProps["size"];
};

export function AppSwitch({ value, onChange, size }: AppSwitchProps) {
  const handleOnChange: SwitchProps["onChange"] = (e) => {
    onChange(e.currentTarget.checked);
  };

  return <Switch size={size} checked={value} onChange={handleOnChange} />;
}
