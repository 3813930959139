import { useEffect, useState } from "react";
import { ListBase } from "src/components/ListBase/ListBase";
import { makeClosestEventForExistingByTime } from "src/components/ShiftPlayerDrawerMobile/ShiftPlayerEventsCarousel/ShiftPlayerEventsCarousel.utils";
import { ShiftPlayerListItem } from "./ShiftPlayerEventsListItem";
import {
  ShiftPlayerEventsListProps,
  ShifterPlayerEventType,
} from "./ShiftPlayerEventsList.model";

export function ShiftPlayerEventsList({
  showFullTranscript,
  onShiftPlayerPlay,
  event,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange,
  mode,
  rows,
  offset,
  ...props
}: ShiftPlayerEventsListProps) {
  const [initialEventSetted, setInitialEventSetted] = useState(false);
  const [inititalEventId, setInititalEventId] = useState<string | null>(null);

  useEffect(() => {
    if (!initialEventSetted && rows.length) {
      const minDiffEventData = makeClosestEventForExistingByTime(rows, event);
      setInititalEventId(minDiffEventData.data.id ?? "");
      setInitialEventSetted(true);
    }
  }, [initialEventSetted, rows, event]);

  const renderItem = (data: ShifterPlayerEventType) => {
    return (
      <ShiftPlayerListItem
        inititalEventId={inititalEventId}
        rows={rows}
        value={data}
        event={event}
        key={data.id}
        isExpandedTextMode={showFullTranscript}
        onShiftPlayerPlay={onShiftPlayerPlay}
        bounds={bounds}
        minSpanSec={minSpanSec}
        trimRange={trimRange}
        setTrimRange={setTrimRange}
        mode={mode}
        offset={offset}
      />
    );
  };

  return (
    <ListBase<ShifterPlayerEventType>
      renderItem={renderItem}
      hideFooter
      ListProps={{
        sx: { pl: 1.5, pr: 0.5, height: "100%" },
      }}
      rows={rows}
      {...props}
    />
  );
}
