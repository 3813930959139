import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicShareEventsPage } from "./PublicShareEvents.page";

export type PublicShareEventsPathParams = {
  reportId: string;
};

export const publicShareEventsRoute =
  new AppRouteBuilder<PublicShareEventsPathParams>({
    path: "/public/share/:reportId",
    element: <PublicShareEventsPage />,
  });
