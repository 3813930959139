import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { AccountsManagementAddUserPage } from "./AccountsManagementAddUser.page";

export type AccountsManagementAddUserParams = {
  accountId: string;
};

export const accountsManagementAddUserRoute =
  new AppRouteBuilder<AccountsManagementAddUserParams>({
    path: "/accounts-management/:accountId/add-user",
    element: (
      <PermissionGuard
        component={AccountsManagementAddUserPage}
        allowedRoles={["Admin", "Account Manager", "User Manager"]}
        disableMobile
        redirectTo="/"
      />
    ),
  });
