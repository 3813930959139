import { ListBase } from "src/components/ListBase/ListBase";
import {
  MediaCenterNoFilteredEventsOverlay,
  MediaCenterNoSavedEventsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useEventSelectionMediaCenter } from "src/api/useEventSelectionMediaCenter";
import { MediaCenterListProps } from "./MediaCenterList.model";
import { MediaCenterListItem } from "./MediaCenterListItem";

export function MediaCenterList({
  downloadMediaItem,
  addItemToReport,
  hasFilters,
  ...props
}: MediaCenterListProps) {
  const selectionState = useEventSelectionMediaCenter();
  const renderItem = (data: MediaCenterItem) => {
    const isChecked = !!selectionState.list.find(
      (event) => event.id === data.id
    );
    return (
      <MediaCenterListItem
        value={data}
        isChecked={isChecked}
        key={data.id}
        downloadMediaItem={downloadMediaItem}
        addItemToReport={addItemToReport}
      />
    );
  };

  return (
    <ListBase<MediaCenterItem>
      renderItem={renderItem}
      renderEmpty={
        hasFilters
          ? MediaCenterNoFilteredEventsOverlay
          : MediaCenterNoSavedEventsOverlay
      }
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
