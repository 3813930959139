import { useMediaQuery, useTheme } from "@mui/material";

export function useBreakpointName(): string {
  const { breakpoints } = useTheme();
  const { only, values, unit } = breakpoints;
  const xs = useMediaQuery(only("xs"));
  const sm = useMediaQuery(only("sm"));
  const md = useMediaQuery(only("md"));
  const lg = useMediaQuery(only("lg"));
  const xl = useMediaQuery(only("xl"));

  if (xs) {
    return `xs: ${values.xs}${unit}`;
  }

  if (sm) {
    return `sm: ${values.sm}${unit}`;
  }

  if (md) {
    return `md: ${values.md}${unit}`;
  }

  if (lg) {
    return `lg: ${values.lg}${unit}`;
  }

  if (xl) {
    return `xl: ${values.xl}${unit}`;
  }

  return "unknown";
}
