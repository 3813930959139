import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppTopNavBar } from "src/components/AppTopNavBar/AppTopNavBar";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { PageDesktopHeaderProps } from "./PageHeaderDesktop.model";

export function PageHeaderDesktop({
  title,
  breadcrumbLabels,
  hidePenultimateRoute,
  hideBreadcrumbs,
  toolbar,
  children,
  onBack,
  hideBackButton = false,
  ...props
}: PageDesktopHeaderProps) {
  const navigate = useNavigate();
  const goBackWrapper = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1);
  };

  const pageTitleBar = (
    <Stack direction="row" alignItems="center">
      {!hideBackButton && (
        <IconButton
          onClick={goBackWrapper}
          sx={{ marginLeft: -1 /* required to align icon with left border */ }}
        >
          <ArrowBack />
        </IconButton>
      )}
      <Tooltip title={title}>
        <TextLineClamp
          flex={1}
          variant="h6"
          lineClamp={1}
          mr={2}
          wordBreak="break-all"
        >
          {title}
        </TextLineClamp>
      </Tooltip>
    </Stack>
  );

  return (
    <Stack mb={3} {...props}>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap={{ xs: "wrap", sm: "nowrap" }}
      >
        {pageTitleBar}
        {toolbar}
      </Stack>
      {!hideBreadcrumbs && (
        <AppTopNavBar
          maxItems={2}
          hidePenultimateRoute={hidePenultimateRoute}
          routeLabels={breadcrumbLabels}
        />
      )}
      {children}
    </Stack>
  );
}
