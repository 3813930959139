import { addMinutes, differenceInSeconds } from "date-fns";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";

export function calcDifferenceInSeconds(date1: string, date2: string) {
  return differenceInSeconds(new Date(date1), new Date(date2));
}

export function makeDates(startDate: Date, endDate: Date, type: string) {
  if (type === "next") {
    const endDateTime = formatDateTimeWithoutTimeZone(addMinutes(endDate, 4));
    const durationInSeconds = calcDifferenceInSeconds(
      endDateTime,
      formatDateTimeWithoutTimeZone(startDate)
    );
    const isDurationLessTenMinutes = durationInSeconds < 60 * 10;
    const startDateTime = isDurationLessTenMinutes
      ? formatDateTimeWithoutTimeZone(startDate)
      : formatDateTimeWithoutTimeZone(addMinutes(endDate, -6));
    return { startDateTime, endDateTime };
  }

  const startDateTime = formatDateTimeWithoutTimeZone(
    addMinutes(startDate, -4)
  );
  const durationInSeconds = calcDifferenceInSeconds(
    formatDateTimeWithoutTimeZone(endDate),
    startDateTime
  );
  const isDurationLessTenMinutes = durationInSeconds < 60 * 10;
  const endDateTime = isDurationLessTenMinutes
    ? formatDateTimeWithoutTimeZone(endDate)
    : formatDateTimeWithoutTimeZone(addMinutes(startDate, 6));
  return { startDateTime, endDateTime };
}
