import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryFunctionContext, useQuery } from "react-query";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

export enum EventSourceCategory {
  eventType = "eventType",
  country = "country",
  state = "state",
  station = "station",
  market = "market",
  language = "language",
}

const path: keyof paths = "/api/source/autocomplete/program-names/{searchText}";
const method: keyof paths[typeof path] = "get";

type Endpoint = paths[typeof path][typeof method];

type EndpointParams = Endpoint["parameters"];

type RequestParams = {
  path: EndpointParams["path"];
  query?: EndpointParams["query"];
  isBroadcast?: boolean;
};

type ResponseBody = Endpoint["responses"]["200"]["content"]["application/json"];

export type ProgramOption = {
  name: string;
  group?: string;
  value: string;
};

type ProgramGroupMeta = {
  [groupKey: string]: {
    label: string;
    total: number;
    count: number;
  };
};

type ProgramData = {
  list: ProgramOption[];
  meta: ProgramGroupMeta;
};

export function usePrograms(params: RequestParams) {
  const { getAccessTokenSilently } = useAuth0();
  const { searchText, ...restPathParams } = params.path;

  const endpointParams: EndpointParams = {
    query: {
      maxPerGroup: 50,
      ...params.query,
    },
    path: {
      searchText,
      ...restPathParams,
    },
  };

  const url = makeApiUrl<EndpointParams>(path, endpointParams);

  return useQuery({
    queryKey: [params],
    // this data does not change too often
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    keepPreviousData: true,
    queryFn: async (_ctx: QueryFunctionContext): Promise<ProgramData> => {
      if (!searchText) {
        return {
          list: [],
          meta: {},
        };
      }

      const token = await getAccessTokenSilently();
      const { data } = await axios<ResponseBody>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result: ProgramData = {
        list: [],
        meta: {},
      };

      for (let catIdx = 0; catIdx < data.length; catIdx++) {
        const category = data[catIdx];

        result.meta[category.name] = {
          label: category.displayName || category.name,
          total: category.total ?? 0,
          count: category.options.length,
        };

        for (let optIdx = 0; optIdx < category.options?.length; optIdx++) {
          const option = category.options[optIdx];

          result.list.push({
            group: category.name,
            name: option.name,
            value: option.value,
          });
        }
      }

      return result;
    },
  });
}
