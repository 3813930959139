import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { ThemeSwitch } from "src/components/navigation/ThemeSwitch";

const sizes: ButtonProps["size"][] = ["large", "medium", "small"];
const variants: ButtonProps["variant"][] = ["contained", "outlined", "text"];
const colors: ButtonProps["color"][] = [
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
];

export function DevButtons() {
  const { palette } = useTheme();
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.list);
  return (
    <Stack>
      <Typography variant="h6">Toggle Button Group</Typography>
      <Stack
        direction="row"
        spacing={2}
        p={2}
        sx={{ background: palette.background.default }}
      >
        <ViewModeSwitch
          value={viewMode}
          onChange={setViewMode}
          options={Object.values(ViewMode)}
          size="medium"
        />

        <ToggleButtonGroup size="medium">
          <ToggleButton value={true}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>

        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>

        <ThemeSwitch />
      </Stack>

      <Typography variant="h6">Buttons</Typography>
      {variants.map((variant) => (
        <Box key={variant}>
          <Typography variant="h6">{variant}</Typography>

          <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
            {colors.map((color) => (
              <Stack key={`${variant}:${color}`} direction="column">
                {sizes.map((size) => {
                  const key = `${variant} ${color} ${size}`;
                  return (
                    <Box
                      m={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      key={key}
                    >
                      <Tooltip title={key}>
                        <Button color={color} variant={variant} size={size}>
                          {size}
                        </Button>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Stack>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
