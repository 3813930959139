import { Dispatch, SetStateAction } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AlertConfig,
  AlertConfigWeekly,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  WeekDays,
  getDefaultAlertStateData,
  isAlertConfigType,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { AppTimePicker } from "src/components/AppTimePicker/AppTimePicker";

type AlertConfigFormWeeklyProps = {
  value: AlertConfigWeekly;
  onChange: Dispatch<SetStateAction<AlertConfig>>;
  updateSelectOpen?: (v: boolean) => void;
};

export const AlertConfigFormWeekly = ({
  value,
  onChange,
  updateSelectOpen,
}: AlertConfigFormWeeklyProps) => {
  const { time, type, preset } = value;

  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const onTypeChange = (event: SelectChangeEvent) => {
    if (isAlertConfigType(event?.target.value)) {
      onChange(getDefaultAlertStateData(event.target.value));
      updateSelectOpen && updateSelectOpen(false);
    } else {
      throw new Error(
        `Invalid value: ${event.target.value}. Should be one of ${Object.values(
          AlertTypesKey
        )}`
      );
    }
  };

  const onTimeChange = (val: Date | null) => {
    if (!val) return;

    onChange((prev) => {
      if (prev.type !== AlertTypesKey.weekly) return prev;

      return {
        ...prev,
        time: val,
      };
    });
  };

  const onDayChange = (day: WeekDays) => {
    onChange((prev) => {
      if (prev.type !== AlertTypesKey.weekly) return prev;

      return {
        ...prev,
        [day]: !prev[day],
      };
    });
  };

  return (
    <Stack minHeight={isMobile ? undefined : 360} gap={2}>
      <FormControl fullWidth>
        <InputLabel>Type of alert</InputLabel>
        <Select
          onOpen={() => {
            updateSelectOpen && updateSelectOpen(true);
          }}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                updateSelectOpen && updateSelectOpen(false);
              },
            },
          }}
          value={preset || type}
          label="Type of alert"
          onChange={onTypeChange}
        >
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.hourly}>
            Hourly
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekdayMorning}>
            Every Weekday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.threeTimes}>
            Three Times Daily
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.mondayMorning}>
            Monday Morning
          </MenuItem>
          <MenuItem sx={{ height: 48 }} divider value={AlertTypesKey.firstDay}>
            First Day of the Month
          </MenuItem>

          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.daily}>
            Custom Every Day
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.weekly}>
            Custom Day of Week
          </MenuItem>
          <MenuItem sx={{ height: 48 }} value={AlertTypesKey.monthly}>
            Custom Monthly
          </MenuItem>
        </Select>
      </FormControl>

      <Stack gap={2}>
        <AppTimePicker
          disablePortal={true}
          label="Time"
          value={time}
          onChange={(val) => onTimeChange(val)}
        />
        <Typography>Select the Day's of the week</Typography>
        <Stack flexDirection="row" gap={2}>
          {Object.values(WeekDays).map((day) => (
            <Box
              key={day}
              width={32}
              height={32}
              borderRadius="50%"
              position="relative"
              sx={{
                color: value[day] ? palette.background.paper : undefined,
                backgroundColor: value[day]
                  ? palette.primary.main
                  : palette.background.default,
                cursor: "pointer",
              }}
              onClick={() => onDayChange(day)}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: 4,
                  left: [WeekDays.mon, WeekDays.wed].includes(day) ? 8 : 10,
                }}
              >
                {day[0].toLocaleUpperCase()}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
