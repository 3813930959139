import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiKey: keyof paths =
  "/api/Event/from-station-date-time-range-and-query-definition";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type Parameters = Endpoint["parameters"];
type RequestBody = Endpoint["requestBody"]["content"]["text/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export function useEventByStationAndDate({
  request,
  body,
  options,
}: {
  request?: Parameters;
  body: RequestBody;
  options?: UseQueryOptions<Response, unknown>;
}) {
  return useQuery<Response, unknown>({
    queryKey: [apiKey, request],
    keepPreviousData: true,
    ...options,
    queryFn: async () => {
      const url = makeApiUrl(apiKey, request);
      const { data } = await axios<Response>(url, {
        method,
        data: body,
      });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
