import { Backdrop, Box, Fab, Stack, Typography, useTheme } from "@mui/material";
import { Settings, Close, Collections, TextSnippet } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useOpenState } from "src/utils/useOpenState";

export function DuplicatesMobileSettingsMenu({
  isExpandTextShown,
  isExpandedTextMode,
  changeExpandedTextMode,
  isGrouped,
  changeGroupDuplicates,
}: {
  isExpandTextShown: boolean;
  isExpandedTextMode: boolean;
  changeExpandedTextMode: () => void;
  isGrouped: boolean;
  changeGroupDuplicates: () => void;
}) {
  const { palette, zIndex } = useTheme();
  const menuState = useOpenState();

  return (
    <>
      <Fab
        size="medium"
        color="primary"
        sx={{
          position: "absolute",
          bottom: 72,
          right: 16,
          transition: menuState.isOpen ? "opacity 0.5s linear 0.9s" : "none",
          opacity: menuState.isOpen ? 0 : 1,
        }}
        onClick={menuState.show}
      >
        <Box
          width={24}
          height={24}
          sx={{
            transition: "transform 0.8s linear, opacity 0.8s linear",
            transform: menuState.isOpen ? "rotate(-45deg)" : "rotate(0deg)",
            opacity: menuState.isOpen ? 0 : 1,
          }}
        >
          <Settings sx={{ color: palette.common.white }} />
        </Box>
      </Fab>
      <Backdrop
        sx={{ color: palette.text.secondary, zIndex: zIndex.drawer + 1 }}
        open={menuState.isOpen}
        transitionDuration={900}
      >
        {isExpandTextShown && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              position: "absolute",
              bottom: 200,
              right: 20,
            }}
          >
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                mr: 2,
                px: 1,
                height: 32,
                backgroundColor: grey[100],
                borderRadius: "4px",
                color:
                  palette.mode === "dark"
                    ? palette.info.contrastText
                    : palette.text.primary,
              }}
            >
              {isExpandedTextMode ? "Collapse Text" : "Expand Text"}
            </Typography>
            <Fab
              size="small"
              sx={{
                width: 40,
                height: 40,
                backgroundColor: isExpandedTextMode
                  ? palette.primary.main
                  : grey[100],
              }}
              onClick={() => {
                changeExpandedTextMode();
                menuState.hide();
              }}
            >
              <TextSnippet
                sx={{
                  color: isExpandedTextMode
                    ? palette.common.white
                    : palette.mode === "dark"
                    ? palette.info.contrastText
                    : palette.text.secondary,
                }}
              />
            </Fab>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: "absolute",
            bottom: 144,
            right: 20,
          }}
        >
          <Typography
            variant="body2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mr: 2,
              px: 1,
              height: 32,
              backgroundColor: grey[100],
              borderRadius: "4px",
              color:
                palette.mode === "dark"
                  ? palette.info.contrastText
                  : palette.text.primary,
            }}
          >
            {isGrouped ? "Ungroup duplicates" : "Group duplicates"}
          </Typography>
          <Fab
            size="small"
            sx={{
              width: 40,
              height: 40,
              backgroundColor: isGrouped ? palette.primary.main : grey[100],
            }}
            onClick={() => {
              changeGroupDuplicates();
              menuState.hide();
            }}
          >
            <Collections
              sx={{
                color: isGrouped
                  ? palette.common.white
                  : palette.mode === "dark"
                  ? palette.info.contrastText
                  : palette.text.secondary,
              }}
            />
          </Fab>
        </Stack>

        <Fab
          size="medium"
          color="primary"
          sx={{
            position: "absolute",
            bottom: 72,
            right: 16,
          }}
          onClick={menuState.hide}
        >
          <Close sx={{ color: palette.common.white }} />
        </Fab>
      </Backdrop>
    </>
  );
}
