import { components } from "@tveyes/twosionwebapischema";
import { useSessionContext } from "./useSessionContext";

export type Entitlements = components["schemas"]["Entitlements"];
export type EntitlementKey = keyof Entitlements;

/** Gets specific entitlement from user session */
export const useEntitlement = (
  entitlementKey: EntitlementKey
): Entitlements[typeof entitlementKey] => {
  const { effectiveEntitlements } = useSessionContext();

  return effectiveEntitlements[entitlementKey];
};
