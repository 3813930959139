import { Stack, useTheme } from "@mui/material";
import { TimeLineCursorProps } from "./TimeLineCursor.model";

export function TimeLineCursor({ width = 3 }: TimeLineCursorProps) {
  const { palette } = useTheme();

  return (
    <Stack
      left="50%"
      height="100%"
      position="absolute"
      width={width}
      bgcolor={palette.secondary.main}
      sx={{
        pointerEvents: "none",
        transform: "translate(-50%)",
      }}
    />
  );
}
