import {
  Avatar,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";

export function LoginLogoutButton({ color }: { color?: string | null }) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleLogin = () => navigate(watchListRoute.path);
  const avatarIconSize = 24;

  return isMobile ? (
    <IconButton onClick={handleLogin} sx={{ pl: 0, pr: 0 }}>
      <Avatar
        sx={{
          width: avatarIconSize,
          height: avatarIconSize,
          color: palette.background.brand,
          backgroundColor: "white",
        }}
        alt="Avatar icon"
      />
    </IconButton>
  ) : (
    <Button
      children="Log In"
      startIcon={
        <Avatar
          sx={{
            width: 24,
            height: 24,
            color: palette.background.brand,
            backgroundColor: "white",
          }}
          alt="Avatar icon"
        />
      }
      onClick={handleLogin}
      sx={{ color: color || "inherit" }}
    />
  );
}
