import {
  Button,
  InputAdornment,
  Stack,
  Tooltip,
  outlinedInputClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TimeField, TimeFieldProps } from "@mui/x-date-pickers-pro";
import {
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
  isEqual,
  isValid,
  max,
  min,
} from "date-fns";
import { useCallback } from "react";
import { updateDateTimeRange } from "src/utils/updateDateTimeRange";
import { useDebouncedCallback } from "use-debounce";
import { useVideoTrimmingLimitationsSnackBar } from "src/api/useVideoEditingLimitationsSnackBar";
import { TextLineClamp } from "../TextLineClamp/TextLineClamp";
import { ClipEditorPreviewButton } from "./ClipEditorPreviewButton";
import { clipViewerDistanceFormatLocaleEN } from "./ClipEditorTrim.const";
import { ClipEditorTrimProps } from "./ClipEditorTrim.model";
import { SystemLimitSnackbar } from "./SystemLimitSnackbar";

export function ClipEditorTrim({
  value,
  bounds,
  minSpanSec,
  offsetSec,
  errors,
  isPreviewing,
  isMobile,
  onChange,
  onClickPreview,
  ...props
}: ClipEditorTrimProps) {
  const { breakpoints } = useTheme();
  const isLG = useMediaQuery(breakpoints.up("lg"));
  const isPreviewDisabled = !value[0] || !value[1] || errors?.some((v) => !!v);

  const videoEditingLimitationsSnackBar = useVideoTrimmingLimitationsSnackBar();

  const interval: Interval | null =
    value[0] && isValid(value[0]) && value[1] && isValid(value[1])
      ? { start: value[0], end: value[1] } //
      : null;

  const duration: Duration | null = interval && intervalToDuration(interval);

  const durationLabel =
    formatDuration(duration || { minutes: 0, seconds: 0, hours: 0 }, {
      format: ["hours", "minutes", "seconds"],
      locale: clipViewerDistanceFormatLocaleEN,
    }) || "0s";

  const startLabel = "Start Time";
  const endLabel = "End Time";

  const setStartLabel = "Set Start";
  const setEndLabel = "Set End";

  const onChangeWrapper = useDebouncedCallback(onChange, 300);

  const handleSetStart = useCallback(() => {
    const nextDateRange = updateDateTimeRange({
      offsetInSec: offsetSec,
      prev: value,
      bounds,
      minSpanSec,
    });

    onChangeWrapper(nextDateRange);
  }, [bounds, minSpanSec, offsetSec, onChangeWrapper, value]);

  const handleSetEnd = useCallback(() => {
    const nextDateRange = updateDateTimeRange({
      offsetOutSec: offsetSec,
      prev: value,
      bounds,
      minSpanSec,
    });

    onChangeWrapper(nextDateRange);
  }, [bounds, minSpanSec, offsetSec, onChangeWrapper, value]);

  const handleChangeT0: TimeFieldProps<Date>["onChange"] = (t0) => {
    if (bounds[0] && t0 && typeof t0.getMonth === "function") {
      const minDate = min([bounds[0], t0]);
      const isT1OutsideVideoTimaRange = isEqual(minDate, t0);
      isT1OutsideVideoTimaRange && videoEditingLimitationsSnackBar.show();
    }

    const nextRange = updateDateTimeRange({
      offsetInSec: bounds[0] && t0 ? differenceInSeconds(t0, bounds[0]) : 0,
      prev: value,
      bounds,
      minSpanSec,
    });

    onChangeWrapper(nextRange);
  };

  const handleChangeT1: TimeFieldProps<Date>["onChange"] = (t1) => {
    if (bounds[1] && t1 && typeof t1.getMonth === "function") {
      const maxDate = max([bounds[1], t1]);
      const isT1OutsideVideoTimaRange = isEqual(maxDate, t1);
      isT1OutsideVideoTimaRange && videoEditingLimitationsSnackBar.show();
    }

    const nextRange = updateDateTimeRange({
      offsetOutSec: bounds[0] && t1 ? differenceInSeconds(t1, bounds[0]) : 0,
      prev: value,
      bounds,
      minSpanSec,
    });
    onChangeWrapper(nextRange);
  };

  if (isMobile) {
    return (
      <Stack
        pt={1}
        px={2}
        rowGap={2}
        sx={{
          [`.${outlinedInputClasses.root}`]: {
            paddingRight: 0.75,
          },
        }}
        {...props}
      >
        <Stack rowGap={2}>
          <TimeField
            label={startLabel}
            value={value[0]}
            onChange={handleChangeT0}
            format="pp"
            variant="outlined"
            size="medium"
            fullWidth
            FormHelperTextProps={{
              error: !!errors?.[0],
            }}
            InputProps={{
              error: !!errors?.[0],
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="success"
                    variant="contained"
                    children={setStartLabel}
                    sx={{ minWidth: 120 }}
                    onClick={handleSetStart}
                  />
                </InputAdornment>
              ),
            }}
          />

          <TimeField
            label={endLabel}
            value={value[1]}
            onChange={handleChangeT1}
            format="pp"
            variant="outlined"
            size="medium"
            fullWidth
            FormHelperTextProps={{
              error: !!errors?.[1],
            }}
            InputProps={{
              error: !!errors?.[1],
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="error"
                    variant="contained"
                    children={setEndLabel}
                    sx={{ minWidth: 120 }}
                    onClick={handleSetEnd}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <ClipEditorPreviewButton
          label={`Preview: ${durationLabel}`}
          isDisabled={isPreviewDisabled}
          isPreviewing={isPreviewing}
          isMobile
          onClick={onClickPreview}
        />
      </Stack>
    );
  }

  // desktop layout
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      minHeight={40}
      columnGap={2}
      overflow="hidden"
      pt={1}
      pb={2}
      {...props}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        [`.${outlinedInputClasses.root}`]: {
          paddingRight: 0.5,
        },
        ...props.sx,
      }}
    >
      <Stack direction="row" alignItems="center" columnGap={2} maxWidth={400}>
        <Tooltip
          title={errors?.[0]}
          children={
            <TimeField
              label={startLabel}
              value={value[0]}
              onChange={handleChangeT0}
              format="pp"
              variant="outlined"
              size="small"
              fullWidth
              FormHelperTextProps={{
                error: !!errors?.[0],
              }}
              InputProps={{
                error: !!errors?.[0],
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      children={setStartLabel}
                      onClick={handleSetStart}
                      sx={{ minWidth: 80 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        <Tooltip
          title={errors?.[1]}
          children={
            <TimeField
              label={endLabel}
              value={value[1]}
              onChange={handleChangeT1}
              format="pp"
              variant="outlined"
              size="small"
              fullWidth
              FormHelperTextProps={{
                error: !!errors?.[1],
              }}
              InputProps={{
                error: !!errors?.[1],
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      children={setEndLabel}
                      onClick={handleSetEnd}
                      sx={{ minWidth: 80 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      </Stack>

      <Stack direction="row" alignItems="center" columnGap={2}>
        <TextLineClamp
          lineClamp={1}
          variant="body1"
          color="text.secondary"
          flex={1}
          sx={{
            textOverflow: "ellipsis",
            justifyContent: "flex-end",
          }}
        >
          {isLG && "Duration: "}
          {durationLabel}
        </TextLineClamp>

        <ClipEditorPreviewButton
          label={isLG ? "Preview" : undefined}
          isDisabled={isPreviewDisabled}
          isPreviewing={isPreviewing}
          onClick={onClickPreview}
        />
      </Stack>
      <SystemLimitSnackbar
        open={videoEditingLimitationsSnackBar.isOpen}
        onClose={videoEditingLimitationsSnackBar.hide}
      />
    </Stack>
  );
}
