import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Chip, FormControlLabel, Stack, Switch } from "@mui/material";
import { ScheduledAlertControls } from "src/components/ScheduledAlertPopover/ScheduledAlertControls/ScheduledAlertControls";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { useWatchQueryAlertCreate } from "src/api/useWatchQueryAlertCreate";
import { useWatchQueryAlertEdit } from "src/api/useWatchQueryAlertEdit";
import { AlertConfig } from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.model";
import {
  AlertTypesKey,
  formatTimeValue,
  getDefaultAlertStateData,
  getMonthlyScheduleTime,
  getScheduledAlertRuleData,
  getWeeklyScheduleTime,
} from "src/components/ScheduledAlertPopover/ScheduledAlertPopover.utils";
import { ScheduledAlertMobileCreateProps } from "./ScheduledAlertMobileCreate.model";
import { makeTypedPropList } from "src/utils/makeTypedPropList";

export const ScheduledAlertMobileCreate = ({
  queryId,
  origin,
  alerts,
  onClose,
  presets = [],
  editedRule,
  onSuccessEditRule,
  sheduledAlertArchiveEnabled,
  setSheduledAlertArchiveEnabled,
  scheduledAlertCreateHandler,
  defaultAlertId,
  ...props
}: ScheduledAlertMobileCreateProps) => {
  const { user } = useAuth0();

  const [config, setConfig] = useState<AlertConfig>(
    getDefaultAlertStateData(AlertTypesKey.daily)
  );

  useEffect(() => {
    if (!editedRule) return;

    const parsedRuleScheduledData = getScheduledAlertRuleData(editedRule);
    setConfig(parsedRuleScheduledData);
    setSheduledAlertArchiveEnabled(sheduledAlertArchiveEnabled);
  }, [editedRule, setSheduledAlertArchiveEnabled, sheduledAlertArchiveEnabled]);

  const alertCreate = useWatchQueryAlertCreate({
    options: {
      onSettled: () => {
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
        onClose && onClose();
      },
      origin,
      type: "scheduled",
    },
  });
  const scheduledAlertCreate = () => {
    if (!queryId) return;

    const isAlertsEnabled = alerts.every((alert) => alert.enabled);

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    alertCreate.mutate({
      params: {
        path: {
          queryId,
        },
        query: {
          type: config.type,
          archive: sheduledAlertArchiveEnabled,
          enabled: isAlertsEnabled,
        },
        schedule: scheduleTime,
      },
    });
  };

  const alertEdit = useWatchQueryAlertEdit({
    options: {
      onSettled: () => {
        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
        setSheduledAlertArchiveEnabled(false);
        onSuccessEditRule && onSuccessEditRule(null);
        onClose && onClose();
      },
      origin,
    },
  });
  const scheduledAlertEdit = () => {
    if (!queryId || !editedRule || !editedRule.id) return;

    let scheduleTime: string[] = [];
    if (config.type === AlertTypesKey.daily) {
      scheduleTime = config.time.map((time) => formatTimeValue(new Date(time)));
    }
    if (config.type === AlertTypesKey.weekly) {
      const days = {
        sat: config.sat,
        mon: config.mon,
        tue: config.tue,
        wed: config.wed,
        thu: config.thu,
        fri: config.fri,
        sun: config.sun,
      };
      const keys = makeTypedPropList(days);
      const activeDays = keys.filter((v) => days[v]);
      scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
    }
    if (config.type === AlertTypesKey.monthly) {
      scheduleTime = getMonthlyScheduleTime(config);
    }

    const updatedAlert = {
      ...editedRule,
      type: config.type,
      schedule: scheduleTime,
      archive: sheduledAlertArchiveEnabled,
    };

    alertEdit.mutate({
      params: {
        path: {
          queryId,
          alertId: editedRule.id,
        },
      },
      alert: updatedAlert,
    });
  };

  const presetElements = Object.entries(presets).map(([k, v]) => {
    const key = `${k}:${v.value}`;
    return (
      <Chip
        label={v.label}
        key={key}
        variant="outlined"
        onClick={(_e) => {
          setConfig(() =>
            getDefaultAlertStateData(v.value as AlertConfig["type"])
          );
        }}
        size="medium"
      />
    );
  });

  const content = (
    <Stack pt={3} pb={presets.length !== 0 ? 1 : 2} gap={2} minHeight={176}>
      <ScheduledAlertControls config={config} setConfig={setConfig} />
      {presets.length !== 0 && (
        <Stack
          flexDirection="row"
          gap={1}
          width="100%"
          height={40}
          pb={1}
          alignItems="center"
          overflow="auto"
        >
          {presetElements}
        </Stack>
      )}
      <FormControlLabel
        label="Archive Alerts"
        control={
          <Switch
            checked={sheduledAlertArchiveEnabled}
            onChange={() =>
              setSheduledAlertArchiveEnabled(!sheduledAlertArchiveEnabled)
            }
          />
        }
      />
    </Stack>
  );

  return (
    <DialogPrompt
      maxWidth="sm"
      title="Add Scheduled alert"
      children={content}
      onDecline={() => {
        if (editedRule) {
          setConfig(getDefaultAlertStateData(AlertTypesKey.daily));
          onSuccessEditRule && onSuccessEditRule(null);
          setSheduledAlertArchiveEnabled(false);
        }
        onClose && onClose();
      }}
      onConfirm={() => {
        if (!scheduledAlertCreateHandler) {
          return editedRule ? scheduledAlertEdit() : scheduledAlertCreate();
        }

        let scheduleTime: string[] = [];
        if (config.type === AlertTypesKey.daily) {
          scheduleTime = config.time.map((time) =>
            formatTimeValue(new Date(time))
          );
        }
        if (config.type === AlertTypesKey.weekly) {
          const days = {
            sat: config.sat,
            mon: config.mon,
            tue: config.tue,
            wed: config.wed,
            thu: config.thu,
            fri: config.fri,
            sun: config.sun,
          };
          const keys = makeTypedPropList(days);
          const activeDays = keys.filter((v) => days[v]);
          scheduleTime = getWeeklyScheduleTime(activeDays, [config.time]);
        }
        if (config.type === AlertTypesKey.monthly) {
          scheduleTime = getMonthlyScheduleTime(config);
        }
        const alert = {
          id: defaultAlertId
            ? "00000000-0000-0000-0000-000000000000"
            : `${config.type}-${alerts.length + 1}`,
          type: config.type,
          archive: sheduledAlertArchiveEnabled,
          destination: user?.email,
          schedule: scheduleTime,
          enabled: true,
          muted: false,
        };
        const updatedAlert = {
          ...editedRule,
          type: config.type,
          schedule: scheduleTime,
          archive: sheduledAlertArchiveEnabled,
        };
        scheduledAlertCreateHandler(
          editedRule ? updatedAlert : alert,
          editedRule ? "edit" : undefined
        );

        setConfig(getDefaultAlertStateData(AlertTypesKey.daily));

        onSuccessEditRule && onSuccessEditRule(null);
        onClose && onClose();
      }}
      confirmLabel="Add alert"
      {...props}
    />
  );
};
