import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { addSeconds, isEqual } from "date-fns";
import { useDebounce } from "use-debounce";
import { DrawerProps, Stack } from "@mui/material";
import { components } from "@tveyes/twosionwebapischema";
import { useShiftPlayerData } from "src/api/useShiftPlayerData";
import { useStationTime } from "src/api/useStationTime";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { EventDetails } from "src/models/EventDetails";
import { ShiftPlayerEventsCarousel } from "./ShiftPlayerEventsCarousel/ShiftPlayerEventsCarousel";
import { BottomSideDrawer } from "../BottomSideDrawer/BottomSideDrawer";
import { ShiftPlayerRangePager } from "../ShiftPlayerDrawer/ShiftPlayerRangePager/ShiftPlayerRangePager";
import { UseTrimRangeValue } from "../ClipEditorPage/hook/useTrimRangeState";

type ShiftPlayerDrawerMobileProps = Omit<
  DrawerProps,
  "anchor" | "hideBackdrop" | "onClose" | "title"
> & {
  onClose: () => void;
  onOpen: () => void;
  event: EventDetails;
  query?: components["schemas"]["QueryDefinition"];
  mode: "view" | "edit";
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  bounds?: [Date, Date] | null;
  minSpanSec: number;
  trimRange?: UseTrimRangeValue;
  setTrimRange?: Dispatch<SetStateAction<UseTrimRangeValue>>;
  height?: string;
  initialStartDateTime?: string;
  shiftPlayerOffset: number;
  offset: number;
};

export function ShiftPlayerDrawerMobile({
  event,
  query,
  mode,
  height = "80%",
  onShiftPlayerPlay,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange,
  initialStartDateTime,
  shiftPlayerOffset,
  offset,
  ...props
}: ShiftPlayerDrawerMobileProps) {
  const [maxDateTime, setMaxDateTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (initialStartDateTime) {
      const cursorTime = addSeconds(
        new Date(initialStartDateTime),
        shiftPlayerOffset
      );
      setCurrentDate(cursorTime);
    }
  }, [initialStartDateTime, shiftPlayerOffset]);

  const [deboucedCurrentDate] = useDebounce(currentDate, 500);

  const stationTime = useStationTime({
    params: {
      path: {
        station: event.sourceId ?? "",
      },
    },
  });

  useEffect(() => {
    setMaxDateTime((prevValue) => {
      if (!stationTime.data || !stationTime.data.currentLocalTime)
        return prevValue;
      if (isEqual(prevValue, new Date(stationTime.data.currentLocalTime)))
        return prevValue;

      return addSeconds(new Date(stationTime.data.currentLocalTime), -3 * 60);
    });
  }, [stationTime.data]);

  const { data } = useShiftPlayerData({
    options: {
      enabled: Object.keys(event).length !== 0 && props.open,
    },
    params: {
      cursorRangeBounds: [0, +10],
      fetchParams: {
        dateTime: deboucedCurrentDate,
        market: null,
        station: {
          group: "StationGUID",
          name: event.source ?? "",
          value: event.sourceId ?? "",
        },
        stationCurrentTime: maxDateTime,
        sentenceAlign: false,
      },
      body: query,
    },
  });

  const content = (
    <Stack px={2} height="100%">
      <ShiftPlayerRangePager
        value={currentDate}
        bounds={[-1, +1]}
        maxDate={maxDateTime}
        onChange={setCurrentDate}
        dateTimeFormat="p"
      />
      <ShiftPlayerEventsCarousel
        data={data ?? []}
        event={event}
        onShiftPlayerPlay={onShiftPlayerPlay}
        bounds={bounds}
        minSpanSec={minSpanSec}
        trimRange={trimRange}
        setTrimRange={setTrimRange}
        mode={mode}
        onChange={() => null}
        offset={offset}
      />
    </Stack>
  );

  return (
    <BottomSideDrawer
      height={height}
      {...props}
      hideBackdrop
      zIndexDrawer={1}
      children={content}
    />
  );
}
