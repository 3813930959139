import { AppRouteBuilder } from "src/models/AppRoute";
import { PublicSharedEventPage } from "./PublicSharedEvent.page";

export type PublicSharedEventPathParams = {
  reportId: string;
  eventId: string;
};

export const publicSharedEventRoute =
  new AppRouteBuilder<PublicSharedEventPathParams>({
    path: "/public/share/:reportId/:eventId",
    element: <PublicSharedEventPage />,
  });
