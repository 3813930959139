import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
} from "react-hook-form";
import { TextInputBase } from "src/components/TextInputBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { NewAccountFormValues } from "../../AccountsManagementCreateAccount.model";

type AccountsManagementUsersFieldsProps = {
  fields: FieldArrayWithId<NewAccountFormValues, "users", "id">[];
  isMobile?: boolean;
  control?: Control<NewAccountFormValues, any>;
  remove: UseFieldArrayRemove;
  setShowUsersGrid?: React.Dispatch<React.SetStateAction<boolean>>;
};
export function AccountsManagementUsersFields({
  fields,
  isMobile = false,
  control,
  remove,
  setShowUsersGrid,
}: AccountsManagementUsersFieldsProps) {
  const removeUserFieldSet = (index: number) => {
    remove(index);
    if (fields.length === 1) {
      setShowUsersGrid?.(false);
    }
  };
  return (
    <Grid container spacing={isMobile ? 1.5 : 3} mt={2} rowSpacing={1}>
      {fields.map((users, index) => (
        <React.Fragment key={users.id}>
          <Grid item xs={12} md={0.6} mr={-2}>
            <Typography pt={2} pl={1.5}>
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Controller
              name={`users.${index}.email`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="User Email"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Controller
              name={`users.${index}.firstName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="First Name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Controller
              name={`users.${index}.lastName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="Last Name"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || " "}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={0.8}>
            <ToggleButtonGroup>
              <ToggleButton
                value={true}
                onClick={() => removeUserFieldSet(index)}
                sx={{ padding: "16px 14px" }}
              >
                <DeleteIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
