import { ChangeEvent } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export function AllCheckboxSelector({
  checked,
  onChange,
  isPartiallySelected,
  sx,
  hideLabel,
}: {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isPartiallySelected: boolean;
  sx?: SxProps;
  hideLabel?: boolean;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const checkbox = (
    <Checkbox
      checked={checked}
      indeterminate={isPartiallySelected}
      onChange={onChange}
      sx={{ height: "40px", width: "40px" }}
    />
  );

  return (
    <Stack pl="2px" alignSelf="center">
      {isMobile ? (
        checkbox
      ) : (
        <Grid
          item
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          sx={sx}
        >
          <FormControlLabel control={checkbox} label={hideLabel ? "" : "All"} />
        </Grid>
      )}
    </Stack>
  );
}
