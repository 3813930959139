import { AppRouteBuilder } from "src/models/AppRoute";
import { PermissionGuard } from "src/components/PermissionGuard";
import { UserManagementCreatePage } from "./UserManagementCreate.page";

export const userManagementCreateRoute = new AppRouteBuilder({
  path: "/user-management/create",
  element: (
    <PermissionGuard
      component={UserManagementCreatePage}
      allowedRoles={["Admin", "Account Manager", "User Manager"]}
      disableMobile
      redirectTo="/"
    />
  ),
});
