import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryListKey } from "./useWatchQueryList";

const apiPath: keyof paths = "/api/WatchList/cluster";
const method: keyof paths[typeof apiPath] = "delete";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"][200];
type ResponseBody = Response["content"]["application/json"];

type MutateFnParams = {
  clusterId: string;
  clusterTitle: string;
};

type RequestOptions = Pick<
  UseMutationOptions<ResponseBody, unknown, MutateFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useWatchQueryClusterDelete({
  options,
}: {
  options?: RequestOptions;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<ResponseBody, unknown, MutateFnParams>({
    ...options,
    mutationFn: async ({ clusterId, clusterTitle }: MutateFnParams) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        query: {
          id: clusterId,
        },
      });

      const { data } = await axios<ResponseBody>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: watchQueryListKey,
      });

      enqueueSnackbar(
        t("clusterDeleteMessage", "Cluster deleted", {
          clusterTitle: variables.clusterTitle,
        }),
        {
          variant: "default",
        }
      );

      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError?.(error, variables, context);
    },
  });
}
