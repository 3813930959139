import Joi from "joi";
import { TagPickerValue } from "../TagPicker.model";

const tagPickerValueSchema = Joi.object<TagPickerValue>({
  list: Joi.array().required(),
  logic: Joi.string().valid("and", "or").insensitive().required(),
}).required();

/**
 * Checks if value is tag picker value object
 */
export const isTagPickerValue = (value: unknown): value is TagPickerValue => {
  return !tagPickerValueSchema.validate(value).error;
};
