import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "./useSessionContext";

import { MediaCenterItem, mediaCenterFetchKey } from "./useMediaCenter";
import { MediaCenterInfiniteFetchKey } from "./useMediaCenterInfinite";

const template: keyof paths = "/api/mediacenter/{userid}/items/{itemid}";
const method: keyof paths[typeof template] = "delete";

type Endpoint = paths[typeof template][typeof method];
type Response = Endpoint["responses"][204];
type ResponseBody = Response["content"]["application/json"];

type UseMediaCenterDeleteItem = Omit<
  UseMutationOptions<ResponseBody, unknown, MediaCenterItem>,
  "mutationFn"
>;

export function useMediaCenterDeleteItem({
  options,
}: {
  options?: UseMediaCenterDeleteItem;
}) {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const sessionData = useSessionContext();

  return useMutation<ResponseBody, unknown, MediaCenterItem>({
    ...options,
    mutationFn: async ({ id }) => {
      const apiPath = template
        .replace("{userid}", sessionData.userId)
        .replace("{itemid}", id);

      const token = await getAccessTokenSilently();
      const response = await axios<ResponseBody>(apiPath, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        t("mediaCenterDeleteMessage", { eventName: `"${variables.title}"` })
      );

      options?.onSuccess && options.onSuccess(data, variables, context);

      queryClient.refetchQueries({
        queryKey: mediaCenterFetchKey,
      });
      queryClient.refetchQueries({
        queryKey: MediaCenterInfiniteFetchKey,
      });
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
