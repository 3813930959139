import { Fragment } from "react";
import { BookmarkAdd, MpOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import {
  ClipEditorTitlePrompt,
  ClipEditorTitlePromptProps,
} from "src/components/ClipEditorTitlePrompt/ClipEditorTitlePrompt";
import { getEventDetailsMediaDownloadExtension } from "src/api/usePowerSearchResult.getters";
import { EventDetails } from "src/models/EventDetails";

type ClipEditorSaveDialogMobileProps = Omit<DialogProps, "onClose"> & {
  handleSaveToMediaCenter: (clipTitle: string) => void;
  handleDownload: () => void;
  onClose: () => void;
  disabled?: boolean;
  event: Pick<EventDetails, "mediaDownloadExtension">;
};

export const ClipEditorSaveDialogMobile = ({
  open,
  disabled,
  onClose,
  handleSaveToMediaCenter,
  handleDownload,
  event,
}: ClipEditorSaveDialogMobileProps) => {
  const titleDialog = useOpenState();

  const handleConfirmTitle: ClipEditorTitlePromptProps["onConfirm"] = (
    values
  ) => {
    titleDialog.hide();
    handleSaveToMediaCenter(values.clipTitle);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 319,
            py: 2,
          },
        }}
      >
        <Typography variant="h6" mx={3} children="Save" />

        <List>
          <ListItemButton
            disabled={disabled}
            onClick={() => {
              onClose();
              titleDialog.show();
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <BookmarkAdd fontSize="medium" color="primary" />
            </ListItemIcon>
            <ListItemText children="To Media Center" />
          </ListItemButton>

          <ListItemButton
            disabled={disabled}
            onClick={() => {
              onClose();
              handleDownload();
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <MpOutlined fontSize="medium" color="primary" />
            </ListItemIcon>
            <ListItemText
              children={`Download ${getEventDetailsMediaDownloadExtension(
                event
              )}`}
            />
          </ListItemButton>
        </List>

        <Stack mx={2}>
          <Button
            variant="outlined"
            fullWidth
            size="medium"
            color="inherit"
            onClick={onClose}
            children="Cancel"
          />
        </Stack>
      </Dialog>

      <ClipEditorTitlePrompt
        open={titleDialog.isOpen}
        onConfirm={handleConfirmTitle}
        onDecline={titleDialog.hide}
      />
    </Fragment>
  );
};
