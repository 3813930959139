export const timezoneData = [
  {
    Id: "America/New_York",
    Display: "(UTC-05:00) Eastern Time (New York)",
  },
  {
    Id: "America/Chicago",
    Display: "(UTC-06:00) Central Time (Chicago)",
  },
  {
    Id: "America/Denver",
    Display: "(UTC-07:00) Mountain Time (Denver)",
  },
  {
    Id: "America/Los_Angeles",
    Display: "(UTC-08:00) Pacific Time (Los Angeles)",
  },
  {
    Id: "America/Juneau",
    Display: "(UTC-09:00) Alaska Time (Juneau)",
  },
  {
    Id: "Pacific/Honolulu",
    Display: "(UTC-10:00) Hawaii-Aleutian Time (Adak) (Honolulu)",
  },
  {
    Id: "Pacific/Niue",
    Display: "(UTC-11:00) Niue Time",
  },
  {
    Id: "Pacific/Midway",
    Display: "(UTC-11:00) Samoa Standard Time (Midway)",
  },
  {
    Id: "Pacific/Pago_Pago",
    Display: "(UTC-11:00) Samoa Standard Time (Pago Pago)",
  },
  {
    Id: "Pacific/Rarotonga",
    Display: "(UTC-10:00) Cook Islands Standard Time (Rarotonga)",
  },
  {
    Id: "America/Adak",
    Display: "(UTC-10:00) Hawaii-Aleutian Time (Adak)",
  },
  {
    Id: "Pacific/Tahiti",
    Display: "(UTC-10:00) Tahiti Time",
  },
  {
    Id: "Pacific/Marquesas",
    Display: "(UTC-09:30) Marquesas Time",
  },
  {
    Id: "America/Anchorage",
    Display: "(UTC-09:00) Alaska Time (Anchorage)",
  },
  {
    Id: "America/Metlakatla",
    Display: "(UTC-09:00) Alaska Time (Metlakatla)",
  },
  {
    Id: "America/Nome",
    Display: "(UTC-09:00) Alaska Time (Nome)",
  },
  {
    Id: "America/Sitka",
    Display: "(UTC-09:00) Alaska Time (Sitka)",
  },
  {
    Id: "America/Yakutat",
    Display: "(UTC-09:00) Alaska Time (Yakutat)",
  },
  {
    Id: "Pacific/Gambier",
    Display: "(UTC-09:00) Gambier Time",
  },
  {
    Id: "America/Tijuana",
    Display: "(UTC-08:00) Pacific Time (Tijuana)",
  },
  {
    Id: "America/Vancouver",
    Display: "(UTC-08:00) Pacific Time (Vancouver)",
  },
  {
    Id: "Pacific/Pitcairn",
    Display: "(UTC-08:00) Pitcairn Islands Time",
  },
  {
    Id: "America/Ciudad_Juarez",
    Display: "(UTC-07:00) GMT",
  },
  {
    Id: "America/Hermosillo",
    Display: "(UTC-07:00) Mexican Pacific Time (Hermosillo)",
  },
  {
    Id: "America/Mazatlan",
    Display: "(UTC-07:00) Mexican Pacific Time (Mazatlan)",
  },
  {
    Id: "America/Boise",
    Display: "(UTC-07:00) Mountain Time (Boise)",
  },
  {
    Id: "America/Cambridge_Bay",
    Display: "(UTC-07:00) Mountain Time (Cambridge Bay)",
  },
  {
    Id: "America/Creston",
    Display: "(UTC-07:00) Mountain Time (Creston)",
  },
  {
    Id: "America/Dawson_Creek",
    Display: "(UTC-07:00) Mountain Time (Dawson Creek)",
  },
  {
    Id: "America/Edmonton",
    Display: "(UTC-07:00) Mountain Time (Edmonton)",
  },
  {
    Id: "America/Fort_Nelson",
    Display: "(UTC-07:00) Mountain Time (Fort Nelson)",
  },
  {
    Id: "America/Inuvik",
    Display: "(UTC-07:00) Mountain Time (Inuvik)",
  },
  {
    Id: "America/Phoenix",
    Display: "(UTC-07:00) Mountain Time (Phoenix)",
  },
  {
    Id: "America/Yellowknife",
    Display: "(UTC-07:00) Mountain Time (Yellowknife)",
  },
  {
    Id: "America/Dawson",
    Display: "(UTC-07:00) Pacific Time (Dawson)",
  },
  {
    Id: "America/Whitehorse",
    Display: "(UTC-07:00) Pacific Time (Whitehorse)",
  },
  {
    Id: "America/Bahia_Banderas",
    Display: "(UTC-06:00) Central Time (Bahia Banderas)",
  },
  {
    Id: "America/Belize",
    Display: "(UTC-06:00) Central Time (Belize)",
  },
  {
    Id: "America/North_Dakota/Beulah",
    Display: "(UTC-06:00) Central Time (Beulah, North Dakota)",
  },
  {
    Id: "America/North_Dakota/Center",
    Display: "(UTC-06:00) Central Time (Center, North Dakota)",
  },
  {
    Id: "America/Costa_Rica",
    Display: "(UTC-06:00) Central Time (Costa Rica)",
  },
  {
    Id: "America/El_Salvador",
    Display: "(UTC-06:00) Central Time (El Salvador)",
  },
  {
    Id: "America/Guatemala",
    Display: "(UTC-06:00) Central Time (Guatemala)",
  },
  {
    Id: "America/Indiana/Knox",
    Display: "(UTC-06:00) Central Time (Knox, Indiana)",
  },
  {
    Id: "America/Managua",
    Display: "(UTC-06:00) Central Time (Managua)",
  },
  {
    Id: "America/Matamoros",
    Display: "(UTC-06:00) Central Time (Matamoros)",
  },
  {
    Id: "America/Menominee",
    Display: "(UTC-06:00) Central Time (Menominee)",
  },
  {
    Id: "America/Merida",
    Display: "(UTC-06:00) Central Time (Merida)",
  },
  {
    Id: "America/Mexico_City",
    Display: "(UTC-06:00) Central Time (Mexico City)",
  },
  {
    Id: "America/Monterrey",
    Display: "(UTC-06:00) Central Time (Monterrey)",
  },
  {
    Id: "America/North_Dakota/New_Salem",
    Display: "(UTC-06:00) Central Time (New Salem, North Dakota)",
  },
  {
    Id: "America/Rainy_River",
    Display: "(UTC-06:00) Central Time (Rainy River)",
  },
  {
    Id: "America/Rankin_Inlet",
    Display: "(UTC-06:00) Central Time (Rankin Inlet)",
  },
  {
    Id: "America/Regina",
    Display: "(UTC-06:00) Central Time (Regina)",
  },
  {
    Id: "America/Resolute",
    Display: "(UTC-06:00) Central Time (Resolute)",
  },
  {
    Id: "America/Swift_Current",
    Display: "(UTC-06:00) Central Time (Swift Current)",
  },
  {
    Id: "America/Tegucigalpa",
    Display: "(UTC-06:00) Central Time (Tegucigalpa)",
  },
  {
    Id: "America/Indiana/Tell_City",
    Display: "(UTC-06:00) Central Time (Tell City, Indiana)",
  },
  {
    Id: "America/Winnipeg",
    Display: "(UTC-06:00) Central Time (Winnipeg)",
  },
  {
    Id: "Pacific/Easter",
    Display: "(UTC-06:00) Easter Island Time",
  },
  {
    Id: "Pacific/Galapagos",
    Display: "(UTC-06:00) Galapagos Time",
  },
  {
    Id: "America/Chihuahua",
    Display: "(UTC-06:00) Mexican Pacific Time (Chihuahua)",
  },
  {
    Id: "America/Ojinaga",
    Display: "(UTC-06:00) Mountain Time (Ojinaga)",
  },
  {
    Id: "America/Eirunepe",
    Display: "(UTC-05:00) Acre Standard Time (Eirunepe)",
  },
  {
    Id: "America/Rio_Branco",
    Display: "(UTC-05:00) Acre Standard Time (Rio Branco)",
  },
  {
    Id: "America/Bogota",
    Display: "(UTC-05:00) Colombia Standard Time (Bogota)",
  },
  {
    Id: "America/Havana",
    Display: "(UTC-05:00) Cuba Time",
  },
  {
    Id: "America/Atikokan",
    Display: "(UTC-05:00) Eastern Time (Atikokan)",
  },
  {
    Id: "America/Cancun",
    Display: "(UTC-05:00) Eastern Time (Cancun)",
  },
  {
    Id: "America/Cayman",
    Display: "(UTC-05:00) Eastern Time (Cayman)",
  },
  {
    Id: "America/Detroit",
    Display: "(UTC-05:00) Eastern Time (Detroit)",
  },
  {
    Id: "America/Grand_Turk",
    Display: "(UTC-05:00) Eastern Time (Grand Turk)",
  },
  {
    Id: "America/Indiana/Indianapolis",
    Display: "(UTC-05:00) Eastern Time (Indianapolis)",
  },
  {
    Id: "America/Iqaluit",
    Display: "(UTC-05:00) Eastern Time (Iqaluit)",
  },
  {
    Id: "America/Jamaica",
    Display: "(UTC-05:00) Eastern Time (Jamaica)",
  },
  {
    Id: "America/Kentucky/Louisville",
    Display: "(UTC-05:00) Eastern Time (Louisville)",
  },
  {
    Id: "America/Indiana/Marengo",
    Display: "(UTC-05:00) Eastern Time (Marengo, Indiana)",
  },
  {
    Id: "America/Kentucky/Monticello",
    Display: "(UTC-05:00) Eastern Time (Monticello, Kentucky)",
  },
  {
    Id: "America/Nassau",
    Display: "(UTC-05:00) Eastern Time (Nassau)",
  },
  {
    Id: "America/Nipigon",
    Display: "(UTC-05:00) Eastern Time (Nipigon)",
  },
  {
    Id: "America/Panama",
    Display: "(UTC-05:00) Eastern Time (Panama)",
  },
  {
    Id: "America/Pangnirtung",
    Display: "(UTC-05:00) Eastern Time (Pangnirtung)",
  },
  {
    Id: "America/Indiana/Petersburg",
    Display: "(UTC-05:00) Eastern Time (Petersburg, Indiana)",
  },
  {
    Id: "America/Port-au-Prince",
    Display: "(UTC-05:00) Eastern Time (Port-au-Prince)",
  },
  {
    Id: "America/Thunder_Bay",
    Display: "(UTC-05:00) Eastern Time (Thunder Bay)",
  },
  {
    Id: "America/Toronto",
    Display: "(UTC-05:00) Eastern Time (Toronto)",
  },
  {
    Id: "America/Indiana/Vevay",
    Display: "(UTC-05:00) Eastern Time (Vevay, Indiana)",
  },
  {
    Id: "America/Indiana/Vincennes",
    Display: "(UTC-05:00) Eastern Time (Vincennes, Indiana)",
  },
  {
    Id: "America/Indiana/Winamac",
    Display: "(UTC-05:00) Eastern Time (Winamac, Indiana)",
  },
  {
    Id: "America/Guayaquil",
    Display: "(UTC-05:00) Ecuador Time",
  },
  {
    Id: "America/Lima",
    Display: "(UTC-05:00) Peru Standard Time (Lima)",
  },
  {
    Id: "America/Boa_Vista",
    Display: "(UTC-04:00) Amazon Standard Time (Boa Vista)",
  },
  {
    Id: "America/Campo_Grande",
    Display: "(UTC-04:00) Amazon Standard Time (Campo Grande)",
  },
  {
    Id: "America/Cuiaba",
    Display: "(UTC-04:00) Amazon Standard Time (Cuiaba)",
  },
  {
    Id: "America/Manaus",
    Display: "(UTC-04:00) Amazon Standard Time (Manaus)",
  },
  {
    Id: "America/Porto_Velho",
    Display: "(UTC-04:00) Amazon Standard Time (Porto Velho)",
  },
  {
    Id: "America/Anguilla",
    Display: "(UTC-04:00) Atlantic Time (Anguilla)",
  },
  {
    Id: "America/Antigua",
    Display: "(UTC-04:00) Atlantic Time (Antigua)",
  },
  {
    Id: "America/Aruba",
    Display: "(UTC-04:00) Atlantic Time (Aruba)",
  },
  {
    Id: "America/Barbados",
    Display: "(UTC-04:00) Atlantic Time (Barbados)",
  },
  {
    Id: "Atlantic/Bermuda",
    Display: "(UTC-04:00) Atlantic Time (Bermuda)",
  },
  {
    Id: "America/Blanc-Sablon",
    Display: "(UTC-04:00) Atlantic Time (Blanc-Sablon)",
  },
  {
    Id: "America/Curacao",
    Display: "(UTC-04:00) Atlantic Time (Curaçao)",
  },
  {
    Id: "America/Dominica",
    Display: "(UTC-04:00) Atlantic Time (Dominica)",
  },
  {
    Id: "America/Glace_Bay",
    Display: "(UTC-04:00) Atlantic Time (Glace Bay)",
  },
  {
    Id: "America/Goose_Bay",
    Display: "(UTC-04:00) Atlantic Time (Goose Bay)",
  },
  {
    Id: "America/Grenada",
    Display: "(UTC-04:00) Atlantic Time (Grenada)",
  },
  {
    Id: "America/Guadeloupe",
    Display: "(UTC-04:00) Atlantic Time (Guadeloupe)",
  },
  {
    Id: "America/Halifax",
    Display: "(UTC-04:00) Atlantic Time (Halifax)",
  },
  {
    Id: "America/Kralendijk",
    Display: "(UTC-04:00) Atlantic Time (Kralendijk)",
  },
  {
    Id: "America/Lower_Princes",
    Display: "(UTC-04:00) Atlantic Time (Lower Prince’s Quarter)",
  },
  {
    Id: "America/Marigot",
    Display: "(UTC-04:00) Atlantic Time (Marigot)",
  },
  {
    Id: "America/Martinique",
    Display: "(UTC-04:00) Atlantic Time (Martinique)",
  },
  {
    Id: "America/Moncton",
    Display: "(UTC-04:00) Atlantic Time (Moncton)",
  },
  {
    Id: "America/Montserrat",
    Display: "(UTC-04:00) Atlantic Time (Montserrat)",
  },
  {
    Id: "America/Port_of_Spain",
    Display: "(UTC-04:00) Atlantic Time (Port of Spain)",
  },
  {
    Id: "America/Puerto_Rico",
    Display: "(UTC-04:00) Atlantic Time (Puerto Rico)",
  },
  {
    Id: "America/Santo_Domingo",
    Display: "(UTC-04:00) Atlantic Time (Santo Domingo)",
  },
  {
    Id: "America/St_Barthelemy",
    Display: "(UTC-04:00) Atlantic Time (St. Barthélemy)",
  },
  {
    Id: "America/St_Kitts",
    Display: "(UTC-04:00) Atlantic Time (St. Kitts)",
  },
  {
    Id: "America/St_Lucia",
    Display: "(UTC-04:00) Atlantic Time (St. Lucia)",
  },
  {
    Id: "America/St_Thomas",
    Display: "(UTC-04:00) Atlantic Time (St. Thomas)",
  },
  {
    Id: "America/St_Vincent",
    Display: "(UTC-04:00) Atlantic Time (St. Vincent)",
  },
  {
    Id: "America/Thule",
    Display: "(UTC-04:00) Atlantic Time (Thule)",
  },
  {
    Id: "America/Tortola",
    Display: "(UTC-04:00) Atlantic Time (Tortola)",
  },
  {
    Id: "America/La_Paz",
    Display: "(UTC-04:00) Bolivia Time",
  },
  {
    Id: "America/Santiago",
    Display: "(UTC-04:00) Chile Time",
  },
  {
    Id: "America/Guyana",
    Display: "(UTC-04:00) Guyana Time",
  },
  {
    Id: "America/Asuncion",
    Display: "(UTC-04:00) Paraguay Time",
  },
  {
    Id: "America/Caracas",
    Display: "(UTC-04:00) Venezuela Time",
  },
  {
    Id: "America/St_Johns",
    Display: "(UTC-03:30) Newfoundland Time (St. John’s)",
  },
  {
    Id: "America/Argentina/Buenos_Aires",
    Display: "(UTC-03:00) Argentina Standard Time (Buenos Aires)",
  },
  {
    Id: "America/Argentina/Catamarca",
    Display: "(UTC-03:00) Argentina Standard Time (Catamarca)",
  },
  {
    Id: "America/Argentina/Cordoba",
    Display: "(UTC-03:00) Argentina Standard Time (Cordoba)",
  },
  {
    Id: "America/Argentina/Jujuy",
    Display: "(UTC-03:00) Argentina Standard Time (Jujuy)",
  },
  {
    Id: "America/Argentina/La_Rioja",
    Display: "(UTC-03:00) Argentina Standard Time (La Rioja)",
  },
  {
    Id: "America/Argentina/Mendoza",
    Display: "(UTC-03:00) Argentina Standard Time (Mendoza)",
  },
  {
    Id: "America/Argentina/Rio_Gallegos",
    Display: "(UTC-03:00) Argentina Standard Time (Rio Gallegos)",
  },
  {
    Id: "America/Argentina/Salta",
    Display: "(UTC-03:00) Argentina Standard Time (Salta)",
  },
  {
    Id: "America/Argentina/San_Juan",
    Display: "(UTC-03:00) Argentina Standard Time (San Juan)",
  },
  {
    Id: "America/Argentina/San_Luis",
    Display: "(UTC-03:00) Argentina Standard Time (San Luis)",
  },
  {
    Id: "America/Argentina/Tucuman",
    Display: "(UTC-03:00) Argentina Standard Time (Tucuman)",
  },
  {
    Id: "America/Argentina/Ushuaia",
    Display: "(UTC-03:00) Argentina Standard Time (Ushuaia)",
  },
  {
    Id: "America/Araguaina",
    Display: "(UTC-03:00) Brasilia Standard Time (Araguaina)",
  },
  {
    Id: "America/Bahia",
    Display: "(UTC-03:00) Brasilia Standard Time (Bahia)",
  },
  {
    Id: "America/Belem",
    Display: "(UTC-03:00) Brasilia Standard Time (Belem)",
  },
  {
    Id: "America/Fortaleza",
    Display: "(UTC-03:00) Brasilia Standard Time (Fortaleza)",
  },
  {
    Id: "America/Maceio",
    Display: "(UTC-03:00) Brasilia Standard Time (Maceio)",
  },
  {
    Id: "America/Recife",
    Display: "(UTC-03:00) Brasilia Standard Time (Recife)",
  },
  {
    Id: "America/Santarem",
    Display: "(UTC-03:00) Brasilia Standard Time (Santarem)",
  },
  {
    Id: "America/Sao_Paulo",
    Display: "(UTC-03:00) Brasilia Standard Time (Sao Paulo)",
  },
  {
    Id: "Atlantic/Stanley",
    Display: "(UTC-03:00) Falkland Islands Standard Time (Stanley)",
  },
  {
    Id: "America/Cayenne",
    Display: "(UTC-03:00) French Guiana Time",
  },
  {
    Id: "America/Nuuk",
    Display: "(UTC-03:00) GMT",
  },
  {
    Id: "Antarctica/Palmer",
    Display: "(UTC-03:00) Palmer Time",
  },
  {
    Id: "America/Punta_Arenas",
    Display: "(UTC-03:00) Punta Arenas Time",
  },
  {
    Id: "Antarctica/Rothera",
    Display: "(UTC-03:00) Rothera Time",
  },
  {
    Id: "America/Miquelon",
    Display: "(UTC-03:00) St. Pierre & Miquelon Time",
  },
  {
    Id: "America/Paramaribo",
    Display: "(UTC-03:00) Suriname Time",
  },
  {
    Id: "America/Montevideo",
    Display: "(UTC-03:00) Uruguay Standard Time (Montevideo)",
  },
  {
    Id: "America/Noronha",
    Display: "(UTC-02:00) Fernando de Noronha Standard Time",
  },
  {
    Id: "Atlantic/South_Georgia",
    Display: "(UTC-02:00) South Georgia Time",
  },
  {
    Id: "Atlantic/Azores",
    Display: "(UTC-01:00) Azores Time",
  },
  {
    Id: "Atlantic/Cape_Verde",
    Display: "(UTC-01:00) Cape Verde Standard Time",
  },
  {
    Id: "America/Scoresbysund",
    Display: "(UTC-01:00) East Greenland Time (Ittoqqortoormiit)",
  },
  {
    Id: "UTC",
    Display: "(UTC) Coordinated Universal Time",
  },
  {
    Id: "Africa/Ouagadougou",
    Display: "(UTC+00:00) Burkina Faso Time",
  },
  {
    Id: "Africa/Abidjan",
    Display: "(UTC+00:00) Côte d’Ivoire Time",
  },
  {
    Id: "America/Danmarkshavn",
    Display: "(UTC+00:00) Danmarkshavn Time",
  },
  {
    Id: "Africa/Banjul",
    Display: "(UTC+00:00) Gambia Time",
  },
  {
    Id: "Africa/Accra",
    Display: "(UTC+00:00) Ghana Time",
  },
  {
    Id: "Europe/Guernsey",
    Display: "(UTC+00:00) Guernsey Time",
  },
  {
    Id: "Africa/Conakry",
    Display: "(UTC+00:00) Guinea Time",
  },
  {
    Id: "Africa/Bissau",
    Display: "(UTC+00:00) Guinea-Bissau Time",
  },
  {
    Id: "Atlantic/Reykjavik",
    Display: "(UTC+00:00) Iceland Time",
  },
  {
    Id: "Europe/Isle_of_Man",
    Display: "(UTC+00:00) Isle of Man Time",
  },
  {
    Id: "Europe/Jersey",
    Display: "(UTC+00:00) Jersey Time",
  },
  {
    Id: "Africa/Monrovia",
    Display: "(UTC+00:00) Liberia Time",
  },
  {
    Id: "Africa/Bamako",
    Display: "(UTC+00:00) Mali Time",
  },
  {
    Id: "Africa/Nouakchott",
    Display: "(UTC+00:00) Mauritania Time",
  },
  {
    Id: "Africa/Dakar",
    Display: "(UTC+00:00) Senegal Time",
  },
  {
    Id: "Africa/Freetown",
    Display: "(UTC+00:00) Sierra Leone Time",
  },
  {
    Id: "Atlantic/St_Helena",
    Display: "(UTC+00:00) St. Helena Time",
  },
  {
    Id: "Africa/Sao_Tome",
    Display: "(UTC+00:00) São Tomé & Príncipe Time",
  },
  {
    Id: "Africa/Lome",
    Display: "(UTC+00:00) Togo Time",
  },
  {
    Id: "Antarctica/Troll",
    Display: "(UTC+00:00) Troll Time",
  },
  {
    Id: "Europe/London",
    Display: "(UTC+00:00) United Kingdom Time",
  },
  {
    Id: "Atlantic/Canary",
    Display: "(UTC+00:00) Western European Time (Canary)",
  },
  {
    Id: "Atlantic/Faroe",
    Display: "(UTC+00:00) Western European Time (Faroe)",
  },
  {
    Id: "Europe/Lisbon",
    Display: "(UTC+00:00) Western European Time (Lisbon)",
  },
  {
    Id: "Atlantic/Madeira",
    Display: "(UTC+00:00) Western European Time (Madeira)",
  },
  {
    Id: "Africa/Algiers",
    Display: "(UTC+01:00) Central European Time (Algiers)",
  },
  {
    Id: "Europe/Amsterdam",
    Display: "(UTC+01:00) Central European Time (Amsterdam)",
  },
  {
    Id: "Europe/Andorra",
    Display: "(UTC+01:00) Central European Time (Andorra)",
  },
  {
    Id: "Europe/Belgrade",
    Display: "(UTC+01:00) Central European Time (Belgrade)",
  },
  {
    Id: "Europe/Berlin",
    Display: "(UTC+01:00) Central European Time (Berlin)",
  },
  {
    Id: "Europe/Bratislava",
    Display: "(UTC+01:00) Central European Time (Bratislava)",
  },
  {
    Id: "Europe/Brussels",
    Display: "(UTC+01:00) Central European Time (Brussels)",
  },
  {
    Id: "Europe/Budapest",
    Display: "(UTC+01:00) Central European Time (Budapest)",
  },
  {
    Id: "Europe/Busingen",
    Display: "(UTC+01:00) Central European Time (Busingen)",
  },
  {
    Id: "Africa/Ceuta",
    Display: "(UTC+01:00) Central European Time (Ceuta)",
  },
  {
    Id: "Europe/Copenhagen",
    Display: "(UTC+01:00) Central European Time (Copenhagen)",
  },
  {
    Id: "Europe/Gibraltar",
    Display: "(UTC+01:00) Central European Time (Gibraltar)",
  },
  {
    Id: "Europe/Ljubljana",
    Display: "(UTC+01:00) Central European Time (Ljubljana)",
  },
  {
    Id: "Arctic/Longyearbyen",
    Display: "(UTC+01:00) Central European Time (Longyearbyen)",
  },
  {
    Id: "Europe/Luxembourg",
    Display: "(UTC+01:00) Central European Time (Luxembourg)",
  },
  {
    Id: "Europe/Madrid",
    Display: "(UTC+01:00) Central European Time (Madrid)",
  },
  {
    Id: "Europe/Malta",
    Display: "(UTC+01:00) Central European Time (Malta)",
  },
  {
    Id: "Europe/Monaco",
    Display: "(UTC+01:00) Central European Time (Monaco)",
  },
  {
    Id: "Europe/Oslo",
    Display: "(UTC+01:00) Central European Time (Oslo)",
  },
  {
    Id: "Europe/Paris",
    Display: "(UTC+01:00) Central European Time (Paris)",
  },
  {
    Id: "Europe/Podgorica",
    Display: "(UTC+01:00) Central European Time (Podgorica)",
  },
  {
    Id: "Europe/Prague",
    Display: "(UTC+01:00) Central European Time (Prague)",
  },
  {
    Id: "Europe/Rome",
    Display: "(UTC+01:00) Central European Time (Rome)",
  },
  {
    Id: "Europe/San_Marino",
    Display: "(UTC+01:00) Central European Time (San Marino)",
  },
  {
    Id: "Europe/Sarajevo",
    Display: "(UTC+01:00) Central European Time (Sarajevo)",
  },
  {
    Id: "Europe/Skopje",
    Display: "(UTC+01:00) Central European Time (Skopje)",
  },
  {
    Id: "Europe/Stockholm",
    Display: "(UTC+01:00) Central European Time (Stockholm)",
  },
  {
    Id: "Europe/Tirane",
    Display: "(UTC+01:00) Central European Time (Tirane)",
  },
  {
    Id: "Africa/Tunis",
    Display: "(UTC+01:00) Central European Time (Tunis)",
  },
  {
    Id: "Europe/Vaduz",
    Display: "(UTC+01:00) Central European Time (Vaduz)",
  },
  {
    Id: "Europe/Vatican",
    Display: "(UTC+01:00) Central European Time (Vatican)",
  },
  {
    Id: "Europe/Vienna",
    Display: "(UTC+01:00) Central European Time (Vienna)",
  },
  {
    Id: "Europe/Warsaw",
    Display: "(UTC+01:00) Central European Time (Warsaw)",
  },
  {
    Id: "Europe/Zagreb",
    Display: "(UTC+01:00) Central European Time (Zagreb)",
  },
  {
    Id: "Europe/Zurich",
    Display: "(UTC+01:00) Central European Time (Zurich)",
  },
  {
    Id: "Europe/Dublin",
    Display: "(UTC+01:00) Ireland Time",
  },
  {
    Id: "Africa/Casablanca",
    Display: "(UTC+01:00) Morocco Time",
  },
  {
    Id: "Africa/Bangui",
    Display: "(UTC+01:00) West Africa Standard Time (Bangui)",
  },
  {
    Id: "Africa/Brazzaville",
    Display: "(UTC+01:00) West Africa Standard Time (Brazzaville)",
  },
  {
    Id: "Africa/Douala",
    Display: "(UTC+01:00) West Africa Standard Time (Douala)",
  },
  {
    Id: "Africa/Kinshasa",
    Display: "(UTC+01:00) West Africa Standard Time (Kinshasa)",
  },
  {
    Id: "Africa/Lagos",
    Display: "(UTC+01:00) West Africa Standard Time (Lagos)",
  },
  {
    Id: "Africa/Libreville",
    Display: "(UTC+01:00) West Africa Standard Time (Libreville)",
  },
  {
    Id: "Africa/Luanda",
    Display: "(UTC+01:00) West Africa Standard Time (Luanda)",
  },
  {
    Id: "Africa/Malabo",
    Display: "(UTC+01:00) West Africa Standard Time (Malabo)",
  },
  {
    Id: "Africa/Ndjamena",
    Display: "(UTC+01:00) West Africa Standard Time (Ndjamena)",
  },
  {
    Id: "Africa/Niamey",
    Display: "(UTC+01:00) West Africa Standard Time (Niamey)",
  },
  {
    Id: "Africa/Porto-Novo",
    Display: "(UTC+01:00) West Africa Standard Time (Porto-Novo)",
  },
  {
    Id: "Africa/El_Aaiun",
    Display: "(UTC+01:00) Western Sahara Time",
  },
  {
    Id: "Africa/Blantyre",
    Display: "(UTC+02:00) Central Africa Time (Blantyre)",
  },
  {
    Id: "Africa/Bujumbura",
    Display: "(UTC+02:00) Central Africa Time (Bujumbura)",
  },
  {
    Id: "Africa/Gaborone",
    Display: "(UTC+02:00) Central Africa Time (Gaborone)",
  },
  {
    Id: "Africa/Harare",
    Display: "(UTC+02:00) Central Africa Time (Harare)",
  },
  {
    Id: "Africa/Khartoum",
    Display: "(UTC+02:00) Central Africa Time (Khartoum)",
  },
  {
    Id: "Africa/Kigali",
    Display: "(UTC+02:00) Central Africa Time (Kigali)",
  },
  {
    Id: "Africa/Lubumbashi",
    Display: "(UTC+02:00) Central Africa Time (Lubumbashi)",
  },
  {
    Id: "Africa/Lusaka",
    Display: "(UTC+02:00) Central Africa Time (Lusaka)",
  },
  {
    Id: "Africa/Maputo",
    Display: "(UTC+02:00) Central Africa Time (Maputo)",
  },
  {
    Id: "Africa/Windhoek",
    Display: "(UTC+02:00) Central Africa Time (Windhoek)",
  },
  {
    Id: "Africa/Juba",
    Display: "(UTC+02:00) East Africa Time (Juba)",
  },
  {
    Id: "Europe/Athens",
    Display: "(UTC+02:00) Eastern European Time (Athens)",
  },
  {
    Id: "Asia/Beirut",
    Display: "(UTC+02:00) Eastern European Time (Beirut)",
  },
  {
    Id: "Europe/Bucharest",
    Display: "(UTC+02:00) Eastern European Time (Bucharest)",
  },
  {
    Id: "Africa/Cairo",
    Display: "(UTC+02:00) Eastern European Time (Cairo)",
  },
  {
    Id: "Europe/Chisinau",
    Display: "(UTC+02:00) Eastern European Time (Chisinau)",
  },
  {
    Id: "Asia/Gaza",
    Display: "(UTC+02:00) Eastern European Time (Gaza)",
  },
  {
    Id: "Asia/Hebron",
    Display: "(UTC+02:00) Eastern European Time (Hebron)",
  },
  {
    Id: "Europe/Helsinki",
    Display: "(UTC+02:00) Eastern European Time (Helsinki)",
  },
  {
    Id: "Europe/Kaliningrad",
    Display: "(UTC+02:00) Eastern European Time (Kaliningrad)",
  },
  {
    Id: "Europe/Kiev",
    Display: "(UTC+02:00) Eastern European Time (Kiev)",
  },
  {
    Id: "Europe/Mariehamn",
    Display: "(UTC+02:00) Eastern European Time (Mariehamn)",
  },
  {
    Id: "Asia/Nicosia",
    Display: "(UTC+02:00) Eastern European Time (Nicosia)",
  },
  {
    Id: "Europe/Riga",
    Display: "(UTC+02:00) Eastern European Time (Riga)",
  },
  {
    Id: "Europe/Sofia",
    Display: "(UTC+02:00) Eastern European Time (Sofia)",
  },
  {
    Id: "Europe/Tallinn",
    Display: "(UTC+02:00) Eastern European Time (Tallinn)",
  },
  {
    Id: "Africa/Tripoli",
    Display: "(UTC+02:00) Eastern European Time (Tripoli)",
  },
  {
    Id: "Europe/Uzhgorod",
    Display: "(UTC+02:00) Eastern European Time (Uzhhorod)",
  },
  {
    Id: "Europe/Vilnius",
    Display: "(UTC+02:00) Eastern European Time (Vilnius)",
  },
  {
    Id: "Europe/Zaporozhye",
    Display: "(UTC+02:00) Eastern European Time (Zaporozhye)",
  },
  {
    Id: "Asia/Famagusta",
    Display: "(UTC+02:00) Famagusta Time",
  },
  {
    Id: "Asia/Jerusalem",
    Display: "(UTC+02:00) Israel Time",
  },
  {
    Id: "Africa/Johannesburg",
    Display: "(UTC+02:00) South Africa Standard Time (Johannesburg)",
  },
  {
    Id: "Africa/Maseru",
    Display: "(UTC+02:00) South Africa Standard Time (Maseru)",
  },
  {
    Id: "Africa/Mbabane",
    Display: "(UTC+02:00) South Africa Standard Time (Mbabane)",
  },
  {
    Id: "Asia/Aden",
    Display: "(UTC+03:00) Arabian Standard Time (Aden)",
  },
  {
    Id: "Asia/Baghdad",
    Display: "(UTC+03:00) Arabian Standard Time (Baghdad)",
  },
  {
    Id: "Asia/Bahrain",
    Display: "(UTC+03:00) Arabian Standard Time (Bahrain)",
  },
  {
    Id: "Asia/Kuwait",
    Display: "(UTC+03:00) Arabian Standard Time (Kuwait)",
  },
  {
    Id: "Asia/Qatar",
    Display: "(UTC+03:00) Arabian Standard Time (Qatar)",
  },
  {
    Id: "Asia/Riyadh",
    Display: "(UTC+03:00) Arabian Standard Time (Riyadh)",
  },
  {
    Id: "Europe/Minsk",
    Display: "(UTC+03:00) Belarus Time",
  },
  {
    Id: "Africa/Addis_Ababa",
    Display: "(UTC+03:00) East Africa Time (Addis Ababa)",
  },
  {
    Id: "Indian/Antananarivo",
    Display: "(UTC+03:00) East Africa Time (Antananarivo)",
  },
  {
    Id: "Africa/Asmara",
    Display: "(UTC+03:00) East Africa Time (Asmara)",
  },
  {
    Id: "Indian/Comoro",
    Display: "(UTC+03:00) East Africa Time (Comoro)",
  },
  {
    Id: "Africa/Dar_es_Salaam",
    Display: "(UTC+03:00) East Africa Time (Dar es Salaam)",
  },
  {
    Id: "Africa/Djibouti",
    Display: "(UTC+03:00) East Africa Time (Djibouti)",
  },
  {
    Id: "Africa/Kampala",
    Display: "(UTC+03:00) East Africa Time (Kampala)",
  },
  {
    Id: "Indian/Mayotte",
    Display: "(UTC+03:00) East Africa Time (Mayotte)",
  },
  {
    Id: "Africa/Mogadishu",
    Display: "(UTC+03:00) East Africa Time (Mogadishu)",
  },
  {
    Id: "Africa/Nairobi",
    Display: "(UTC+03:00) East Africa Time (Nairobi)",
  },
  {
    Id: "Asia/Amman",
    Display: "(UTC+03:00) Eastern European Time (Amman)",
  },
  {
    Id: "Asia/Damascus",
    Display: "(UTC+03:00) Eastern European Time (Damascus)",
  },
  {
    Id: "Europe/Kirov",
    Display: "(UTC+03:00) Kirov Time",
  },
  {
    Id: "Europe/Moscow",
    Display: "(UTC+03:00) Moscow Time",
  },
  {
    Id: "Europe/Simferopol",
    Display: "(UTC+03:00) Simferopol Time",
  },
  {
    Id: "Antarctica/Syowa",
    Display: "(UTC+03:00) Syowa Time",
  },
  {
    Id: "Europe/Istanbul",
    Display: "(UTC+03:00) Turkey Time",
  },
  {
    Id: "Europe/Volgograd",
    Display: "(UTC+03:00) Volgograd Standard Time",
  },
  {
    Id: "Asia/Tehran",
    Display: "(UTC+03:30) Iran Time",
  },
  {
    Id: "Asia/Yerevan",
    Display: "(UTC+04:00) Armenia Standard Time (Yerevan)",
  },
  {
    Id: "Europe/Astrakhan",
    Display: "(UTC+04:00) Astrakhan Time",
  },
  {
    Id: "Asia/Baku",
    Display: "(UTC+04:00) Azerbaijan Standard Time (Baku)",
  },
  {
    Id: "Asia/Tbilisi",
    Display: "(UTC+04:00) Georgia Standard Time (Tbilisi)",
  },
  {
    Id: "Asia/Dubai",
    Display: "(UTC+04:00) Gulf Standard Time (Dubai)",
  },
  {
    Id: "Asia/Muscat",
    Display: "(UTC+04:00) Gulf Standard Time (Muscat)",
  },
  {
    Id: "Indian/Mauritius",
    Display: "(UTC+04:00) Mauritius Standard Time",
  },
  {
    Id: "Indian/Reunion",
    Display: "(UTC+04:00) Réunion Time",
  },
  {
    Id: "Europe/Samara",
    Display: "(UTC+04:00) Samara Standard Time",
  },
  {
    Id: "Europe/Saratov",
    Display: "(UTC+04:00) Saratov Time",
  },
  {
    Id: "Indian/Mahe",
    Display: "(UTC+04:00) Seychelles Time",
  },
  {
    Id: "Europe/Ulyanovsk",
    Display: "(UTC+04:00) Ulyanovsk Time",
  },
  {
    Id: "Asia/Kabul",
    Display: "(UTC+04:30) Afghanistan Time",
  },
  {
    Id: "Indian/Kerguelen",
    Display: "(UTC+05:00) French Southern & Antarctic Time (Kerguelen)",
  },
  {
    Id: "Indian/Maldives",
    Display: "(UTC+05:00) Maldives Time",
  },
  {
    Id: "Antarctica/Mawson",
    Display: "(UTC+05:00) Mawson Time",
  },
  {
    Id: "Asia/Karachi",
    Display: "(UTC+05:00) Pakistan Standard Time (Karachi)",
  },
  {
    Id: "Asia/Dushanbe",
    Display: "(UTC+05:00) Tajikistan Time",
  },
  {
    Id: "Asia/Ashgabat",
    Display: "(UTC+05:00) Turkmenistan Standard Time (Ashgabat)",
  },
  {
    Id: "Asia/Samarkand",
    Display: "(UTC+05:00) Uzbekistan Standard Time (Samarkand)",
  },
  {
    Id: "Asia/Tashkent",
    Display: "(UTC+05:00) Uzbekistan Standard Time (Tashkent)",
  },
  {
    Id: "Asia/Aqtau",
    Display: "(UTC+05:00) West Kazakhstan Time (Aqtau)",
  },
  {
    Id: "Asia/Aqtobe",
    Display: "(UTC+05:00) West Kazakhstan Time (Aqtobe)",
  },
  {
    Id: "Asia/Atyrau",
    Display: "(UTC+05:00) West Kazakhstan Time (Atyrau)",
  },
  {
    Id: "Asia/Oral",
    Display: "(UTC+05:00) West Kazakhstan Time (Oral)",
  },
  {
    Id: "Asia/Qyzylorda",
    Display: "(UTC+05:00) West Kazakhstan Time (Qyzylorda)",
  },
  {
    Id: "Asia/Yekaterinburg",
    Display: "(UTC+05:00) Yekaterinburg Standard Time",
  },
  {
    Id: "Asia/Colombo",
    Display: "(UTC+05:30) India Standard Time (Colombo)",
  },
  {
    Id: "Asia/Kolkata",
    Display: "(UTC+05:30) India Standard Time (Kolkata)",
  },
  {
    Id: "Asia/Kathmandu",
    Display: "(UTC+05:45) Nepal Time",
  },
  {
    Id: "Asia/Dhaka",
    Display: "(UTC+06:00) Bangladesh Standard Time (Dhaka)",
  },
  {
    Id: "Asia/Thimphu",
    Display: "(UTC+06:00) Bhutan Time",
  },
  {
    Id: "Asia/Almaty",
    Display: "(UTC+06:00) East Kazakhstan Time (Almaty)",
  },
  {
    Id: "Asia/Qostanay",
    Display: "(UTC+06:00) East Kazakhstan Time (Kostanay)",
  },
  {
    Id: "Indian/Chagos",
    Display: "(UTC+06:00) Indian Ocean Time (Chagos)",
  },
  {
    Id: "Asia/Bishkek",
    Display: "(UTC+06:00) Kyrgyzstan Time",
  },
  {
    Id: "Asia/Omsk",
    Display: "(UTC+06:00) Omsk Standard Time",
  },
  {
    Id: "Asia/Urumqi",
    Display: "(UTC+06:00) Urumqi Time",
  },
  {
    Id: "Antarctica/Vostok",
    Display: "(UTC+06:00) Vostok Time",
  },
  {
    Id: "Indian/Cocos",
    Display: "(UTC+06:30) Cocos Islands Time",
  },
  {
    Id: "Asia/Yangon",
    Display: "(UTC+06:30) Myanmar Time (Yangon)",
  },
  {
    Id: "Asia/Barnaul",
    Display: "(UTC+07:00) Barnaul Time",
  },
  {
    Id: "Indian/Christmas",
    Display: "(UTC+07:00) Christmas Island Time",
  },
  {
    Id: "Antarctica/Davis",
    Display: "(UTC+07:00) Davis Time",
  },
  {
    Id: "Asia/Hovd",
    Display: "(UTC+07:00) Hovd Standard Time",
  },
  {
    Id: "Asia/Bangkok",
    Display: "(UTC+07:00) Indochina Time (Bangkok)",
  },
  {
    Id: "Asia/Ho_Chi_Minh",
    Display: "(UTC+07:00) Indochina Time (Ho Chi Minh City)",
  },
  {
    Id: "Asia/Phnom_Penh",
    Display: "(UTC+07:00) Indochina Time (Phnom Penh)",
  },
  {
    Id: "Asia/Vientiane",
    Display: "(UTC+07:00) Indochina Time (Vientiane)",
  },
  {
    Id: "Asia/Krasnoyarsk",
    Display: "(UTC+07:00) Krasnoyarsk Standard Time",
  },
  {
    Id: "Asia/Novokuznetsk",
    Display: "(UTC+07:00) Krasnoyarsk Standard Time (Novokuznetsk)",
  },
  {
    Id: "Asia/Novosibirsk",
    Display: "(UTC+07:00) Novosibirsk Standard Time",
  },
  {
    Id: "Asia/Tomsk",
    Display: "(UTC+07:00) Tomsk Time",
  },
  {
    Id: "Asia/Jakarta",
    Display: "(UTC+07:00) Western Indonesia Time (Jakarta)",
  },
  {
    Id: "Asia/Pontianak",
    Display: "(UTC+07:00) Western Indonesia Time (Pontianak)",
  },
  {
    Id: "Australia/Perth",
    Display: "(UTC+08:00) Australian Western Standard Time (Perth)",
  },
  {
    Id: "Asia/Brunei",
    Display: "(UTC+08:00) Brunei Darussalam Time",
  },
  {
    Id: "Asia/Makassar",
    Display: "(UTC+08:00) Central Indonesia Time (Makassar)",
  },
  {
    Id: "Asia/Macau",
    Display: "(UTC+08:00) China Standard Time (Macao)",
  },
  {
    Id: "Asia/Shanghai",
    Display: "(UTC+08:00) China Standard Time (Shanghai)",
  },
  {
    Id: "Asia/Hong_Kong",
    Display: "(UTC+08:00) Hong Kong Standard Time",
  },
  {
    Id: "Asia/Irkutsk",
    Display: "(UTC+08:00) Irkutsk Standard Time",
  },
  {
    Id: "Asia/Kuala_Lumpur",
    Display: "(UTC+08:00) Malaysia Time",
  },
  {
    Id: "Asia/Kuching",
    Display: "(UTC+08:00) Malaysia Time (Kuching)",
  },
  {
    Id: "Asia/Manila",
    Display: "(UTC+08:00) Philippine Standard Time (Manila)",
  },
  {
    Id: "Asia/Singapore",
    Display: "(UTC+08:00) Singapore Standard Time",
  },
  {
    Id: "Asia/Taipei",
    Display: "(UTC+08:00) Taipei Standard Time",
  },
  {
    Id: "Asia/Ulaanbaatar",
    Display: "(UTC+08:00) Ulaanbaatar Standard Time",
  },
  {
    Id: "Asia/Choibalsan",
    Display: "(UTC+08:00) Ulaanbaatar Standard Time (Choibalsan)",
  },
  {
    Id: "Australia/Eucla",
    Display: "(UTC+08:45) Australian Central Western Standard Time (Eucla)",
  },
  {
    Id: "Asia/Dili",
    Display: "(UTC+09:00) East Timor Time (Dili)",
  },
  {
    Id: "Asia/Jayapura",
    Display: "(UTC+09:00) Eastern Indonesia Time (Jayapura)",
  },
  {
    Id: "Asia/Tokyo",
    Display: "(UTC+09:00) Japan Standard Time (Tokyo)",
  },
  {
    Id: "Asia/Pyongyang",
    Display: "(UTC+09:00) Korean Standard Time (Pyongyang)",
  },
  {
    Id: "Asia/Seoul",
    Display: "(UTC+09:00) Korean Standard Time (Seoul)",
  },
  {
    Id: "Pacific/Palau",
    Display: "(UTC+09:00) Palau Time",
  },
  {
    Id: "Asia/Yakutsk",
    Display: "(UTC+09:00) Yakutsk Standard Time",
  },
  {
    Id: "Asia/Chita",
    Display: "(UTC+09:00) Yakutsk Standard Time (Chita)",
  },
  {
    Id: "Asia/Khandyga",
    Display: "(UTC+09:00) Yakutsk Standard Time (Khandyga)",
  },
  {
    Id: "Australia/Adelaide",
    Display: "(UTC+09:30) Central Australia Time (Adelaide)",
  },
  {
    Id: "Australia/Broken_Hill",
    Display: "(UTC+09:30) Central Australia Time (Broken Hill)",
  },
  {
    Id: "Australia/Darwin",
    Display: "(UTC+09:30) Central Australia Time (Darwin)",
  },
  {
    Id: "Pacific/Guam",
    Display: "(UTC+10:00) Chamorro Standard Time (Guam)",
  },
  {
    Id: "Pacific/Saipan",
    Display: "(UTC+10:00) Chamorro Standard Time (Saipan)",
  },
  {
    Id: "Pacific/Chuuk",
    Display: "(UTC+10:00) Chuuk Time",
  },
  {
    Id: "Antarctica/DumontDUrville",
    Display: "(UTC+10:00) Dumont-d’Urville Time (Dumont d’Urville)",
  },
  {
    Id: "Australia/Brisbane",
    Display: "(UTC+10:00) Eastern Australia Time (Brisbane)",
  },
  {
    Id: "Australia/Hobart",
    Display: "(UTC+10:00) Eastern Australia Time (Hobart)",
  },
  {
    Id: "Australia/Lindeman",
    Display: "(UTC+10:00) Eastern Australia Time (Lindeman)",
  },
  {
    Id: "Australia/Melbourne",
    Display: "(UTC+10:00) Eastern Australia Time (Melbourne)",
  },
  {
    Id: "Australia/Sydney",
    Display: "(UTC+10:00) Eastern Australia Time (Sydney)",
  },
  {
    Id: "Antarctica/Macquarie",
    Display: "(UTC+10:00) Macquarie Island Time",
  },
  {
    Id: "Pacific/Port_Moresby",
    Display: "(UTC+10:00) Papua New Guinea Time (Port Moresby)",
  },
  {
    Id: "Asia/Vladivostok",
    Display: "(UTC+10:00) Vladivostok Standard Time",
  },
  {
    Id: "Asia/Ust-Nera",
    Display: "(UTC+10:00) Vladivostok Standard Time (Ust-Nera)",
  },
  {
    Id: "Australia/Lord_Howe",
    Display: "(UTC+10:30) Lord Howe Time",
  },
  {
    Id: "Antarctica/Casey",
    Display: "(UTC+11:00) Australian Western Standard Time (Casey)",
  },
  {
    Id: "Pacific/Bougainville",
    Display: "(UTC+11:00) Bougainville Time",
  },
  {
    Id: "Pacific/Kosrae",
    Display: "(UTC+11:00) Kosrae Time",
  },
  {
    Id: "Asia/Magadan",
    Display: "(UTC+11:00) Magadan Standard Time",
  },
  {
    Id: "Pacific/Noumea",
    Display: "(UTC+11:00) New Caledonia Standard Time (Noumea)",
  },
  {
    Id: "Pacific/Norfolk",
    Display: "(UTC+11:00) Norfolk Island Time",
  },
  {
    Id: "Pacific/Pohnpei",
    Display: "(UTC+11:00) Ponape Time (Pohnpei)",
  },
  {
    Id: "Asia/Sakhalin",
    Display: "(UTC+11:00) Sakhalin Standard Time",
  },
  {
    Id: "Pacific/Guadalcanal",
    Display: "(UTC+11:00) Solomon Islands Time",
  },
  {
    Id: "Asia/Srednekolymsk",
    Display: "(UTC+11:00) Srednekolymsk Time",
  },
  {
    Id: "Pacific/Efate",
    Display: "(UTC+11:00) Vanuatu Standard Time (Efate)",
  },
  {
    Id: "Asia/Anadyr",
    Display: "(UTC+12:00) Anadyr Standard Time",
  },
  {
    Id: "Pacific/Fiji",
    Display: "(UTC+12:00) Fiji Time",
  },
  {
    Id: "Pacific/Tarawa",
    Display: "(UTC+12:00) Gilbert Islands Time (Tarawa)",
  },
  {
    Id: "Pacific/Majuro",
    Display: "(UTC+12:00) Marshall Islands Time",
  },
  {
    Id: "Pacific/Kwajalein",
    Display: "(UTC+12:00) Marshall Islands Time (Kwajalein)",
  },
  {
    Id: "Pacific/Nauru",
    Display: "(UTC+12:00) Nauru Time",
  },
  {
    Id: "Pacific/Auckland",
    Display: "(UTC+12:00) New Zealand Time",
  },
  {
    Id: "Antarctica/McMurdo",
    Display: "(UTC+12:00) New Zealand Time (McMurdo)",
  },
  {
    Id: "Asia/Kamchatka",
    Display: "(UTC+12:00) Petropavlovsk-Kamchatski Standard Time (Kamchatka)",
  },
  {
    Id: "Pacific/Funafuti",
    Display: "(UTC+12:00) Tuvalu Time",
  },
  {
    Id: "Pacific/Wake",
    Display: "(UTC+12:00) Wake Island Time",
  },
  {
    Id: "Pacific/Wallis",
    Display: "(UTC+12:00) Wallis & Futuna Time",
  },
  {
    Id: "Pacific/Chatham",
    Display: "(UTC+12:45) Chatham Time",
  },
  {
    Id: "Pacific/Enderbury",
    Display: "(UTC+13:00) Phoenix Islands Time (Enderbury)",
  },
  {
    Id: "Pacific/Apia",
    Display: "(UTC+13:00) Samoa Time",
  },
  {
    Id: "Pacific/Fakaofo",
    Display: "(UTC+13:00) Tokelau Time",
  },
  {
    Id: "Pacific/Tongatapu",
    Display: "(UTC+13:00) Tonga Standard Time (Tongatapu)",
  },
  {
    Id: "Pacific/Kiritimati",
    Display: "(UTC+14:00) Line Islands Time (Kiritimati)",
  },
];
