import { autocompleteClasses } from "@mui/material";
import { useAppAutocompleteInlineStyles } from "./useAppAutocompleteInlineStyles";

export const useAppAutocompleteMultilineStyles = ({
  isFocused,
  size = "medium",
}: {
  size?: "small" | "medium";
  isFocused?: boolean;
  lineClamp?: number;
}) => {
  const inlineSx = useAppAutocompleteInlineStyles({ size });

  if (isFocused) {
    return {
      // multiline styles
      [`& .${autocompleteClasses.inputRoot}`]: {
        paddingRight: "88px!important",
        flexWrap: "wrap",
      },
      [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
        minWidth: "80px",
      },
    };
  }

  // default to inline styles
  return inlineSx;
};
