import { AppRouteBuilder } from "src/models/AppRoute";
import { ReportAnalysisPage } from "./ReportAnalysis.page";

export type ReportAnalysisRouteQueryParams = {
  //
};

export const reportAnalysisRoute = new AppRouteBuilder<
  void,
  ReportAnalysisRouteQueryParams
>({
  path: "/report-analysis",
  element: <ReportAnalysisPage />,
});
