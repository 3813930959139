import { TextFieldProps, dialogActionsClasses, useTheme } from "@mui/material";
import {
  DateTimePicker,
  DateTimePickerProps,
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { useState } from "react";
import { useOpenState } from "src/utils/useOpenState";

const getDefaultDateTimeFormat = (
  views: DateTimePickerProps<Date>["views"]
) => {
  if (views?.includes("hours")) {
    if (views?.includes("seconds")) {
      return "PPpp";
    }

    return "PPp";
  }

  return "PP";
};

export const AppDateTimePicker = ({
  views = ["year", "month", "day", "hours", "minutes", "seconds"],
  onChange,
  onAccept,
  onOpen,
  onClose,
  ...props
}: Omit<DateTimePickerProps<Date>, "ampmInClock">) => {
  const [isFocused, setFocused] = useState(false);
  const defaultFormat = getDefaultDateTimeFormat(views);
  const dropDownOpenState = useOpenState();
  const [context, setContext] =
    useState<PickerChangeHandlerContext<DateTimeValidationError>>();

  const onChangeWrapper: DateTimePickerProps<Date>["onChange"] = (
    value,
    context
  ) => {
    if (dropDownOpenState.isOpen) {
      setContext(context);
    } else {
      onChange?.(value, context);
    }
  };

  const onAcceptWrapper: DateTimePickerProps<Date>["onAccept"] = (
    value,
    error
  ) => {
    if (dropDownOpenState.isOpen) {
      if (context) {
        onChange?.(value, context);
      }
    } else {
      onAccept?.(value, error);
    }
  };

  const onOpenWrapper: DateTimePickerProps<Date>["onOpen"] = () => {
    dropDownOpenState.show();
    onOpen?.();
  };

  const onCloseWrapper: DateTimePickerProps<Date>["onClose"] = () => {
    dropDownOpenState.hide();
    onClose?.();
  };

  const onTextFieldClick: TextFieldProps["onClick"] = () => {
    dropDownOpenState.show();
  };

  const onTextFieldFocus: TextFieldProps["onFocus"] = () => {
    setFocused(true);
  };

  const onTextFieldBlur: TextFieldProps["onBlur"] = () => {
    setFocused(false);
  };

  const { breakpoints } = useTheme();
  return (
    <DateTimePicker
      ampmInClock
      views={views}
      format={defaultFormat}
      desktopModeMediaQuery={breakpoints.up("sm")}
      onAccept={onAcceptWrapper}
      onChange={onChangeWrapper}
      onOpen={onOpenWrapper}
      onClose={onCloseWrapper}
      open={dropDownOpenState.isOpen}
      {...props}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          focused: isFocused || dropDownOpenState.isOpen,
          onFocus: onTextFieldFocus,
          onBlur: onTextFieldBlur,
          onClick: onTextFieldClick,
          sx: {
            ".clearButton": {
              display: "none",
            },

            "&:hover": {
              ".clearButton": {
                display: "flex",
              },
            },
          },
        },
        layout: {
          ...props.slotProps?.layout,
          sx: {
            ...props.slotProps?.layout?.sx,
            [`.${dialogActionsClasses.root}`]: {
              paddingY: 1,
              paddingX: 2,
            },
            ".MuiMultiSectionDigitalClockSection-root:after": {
              display: "none",
            },
            ".MuiTimeClock-arrowSwitcher": {
              pointerEvents: "none",
              left: 4,
              right: 4,

              ".MuiPickersArrowSwitcher-spacer": {
                margin: "auto",
              },

              ".MuiPickersArrowSwitcher-button": {
                pointerEvents: "auto",
              },
            },
          },
        },

        mobilePaper: {
          ...props.slotProps?.mobilePaper,
          sx: {
            ...props.slotProps?.mobilePaper?.sx,
            margin: 2,
          },
        },

        toolbar: {
          ...props.slotProps?.toolbar,
          sx: {
            ...props.slotProps?.toolbar?.sx,
            ".MuiPickersToolbar-content": {
              alignItems: "flex-end",
            },
            ".MuiDateTimePickerToolbar-dateContainer button:last-of-type": {
              mr: 1,
              span: {
                fontSize: "32px",
                lineHeight: "120%",
              },
            },

            ".MuiDateTimePickerToolbar-timeContainer": {
              minWidth: "168px",
              justifyContent: "flex-end",
            },

            ".MuiDateTimePickerToolbar-timeDigitsContainer span": {
              fontSize: "32px",
              lineHeight: "120%",
            },
          },
        },
      }}
    />
  );
};
