import { Stack, Tooltip } from "@mui/material";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getPowerSearchResultCategory,
  getPowerSearchResultCountry,
  getPowerSearchResultDateTime,
  getPowerSearchResultEventType,
  getPowerSearchResultLanguage,
  getPowerSearchResultMarket,
  getPowerSearchResultEvent,
  getPowerSearchResultSource,
  getPowerSearchResultTranscript,
  getPowerSearchResultViews,
  getPowerSearchResultId,
  getPowerSearchResultMediaType,
  getPowerSearchResultTag,
  getPowerSearchResultDuplicates,
} from "src/api/usePowerSearchResult.getters";
import { stripHTMLTags } from "src/utils/stripHTMLTags";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { AppLink } from "src/components/AppLink/AppLink";
import { AdvChip } from "src/components/AdvChip/AdvChip";
import { DuplicatesChip } from "src/components/DuplicatesChip/DuplicatesChip";
import { getEventTypeLabel } from "src/utils/getEventTypeLabel";
import { getEventIcon } from "src/utils/getEventIcon";
import { powerSearchResultClipRoute } from "src/pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { PowerSearchResultTableClasses } from "./PowerSearchResultTable.const";
import { PowerSearchResultTableColDef } from "./PowerSearchResultTable.model";
import { PowerSearchResultActions } from "../PowerSearchResultActions/PowerSearchResultActions";

const commonCellParams: Pick<
  PowerSearchResultTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu" | "renderCell"
> = {
  sortable: false,
  disableColumnMenu: true,
  renderCell(params) {
    return (
      <Tooltip title={params.value}>
        <TextLineClamp lineClamp={2}>{params.value}</TextLineClamp>
      </Tooltip>
    );
  },
};

export function makePowerSearchResultTableColumns({
  highlightColor = "inherit",
  addItemToReport,
  showDuplicatesDrawer,
  selectedDuplicatesIds,
}: {
  highlightColor?: string;
  addItemToReport: (value: PowerSearchResultItem) => void;
  showDuplicatesDrawer: (eventId: string, duplicatesIds: string[]) => void;
  selectedDuplicatesIds: string[];
}): PowerSearchResultTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Event",
      description: "Event",
      field: "title",
      minWidth: 160,
      flex: 1,
      cellClassName: PowerSearchResultTableClasses.cellEvent,
      renderCell(params) {
        const detailsUrl = powerSearchResultClipRoute.makeUrl({
          eventId: getPowerSearchResultId(params.row),
        });

        return (
          <AppLink
            title={getPowerSearchResultEvent(params.row)}
            to={detailsUrl}
          >
            <Tooltip title={getPowerSearchResultEvent(params.row)}>
              <TextLineClamp lineClamp={1}>
                {getPowerSearchResultEvent(params.row)}
              </TextLineClamp>
            </Tooltip>
          </AppLink>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Type",
      description: "Type",
      field: "type",
      width: 56,
      renderCell(params) {
        const eventType = getPowerSearchResultEventType(params.row);
        const mediaType = getPowerSearchResultMediaType(params.row);
        const eventLabel = getEventTypeLabel(eventType);
        const Icon = getEventIcon(eventType, mediaType);

        return (
          <Tooltip title={eventLabel}>
            <Stack m="auto">
              <Icon />
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Date",
      description: "Date",
      field: "date",
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultDateTime(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Has ads",
      field: "adTrackIds",
      flex: 0.5,
      renderCell(params) {
        const value = getPowerSearchResultTag(params.row);

        return value.length ? <AdvChip /> : undefined;
      },
    },
    {
      ...commonCellParams,
      headerName: "Transcription",
      description: "Transcription",
      field: "transcription",
      minWidth: 160,
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultTranscript(params.row);
      },
      renderCell(params) {
        const title = stripHTMLTags({ transcript: params.value });
        return (
          <Tooltip title={title}>
            <TranscriptText
              variant="body2"
              highlightColor={highlightColor}
              transcript={params.value}
              lineClamp={2}
            />
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Country",
      description: "Country",
      field: "country",
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultCountry(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Market",
      description: "Market",
      field: "market",
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultMarket(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Source",
      description: "Source",
      field: "source",
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultSource(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Language",
      description: "Language",
      field: "language",
      flex: 1,
      valueGetter(params) {
        return getPowerSearchResultLanguage(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Duplicates",
      field: "duplicates",
      align: "center",
      renderCell(params) {
        const eventId = getPowerSearchResultId(params.row);
        const duplicatesIds = getPowerSearchResultDuplicates(params.row);
        const itemSelectedDuplicates = duplicatesIds.filter((id) =>
          selectedDuplicatesIds.includes(id)
        );
        return (
          !!duplicatesIds.length && (
            <DuplicatesChip
              count={duplicatesIds.length}
              selectedCount={itemSelectedDuplicates.length}
              onClick={() => showDuplicatesDrawer(eventId, duplicatesIds)}
            />
          )
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Category",
      description: "Category",
      field: "category",
      valueGetter(params) {
        return getPowerSearchResultCategory(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Views",
      description: "Views",
      headerAlign: "center",
      field: "views",
      align: "center",
      type: "number",
      valueGetter(params) {
        return getPowerSearchResultViews(params.row);
      },
      valueFormatter({ value }) {
        if (value === undefined) {
          return " - ";
        }

        if (Number.isNaN(value)) {
          return " - ";
        }

        return value.toLocaleString();
      },
    },
    {
      ...commonCellParams,
      headerName: "Actions",
      description: "Actions",
      headerAlign: "center",
      field: "actions",
      align: "center",
      type: "actions",
      cellClassName: PowerSearchResultTableClasses.cellEventActions,
      renderCell(params) {
        return (
          <PowerSearchResultActions
            value={params.row}
            addItemToReport={addItemToReport}
          />
        );
      },
    },
  ];
}
