import { Menu, ManageSearchOutlined } from "@mui/icons-material";
import {
  AppBar,
  badgeClasses,
  Badge,
  Box,
  IconButton,
  Toolbar,
  alpha,
  useTheme,
} from "@mui/material";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useGlobalSearchOpen } from "src/api/useGlobalSearchOpen";
import { useDrawer } from "src/utils/useDrawer";
import { Logo } from "../logo/Logo";
import { AppLink } from "../AppLink/AppLink";
import { IntercomButton } from "../IntercomButton/IntercomButton";

export function AppTopBarMobile({
  hideGlobalSearchBtn = false,
  hideIntercomBtn = false,
}: {
  hideGlobalSearchBtn?: boolean;
  hideIntercomBtn?: boolean;
}) {
  const theme = useTheme();
  const drawer = useDrawer();
  const watchQuerySearchDialog = useGlobalSearchOpen();
  const { highlightedEventsIds, allAddedEventsLenght } = useEventsPreselected();

  const menuIconButton = (
    <IconButton
      edge="start"
      onClick={drawer.show}
      children={
        <Menu
          fontSize="large"
          sx={{ color: alpha(theme.palette.text.primary, 0.65) }}
        />
      }
    />
  );

  return (
    <AppBar position="relative">
      <Toolbar>
        {!!highlightedEventsIds.length || !!allAddedEventsLenght ? (
          <Badge
            color="info"
            overlap="circular"
            variant="dot"
            children={menuIconButton}
            sx={{
              [`.${badgeClasses.badge}`]: {
                top: 12,
                right: 10,
              },
            }}
          />
        ) : (
          menuIconButton
        )}
        <Box ml="auto" mr="auto" mt={0.5}>
          <AppLink to={"/"}>
            <Logo dark={theme.palette.mode === "dark"} height={34} />
          </AppLink>
        </Box>
        {hideIntercomBtn ? null : <IntercomButton />}
        {hideGlobalSearchBtn ? null : (
          <IconButton edge="end" onClick={watchQuerySearchDialog.show}>
            <ManageSearchOutlined
              fontSize="large"
              sx={{ color: alpha(theme.palette.text.primary, 0.65) }}
            />
          </IconButton>
        )}
        {hideIntercomBtn && hideGlobalSearchBtn && <Box width={35} />}
      </Toolbar>
    </AppBar>
  );
}
