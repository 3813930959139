import EmailTemplate from "../components/assets/email-template.png";
import Webpage from "../components/assets/webpage.png";
import Report from "../components/assets/Report.png";
import MobileEmailTemplate from "../components/assets/mobile-email-template.png";
import MobileWebpage from "../components/assets/mobile-webpage.png";
import MobileReport from "../components/assets/mobile-report.png";

export enum SettingsGeneralTabClasses {
  avatarButton = "avatar-button",
}

export const DESKTOP_PREVIEWS = [
  {
    label: "Email template",
    img: EmailTemplate,
    position: {
      top: "63px",
      left: "136px",
    },
    width: 174,
    height: 22,
  },
  {
    label: "Webpage",
    img: Webpage,
    position: {
      top: "22px",
      left: "105px",
    },
    width: 168,
    height: 22,
    hasLogin: true,
  },
  {
    label: "Report",
    img: Report,
    position: {
      top: "19px",
      left: "83px",
    },
    width: 224,
    height: 21,
    hasLogin: true,
  },
];

export const MOBILE_PREVIEWS = [
  {
    label: "Email template",
    img: MobileEmailTemplate,
    position: {
      top: "64px",
      left: "7px",
    },
    width: 90,
    height: 26,
  },
  {
    label: "Webpage",
    img: MobileWebpage,
    position: {
      top: "21px",
      left: "6px",
    },
    width: 91,
    height: 21,
    hasLogin: true,
  },
  {
    label: "Report",
    img: MobileReport,
    position: {
      top: "20px",
      left: "7px",
    },
    width: 91,
    height: 22,
    hasLogin: true,
  },
];
