import { Box, IconButton, Tooltip, alpha, useTheme } from "@mui/material";
import {
  NotificationsActive,
  NotificationsActiveOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "src/api/useSessionContext";

export function InstantAlertButton({
  onClick,
  active,
}: {
  onClick?: () => void;
  active?: boolean;
}) {
  const { palette } = useTheme();
  const { effectiveEntitlements } = useSessionContext();
  const { t } = useTranslation();

  const enableEditWatchList = effectiveEntitlements.enableEditWatchList?.value;
  const Icon = active ? NotificationsActive : NotificationsActiveOutlined;
  return (
    <Tooltip
      title={
        enableEditWatchList
          ? active
            ? "Turn off Instant Alert"
            : "Turn on Instant Alert"
          : t("editWatchListDisabled")
      }
    >
      <Box>
        <IconButton
          disabled={!enableEditWatchList}
          onClick={onClick}
          sx={{
            borderRadius: 2,
            color: palette.text.secondary,
            backgroundColor: active
              ? alpha(palette.primary.light, 0.08)
              : undefined,
          }}
        >
          <Icon color={active ? "primary" : undefined} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
