import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { useCallback } from "react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ReportItem } from "src/models/Report";
import { usePushNotificationEvent } from "src/utils/usePushNotificationEvent";

const apiPath: keyof paths = "/api/reports";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = paths[typeof apiPath][typeof method];
type Parameters = Endpoint["parameters"];

type UseReportsListParams = {
  query: Parameters["query"];
};

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type ReportsListResponse = {
  total?: number;
  results?: ReportItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

export const reportsListKey = apiPath;

export function useReportsList({
  request,
  options,
}: {
  request: UseReportsListParams;
  options?: UseQueryOptions<ReportsListResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const refetchReports = useCallback(
    () => queryClient.refetchQueries(reportsListKey),
    [queryClient]
  );

  usePushNotificationEvent({
    key: "ReportLoading",
    cb: refetchReports,
  });

  usePushNotificationEvent({
    key: "ReportLoadingComplete",
    cb: refetchReports,
  });

  usePushNotificationEvent({
    key: "ReportPublicationComplete",
    cb: refetchReports,
  });

  return useQuery<ReportsListResponse>({
    ...options,
    queryKey: [reportsListKey, request],
    keepPreviousData: true,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request);
      const { data } = await axios<ReportsListResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
  });
}
