import { create } from "zustand";
import { makeEmptyUserManagementFilterFormValues } from "../components/UserManagementFilterForm/UserManagementFilterForm.hook";
import { UserManagementFilterFormValues } from "../components/UserManagementFilterForm/UserManagementFilterForm.model";

type UseUserManagementFilterState = {
  form: UserManagementFilterFormValues;
  reset: () => void;
  update: (changes: Partial<UserManagementFilterFormValues>) => void;
};

const emptyWatchQueryFilter = makeEmptyUserManagementFilterFormValues();

const stateHook = create<UseUserManagementFilterState>((set, get) => {
  return {
    form: emptyWatchQueryFilter,
    reset: () => {
      const prev = get();
      set(
        {
          ...prev,
          form: {
            ...emptyWatchQueryFilter,
          },
        },
        true
      );
    },
    update: (changes) => {
      const { form } = get();
      set({ form: { ...form, ...changes } });
    },
  };
});

export function useUserManagementFilter(): [
  UserManagementFilterFormValues,
  (changes: UserManagementFilterFormValues) => void,
  () => void
] {
  const state = stateHook();
  const { form, update, reset } = state;

  return [form, update, reset];
}
