import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/WatchList";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type Parameters = {
  query: Endpoint["parameters"]["query"];
  filters?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
};

export type WatchQueryListResponse =
  Endpoint["responses"][200]["content"]["application/json"];

export type WatchListQueries = Required<
  NonNullable<WatchQueryListResponse["results"]>
>;

export const watchQueryListKey = "watchQueryListKey";

export function useWatchQueryList({
  options,
  params,
}: {
  options?: Omit<
    UseQueryOptions<WatchQueryListResponse, unknown>,
    "queryFn" | "queryKey"
  >;
  params?: Parameters;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<WatchQueryListResponse, unknown>({
    ...options,
    queryKey: [watchQueryListKey, params],
    keepPreviousData: true,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, params);
      const { data } = await axios<WatchQueryListResponse>(url, {
        data: params?.filters,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
