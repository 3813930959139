import { ToggleButtonGroupProps } from "@mui/material";

export enum SortBy {
  titleAsc = "titleAsc",
  titleDesc = "titleDesc",
  //default newest/oldest
  defaultAsc = "defaultAsc",
  defaultDesc = "defaultDesc",
  viewershipAsc = "viewershipAsc",
  viewershipDesc = "viewershipDesc",
  disctanceAsc = "disctanceAsc",
  distanceDesc = "distanceDesc",
  marketSizeAsc = "marketSizeAsc",
  marketSizeDesc = "marketSizeDesc",
  marketTitleAsc = "marketTitleAsc",
  marketTitleDesc = "marketTitleDesc",
}

export type SortOption = {
  value: SortBy;
  field: SortByParameters;
  order: OrderByParameters;
};

type SortByValues = {
  [key: string]: SortOption;
};

export const sortByValues: SortByValues = {
  titleAsc: {
    value: SortBy.titleAsc,
    field: "title",
    order: "asc",
  },
  titleDesc: {
    value: SortBy.titleDesc,
    field: "title",
    order: "desc",
  },
  defaultAsc: {
    value: SortBy.defaultAsc,
    field: "default",
    order: "asc",
  },
  defaultDesc: {
    value: SortBy.defaultDesc,
    field: "default",
    order: "desc",
  },
  viewershipAsc: {
    value: SortBy.viewershipAsc,
    field: "viewership",
    order: "asc",
  },
  viewershipDesc: {
    value: SortBy.viewershipDesc,
    field: "viewership",
    order: "desc",
  },
  disctanceAsc: {
    value: SortBy.disctanceAsc,
    field: "geo",
    order: "asc",
  },
  distanceDesc: {
    value: SortBy.distanceDesc,
    field: "geo",
    order: "desc",
  },
  marketSizeAsc: {
    value: SortBy.marketSizeAsc,
    field: "market",
    order: "asc",
  },
  marketSizeDesc: {
    value: SortBy.marketSizeDesc,
    field: "market",
    order: "desc",
  },
  marketTitleAsc: {
    value: SortBy.marketTitleAsc,
    field: "default",
    order: "asc",
  },
  marketTitleDesc: {
    value: SortBy.marketTitleDesc,
    field: "default",
    order: "desc",
  },
};

export type SortByParameters =
  | "default"
  | "title"
  | "viewership"
  | "geo"
  | "market"
  | "date"
  | "createTime";
export type OrderByParameters = "desc" | "asc";

export type SortingMenuProps = Omit<
  ToggleButtonGroupProps,
  "children" | "onChange" | "value"
> & {
  value: SortBy;
  customSortLabel?: string;
  onChange: (sortBy: SortOption) => void;
  options?: SortBy[];
  minWidth?: string;
};
