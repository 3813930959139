import { alpha, Stack, useTheme } from "@mui/material";
import { TimeLineMentions } from "src/components/TimeLineMentions/TimeLineMentions";
import { EventDetails } from "src/models/EventDetails";

type ShiftPlayerTimeLineProps = {
  value: Date;
  highlights: EventDetails["highlights"];
  handleChange: (date: Date) => void;
  maxDateTime: Date;
  timeZone?: string;
};

export function ShiftPlayerTimeLine({
  value,
  highlights,
  handleChange,
  maxDateTime,
  timeZone,
}: ShiftPlayerTimeLineProps) {
  const { palette } = useTheme();

  return (
    <Stack height={82} pt={3.75}>
      <TimeLineMentions
        disabledColor={alpha(palette.primary.main, 0.12)}
        initialUserScale={2.4}
        value={value}
        highlights={highlights}
        onChange={handleChange}
        maxDate={maxDateTime}
        height={52}
        timeZone={timeZone}
        zoomControlPosition="outside"
        hideCursorBounds
      />
    </Stack>
  );
}
