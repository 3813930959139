import clsx from "clsx";
import { ReactNode, useEffect, useMemo } from "react";
import { Stack, StackProps, useTheme } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import { carouselBaseClasses } from "./CarouselBase.const";

export type CarouselBaseProps<T> = Omit<
  StackProps,
  "children" | "onChange" | "onScroll"
> & {
  current?: number;
  data: T[];
  disabled?: boolean;
  onChange: (value: T, index: number) => void;
  onScroll?: () => void;
  renderItem: (item: T, index: number, all: T[]) => ReactNode;
  getItemId: (item: T) => string;
};

export function CarouselBase<T>({
  current = 0,
  data,
  disabled,
  renderItem,
  getItemId,
  onChange,
  onScroll,
  className,
  ...props
}: CarouselBaseProps<T>) {
  const { transitions } = useTheme();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  useEffect(() => {
    if (typeof current === "number") {
      emblaApi?.scrollTo(current);
    }
  }, [emblaApi, current]);

  useEffect(() => {
    const handleSelect = () => {
      const nextIndex = emblaApi?.selectedScrollSnap();

      if (typeof nextIndex === "number") {
        const nextValue = data[nextIndex];

        onChange(nextValue, nextIndex);
      }
    };

    const handleScroll = () => {
      onScroll?.();
    };

    if (emblaApi) {
      emblaApi.on("select", handleSelect);
      emblaApi.on("scroll", handleScroll);
    }

    return () => {
      emblaApi?.off("select", handleSelect);
      emblaApi?.off("scroll", handleScroll);
    };
  }, [emblaApi, data, onChange, onScroll]);

  const slides = useMemo(() => {
    return data.map((item, index, all) => {
      const content = renderItem(item, index, all);
      const id = getItemId?.(item) ?? index;
      const isCurrent = current === index;

      return (
        <div
          key={id}
          className={clsx(
            carouselBaseClasses.slide,
            isCurrent
              ? carouselBaseClasses.slideCurrent
              : carouselBaseClasses.slideInactive
          )}
          children={content}
        />
      );
    });
  }, [data, current, getItemId, renderItem]);

  const slideSpacing = "16px";
  const slideSize = "calc(100% - 64px);";

  return (
    <Stack
      {...props}
      sx={{
        [`.${carouselBaseClasses.root}`]: {
          maxWidth: "100%",
          margin: "auto",
        },

        [`.${carouselBaseClasses.viewport}`]: {
          overflow: "hidden",
          height: "100%",
        },

        [`.${carouselBaseClasses.container}`]: {
          height: "100%",
          backfaceVisibility: "hidden",
          display: "flex",
          touchAction: "pan-y",
          marginLeft: `calc(${slideSpacing} * -1);`,
        },

        [`.${carouselBaseClasses.slide}`]: {
          height: "100%",
          flex: `0 0 ${slideSize}`,
          minWidth: 0,
          paddingLeft: slideSpacing,
          display: "flex",
          transition: transitions.create(["opacity", "filter"]),

          "&:first-of-type": {
            marginLeft: `calc(${slideSpacing} * 2)`,
          },

          "&:last-of-type": {
            marginRight: `calc(${slideSpacing} * 2)`,
          },
        },
        [`.${carouselBaseClasses.slideInactive}`]: {
          opacity: 0.5,
          filter: "grayscale(1) blur(2px)",
        },
        [`.${carouselBaseClasses.slideCurrent}`]: {
          opacity: 1.0,
        },
        ...props.sx,
      }}
      className={clsx(carouselBaseClasses.root, className)}
      children={
        <div
          className={carouselBaseClasses.viewport}
          ref={emblaRef}
          children={
            <div className={carouselBaseClasses.container} children={slides} />
          }
        />
      }
    />
  );
}
