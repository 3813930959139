import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

export const Auth0CallbackPage = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <Box mt={2} mr="auto" mb="auto" ml="auto">
        <Typography variant="h4">Error</Typography>
        <Typography color="error" mb={2}>
          {error.message}
        </Typography>
        <Button component={Link} to="/" variant="contained">
          Go Home
        </Button>
      </Box>
    );
  }

  return <Navigate to="/" />;
};
