import { Box, Stack } from "@mui/material";
import { TileLayoutProps } from "./TileLayout.model";
import { TableBaseNoRowsOverlay } from "../TableBase/TableBaseNoRowsOverlay";

export function TileLayout<T>({
  id,
  rows,
  renderItem,
  renderEmpty,
  getRowId,
  gap = 1,
  fillMode = "auto-fill",
  gridMaxWidth = "1528px",
  minTileWidth = 335,
}: TileLayoutProps<T>) {
  const renderItemWrapper = (data: T, index: number) => {
    const key = getRowId ? getRowId(data) : index;
    return (
      <Box key={key} display="flex">
        {renderItem && renderItem(data, index)}
      </Box>
    );
  };

  return rows.length ? (
    <Box
      id={id}
      sx={{
        gap,
        display: "grid",
        maxWidth: gridMaxWidth,
        gridTemplateColumns: `repeat(${fillMode}, minmax(${minTileWidth}px, 1fr))`,
      }}
      children={rows.map(renderItemWrapper)}
    />
  ) : (
    <Stack flex={1} justifyContent="center" alignItems="center">
      {renderEmpty ? renderEmpty() : <TableBaseNoRowsOverlay />}
    </Stack>
  );
}
