import axios from "axios";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { ReportItem } from "../models/Report";

const apiKey: keyof paths = "/api/Report/{id}";
const method: keyof paths[typeof apiKey] = "get";

type Endpoint = paths[typeof apiKey][typeof method];
type Parameters = Endpoint["parameters"];

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
type Response = ReportItem;

export function useReport(params?: Parameters) {
  return useQuery<Response, unknown>({
    queryKey: [apiKey, params],
    queryFn: async () => {
      const url = makeApiUrl(apiKey, params);
      const { data } = await axios<Response>(url, { method });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
