import { useCallback, useMemo } from "react";
import { usePowerSearchSharedForm } from "src/api/usePowerSearchSharedForm";
import {
  emptyPowerSearchCustomQueryFormValues,
  usePowerSearchCustomQueryForm,
} from "src/components/PowerSearchCustomQueryForm/PowerSearchCustomQueryForm.hook";
import { PowerSearchCustomQueryFormSchema } from "src/components/PowerSearchCustomQueryForm/PowerSearchCustomQueryForm.schema";
import {
  emptyPowerSearchKeywordFormValues,
  usePowerSearchKeywordForm,
} from "src/components/PowerSearchKeywordForm/PowerSearchKeywordForm.hook";
import { PowerSearchKeywordFormSchema } from "src/components/PowerSearchKeywordForm/PowerSearchKeywordForm.schema";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";

export enum Tabs {
  simpleMode = "simpleMode",
  advancedMode = "advancedMode",
}

export const usePowerSearchForms = () => {
  const [tabMode] = useViewModeQueryParam<Tabs>({
    paramKey: "tab",
    defaultValue: Tabs.simpleMode,
  });

  const keyWordFormHook = usePowerSearchKeywordForm({
    schema: PowerSearchKeywordFormSchema,
  });

  const customQueryFormHook = usePowerSearchCustomQueryForm({
    schema: PowerSearchCustomQueryFormSchema,
  });

  const formHook = useMemo(() => {
    return tabMode === Tabs.simpleMode ? keyWordFormHook : customQueryFormHook;
  }, [tabMode, keyWordFormHook, customQueryFormHook]);

  const { watch, formState, handleSubmit } = formHook;

  const { clearSharedValues } = usePowerSearchSharedForm();

  const resetForm = useCallback(() => {
    if (tabMode === Tabs.simpleMode) {
      keyWordFormHook.reset(emptyPowerSearchKeywordFormValues);
    } else {
      customQueryFormHook.reset(emptyPowerSearchCustomQueryFormValues);
    }
    clearSharedValues();
  }, [tabMode, keyWordFormHook, customQueryFormHook, clearSharedValues]);

  const resetCustomQueryValues = useCallback(() => {
    customQueryFormHook.resetField("customQuery", {
      defaultValue: emptyPowerSearchCustomQueryFormValues.customQuery,
    });
    customQueryFormHook.resetField("displayName", {
      defaultValue: emptyPowerSearchCustomQueryFormValues.displayName,
    });
  }, [customQueryFormHook]);

  return {
    keyWordFormHook,
    customQueryFormHook,
    formValues: watch(),
    formState,
    handleSubmit,
    resetForm,
    resetCustomQueryValues,
  };
};
