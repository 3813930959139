import { useTheme } from "@mui/material";
import { featureCollection } from "@turf/helpers";
import { GeoJSON } from "geojson";
import { cloneDeep } from "lodash";
import { GeoJSONFeature, Map, MapEventOf } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import { waitForMapBoxSourceFeatures } from "./waitForMapBoxSourceFeatures";

const sourceId = "markets";
const sourceUrl = "mapbox://moeitrope.0h6hf9fy";
const sourceLayer = "mmsboundariesbundle";
const areaId = "market-area";
const borderId = "market-border";

export type MapBoxLayerTransformFn<T extends number> = (args: {
  features: GeoJSONFeature[];
  valueByFeatureId: { [featureId: string]: T };
}) => GeoJSONFeature[];

export function useMapBoxLayerData<T extends number>({
  map,
  valueByFeatureId,
  showBorders,
  transform,
  dataLevel,
}: {
  map: Map | null;
  valueByFeatureId?: { [featureId: string]: T };
  showBorders?: boolean;
  transform?: MapBoxLayerTransformFn<T>;
  dataLevel: "Regional" | "National";
}) {
  const { palette } = useTheme();
  const [remoteSourceData, setRemoteSourceData] = useState<
    GeoJSONFeature[] | null
  >(null);

  const remoteSourceId = `remote:${sourceId}`;

  const createLayers = useCallback(() => {
    if (!map) return;

    console.log(`@@ DEBUG:useMapBoxLayerData:createLayers`);

    if (map.getLayer(borderId)) {
      console.log(`@@ DEBUG:createLayers:remove:${borderId}`);
      map.removeLayer(borderId);
    }

    // need to make a reference to the source, otherwise MapBox does not load it
    map.addLayer({
      id: borderId,
      type: "line",
      source: remoteSourceId,
      "source-layer": sourceLayer,
      filter: ["==", ["get", "ShapeLevel"], dataLevel],
      paint: {
        "line-color": palette.primary.main,
        "line-width": 1,
        "line-opacity": showBorders ? 1.0 : 0.0,
      },
    });
  }, [dataLevel, map, palette.primary.main, remoteSourceId, showBorders]);

  useEffect(() => {
    if (!map) return;

    if (map.isStyleLoaded()) {
      // re-create layers
      createLayers();
    } else {
      map.once("load", () => {
        // request source tiles from MapBox only once
        map.addSource(remoteSourceId, {
          type: "vector",
          url: sourceUrl,
        });

        // then create layers
        createLayers();
      });
    }

    return () => {
      // remove layer that uses source first
      console.log(`@@ DEBUG:useMapBoxLayerData:cleanup`);

      if (map.getLayer(borderId)) {
        console.log(`@@ DEBUG:useMapBoxLayerData:remove:${borderId}`);
        map.removeLayer(borderId);
      }

      map.off("load", createLayers);
    };
  }, [
    map,
    remoteSourceId,
    palette.primary.main,
    showBorders,
    dataLevel,
    createLayers,
  ]);

  useEffect(() => {
    if (!map) return;

    const remoteSourceReady = async (e: MapEventOf<"sourcedata">) => {
      if (e.sourceId === remoteSourceId && e.isSourceLoaded) {
        // querySourceFeatures takes unreliable amount of time, because it returns
        // only geometry available in the viewport.
        const remoteSourceFeatures = await waitForMapBoxSourceFeatures({
          map: e.target,
          sourceId: remoteSourceId,
          sourceLayer,
        });

        console.log(
          `@@ DEBUG:MapBox:remoteSourceReady`,
          remoteSourceId,
          remoteSourceFeatures
        );

        setRemoteSourceData(remoteSourceFeatures);
      }
    };

    map.on("sourcedata", remoteSourceReady);

    return () => {
      // remove layer that uses source first
      console.log(`@@ DEBUG:useMapBoxLayerData:cleanup`);

      if (map.getLayer(borderId)) {
        console.log(`@@ DEBUG:useMapBoxLayerData:remove:${borderId}`);
        map.removeLayer(borderId);
      }

      if (map.getSource(remoteSourceId)) {
        console.log(`@@ DEBUG:useMapBoxLayerData:remove:${remoteSourceId}`);
        map.removeSource(remoteSourceId);
      }

      map.off("sourcedata", remoteSourceReady);
    };
  }, [map, remoteSourceId]);

  useEffect(() => {
    if (!map) return;
    if (!remoteSourceData) return;

    // IMPORTANT: need to deep copy data to prevent default source state change
    const nextFeatures = cloneDeep(remoteSourceData);

    // need to process features on the fly, so the choropleth values can be calculated
    const transformedGeoJson =
      valueByFeatureId && transform
        ? transform({
            features: nextFeatures,
            valueByFeatureId,
          })
        : remoteSourceData;

    const sourceData: GeoJSON = featureCollection(transformedGeoJson);

    map.addSource(sourceId, {
      type: "geojson",
      data: sourceData,
    });

    // add choropleth layer
    map.addLayer(
      {
        id: areaId,
        type: "fill",
        source: sourceId,
        filter: ["==", ["get", "ShapeLevel"], dataLevel],
        paint: {
          "fill-color": palette.secondary.main,
          "fill-opacity": [
            "case",
            ["has", "hits"],
            [
              "interpolate",
              ["linear"],
              ["get", "hits"],
              // hits to opacity
              0,
              0.2,
              1,
              0.8,
            ],
            0,
          ],
        },
      },
      // insert below borders
      borderId
    );

    return () => {
      if (map.getLayer(areaId)) map.removeLayer(areaId);
      if (map.getSource(sourceId)) map.removeSource(sourceId);
    };
  }, [
    valueByFeatureId,
    dataLevel,
    map,
    palette.secondary.main,
    remoteSourceData,
    transform,
  ]);
}
