import { AppRouteBuilder } from "src/models/AppRoute";
import { WatchListTermResultsPage } from "./WatchListTermResults.page";

export type WatchListPathParams = {
  queryId: string;
};

export type WatchListQueryParams = {
  startDateTime: string;
  endDateTime: string;
} | void;

export const watchListTermResultsRoute = new AppRouteBuilder<
  WatchListPathParams,
  WatchListQueryParams
>({
  path: "/watchlist/:queryId",
  element: <WatchListTermResultsPage />,
});
