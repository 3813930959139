import { Close } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  alpha,
  drawerClasses,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDrawer } from "src/utils/useDrawer";
import { DrawerNavMenu } from "./DrawerNavMenu";
import { ProfileButton } from "./ProfileButton";
import { ThemeSwitch } from "./ThemeSwitch";
import { Logo } from "../logo/Logo";
import { AppLink } from "../AppLink/AppLink";

export const DrawerMobile = () => {
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const { isOpen, hide } = useDrawer();

  useEffect(() => {
    // close drawer when route changes
    hide();
  }, [pathname, hide]);

  return (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={hide}
      sx={{
        width: 307,
        [`& .${drawerClasses.paper}`]: {
          background: alpha(palette.background.paper, 1),
          width: "inherit",
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Box
        ml={0.75}
        mt={0}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <IconButton onClick={hide}>
          <Close fontSize="large" />
        </IconButton>
        <Box ml={6}>
          <AppLink to={"/"}>
            <Logo height={64} dark={palette.mode === "dark"} />
          </AppLink>
        </Box>
      </Box>

      <Box pl={1} pr={1} display="flex" flexGrow={1}>
        <DrawerNavMenu />
      </Box>

      <Box m={2} mb={1} display="flex" flexDirection="column">
        <ThemeSwitch />
      </Box>

      <Box m={2} mb={3}>
        <ProfileButton />
      </Box>
    </Drawer>
  );
};
