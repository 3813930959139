import axios from "axios";
import { UseQueryOptions, useInfiniteQuery, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { countPagedResultsTotal } from "src/utils/countPagedResultTotal";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";

const apiKey: keyof paths = "/api/WatchList";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = {
  query: Endpoint["parameters"]["query"];
  filters?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
};

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type WatchTermListInfiniteResponse = {
  total?: number;
  results?: WatchListUserQueryDTO[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

export const watchQueryListInfiniteKey = "watchQueryListInfiniteKey";

const pageSize = 24;

export function useWatchQueryListInfinite({
  options,
  params,
}: {
  options?: Omit<
    UseQueryOptions<WatchTermListInfiniteResponse, unknown>,
    "queryFn" | "queryKey"
  >;
  params?: Parameters;
}) {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useInfiniteQuery<WatchTermListInfiniteResponse, unknown>({
    queryKey: [watchQueryListInfiniteKey],
    keepPreviousData: true,
    initialData: () => {
      const cacheWatchListResults =
        queryClient.getQueryData<WatchTermListInfiniteResponse>(
          [
            apiKey,
            {
              // we want cached data only from start regardless of the page size
              query: { from: 0 },
            },
          ],
          {
            // ignore page size
            exact: false,
          }
        );

      const { results = [], total = 0 } = cacheWatchListResults || {};
      const pages: WatchTermListInfiniteResponse[] = [];

      while (true) {
        const from = pages.length * pageSize;
        const to = from + pageSize;
        const pageData = (results || []).slice(from, to);

        if (pageData.length < pageSize) {
          // no more data to fill next page
          break;
        }

        pages.push({
          total,
          results: pageData,
          configuration: {
            from,
            size: pageSize,
          },
        });
      }

      return {
        pages: pages,
        pageParams: pages.map(({ configuration }) => configuration),
      };
    },
    queryFn: async ({ pageParam }) => {
      const from = pageParam?.from || 0;
      const size = pageParam?.size || pageSize;

      const token = await getAccessTokenSilently();
      const apiPath = makeApiUrl(apiKey, {
        query: {
          ...params?.query,
          from,
          size,
        },
      });

      const { data } = await axios<WatchTermListInfiniteResponse>(apiPath, {
        data: params?.filters,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    getNextPageParam: (lastGroup = {}, allPages) => {
      const loadedCount = countPagedResultsTotal(allPages);
      const { total, configuration } = lastGroup;
      if (total === loadedCount) return;

      if (configuration && configuration.from !== null) {
        const { from = 0 } = configuration;
        return {
          from: from + pageSize,
          size: pageSize,
        };
      }

      return {
        from: 0,
        size: pageSize,
      };
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
