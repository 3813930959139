import { Dispatch, SetStateAction, useMemo } from "react";
import { UseInfiniteQueryResult } from "react-query";
import {
  Stack,
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  Typography,
  useTheme,
  useMediaQuery,
  badgeClasses,
  alpha,
  Badge,
} from "@mui/material";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionFromPreselected } from "src/api/useEventSelectionFromPreselected";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { PreselectedEvents } from "./PreselectedEvents";
import { LoadingButton } from "../buttons/LoadingButton";
import {
  OrderByParameters,
  SortBy,
  SortByParameters,
} from "../SortingMenu/SortingMenu.model";

type ReportAddEventsDialogProps = Omit<DialogProps, "onSubmit" | "onClose"> & {
  onClose: () => void;
  addEvents: (events: PowerSearchResultItem[]) => void;
  events: PowerSearchResultItem[];
  rowCount: number;
  isEditFlow?: boolean;
  isLoading: boolean;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  sortOption: SortBy;
  setSortOption: Dispatch<SetStateAction<SortBy>>;
  setSortBy: Dispatch<SetStateAction<SortByParameters>>;
  setOrderBy: Dispatch<SetStateAction<OrderByParameters>>;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  reportEventsTiles: UseInfiniteQueryResult;
};

export const ReportAddEventsDialog = ({
  onClose,
  addEvents,
  events,
  rowCount,
  isEditFlow,
  isLoading,
  paginationModel,
  onPaginationModelChange,
  sortOption,
  setSortOption,
  setSortBy,
  setOrderBy,
  dateRange,
  setDateRange,
  reportEventsTiles,
  ...props
}: ReportAddEventsDialogProps) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const selectionState = useEventSelectionFromPreselected();

  const actionButtons = useMemo(
    () => (
      <DialogActions sx={{ py: isMobile ? 2 : 0, px: isMobile ? 1 : 0 }}>
        <Stack
          direction="row"
          justifyContent={isMobile ? "space-between" : "flex-end"}
          gap={isMobile ? 1 : 2}
          width={isMobile ? "100%" : "unset"}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              width: isMobile ? "unset" : 155,
              flex: isMobile ? 1 : "unset",
              height: 36,
            }}
            onClick={() => {
              selectionState.unselectAll();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Badge
            badgeContent={`${selectionState.list.length ?? 0}`}
            color="info"
            sx={{
              minWidth: isMobile ? "calc(50% - 4px)" : "unset",
              [`.${badgeClasses.badge}`]: {
                fontSize: 12,
                top: 18,
                right: 24,
                backgroundColor: !!selectionState.list.length
                  ? palette.info
                  : alpha(palette.text.secondary, 0.18),
              },
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: isMobile ? "100%" : 155,
                height: 36,
                pl: 2,
                pr: 4,
              }}
              disabled={!selectionState.list.length}
              onClick={() => {
                addEvents(selectionState.list);
                selectionState.unselectAll();
                onClose();
              }}
            >
              Add events
            </LoadingButton>
          </Badge>
        </Stack>
      </DialogActions>
    ),
    [selectionState, onClose, isMobile, addEvents, palette]
  );

  const dialogHeader = useMemo(() => {
    return (
      <Stack
        direction="row"
        sx={{
          mt: isMobile ? 2 : 3,
          mx: isMobile ? 1 : 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Typography
            variant={isMobile ? "h6" : "subtitle1"}
            sx={{ mb: isMobile ? 0.5 : 1 }}
          >
            Preselected events
          </Typography>
          <Typography variant="body2">
            You can {isEditFlow ? "copy" : "add"} event(s) to your Report from
            Preselected events page
          </Typography>
        </Stack>
        {!isMobile && actionButtons}
      </Stack>
    );
  }, [actionButtons, isMobile, isEditFlow]);

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => {
        selectionState.unselectAll();
        onClose();
      }}
      PaperProps={{
        sx: {
          width: 864,
          height: isMobile ? 630 : "80%",
          mx: isMobile ? 1 : 2,
        },
      }}
      {...props}
    >
      {dialogHeader}
      <PreselectedEvents
        isLoading={isLoading}
        events={events}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortOption={sortOption}
        setSortOption={setSortOption}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        reportEventsTiles={reportEventsTiles}
      />
      {isMobile && actionButtons}
    </Dialog>
  );
};
