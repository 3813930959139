import { isToday, isWeekend } from "date-fns";

export function makeDateCaption(date: Date) {
  if (isToday(date)) {
    return "Today";
  }

  if (isWeekend(date)) {
    return "Weekend";
  }

  return "";
}
