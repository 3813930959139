import { AppRouteBuilder } from "src/models/AppRoute";
import { ReportsPage } from "./Reports.page";

export type ReportRouteQueryParams = {
  pageTab?: string;
};

export const reportsRoute = new AppRouteBuilder<void, ReportRouteQueryParams>({
  path: "/reports",
  element: <ReportsPage />,
  hideAppBar: true,
});
