import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Snackbar,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGridPremiumProps } from "@mui/x-data-grid-premium";
import { BooleanParam, useQueryParam } from "use-query-params";
import { UseInfiniteQueryResult } from "react-query";
import { ReportItem } from "src/models/Report";
import { ViewModeSwitch } from "src/components/ViewModeSwitch/ViewModeSwitch";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { SearchInput } from "src/components/SearchInput";
import { DateTimeRangePickerMobile } from "src/components/DateTimeRangePickerMobile/DateTimeRangePickerMobile";
import { DateRange } from "src/components/DateSelector/DateSelector";
import { SortingMenu } from "src/components/SortingMenu/SortingMenu";
import {
  SortBy,
  SortOption,
} from "src/components/SortingMenu/SortingMenu.model";
import {
  DateTimeSelector,
  DateTimeSelectorMode,
} from "src/components/DateSelector/DateTimeSelector";
import { DeleteIconButton } from "src/components/DeleteIconButton/DeleteIconButton";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { SnackbarBase } from "src/components/SnackbarBase/SnackbarBase";
import { ReportsNoSavedReportsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";
import { useOpenState } from "src/utils/useOpenState";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { useListFilter } from "src/utils/useListFilter";
import { useReportsSelection } from "src/api/useReportsSelection";
import { useCopyReportLinkSnackbar } from "src/api/useCopyReportLinkSnackbar";
import { useReportDeleteSnackbar } from "src/api/useReportDeleteSnackbar";
import { useReportsDataView } from "./Reports.hook";
import { ReportDeleteDialog } from "./ReportItemActions/ReportDeleteDialog";

const viewModeOptions: {
  desktop: ViewMode[];
  mobile: ViewMode[];
} = {
  desktop: [ViewMode.table, ViewMode.list, ViewMode.tile],
  mobile: [ViewMode.tile, ViewMode.table],
};

export function ReportsTabContent({
  isLoading,
  reports,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  onSortChange,
  sortOption,
  dateRange,
  setDateRange,
  reportsListTiles,
}: {
  isLoading: boolean;
  reports: ReportItem[];
  rowCount: number;
  paginationModel: DataGridPremiumProps["paginationModel"];
  onPaginationModelChange: DataGridPremiumProps["onPaginationModelChange"];
  onSortChange: (sortBy: SortOption) => void;
  sortOption: SortBy;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  reportsListTiles: UseInfiniteQueryResult;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { t } = useTranslation();

  const initialViewMode: ViewMode = isMobile ? ViewMode.tile : ViewMode.list;
  const [viewMode, setViewModeWrapper] = useViewModeQueryParam<ViewMode>({
    paramKey: "tab",
    defaultValue: initialViewMode,
  });
  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);
  const [filterText, setFilterText] = useState("");
  const deleteReportDialog = useOpenState();
  const copyReportLinkSnackBar = useCopyReportLinkSnackbar();
  const reportsSelection = useReportsSelection();
  const reportDeleteSnackbar = useReportDeleteSnackbar();

  const filterData = useListFilter(reports, filterText, [
    "title",
    "description",
  ]);

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: reportsSelection.list,
  });

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && !isPartiallySelected) {
        const readyReports = filterData.filter(
          (report) => report.status === "ready"
        );
        reportsSelection.selectAll(readyReports);
      } else {
        reportsSelection.unselectAll();
      }
    },
    [filterData, reportsSelection, isPartiallySelected]
  );

  const viewModeSwitch = useMemo(
    () => (
      <ViewModeSwitch
        value={viewMode}
        onChange={setViewModeWrapper}
        options={isMobile ? viewModeOptions.mobile : viewModeOptions.desktop}
        size={isMobile ? "small" : "medium"}
      />
    ),
    [isMobile, setViewModeWrapper, viewMode]
  );

  const allCheckboxSelector = useMemo(
    () =>
      [ViewMode.tile, ViewMode.list].includes(viewMode) && (
        <AllCheckboxSelector
          checked={isAllSelected || isPartiallySelected}
          isPartiallySelected={isPartiallySelected}
          onChange={handleChangeAllCheckbox}
          sx={{ ml: viewMode === ViewMode.tile ? "12px" : "28px" }}
        />
      ),
    [handleChangeAllCheckbox, isAllSelected, isPartiallySelected, viewMode]
  );

  const deleteIconButton = useMemo(
    () => (
      <DeleteIconButton
        onClick={deleteReportDialog.show}
        size={isMobile ? "small" : "medium"}
        disabled={!reportsSelection.list.length}
      />
    ),
    [deleteReportDialog.show, reportsSelection.list.length, isMobile]
  );

  const sortingMenu = useMemo(
    () => (
      <SortingMenu
        value={sortOption}
        onChange={onSortChange}
        options={[
          SortBy.titleAsc,
          SortBy.titleDesc,
          SortBy.defaultAsc,
          SortBy.defaultDesc,
        ]}
      />
    ),
    [onSortChange, sortOption]
  );

  const searchInput = useMemo(
    () => (
      <SearchInput
        placeholder={isMobile ? "Search..." : "Search by text..."}
        onTextChangeThrottled={setFilterText}
      />
    ),
    [isMobile]
  );

  const toolbarDesktop = useMemo(
    () => (
      <Stack
        p={2}
        gap={2}
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Stack direction="row" flex={1}>
          {allCheckboxSelector}
          <Stack
            sx={{
              justifyContent: "flex-end",
              mr: 2,
            }}
          >
            {searchInput}
          </Stack>
          <DateTimeSelector
            mode={DateTimeSelectorMode.variable}
            views={["year", "month", "day", "hours", "minutes"]}
            value={dateRange}
            onChange={setDateRange}
            clearable
            sx={{ flex: 1 }}
          />
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {deleteIconButton}
          {sortingMenu}
          {viewModeSwitch}
        </Stack>
      </Stack>
    ),
    [
      allCheckboxSelector,
      dateRange,
      deleteIconButton,
      searchInput,
      setDateRange,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const toolbarMobile = useMemo(
    () => (
      <Stack>
        <Stack sx={{ mx: 2, mt: 2 }}>{searchInput}</Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-end"
          justifyContent="space-between"
          sx={{ m: 2 }}
        >
          <Box>{allCheckboxSelector}</Box>
          <Stack flexDirection="row" gap={1}>
            {deleteIconButton}
            <DateTimeRangePickerMobile
              value={dateRange}
              onChange={setDateRange}
              iconButtonSize="small"
            />
            {sortingMenu}
            {viewModeSwitch}
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      allCheckboxSelector,
      dateRange,
      deleteIconButton,
      searchInput,
      setDateRange,
      sortingMenu,
      viewModeSwitch,
    ]
  );

  const renderHeader = useCallback(() => {
    return isMobile ? toolbarMobile : toolbarDesktop;
  }, [isMobile, toolbarMobile, toolbarDesktop]);

  const dataViewElement = useReportsDataView({
    loading: isLoading,
    filterData,
    viewMode,
    isMobile,
    isExpandedTextMode,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    renderHeader,
    reportsListTiles,
  });

  if (!isLoading && !rowCount) {
    return <ReportsNoSavedReportsOverlay />;
  }

  return (
    <Fragment>
      {dataViewElement}
      <ReportDeleteDialog
        open={deleteReportDialog.isOpen}
        values={reportsSelection.list}
        onClose={deleteReportDialog.hide}
      />
      <Snackbar
        open={copyReportLinkSnackBar.isOpen}
        autoHideDuration={5000}
        onClose={copyReportLinkSnackBar.hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={copyReportLinkSnackBar.hide}
          sx={{ borderRadius: "4px" }}
        >
          The link to the Report was successfully copied to the clipboard
        </Alert>
      </Snackbar>
      <SnackbarBase
        open={reportDeleteSnackbar.isOpen}
        onClose={reportDeleteSnackbar.hide}
        subtitle={t("reportDeleteMessage", {
          reportTitle: reportDeleteSnackbar.reportTitle,
        })}
        sxProps={{
          bottom: 122,
        }}
      />
    </Fragment>
  );
}
