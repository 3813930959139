import { DateTimeParam, UrlUpdateType, useQueryParams } from "use-query-params";
import { useDebouncedCallback } from "use-debounce";
import { DateRange } from "src/components/DateSelector/DateSelector";

type UseQueryDateRangeResult = [
  [Date | null, Date | null],
  (dateRange: DateRange) => void
];

type UseQueryDateRangeArgs = {
  updateType?: UrlUpdateType;
};

export function useQueryDateRangeWithEmptyDefault(
  args?: UseQueryDateRangeArgs
): UseQueryDateRangeResult {
  const { updateType = "replaceIn" } = args || {};

  const [{ startDate, endDate }, setQueryParams] = useQueryParams({
    startDate: DateTimeParam,
    endDate: DateTimeParam,
  });

  const nextDateRange: [Date | null, Date | null] = [
    startDate || null,
    endDate || null,
  ];

  const setDateRange = useDebouncedCallback((dateRange: DateRange) => {
    setQueryParams(
      { startDate: dateRange[0], endDate: dateRange[1] },
      updateType
    );
  }, 300);

  return [nextDateRange, setDateRange];
}
