import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";
import { mediaCenterFetchKey } from "./useMediaCenter";
import { MediaCenterInfiniteFetchKey } from "./useMediaCenterInfinite";
import { MediaCenterTagsListkey } from "./useMediaCenterTagList";

const apiKey: keyof paths = "/api/mediacenter/{userid}/items/{itemid}/tags";
const method: keyof paths[typeof apiKey] = "delete";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  path: {
    itemid: Endpoint["parameters"]["path"]["itemid"];
  };
  body: RequestBody;
};

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

export function useMediaCenterTagsDelete({
  options,
}: {
  options?: RequestOptions;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ path, body }) => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl(apiKey, {
        path: {
          userid: userId,
          itemid: path.itemid,
        },
      });

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      queryClient.refetchQueries({
        queryKey: mediaCenterFetchKey,
      });
      queryClient.refetchQueries({
        queryKey: MediaCenterInfiniteFetchKey,
      });
      queryClient.refetchQueries({
        queryKey: MediaCenterTagsListkey,
      });
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
