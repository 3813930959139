import axios from "axios";
import { add } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useAuth0 } from "@auth0/auth0-react";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { DateTimeSearchFormValues } from "src/pages/DateTimeSearchRoot/hooks/useDateTimeSearchForm";

const apiPath: keyof paths = "/api/DateTimeSearch";
const method: keyof paths[typeof apiPath] = "get";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;

type RequestParams = {
  params: {
    fetchParams: DateTimeSearchFormValues | null;
    timeZone?: string;
    cursorRangeBounds: [number, number];
  };
  options?: Pick<
    UseQueryOptions<DateTimeSearchResult, unknown>,
    "onSuccess" | "onError" | "enabled"
  >;
};

type Response = Endpoint["responses"][200]["content"]["application/json"];

type DateTimeSearchResult = Response;

export type DateTimeSearchResultItem = DateTimeSearchResult[number];

export const dateTimeQueryKey = apiPath;

export function useDateTimeSearch({ params, options }: RequestParams) {
  const { getAccessTokenSilently } = useAuth0();
  const { cursorRangeBounds, fetchParams, timeZone } = params;

  const dateTimeSearchRange: [Date, Date] | null =
    fetchParams?.dateTime && timeZone
      ? [
          add(utcToZonedTime(fetchParams.dateTime, timeZone), {
            minutes: cursorRangeBounds[0],
          }),
          add(utcToZonedTime(fetchParams.dateTime, timeZone), {
            minutes: cursorRangeBounds[1],
          }),
        ]
      : null;

  const t0 = dateTimeSearchRange?.[0]
    ? formatDateTimeWithoutTimeZone(dateTimeSearchRange[0])
    : undefined;

  const t1 = dateTimeSearchRange?.[1]
    ? formatDateTimeWithoutTimeZone(dateTimeSearchRange[1])
    : undefined;

  const query: Endpoint["parameters"]["query"] = {
    stationUUID: params.fetchParams?.station?.value,
    startDateTime: t0,
    endDateTime: t1,
  };

  const isNotUndefined = (v: unknown) => v !== undefined;

  return useQuery<DateTimeSearchResult, unknown>({
    queryKey: [dateTimeQueryKey, params],
    keepPreviousData: true,
    enabled: options?.enabled ?? Object.values(query).every(isNotUndefined),
    ...options,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, { query });

      const response = await axios<DateTimeSearchResult>(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onError(err) {
      options?.onError?.(err);

      enqueueSnackbar({
        message: `Error fetching Date Time search results: ${err}`,
        variant: "error",
      });
    },
  });
}
