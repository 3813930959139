import { create } from "zustand";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { EventSourceOption } from "src/api/useEventSources";
import { Advertisement } from "src/models/Advertisement";
import { WatchTerm } from "src/components/WatchTermInput/WatchTermInput.model";
import { TagPickerValue } from "src/components/TagPicker/TagPicker.model";
import { ProgramTagPickerValue } from "src/components/ProgramTagPicker/ProgramPicker.model";

export type PowerSearchQueryValues = {
  customQuery?: string;

  termsInclude: WatchTerm;
  termsExclude: WatchTerm;

  displayName: string;

  sourcesInclude: TagPickerValue<EventSourceOption>;
  sourcesExclude: TagPickerValue<EventSourceOption>;

  advertisement: Advertisement;

  programInclude: ProgramTagPickerValue;
  programExclude: ProgramTagPickerValue;
};

type PowerSearchQueryState = {
  data: PowerSearchQueryValues;
  save: (data: PowerSearchQueryValues) => void;
};

export const usePowerSearchQuery = create<PowerSearchQueryState>(
  (setState) => ({
    data: {
      customQuery: "",

      displayName: "",

      // terms
      termsInclude: {
        list: [],
        logic: "or",
      },
      termsExclude: {
        list: [],
        logic: "or",
      },
      // terms

      // sources
      sourcesInclude: {
        list: [],
        logic: "or",
      },
      sourcesExclude: {
        list: [],
        logic: "or",
      },
      // sources

      // program
      programInclude: {
        list: [],
        logic: "or",
      },
      programExclude: {
        list: [],
        logic: "or",
      },
      // program

      advertisement: queryAdvertisementValues.all,
    },
    save: (data) => {
      setState({ data });
    },
  })
);
