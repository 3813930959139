import { KeyboardReturn, Search } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";

export function WatchTermCreatePrompt({
  userInput: text,
}: {
  userInput: string;
}) {
  const { palette } = useTheme();
  const color = palette.text.disabled;

  return (
    <Stack
      px={2}
      py={2}
      direction="row"
      columnGap={1}
      rowGap={2}
      alignItems="center"
      borderBottom={`1px solid ${palette.divider}`}
      flexWrap="wrap"
    >
      <Stack direction="row" alignItems="center" columnGap={1}>
        <Search sx={{ color: palette.text.primary }} />
        <Typography children={text} />
      </Stack>

      <Stack direction="row" alignItems="center" columnGap={1} flex={1}>
        <Typography
          flex={1}
          fontStyle="italic"
          color={color}
          children={
            text
              ? 'Press "Enter" to search this keyword'
              : "Type a search keyword"
          }
          noWrap
        />
        <Typography
          children="Enter"
          fontSize={13}
          fontFamily="monospace"
          fontWeight={600}
          sx={{
            px: 0.5,
            border: `1px solid ${color}`,
            color,
          }}
        />
        <KeyboardReturn fontSize="small" sx={{ color }} />
      </Stack>
    </Stack>
  );
}
