import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { UseMutationOptions, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "./useCurrentUser";

const apiKey = "/dbconnections/change_password";
const method = "post";
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const url = `https://${domain}${apiKey}`;

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown>,
  "onSuccess" | "onError"
>;

export function useResetPassword({ options }: { options?: RequestOptions }) {
  const { getAccessTokenSilently } = useAuth0();
  const { data: currentUser } = useCurrentUser();

  const { enqueueSnackbar } = useSnackbar();
  return useMutation<Response, unknown, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      const body = {
        client_id: clientId,
        email: currentUser?.email,
        connection: "Username-Password-Authentication",
      };
      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      return data;
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
      options?.onError && options.onError(error, variables, context);
    },
  });
}
