import { Box, DrawerProps, Stack, Typography, useTheme } from "@mui/material";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { SearchInput } from "src/components/SearchInput";
import { BottomSideDrawer } from "src/components/BottomSideDrawer/BottomSideDrawer";
import { useListFilter } from "src/utils/useListFilter";
import { useListSelectInfo } from "src/utils/useListSelectInfo";
import { AllCheckboxSelector } from "src/components/AllCheckboxSelector/AllCheckboxSelector";
import { DuplicatesTiles } from "./DuplicatesTiles/DuplicatesTiles";

type DuplicatesDrawerMobileProps = Omit<
  DrawerProps,
  "anchor" | "hideBackdrop" | "onClose" | "title"
> & {
  onClose: () => void;
  onOpen: () => void;
  duplicatesData: PowerSearchResultItem[];
  loading: boolean;
  addItemToReport: (value: PowerSearchResultItem) => void;
};

const emptyList: PowerSearchResultItem[] = [];

export function DuplicatesDrawerMobile({
  duplicatesData,
  loading,
  addItemToReport,
  ...props
}: DuplicatesDrawerMobileProps) {
  const { zIndex } = useTheme();

  const { queryId = "" } = useParams();
  const [isExpandedTextMode] = useQueryParam("expanded", BooleanParam);

  const { list, selectEvents, unselectEvents } =
    useEventSelectionDuplicatesDrawer();

  const [filterText, setFilterText] = useState("");
  const filterData = useListFilter(duplicatesData || emptyList, filterText, [
    "title",
    "highlights",
  ]);

  const filterDataIds = filterData.map((ev) => ev.id);
  const selectedInCurrentDrawer = list.filter((event) =>
    filterDataIds.includes(event.id)
  );

  const { isAllSelected, isPartiallySelected } = useListSelectInfo({
    list: filterData,
    selectedList: selectedInCurrentDrawer,
  });

  const handleChangeAllCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectEvents(filterData);
      } else {
        unselectEvents(filterData);
      }
    },
    [filterData, selectEvents, unselectEvents]
  );

  const renderAllCheckboxSelector = useCallback(() => {
    return (
      <AllCheckboxSelector
        checked={isAllSelected || isPartiallySelected}
        isPartiallySelected={isPartiallySelected}
        onChange={handleChangeAllCheckbox}
        sx={{ ml: "28px" }}
      />
    );
  }, [handleChangeAllCheckbox, isAllSelected, isPartiallySelected]);

  const toolbar = useMemo(
    () => (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        px={2}
        pb={1.5}
      >
        <Stack direction="row" alignItems="center" width="100%">
          {renderAllCheckboxSelector()}
          <Box>All</Box>
          <SearchInput
            placeholder="Search by text..."
            onTextChangeThrottled={setFilterText}
            fullWidth
            sx={{ ml: 5 }}
          />
        </Stack>
      </Stack>
    ),
    [renderAllCheckboxSelector, setFilterText]
  );

  const content = useMemo(
    () => (
      <Stack height="100%">
        <Typography variant="subtitle1" px={2} lineHeight="40px">
          Duplicates
        </Typography>
        {toolbar}
        <DuplicatesTiles
          id="duplicates-tiles"
          queryId={queryId}
          isExpandedTextMode={!!isExpandedTextMode}
          addItemToReport={addItemToReport}
          rows={filterData}
          isLoading={loading}
        />
      </Stack>
    ),
    [toolbar, queryId, isExpandedTextMode, addItemToReport, filterData, loading]
  );

  return (
    <BottomSideDrawer
      {...props}
      height="90%"
      children={content}
      zIndexDrawer={zIndex.tooltip + 1}
    />
  );
}
