import { useEffect } from "react";

// need to remove clip overflow from the root element
// need to set body height to auto
export enum renderPDFClassNames {
  root = "renderPDF",
}

function applyPDFModeClass(isPDFMode?: boolean) {
  const cn = renderPDFClassNames.root;
  const rootEl = document.getElementById("root");

  if (rootEl) {
    rootEl.classList[isPDFMode ? "add" : "remove"](cn);
    document.body.classList[isPDFMode ? "add" : "remove"](cn);
    document.documentElement.classList[isPDFMode ? "add" : "remove"](cn);
  }
}

/**
 * Manages global styles for the page to be printed as PDF document
 */
export const useRenderPDFMode = () => {
  useEffect(() => {
    applyPDFModeClass(true);

    return () => {
      // clear global PDF mode when unmounting
      applyPDFModeClass(false);
    };
  }, []);
};
