import TuneIcon from "@mui/icons-material/Tune";
import { Badge, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { countAccountsManagementFilters } from "src/utils/countAccountsManagementFilters";
import { AccountsManagementFilterButtonProps } from "./AccountsManagementFilterButton.model";

export function AccountManagementFilterButton({
  value,
  isMobile,
  onClick,
  ...props
}: AccountsManagementFilterButtonProps) {
  const title = props.title || "Apply filters";
  const badgeContent = countAccountsManagementFilters(value);

  return (
    <Tooltip title={title}>
      <Badge color="primary" badgeContent={badgeContent}>
        <ToggleButtonGroup size={isMobile ? "small" : "medium"}>
          <ToggleButton value={true} onClick={onClick}>
            <TuneIcon sx={{ color: "text.primary" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Badge>
    </Tooltip>
  );
}
