import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Stack, StackProps } from "@mui/material";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import {
  CarouselBase,
  CarouselBaseProps,
} from "src/components/CarouselBase/CarouselBase";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { UseTrimRangeValue } from "src/components/ClipEditorPage/hook/useTrimRangeState";
import { ShiftPlayerDataProps } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";
import { EventDetails } from "src/models/EventDetails";
import { ShiftPlayerEventSlide } from "./ShiftPlayerEventSlide";
import { makeClosestEventForExistingByTime } from "./ShiftPlayerEventsCarousel.utils";

export type ShiftPlayerEventsCarouselProps = Omit<
  StackProps,
  "children" | "onChange"
> & {
  id?: string;
  data: DateTimeSearchResultItem[];
  event: EventDetails;
  mode: "view" | "edit";
  loading?: boolean;
  current?: number;
  onChange: CarouselBaseProps<DateTimeSearchResultItem>["onChange"];
  onShiftPlayerPlay?: (data: ShiftPlayerDataProps) => void;
  bounds?: [Date, Date] | null;
  minSpanSec: number;
  trimRange?: UseTrimRangeValue;
  setTrimRange?: Dispatch<SetStateAction<UseTrimRangeValue>>;
  offset: number;
};

export function ShiftPlayerEventsCarousel({
  id,
  data,
  event,
  mode,
  loading,
  current,
  onChange,
  onShiftPlayerPlay,
  bounds,
  minSpanSec,
  trimRange,
  setTrimRange,
  offset,
  ...props
}: ShiftPlayerEventsCarouselProps) {
  const [nearestEventFound, setNearestEventFound] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!data.length || nearestEventFound || !data.length) return;

    const minDiffEventData = makeClosestEventForExistingByTime(data, event);
    setNearestEventFound(true);
    setActiveIndex(minDiffEventData.idx);
  }, [data, event, nearestEventFound]);

  const renderItemWrapper: CarouselBaseProps<DateTimeSearchResultItem>["renderItem"] =
    (item) => {
      return (
        <ShiftPlayerEventSlide
          onShiftPlayerPlay={onShiftPlayerPlay}
          value={item}
          event={event}
          mode={mode}
          bounds={bounds}
          minSpanSec={minSpanSec}
          trimRange={trimRange}
          setTrimRange={setTrimRange}
          offset={offset}
        />
      );
    };

  const onChangeWrapper: CarouselBaseProps<DateTimeSearchResultItem>["onChange"] =
    (item) => {
      const selectedIndex = data.findIndex((el) => el.id === item.id);
      setActiveIndex(selectedIndex);
    };

  const content = loading ? (
    <LoadingOverlay />
  ) : (
    <CarouselBase
      flex={1}
      height="100%"
      data={data}
      getItemId={(item) => `${item.id}`}
      current={activeIndex ?? 0}
      onChange={onChangeWrapper}
      renderItem={renderItemWrapper}
    />
  );

  return (
    <Stack
      id={id}
      overflow="hidden"
      height="100%"
      children={content}
      {...props}
    />
  );
}
