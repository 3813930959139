import {
  alpha,
  Box,
  Chip,
  Divider,
  ListItem,
  ListItemText,
  Stack,
  svgIconClasses,
  Typography,
  useTheme,
} from "@mui/material";
import { WatchListQueries } from "src/api/useWatchQueryList";
import { useAppChartColorMapper } from "src/components/charts/utils/useAppChartColorMapper";
import {
  AppAutocomplete,
  AppAutocompleteProps,
} from "../AppAutocomplete/AppAutocomplete";
import { Fragment } from "react/jsx-runtime";

type WatchQuery = WatchListQueries[number];

type WatchQueryPickerProps = Omit<
  AppAutocompleteProps<WatchQuery, true>,
  "renderOption" | "renderTags" | "getOptionLabel" | "getOptionKey"
> & {
  // FIXME: move this to AppAutocomplete as standard prop??
  maxActiveQueries?: number;
};

export function WatchQueryPicker({
  maxActiveQueries = 6,
  onChange,
  ...props
}: WatchQueryPickerProps) {
  const { palette } = useTheme();
  const { getColorForId } = useAppChartColorMapper();

  const onChangeWrapper: WatchQueryPickerProps["onChange"] = (value) => {
    if (value.length > maxActiveQueries) {
      return;
    }

    onChange?.(value);
  };

  return (
    <AppAutocomplete<WatchQuery, true>
      {...props}
      onChange={onChangeWrapper}
      getOptionLabel={(o) => o.title}
      getOptionKey={(o) => o.id}
      fullWidth
      multiple
      renderOption={(listItemProps, option, state) => {
        const bgColor: string | undefined = state.selected
          ? getColorForId(option.id).bg
          : undefined;

        return (
          <ListItem {...listItemProps}>
            {state.selected ? (
              <Box height={24} width={24} bgcolor={bgColor} mr={2} />
            ) : null}
            <ListItemText children={option.title} />
          </ListItem>
        );
      }}
      renderTags={(value, getTagProps, _ownerState) => {
        const tagElements = value.map((option, index) => {
          const label = option.title;
          const { bg, fg, icon } = getColorForId(option.id);
          const { key, ...tagProps } = getTagProps({ index });

          return (
            <Chip
              label={label}
              {...tagProps}
              key={key}
              size="small"
              sx={{
                backgroundColor: bg,
                "&:hover": {
                  backgroundColor: alpha(bg, 0.75),
                },
                color: fg,
                [`.${svgIconClasses.root}`]: {
                  color: icon,
                },
              }}
            />
          );
        });

        return tagElements;
      }}
      renderDropDownHeader={() => {
        if (!props.value || props.value.length < maxActiveQueries) {
          return <Fragment />;
        }

        return (
          <Stack justifyContent="center">
            <Typography
              px={2}
              py={1}
              variant="subtitle2"
              color={palette.error.main}
              children={`Max ${maxActiveQueries} queries can be selected at the same time`}
            />
            <Divider />
          </Stack>
        );
      }}
    />
  );
}
