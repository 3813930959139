import { isValid } from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import { useMemo } from "react";
import { DateTimeParam, UrlUpdateType, useQueryParams } from "use-query-params";
import { makeDateRange } from "./makeDateRange";

type UseQueryDateTimeRangeResult = [
  [Date, Date],
  (dateRange: [Date | null, Date | null]) => void
];

const defaultQueryDays = 7;

type UseQueryDateTimeRangeArgs = {
  updateType?: UrlUpdateType;
};

export function useQueryDateTimeRangeWatchlist(
  args?: UseQueryDateTimeRangeArgs
): UseQueryDateTimeRangeResult {
  const { updateType = "replaceIn" } = args || {};
  const today = useMemo(() => new Date(), []);

  const [defaultStartDate, defaultEndDate] = useMemo(
    () => makeDateRange(today, -defaultQueryDays),
    [today]
  );

  const [{ startDateTime, endDateTime }, setQueryParams] = useQueryParams({
    startDateTime: DateTimeParam,
    endDateTime: DateTimeParam,
  });

  const nextDateRange: [Date, Date] = useMemo(
    () => [startDateTime || defaultStartDate, endDateTime || defaultEndDate],
    [defaultEndDate, defaultStartDate, endDateTime, startDateTime]
  );

  const setDateRange = useDebouncedCallback(
    (dateRange: [Date | null, Date | null]) => {
      if (!isValid(dateRange[0]) || !isValid(dateRange[1])) {
        return;
      }

      setQueryParams(
        { startDateTime: dateRange[0], endDateTime: dateRange[1] },
        updateType
      );
    },
    300
  );

  return [nextDateRange, setDateRange];
}
