import { Button, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useIsMobile } from "src/utils/useIsMobile";
import { useWatchListClusterEditMode } from "./hooks/useWatchListClusterEditMode";
import { WatchListClusterAssignDialog } from "../WatchListClusterAssignDialog/WatchListClusterAssignDialog";

export function WatchListClusterEditToolbar() {
  const clusterEditMode = useWatchListClusterEditMode();
  const isMobile = useIsMobile();

  const clusterAssignDialog = (
    <WatchListClusterAssignDialog
      open={clusterEditMode.isAssignDialogShown}
      queryIds={clusterEditMode.queryList.map(String)}
    />
  );

  if (isMobile) {
    return (
      <Stack direction="row" alignItems="center" columnGap={1} minHeight={40}>
        <Button
          children="Done"
          variant="outlined"
          sx={{ minWidth: 85 }}
          onClick={clusterEditMode.deactivate}
        />
        <Button
          children="Assign to Cluster"
          variant="contained"
          color="secondary"
          sx={{ flex: 1 }}
          onClick={clusterEditMode.assignDialogShow}
          disabled={!clusterEditMode.queryList.length}
        />
        {clusterAssignDialog}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      columnGap={2}
      minHeight={48}
    >
      <Button
        children="Done"
        variant="outlined"
        sx={{ minWidth: 100, minHeight: 42 }}
        onClick={clusterEditMode.deactivate}
      />
      <Button
        children="Assign to Cluster"
        variant="contained"
        color="secondary"
        startIcon={<Add />}
        sx={{ minWidth: 182, minHeight: 42 }}
        onClick={clusterEditMode.assignDialogShow}
        disabled={!clusterEditMode.queryList.length}
      />
      {clusterAssignDialog}
    </Stack>
  );
}
