import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { TextInputBase } from "src/components/TextInputBase";
import { CurrencyInput } from "src/pages/AccountsManagement/components/CurrencyInput/CurrencyInput";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";
import { useCountries } from "src/pages/AccountsManagementCreateAccount/hooks/useCountries";
import { ORGANIZATION_TYPES } from "src/pages/AccountsManagementCreateAccount/components/AccountsManagementNewAccountForm/AccountsManagementNewAccountForm.const";
import { useOpenState } from "src/utils/useOpenState";
import { STATUS_LIST } from "src/pages/AccountsManagement/components/AccountsManagementConfigurationDropdown/AccountsManagementConfigurationDropdown";
import { StatusConfig } from "src/pages/AccountsManagement/components/AccountsManagementConvertForm/AccountsManagementConvertForm.model";
import { AccountResponse } from "src/api/useAccount";
import { timezoneData } from "src/utils/timezoneData";
import { UserDataResponse } from "src/api/useUserData";
import { useAccountStore } from "../../hooks/useAccountStore";
import { AccountsManagementDeactivateAccountDialog } from "../AccountsManagementDeactivateAccountDialog/AccountsManagementDeactivateAccountDialog";

export function AccountsManagementEditAccountForm({
  deactivateAccount,
  accountConfiguration,
  data,
  accountManagerData,
}: {
  deactivateAccount: (convertKey: string, value?: string | boolean) => void;
  accountConfiguration: keyof StatusConfig;
  data?: AccountResponse;
  accountManagerData?: UserDataResponse;
}) {
  const { control } = useFormContext<NewAccountFormValues>();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const countriesList = useCountries();
  const deactivateAccountDialog = useOpenState();
  const { isActive, accountName } = useAccountStore();
  const configurationIcon = STATUS_LIST.filter(
    (config) => config.value === accountConfiguration
  );
  const accountRepresentative =
    accountManagerData?.email || data?.accountManager || "";
  return (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid
        item
        xs={12}
        mt={4}
        pt={4}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          Rep Information & Status
        </Typography>
        <Grid item xs={12} md={6} justifyContent={"end"} display={"flex"}>
          <FormControlLabel
            label="Active"
            control={
              <Switch
                disabled={!isActive}
                checked={isActive || false}
                onChange={deactivateAccountDialog.show}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={1} m={2} rowSpacing={4}>
        <Grid item xs={12} md={4}>
          <Tooltip
            placement="left"
            title={'Please use "Convert" to change this option'}
            arrow
          >
            <FormControl fullWidth>
              <InputLabel>Account Configuration</InputLabel>
              <Select
                disabled
                id="status"
                label="Account Configuration"
                value={accountConfiguration || null}
                fullWidth
                sx={{ textTransform: "capitalize" }}
                SelectDisplayProps={{
                  style: {
                    display: "flex",
                  },
                }}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    width: 100,
                    textTransform: "capitalize",
                  },
                }}
              >
                <MenuItem value={accountConfiguration}>
                  <ListItemIcon
                    sx={{
                      minWidth: "32px",
                    }}
                  >
                    {configurationIcon[0].icon}
                  </ListItemIcon>
                  {accountConfiguration}
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            disabled
            label="Creation Date"
            format="MMM dd ,yyyy"
            //FIXME: Update with missing value
            value={new Date()}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
              },
            }}
            slots={{
              textField: TextInputBase,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            disabled
            format="MMM dd ,yyyy"
            label="Expires"
            value={
              (data?.expirationDate && new Date(data?.expirationDate)) || null
            }
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                error: false,
              },
            }}
            slots={{
              textField: TextInputBase,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Account Representative</InputLabel>
            <Select
              disabled
              id="Account Representative"
              label="Account Representative"
              value={accountRepresentative}
              fullWidth
              MenuProps={{
                style: {
                  maxHeight: 400,
                  width: 100,
                },
              }}
            >
              <MenuItem value={accountRepresentative}>
                {accountRepresentative}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>User seats</InputLabel>
            <Select
              disabled
              id="User seats"
              label="User seats"
              value={data?.seatCount || 0}
              type="number"
              fullWidth
              MenuProps={{
                style: {
                  maxHeight: 400,
                  width: 100,
                },
              }}
            >
              <MenuItem value={data?.seatCount || 0}>
                {data?.seatCount || 0}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <Tooltip
            title={
              "How much does the user spend per year. (If this is a short-term account adjust for 12 month term.)"
            }
          >
            <Stack>
              <CurrencyInput
                label="Annualized Revenue"
                value={data?.annualRecurringRevenue || 0}
                disabled={true}
                fullWidth
              />
            </Stack>
          </Tooltip>
        </Grid>
      </Grid>

      <Typography variant="subtitle1" pl={5} pt={1}>
        Information
      </Typography>
      <Grid container spacing={3} m={2} mb={0} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Organization Name"
                  value={field.value || ""}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="organizationType"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Organization Type</InputLabel>
                <Select
                  {...field}
                  id="organizationType"
                  label="Organization Type"
                  value={field.value || ""}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  onBlur={field.onBlur}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      width: 100,
                    },
                  }}
                >
                  {ORGANIZATION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid container spacing={3} mb={1} pl={3}>
          <Grid
            item
            xs={12}
            mt={1}
            pt={1}
            justifyContent={"space-between"}
            display={"flex"}
          >
            <Typography variant="subtitle1">Address</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    {...field}
                    id="country"
                    label="Country"
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        width: 100,
                      },
                    }}
                  >
                    {countriesList.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} mb={2}>
            <Controller
              name="city"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="City"
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} mb={2}>
            <Controller
              name="stateOrProvince"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="State"
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="postalCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextInputBase
                    label="Zip Code"
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="timezone"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <InputLabel>Time Zone</InputLabel>
                  <Select
                    {...field}
                    id="timezone"
                    label="Time Zone"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    fullWidth
                    MenuProps={{
                      style: {
                        maxHeight: 300,
                        width: 100,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    }}
                    inputProps={{
                      sx: {
                        display: "grid",
                      },
                    }}
                  >
                    {timezoneData.map((timezone) => (
                      <MenuItem key={timezone.Id} value={timezone.Id}>
                        <Typography
                          variant="inherit"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {timezone.Display}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <AccountsManagementDeactivateAccountDialog
        title="Deactivate an account?"
        open={deactivateAccountDialog.isOpen}
        onClose={deactivateAccountDialog.hide}
        accountName={accountName}
        convertAccount={deactivateAccount}
      />
    </Grid>
  );
}
