import { SyntheticEvent, useCallback } from "react";
import {
  Alert,
  Box,
  Stack,
  Tooltip,
  Typography,
  alertClasses,
  useTheme,
  darken,
} from "@mui/material";
import { Done, DoNotDisturb, InsertLink } from "@mui/icons-material";
import { DownloadReportFormat } from "src/api/useReportExport";
import { getReportId, getReportTitle } from "src/api/useReport.getters";
import { useCopyReportLinkSnackbar } from "src/api/useCopyReportLinkSnackbar";
import { getFullReportDetailsLink } from "src/utils/getFullPublicPageLink";
import { useEventsDownloadLimits } from "src/utils/useEventsDownloadLimits";
import { ReportItem } from "src/models/Report";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { ScrollBox } from "src/components/ScrollBox/ScrollBox";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { AppLink } from "src/components/AppLink/AppLink";
import { reportEditRoute } from "src/pages/ReportEdit/ReportEdit.route";

type DownloadLimitsDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  handleReportExport: (format: DownloadReportFormat) => void;
  format: DownloadReportFormat;
  reports: ReportItem[];
};

export const DownloadLimitsDialog = ({
  onClose,
  handleReportExport,
  format,
  reports,
  ...props
}: DownloadLimitsDialogProps) => {
  const { palette } = useTheme();
  const getEventsDownloadLimit = useEventsDownloadLimits();
  const copyReportLinkSnackBar = useCopyReportLinkSnackbar();

  const copyLinkToClipboard = useCallback(
    async (event: SyntheticEvent, reportId: string) => {
      event.stopPropagation();
      if (!navigator.clipboard) {
        console.error("browser does not support navigation.clipboard");
      }
      await navigator.clipboard.writeText(getFullReportDetailsLink(reportId));
      copyReportLinkSnackBar.show();
    },
    [copyReportLinkSnackBar]
  );

  const downloadLimit = getEventsDownloadLimit(format);

  const isDownloadLimitExceeded = useCallback(
    (report: ReportItem) => {
      return downloadLimit && report.count > downloadLimit;
    },
    [downloadLimit]
  );

  const reportsToDownloadCount = reports.filter(
    (report) => downloadLimit && report.count < downloadLimit
  ).length;

  const onConfirm = useCallback(() => {
    if (reportsToDownloadCount > 0) {
      handleReportExport(format);
    }
    onClose && onClose();
  }, [reportsToDownloadCount, handleReportExport, onClose, format]);

  const renderReportItem = useCallback(
    (report: ReportItem) => {
      return (
        <Stack
          key={getReportId(report)}
          direction="row"
          height={42}
          minHeight={42}
          alignItems="center"
          sx={{
            borderBottom: `1px solid ${palette.divider}`,
            "&:last-of-type": {
              border: "none",
            },
          }}
        >
          {isDownloadLimitExceeded(report) ? (
            <DoNotDisturb color="error" sx={{ width: 20, height: 20, mx: 1 }} />
          ) : (
            <Done color="secondary" sx={{ width: 20, height: 20, mx: 1 }} />
          )}

          <AppLink
            to={reportEditRoute.makeUrl(
              {
                reportId: getReportId(report),
              },
              {}
            )}
          >
            <TextLineClamp
              variant="subtitle2"
              color="primary"
              lineClamp={1}
              height={21}
            >
              {getReportTitle(report)}
            </TextLineClamp>
          </AppLink>
          <Tooltip title="Copy report link">
            <InsertLink
              sx={{
                color: palette.text.secondary,
                cursor: "pointer",
                mr: 1,
                ml: "auto",
              }}
              onClick={(event) =>
                copyLinkToClipboard(event, getReportId(report))
              }
            />
          </Tooltip>
        </Stack>
      );
    },
    [
      palette.divider,
      palette.text.secondary,
      isDownloadLimitExceeded,
      copyLinkToClipboard,
    ]
  );

  return (
    <DialogPrompt
      maxWidth="xs"
      title={`Download ${reportsToDownloadCount} of ${
        reports.length
      } ${format.toUpperCase()} Report(s)`}
      children={
        <Box pb={1}>
          <Alert
            severity="warning"
            sx={{
              py: 0.5,
              px: 1,
              borderRadius: "4px",
              [`.${alertClasses.icon}`]: {
                mr: 1,
                color: darken(palette.warning.main, 0.6),
              },
            }}
          >
            <Typography
              variant="body2"
              color={darken(palette.warning.main, 0.6)}
            >
              Downloaded limits: PDF - up to{" "}
              {getEventsDownloadLimit(
                DownloadReportFormat.pdf
              )?.toLocaleString()}{" "}
              events per report, and CSV - up to{" "}
              {getEventsDownloadLimit(
                DownloadReportFormat.csv
              )?.toLocaleString()}{" "}
              events.
            </Typography>
          </Alert>
          <Typography variant="body1" mt={2} mb={1} px={1}>
            Try using an alternative format or a web link.
          </Typography>
          <Stack
            sx={{
              backgroundColor: palette.action.selected,
              borderRadius: "4px",
              maxHeight: "210px",
            }}
          >
            <ScrollBox direction="column">
              {reports.map(renderReportItem)}
            </ScrollBox>
          </Stack>
        </Box>
      }
      onDecline={onClose}
      onConfirm={onConfirm}
      confirmLabel="Ok"
      {...props}
    />
  );
};
