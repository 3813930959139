import { ListBase } from "src/components/ListBase/ListBase";
import {
  EventsNoResultsOverlay,
  FilteredEventsNoResultsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { WatchTermResultsListProps } from "./WatchTermResultsList.model";
import { WatchTermResultsListItem } from "./WatchTermResultsListItem";

export function WatchTermResultsList({
  addItemToReport,
  isExpandedTextMode,
  hideMarket,
  showDuplicatesDrawer,
  drawerParentEventId,
  hasFilters,
  ...props
}: WatchTermResultsListProps) {
  const { list, areAllPagesSelected, excludeList } =
    useEventSelectionWatchTermResults();
  const renderItem = (data: PowerSearchResultItem) => {
    let isChecked = false;
    if (areAllPagesSelected) {
      isChecked = !excludeList.find((event) => event.id === data.id);
    } else {
      isChecked = !!list.find((event) => event.id === data.id);
    }
    return (
      <WatchTermResultsListItem
        queryId={props.queryId}
        value={data}
        isChecked={isChecked}
        key={data.id}
        addItemToReport={addItemToReport}
        isExpandedTextMode={isExpandedTextMode}
        hideMarket={hideMarket}
        showDuplicatesDrawer={showDuplicatesDrawer}
        drawerParentEventId={drawerParentEventId}
      />
    );
  };

  return (
    <ListBase<PowerSearchResultItem>
      renderItem={renderItem}
      renderEmpty={
        hasFilters ? FilteredEventsNoResultsOverlay : EventsNoResultsOverlay
      }
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
