import { QueryGeoAnalytics } from "src/api/useQueryGeoAnalytics";

// calculate sum of all query hits for particular feature
export function calcValueByFeatureId(
  queryGeoAnalyticList: QueryGeoAnalytics[] = [],
  dataLevel: "Regional" | "National"
) {
  const maxValueByFeatureId: { [featureId: string]: number } = {};

  for (const queryGeoAnalytic of queryGeoAnalyticList) {
    for (const geoAggregation of queryGeoAnalytic.geoAggregations || []) {
      if (!geoAggregation.shapeId || geoAggregation.level !== dataLevel) {
        continue;
      }

      const geoAggregationsHits = geoAggregation.hits ?? 0;
      const shapeKey = `${geoAggregation.shapeId}`;

      if (maxValueByFeatureId[shapeKey] === undefined) {
        maxValueByFeatureId[shapeKey] = geoAggregationsHits;
      } else {
        maxValueByFeatureId[shapeKey] += geoAggregationsHits;
      }
    }
  }

  return maxValueByFeatureId;
}
