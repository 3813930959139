import { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { LoadingButton } from "src/components/buttons/LoadingButton";

import { UserManagementFilterForm } from "../UserManagementFilterForm/UserManagementFilterForm";
import { useUserManagementFilterForm } from "../UserManagementFilterForm/UserManagementFilterForm.hook";
import { UserManagementFilterFormValues } from "../UserManagementFilterForm/UserManagementFilterForm.model";

type UserManagementFiltersModalProps = Omit<
  DialogBaseProps,
  "content" | "footer" | "onSubmit"
> & {
  initial?: Partial<UserManagementFilterFormValues>;
  onSubmit: (data: UserManagementFilterFormValues) => void;
  onResetForm: () => void;
};

export const UserManagementFiltersModal = ({
  onClose,
  onSubmit,
  onResetForm,
  initial,
  ...props
}: UserManagementFiltersModalProps) => {
  const formHook = useUserManagementFilterForm({
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;
  const handleSubmitWrapper = handleSubmit((data) => {
    onClose && onClose();
    onSubmit(data);
  });

  const isBusy = formState.isSubmitting;

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
        children={<UserManagementFilterForm />}
        footer={
          <Stack
            direction="row"
            justifyContent="end"
            width="100%"
            spacing={2}
            minHeight={42}
          >
            <Button sx={{ width: 100 }} onClick={onClose} color="error">
              Cancel
            </Button>
            <Button sx={{ width: 160 }} onClick={onResetForm}>
              Clear options
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              loading={isBusy}
              disabled={!formState.isValid}
              onClick={handleSubmitWrapper}
              sx={{
                minWidth: 176,
              }}
            >
              Apply
            </LoadingButton>
          </Stack>
        }
      />
    </FormProvider>
  );
};
