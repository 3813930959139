import { EventDetails } from "src/models/EventDetails";
import { EventType } from "src/models/EventType";
import { useClipSaveBCast } from "./useClipSaveBCast";
import { useClipSavePCast } from "./useClipSavePCast";
import { useClipSaveUTube } from "./useClipSaveUTube";

export const availableEventType = [
  EventType.BCast,
  EventType.PCast,
  EventType.YouTube,
] as const;

export const isAvailableEventType = (value: string): value is EventType => {
  switch (value) {
    case EventType.BCast:
    case EventType.PCast:
    case EventType.YouTube:
      return true;
    default:
      return false;
  }
};

type RequestMutationFnParams = {
  event: EventDetails;
  trimRange?: [Date | null, Date | null];
  clipTitle?: string;
  archive?: boolean;
};

type UseClipSaveResult = {
  isLoading?: boolean;
  mutate: (params: RequestMutationFnParams) => void;
};

export function useClipSave(params: {
  event: EventDetails;
}): UseClipSaveResult {
  const { event } = params || {};

  const clipSaveBCast = useClipSaveBCast();
  const clipSavePCast = useClipSavePCast();
  const clipSaveUTube = useClipSaveUTube();

  switch (event?.eventType) {
    case EventType.BCast:
      return {
        isLoading: clipSaveBCast.isLoading,
        mutate: clipSaveBCast.mutate,
      };
    case EventType.PCast:
      return {
        isLoading: clipSavePCast.isLoading,
        mutate: clipSavePCast.mutate,
      };
    case EventType.YouTube:
      return {
        isLoading: clipSaveUTube.isLoading,
        mutate: clipSaveUTube.mutate,
      };
  }

  return {
    isLoading: false,
    mutate: () => alert(`Invalid event type: ${event?.eventType}`),
  };
}
