import queryString from "query-string";
import { PropsWithChildren } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

export function AppQueryParamsProvider({ children }: PropsWithChildren<{}>) {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      {children}
    </QueryParamProvider>
  );
}
