import { ReactNode, isValidElement } from "react";
import { Stack, StackProps, Typography, alpha, useTheme } from "@mui/material";

export function EventDetailsDataBlock({
  title,
  value,
  ...props
}: { title?: string; value?: ReactNode } & StackProps) {
  const { palette } = useTheme();

  return (
    <Stack {...props}>
      {title ? (
        <Typography variant="body2" color={alpha(palette.action.active, 0.54)}>
          {title}
        </Typography>
      ) : null}
      {isValidElement(value) ? (
        value
      ) : (
        <Typography variant="subtitle2" color="text.primary">
          {value || " - "}
        </Typography>
      )}
    </Stack>
  );
}
