import DeleteOutline from "@mui/icons-material/DeleteOutline";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
  useTheme,
  Tooltip,
  alpha,
} from "@mui/material";
import { parseISO } from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputBase } from "src/components/TextInputBase";
import { TimeZonePicker } from "src/components/TimeZonePicker/TimeZonePicker";
import { UserRolePicker } from "src/components/UserRolePicker/UserRolePicker";
import { UploadIcon } from "src/components/icons/UploadIcon";
import { SettingsGeneralTabClasses } from "src/pages/Settings/components/SettingsGeneralTab.const";
import defaultAvatar from "src/pages/Settings/components/assets/defaultAvatar.png";
import { AccountsManagementAddUserFormValues } from "src/pages/AccountsManagement/components/AccountsManagementAddUserForm/AccountsManagementAddUserForm.model";
import { getSelectedRoles } from "src/utils/useUserRoleList";

export function AccountsManagementAddUserGeneralTab({
  loading,
}: {
  loading?: boolean;
}) {
  const { palette } = useTheme();

  const { control, formState } =
    useFormContext<AccountsManagementAddUserFormValues>();
  const { isSubmitting } = formState;
  const isBusy = isSubmitting || loading;

  const userData = (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={5}
        mt={4}
        pt={4}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          User Information
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        mt={{ md: 4 }}
        pt={{ md: 4 }}
        justifyContent={"end"}
        display={"flex"}
      >
        <Stack flexDirection="row" gap={3}>
          <FormGroup sx={{ flexDirection: "row" }}>
            <Controller
              name="notify"
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    checked={field.value}
                    control={<Checkbox />}
                    label="Notify user"
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </FormGroup>
          <Controller
            name="isActive"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  checked={field.value}
                  control={<Switch />}
                  label="Active"
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Stack>
      </Grid>

      <Grid container spacing={3} mb={1} m={2} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="First name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                  disabled={isBusy}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Last name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                  disabled={isBusy}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                  disabled={isBusy}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Phone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  disabled={isBusy}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="accountName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Account"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={true}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <TimeZonePicker
                value={field.value ?? ""}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="creationDate"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label="Creation date"
                value={parseISO(field.value ?? "")}
                onChange={field.onChange}
                disabled
                format="MM/dd/yyyy"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
                slots={{
                  textField: TextInputBase,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="expirationDate"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                label="Expiration date"
                value={parseISO(field.value ?? "")}
                onChange={field.onChange}
                disabled
                format="MM/dd/yyyy"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
                slots={{
                  textField: TextInputBase,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const roles = (
    <Grid container spacing={3} mb={2} pl={2}>
      <Grid item xs={12} md={12}>
        <Controller
          name="roles"
          control={control}
          render={({ field }) => {
            return (
              <UserRolePicker
                value={field.value ?? []}
                onChange={(e) => {
                  const inputValue = field.value ?? [];
                  const { value } = e.target;
                  if (inputValue.includes(value)) {
                    field.onChange(inputValue.filter((v) => v !== value));
                  } else {
                    const roles = getSelectedRoles(value);
                    field.onChange(roles);
                  }
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Stack direction={"row"} gap={3}>
      <Stack component={Paper} flex={1} px={3}>
        {userData}
        {roles}{" "}
      </Stack>
      <Stack width={300} alignItems={"center"} component={Paper} pt={14}>
        <Box
          sx={{
            width: 200,
            height: 200,
            position: "relative",
            [`&:hover .${SettingsGeneralTabClasses.avatarButton}`]: {
              visibility: "visible",
            },
          }}
        >
          <Avatar
            alt="avatar"
            src={defaultAvatar}
            sx={{ width: "100%", height: "100%" }}
          ></Avatar>
        </Box>
        <Stack direction={"row"} gap={2} mt={3}>
          <Tooltip
            title={"Users can upload their photo after creating their profile"}
          >
            <Box>
              <Button
                disabled
                variant="outlined"
                sx={{
                  width: "40px",
                  height: "40px",
                  padding: "12px",
                  minWidth: "40px",
                  borderColor:
                    palette.mode === "light"
                      ? alpha(palette.common.black, 0.23)
                      : alpha(palette.common.white, 0.12),
                }}
              >
                <UploadIcon
                  stroke={palette.action.disabled}
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </Button>
            </Box>
          </Tooltip>
          <Tooltip
            title={
              "Users can delete their photo after creating their profile and uploading a photo"
            }
          >
            <Box>
              <Button
                variant="outlined"
                disabled
                sx={{
                  width: "40px",
                  height: "40px",
                  padding: "12px",
                  minWidth: "40px",
                  borderColor:
                    palette.mode === "light"
                      ? alpha(palette.common.black, 0.23)
                      : alpha(palette.common.white, 0.12),
                }}
              >
                <DeleteOutline
                  sx={{
                    width: "24px",
                    height: "24px",
                    color: palette.action.disabled,
                  }}
                />
              </Button>
            </Box>
          </Tooltip>
        </Stack>
        <Typography variant="body2" width="65%" mt={4} textAlign={"center"}>
          Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
        </Typography>
      </Stack>
    </Stack>
  );
}
