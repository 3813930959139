import { UserInformationUpdateFormValues } from "src/components/UserInformationUpdateForm/UserInformationUpdateForm.model";
import { NewAccountFormValues } from "src/pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.model";

type FormValues =
  | keyof NewAccountFormValues
  | keyof UserInformationUpdateFormValues;

export function getSectionName(fieldName: FormValues) {
  switch (fieldName) {
    case "firstName":
    case "lastName":
    case "email":
    case "phoneNumber":
    case "timezone":
    case "isActive":
    case "roles":
      return "General";

    case "sourcesInclude":
    case "sourcesExclude":
    case "preset":
      return "Content Filter";

    case "enableMediaDownloads":
    case "enableEditWatchList":
    case "enableAdFiltering":
    case "enableCustomReportBanner":
    case "enableReportDownloads":
    case "limitWatchlistSize":
    case "limitItemsPerEmail":
    case "limitEventsInReport":
    case "limitEventsInCSV":
    case "limitEventsInPDF":
    case "limitScratchReportItems":
    case "limitEventsInReportEmail":
    case "limitEventsInAlertEmail":
      return "Features";

    default:
      return "Other";
  }
}

export function getSectionField(fieldName: FormValues) {
  switch (fieldName) {
    case "firstName":
      return "First name";
    case "lastName":
      return "Last name";
    case "phoneNumber":
      return "Phone number";
    case "isActive":
      return "Status";
    case "timezone":
      return "Time Zone";

    case "sourcesInclude":
      return "Sources Include";
    case "sourcesExclude":
      return "Sources Exclude";

    case "enableMediaDownloads":
      return "Enable save clips";
    case "enableEditWatchList":
      return "Edit Watchlist";
    case "enableAdFiltering":
      return "Enable ad filtering";
    case "enableCustomReportBanner":
      return "Enable report customization";
    case "enableReportDownloads":
      return "Enable report downloads";
    case "limitWatchlistSize":
      return "Limit Watchlist Size";
    case "limitItemsPerEmail":
      return "Limit items per email";
    case "limitEventsInReport":
      return "Limit Events in reports";
    case "limitEventsInCSV":
      return "Limit Events in CSV";
    case "limitEventsInPDF":
      return "Limit Events in PDF";
    case "limitScratchReportItems":
      return "Limit scratch report items";
    case "limitEventsInReportEmail":
      return "Limit events in report email";
    case "limitEventsInAlertEmail":
      return "Limit events in alert email";

    default:
      return fieldName[0].toLocaleUpperCase() + fieldName.slice(1);
  }
}
