import { Dispatch, SetStateAction } from "react";
import {
  Stack,
  Button,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DialogBase } from "../DialogBase/DialogBase";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";
import { LoadingButton } from "../buttons/LoadingButton";
import { ShareForm } from "../ShareForm/ShareForm";

type ShareBaseDialogProps = Omit<
  DialogBaseProps,
  "title" | "onSubmit" | "onClose"
> & {
  title?: string;
  hideInstantLink?: boolean;
  instantLinkValue?: string;
  getInstantLink: () => void;
  isLoadingLink?: boolean;
  copyShareLinkCallback: () => void;
  onClose: () => void;
  isBusy: boolean;
  handleSubmit: () => void;
  sendCopyValue?: boolean;
  setSendCopyValue?: Dispatch<SetStateAction<boolean>>;
};

export const ShareBaseDialog = ({
  title = "Share Event",
  hideInstantLink = false,
  instantLinkValue,
  getInstantLink,
  isLoadingLink = false,
  copyShareLinkCallback,
  onClose,
  isBusy,
  handleSubmit,
  sendCopyValue,
  setSendCopyValue,
  ...props
}: ShareBaseDialogProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <DialogBase
      title={title}
      maxWidth="sm"
      extraMobileMargin={3}
      disableHeaderDivider
      {...props}
      children={
        <ShareForm
          hideInstantLink={hideInstantLink}
          instantLinkValue={instantLinkValue}
          getInstantLink={getInstantLink}
          isLoadingLink={isLoadingLink}
          copyShareLinkCallback={copyShareLinkCallback}
          onClose={onClose}
        />
      }
      footer={
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          width="100%"
        >
          {!!setSendCopyValue && (
            <FormControlLabel
              label="Send me copy"
              control={
                <Checkbox
                  checked={sendCopyValue}
                  onChange={(_e, value) => setSendCopyValue(value)}
                  sx={{ height: "36px", width: "36px" }}
                />
              }
              sx={{ ml: "0", mb: isMobile ? 1 : 0 }}
            />
          )}
          <Stack
            direction="row"
            justifyContent={isMobile ? "space-between" : "flex-end"}
            ml={isMobile ? 0 : "auto"}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: isMobile ? 132 : 84,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                width: isMobile ? 132 : 84,
                ml: isMobile ? 0 : 1,
              }}
              disabled={isBusy}
              onClick={handleSubmit}
            >
              Ok
            </LoadingButton>
          </Stack>
        </Stack>
      }
    />
  );
};
