import {
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { orange } from "@mui/material/colors";
import { Controller, useFormContext } from "react-hook-form";
import { AccountsManagementConvertFormValues } from "../AccountsManagementConvertForm/AccountsManagementConvertForm.model";

export const STATUS_LIST = [
  { value: "Active Trial Account", icon: <AccountCircleIcon color="info" /> },
  {
    value: "Active Paying Account",
    icon: <CheckCircleIcon color="success" />,
  },
  {
    value: "Active Non-Paying Account",
    icon: <AccountCircleIcon sx={{ color: orange[800] }} />,
  },
  {
    value: "Disabled Paying Account",
    icon: <WatchLaterIcon color="warning" />,
  },

  { value: "Disabled Trial Account", icon: <ErrorIcon color="disabled" /> },
  { value: "Disabled Account", icon: <ErrorIcon color="disabled" /> },
];

export function AccountsManagementConfigurationDropdown() {
  const { control } = useFormContext<AccountsManagementConvertFormValues>();
  return (
    <Controller
      name="status"
      control={control}
      render={({ field }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Account Configuration</InputLabel>
            <Select
              id="status"
              label="Account Configuration"
              value={field.value ?? ""}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
              sx={{ textTransform: "capitalize" }}
              SelectDisplayProps={{
                style: {
                  display: "flex",
                },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  width: 100,
                  textTransform: "capitalize",
                },
              }}
            >
              {STATUS_LIST.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  <ListItemIcon
                    sx={{
                      minWidth: "32px",
                    }}
                  >
                    {status.icon}
                  </ListItemIcon>
                  {status.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    />
  );
}
