import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { UseMutationOptions, useMutation } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useSessionContext } from "./useSessionContext";

const apiPath: keyof paths = "/api/Notifications/message/user";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type EndpointParameters = {
  //
};

type RequestMutationFnParams = Endpoint["parameters"]["query"];

type RequestOptions = Pick<
  UseMutationOptions<Response, unknown, RequestMutationFnParams>,
  "onSuccess" | "onError" | "onSettled"
>;

type ResponseBody = Endpoint["responses"]["200"]["content"];

export function useNotificationMessageUser(args?: {
  options?: RequestOptions;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { userId } = useSessionContext();
  const { options } = args || {};

  return useMutation<ResponseBody, unknown, RequestMutationFnParams>({
    ...options,
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      const query: Endpoint["parameters"]["query"] = {
        userId,
        ...data,
      };

      const apiUrl = makeApiUrl<EndpointParameters>(apiPath, { query });
      const response = await axios<ResponseBody>(apiUrl, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
  });
}
