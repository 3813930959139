import { AppRouteBuilder } from "src/models/AppRoute";
import { SnapshotsClipViewer } from "./SnapshotsClipViewer.page";

export type SnapshotsClipViewerParams = {
  stationId?: string;
  date?: string;
};

export const SnapshotsClipViewerRoute =
  new AppRouteBuilder<SnapshotsClipViewerParams>({
    path: "/snapshot/result/clip/:stationId/:date",
    element: <SnapshotsClipViewer />,
  });
