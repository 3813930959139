import Joi from "joi";
import { AccountsManagementAddUserFormValues } from "./AccountsManagementAddUserForm.model";

const sourceListSchema = Joi.array().items().required();
const sourceLogicSchema = Joi.string()
  .allow("and", "or")
  .insensitive()
  .required();

export const AccountsManagementAddUserFormSchema =
  //user Info
  Joi.object<AccountsManagementAddUserFormValues>({
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    isActive: Joi.bool().label("Active").optional(),
    notify: Joi.bool().label("Notify user").optional(),
    accountName: Joi.string().required().label("Account"),
    phoneNumber: Joi.string().label("Phone").optional().allow(""),
    roles: Joi.array().items(Joi.string()),
    creationDate: Joi.string().label("Creation date").optional().allow(""),
    expirationDate: Joi.string().label("Expiration date").optional().allow(""),
    timezone: Joi.string().label("Timezone").optional().allow(""),
    //Features
    enableMediaDownloads: Joi.boolean().optional().label("Media Download"),
    enableEditWatchList: Joi.boolean().optional().label("Watch List"),
    enableAdFiltering: Joi.boolean().optional().label("Ad Filtering"),
    enableCustomReportBanner: Joi.boolean()
      .optional()
      .label("Custom Report Banner"),
    enableReportDownloads: Joi.boolean().optional().label("Report Downloads"),
    limitWatchlistSize: Joi.number().optional().label("Watchlist size limit"),
    limitItemsPerEmail: Joi.number().optional().label("Items per email limit"),
    limitEventsInReport: Joi.number()
      .optional()
      .label("Events in report limit"),
    limitEventsInCSV: Joi.number().optional().label("Events in CSV limit"),
    limitEventsInPDF: Joi.number().optional().label("Events in PDF limit"),
    limitScratchReportItems: Joi.number()
      .optional()
      .label("Scratch report items limit"),
    limitEventsInReportEmail: Joi.number()
      .optional()
      .label("Events in report email limit"),
    limitEventsInAlertEmail: Joi.number()
      .optional()
      .label("Events in alert email limit"),
    //Content Filter
    sourcesInclude: {
      list: sourceListSchema.label("Include sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
    sourcesExclude: {
      list: sourceListSchema.label("Exclude sources"),
      logic: sourceLogicSchema.required().label("Logic"),
    },
  });
