export const ORGANIZATION_TYPES = [
  "Operating Company",
  "Advocacy",
  "Education",
  "Government",
  "Public Office",
  "Public Relations",
  "Sports",
  "Venues",
];
