import { ListBase } from "src/components/ListBase/ListBase";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { PreselectedEventListProps } from "./PreselectedEventList.model";
import { PreselectedEventListItem } from "./PreselectedEventListItem";
import { ReportsNoFilteredEventsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";

export function PreselectedEventList(props: PreselectedEventListProps) {
  const eventsSelectedForReports = useEventsSelectedForReports();
  const renderItem = (data: PowerSearchResultItem) => {
    let isChecked = false;
    if (eventsSelectedForReports.areAllPagesSelected) {
      isChecked = !eventsSelectedForReports.excludeEvents.find(
        (event) => event.id === data.id
      );
    } else {
      isChecked = !!eventsSelectedForReports.events.find(
        (event) => event.id === data.id
      );
    }
    return (
      <PreselectedEventListItem
        value={data}
        isChecked={isChecked}
        key={data.id}
      />
    );
  };

  return (
    <ListBase<PowerSearchResultItem>
      renderItem={renderItem}
      renderEmpty={ReportsNoFilteredEventsOverlay}
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
