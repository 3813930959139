export enum WatchQueryTableClasses {
  rowData = "row-data",
  rowGroup = "row-group",
  rowGroupData = "row-group-data",

  cellTitle = "cell-title",
  cellToday = "cell-today",
  cellWeekend = "cell-weekend",
  cellDay = "cell-day",

  header = "header",
  headerToday = "header-today",
  headerWeekend = "header-weekend",

  actions = "actions",
}
