import { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GoogleBadge } from "src/components/icons/GoogleBadge";
import { UserInformationSettingsForm } from "src/components/UserInformationSettingsForm/UserInformationSettingsForm";
import { useOpenState } from "src/utils/useOpenState";
import { PageLoader } from "src/components/PageLoader";
import { UploadImageDialog } from "src/components/UploadImageDialog/UploadImageDialog";
import { UploadIcon } from "src/components/icons/UploadIcon";
import { DialogPrompt } from "src/components/DialogPrompt/DialogPrompt";
import { useProfileImage } from "src/api/useProfileImage";
import { useProfileImageUpdate } from "src/api/useProfileImageUpdate";
import { useProfileImageDelete } from "src/api/useProfileImageDelete";
import { User } from "../Settings.model";
import { SettingsGeneralTabClasses } from "./SettingsGeneralTab.const";
import { DialogModes } from "src/components/UploadImageDialog/UploadImageDialog.model";
import defaultAvatar from "../components/assets/defaultAvatar.png";

const SmallAvatar = styled(Avatar)(() => ({
  width: 35,
  height: 35,
  right: "25px",
  background: "#FFF",
}));

const AUTH0 = "auth0";

export function SettingsGeneralTab({
  userInfo,
  isRegularUser,
}: {
  userInfo?: User;
  isRegularUser?: boolean;
}) {
  const avatarDialog = useOpenState();
  const deleteAvatarDialog = useOpenState();
  const { data, isLoading, isFetching } = useProfileImage();
  const profileImageUpdate = useProfileImageUpdate({});

  const [selectedAvatar, setSelectedAvatar] = useState<
    string | Blob | null | undefined
  >(data?.image);
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isDeleteDisabled = !data?.image || data?.imageSource === AUTH0;

  const deleteProfileImage = useProfileImageDelete({
    options: {
      onSuccess: () => deleteAvatarDialog.hide(),
    },
  });

  const selectedAvatarString =
    typeof selectedAvatar === "string" ? selectedAvatar : undefined;

  const isProfileImageLoading =
    isLoading || isFetching || profileImageUpdate.isLoading;

  const handleDeleteProfileImage = () => {
    deleteProfileImage.mutate();
  };
  useEffect(() => {
    setSelectedAvatar(data?.image || defaultAvatar);
  }, [data]);

  const deleteDialog = (
    <DialogPrompt
      disableHeaderDivider
      open={deleteAvatarDialog.isOpen}
      title={"Delete avatar"}
      maxWidth="xs"
      onConfirm={handleDeleteProfileImage}
      isLoading={deleteProfileImage.isLoading}
      onDecline={deleteAvatarDialog.hide}
      children={
        <Typography variant="body1" pb={1}>
          Are you sure that you want to delete profile photo?
        </Typography>
      }
    />
  );

  const mobileContent = (
    <Stack direction={"column"}>
      <Stack
        width={"100%"}
        justifyContent="center"
        alignItems={"center"}
        component={Paper}
        mr="auto"
        ml="auto"
        mt={2}
        pb={4}
        borderBottom={"18px solid rgb(242, 245, 248)"}
        borderRadius={0}
      >
        <Box
          sx={{
            width: 148,
            height: 148,
            position: "relative",
            [`&:hover .${SettingsGeneralTabClasses.avatarButton}`]: {
              visibility: "visible",
            },
          }}
        >
          {isProfileImageLoading ? (
            <PageLoader />
          ) : selectedAvatar ? (
            data?.imageSource === AUTH0 ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ width: "100%", height: "100%" }}
                badgeContent={
                  //Needs new badge design, hidden for now
                  <SmallAvatar
                    alt="auth provider badge"
                    sx={{
                      display: "none",
                    }}
                  >
                    <GoogleBadge />
                  </SmallAvatar>
                }
              >
                <Avatar
                  src={selectedAvatarString}
                  alt="avatar"
                  sx={{ width: "100%", height: "100%" }}
                />
              </Badge>
            ) : (
              <Avatar
                alt="avatar"
                src={selectedAvatarString}
                sx={{ width: "100%", height: "100%" }}
              ></Avatar>
            )
          ) : (
            <Avatar sx={{ width: "100%", height: "100%" }}>
              <AccountCircleIcon />
            </Avatar>
          )}
        </Box>
        <Stack direction={"row"} gap={2} mt={3}>
          <Button
            variant="outlined"
            sx={{
              width: "40px",
              height: "40px",
              padding: "12px",
              minWidth: "40px",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
            onClick={avatarDialog.show}
          >
            <UploadIcon
              stroke={palette.success.main}
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "40px",
              height: "40px",
              padding: "12px",
              minWidth: "40px",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
            disabled={isDeleteDisabled}
            onClick={deleteAvatarDialog.show}
          >
            <DeleteOutlineIcon
              sx={{
                width: "24px",
                height: "24px",
                color: isDeleteDisabled
                  ? palette.action.disabled
                  : palette.text.primary,
              }}
            />
          </Button>
        </Stack>
        <Typography variant="body2" width="65%" mt={3} textAlign={"center"}>
          Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
        </Typography>
      </Stack>
      <Stack component={Paper} flex={1}>
        <UserInformationSettingsForm
          userInfo={userInfo}
          isRegularUser={isRegularUser}
        />
      </Stack>
      <UploadImageDialog
        maxWidth={"lg"}
        title="Upload Image"
        open={avatarDialog.isOpen}
        onClose={avatarDialog.hide}
        selectedAvatar={selectedAvatar}
        setSelectedAvatar={setSelectedAvatar}
        mode={DialogModes.avatar}
      />
      {deleteDialog}
    </Stack>
  );

  const desktopContent = (
    <Stack direction={"row"} gap={3}>
      <Stack
        component={Paper}
        flex={1}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <UserInformationSettingsForm
          userInfo={userInfo}
          isRegularUser={isRegularUser}
        />
      </Stack>
      <Stack
        width={300}
        alignItems={"center"}
        component={Paper}
        pt={17.75}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Box
          sx={{
            width: 200,
            height: 200,
            position: "relative",
            [`&:hover .${SettingsGeneralTabClasses.avatarButton}`]: {
              visibility: "visible",
            },
          }}
        >
          {isProfileImageLoading ? (
            <PageLoader />
          ) : selectedAvatar ? (
            data?.imageSource === AUTH0 ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ width: "100%", height: "100%" }}
                badgeContent={
                  //Needs new badge design, hidden for now
                  <SmallAvatar
                    alt="auth provider badge"
                    sx={{
                      display: "none",
                    }}
                  >
                    <GoogleBadge />
                  </SmallAvatar>
                }
              >
                <Avatar
                  src={selectedAvatarString}
                  alt="avatar"
                  sx={{ width: "100%", height: "100%" }}
                />
              </Badge>
            ) : (
              <Avatar
                alt="avatar"
                src={selectedAvatarString}
                sx={{ width: "100%", height: "100%" }}
              ></Avatar>
            )
          ) : (
            <Avatar sx={{ width: "100%", height: "100%" }}>
              <AccountCircleIcon />
            </Avatar>
          )}
        </Box>
        <Stack direction={"row"} gap={2} mt={3}>
          <Button
            variant="outlined"
            sx={{
              width: "40px",
              height: "40px",
              padding: "12px",
              minWidth: "40px",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
            onClick={avatarDialog.show}
          >
            <UploadIcon
              stroke={palette.success.main}
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "40px",
              height: "40px",
              padding: "12px",
              minWidth: "40px",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
            onClick={deleteAvatarDialog.show}
            disabled={isDeleteDisabled}
          >
            <DeleteOutlineIcon
              sx={{
                width: "24px",
                height: "24px",
                color: isDeleteDisabled
                  ? palette.action.disabled
                  : palette.text.primary,
              }}
            />
          </Button>
        </Stack>
        <Typography variant="body2" width="65%" mt={4} textAlign={"center"}>
          Allowed *.jpeg, *.jpg, *.png size of 3.1 MB
        </Typography>
      </Stack>
      <UploadImageDialog
        maxWidth={"lg"}
        title="Upload Image"
        open={avatarDialog.isOpen}
        onClose={avatarDialog.hide}
        selectedAvatar={selectedAvatar}
        setSelectedAvatar={setSelectedAvatar}
        mode={DialogModes.avatar}
      />
      {deleteDialog}
    </Stack>
  );

  return isMobile ? mobileContent : desktopContent;
}
