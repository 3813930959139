import { useCallback, useMemo, useState } from "react";
import { Stack, sliderClasses, useTheme } from "@mui/material";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { ClipViewerTimeBadge } from "src/components/ClipViewerTimeBadge/ClipViewerTimeBadge";
import { TranscriptView } from "src/components/TranscriptView/TranscriptView";
import { EventType } from "src/models/EventType";
import { useDateTimeSearchPlayState } from "../DateTimeSearchPlayButton/hooks/useDateTimeSearchPlayState";
import { DateTimeSearchResultActions } from "../DateTimeSearchResultActions/DateTimeSearchResultActions";

export function DateTimeSearchSlide({
  value,
}: {
  value: DateTimeSearchResultItem;
}) {
  const { palette, spacing } = useTheme();

  const {
    id,
    title,
    stationUUID,
    blockStartTime,
    blockEndTime,
    transcript,
    transcriptLines,
    mediaType,
  } = value || {};

  const [seekTo, setSeekTo] = useState(0);
  const {
    isPlaying,
    setPlayState,
    setInstanceTime,
    getInstanceTime,
    getActiveInstanceId,
    playNext,
  } = useDateTimeSearchPlayState();

  const activeInstanceId = getActiveInstanceId();
  const isCurrent = activeInstanceId === id;
  const isCurrentPlaying = isCurrent && isPlaying;
  const currentTime = id ? getInstanceTime(id) : 0;

  const handlePlayEndWrapper = useCallback(() => {
    playNext();
  }, [playNext]);

  const clipViewer = useMemo(() => {
    return (
      <ClipViewer
        id={`${id}`}
        viewMode="compact"
        seekOffsetMs={seekTo}
        play={isCurrentPlaying}
        onCurrentTimeChange={setInstanceTime}
        onChangePlayState={setPlayState}
        onPlayEnd={handlePlayEndWrapper}
        event={{
          eventType: EventType.BCast,
          sourceId: stationUUID ?? undefined,
          startDateTime: blockStartTime,
          endDateTime: blockEndTime,
          mediaType: mediaType,
        }}
        sx={{
          [`& > .MuiBox-root:first-of-type div`]: {
            borderTopRightRadius: 8, // not 8 because of outer 1px border
            borderTopLeftRadius: 8, // not 8 because of outer 1px border
            overflow: "hidden",
          },

          [`& .${sliderClasses.root}`]: {
            visibility: isCurrent ? "visible" : "hidden",
          },
        }}
      />
    );
  }, [
    id,
    seekTo,
    isCurrentPlaying,
    setInstanceTime,
    setPlayState,
    handlePlayEndWrapper,
    stationUUID,
    blockStartTime,
    blockEndTime,
    isCurrent,
    mediaType,
  ]);

  const transcriptView = useMemo(() => {
    return (
      <TranscriptView
        key={`${id}:offset=${title}`}
        offset={currentTime}
        flex={1}
        overflow="scroll"
        autoScroll
        hideAutoScrollSwitch
        pb={2}
        onClickLineBlock={(offsetMs) => {
          setSeekTo(offsetMs);
          setPlayState(`${id}`, true);
        }}
        event={{
          startDateTime: blockStartTime,
          transcriptLineBundles: [
            {
              bundleStartDateTime: blockStartTime,
              bundleEndDateTime: blockEndTime,
              transcriptLines: transcriptLines,
              transcript: transcript,
            },
          ],
        }}
      />
    );
  }, [
    blockEndTime,
    blockStartTime,
    currentTime,
    id,
    title,
    transcript,
    transcriptLines,
    setPlayState,
    setSeekTo,
  ]);

  return useMemo(() => {
    return (
      <Stack
        key={value.id}
        flex={1}
        position="relative"
        boxShadow={8}
        borderRadius={2}
        my={1}
        rowGap={2}
      >
        <ClipViewerTimeBadge
          baseTime={blockStartTime}
          sx={{
            position: "absolute",
            left: 12,
            top: 12,
            zIndex: 2,
          }}
        />

        {clipViewer}
        {transcriptView}
        <DateTimeSearchResultActions
          value={value}
          sx={{
            position: "absolute",
            top: spacing(0.5),
            right: spacing(1),
            zIndex: 2,
            transform: "none",
            borderRadius: "50%",
            bgcolor: palette.background.paper,
          }}
        />
      </Stack>
    );
  }, [
    blockStartTime,
    clipViewer,
    spacing,
    palette.background.paper,
    transcriptView,
    value,
  ]);
}
