import { create } from "zustand";

type PreselectedEventsState = {
  tooltipIsOpen: boolean;
  showTooltip: () => void;
  hideTooltip: () => void;
  allAddedEventsLenght: number;
  setAllAddedEventsLenght: (eventsLenght: number) => void;
  highlightedEventsIds: string[];
  setHighlightedEventsIds: (highlightedEventsIds: string[]) => void;
  removeHighlighting: () => void;
};

export const preselectedEventsTooltipKey = "preselectedEventsTooltipViewed";

export const useEventsPreselected = create<PreselectedEventsState>(
  (setState) => ({
    tooltipIsOpen: false,
    showTooltip: () => {
      if (localStorage.getItem(preselectedEventsTooltipKey)) return;
      setState({
        tooltipIsOpen: true,
      });
      localStorage.setItem(preselectedEventsTooltipKey, "true");
    },
    hideTooltip: () => {
      setState({
        tooltipIsOpen: false,
      });
    },
    allAddedEventsLenght: 0,
    setAllAddedEventsLenght: (eventsLength: number) => {
      setState({
        allAddedEventsLenght: eventsLength,
      });
    },
    highlightedEventsIds: [],
    setHighlightedEventsIds: (highlightedEventsIds: string[]) => {
      setState((state) => ({
        highlightedEventsIds: [
          ...state.highlightedEventsIds,
          ...highlightedEventsIds,
        ],
      }));
    },
    removeHighlighting: () => {
      setState({
        highlightedEventsIds: [],
        allAddedEventsLenght: 0,
      });
    },
  })
);
