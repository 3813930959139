import { MouseEvent } from "react";
import { Button, Typography, colors, useTheme } from "@mui/material";
import Info from "@mui/icons-material/Info";

export const InfoIconButton = ({
  onClick,
  text,
}: {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  text?: string;
}) => {
  const { palette } = useTheme();

  return (
    <Button
      startIcon={<Info />}
      variant="text"
      sx={{ color: colors.blueGrey[200] }}
      onClick={onClick}
    >
      {text ? (
        <Typography variant="body1" sx={{ color: palette.text.secondary }}>
          {text}
        </Typography>
      ) : null}
    </Button>
  );
};
