import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { PowerSearchResultItem } from "./usePowerSearch";

const apiKey: keyof paths = "/api/Report/{id}/highlights";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = Required<paths[typeof apiKey][typeof method]>;
type Parameters = Endpoint["parameters"];

type RequestBody = Endpoint["requestBody"]["content"]["application/json"];

// FIXME: this is to make response fields non-optional,
// keep an eye for type updates and get rid of this mock
export type ReportEventsResponse = {
  total?: number;
  results?: PowerSearchResultItem[];
  configuration?: components["schemas"]["PagingConfiguration"] | null;
};

export function useReportEvents({
  options,
  params,
  excludeFilter,
}: {
  options?: UseQueryOptions<ReportEventsResponse, unknown>;
  params: Parameters;
  excludeFilter?: string[];
}) {
  return useQuery<ReportEventsResponse, unknown>({
    ...options,
    queryKey: [apiKey, params, excludeFilter],
    keepPreviousData: true,
    queryFn: async () => {
      const url = makeApiUrl(apiKey, params);
      const body: RequestBody = {
        excludeFilter,
      };
      const { data } = await axios<ReportEventsResponse>(url, {
        method,
        data: body,
      });
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
