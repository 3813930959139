import { Map } from "mapbox-gl";

export const waitForMapBoxSourceFeatures = async ({
  map,
  sourceId,
  sourceLayer,
}: {
  map: Map;
  sourceId: string;
  sourceLayer: string;
}) => {
  for (let n = 0; n < 100; n++) {
    // wait some time until MapBox processes source
    await new Promise((resolve) => setTimeout(resolve, 50));

    const features = map.querySourceFeatures(sourceId, {
      sourceLayer,
    });

    if (features.length) {
      return features;
    }
  }

  return [];
};
