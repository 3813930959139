import { AppRouteBuilder } from "src/models/AppRoute";
import { PageTabMode, ReportEditPage } from "./ReportEdit.page";

export type ReportEditPathParam = {
  reportId: string;
};

type ReportEditQueryParams = {
  pageTab?: PageTabMode;
};

export const reportEditRoute = new AppRouteBuilder<
  ReportEditPathParam,
  ReportEditQueryParams
>({
  path: "/reports/:reportId",
  element: <ReportEditPage />,
});
