import { Badge, badgeClasses } from "@mui/material";

export function AppAutocompleteCountBadge({
  count,
  size = "medium",
}: {
  count: number;
  size?: "small" | "medium";
}) {
  const sxMap = {
    small: {
      px: 0.5,
      [`.${badgeClasses.badge}`]: {
        m: "auto",
        position: "relative",
        transform: "unset",
        fontSize: 12,
        width: 22,
        height: 22,
        borderRadius: "50%",
      },
    },
    medium: {
      px: 0.5,
      [`.${badgeClasses.badge}`]: {
        m: "auto",
        position: "relative",
        transform: "unset",
        fontSize: 14,
        width: 24,
        height: 24,
        borderRadius: "50%",
      },
    },
  };

  const sx = sxMap[size];
  return <Badge badgeContent={count} color="primary" sx={sx} />;
}
