import { useMemo } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const DownloadPDF = ({
  disabled,
  color,
  width = "24",
  height = "24",
  ...props
}: Omit<SvgIconProps, "color"> & { disabled?: boolean; color?: string }) => {
  const { palette } = useTheme();

  const iconColor = useMemo(() => {
    if (disabled) {
      return palette.action.disabled;
    }
    return color ? color : palette.primary.main;
  }, [disabled, palette.action.disabled, color, palette.primary.main]);

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        style={{
          width,
          height,
        }}
      >
        <path
          d="M8.26653 14.6801C8.08253 14.6801 7.95853 14.6981 7.89453 14.7161V15.8941C7.97053 15.9121 8.06553 15.9171 8.19653 15.9171C8.67553 15.9171 8.97053 15.6751 8.97053 15.2661C8.97053 14.9001 8.71653 14.6801 8.26653 14.6801ZM11.7535 14.6921C11.5535 14.6921 11.4235 14.7101 11.3465 14.7281V17.3381C11.4235 17.3561 11.5475 17.3561 11.6595 17.3561C12.4765 17.3621 13.0085 16.9121 13.0085 15.9601C13.0145 15.1301 12.5295 14.6921 11.7535 14.6921Z"
          fill={iconColor}
        />
        <path
          d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM9.498 16.19C9.189 16.48 8.733 16.61 8.202 16.61C8.09904 16.6111 7.99613 16.6051 7.894 16.592V18.018H7V14.082C7.40345 14.0218 7.81112 13.9944 8.219 14C8.776 14 9.172 14.106 9.439 14.319C9.693 14.521 9.865 14.852 9.865 15.242C9.864 15.634 9.734 15.965 9.498 16.19ZM13.305 17.545C12.885 17.894 12.246 18.06 11.465 18.06C10.997 18.06 10.666 18.03 10.441 18V14.083C10.8446 14.0241 11.2521 13.9963 11.66 14C12.417 14 12.909 14.136 13.293 14.426C13.708 14.734 13.968 15.225 13.968 15.93C13.968 16.693 13.689 17.22 13.305 17.545ZM17 14.77H15.468V15.681H16.9V16.415H15.468V18.019H14.562V14.03H17V14.77ZM14 9H13V4L18 9H14Z"
          fill={iconColor}
        />
      </svg>
    </SvgIcon>
  );
};
