import { useState } from "react";
import { Info } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { AdvertisementPicker } from "src/components/AdvertisementPicker/AdvertisementPicker";
import { SourcesPicker } from "src/components/SourcesPicker/SourcesPicker";
import { PopperInfo } from "src/components/PopperInfo/PopperInfo";
import { MediaCenterFilterFormValues } from "./MediaCenterFilterForm.model";
import { MediaCenterTagsAutocomplete } from "../MediaCenterTagsAutocomplete/MediaCenterTagsAutocomplete";

export function MediaCenterFilterForm() {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { control } = useFormContext<MediaCenterFilterFormValues>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack pt={3}>
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Tag
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => {
              return (
                <MediaCenterTagsAutocomplete
                  onChange={field.onChange}
                  value={field.value}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {/* Sources */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography variant="subtitle1" mt={isMobile ? 0 : 2}>
            Source
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10}>
          <Controller
            name="sourcesInclude"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SourcesPicker
                  id="source-include-input"
                  label="All Sources"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Sources */}

      {/* Advertisement */}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            height={36.5}
          >
            Advertisement
            <Info
              onClick={handleClick}
              sx={{ ml: 1, color: colors.blueGrey[200], cursor: "pointer" }}
            />
            <PopperInfo
              open={open}
              anchorEl={anchorEl}
              title="Advertisement"
              text="The TVEyes platform introduces a robust and user-centric tool designed to enhance your experience in monitoring and analyzing advertising content across broadcast television and radio channels. This feature-rich popover provides versatile options for managing advertisements with a focus on convenience and customization."
              onClose={handleClose}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={5} display="flex" alignItems="center">
          <Controller
            name="advertisement"
            control={control}
            render={({ field }) => {
              return (
                <AdvertisementPicker
                  fullWidth
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {/* Advertisement */}
    </Stack>
  );
}
