import { useCallback, useState } from "react";
import {
  Dialog,
  Typography,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogProps,
  Stack,
} from "@mui/material";
import Assessment from "@mui/icons-material/Assessment";
import BookmarkAdd from "@mui/icons-material/BookmarkAdd";
import MpOutlined from "@mui/icons-material/MpOutlined";
import { isAvailableEventType } from "src/api/useClipSave";
import { SaveClipDialog } from "src/components/SaveClipDialog/SaveClipDialog";

// every commented line here is related to MMSR-2997
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { EventDetails } from "src/models/EventDetails";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { getEventDetailsMediaDownloadExtension } from "src/api/usePowerSearchResult.getters";
import { useSessionContext } from "src/api/useSessionContext";

type ClipViewerExportDialogMobileProps = Omit<DialogProps, "onClose"> & {
  onClose: () => void;
  handleDownload: () => void;
  event: EventDetails;
  createReport: () => void;
};

export const ClipViewerExportDialogMobile = ({
  open,
  event,
  onClose,
  createReport,
  handleDownload,
}: ClipViewerExportDialogMobileProps) => {
  const [openSaveClipDialog, setOpenSaveClipDialog] = useState(false);
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;
  // const downloadPdfMutation = useExportPdf({});
  // const downloadPdfEventItem = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: [event],
  //   });
  // }, [downloadPdfMutation, downloadFilesSnackBar, event]);

  const downloadCsvMutation = useExportCsv({});
  const downloadCsvEventItem = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: [event],
    });
  }, [downloadCsvMutation, downloadFilesSnackBar, event]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 319,
            py: 2,
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            px: 3,
          }}
        >
          Export
        </Typography>
        <List>
          <MenuItem
            onClick={() => {
              onClose();
              createReport();
            }}
          >
            <ListItemIcon>
              <Assessment fontSize="medium" color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>Сreate Report</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              handleDownload();
            }}
          >
            <ListItemIcon>
              <MpOutlined fontSize="medium" color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>
              Download {getEventDetailsMediaDownloadExtension(event)}
            </ListItemText>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              onClose();
              downloadPdfEventItem();
            }}
          >
            <ListItemIcon>
              <DownloadPDF />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>Download PDF</ListItemText>
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              onClose();
              downloadCsvEventItem();
            }}
          >
            <ListItemIcon>
              <DownloadCSV />
            </ListItemIcon>
            <ListItemText sx={{ ml: "4px" }}>Download CSV</ListItemText>
          </MenuItem>
          {event.eventType && isAvailableEventType(event.eventType) && (
            <MenuItem
              onClick={() => {
                onClose();
                setOpenSaveClipDialog(true);
              }}
              disabled={!enableMediaDownload}
            >
              <ListItemIcon>
                <BookmarkAdd fontSize="medium" color="primary" />
              </ListItemIcon>
              <ListItemText sx={{ ml: "4px" }}>
                Save To Media Center
              </ListItemText>
            </MenuItem>
          )}
        </List>
        <Stack mx={2}>
          <Button
            variant="outlined"
            fullWidth
            size="medium"
            color="inherit"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Dialog>
      <SaveClipDialog
        open={openSaveClipDialog}
        event={event}
        onClose={() => setOpenSaveClipDialog(false)}
      />
    </>
  );
};
