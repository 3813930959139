import { Typography, useTheme } from "@mui/material";
import { useIsMobile } from "src/utils/useIsMobile";
import versions from "../versions.json";

export const AppVersions = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const separator = isMobile ? <br /> : " - ";

  return (
    <Typography variant="subtitle2" color={palette.text.disabled} pt={1}>
      <span>
        UI Version {versions.app}
        {separator}
      </span>
      <span>
        Service Model Version {versions.services}
        {separator}
      </span>
      <span>Media Viewport {versions.player}</span>
    </Typography>
  );
};
