import { useCallback, useMemo } from "react";
import { useWatchTermSharedForm } from "src/api/useWatchTermSharedForm";
import { useWatchListCreateCustomForm } from "src/components/WatchListCreateCustomForm/WatchListCreateCustomForm.hook";
import { useWatchListCreateKeywordForm } from "src/components/WatchListCreateKeywordForm/WatchListCreateKeywordForm.hook";
import { WatchQueryKeywordFormSchema } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.schema";
import { WatchQueryCustomFormSchema } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.schema";
import { emptyWatchQueryKeywordFormValues } from "src/components/WatchQueryKeywordForm/WatchQueryKeywordForm.hook";
import { emptyWatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.hook";
import { useViewModeQueryParam } from "src/utils/useViewModeQueryParam";

export enum Tabs {
  simpleMode = "simpleMode",
  advancedMode = "advancedMode",
}

export const useWatchListCreateForms = () => {
  const [tabMode] = useViewModeQueryParam<Tabs>({
    paramKey: "tab",
    defaultValue: Tabs.simpleMode,
  });

  const keyWordFormHook = useWatchListCreateKeywordForm({
    schema: WatchQueryKeywordFormSchema,
  });

  const customQueryFormHook = useWatchListCreateCustomForm({
    schema: WatchQueryCustomFormSchema,
  });

  const formHook = useMemo(() => {
    return tabMode === Tabs.simpleMode ? keyWordFormHook : customQueryFormHook;
  }, [tabMode, keyWordFormHook, customQueryFormHook]);

  const { watch, formState, handleSubmit } = formHook;

  const { clearSharedValues } = useWatchTermSharedForm();

  const resetForm = useCallback(() => {
    if (tabMode === Tabs.simpleMode) {
      keyWordFormHook.reset(emptyWatchQueryKeywordFormValues);
    } else {
      customQueryFormHook.reset(emptyWatchQueryCustomFormValues);
    }
    clearSharedValues();
  }, [tabMode, keyWordFormHook, customQueryFormHook, clearSharedValues]);

  const resetCustomQueryValues = useCallback(() => {
    customQueryFormHook.resetField("customQuery", {
      defaultValue: emptyWatchQueryCustomFormValues.customQuery,
    });
    customQueryFormHook.resetField("displayName", {
      defaultValue: emptyWatchQueryCustomFormValues.displayName,
    });
  }, [customQueryFormHook]);

  return {
    keyWordFormHook,
    customQueryFormHook,
    formValues: watch(),
    formState,
    handleSubmit,
    resetForm,
    resetCustomQueryValues,
    isValid: formState.isValid,
  };
};
