import { useRef } from "react";
import { useWatchQueryThumbnail } from "src/api/useWatchQueryThumbnail";
import { useElementSize } from "src/utils/useElementSize";
import { WatchQueryThumbnailProps } from "./WatchQueryThumbnail.model";
import { ImageLoader } from "../ImageLoader/ImageLoader";

export const WatchQueryThumbnail = ({
  queryId,
  userId,
  ...props
}: WatchQueryThumbnailProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const size = useElementSize(ref);

  const { data, isFetching } = useWatchQueryThumbnail({
    options: {
      enabled: !!size,
    },
    params: {
      path: {
        queryId,
        userId,
      },
      query: {
        format: "png;6",
        width: size?.width && Math.ceil(size.width),
        height: size?.height && Math.ceil(size.height),
      },
    },
  });

  return (
    <ImageLoader innerRef={ref} src={data} loading={isFetching} {...props} />
  );
};
