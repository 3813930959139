import { useState } from "react";
import { TagPicker } from "../TagPicker/TagPicker";
import { WatchTermInputProps } from "./WatchTermInput.model";
import { WatchTermCreatePrompt } from "./WatchTermCreatePrompt";

export const WatchTermInput = ({
  value,
  onChange,
  onBlur,
  onInputChange,
  renderStartAdornment,
  displayTooltip,
  options,
  ...props
}: WatchTermInputProps) => {
  const [customOptions, setCustomOptions] = useState<string[]>([]);
  const optionList = [...customOptions, ...options];

  return (
    <TagPicker
      value={value}
      onChange={onChange}
      options={optionList}
      getOptionFromInput={(v) => v}
      dropDownLayout="column"
      scrollToLast
      onInputSubmit={({ option, isExisting }) => {
        if (!isExisting) {
          // add custom options to the beginning
          setCustomOptions((prev) => [option, ...prev]);
        }

        const i = value.list.indexOf(option);
        if (i < 0) {
          onChange({ ...value, list: [...value.list, option] });
        } else {
          const next = [...value.list];
          next.splice(i, 1);
          onChange({ ...value, list: next });
        }
      }}
      renderDropDownHeader={({ inputValue }) => {
        return <WatchTermCreatePrompt userInput={inputValue} />;
      }}
      {...props}
    />
  );
};
