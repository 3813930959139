import axios from "axios";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { AlertType } from "src/models/AlertType";
import { watchQueryKey } from "./useWatchQuery";
import { watchQueryListKey } from "./useWatchQueryList";
import { watchQueryListInfiniteKey } from "./useWatchQueryListInfinite";

const apiKey: keyof paths = "/api/user/query/{queryId}/alert/{alertId}";
const method: keyof paths[typeof apiKey] = "put";

type Endpoint = paths[typeof apiKey][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
  alert: AlertType;
};

type OriginType = "watchlist" | "edit" | "create";

type RequestParams = {
  params?: RequestMutationFnParams;
  options?: Pick<
    UseMutationOptions<Response, unknown, RequestMutationFnParams>,
    "onSuccess" | "onError" | "onSettled"
  > & {
    origin: OriginType;
  };
};

const getQueryKeyName = (origin: OriginType) => {
  switch (origin) {
    case "edit":
      return [watchQueryKey];
    case "create":
      return [watchQueryListKey];
    case "watchlist":
      return [watchQueryListKey, watchQueryListInfiniteKey];
  }
};

export function useWatchQueryAlertEdit({ options }: RequestParams) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiKey],
    mutationFn: async ({ params, alert }) => {
      const token = await getAccessTokenSilently();

      const url = makeApiUrl<Endpoint["parameters"]>(apiKey, params);

      const { data } = await axios<Response>({
        url,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: alert,
      });

      return data;
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar(
        `The Scheduled alert for the "${data.title}" Watch term was successfully changed.`
      );

      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar(`${error}`, { variant: "error" });

      options?.onError && options.onError(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      if (!options?.origin) return;
      const queryKeyName = getQueryKeyName(options?.origin);
      queryKeyName.forEach((keyName) => {
        queryClient.refetchQueries({
          queryKey: keyName,
        });
      });

      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}
