import { SvgIcon, SvgIconProps } from "@mui/material";

export const DateTimeSearch = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 20 22"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M17.3 17.9C17.7 17.2 18 16.4 18 15.5C18 13 16 11 13.5 11C11 11 9 13 9 15.5C9 18 11 20 13.5 20C14.4 20 15.2 19.7 15.9 19.3L18.6 22L20 20.6L17.3 17.9ZM13.5 18C12.1 18 11 16.9 11 15.5C11 14.1 12.1 13 13.5 13C14.9 13 16 14.1 16 15.5C16 16.9 14.9 18 13.5 18Z" />
        <path d="M3 0.00170898H5V2H13V0.00170898H15V2H16C17.1 2 18 2.9 18 4V9H16V6H2V18H7V20H2C0.9 20 0 19.1 0 18V4C0 2.9 0.9 2 2 2H3V0.00170898Z" />
      </svg>
    </SvgIcon>
  );
};
