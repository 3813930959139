import { SvgIcon, SvgIconProps } from "@mui/material";

export const SortDistanceDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M19.5 22L17 19.25L18.6667 19.25L18.6667 15L20.3333 15L20.3333 19.25L22 19.25L19.5 22Z"
          fill="currentColor"
        />
        <path
          d="M9 2C5.13 2 2 5.13 2 9C2 14.25 9 22 9 22C9 22 16 14.25 16 9C16 5.13 12.87 2 9 2ZM4 9C4 6.24 6.24 4 9 4C11.76 4 14 6.24 14 9C14 11.88 11.12 16.19 9 18.88C6.92 16.21 4 11.85 4 9Z"
          fill="currentColor"
        />
        <path
          d="M9 11.5C10.3807 11.5 11.5 10.3807 11.5 9C11.5 7.61929 10.3807 6.5 9 6.5C7.61929 6.5 6.5 7.61929 6.5 9C6.5 10.3807 7.61929 11.5 9 11.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
