import {
  Box,
  Button,
  ButtonGroup,
  alpha,
  useTheme,
  buttonClasses,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { ButtonSelectorProps } from "./ButtonSelector.model";

export function ButtonSelector<T extends string | null>({
  value,
  options,
  onChange,
  getOptionLabel,
  isOptionEqual = (a, b) => a === b,
  ...props
}: ButtonSelectorProps<T>) {
  const { palette, transitions } = useTheme();

  return (
    <ButtonGroup {...props}>
      {options.map((o) => (
        <Button
          key={o}
          sx={{
            flex: 1,
            px: 4,
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            background: isOptionEqual(o, value)
              ? alpha(palette.primary.main, 0.04)
              : undefined,
            borderColor: isOptionEqual(o, value)
              ? palette.info.dark
              : undefined,
            [`.${buttonClasses.startIcon}`]: {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
          onClick={() => onChange(o)}
          startIcon={
            <CheckIcon
              sx={{
                width: isOptionEqual(o, value) ? undefined : 0,
                transition: transitions.create(["width"], {
                  duration: 500,
                }),
              }}
            />
          }
        >
          <Box
            sx={{
              marginLeft: isOptionEqual(o, value) ? "10px" : 0,
              transition: transitions.create("margin-left", {
                duration: 500,
              }),
            }}
          >
            {getOptionLabel ? getOptionLabel(o) : o}
          </Box>
        </Button>
      ))}
    </ButtonGroup>
  );
}
