import { Box, Fade } from "@mui/material";
import { Spinner, SpinnerProps } from "../Spinner/Spinner";

export function LoadingOverlay(props: SpinnerProps) {
  return (
    <Fade in>
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent={"center"}
      >
        <Spinner size={80} {...props} />
      </Box>
    </Fade>
  );
}
