import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import {
  PushNotificationEmptyState,
  PushNotificationState,
  usePushNotificationState,
} from "./hooks/usePushNotificationState";
import { usePushNotificationSignalRConnection } from "./hooks/usePushNotificationSignalRConnection";

const PushNotificationContext = createContext<PushNotificationState>(
  PushNotificationEmptyState
);

export function PushNotificationProvider({ children }: PropsWithChildren<{}>) {
  const connection = usePushNotificationSignalRConnection({});
  const context = usePushNotificationState({ connection });

  // Auto-start connection
  const { connect } = context;
  useEffect(() => {
    connect();
  }, [connect]);

  return (
    <PushNotificationContext.Provider value={context} children={children} />
  );
}

export function usePushNotificationContext() {
  return useContext(PushNotificationContext);
}
