import { addMilliseconds, format } from "date-fns";
/**
 * FIXME: this purpose of this entire file is to provide a value getters for badly typed
 * media center result. We may get rid of this once types are stabilized.
 */
import { MediaCenterItem } from "./useMediaCenter";

export function getMediaCenterResultId(value: MediaCenterItem) {
  return value.id;
}

export function getMediaCenterResultEvent(value: MediaCenterItem) {
  return value.title;
}

export function getMediaCenterResultEventType(value: MediaCenterItem) {
  return value?.eventHighlights?.eventType || " - ";
}

export function getMediaCenterResultMediaType(value: MediaCenterItem) {
  return value.mediaType || " - ";
}

export function getMediaCenterResultFormat(value: MediaCenterItem) {
  return value?.itemType?.toLocaleUpperCase() || " - ";
}
export function getMediaCenterResultDuration(value: MediaCenterItem) {
  const helperDate = addMilliseconds(new Date(0), value?.durationMs);
  const formattedDuration = format(helperDate, "mm:ss");
  return formattedDuration || " - ";
}

export function getMediaCenterResultTranscript(value: MediaCenterItem) {
  return (
    value?.eventHighlights?.highlights?.map(({ text }) => text).join(", ") ||
    " - "
  );
}

export function getMediaCenterResultIndividualTranscript(
  value: MediaCenterItem,
  idx: number
) {
  if (!value?.eventHighlights?.highlights) return " - ";

  const hightlightValues = value?.eventHighlights?.highlights;
  return hightlightValues[idx]?.text || " - ";
}

export function getMediaCenterResultDateTime(value: MediaCenterItem) {
  const startDateTime = value?.eventHighlights?.startDateTime
    ? format(new Date(value?.eventHighlights?.startDateTime), "PP pp")
    : " - ";

  return startDateTime;
}

export function getMediaCenterResultCountry(value: MediaCenterItem) {
  return value?.eventHighlights?.country?.toLocaleLowerCase() || " - ";
}

export function getMediaCenterResultMarket(value: MediaCenterItem) {
  return value?.eventHighlights?.market || " - ";
}

export function getMediaCenterResultState(value: MediaCenterItem) {
  return "FIXME: state is unmapped";
}

export function getMediaCenterResultStation(value: MediaCenterItem) {
  return "FIXME: station is unmapped";
}

export function getMediaCenterResultViews(value: MediaCenterItem) {
  return value?.eventHighlights?.viewership?.toLocaleString() || " - ";
}

export function getMediaCenterResultCategory(value: MediaCenterItem) {
  return value?.eventHighlights?.category || " - ";
}

export function getMediaCenterResultSource(value: MediaCenterItem) {
  return value?.eventHighlights?.source || " - ";
}

export function getMediaCenterResultLanguage(value: MediaCenterItem): string {
  return value?.eventHighlights?.language || " - ";
}
