import { Fragment, forwardRef } from "react";
import { IconButton } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { useForwardRef } from "src/utils/useForwardRef";
import { IconButtonDropDownProps } from "./IconButtonDropDown.model";

export const IconButtonDropDown = forwardRef<
  HTMLButtonElement,
  IconButtonDropDownProps
>(({ renderDropDown: renderMenu, children, ...props }, forwardRef) => {
  const menuState = useOpenState();
  const ref = useForwardRef(forwardRef);

  return (
    <Fragment>
      <IconButton onClick={menuState.show} ref={ref} {...props}>
        {children}
      </IconButton>
      {renderMenu(
        {
          open: menuState.isOpen,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "top" },
          anchorEl: ref.current,
          onClose: menuState.hide,
        },
        menuState.hide
      )}
    </Fragment>
  );
});
