import { useEffect, useRef, useState } from "react";
import { Add, ExpandCircleDown } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  ClickAwayListener,
  Fab,
  Fade,
  Stack,
  SxProps,
} from "@mui/material";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useTagListData } from "src/api/useTagListData";
import { useMediaCenterTagsDelete } from "src/api/useMediaCenterTagsDelete";
import { useOpenedSelector } from "src/api/useOpenedSelector";
import { useElementSize } from "src/utils/useElementSize";
import { AssignNewTagModal } from "../AssignNewTagModal/AssignNewTagModal";

type MediaCenterTagsControlsProps = {
  event: MediaCenterItem;
  tagsDisplayingMode: "direct" | "reverse";
  sx?: SxProps;
  setTagContainerSize?: (v: number) => void;
};

const tagWithMargin = 32 + 16;
const defaultSpace = 8;

export const MediaCenterTagsControls = ({
  event,
  tagsDisplayingMode,
  setTagContainerSize,
  sx,
}: MediaCenterTagsControlsProps) => {
  const tagContainerRef = useRef(null);

  const [showTagModal, setShowTagModal] = useState(false);
  const openedSelector = useOpenedSelector();

  const tagDelete = useMediaCenterTagsDelete({});

  const { tags } = event;

  const tagsListData = useTagListData();

  const handleTagCollapse = () => {
    const updatedValue =
      openedSelector.activeSelector !== event.id ? event.id : null;
    openedSelector.select(updatedValue);
  };

  const handleTagRemove = (tagName: string) => {
    tagDelete.mutate({
      path: {
        itemid: event.id,
      },
      body: [tagName],
    });
  };

  const lastActiveTagData = tags
    ? tagsListData.tags.find(
        (tagData) => tagData.tagName === tags[tags.length - 1]
      )
    : { tagName: undefined, color: undefined };

  const tagsData = tags || [];

  const size = useElementSize(tagContainerRef);

  useEffect(() => {
    if (!size || !setTagContainerSize) return;
    const requiredWidth = size.width + 5 * defaultSpace;
    setTagContainerSize(requiredWidth);
  }, [size, setTagContainerSize]);

  return (
    <Stack
      ref={tagContainerRef}
      zIndex={openedSelector.activeSelector === event.id ? 3 : 2}
      flexDirection="row"
      gap={2}
      sx={sx}
    >
      <ClickAwayListener
        touchEvent={false}
        onClickAway={() => {
          openedSelector.select(null);
        }}
      >
        <Stack alignItems="end">
          {tags && tags.length > 0 && (
            <Chip
              sx={{
                maxWidth: 128,
                backgroundColor: lastActiveTagData?.color,
                "&:hover": {
                  backgroundColor: lastActiveTagData?.color,
                },
                position: "relative",
                color: "white",
              }}
              deleteIcon={
                <ExpandCircleDown
                  sx={{
                    rotate:
                      openedSelector.activeSelector === event.id
                        ? "180deg"
                        : undefined,
                  }}
                />
              }
              onClick={handleTagCollapse}
              onDelete={handleTagCollapse}
              avatar={
                <Avatar
                  sx={{
                    color: "white !important",
                    backgroundColor: `${lastActiveTagData?.color} !important`,
                    filter: "brightness(0.85)",
                  }}
                >
                  {tags.length}
                </Avatar>
              }
              label={[tags[tags.length - 1]]}
            />
          )}

          <Fade
            in={openedSelector.activeSelector === event.id}
            timeout={openedSelector.activeSelector === event.id ? 300 : 250}
          >
            <Stack
              sx={
                tagsDisplayingMode === "direct"
                  ? undefined
                  : {
                      position: "absolute",
                      top: `-${tagsData.length * tagWithMargin + 16}px`,
                    }
              }
            >
              <Stack
                alignItems="end"
                flexDirection={
                  tagsDisplayingMode === "direct" ? "column" : "column-reverse"
                }
              >
                {tagsData.map((tag) => {
                  const tagListData = tagsListData.tags.find(
                    (tagData) => tagData.tagName === tag
                  );
                  return (
                    <Chip
                      key={tag}
                      sx={{
                        mt: 2,
                        backgroundColor: tagListData?.color,
                        "&:hover": {
                          backgroundColor: tagListData?.color,
                        },
                        color: "white",
                      }}
                      onDelete={() => handleTagRemove(tag)}
                      label={tag}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Fade>
        </Stack>
      </ClickAwayListener>

      <Fab
        sx={{
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "white",
          },
          minHeight: "32px !important",
        }}
        aria-label="add tag"
        size="small"
        onClick={() => setShowTagModal(true)}
      >
        <Add />
      </Fab>

      <AssignNewTagModal
        value={event}
        open={showTagModal}
        onClose={() => setShowTagModal(false)}
      />
    </Stack>
  );
};
