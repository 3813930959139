import { StatusConfig } from "./AccountsManagementConvertForm.model";

export const STATUS_CONFIG: StatusConfig = {
  "Active Trial Account": {
    isTrial: true,
    isActive: true,
    isPaying: false,
  },
  "Active Paying Account": {
    isTrial: false,
    isActive: true,
    isPaying: true,
  },
  "Active Non-Paying Account": {
    isTrial: false,
    isActive: true,
    isPaying: false,
  },
  "Disabled Paying Account": {
    isTrial: false,
    isActive: false,
    isPaying: true,
  },
  "Disabled Trial Account": {
    isTrial: true,
    isActive: false,
    isPaying: false,
  },
  "Disabled Account": {
    isTrial: false,
    isActive: false,
    isPaying: false,
  },
} as const;
