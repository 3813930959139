import { Stack, Typography, useTheme } from "@mui/material";
import { ViewMode } from "src/components/ViewModeSwitch/ViewModeSwitch.model";
import { MarketSnapshot, StationSnapshot } from "src/api/useSnapshot";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { TileLayoutProps } from "src/components/TileLayout/TileLayout.model";
import { useIsFullScreen } from "src/utils/useIsFullScreen";
import {
  snapshotTileGroupClasses,
  snapshotTileGroupConstants,
} from "./SnapshotTileGroup.const";

export function SnapshotTileGroup({
  value,
  viewMode,
  headerHeight = snapshotTileGroupConstants.defaultHeaderHeight,
  renderItem,
}: Omit<TileLayoutProps<StationSnapshot>, "rows"> & {
  value: MarketSnapshot;
  viewMode: ViewMode;
  headerHeight?: number;
  isLoading?: boolean;
  isFullScreen?: boolean;
}) {
  const { palette } = useTheme();
  const isFullScreen = useIsFullScreen();

  return (
    <Stack className={snapshotTileGroupClasses.root}>
      <Stack
        position="sticky"
        top={0}
        width="100%"
        zIndex={snapshotTileGroupConstants.headerZIndex}
        bgcolor={palette.background.default}
        boxShadow={`0px -1px 0px 0px ${palette.divider} inset`}
        minHeight={headerHeight}
        maxHeight={headerHeight}
        justifyContent="center"
        className={snapshotTileGroupClasses.header}
      >
        <Typography
          px={2}
          zIndex={1}
          variant="subtitle1"
          children={value.marketInfo?.name}
        />
      </Stack>
      <Stack
        className={snapshotTileGroupClasses.content}
        sx={{
          pl: isFullScreen
            ? 7.25
            : viewMode === ViewMode.smallTiles
            ? 1.5
            : 2.0,
          pr: isFullScreen
            ? 6.75
            : viewMode === ViewMode.smallTiles
            ? 0.5
            : 1.0,
          py: 2,
        }}
      >
        <TileLayout<StationSnapshot>
          minTileWidth={viewMode === ViewMode.smallTiles ? 275 : 335}
          rows={value?.stationSnapshots || []}
          renderItem={renderItem}
        />
      </Stack>
    </Stack>
  );
}
