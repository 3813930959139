import { ToggleButtonGroupProps } from "@mui/material";

export enum ViewMode {
  table = "table",
  list = "list",
  tile = "tile",
  portrait = "portrait",
  book = "book",
  landscape = "landscape",
  smallTiles = "smallTiles",
  largeTiles = "largeTiles",
}

export type ViewModeSwitchProps = {
  value: ViewMode;
  onChange: (nextMode: ViewMode) => void;
  options: ViewMode[];
  size?: ToggleButtonGroupProps["size"];
};
