import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { AppRouteBuilder } from "src/models/AppRoute";

export function useRouteLeave({
  route,
  callback,
}: {
  route: AppRouteBuilder<any, any>;
  callback: () => void;
}) {
  const { pathname } = useLocation();
  const match = useMatch(route.path);

  useEffect(() => {
    // detect route of interest was entered and install cleanup function
    if (match?.pattern.path === route.path) {
      return callback;
    }
  }, [pathname, match, callback, route]);
}
