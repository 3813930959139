import { create } from "zustand";
import { useIsMobile } from "./useIsMobile";
import { useLocalStorageFlags } from "./useLocalStorageFlags";
import { useEffect, useMemo } from "react";

type DrawerState = {
  key: "mobile" | "desktop";
  isOpen: boolean;
  show: () => void;
  hide: () => void;
};

const useDrawerMobileState = create<DrawerState>((set) => ({
  key: "mobile",
  isOpen: false,
  show: () => set({ isOpen: true }),
  hide: () => set({ isOpen: false }),
}));

const useDrawerDesktopState = () => {
  const { drawerState, write } = useLocalStorageFlags();

  // create memoised store
  const useStore = useMemo(
    () =>
      create<DrawerState>((set) => ({
        key: "desktop",
        isOpen: drawerState,
        show: () => set({ isOpen: true }),
        hide: () => set({ isOpen: false }),
      })),
    [drawerState]
  );

  // persist drawer state changes to the local storage
  const { isOpen } = useStore();
  useEffect(() => {
    write("drawerState", isOpen);
  }, [write, isOpen]);

  return useStore();
};

export const useDrawer = (): DrawerState => {
  const isMobile = useIsMobile();
  const drawerMobileState = useDrawerMobileState();
  const drawerDesktopState = useDrawerDesktopState();
  const drawerState = isMobile ? drawerMobileState : drawerDesktopState;

  return drawerState;
};
