import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { format } from "date-fns";
import {
  getFullPublicEventLink,
  getFullShareEventsLink,
} from "src/utils/getFullPublicPageLink";
import { DateTimeSearchResultItem } from "src/api/useDateTimeSearch";
import { useReportNotify } from "src/api/useReportNotify";
import { useReportCreateFromDateTime } from "src/api/useReportCreateFromDateTime";
import { ReportItem } from "src/models/Report";
import { DialogBaseProps } from "src/components/DialogBase/DialogBase.model";
import { ShareFormValues } from "src/components/ShareForm/ShareForm.model";
import { ShareFormSchema } from "src/components/ShareForm/ShareForm.schema";
import { ShareBaseDialog } from "src/components/ShareBaseDialog/ShareBaseDialog";

type ShareDateTimeSearchDialogProps = Omit<
  DialogBaseProps,
  "title" | "onSubmit" | "onClose"
> & {
  items: DateTimeSearchResultItem[];
  onSubmit: () => void;
  onClose: () => void;
  copyShareLinkCallback: () => void;
};

const defaultValues = {
  recipients: [],
  subject: "",
  note: "",
};

export const ShareDateTimeSearchDialog = ({
  items,
  onClose,
  onSubmit,
  copyShareLinkCallback,
  ...props
}: ShareDateTimeSearchDialogProps) => {
  const formHook = useForm<ShareFormValues>({
    mode: "all",
    resolver: joiResolver(ShareFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;
  const formValues = formHook.watch();

  const reportNotifyMutation = useReportNotify({});

  const shareDateTimeSearchMutation = useReportCreateFromDateTime({
    options: {
      onSuccess: (data: ReportItem) => {
        if (!data?.id) return;
        const { recipients, subject, note } = formValues;
        if (!recipients.length) return;
        reportNotifyMutation.mutate({
          params: {
            path: {
              id: data.id,
            },
          },
          recipients,
          subject,
          message: note,
        });
      },
    },
  });

  const title = `${format(new Date(), "MM/d/yy")} - Shared 1 event`;

  const shareDateTimeSearchItem = (notify: boolean) => {
    shareDateTimeSearchMutation.mutate({
      title,
      selectedItems: items,
      archive: false,
      notify,
    });
  };

  const instantLinkValue = useMemo(() => {
    const reportId = shareDateTimeSearchMutation.data?.id;

    if (reportId) {
      if (items.length === 1) {
        return getFullPublicEventLink(reportId, items[0].id as string);
      }
      return getFullShareEventsLink(reportId);
    }
    return "";
  }, [shareDateTimeSearchMutation.data?.id, items]);

  const handleSubmitWrapper = handleSubmit(() => {
    shareDateTimeSearchItem(true);
    onClose();
    onSubmit();
  });

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open]);

  return (
    <FormProvider {...formHook}>
      <ShareBaseDialog
        instantLinkValue={instantLinkValue}
        getInstantLink={() => shareDateTimeSearchItem(false)}
        isLoadingLink={shareDateTimeSearchMutation.isLoading}
        copyShareLinkCallback={copyShareLinkCallback}
        onClose={onClose}
        isBusy={!formState.isValid || !formState.dirtyFields?.recipients}
        handleSubmit={handleSubmitWrapper}
        {...props}
      />
    </FormProvider>
  );
};
