import axios from "axios";
import { useSnackbar } from "notistack";
import { UseMutationOptions, useMutation } from "react-query";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { useDateTimeSearchResultCache } from "src/api/useDateTimeSearchResultCache";

const apiKey: keyof paths = "/api/Event/from-date-time-block";
const method: keyof paths[typeof apiKey] = "post";

type Endpoint = paths[typeof apiKey][typeof method];

type RequestParams = {
  body: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
};

export type Response = Required<components["schemas"]["EventHighlightsDTO"]>;

type UseDateTimeSearchDetails = {
  options?: Omit<
    UseMutationOptions<Response, unknown, RequestParams>,
    "mutationFn"
  >;
};

export function useDateTimeSearchDetails(params?: UseDateTimeSearchDetails) {
  const cache = useDateTimeSearchResultCache();
  const snackbar = useSnackbar();

  const { data, isLoading, ...mutation } = useMutation<
    Response,
    unknown,
    RequestParams
  >({
    ...params?.options,
    mutationFn: async ({ body }: RequestParams) => {
      const url = makeApiUrl(apiKey);
      const { data } = await axios<Response>({
        url,
        method,
        data: body,
      });

      return data;
    },

    onMutate(variables) {
      cache.update({ data: null, isLoading: true });
    },

    onSuccess(data, variables, context) {
      cache.update({ data, isLoading: false });
      params?.options?.onSuccess?.(data, variables, context);
    },
    onError(err, variables, context) {
      snackbar.enqueueSnackbar({
        variant: "error",
        message: err instanceof Error ? err.message : `${err}`,
      });

      cache.clear();
      params?.options?.onError?.(err, variables, context);
    },
  });

  const state = {
    data: data || cache.state.data,
    isLoading: isLoading || cache.state.isLoading,
    ...mutation,
  };

  return state;
}
