import { useTheme } from "@mui/material";

export const scrollbarSize = {
  normal: 8,
  thin: 4,
  hidden: 0,
} as const;

export function useCustomScrollBarStyles(args?: {
  overflowY: "scroll" | "visible" | "hidden";
  overflowX: "scroll" | "visible" | "hidden";
  size: "normal" | "thin" | "hidden";
  scrollbarColor?: string;
}) {
  const { palette } = useTheme();
  const {
    overflowY,
    overflowX,
    size = "normal",
    scrollbarColor = "transparent",
  } = args || {};

  const scrollbarWidth = scrollbarSize[size];

  const scrollBarSx = {
    // this required to keep scrollbars visible to reserve space
    overflowY: overflowY === "scroll" ? "scroll" : overflowY,
    overflowX: overflowX === "scroll" ? "scroll" : overflowX,

    "&::-webkit-scrollbar": {
      width: overflowY === "scroll" ? scrollbarWidth : 0,
      height: overflowX === "scroll" ? scrollbarWidth : 0,
      background: scrollbarColor,
      display: size === "hidden" ? "none" : undefined,
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: scrollbarWidth / 2,
      borderStyle: "solid",
      borderWidth: size === "normal" ? 2 : 1,
      borderColor: "transparent",
    },

    "&:hover": {
      // Hack: below is required for Safari, otherwise hover styles below do not do anything
      // using detection code from here: https://jsbin.com/yoqecol/edit?css,output
      "@supports (background: -webkit-named-image(i))": {
        transform: "translateZ(0px);" /* force GPU rendering */,
      },

      "::-webkit-scrollbar-thumb": {
        background: palette.action.disabled,
      },

      "::-webkit-scrollbar-thumb:hover": {
        background: palette.action.active,
      },
    },
  } as const;

  return scrollBarSx;
}
