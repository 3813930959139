import { create } from "zustand";
import { components } from "@tveyes/twosionwebapischema";

type EventHighlightsDTO = Required<components["schemas"]["EventHighlightsDTO"]>;

type State = {
  data: EventHighlightsDTO | null;
  isLoading: boolean;
};

interface UseDateTimeSearchResultCache {
  state: State;
  update: (state: State) => void;
  clear: () => void;
}

export const useDateTimeSearchResultCache =
  create<UseDateTimeSearchResultCache>((set, get) => ({
    state: {
      data: null,
      isLoading: false,
    },
    clear: () =>
      set({
        state: {
          data: null,
          isLoading: false,
        },
      }),
    update: (changes) =>
      set({
        state: {
          ...get().state,
          ...changes,
        },
      }),
  }));
