import { add, format, isBefore, isValid, sub } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  IconButton,
  Stack,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { useCallback } from "react";

export function DateTimeSearchRangePager({
  value,
  timeZone,
  bounds,
  maxDate,
  onChange,
  dateTimeFormat,
}: {
  value?: Date | null;
  timeZone?: string;
  bounds: [number, number];
  maxDate: Date;
  onChange: (value: Date) => void;
  dateTimeFormat?: string;
}) {
  const { palette, breakpoints } = useTheme();
  const isShortDate = useMediaQuery(breakpoints.down("md"));

  const range: [Date, Date] | null = value
    ? [sub(value, { minutes: 10 }), add(value, { minutes: 10 })]
    : null;

  const dateFormat = dateTimeFormat
    ? dateTimeFormat
    : isShortDate
    ? "pp"
    : "PP pp";
  const t0Label =
    range && timeZone
      ? `${format(utcToZonedTime(range[0], timeZone), dateFormat)}`
      : null;

  const t1Label =
    range && timeZone
      ? `${format(utcToZonedTime(range[1], timeZone), dateFormat)}`
      : null;

  const label = t0Label && t1Label ? `${t0Label} - ${t1Label}` : " - ";

  const onPrevWrapper = useCallback(() => {
    if (!value || !isValid(value)) {
      return;
    }

    const nextValue = add(value, { minutes: bounds[0] });
    onChange(nextValue);
  }, [value, bounds, onChange]);

  const onNextWrapper = useCallback(() => {
    if (!value || !isValid(value)) {
      return;
    }

    const nextValue = add(value, { minutes: bounds[1] });

    if (isBefore(nextValue, maxDate)) {
      onChange(nextValue);
    } else {
      onChange(maxDate);
    }
  }, [value, bounds, maxDate, onChange]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        backgroundColor: alpha(palette.action.selected, 0.04),
        borderRadius: 1,
        border: `1px solid ${alpha(palette.action.selected, 0.12)}`,
      }}
    >
      <IconButton onClick={onPrevWrapper}>
        <ChevronLeft />
      </IconButton>
      <TextLineClamp
        variant="subtitle2"
        align="center"
        justifyContent="center"
        flex={1}
        lineClamp={1}
      >
        {label}
      </TextLineClamp>
      <IconButton onClick={onNextWrapper}>
        <ChevronRight />
      </IconButton>
    </Stack>
  );
}
