import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import { ReportsNoFilteredEventsOverlay } from "src/components/NoResultsOverlay/NoResultsOverlay";
import { PreselectedEventTile } from "./PreselectedEventTile";
import { PreselectedEventTilesProps } from "./PreselectedEventTiles.model";

export function PreselectedEventTiles({
  id,
  rows,
  lineClamp,
  renderHeader,
  reportScratchEventsTiles,
}: PreselectedEventTilesProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const eventsSelectedForReports = useEventsSelectedForReports();
  const isIOS = window?.navigator?.platform === "iPhone";

  const handleScroll = useScrollQueryLoader({
    query: reportScratchEventsTiles,
  });

  const renderItem = (data: PowerSearchResultItem) => {
    let isChecked = false;
    if (eventsSelectedForReports.areAllPagesSelected) {
      isChecked = !eventsSelectedForReports.excludeEvents.find(
        (event) => event.id === data.id
      );
    } else {
      isChecked = !!eventsSelectedForReports.events.find(
        (event) => event.id === data.id
      );
    }
    return (
      <PreselectedEventTile
        key={data.id}
        value={data}
        lineClamp={lineClamp}
        isChecked={isChecked}
      />
    );
  };

  return (
    <ScrollHeaderLayout
      id={id}
      headerMinHeight={70}
      onScroll={handleScroll}
      renderHeader={renderHeader}
      isLoading={
        reportScratchEventsTiles.isLoading ||
        reportScratchEventsTiles.isFetching
      }
      children={
        <Stack
          pl={2}
          pr={isIOS ? 2 : 1}
          pb={1}
          flex={1}
          children={
            <TileLayout<PowerSearchResultItem>
              rows={rows}
              renderItem={renderItem}
              renderEmpty={ReportsNoFilteredEventsOverlay}
              gap={isMobile ? 3 : undefined}
            />
          }
        />
      }
    />
  );
}
