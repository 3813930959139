import { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { TranscriptText } from "src/components/TranscriptText/TranscriptText";
import {
  getMediaCenterResultCountry,
  getMediaCenterResultDateTime,
  getMediaCenterResultEvent,
  getMediaCenterResultEventType,
  getMediaCenterResultSource,
  getMediaCenterResultLanguage,
  getMediaCenterResultCategory,
  getMediaCenterResultMarket,
  getMediaCenterResultFormat,
  getMediaCenterResultDuration,
  getMediaCenterResultId,
  getMediaCenterResultMediaType,
  getMediaCenterResultIndividualTranscript,
} from "src/api/useMediaCenter.getters";
import { mediaCenterClipRoute } from "src/pages/MediaCenterClip/MediaCenterClip.route";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { getEventIcon, getEventTooltipTitle } from "src/utils/getEventIcon";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { ClipViewer } from "src/components/ClipViewer/ClipViewer";
import { AppLink } from "src/components/AppLink/AppLink";
import { useEventSelectionMediaCenter } from "src/api/useEventSelectionMediaCenter";
import { useSessionContext } from "src/api/useSessionContext";
import { useMediaCenterThumbnail } from "src/api/useMediaCenterThumbnail";
import { EventType } from "src/models/EventType";
import { MediaCenterActions } from "../MediaCenterActions/MediaCenterActions";
import { MediaCenterTagsControls } from "../MediaCenterTagsControls/MediaCenterTagsControls";
import { getEventData } from "../MediaCenterTiles/MediaCenterTile";

export function MediaCenterListItem({
  value,
  isChecked,
  downloadMediaItem,
  addItemToReport,
}: {
  value: MediaCenterItem;
  isChecked: boolean;
  downloadMediaItem: (value: MediaCenterItem) => void;
  addItemToReport: (value: MediaCenterItem) => void;
}) {
  const { palette, spacing } = useTheme();

  const [activeMention, setActiveMention] = useState(0);

  const { userId } = useSessionContext();
  const thumbnail = useMediaCenterThumbnail({
    params: {
      path: {
        itemid: value.id,
      },
    },
  });

  const eventType = getMediaCenterResultEventType(value);
  const mediaType = getMediaCenterResultMediaType(value);
  const Icon = getEventIcon(eventType, mediaType);
  const tooltipTitle = getEventTooltipTitle(eventType, mediaType);

  const eventTitle = getMediaCenterResultEvent(value);
  const detailsUrl = mediaCenterClipRoute.makeUrl({
    eventId: getMediaCenterResultId(value),
  });
  const selectedEventsState = useEventSelectionMediaCenter();

  const [tagsDisplayingMode, setTagsDisplayingMode] = useState<
    "direct" | "reverse"
  >("direct");
  const [tagContainerWidth, setTagContainerWidth] = useState(200);

  const anchorRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!anchorRef || !anchorRef.current) return;
    const rect = anchorRef.current.getBoundingClientRect();
    const { top } = rect;

    const { innerHeight } = window;

    const bottomSpace = innerHeight - top;
    const maxSpace = Math.max(bottomSpace, top);

    const nextTagDisplayMode = maxSpace === top ? "reverse" : "direct";
    if (tagsDisplayingMode === nextTagDisplayMode) return;

    setTagsDisplayingMode(nextTagDisplayMode);
  }, [anchorRef, value, tagsDisplayingMode]);

  const handleChange = (checked: boolean) => {
    if (checked) {
      selectedEventsState.select(value);
    } else {
      selectedEventsState.unselect(value);
    }
  };

  return (
    <ListBaseItem
      key={value.id}
      innerRef={anchorRef}
      sx={{
        maxHeight: 208,
        minHeight: 208,
        alignItems: "stretch",
        columnGap: 2,
      }}
    >
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        border={`1px solid ${palette.action.disabledBackground}`}
        width={215}
      >
        <ClipViewer
          id={value.id}
          onMentionChange={setActiveMention}
          event={
            getEventData(
              value,
              thumbnail.data,
              `/api/mediacenter/${userId}/items/${value.id}/media`
            ) || {}
          }
          viewMode="compact"
        />
        <TileCheckBox value={isChecked} onChange={handleChange} zIndex={3} />
      </Box>

      <Stack flex={1} justifyContent="space-between" overflow="hidden">
        {/* header card */}
        <Stack
          direction="row"
          width={`calc(100% - ${tagContainerWidth}px)`}
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title={tooltipTitle}>
              <Stack m="auto">
                <Icon />
              </Stack>
            </Tooltip>
            <Tooltip title={eventTitle}>
              <AppLink to={detailsUrl}>
                <TextLineClamp
                  variant="subtitle1"
                  color="primary"
                  lineClamp={1}
                  height={20}
                >
                  {eventTitle}
                </TextLineClamp>
              </AppLink>
            </Tooltip>
          </Stack>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            minWidth="fit-content"
          >
            <DateRange sx={{ color: palette.action.active }} />
            <TextLineClamp variant="body2" lineClamp={1}>
              {getMediaCenterResultDateTime(value).toLocaleString()}
            </TextLineClamp>
          </Stack>
        </Stack>

        {/* attributes */}
        <Stack
          direction="row"
          columnGap={2}
          justifyContent="space-between"
          maxWidth="100%"
          overflow="hidden"
        >
          <KeyValue
            name="Type"
            value={getEventTooltipTitle(eventType, mediaType)}
          />
          <KeyValue name="Source" value={getMediaCenterResultSource(value)} />
          <KeyValue name="Country" value={getMediaCenterResultCountry(value)} />

          {eventType === EventType.PCast && (
            <KeyValue
              name="Language"
              value={getMediaCenterResultLanguage(value)}
            />
          )}
          {eventType === EventType.PCast && (
            <KeyValue
              name="Category"
              value={getMediaCenterResultCategory(value)}
            />
          )}

          {eventType === EventType.BCast && (
            <KeyValue name="Market" value={getMediaCenterResultMarket(value)} />
          )}

          <KeyValue
            name="Duration"
            value={getMediaCenterResultDuration(value)}
          />
          <KeyValue name="Format" value={getMediaCenterResultFormat(value)} />
        </Stack>

        <TranscriptBlock px={2} py={1} borderRadius={1}>
          <TranscriptText
            transcript={getMediaCenterResultIndividualTranscript(
              value,
              activeMention
            )}
            lineClamp={3}
          />
        </TranscriptBlock>
      </Stack>
      <ListItemSecondaryAction
        sx={{ top: spacing(1), right: 0, transform: "none" }}
      >
        <MediaCenterTagsControls
          event={value}
          tagsDisplayingMode={tagsDisplayingMode}
          setTagContainerSize={setTagContainerWidth}
          sx={{
            position: "absolute",
            right: 48,
            top: 4,
          }}
        />
        <MediaCenterActions
          value={value}
          downloadMediaItem={downloadMediaItem}
          addItemToReport={addItemToReport}
        />
      </ListItemSecondaryAction>
    </ListBaseItem>
  );
}
