import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material";
import { TextInputBase, TextInputBaseProps } from "../TextInputBase";

export type TextFieldDebouncedProps = {
  delay?: number;
  onTextChangeThrottled?: (value: string) => void;
  value: string;
} & TextInputBaseProps;

export const TextFieldDebounced = ({
  delay = 300,
  onTextChangeThrottled,
  value,
  onChange,
  ...props
}: TextFieldDebouncedProps) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [debouncedValue] = useDebounce(inputValue, delay);

  useEffect(() => {
    if (value !== undefined && value !== inputValue) {
      setInputValue(value);
    }
  }, [value, inputValue]);

  useEffect(() => {
    if (onTextChangeThrottled) {
      onTextChangeThrottled(debouncedValue);
    }
  }, [debouncedValue, onTextChangeThrottled]);

  const onChangeWrapper: TextFieldProps["onChange"] = (e) => {
    setInputValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <TextInputBase {...props} value={inputValue} onChange={onChangeWrapper} />
  );
};
