import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDebounce } from "use-debounce";
import { queryAdvertisementValues } from "src/models/Advertisement";
import { useValidateCustomQueryBasic } from "src/api/useValidateCustomQueryBasic";
import { useCustomQueryValidationData } from "src/api/useCustomQueryValidationData";
import { WatchQueryFormValues } from "./WatchQueryForm.model";

/**
 * @deprecated
 */
export const emptyFormValues: WatchQueryFormValues = {
  customQuery: "",
  displayName: "",
  termsInclude: {
    list: [],
    logic: "or",
  },
  termsExclude: {
    list: [],
    logic: "or",
  },
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type UseWatchQueryFormArgs = {
  initial?: WatchQueryFormValues;
  schema: Joi.Schema;
};
/**
 * This was replaced with ../WatchQueryKeywordForm/WatchQueryKeywordForm.hook.ts
 * and ../WatchQueryCustomForm/WatchQueryCustomForm.hook.ts
 * Please, remove this when it's not needed anymore
 * @deprecated
 */

export function useWatchQueryForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<WatchQueryFormValues>({
    mode: "all",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: initial || emptyFormValues,
  });

  const { formState, watch, setValue, setError } = formHook;
  const { dirtyFields } = formState;

  const { customQuery } = watch();
  const [debouncedCustomQuery] = useDebounce(customQuery, 1000);

  const basicValData = useValidateCustomQueryBasic({
    customQuery: debouncedCustomQuery || "",
  });

  useEffect(() => {
    if (!basicValData.data?.isValid || basicValData.isLoading) {
      setError("customQuery", {
        type: "custom",
      });
    }
  }, [setError, basicValData.data, basicValData.isLoading]);

  const { validationData } = useCustomQueryValidationData();

  useEffect(() => {
    if (!validationData.isValid) {
      setError("customQuery", {
        type: "custom",
        message: validationData.message || "",
      });
    }
  }, [setError, validationData.isValid, validationData.message]);

  useEffect(() => {
    if (
      validationData.isValid &&
      validationData.titleSuggestions?.length &&
      !dirtyFields.displayName
    ) {
      const suggestedDisplayName = validationData.titleSuggestions[0];
      if (suggestedDisplayName) {
        setValue("displayName", suggestedDisplayName, { shouldValidate: true });
      }
    }
  }, [validationData, dirtyFields.displayName, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "termsInclude" &&
        !dirtyFields.displayName
      ) {
        const updatedValue = value.termsInclude?.list?.join(", ") || "";
        setValue("displayName", updatedValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, dirtyFields]);

  return formHook;
}
