import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { TileLayout } from "src/components/TileLayout/TileLayout";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { useScrollQueryLoader } from "src/utils/useScrollQueryLoader";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { ScrollHeaderLayout } from "src/components/ScrollHeaderLayout/ScrollHeaderLayout";
import {
  EventsNoResultsOverlay,
  FilteredEventsNoResultsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { PowerSearchResultTile } from "./PowerSearchResultTile";
import { PowerSearchResultTilesProps } from "./PowerSearchResultTiles.model";

export function PowerSearchResultTiles({
  id,
  addItemToReport,
  renderHeader,
  powerSearchResultsTiles,
  rows,
  renderCustomFooterEl,
  isExpandedTextMode,
  showDuplicatesDrawer,
  drawerParentEventId,
  hasFilters,
}: PowerSearchResultTilesProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const handleScroll = useScrollQueryLoader({
    query: powerSearchResultsTiles,
  });

  const { list, areAllPagesSelected, excludeList } =
    useEventSelectionPowerSearchResults();
  const isIOS = window?.navigator?.platform === "iPhone";

  const renderItem = (data: PowerSearchResultItem) => {
    let isChecked = false;
    if (areAllPagesSelected) {
      isChecked = !excludeList.find((event) => event.id === data.id);
    } else {
      isChecked = !!list.find((event) => event.id === data.id);
    }

    return (
      <PowerSearchResultTile
        key={data.id}
        value={data}
        isChecked={isChecked}
        addItemToReport={addItemToReport}
        isExpandedTextMode={isExpandedTextMode}
        showDuplicatesDrawer={showDuplicatesDrawer}
        drawerParentEventId={drawerParentEventId}
      />
    );
  };

  const tilesFooter = (
    <Stack
      direction="row"
      alignItems="center"
      borderTop={`1px solid ${palette.divider}`}
      minHeight={53}
    >
      {renderCustomFooterEl && renderCustomFooterEl()}
    </Stack>
  );

  return (
    <>
      <ScrollHeaderLayout
        id={id}
        headerMinHeight={68} // not 56 to contain filter button badge
        onScroll={handleScroll}
        renderHeader={renderHeader}
        isLoading={
          powerSearchResultsTiles.isLoading ||
          powerSearchResultsTiles.isFetching
        }
        children={
          <Stack
            pl={2}
            pr={isIOS ? 2 : 1}
            pb={1}
            flex={1}
            children={
              <TileLayout<PowerSearchResultItem>
                rows={rows}
                renderItem={renderItem}
                renderEmpty={
                  hasFilters
                    ? FilteredEventsNoResultsOverlay
                    : EventsNoResultsOverlay
                }
              />
            }
          />
        }
      />
      {!isMobile && !!renderCustomFooterEl && tilesFooter}
    </>
  );
}
