import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { usePowerSearchQuery } from "src/api/usePowerSearchQuery";
import { useWatchQueryCreate } from "src/api/useWatchQueryCreate";
import { makeQueryDefinition } from "src/utils/makeQueryDefinition";
import { makePowerSearchQuery } from "src/utils/makePowerSearchQuery";
import { DialogBase } from "src/components/DialogBase/DialogBase";
import { WatchQueryDialogActions } from "src/components/WatchQueryDialogActions/WatchQueryDialogActions";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import {
  emptyWatchQueryCustomFormValues,
  useWatchQueryCustomForm,
} from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.hook";
import { WatchQueryCustomFormSchema } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.schema";
import { WatchQueryCustomForm } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm";
import { PowerSearchFilterDialogAdvancedProps } from "./PowerSearchFilterDialogAdvanced.model";

export function PowerSearchFilterDialogAdvanced({
  onClose,
  initial,
  onSubmit,
  closeOnLeftSide,
  ...props
}: PowerSearchFilterDialogAdvancedProps) {
  const watchQueryCreate = useWatchQueryCreate({
    options: {
      onSettled: onClose,
    },
  });

  const powerSearchQuery = usePowerSearchQuery();

  const formHook = useWatchQueryCustomForm({
    schema: WatchQueryCustomFormSchema,
    initial,
  });

  const { formState, handleSubmit, reset } = formHook;

  useEffect(() => {
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(initial);
    }
  }, [reset, props.open, initial]);

  const isBusy = formState.isSubmitting || watchQueryCreate.isLoading;

  const submitWatchTerm = handleSubmit(
    (data: WatchQueryCustomFormValues) => {
      const query = makeQueryDefinition(data);

      watchQueryCreate.mutate({
        title: data.displayName,
        queryBuilderDefinition: query,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:submitWatchTerm:error", errors);
    }
  );

  const submitPowerSearch = handleSubmit(
    (data: WatchQueryCustomFormValues) => {
      powerSearchQuery.save(makePowerSearchQuery(data));
      onSubmit();
    },
    (errors) => {
      console.log("@@ DEBUG:submitPowerSearch:error", errors);
    }
  );

  const onFormClear = () => {
    reset(emptyWatchQueryCustomFormValues);
    powerSearchQuery.save(
      makePowerSearchQuery(emptyWatchQueryCustomFormValues)
    );

    onSubmit();
  };

  return (
    <FormProvider {...formHook}>
      <DialogBase
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        closeOnLeftSide={closeOnLeftSide}
        {...props}
        children={
          <WatchQueryCustomForm
            layout="create"
            loading={watchQueryCreate.isLoading}
          />
        }
        footer={
          <WatchQueryDialogActions
            isBusy={isBusy}
            isValid={formState.isValid && isEmpty(formState.errors)}
            onFormClear={!initial?.customQuery ? onFormClear : undefined}
            onClose={onClose}
            submitPowerSearch={submitPowerSearch}
            submitWatchQueryCreate={submitWatchTerm}
          />
        }
      />
    </FormProvider>
  );
}
