import { Breadcrumbs, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppLink } from "../AppLink/AppLink";
import { AppTopNavBarProps } from "./AppTopNavBar.model";

const renderNavItem = (to: string, label: string, isActive: boolean) => (
  <AppLink
    title={label}
    color={isActive ? "text.primary" : "text.secondary"}
    to={to}
    key={to}
  />
);

export const AppTopNavBar = ({
  showRoot,
  lastBreadcrumbLabel,
  hidePenultimateRoute,
  routeLabels,
  ...breadcrumbsProps
}: AppTopNavBarProps): JSX.Element | null => {
  const location = useLocation();
  const { t, ready } = useTranslation("routes");
  const prevParts: string[] = [];
  const parts = location.pathname.split("/").filter(Boolean);
  const rootElement = renderNavItem(
    "/",
    ready ? t("Home") : "Home",
    location.pathname === "/"
  );

  const partsReversed = [...parts].reverse();

  const breadcrumbElements = parts.reduce((res: JSX.Element[], part, index) => {
    prevParts.push(part);
    let to = "/" + prevParts.join("/");

    const isPenultimate = index === parts.length - 2;
    if (hidePenultimateRoute && isPenultimate) return res;

    const isLast = index === parts.length - 1;

    const reversedIndex = partsReversed.findIndex((item) => item === part);

    if (routeLabels?.[reversedIndex]) {
      res.push(renderNavItem(to, routeLabels[reversedIndex], isLast));
      return res;
    }

    const token =
      lastBreadcrumbLabel && isLast ? lastBreadcrumbLabel : capitalize(part);

    const label = ready ? t(token) : part;
    res.push(renderNavItem(to, label, isLast));
    return res;
  }, []);

  return (
    <Breadcrumbs sx={{ margin: 0 }} {...breadcrumbsProps}>
      {showRoot ? [rootElement, ...breadcrumbElements] : breadcrumbElements}
    </Breadcrumbs>
  );
};
