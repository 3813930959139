import { Fragment, useEffect, useState } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import {
  Stack,
  Typography,
  ListItemButton,
  IconButton,
  CircularProgress,
  Box,
  Dialog,
} from "@mui/material";
import { ListBase } from "src/components/ListBase/ListBase";
import { useWatchQueryList } from "src/api/useWatchQueryList";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { SearchInput } from "src/components/SearchInput";
import { WatchTermNavBarMobileProps } from "./WatchTermNavBarMobile.model";
import { TextInputBase } from "../TextInputBase";
import { useOpenState } from "src/utils/useOpenState";

export function WatchTermNavBarMobile({
  value,
  onChange,
  loading,
  ...props
}: WatchTermNavBarMobileProps) {
  const watchQueryList = useWatchQueryList({});
  const menuState = useOpenState();
  const [filterText, setFilterText] = useState("");
  const [filterOptions, setFilterOptions] = useState<WatchListUserQueryDTO[]>(
    []
  );

  const selectWatchQuery = (nextWatchQuery: WatchListUserQueryDTO) => {
    menuState.hide();
    onChange(nextWatchQuery);
  };

  useEffect(() => {
    if (!filterText) {
      setFilterOptions(watchQueryList.data?.results || []);
      return;
    }

    const prevOptions = watchQueryList.data?.results || [];
    const nextOptions = prevOptions.filter((query) => {
      const q = filterText.toLowerCase();
      return query.title.toLowerCase().includes(q);
    });

    setFilterOptions(nextOptions);
  }, [watchQueryList.data?.results, filterText]);

  return (
    <Stack {...props}>
      <TextInputBase
        label="Keyword"
        value={loading ? "Loading..." : value?.title || " "}
        onClick={menuState.isOpen ? menuState.hide : menuState.show}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Fragment>
              {loading ? (
                <CircularProgress size={22} />
              ) : (
                <IconButton
                  onClick={menuState.isOpen ? menuState.hide : menuState.show}
                >
                  <ArrowDropDownOutlined />
                </IconButton>
              )}
            </Fragment>
          ),
        }}
        fullWidth
      />
      <Dialog open={menuState.isOpen} onClose={menuState.hide} fullWidth>
        <Stack overflow="hidden" minHeight={400} maxHeight={400}>
          <SearchInput
            onTextChangeThrottled={setFilterText}
            loading={watchQueryList.isLoading}
            placeholder="Watch terms in watchlist"
            hideClear
            sx={{
              maxWidth: "inherit",
              m: 1,
            }}
            InputProps={{
              endAdornment: <CircularProgress size={18} color="primary" />,
            }}
          />
          {filterOptions.length ? (
            <ListBase<WatchListUserQueryDTO>
              rows={filterOptions}
              hideFooter
              loading={watchQueryList.isLoading}
              ListProps={{
                sx: {
                  rowGap: 0,
                },
              }}
              renderItem={(item) => (
                <Typography
                  key={item.id}
                  component={ListItemButton}
                  onClick={() => selectWatchQuery(item)}
                  selected={item.id === value?.id}
                >
                  {item.title}
                </Typography>
              )}
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              m="auto"
              py={2}
            >
              <Typography>No options</Typography>
            </Box>
          )}
        </Stack>
      </Dialog>
    </Stack>
  );
}
