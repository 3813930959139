import { useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";
import { useRouteLeave } from "src/utils/useRouteLeave";
import { useWatchQueryFilter } from "src/pages/WatchList/hooks/useWatchQueryFilter";
import { useWatchListClusterEditMode } from "src/pages/WatchList/components/WatchListClusterEditToolbar/hooks/useWatchListClusterEditMode";
import { useRouteGroupLeave } from "src/utils/useRouteGroupLeave";
import { usePushNotificationEvent } from "src/utils/usePushNotificationEvent";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useEventSelectionReport } from "src/api/useEventSelectionReport";
import { useEventsSelectedForReports } from "src/api/useEventsSelectedForReport";
import { useEventSelectionWatchTermResults } from "src/api/useEventSelectionWatchTermResults";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { useEventSelectionDuplicatesDrawer } from "src/api/useEventSelectionDuplicatesDrawer";
import { reportsRoute } from "src/pages/Reports/Reports.route";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { watchListTermResultsRoute } from "src/pages/WatchListTermResults/WatchListTermResults.route";
import { watchListTermResultClipRoute } from "src/pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { watchListTermResultClipEditorRoute } from "src/pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.route";
import { watchListUpdateRoute } from "src/pages/WatchListUpdate/WatchListUpdate.route";
import { watchListCreateRoute } from "src/pages/WatchListCreate/WatchListCreate.route";
import { powerSearchResultRoute } from "src/pages/PowerSearchResult/PowerSearchResult.route";
import { reportCreateRoute } from "src/pages/ReportCreate/ReportCreate.route";
import { reportEditRoute } from "src/pages/ReportEdit/ReportEdit.route";
import { DrawerDesktop } from "./navigation/DrawerDesktop";
import { DrawerMobile } from "./navigation/DrawerMobile";
import { ExportCompleteNotificationActions } from "./ExportCompleteNotificationActions/ExportCompleteNotificationActions";

export const AppLayout = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const resetWatchQueryFilter = useWatchQueryFilter()[2];
  const clusterEditMode = useWatchListClusterEditMode();

  useRouteGroupLeave({
    routes: useMemo(
      () => [
        watchListRoute,
        watchListCreateRoute,
        watchListUpdateRoute,
        watchListTermResultsRoute,
        watchListTermResultClipRoute,
        watchListTermResultClipEditorRoute,
      ],
      []
    ),
    callback: resetWatchQueryFilter,
  });

  useRouteLeave({
    // reset cluster edit mode when leaving watch list
    route: watchListRoute,
    callback: clusterEditMode.deactivate,
  });

  const preselectedEventsState = useEventsPreselected();
  useRouteLeave({
    route: reportsRoute,
    callback: preselectedEventsState.removeHighlighting,
  });

  const eventSelectionWatchTermResults = useEventSelectionWatchTermResults();
  useRouteLeave({
    route: watchListTermResultsRoute,
    callback: eventSelectionWatchTermResults.unselectAll,
  });

  const eventSelectionPowerSearchResults =
    useEventSelectionPowerSearchResults();
  useRouteLeave({
    route: powerSearchResultRoute,
    callback: eventSelectionPowerSearchResults.unselectAll,
  });

  const eventSelectionDuplicatesDrawer = useEventSelectionDuplicatesDrawer();
  useRouteGroupLeave({
    routes: useMemo(
      () => [watchListTermResultsRoute, powerSearchResultRoute],
      []
    ),
    callback: eventSelectionDuplicatesDrawer.unselectAll,
  });

  const eventSelectionReport = useEventSelectionReport();
  const eventsSelectedForReports = useEventsSelectedForReports();
  useRouteLeave({
    route: reportCreateRoute,
    callback: eventSelectionReport.unselectAll,
  });
  useRouteLeave({
    route: reportEditRoute,
    callback: eventSelectionReport.unselectAll,
  });

  useRouteLeave({
    route: reportCreateRoute,
    callback: eventsSelectedForReports.unselectAll,
  });
  useRouteLeave({
    route: reportEditRoute,
    callback: eventsSelectedForReports.unselectAll,
  });

  usePushNotificationEvent({
    key: "ExportComplete",
    cb: (notification) => {
      if (notification.additionalData) {
        const reportDataObj = JSON.parse(notification.additionalData);
        enqueueSnackbar(
          `${reportDataObj.type?.toUpperCase()} is ready: '${
            reportDataObj.title
          }'`,
          {
            variant: "success",
            action: (snackbarId) => (
              <ExportCompleteNotificationActions
                userId={notification.userId}
                reportDataObj={reportDataObj}
                snackbarId={snackbarId}
              />
            ),
            autoHideDuration: 15000,
            key: reportDataObj.title,
          }
        );
      }
    },
  });

  return (
    <AppQueryParamsProvider>
      <Box display="flex" flexDirection="row" width="100%">
        {smUp ? <DrawerDesktop /> : <DrawerMobile />}
        <Outlet />
      </Box>
    </AppQueryParamsProvider>
  );
};
