import { create } from "zustand";

export const useAccountStore = create<{
  accountName?: string | null;
  isActive: boolean;
  setAccountName: (name?: string | null) => void;
  setIsActive: (isActive?: boolean) => void;
}>((set) => ({
  setAccountName: (accountName) => set({ accountName }),
  setIsActive: (isActive) => set({ isActive }),
  accountName: "",
  isActive: false,
}));
