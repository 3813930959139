import { add, differenceInSeconds, isAfter, isEqual, sub } from "date-fns";
import { memo } from "react";
import { TimeLineBlock } from "../TimeLineBlock/TimeLineBlock";
import { TimeLineBlockLayoutProps } from "./TimeLineBlockLayout.model";

export const TimeLineBlockLayout = memo(
  ({
    date,
    count,
    maxDate,
    blockParams,
    renderBlockContent,
    renderDisabledBlockContent,
    disabledColor,
  }: TimeLineBlockLayoutProps): JSX.Element => {
    const total = count * 2;

    const timeMarkBlocks: JSX.Element[] = new Array(total);
    const blockRangeStartDate = sub(date, {
      seconds: count * blockParams.spanSec,
    });

    for (let blockIndex = 0; blockIndex < total; blockIndex++) {
      const blockStartTime = add(blockRangeStartDate, {
        seconds: blockIndex * blockParams.spanSec,
      });

      const blockEndTime = add(blockStartTime, {
        seconds: blockParams.spanSec,
      });

      const secToDisabled = maxDate
        ? differenceInSeconds(maxDate, blockStartTime)
        : undefined;

      const isDisabledBlock = maxDate && isAfter(blockEndTime, maxDate);

      if (isDisabledBlock) {
        timeMarkBlocks[blockIndex] = (
          <TimeLineBlock
            key={blockIndex}
            blockWidth={blockParams.width}
            renderDisabledBlockContent={renderDisabledBlockContent}
            width={blockParams.width * (total - blockIndex)}
            spanSec={blockParams.spanSec * (total - blockIndex)}
            disabledSec={secToDisabled}
            disabledColor={disabledColor}
            alignText="left"
            content={renderBlockContent?.({
              blockIndex,
              blockStartTime,
              blockEndTime,
              count,
            })}
          />
        );

        break;
      }

      timeMarkBlocks[blockIndex] = (
        <TimeLineBlock
          key={blockIndex}
          width={blockParams.width}
          spanSec={blockParams.spanSec}
          content={renderBlockContent?.({
            blockIndex,
            blockStartTime,
            blockEndTime,
            count,
          })}
        />
      );
    }

    return <>{timeMarkBlocks}</>;
  },
  (prevProps, nextProps) => {
    // This function compares dates in a specific way:
    // 1 - for performance we want component to change if user moves farther that one block width
    // 2 - maxDate is an object reference, so we compare date values, not references
    const keys = Object.keys({ ...prevProps, ...nextProps });
    const obj1: Record<string, unknown> = { ...prevProps };
    const obj2: Record<string, unknown> = { ...nextProps };

    for (const key of keys) {
      const prevValue = obj1[key];
      const nextValue = obj2[key];

      const dateKey: keyof typeof prevProps = "date";
      if (key === dateKey) {
        const diff = differenceInSeconds(
          prevProps[dateKey],
          nextProps[dateKey]
        );

        if (Math.abs(diff) > nextProps.blockParams.spanSec) {
          return false;
        } else {
          continue;
        }
      }

      const maxDateKey: keyof typeof prevProps = "maxDate";
      if (key === maxDateKey) {
        if (!isEqual(prevProps[dateKey], nextProps[dateKey])) {
          return false;
        } else {
          continue;
        }
      }

      if (prevValue !== nextValue) {
        return false;
      }
    }

    return true;
  }
);
