import { HubConnectionState } from "@microsoft/signalr";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useCurrentUser } from "src/api/useCurrentUser";
import { useNotificationMessageUser } from "src/api/useNotificationMessageUser";
import { ListBase } from "src/components/ListBase/ListBase";
import {
  TextInputBase,
  TextInputBaseProps,
} from "src/components/TextInputBase";
import { InProductNotification } from "src/models/InProductNotification";
import { usePushNotificationContext } from "src/providers/PushNotificationProvider/PushNotificationProvider";
import { usePushNotificationEvent } from "src/utils/usePushNotificationEvent";

export function DevPushNotifications() {
  const user = useCurrentUser();
  const { state, connect, disconnect } = usePushNotificationContext();
  const [message, setMessage] = useState("");
  const [notifications, setNotifications] = useState<InProductNotification[]>(
    []
  );
  const notificationMessage = useNotificationMessageUser({
    options: {
      onSuccess: () => {
        setMessage("");
      },
    },
  });

  const handleChangeWrapper: TextInputBaseProps["onChange"] = (e) => {
    setMessage(e.target.value);
  };

  const handleOnKeyDownWrapper: TextInputBaseProps["onKeyDown"] = (e) => {
    if (e.key === "Enter") {
      notificationMessage.mutate({
        message,
        sender: `${user.data?.firstName} ${user.data?.lastName}`,
        subject: "Foo bar subject",
      });
    }
  };

  usePushNotificationEvent({
    key: "General",
    cb: (notification) => setNotifications((prev) => [notification, ...prev]),
  });

  const renderButton = () => {
    if (
      state === HubConnectionState.Disconnected ||
      state === HubConnectionState.Connecting
    ) {
      return (
        <LoadingButton
          children="Connect"
          variant="contained"
          color="info"
          disabled={state === HubConnectionState.Connecting}
          loading={state === HubConnectionState.Connecting}
          onClick={connect}
        />
      );
    }
    if (
      state === HubConnectionState.Connected ||
      state === HubConnectionState.Disconnecting
    ) {
      return (
        <LoadingButton
          children="Disconnect"
          variant="contained"
          color="warning"
          disabled={state === HubConnectionState.Disconnecting}
          loading={state === HubConnectionState.Disconnecting}
          onClick={disconnect}
        />
      );
    }
  };

  return (
    <Stack px={2} rowGap={2}>
      <Typography variant="h6">Push Notifications</Typography>
      <Stack direction="row" columnGap={2} alignItems="center">
        {renderButton()}
        <Typography children={`State: ${state}`} />
      </Stack>
      <TextInputBase
        size="small"
        disabled={
          state !== HubConnectionState.Connected ||
          notificationMessage.isLoading
        }
        value={message}
        onChange={handleChangeWrapper}
        onKeyDown={handleOnKeyDownWrapper}
        InputProps={{
          endAdornment: notificationMessage.isLoading ? (
            <CircularProgress size="small" />
          ) : null,
        }}
      />

      <ListBase<InProductNotification>
        rows={notifications}
        renderItem={(notification) => {
          return (
            <Typography
              key={notification.id}
              children={JSON.stringify(notification, null, 2)}
            />
          );
        }}
      />
    </Stack>
  );
}
