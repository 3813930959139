import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { forwardRef } from "react";

export type LoadingButtonProps = ButtonProps & { loading?: boolean };

/** Button that has spinner as start icon when loading prop is true */
export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading, startIcon, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        startIcon={
          loading ? (
            <CircularProgress sx={{ color: "inherit" }} size="1em" />
          ) : (
            startIcon
          )
        }
        {...props}
      />
    );
  }
);
