import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TranscriptLineBundleView } from "src/components/TranscriptLineBundleView/TranscriptLineBundleView";
import {
  TranscriptLineBundleViewLabelLayout,
  TranscriptLineBundleViewTrimShowMode,
} from "src/components/TranscriptLineBundleView/TranscriptLineBundleView.const";
import { TranscriptParser } from "src/components/TranscriptParser/TranscriptParser";

const mockHighlights =
  `start ` +
  `<em timing="2023-07-25T00:00:00.000">label_a</em> ` +
  `middle text ` +
  `<em timing="2023-07-25T23:59:59.999">label_b</em>` +
  ` end text`;

const mockTranscriptLineBundle = {
  bundleStartDateTime: "2023-11-22T15:10:50.597",
  bundleEndDateTime: "2023-11-22T15:11:45.951",
  bundleDurationMS: 55354,
  transcriptLines: [
    {
      offsetMs: 0,
      lineDateTime: "2023-11-22T15:10:50.597",
      lineText: "lose the jobs that are here already.",
    },
    {
      offsetMs: 2970,
      lineDateTime: "2023-11-22T15:10:53.567",
      lineText: "And just briefly, will it make it",
    },
    {
      offsetMs: 5658,
      lineDateTime: "2023-11-22T15:10:56.255",
      lineText: "easier for you to win your",
    },
    {
      offsetMs: 7048,
      lineDateTime: "2023-11-22T15:10:57.645",
      lineText: "constituency at the next election,",
    },
    {
      offsetMs: 8846,
      lineDateTime: "2023-11-22T15:10:59.443",
      lineText: "because it looks like quite a close",
    },
    {
      offsetMs: 12596,
      lineDateTime: "2023-11-22T15:11:03.193",
      lineText: "battle between you and Labour. From",
    },
    {
      offsetMs: 14565,
      lineDateTime: "2023-11-22T15:11:05.162",
      lineText: "that point of view, I do not know,",
    },
    {
      offsetMs: 16081,
      lineDateTime: "2023-11-22T15:11:06.678",
      lineText: "but from a Chambers point of view,",
    },
    {
      offsetMs: 17363,
      lineDateTime: "2023-11-22T15:11:07.96",
      lineText: "it does mean that we have got",
    },
    {
      offsetMs: 18550,
      lineDateTime: "2023-11-22T15:11:09.147",
      lineText: "something that we have been pushing",
    },
    {
      offsetMs: 21051,
      lineDateTime: "2023-11-22T15:11:11.648",
      lineText: "for for a long while, and we have to",
    },
    {
      offsetMs: 22520,
      lineDateTime: "2023-11-22T15:11:13.117",
      lineText: "put great congratulations to Ann",
    },
    {
      offsetMs: 23458,
      lineDateTime: "2023-11-22T15:11:14.055",
      lineText: "Handley, who is pocket battleship",
    },
    {
      offsetMs: 26020,
      lineDateTime: "2023-11-22T15:11:16.617",
      lineText: "and she has done a marvellous piece",
    },
    {
      offsetMs: 28693,
      lineDateTime: "2023-11-22T15:11:19.29",
      lineText: "of work, and also Mike Ross. A",
    },
    {
      offsetMs: 30381,
      lineDateTime: "2023-11-22T15:11:20.978",
      lineText: "leaders of the local councils. Kate,",
    },
    {
      offsetMs: 31850,
      lineDateTime: "2023-11-22T15:11:22.447",
      lineText: "you were with us earlier, you are",
    },
    {
      offsetMs: 35522,
      lineDateTime: "2023-11-22T15:11:26.119",
      lineText: "from Tyne bank. You are here just",
    },
    {
      offsetMs: 36944,
      lineDateTime: "2023-11-22T15:11:27.541",
      lineText: "before the Autumn Statement. What is",
    },
    {
      offsetMs: 39366,
      lineDateTime: "2023-11-22T15:11:29.963",
      lineText: "your reaction? Low paid area, people",
    },
    {
      offsetMs: 41257,
      lineDateTime: "2023-11-22T15:11:31.854",
      lineText: "on long-term benefits, will what the",
    },
    {
      offsetMs: 42086,
      lineDateTime: "2023-11-22T15:11:32.683",
      lineText: "Chancellor announced today change",
    },
    {
      offsetMs: 45008,
      lineDateTime: "2023-11-22T15:11:35.605",
      lineText: "any of that? I think it is positive",
    },
    {
      offsetMs: 47462,
      lineDateTime: "2023-11-22T15:11:38.059",
      lineText: "that there is a rise in the minimum",
    },
    {
      offsetMs: 49446,
      lineDateTime: "2023-11-22T15:11:40.043",
      lineText: "wage, the living wage, and also in",
    },
    {
      offsetMs: 51681,
      lineDateTime: "2023-11-22T15:11:42.278",
      lineText: "benefits as well, in line with",
    },
    {
      offsetMs: 52884,
      lineDateTime: "2023-11-22T15:11:43.481",
      lineText: "inflation. But what really worries",
    },
    {
      offsetMs: 55354,
      lineDateTime: "2023-11-22T15:11:45.951",
      lineText: "me is again there is a focus on",
    },
  ],
  transcript:
    "lose the jobs that are here already. And just briefly, will it make it easier for you to win your constituency at the next election, because it looks like quite a close battle between you and Labour. From that point of view, I do not know, but from a Chambers point of view, it does mean that we have got something that we have been pushing for for a long while, and we have to put great congratulations to Ann Handley, who is pocket battleship and she has done a marvellous piece of work, and also Mike Ross. A leaders of the local councils. Kate, you were with us earlier, you are from Tyne bank. You are here just before the Autumn Statement. What is your reaction? Low paid area, people on long-term benefits, will what the Chancellor announced today change any of that? I think it is positive that there is a rise in the minimum wage, the living wage, and also in benefits as well, in line with inflation. But what really worries me is again there is a focus on",
};

export function DevTranscript() {
  const [params] = useSearchParams();
  const timing = params.get("timing");
  const [trimViewMode, setTrimViewMode] =
    useState<TranscriptLineBundleViewTrimShowMode>();

  const noop = () => null;

  return (
    <Stack height="100%" rowGap={2}>
      <TranscriptParser transcript={mockHighlights} clickable />
      <Typography>
        Param from highlights: <strong>{timing}</strong>
      </Typography>

      <Divider />

      <Typography variant="h6" children="Bundle View" />

      <FormControl>
        <FormLabel>Trim Controls show mode</FormLabel>
        <RadioGroup
          row
          name="trim-view-mode"
          defaultValue={TranscriptLineBundleViewTrimShowMode.always}
          onChange={(_e, v) => {
            v
              ? setTrimViewMode(v as TranscriptLineBundleViewTrimShowMode)
              : setTrimViewMode(undefined);
          }}
        >
          <FormControlLabel value="" control={<Radio />} label="default" />
          <FormControlLabel
            value={TranscriptLineBundleViewTrimShowMode.always}
            control={<Radio />}
            label={TranscriptLineBundleViewTrimShowMode.always}
          />
          <FormControlLabel
            value={TranscriptLineBundleViewTrimShowMode.hover}
            control={<Radio />}
            label={TranscriptLineBundleViewTrimShowMode.hover}
          />
        </RadioGroup>
      </FormControl>

      <TranscriptLineBundleView
        bundle={mockTranscriptLineBundle}
        baseTime={mockTranscriptLineBundle.bundleStartDateTime}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.left}
        showTrimToolBar={trimViewMode}
        onClickSetStart={noop}
        onClickSetEnd={noop}
      />

      <TranscriptLineBundleView
        bundle={mockTranscriptLineBundle}
        baseTime={mockTranscriptLineBundle.bundleStartDateTime}
        showTimeLabel={TranscriptLineBundleViewLabelLayout.top}
        showTrimToolBar={trimViewMode}
        onClickSetStart={noop}
        onClickSetEnd={noop}
      />
    </Stack>
  );
}
