import { WatchTerm } from "src/components/WatchTermInput/WatchTermInput.model";

export const convertToCustomQuery = ({
  termsInclude,
  termsExclude,
}: {
  termsInclude?: WatchTerm;
  termsExclude?: WatchTerm;
}): string => {
  let customQueryString = "";
  termsInclude?.list.forEach((item, index) => {
    if (index === 0) {
      customQueryString = `"${item}"`;
    } else {
      customQueryString = `${customQueryString} ${termsInclude.logic.toLocaleUpperCase()} "${item}"`;
    }
  });

  if (!!termsExclude?.list.length) {
    customQueryString = customQueryString + " AND NOT (";
    termsExclude?.list.forEach((item, index) => {
      if (index === 0) {
        customQueryString = `${customQueryString}"${item}"`;
      } else {
        customQueryString = `${customQueryString} ${termsExclude.logic.toLocaleUpperCase()} "${item}"`;
      }
    });
    customQueryString = customQueryString + ")";
  }

  return customQueryString;
};
