import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Add,
  DeleteOutline,
  FileDownloadOutlined,
  MoreVertOutlined,
  RemoveRedEyeOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { MediaCenterItem } from "src/api/useMediaCenter";
import {
  useShareMediaFormSnackbar,
  useShareMediaLinkSnackbar,
} from "src/api/useShareMediaSnackbar";
import { useDownloadFilesSnackbar } from "src/api/useDownloadFilesSnackbar";

// every commented line here is related to MMSR-2997
// import { useExportPdf } from "src/api/useExportPdf";
import { useExportCsv } from "src/api/useExportCsv";
import { getMediaCenterResultId } from "src/api/useMediaCenter.getters";
import { IconButtonDropDown } from "src/components/IconButtonDropDown/IconButtonDropDown";
import { ShareMediaDialog } from "src/components/ShareMediaDialog/ShareMediaDialog";
// import { DownloadPDF } from "src/components/icons/DownloadPDF";
import { DownloadCSV } from "src/components/icons/DownloadCSV";
import { useSessionContext } from "src/api/useSessionContext";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { mediaCenterClipRoute } from "src/pages/MediaCenterClip/MediaCenterClip.route";
import { MediaCenterDeleteDialog } from "../MediaCenterDeleteDialog/MediaCenterDeleteDialog";

export type MediaCenterActionsProps = {
  value: MediaCenterItem;
  downloadMediaItem: (value: MediaCenterItem) => void;
  addItemToReport: (value: MediaCenterItem) => void;
};

export const MediaCenterActions = ({
  value,
  downloadMediaItem,
  addItemToReport,
}: MediaCenterActionsProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const shareDialog = useOpenState();
  const shareFormSnackBar = useShareMediaFormSnackbar();
  const shareLinkSnackBar = useShareMediaLinkSnackbar();
  const downloadFilesSnackBar = useDownloadFilesSnackbar();
  const { effectiveEntitlements } = useSessionContext();
  const enableMediaDownload = effectiveEntitlements.enableMediaDownloads?.value;
  const navigate = useNavigate();

  const detailsUrl = mediaCenterClipRoute.makeUrl({
    eventId: getMediaCenterResultId(value),
  });

  // const downloadPdfMutation = useExportPdf({});
  // const downloadPdfEventItem = useCallback(() => {
  //   downloadFilesSnackBar.show();
  //   downloadPdfMutation.mutate({
  //     selectedEvents: [value.eventHighlights],
  //   });
  // }, [downloadPdfMutation, downloadFilesSnackBar, value.eventHighlights]);

  const downloadCsvMutation = useExportCsv({});

  const downloadCsvEventItem = useCallback(() => {
    downloadFilesSnackBar.show();
    downloadCsvMutation.mutate({
      selectedEvents: [value.eventHighlights],
    });
  }, [downloadCsvMutation, downloadFilesSnackBar, value.eventHighlights]);

  return (
    <>
      <IconButtonDropDown
        renderDropDown={(menuProps, closeMenu) => (
          <Menu {...menuProps}>
            <MenuItem
              onClick={() => {
                closeMenu();
                shareDialog.show();
              }}
            >
              <ListItemIcon>
                <ShareOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                addItemToReport(value);
              }}
            >
              <ListItemIcon>
                <Add fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Add to Report</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                navigate(detailsUrl);
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeOutlined fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
            <TooltipBase
              width={181}
              textAlign="left"
              title={
                "You can't download media as you have an account limitation. Please contact your account manager to extend your limits."
              }
              placement="right"
              disableHoverListener={enableMediaDownload}
            >
              <Box>
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    downloadMediaItem(value);
                  }}
                  disabled={!enableMediaDownload}
                >
                  <ListItemIcon>
                    <FileDownloadOutlined fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Download</ListItemText>
                </MenuItem>
              </Box>
            </TooltipBase>
            {/* <MenuItem
              onClick={() => {
                downloadPdfEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadPDF />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </MenuItem> */}

            <MenuItem
              onClick={() => {
                downloadCsvEventItem();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DownloadCSV />
              </ListItemIcon>
              <ListItemText>Download CSV</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                closeMenu();
                setShowDeleteModal(true);
              }}
            >
              <ListItemIcon>
                <DeleteOutline fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Remove</ListItemText>
            </MenuItem>
          </Menu>
        )}
      >
        <MoreVertOutlined />
      </IconButtonDropDown>
      <MediaCenterDeleteDialog
        open={showDeleteModal}
        value={value}
        onClose={() => setShowDeleteModal(false)}
      />
      <ShareMediaDialog
        title="Share Media Center Item"
        itemsIds={[value.id]}
        open={shareDialog.isOpen}
        onClose={shareDialog.hide}
        onSubmit={shareFormSnackBar.show}
        copyShareLinkCallback={shareLinkSnackBar.show}
      />
    </>
  );
};
