import { useTheme } from "@mui/material";
import { getEventSourceIcon } from "src/utils/getEventSourceIcon";

export const QuerySourceIncludeIcon = ({
  color,
  eventType,
}: {
  color?: string;
  eventType: string;
}) => {
  const { palette } = useTheme();
  const Icon = getEventSourceIcon(eventType);
  if (!Icon) {
    return null;
  }

  return (
    <Icon
      fontSize="small"
      sx={{
        position: "relative",
        color: color || palette.text.primary,
      }}
    />
  );
};
