import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEventDetails } from "src/api/useEventDetails";
import { useEventByStationAndDate } from "src/api/useEventByStationAndDate";
import { useWatchQuery } from "src/api/useWatchQuery";
import { ClipViewerPage } from "src/components/ClipViewerPage/ClipViewerPage";
import { WatchListTermResultClipPathParam } from "./WatchListTermResultClip.route";
import { watchListTermResultClipEditorRoute } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.route";
import { ShiftPlayerDataProps } from "../WatchListTermResultClipEditor/WatchListTermResultClipEditor.page";

export const WatchListTermResultClipPage = () => {
  const navigate = useNavigate();
  const { queryId = "", eventId = "" } =
    useParams<WatchListTermResultClipPathParam>();

  const [dateTime, setDateTime] = useState<undefined | ShiftPlayerDataProps>(
    undefined
  );

  const currentQueryData = useWatchQuery({
    path: {
      queryId,
    },
  });

  const eventDetails = useEventDetails({
    params: {
      path: {
        source: "query",
        sourceId: queryId,
        eventId,
      },
    },
  });

  const dateTimeEventDetails = useEventByStationAndDate({
    request: {
      query: {
        stationUUID: eventDetails.data?.sourceId ?? "",
        startDateTime: dateTime?.startDateTime ?? "",
        endDateTime: dateTime?.endDateTime ?? "",
      },
    },
    body: currentQueryData.data?.queryBuilderDefinition ?? {},
    options: {
      enabled: Boolean(dateTime) && Boolean(currentQueryData.data),
    },
  });

  const event = useMemo(() => {
    if (dateTime && dateTimeEventDetails.data) {
      return (
        {
          ...eventDetails.data,
          startDateTime: dateTime.startDateTime,
          endDateTime: dateTime.endDateTime,
          transcript: dateTimeEventDetails.data.transcript,
          transcriptLines: dateTimeEventDetails.data.transcriptLines,
          transcriptLineBundles:
            dateTimeEventDetails.data.transcriptLineBundles,
          highlights: dateTimeEventDetails.data.highlights,
        } || {}
      );
    }

    return eventDetails.data || {};
  }, [eventDetails.data, dateTimeEventDetails.data, dateTime]);

  const onShiftPlayerPlay = (data: ShiftPlayerDataProps) => setDateTime(data);

  const goToEditor = () => {
    const url = watchListTermResultClipEditorRoute.makeUrl({
      queryId,
      eventId,
    });

    navigate(url);
  };

  return (
    <ClipViewerPage
      initialStartDateTime={eventDetails.data?.startDateTime}
      event={event}
      query={currentQueryData.data?.queryBuilderDefinition}
      loading={eventDetails.isLoading}
      shiftOffset={dateTime?.shiftOffset}
      onShiftPlayerPlay={onShiftPlayerPlay}
      routeRoot="Watchlist"
      onEdit={goToEditor}
    />
  );
};
