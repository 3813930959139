import { queryAdvertisementValues } from "src/models/Advertisement";
import { WatchQueryCustomFormValues } from "src/components/WatchQueryCustomForm/WatchQueryCustomForm.model";
import { WatchQueryFilterFormValues } from "src/components/WatchQueryFilterForm/WatchQueryFilterForm.model";
import { isTagPickerValue } from "src/components/TagPicker/utils/isTagPickerValue";

/**
 * Checks that customQuery is non empty string
 */
const hasCustomQuery = (
  data: unknown
): data is Pick<WatchQueryCustomFormValues, "customQuery"> => {
  const v = (data as WatchQueryCustomFormValues).customQuery as unknown;

  return typeof v === "string";
};

const getTermIncludeExcludeCount = (value?: unknown) => {
  type UnknownValue = Pick<
    Partial<WatchQueryFilterFormValues>,
    "termsInclude" | "termsExclude"
  >;

  const { termsInclude, termsExclude } = value as UnknownValue;

  let count = 0;

  if (isTagPickerValue(termsInclude) && termsInclude.list.length) {
    count++;
  }

  if (isTagPickerValue(termsExclude) && termsExclude.list.length) {
    count++;
  }

  return count;
};

export const countQueryFilters = (
  data?: WatchQueryFilterFormValues | WatchQueryCustomFormValues
): number => {
  let totalFilters = 0;

  if (hasCustomQuery(data) && data.customQuery.length) {
    totalFilters++;
  }

  totalFilters += getTermIncludeExcludeCount(data);

  const { sourcesInclude } = data || {};
  totalFilters += sourcesInclude?.list.length ? 1 : 0;

  const { programExclude, programInclude, advertisement } = data || {};

  totalFilters += programInclude?.list.length ? 1 : 0;
  totalFilters += programExclude?.list.length ? 1 : 0;
  totalFilters += advertisement !== queryAdvertisementValues.all ? 1 : 0;

  return totalFilters;
};
