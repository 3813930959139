import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useAuth0 } from "@auth0/auth0-react";
import { components, paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { RequestAccountAutocompleteBody } from "./useUsersManagement";

const apiPath: keyof paths = "/api/Users/management-view";
const method: keyof paths[typeof apiPath] = "post";

export type UserItem = Required<components["schemas"]["UserManagementView"]>;

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type EndpointResponse =
  Endpoint["responses"]["200"]["content"]["application/json"];
type Parameters = Endpoint["parameters"];

export const defaultUMBody = {
  accountIds: [],
  roles: [],
  timeZone: null,
  status: null,
  accountManagerId: null,
  search: "",
};

export type UsersManagementResponse = {
  total?: EndpointResponse["total"];
  results?: UserItem[];
  configuration?: EndpointResponse["configuration"];
};

type RequestOptions = {
  params?: Parameters;
  body: RequestAccountAutocompleteBody;
};

export const userManagementAutocompleteKey = "userManagementAutocompleteKey";

export function useUsersAutocomplete({
  request,
  options,
}: {
  request: RequestOptions;
  options?: UseQueryOptions<UsersManagementResponse, unknown>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<UsersManagementResponse, unknown>({
    ...options,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    keepPreviousData: true,
    enabled: !!request.params?.query?.size,
    queryKey: [userManagementAutocompleteKey, request],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const url = makeApiUrl<Parameters>(apiPath, request.params);
      const { data } = await axios<UsersManagementResponse>(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: request.body,
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}
