import { Avatar, Palette, Stack, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import RemoveIcon from "@mui/icons-material/Remove";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import {
  getUserHighestRole,
  getUserManagementAccount,
  getUserManagementAcountId,
  getUserManagementCreationDate,
  getUserManagementCreationDateDistanceToNow,
  getUserManagementEmail,
  getUserManagementExpirationDate,
  getUserManagementExpirationDateDistanceToNow,
  getUserManagementFirstName,
  getUserManagementId,
  getUserManagementLastName,
  getUserManagementStatus,
} from "src/api/useUserManagementResult.getters";
import { accountsManagementEditAccountRoute } from "src/pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { userManagementUpdateRoute } from "src/pages/UserManagementUpdate/UserManagementUpdate.route";
import { AppLink } from "src/components/AppLink/AppLink";
import { getRoleColor } from "src/pages/UserManagement/UserManagement.utils";
import { getStatusIcon } from "src/pages/AccountsManagement/components/AccountsManagementTable/AccountsManagementTable.utils";
import { AccountsManagementTableClasses } from "src/pages/AccountsManagement/components/AccountsManagementTable/AccountsManagementTable.const";
import { UserManagementTableColDef } from "./UserManagementTable.model";
import { DuplicateUserFormValues } from "../DuplicateUserForm/DuplicateUserForm.model";
import { UserManagementActions } from "../UserManagementActions/UserManagementActions";

const commonCellParams: Pick<
  UserManagementTableColDef,
  "sortable" | "align" | "headerAlign" | "disableColumnMenu" | "renderCell"
> = {
  sortable: false,
  disableColumnMenu: true,
  renderCell(params) {
    return (
      <Tooltip title={params.value}>
        <TextLineClamp lineClamp={2}>{params.value}</TextLineClamp>
      </Tooltip>
    );
  },
};

export function makeUserManagementhResultTableColumns(
  palette: Palette,
  onUserEdit: (userId: string) => void,
  onUserDuplicate: (userId: DuplicateUserFormValues | null) => void,
  onUserDeactivate: (id: string, isActive: boolean) => void,
  authUserHighestRole: string
): UserManagementTableColDef[] {
  return [
    {
      ...commonCellParams,
      headerName: "Full Name",
      description: "Full Name",
      field: "fullName",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const fullName = `${getUserManagementFirstName(
          params.row
        )} ${getUserManagementLastName(params.row)}`;
        const userId = getUserManagementId(params.row);
        const userURL = userManagementUpdateRoute.makeUrl({ userId });

        return (
          <Tooltip title={fullName}>
            <TextLineClamp lineClamp={2} color="primary">
              <AppLink to={userURL}>{fullName}</AppLink>
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Account",
      description: "Account",
      field: "account",
      flex: 1,
      renderCell(params) {
        const accountId = getUserManagementAcountId(params.row);
        const accountUrl = accountsManagementEditAccountRoute.makeUrl({
          accountId: accountId,
        });
        const accountName = getUserManagementAccount(params.row);
        return (
          <Tooltip title={accountName}>
            <TextLineClamp lineClamp={2} color="primary">
              <AppLink to={accountUrl}>{accountName}</AppLink>
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Status",
      description: "Status",
      field: "status",
      width: 70,
      align: "center",
      cellClassName: AccountsManagementTableClasses.cellStatus,
      renderCell(params) {
        const status = getUserManagementStatus(params.row);
        return (
          <Stack direction="row" alignItems={"center"} gap={1}>
            <Tooltip
              title={status}
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize",
                  },
                },
              }}
            >
              <TextLineClamp lineClamp={2} color="primary">
                {getStatusIcon(status)}
              </TextLineClamp>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Email",
      description: "Email",
      field: "email",
      minWidth: 350,
      flex: 1,
      valueGetter(params) {
        return getUserManagementEmail(params.row);
      },
    },
    {
      ...commonCellParams,
      headerName: "Creation date",
      description: "Creation date",
      field: "createdDate",
      flex: 1,
      renderCell(params) {
        const dateDistance = getUserManagementCreationDateDistanceToNow(
          params.row
        );
        const date = getUserManagementCreationDate(params.row);
        return (
          <Tooltip title={date}>
            <TextLineClamp lineClamp={2}>{dateDistance}</TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Expiration date",
      description: "Expiration date",
      field: "expirationDate",
      flex: 1,
      renderCell(params) {
        const dateDistance = getUserManagementExpirationDateDistanceToNow(
          params.row
        );
        const date = getUserManagementExpirationDate(params.row);
        return (
          <Tooltip title={date}>
            <TextLineClamp textTransform={"capitalize"} lineClamp={2}>
              {dateDistance}
            </TextLineClamp>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Role",
      description: "Role",
      field: "roles",
      width: 70,
      renderCell(params) {
        const userHighestRole = getUserHighestRole(params.row);

        return (
          <Tooltip title={userHighestRole}>
            <Avatar
              sx={{
                backgroundColor: getRoleColor(palette, userHighestRole),
                "&:hover": {
                  backgroundColor: getRoleColor(palette, userHighestRole),
                },
                color: palette.text.primary,
              }}
            >
              {userHighestRole === "No role" ? <RemoveIcon /> : <PersonIcon />}
            </Avatar>
          </Tooltip>
        );
      },
    },
    {
      ...commonCellParams,
      headerName: "Actions",
      description: "Actions",
      field: "actions",
      width: 80,
      renderCell(params) {
        const userData = {
          firstName: params.row.firstName || "",
          lastName: params.row.lastName || "",
          email: params.row.email || "",
          id: params.row.id || "",
        };
        const userHighestRole = getUserHighestRole(params.row);

        return (
          <UserManagementActions
            id={params.row.id}
            status={getUserManagementStatus(params.row)}
            onUserEdit={() => onUserEdit(params.row.id)}
            onUserClone={() => onUserDuplicate(userData)}
            authUserHighestRole={authUserHighestRole}
            userHighestRole={userHighestRole}
            onUserDeactivate={() =>
              onUserDeactivate(
                params.row.id,
                getUserManagementStatus(params.row) !== "active"
              )
            }
          />
        );
      },
    },
  ];
}
