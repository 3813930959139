import { useRef } from "react";
import { PopperPlacementType } from "@mui/material";
import { useOpenState } from "src/utils/useOpenState";
import { AppLink } from "src/components/AppLink/AppLink";
import { AppPopper } from "src/components/AppPopper/AppPopper";
import { AppPopperArrowSize } from "src/components/AppPopper/components/AppPopperArrow/AppPopperArrow.model";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { WatchListUserQueryDTO } from "src/models/WatchListUserQueryDTO";
import { watchListTermResultsRoute } from "src/pages/WatchListTermResults/WatchListTermResults.route";
import { formatDateTimeWithoutTimeZone } from "src/utils/formatDateTimeWithoutTimeZone";
import { isWatchQueryComplex } from "src/utils/isWatchQueryComplex";
import { WatchQueryExplanation } from "./WatchQueryExplanation";
import { useIsMobile } from "src/utils/useIsMobile";

export function WatchQueryTitle({
  query,
  dateRange,
  placement,
}: {
  query: WatchListUserQueryDTO;
  dateRange: [Date, Date];
  placement?: PopperPlacementType;
}) {
  const isMobile = useIsMobile();
  const popperState = useOpenState();
  const containerRef = useRef<HTMLDivElement>(null);

  const { queryBuilderDefinition } = query;
  const isComplex = queryBuilderDefinition
    ? isWatchQueryComplex({ queryBuilderDefinition })
    : false;

  return (
    <TextLineClamp
      lineClamp={2}
      whiteSpace="break-spaces"
      onMouseEnter={popperState.show}
      onMouseLeave={popperState.hide}
    >
      <AppLink
        variant="subtitle1"
        to={watchListTermResultsRoute.makeUrl(
          {
            queryId: query.id,
          },
          {
            startDateTime: formatDateTimeWithoutTimeZone(dateRange[0]),
            endDateTime: formatDateTimeWithoutTimeZone(dateRange[1]),
          }
        )}
      >
        {isComplex ? (
          <span ref={containerRef}>{query.title}* </span>
        ) : (
          query.title
        )}
      </AppLink>

      <AppPopper
        open={!isMobile && isComplex && popperState.isOpen}
        anchorEl={containerRef.current}
        onClose={popperState.hide}
        placement={placement}
        arrow={AppPopperArrowSize.medium}
        children={
          <WatchQueryExplanation
            title="Watch Term Monitors"
            queryDefinition={query.queryBuilderDefinition}
          />
        }
      />
    </TextLineClamp>
  );
}
