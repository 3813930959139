import { Fragment, ReactNode } from "react";
import {
  LabelDisplayedRowsArgs,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  GridFooterContainer,
  GridPagination,
  useGridApiContext,
  useGridRootProps,
} from "@mui/x-data-grid-premium";
import { PaginationActions } from "src/components/PaginationActions/PaginationActions";
import { tableBaseFooterClasses } from "./TableBaseFooter.const";

export type TableBaseFooterProps = {
  showDensitySwitch?: boolean;
  renderCustomFooterEl?: () => ReactNode;
  nextPageButtonIsDisabled?: boolean;
  renderLabelDisplayedRows?: (
    paginationInfo: LabelDisplayedRowsArgs
  ) => ReactNode;
};

export function TableBaseFooter({
  showDensitySwitch,
  renderCustomFooterEl,
  nextPageButtonIsDisabled = false,
  renderLabelDisplayedRows,
}: TableBaseFooterProps) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const renderLabelDisplayedRowsContent = (
    paginationInfo: LabelDisplayedRowsArgs
  ) => {
    const { from, to, count } = paginationInfo;
    return `${from.toLocaleString()} - ${to.toLocaleString()} of ${count.toLocaleString()}`;
  };

  return (
    <GridFooterContainer sx={{ fontSize: 12 }}>
      <Stack direction="row" alignItems="center">
        {renderCustomFooterEl ? (
          renderCustomFooterEl()
        ) : showDensitySwitch ? (
          <Fragment>
            <Switch
              className={tableBaseFooterClasses.densitySwitch}
              value={rootProps.density === "compact"}
              onChange={(_e, checked) => {
                apiRef.current.setDensity(checked ? "compact" : "standard");
              }}
            />
            <Typography fontSize="inherit">Dense</Typography>
          </Fragment>
        ) : null}
      </Stack>
      <GridPagination
        ActionsComponent={PaginationActions}
        labelDisplayedRows={
          renderLabelDisplayedRows || renderLabelDisplayedRowsContent
        }
        slotProps={{
          actions: {
            nextButton: { disabled: nextPageButtonIsDisabled },
          },
        }}
      />
    </GridFooterContainer>
  );
}
