import { useEffect, useMemo } from "react";
import { format } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useReportCreate } from "src/api/useReportCreate";
import { useReportCreateFromQuery } from "src/api/useReportCreateFromQuery";
import { useReportCreateFromPowersearch } from "src/api/useReportCreateFromPowersearch";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { MediaCenterItem } from "src/api/useMediaCenter";
import { useReportNotify } from "src/api/useReportNotify";
import { QueryDefinition } from "src/models/QueryDefinition";
import { ReportItem } from "src/models/Report";
import { EventDetails } from "src/models/EventDetails";
import {
  getFullShareEventsLink,
  getFullPublicEventLink,
} from "src/utils/getFullPublicPageLink";
import { DialogBaseProps } from "../DialogBase/DialogBase.model";
import { ShareFormValues } from "../ShareForm/ShareForm.model";
import { ShareFormSchema } from "../ShareForm/ShareForm.schema";
import { ShareBaseDialog } from "../ShareBaseDialog/ShareBaseDialog";

type ShareEventsDialogProps = Omit<
  DialogBaseProps,
  "title" | "onSubmit" | "onClose"
> & {
  title?: string;
  events: PowerSearchResultItem[] | MediaCenterItem[] | EventDetails[];
  onSubmit: () => void;
  onClose: () => void;
  copyShareLinkCallback: () => void;
  areAllPagesSelected?: boolean;
  isPowerSearch?: boolean;
  queryParams?: {
    queryDefinition: QueryDefinition;
    queryId?: string;
    startTime?: string;
    endTime?: string;
    groupDuplicates?: boolean;
  };
  selectedEventsLength?: number;
};

const defaultValues = {
  recipients: [],
  subject: "",
  note: "",
};

export const ShareEventsDialog = ({
  events,
  onClose,
  onSubmit,
  copyShareLinkCallback,
  areAllPagesSelected = false,
  isPowerSearch = false,
  queryParams,
  selectedEventsLength,
  title = "Share Event",
  ...props
}: ShareEventsDialogProps) => {
  const formHook = useForm<ShareFormValues>({
    mode: "all",
    resolver: joiResolver(ShareFormSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = formHook;
  const formValues = formHook.watch();

  const reportNotifyMutation = useReportNotify({});

  const shareMutationOptions = {
    onSuccess: (data: ReportItem) => {
      if (!data?.id) return;
      const { recipients, subject, note } = formValues;
      if (!recipients.length) return;
      reportNotifyMutation.mutate({
        params: {
          path: {
            id: data.id,
          },
        },
        recipients,
        subject,
        message: note,
      });
    },
  };

  const shareEventsMutation = useReportCreate({
    options: shareMutationOptions,
  });

  const shareEventsFromQueryMutation = useReportCreateFromQuery({
    options: shareMutationOptions,
  });

  const shareEventsFromPowersearchMutation = useReportCreateFromPowersearch({
    options: shareMutationOptions,
  });

  const titleLabel = `${format(new Date(), "MM/d/yy")} - Shared ${
    selectedEventsLength || events.length
  } events`;

  const shareEvents = (notify: boolean) => {
    if (!areAllPagesSelected) {
      shareEventsMutation.mutate({
        title: titleLabel,
        selectedEvents: events,
        archive: false,
        notify,
      });
      return;
    }

    const allEventsMutationParams = {
      queryDefinition: queryParams?.queryDefinition || {},
      params: {
        query: {
          startTime: queryParams?.startTime,
          endTime: queryParams?.endTime,
          groupDuplicates: queryParams?.groupDuplicates,
          title,
          archive: false,
          notify,
        },
      },
    };

    if (isPowerSearch) {
      shareEventsFromPowersearchMutation.mutate(allEventsMutationParams);
    } else {
      shareEventsFromQueryMutation.mutate({
        ...allEventsMutationParams,
        params: {
          query: {
            ...allEventsMutationParams.params.query,
            queryId: queryParams?.queryId,
          },
        },
      });
    }
  };

  const instantLinkValue = useMemo(() => {
    const reportId =
      shareEventsMutation.data?.id ||
      shareEventsFromQueryMutation.data?.id ||
      shareEventsFromPowersearchMutation.data?.id;
    if (reportId) {
      if (events.length === 1) {
        return getFullPublicEventLink(reportId, events[0].id as string);
      }
      return getFullShareEventsLink(reportId);
    }
    return "";
  }, [
    shareEventsMutation.data,
    shareEventsFromQueryMutation.data?.id,
    shareEventsFromPowersearchMutation.data?.id,
    events,
  ]);

  const handleSubmitWrapper = handleSubmit(() => {
    shareEvents(true);
    onClose();
    onSubmit();
  });

  useEffect(() => {
    // reset when Dialog is closed, but not unmounted
    if (!props.open) {
      reset({}, { keepDefaultValues: true });
    } else {
      reset(defaultValues);
    }
  }, [reset, props.open]);

  return (
    <FormProvider {...formHook}>
      <ShareBaseDialog
        title={title}
        instantLinkValue={instantLinkValue}
        getInstantLink={() => shareEvents(false)}
        isLoadingLink={
          shareEventsMutation.isLoading ||
          shareEventsFromQueryMutation.isLoading ||
          shareEventsFromPowersearchMutation.isLoading
        }
        copyShareLinkCallback={copyShareLinkCallback}
        onClose={onClose}
        isBusy={!formState.isValid || !formState.dirtyFields?.recipients}
        handleSubmit={handleSubmitWrapper}
        {...props}
      />
    </FormProvider>
  );
};
