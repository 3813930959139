import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { queryAdvertisementValues } from "src/models/Advertisement";
import {
  emptySharedValues,
  PowerSearchSharedValues,
  usePowerSearchSharedForm,
} from "src/api/usePowerSearchSharedForm";
import { PowerSearchKeywordFormValues } from "./PowerSearchKeywordForm.model";

export const emptyPowerSearchKeywordFormValues: PowerSearchKeywordFormValues = {
  displayName: "",
  termsInclude: {
    list: [],
    logic: "or",
  },
  termsExclude: {
    list: [],
    logic: "or",
  },
  startDateTime: null,
  endDateTime: null,
  sourcesInclude: {
    list: [],
    logic: "or",
  },
  sourcesExclude: {
    list: [],
    logic: "or",
  },
  advertisement: queryAdvertisementValues.all,
  programInclude: {
    list: [],
    logic: "or",
  },
  programExclude: {
    list: [],
    logic: "or",
  },
};

type UseWatchQueryFormArgs = {
  initial?: Partial<PowerSearchKeywordFormValues>;
  schema: Joi.Schema;
};

export function usePowerSearchKeywordForm(params?: UseWatchQueryFormArgs) {
  const { initial, schema } = params || {};
  const formHook = useForm<PowerSearchKeywordFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: schema ? joiResolver(schema) : undefined,
    defaultValues: {
      ...emptyPowerSearchKeywordFormValues,
      ...initial,
    },
  });

  const { formState, watch, setValue, trigger } = formHook;

  const { dirtyFields } = formState;

  const { saveSharedValue } = usePowerSearchSharedForm();
  const sharedValues = Object.keys(emptySharedValues);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "termsInclude" &&
        !dirtyFields.displayName
      ) {
        const updatedValue = value.termsInclude?.list?.join(", ") || "";
        setValue("displayName", updatedValue);
      }

      if (name === "endDateTime") {
        trigger("startDateTime");
      }

      if (
        type === "change" &&
        sharedValues.includes(name as keyof PowerSearchSharedValues)
      ) {
        const itemValue = value[name as keyof PowerSearchSharedValues];
        saveSharedValue(
          name as keyof PowerSearchSharedValues,
          itemValue as PowerSearchSharedValues[keyof PowerSearchSharedValues]
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, setValue, dirtyFields, saveSharedValue, sharedValues]);

  return formHook;
}
