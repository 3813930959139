import { ListBase } from "src/components/ListBase/ListBase";
import { PowerSearchResultListItem } from "src/pages/PowerSearchResult/components/PowerSearchResultList/PowerSearchResultListItem";
import {
  EventsNoResultsOverlay,
  FilteredEventsNoResultsOverlay,
} from "src/components/NoResultsOverlay/NoResultsOverlay";
import { useEventSelectionPowerSearchResults } from "src/api/useEventSelectionPowerSearchResults";
import { PowerSearchResultItem } from "src/api/usePowerSearch";
import { PowerSearchResultListProps } from "./PowerSearchResultList.model";

export function PowerSearchResultList({
  addItemToReport,
  isExpandedTextMode,
  hideMarket,
  showDuplicatesDrawer,
  drawerParentEventId,
  hasFilters,
  ...props
}: PowerSearchResultListProps) {
  const { list, areAllPagesSelected, excludeList } =
    useEventSelectionPowerSearchResults();

  const renderItem = (data: PowerSearchResultItem) => {
    let isChecked = false;
    if (areAllPagesSelected) {
      isChecked = !excludeList.find((event) => event.id === data.id);
    } else {
      isChecked = !!list.find((event) => event.id === data.id);
    }

    return (
      <PowerSearchResultListItem
        value={data}
        isChecked={isChecked}
        key={data.id}
        addItemToReport={addItemToReport}
        isExpandedTextMode={isExpandedTextMode}
        hideMarket={hideMarket}
        showDuplicatesDrawer={showDuplicatesDrawer}
        drawerParentEventId={drawerParentEventId}
      />
    );
  };

  return (
    <ListBase<PowerSearchResultItem>
      renderItem={renderItem}
      renderEmpty={
        hasFilters ? FilteredEventsNoResultsOverlay : EventsNoResultsOverlay
      }
      ListProps={{
        sx: { pl: 2, pr: 1, height: "100%" },
      }}
      {...props}
    />
  );
}
